// @mui
import { Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useQueryClient } from '@tanstack/react-query';
// components
import moment from 'moment';
import { Link } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { AuthorizedUsersQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import RejectTeacherRequestDialog from 'src/sections/dialogs/edit/reject-teacher-request-dialog';

// ----------------------------------------------------------------------

type Props = {
  row: AuthorizedUsersQuery['authorized_users'][0];
};

export default function TeachersRequestRow({ row }: Props) {
  // #region States and hooks
  const queryClient = useQueryClient();
  const { t, currentLang } = useLocales();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';
  const router = useRouter();
  const chipColor =
    row.status === 'approved' ? 'success' : row.status === 'pending' ? 'warning' : 'error';
  // #endregion

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <Link
            style={{
              color: isLight ? 'black' : 'white',
            }}
            to={`${paths.dashboard.userAuthorization.teachersRequests}/${row.authorizedUserId}`}
          >
            {row.user.firstName}
          </Link>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <Link
            style={{
              color: isLight ? 'black' : 'white',
            }}
            to={`${paths.dashboard.userAuthorization.teachersRequests}/${row.authorizedUserId}`}
          >
            {row.user.lastName}
          </Link>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>{row.fieldOfStudy}</TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.files_aggregate.aggregate?.count}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {moment(row.createdAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell>
          <Chip label={row.status} color={chipColor} />
        </TableCell>

        {/* TODO: Edit permission */}
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {/* TODO: Edit permission */}
          <AppPermissionGate permissionsName={[AppPermissions.USER_RECORD_VIEW]}>
            <Tooltip title={t('tableLabels.preview')} placement="bottom" arrow>
              <IconButton
                LinkComponent={RouterLink}
                onClick={() => router.push(row.authorizedUserId)}
              >
                <Iconify icon="mdi:eye-circle" width="27" height="27" color="success" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
    </>
  );
}
