import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';

import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import AddCustomNewBadge from 'src/pages/dashboard/badges/add-new/custom/add-new-custom-badge';
import AddGeneralNewBadge from 'src/pages/dashboard/badges/add-new/general/add-new-general-badge';
import BadgesContainer from 'src/pages/dashboard/badges/table/badges-container';
import Badges from 'src/pages/dashboard/badges/table/badges';
import AddNewCertificate from 'src/pages/dashboard/certificates/add-new/add-new-certificate';
import Certificates from 'src/pages/dashboard/certificates/table/certificates';
import ContactUs from 'src/pages/dashboard/contact-us/contact-us';
import AddNewUser from 'src/pages/dashboard/dashboard-users/add-new/add-new-user';
import DashboardUsers from 'src/pages/dashboard/dashboard-users/table/dashboard-users';
import AddNewExam from 'src/pages/dashboard/exams/add-new/add-new-exam';
import Exams from 'src/pages/dashboard/exams/table/exams';
import AddNewFAQ from 'src/pages/dashboard/faqs/add-new/add-new-faq';
import FAQs from 'src/pages/dashboard/faqs/table/FAQs';
import AddNewHearingCouncil from 'src/pages/dashboard/hearing-councils/add-new/add-new-hearing-council';
import HearingCouncilsAnalysis from 'src/pages/dashboard/hearing-councils/analysis/view';
import HearingCouncils from 'src/pages/dashboard/hearing-councils/view/table/hearing-councils';
import AddNewKnowledgeBase from 'src/pages/dashboard/knowledge-bases/add-new/add-new-knowledge-base';
import KnowLedgeBases from 'src/pages/dashboard/knowledge-bases/table/knowledge-bases';
import MutoonsCategories from 'src/pages/dashboard/mutoon-management/categories/categories-mutoon';
import MatnSections from 'src/pages/dashboard/mutoon-management/matn-sections/matn-sections';
import AddNewMatnSection from 'src/pages/dashboard/mutoon-management/matn-sections/add-new-matn-section/view/add-new-matn-section';
import MatnSectionDiffWords from 'src/pages/dashboard/mutoon-management/matn-sections/matn-section-diff-words/matn-section-diff-words';
import MatnSectionSounds from 'src/pages/dashboard/mutoon-management/matn-sections/matn-section-sounds/matn-section-sounds';
import AddNewBook from 'src/pages/dashboard/mutoon-management/reference-books/add-new/add-new-book';
import ReferenceBooks from 'src/pages/dashboard/mutoon-management/reference-books/table/ReferenceBooks';
import AddNewQuestions from 'src/pages/dashboard/questions/add-new/view/index';
import RolesPage from 'src/pages/dashboard/roles';
import AddRolePage from 'src/pages/dashboard/roles/AddRole';
import EditRolePage from 'src/pages/dashboard/roles/EditRole';
import AddNewSession from 'src/pages/dashboard/sessions/add/add-new-session';
import SessionJoin from 'src/pages/dashboard/sessions/join/session-join';
import Statistics from 'src/pages/dashboard/statistics/view';
import AboutUser from 'src/pages/dashboard/user-management/user-details/about-user';
import UserDonations from 'src/pages/dashboard/user-management/user-details/donations';
import UserHearingCouncils from 'src/pages/dashboard/user-management/user-details/user-hearing-councils';
import UserSessions from 'src/pages/dashboard/user-management/user-details/user-sessions';
import UserDetailsView from 'src/pages/dashboard/user-management/user-details/view';
import UserManagementView from 'src/pages/dashboard/user-management/view';
import UsersRecords from 'src/pages/dashboard/users-records/users-records';
import { paths } from '../paths';
import Questions from 'src/pages/dashboard/questions/table/Questions';
import Sessions from 'src/pages/dashboard/sessions/table/Sessions';
import TeachersRequests from 'src/pages/dashboard/users-authorization/teachers-request';
import TeacherRequestDetails from 'src/pages/dashboard/users-authorization/teacher-request-details';
import SchoolsRequests from 'src/pages/dashboard/school-users/school-request/schools-request';
import SchoolRequestDetails from 'src/pages/dashboard/school-users/school-request-details/school-request-details';
import HearingCouncilJoin from 'src/pages/dashboard/hearing-councils/join/hearing-council-join';
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        replace: '/',
        element: <Navigate to={paths.dashboard.hearingCouncils} />,
      },
      // Statistics
      {
        path: 'statistics',
        element: <Statistics />,
      },
      // Mutoon
      {
        path: 'mutoon',
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard/mutoon/categories" />,
          },
          {
            path: 'categories',
            element: <MutoonsCategories />,
          },
          {
            path: 'categories/matn/:matnId',
            element: <MatnSections />,
          },
          {
            path: 'categories/matn/:matnId/new',
            element: <AddNewMatnSection />,
          },
          {
            path: 'categories/matn/:matnId/:matnSectionId',
            children: [
              {
                index: true,
                element: <AddNewMatnSection />,
              },
              {
                path: 'difficult-words',
                element: <MatnSectionDiffWords />,
              },
              {
                path: 'sounds',
                element: <MatnSectionSounds />,
              },
            ],
          },
          {
            path: 'categories/*',
            element: <MutoonsCategories />,
          },
          {
            path: 'books',
            children: [
              {
                index: true,
                element: <ReferenceBooks />,
              },
              {
                path: 'new',
                element: <AddNewBook />,
              },
              {
                path: ':bookId',
                element: <AddNewBook />,
              },
            ],
          },
          {
            path: 'exams',
            children: [
              {
                index: true,
                element: <Exams />,
              },
              {
                path: 'new',
                element: <AddNewExam />,
              },
              {
                path: ':examId',
                element: <AddNewExam />,
              },
              {
                path: 'questions/:questionsExamId',
                element: <AddNewQuestions />,
              },
            ],
          },
          {
            path: 'questions',
            children: [
              {
                index: true,
                element: <Questions />,
              },
              {
                path: 'new',
                element: <AddNewQuestions />,
              },
              {
                path: ':questionId',
                element: <AddNewQuestions />,
              },
            ],
          },
        ],
      },
      // Accounts authorization
      {
        path: 'user-authorization',
        children: [
          {
            index: true,
            element: <TeachersRequests />,
          },
          {
            path: 'teachers-requests',
            children: [
              {
                index: true,
                element: <TeachersRequests />,
              },
              {
                path: ':teacherRequestId',
                element: <TeacherRequestDetails />,
              },
            ],
          },
          {
            path: 'schools-accounts',
            children: [
              {
                index: true,
                element: <SchoolsRequests />,
              },
              {
                path: ':schoolRequestId',
                element: <SchoolRequestDetails />,
              },
            ],
          },
        ],
      },
      // Hearing councils
      {
        path: 'hearing-councils',
        children: [
          {
            index: true,
            element: <HearingCouncils />,
          },
          {
            path: 'new',
            element: <AddNewHearingCouncil />,
          },
          {
            path: ':hearingCouncilId',
            element: <HearingCouncilJoin />,
          },
          {
            path: ':hearingCouncilId/edit',
            element: <AddNewHearingCouncil />,
          },
          {
            path: ':hearingCouncilId/analysis',
            element: <HearingCouncilsAnalysis />,
          },
        ],
      },
      // Sessions
      {
        path: 'sessions',
        children: [
          {
            index: true,
            element: <Sessions />,
          },
          {
            path: 'new',
            element: <AddNewSession />,
          },
          {
            path: ':sessionId',
            element: <SessionJoin />,
          },
        ],
      },
      // Voice requests
      {
        path: 'voice-requests',
        element: <UsersRecords />,
      },
      // App user management
      {
        path: paths.dashboard.appUsers,
        children: [
          {
            index: true,
            element: <UserManagementView />,
          },
          {
            path: ':userId',
            element: (
              <UserDetailsView>
                <Outlet />
              </UserDetailsView>
            ),
            children: [
              {
                index: true,
                replace: '/',
                element: <Navigate to="about-user" />,
              },
              {
                path: 'about-user',
                element: <AboutUser />,
              },
              {
                path: 'sessions',
                element: <UserSessions />,
              },
              {
                path: 'hearing-councils',
                element: <UserHearingCouncils />,
              },
              {
                path: 'donations',
                element: <UserDonations />,
              },
            ],
          },
        ],
      },
      // Badges
      {
        path: 'badges',
        children: [
          {
            element: (
              <BadgesContainer>
                <Outlet />
              </BadgesContainer>
            ),
            children: [
              {
                index: true,
                replace: '/',
                element: <Navigate to="custom-badges" />,
              },
              {
                path: 'custom-badges',
                children: [
                  {
                    index: true,
                    element: <Badges isGeneral={false} />,
                  },
                  {
                    path: ':badgeId',
                    element: <AddCustomNewBadge />,
                  },
                ],
              },
              {
                path: 'general-badges',
                children: [
                  {
                    index: true,
                    element: <Badges isGeneral />,
                  },
                  {
                    path: ':badgeId',
                    element: <AddGeneralNewBadge />,
                  },
                ],
              },
            ],
          },
          {
            path: 'new',
            children: [
              {
                index: true,
                replace: '/',
                element: <Navigate to="custom-badges" />,
              },
              {
                path: 'custom-badges',
                element: <AddCustomNewBadge />,
              },
              {
                path: 'general-badges',
                element: <AddGeneralNewBadge />,
              },
            ],
          },
        ],
      },
      // Knowledge bases
      {
        path: 'knowledge-bases',
        children: [
          {
            index: true,
            element: <KnowLedgeBases />,
          },
          {
            path: 'new',
            element: <AddNewKnowledgeBase />,
          },
          {
            path: ':knowledgeBaseId',
            element: <AddNewKnowledgeBase />,
          },
        ],
      },
      // FAQs
      {
        path: 'faqs',
        children: [
          {
            index: true,
            element: <FAQs />,
          },
          {
            path: 'new',
            element: <AddNewFAQ />,
          },
          {
            path: ':faqId',
            element: <AddNewFAQ />,
          },
        ],
      },
      // Certificates
      {
        path: 'certificates',
        children: [
          {
            index: true,
            element: <Certificates />,
          },
          {
            path: 'new',
            element: <AddNewCertificate />,
          },
          {
            path: ':certificateId',
            element: <AddNewCertificate />,
          },
        ],
      },
      // Contact us
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
      // Roles
      {
        path: 'roles-and-users/roles',
        children: [
          { element: <RolesPage />, index: true },
          { path: 'new-role', element: <AddRolePage /> },
          { path: 'editRole/:roleId', element: <EditRolePage /> },
        ],
      },
      {
        path: 'roles-and-users/users',
        children: [
          { element: <DashboardUsers />, index: true },
          { path: ':userId', element: <AddNewUser /> },
          { path: 'new', element: <AddNewUser /> },
        ],
      },
    ],
  },
];
