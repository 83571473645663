import { Checkbox, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify/iconify';
import { useTable } from 'src/components/table';
import { AppTablePropsType } from 'src/components/table/app-table';
import RowActions, { RowActionsPropsType } from 'src/components/table/table-row-actions';
import {
  HearingCouncilAnalysisQuery,
  Order_By,
  SchoolStudentsQuery,
  useCreateCouncilCertificateMutation,
  useHearingCouncilAnalysisQuery,
  useSchoolStudentsQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { default as UseSearch } from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import SchoolStudents from 'src/pages/dashboard/school-users/school-students/view/school-students';
import { FinishedCouncilsFormType } from 'src/types/hearing-council';
import { orderBy } from 'src/utils/order-by';

const useFinishedHearingCouncilsSchoolsUsers = () => {
  // #region States and hooks
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const { methods, query, handleSearch, setQuery } = UseSearch<FinishedCouncilsFormType>({});
  const [selectedRow, setSelectedRow] =
    useState<HearingCouncilAnalysisQuery['user_hearing_councils'][0]>();
  const [index, setIndex] = useState<number>();
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const { hearingCouncilId } = useParams();
  const confirm = useBoolean();
  const confirmExportSelected = useBoolean();
  const [showStudentsSchool, setShowStudentsSchool] =
    useState<HearingCouncilAnalysisQuery['user_hearing_councils'][0]>();

  const {
    fields: selectedUsers,
    append: selectUser,
    remove: unselectUser,
  } = useFieldArray({
    control: methods!?.control,
    name: 'selectedUsers',
  });
  // #endregion States and hooks

  // #region Services
  const { mutate: createCert, isLoading: isCreatingCert } = useCreateCouncilCertificateMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      confirmExportSelected.onFalse();
      queryClient.invalidateQueries({ queryKey: ['HearingCouncilAnalysis'] });
      queryClient.invalidateQueries({ queryKey: ['SchoolStudents'] });
      if (!data.createUserHearingCouncilCertificate.length) {
        enqueueSnackbar({
          message: t('tables.finishedHearingCouncilsAnalysis.createCertResWarning'),
          variant: 'warning',
        });
      }
    },
  });

  const { data, isLoading } = useHearingCouncilAnalysisQuery(
    {
      offset: table.offset,
      limit: table.rowsPerPage,
      order_by: orderBy(methods.watch().sortBy!, methods.watch().sortDirection),
      hearingCouncilId: hearingCouncilId!,
      search: query.search ? { _ilike: `%${query.search}%` } : undefined,
      user: { school_user: {} },
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  // #endregion Services

  // #region Handlers
  const handleExportSelectedCert = () => {
    createCert({
      createCertficateInput: selectedUsers
        .map(
          (user) =>
            user.user?.school_user?.students
              .filter((student) => {
                const isCertified = student.user_hearing_councils.find(
                  (uhc) => uhc.hearingCouncilId === hearingCouncilId
                )?.user_certificate?.certificateId;
                return !isCertified;
              })
              .map((student) => {
                const userHearingCouncilId = student.user_hearing_councils.find(
                  (uhc) => uhc.hearingCouncilId === hearingCouncilId
                )?.userHearingCouncilId;
                return {
                  userHearingCouncilId: userHearingCouncilId!,
                  customName: student.firstName + ' ' + student.lastName,
                };
              })!
        )
        .flat()!,
    });
  };

  const handleExportUserCertificate = (
    index: number,
    row: HearingCouncilAnalysisQuery['user_hearing_councils'][0] | undefined
  ) => {
    console.log(row);
    if (row)
      createCert({
        createCertficateInput: row.user?.school_user?.students
          .filter((student) => {
            const isCertified = student.user_hearing_councils.find(
              (uhc) => uhc.hearingCouncilId === hearingCouncilId
            )?.user_certificate?.certificateId;
            return !isCertified;
          })
          .map((student) => ({
            userHearingCouncilId: student.user_hearing_councils.find(
              (uhc) => uhc.hearingCouncilId === hearingCouncilId
            )?.userHearingCouncilId,
            customName: student.firstName + ' ' + student.lastName,
          })),
      });
  };

  const handleSelectAllRows = (checked: boolean) => {
    table.onSelectAllRows(
      checked,
      data!?.user_hearing_councils.map((user) => user.userHearingCouncilId)
    );
    if (!checked) {
      unselectUser();
    } else {
      // appendUser();
      selectUser(methods.watch().users.map((user, index) => ({ ...user })));
    }
  };

  const handleSelectRow = (
    row: HearingCouncilAnalysisQuery['user_hearing_councils'][0],
    index: number
  ) => {
    table.onSelectRow(row.userHearingCouncilId);
    if (!table.selected.includes(row.userHearingCouncilId)) {
      selectUser({
        ...row,
        customName: methods.watch().users[index].customName,
      });
    } else {
      unselectUser(index);
    }
  };

  const handleCollapseRow = (row: HearingCouncilAnalysisQuery['user_hearing_councils'][0]) => {
    if (row.userHearingCouncilId === showStudentsSchool?.userHearingCouncilId) {
      setShowStudentsSchool(undefined);
    } else {
      setShowStudentsSchool(row);
    }
  };
  // #endregion Handlers

  useEffect(() => {
    if (data) {
      methods.setValue(
        'users',
        data!?.user_hearing_councils.map((user) => ({
          ...user,
          customName: user.user?.firstName! + ' ' + user.user?.lastName!,
        }))
      );
    }
  }, [data]);

  const generateMenuOption = (
    row: HearingCouncilAnalysisQuery['user_hearing_councils'][0],
    index: number
  ): RowActionsPropsType['options'] => {
    return [
      {
        tooltip: '',
        icon: 'fluent:certificate-16-regular',
        onClick: () => {
          setSelectedRow(row);
          setIndex(index);
          confirm.onTrue();
        },
      },
    ];
  };

  const columns: AppTablePropsType<
    HearingCouncilAnalysisQuery['user_hearing_councils'][0]
  >['columns'] = [
    {
      name: 'user.firstName',
      PreviewComponent: (data, selectRow, index) => {
        return (
          <TableCell padding="checkbox">
            <Checkbox onClick={() => handleSelectRow(data, index)} />
            <IconButton
              size="small"
              onClick={() => {
                handleCollapseRow(data);
              }}
            >
              <Iconify
                icon={
                  showStudentsSchool?.userHearingCouncilId === data.userHearingCouncilId
                    ? 'eva:arrow-ios-upward-fill'
                    : 'eva:arrow-ios-downward-fill'
                }
              />
            </IconButton>
          </TableCell>
        );
      },
    },
    {
      name: 'user.firstName',
      PreviewComponent: (data, selectRow, index) => {
        return <RHFTextField name={`users.${index}.customName`} />;
      },
    },
    {
      name: 'user.email',
    },
    {
      name: 'joinedAt',
      PreviewComponent: (data) => data.joinedAt && moment(data.joinedAt).format('YYYY-MM-DD'),
    },
    {
      name: 'durationAttendance',
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data) =>
        `${+(+data.durationAttendance! / +data.hearingCouncil.duration).toFixed(4) * 100 || '-'}%`,
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data) =>
        !data.durationAttendance
          ? '-'
          : data.numSubmissions! > 0 // has attended onsite
          ? t('tables.finishedHearingCouncilsAnalysis.online')
          : t('tables.finishedHearingCouncilsAnalysis.onSite'),
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data, _, index) => (
        <RowActions options={generateMenuOption(data, index)} />
      ),
    },
    {
      name: 'durationAttendance',
      PreviewComponent: (data, _, index) => (
        <TableCell sx={{ py: 0 }} colSpan={6}>
          <Collapse
            in={data.userHearingCouncilId === showStudentsSchool?.userHearingCouncilId}
            unmountOnExit
          >
            <SchoolStudents userId={data.user?.id!} />
          </Collapse>
        </TableCell>
      ),
      collapsible: true,
    },
  ];

  const filters = [{}];

  return {
    t,
    data,
    table: table,
    isLoading,
    isCreatingCert,
    columns: columns.filter((col) => col),
    filters,
    handleSearch,
    setQuery,
    query,
    methods,
    confirm,
    confirmExportSelected,
    handleSelectAllRows,
    handleExportSelectedCert,
    handleExportUserCertificate: () => handleExportUserCertificate(index!, selectedRow!),
  };
};

export default useFinishedHearingCouncilsSchoolsUsers;
