import { TFunction } from 'i18next';
import { RefBooksFormType } from 'src/pages/dashboard/mutoon-management/reference-books/table/use-ref-books';

export const USER_RECORDS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.voiceRequests.headLabels.userName'), orderName: 'user.firstName' },
  {
    id: '2',
    label: t('tables.voiceRequests.headLabels.nameOfMatn'),
    orderName: 'mutoonSection.mutoon.name',
  },
  { id: '3', label: t('tables.questions.headLabels.matnSection'), orderName: 'mutoonSection.name' },
  { id: '4', label: t('tables.voiceRequests.headLabels.userEmail'), orderName: 'user.email' },
  { id: '5', label: t('tables.voiceRequests.headLabels.sendingDate') },
  { id: '6', label: t('tables.voiceRequests.headLabels.preview') },
  { id: '7', label: t('tables.voiceRequests.headLabels.status'), orderName: 'status' },
  { id: '8', label: t('tables.voiceRequests.headLabels.actions') },
];

export const SCHOOL_STUDENTS_TABLE_HEAD = (t: TFunction<'ns1'>) => [
  { id: '1', label: t('tables.students.headLabels.firstName'), orderName: 'firstName' },
  {
    id: '2',
    label: t('tables.students.headLabels.lastName'),
    orderName: 'lastName',
  },
  { id: '4', label: t('tables.students.headLabels.email'), orderName: 'email' },
  { id: '5', label: t('tables.students.headLabels.birthDate'), orderName: 'birthDate' },
  { id: '6', label: t('tables.students.headLabels.actions') },
];

export const CONNECTED_USERS_TABLE_HEAD = (t: TFunction<'ns1'>) => [
  { id: '1', label: t('tables.students.headLabels.firstName'), orderName: 'firstName' },
  {
    id: '2',
    label: t('tables.students.headLabels.lastName'),
    orderName: 'lastName',
  },
  { id: '4', label: t('tables.students.headLabels.email'), orderName: 'email' },
];

export const TEACHERS_REQUESTS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  {
    id: '1',
    label: t('tables.teachersRequests.headLabels.firstName'),
    orderName: 'user.firstName',
  },
  {
    id: '2',
    label: t('tables.teachersRequests.headLabels.lastName'),
    orderName: 'user.lastName',
  },
  {
    id: '3',
    label: t('tables.teachersRequests.headLabels.fieldOfStudy'),
    orderName: 'fieldOfStudy',
  },
  {
    id: '4',
    label: t('tables.teachersRequests.headLabels.filesCount'),
    orderName: 'files_aggregate.aggregate.count',
  },
  { id: '5', label: t('tables.teachersRequests.headLabels.createdAt') },
  { id: '6', label: t('tables.teachersRequests.headLabels.status'), orderName: 'status' },
  { id: '7', label: t('tables.teachersRequests.headLabels.actions') },
];

export const SCHOOLS_REQUESTS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  {
    id: '1',
    label: t('tables.schoolRequests.headLabels.schoolName'),
  },
  {
    id: '2',
    label: t('tables.schoolRequests.headLabels.city'),
  },
  {
    id: '3',
    label: t('tables.schoolRequests.headLabels.schoolAddress'),
  },
  {
    id: '4',
    label: t('tables.schoolRequests.headLabels.filesCount'),
  },
  { id: '6', label: t('tables.schoolRequests.headLabels.createdAt') },
  { id: '5', label: t('tables.schoolRequests.headLabels.status') },
  { id: '7', label: t('tables.schoolRequests.headLabels.actions') },
];

export const CATEGORIES_AND_MUTOON_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.categories.headLabels.name'), orderName: 'name' },
  { id: '2', label: t('tables.categories.headLabels.type') },
  { id: '4', label: t('tables.categories.headLabels.date'), orderName: 'createdAt' },
  { id: '3', label: t('tables.categories.headLabels.actions') },
];

export const QUESTIONS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.questions.headLabels.question'), orderName: 'questionText' },
  {
    id: '2',
    label: t('tables.questions.headLabels.matn'),
    orderName: 'mutoon_section.mutoon.name',
  },
  {
    id: '4',
    label: t('tables.questions.headLabels.matnSection'),
    orderName: 'mutoon_section.name',
  },
  { id: '5', label: t('tables.questions.headLabels.questionChoices') },
  { id: '3', label: t('tables.questions.headLabels.questionType'), orderName: 'choicesType' },
  { id: '6', label: t('tables.exams.headLabels.actions') },
];

export const DASHBOARD_USERS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.dashboardUsers.headLabels.name'), orderName: 'username' },
  { id: '2', label: t('tables.dashboardUsers.headLabels.role'), orderName: 'role.name' },
  { id: '3', label: t('tables.dashboardUsers.headLabels.email'), orderName: 'email' },
  { id: '4', label: t('tables.dashboardUsers.headLabels.actions') },
];

export const LEVELS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.levels.headLabels.nameOfLevel') },
  { id: '2', label: t('tables.levels.headLabels.date') },
  { id: '3', label: t('tables.levels.headLabels.actions') },
];

export const MATN_SECTIONS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.matnSections.headLabels.name'), orderName: 'name' },
  { id: '2', label: t('tables.matnSections.headLabels.description'), orderName: 'description' },
  { id: '3', label: t('tables.matnSections.headLabels.date') },
  { id: '4', label: t('tables.matnSections.headLabels.actions') },
];

export const SOUND_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.matnSectionSounds.headLabels.name') },
  { id: '2', label: t('tables.matnSectionSounds.headLabels.description') },
  { id: '3', label: t('tables.matnSectionSounds.headLabels.preview') },
  { id: '4', label: t('tables.matnSectionSounds.headLabels.actions') },
];

export const KNOWLEDGE_BASES_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.knowledgeBases.headLabels.title'), orderName: 'title' },
  { id: '2', label: t('tables.knowledgeBases.headLabels.content'), orderName: 'content' },
  { id: '3', label: t('tables.knowledgeBases.headLabels.createdAt'), orderName: 'createdAt' },
  { id: '4', label: t('tables.knowledgeBases.headLabels.coverUrl') },
  { id: '5', label: t('tables.knowledgeBases.headLabels.actions') },
];

export const CERTIFICATES_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.certificates.headLabels.title'), orderName: 'title' },
  { id: '2', label: t('tables.certificates.headLabels.createdAt'), orderName: 'createdAt' },
  { id: '3', label: t('tables.certificates.headLabels.actions') },
];

export const FAQS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.faqs.headLabels.question'), orderName: 'question' },
  { id: '2', label: t('tables.faqs.headLabels.answer'), orderName: 'answer' },
  { id: '3', label: t('tables.faqs.headLabels.createdAt'), orderName: 'createdAt' },
  { id: '4', label: t('tables.faqs.headLabels.actions') },
];

export const BADGES_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.badges.headLabels.badgeImage') },
  { id: '2', label: t('tables.badges.headLabels.badgeName'), orderName: 'programName' },
  { id: '3', label: t('tables.badges.headLabels.createdAt'), orderName: 'createdAt' },
  { id: '4', label: t('tables.badges.headLabels.lastUpdated'), orderName: 'updatedAt' },
  { id: '5', label: t('tables.badges.headLabels.actions') },
];

export const USERS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.users.headLabels.firstName'), orderName: 'firstName' },
  { id: '2', label: t('tables.users.headLabels.lastName'), orderName: 'lastName' },
  { id: '3', label: t('tables.users.headLabels.createdAt'), orderName: 'createdAt' },
  {
    id: '3',
    label: t('tables.users.headLabels.numberOfSessions'),
    orderName: 'user_sessions_aggregate.count',
  },
  {
    id: '3',
    label: t('tables.users.headLabels.numberOfHearingCouncils'),
    orderName: 'users_user_hearing_councils_aggregate.count',
  },
  { id: '4', label: t('tables.users.headLabels.actions') },
];

export const CONTACT_US_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.contactUs.headLabels.name'), orderName: 'name' },
  { id: '2', label: t('tables.contactUs.headLabels.email'), orderName: 'email' },
  { id: '3', label: t('tables.contactUs.headLabels.status'), orderName: 'status' },
  { id: '4', label: t('tables.contactUs.headLabels.problem'), orderName: 'message' },
  { id: '5', label: t('tables.contactUs.headLabels.date'), orderName: 'createdAt' },
  { id: '6', label: t('tables.contactUs.headLabels.actions') },
];

export const FINISHED_COUCNILS_ANALYSIS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  {
    id: '1',
    label: t('tables.finishedHearingCouncilsAnalysis.headLabels.name'),
  },
  {
    id: '2',
    label: t('tables.finishedHearingCouncilsAnalysis.headLabels.email'),
    orderName: 'user.email',
  },
  {
    id: '3',
    label: t('tables.finishedHearingCouncilsAnalysis.headLabels.registeredIn'),
    orderName: 'user.joinedAt',
  },
  {
    id: '4',
    label: t('tables.finishedHearingCouncilsAnalysis.headLabels.attendanceDuration'),
    orderName: 'durationAttendance',
  },
  { id: '5', label: t('tables.finishedHearingCouncilsAnalysis.headLabels.attendanceRate') },
  {
    id: '5',
    label: t('tables.finishedHearingCouncilsAnalysis.headLabels.joinType'),
    orderName: 'numSubmissions',
  },
  { id: '6', label: t('tables.books.headLabels.actions') },
];

export const REFERENCE_BOOK_TABLE_HEAD = (
  t: TFunction<'ns1', undefined>
): { id: string; label: string; orderName?: keyof RefBooksFormType }[] => [
  { id: '1', label: t('tables.books.headLabels.bookAuthor'), orderName: 'bookAuthor' },
  { id: '2', label: t('tables.books.headLabels.bookName'), orderName: 'bookName' },
  { id: 'updatedAt', label: t('tables.books.headLabels.date'), orderName: 'createdAt' },
  { id: '5', label: t('tables.books.headLabels.actions') },
];

export const ROLES_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('tables.roles.headLabels.roleName'), orderName: 'name' },
  { id: '2', label: t('tables.roles.headLabels.users') },
  { id: '3', label: t('tables.roles.headLabels.actions') },
];

export const PERMISSIONS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('dialogs.addNewRole.actionsTable.systemCriteria'), align: 'left' },
  { id: '2', label: t('dialogs.addNewRole.actionsTable.view') },
  { id: '3', label: t('dialogs.addNewRole.actionsTable.create') },
  { id: '4', label: t('dialogs.addNewRole.actionsTable.edit') },
  { id: '5', label: t('dialogs.addNewRole.actionsTable.delete') },
];

export const USERS_STATISTICS_TABLE_HEAD = (t: TFunction<'ns1', undefined>) => [
  { id: '1', label: t('statistics.users.table.avatar') },
  { id: '2', label: t('statistics.users.table.name') },
  { id: '3', label: t('statistics.users.table.email') },
  { id: '4', label: t('statistics.users.table.joined') },
];
