import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import { Socket, io } from 'socket.io-client';
import { SOCKET_API } from 'src/config-global';
import {
  useUpdateHearingCouncilFormMutation,
  useUpdateHearingCouncilMutation,
  useUserHearingCouncilByCouncilIdAndUserIdQuery,
} from 'src/graphql';
import { useAuthContext } from 'src/auth/hooks';
import { ConnectedUser } from 'src/types/hearing-council';
import { useQueryClient } from '@tanstack/react-query';

type StateType = {
  __typename?: 'joinHearingCouncilResponse';
  join_link?: string | null;
  stream_key?: string | null;
  watch_link?: string | null;
};

const useHearingCouncilJoin = () => {
  // #region States and hooks
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const apiRef = useRef<IJitsiMeetExternalApi | null>(null);
  const toggleShare = useBoolean();
  const confirmEndCall = useBoolean();
  const { hearingCouncilId } = useParams();
  const [streamKey, setStreamKey] = useState<string>();
  const { state: link }: { state: StateType | string | null | undefined } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const returnToPath = paths.dashboard.hearingCouncils;
  const router = useNavigate();
  let url: URL | undefined = undefined;
  const [userHearingCouncilId, setUserHearingCouncilId] = useState<string>();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connectedUsers, setConnectedUsers] = useState<ConnectedUser[]>();
  // #endregion States and hooks

  console.log(userHearingCouncilId);

  // #region Services
  const {} = useUserHearingCouncilByCouncilIdAndUserIdQuery(
    {
      hearingCouncilId: hearingCouncilId,
      userId: user?.id,
    },
    {
      onSuccess: (data) => {
        console.log(data);
        if (data.user_hearing_councils.length > 0) {
          setUserHearingCouncilId(data.user_hearing_councils[0].userHearingCouncilId);
        }
      },
    }
  );

  const { mutate } = useUpdateHearingCouncilMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['HearingCouncils'],
      });
    },
  });
  // #endregion

  // #region Handlers
  const handleApiReady = (apiObj: IJitsiMeetExternalApi) => {
    apiRef.current = apiObj;
  };

  const handleHearingCouncilExpiration = () => {
    mutate({
      hearingCouncilId: hearingCouncilId,
      _set: {
        status: 'Expired',
      },
    });
  };

  const handleEndCallForAll = () => {
    socket?.emit('broadcastSessionExpired', {
      event: 'broadcastSessionExpired',
    });
    socket?.disconnect();
    handleHearingCouncilExpiration();
    router(paths.dashboard.hearingCouncils);
  };

  const leaveMeeting = () => {
    socket?.disconnect();
    router(paths.dashboard.hearingCouncils);
  };
  // #endregion Handlers

  // Parse the URL using the URL class
  if (typeof link !== 'string') {
    if ('join_link' in (link || {})) {
      url = new URL(link!?.join_link!);
    }
  } else {
    url = new URL(link);
  }

  // Extract the JWT from the URL parameters
  const jwt = url!?.searchParams.get('jwt');

  // Extract the domain
  const domain = url!?.hostname;

  // #region useEffect
  useEffect(() => {
    if (!link || !domain || !jwt) {
      router(returnToPath);
    } else if (!(typeof link === 'string')) {
      if (!link.join_link || !link.stream_key) {
        setStreamKey(link.stream_key!);
        router(returnToPath);
        enqueueSnackbar({
          variant: 'error',
          message: 'Live streaming is not valid',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        setStreamKey(link.stream_key!);
      }
    } else {
      setStreamKey(link);
    }
  }, [link]);

  // Start youtube live streaming
  useEffect(() => {
    if (streamKey && apiRef.current) {
      apiRef.current.executeCommand('startRecording', {
        mode: 'stream',
        youtubeStreamKey: streamKey,
      });
    }
  }, [toggleShare.value]);

  // Start websocket
  useEffect(() => {
    if (userHearingCouncilId) {
      const socket = io(`${SOCKET_API}/attendance`, {
        query: {
          userHearingCouncilId,
          userId: user?.id,
        },
      });

      // Listen to users connection
      socket.on('connectedUsersList', (data: ConnectedUser[]) => {
        // Filter user to only get the users that are not the current user
        const filteredData = data.filter((connectedUser) => connectedUser.id !== user?.id);
        setConnectedUsers(filteredData);
      });

      setSocket(socket);
    }
    return () => {
      socket?.disconnect();
      socket?.off('connectedUsersList');
    };
  }, [userHearingCouncilId]);
  // #endregion useEffect

  return {
    link,
    t,
    hearingCouncilId,
    jwt,
    domain,
    router,
    streamKey,
    returnToPath,
    apiRef,
    toggleShare,
    handleApiReady,
    handleEndCallForAll,
    leaveMeeting,
    connectedUsers,
    confirmEndCall,
  };
};

export default useHearingCouncilJoin;
