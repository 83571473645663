import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';

type StateType = {
  __typename?: 'joinHearingCouncilResponse';
  join_link?: string | null;
  stream_key?: string | null;
  watch_link?: string | null;
};

const useSessionJoin = () => {
  // #region States and hooks
  const apiRef = useRef<IJitsiMeetExternalApi | null>(null);

  const { sessionId } = useParams();
  const [streamKey, setStreamKey] = useState<string>();
  const { state: link }: { state: StateType | string | null | undefined } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const returnToPath = paths.dashboard.sessions;
  const router = useNavigate();
  let url: URL | undefined = undefined;
  // #endregion States and hooks

  // #region Handlers
  const handleApiReady = (apiObj: IJitsiMeetExternalApi) => {
    apiRef.current = apiObj;
  };
  // #endregion Handlers

  // Parse the URL using the URL class
  if (typeof link !== 'string') {
    if ('join_link' in (link || {})) {
      url = new URL(link!?.join_link!);
    }
  } else {
    url = new URL(link);
  }

  // Extract the JWT from the URL parameters
  const jwt = url!?.searchParams.get('jwt');

  // Extract the domain
  const domain = url!?.hostname;

  // #region useEffect
  useEffect(() => {
    if (!link || !domain || !jwt) {
      router(returnToPath);
    } else if (!(typeof link === 'string')) {
      if (!link.join_link || !link.stream_key) {
        setStreamKey(link.stream_key!);
        router(returnToPath);
        enqueueSnackbar({
          variant: 'error',
          message: 'Live streaming is not valid',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else {
        setStreamKey(link.stream_key!);
      }
    } else {
      setStreamKey(link);
    }
  }, [link]);
  // #endregion useEffect

  return {
    link,
    t,
    sessionId,
    jwt,
    domain,
    router,
    streamKey,
    returnToPath,
    apiRef,
    handleApiReady,
  };
};

export default useSessionJoin;
