import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import {
  useAddBadgeMutation,
  useEditBadgeMutation,
  useOneBadgeQuery
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import {
  AddGeneralBadgeVariablesType,
  BadgesDtoType
} from 'src/types/badges';

const useAddNewGeneralBadge = () => {
  // #region States
  const confirm = useBoolean();
  const { AddGeneralBadgeSchema } = useSchemas();
  const { badgeId: badgeIdParam } = useParams();
  const queryClient = useQueryClient();
  const { t, allLangs, currentLang } = useLocales();
  const router = useNavigate();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const [file, setFile] = useState<File | string | null>(null);
  const [badgeId, setBadgeId] = useState<string | undefined>(badgeIdParam);
  const { state }: { state: { badge: BadgesDtoType | undefined } | undefined } = useLocation();
  const isEditing = !!badgeIdParam;
  const { usePostApi } = useApiServices();
  const [dataToSend, setDataToSend] = useState<AddGeneralBadgeVariablesType>();
  // #endregion States

  const BADGE_TYPE_DEFAULT = state?.badge?.categoryId
    ? 'category'
    : state?.badge?.mutoonId
    ? 'matn'
    : 'hearingCouncil';

  let defaultValues: AddGeneralBadgeVariablesType = {
    programName: state?.badge?.programName || {},
    badgeImageURL: state?.badge?.badgeImageURL || '',
    generalType: state?.badge?.generalType || '',
    count: state?.badge?.count || 0,
    categoryId: null,
    hearingCouncilId: null,
    mutoonId: null,
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state?.badge; i++) {
    defaultValues.programName[allLangs[i].value] = '';
  }

  const methods = useForm<AddGeneralBadgeVariablesType>({
    defaultValues,
    resolver: yupResolver<AddGeneralBadgeVariablesType>(AddGeneralBadgeSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddGeneralBadgeVariablesType) => {
      const newData = { ...data };
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  // Queries
  const { isFetching: isLoadingBadge } = useOneBadgeQuery(
    {
      id: badgeIdParam!,
    },
    {
      enabled: !state?.badge && !!badgeIdParam,
      onSuccess: (data) => {
        {
          methods.setValue('programName', data.badges_by_pk!?.programName);
          methods.setValue('badgeImageURL', data.badges_by_pk?.badgeImageURL!);
          methods.setValue('generalType', data.badges_by_pk?.generalType!);
        }
      },
    }
  );

  // Mutations
  const {
    mutate: addBadge,
    isLoading: isAdding,
    isSuccess: hasAddedBadge,
  } = useAddBadgeMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      setBadgeId(data.insert_badges_one?.badgeId);
    },
  });

  const { mutate: editBadge, isLoading: isUpdating } = useEditBadgeMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['Badges'] });
      router(paths.dashboard.faqs);
    },
  });

  const {
    mutate: uploadBadgeImg,
    isLoading: isUploadingBadgeImg,
    data,
  } = usePostApi({
    url: API_URLS.UPLOAD_BADGE_IMAGE(badgeId ?? ''),
    withFormData: true,
    onSuccess: (data: any) => {
      methods.setValue('badgeImageURL', data?.avatar);
      !badgeIdParam && router(paths.dashboard.badges.generalBadges);
    },
  });

  const mutate = isEditing
    ? () =>
        editBadge({
          ...dataToSend,
          id: badgeIdParam,
        })
    : () =>
        addBadge({
          ...dataToSend,
        });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
      methods.setValue('badgeImageURL', URL.createObjectURL(newFile));
    }
  }, []);

  const handleDeleteBadgeImg = (badgeId: string) => {
    editBadge({
      id: badgeId,
      ...methods.watch(),
      badgeType: undefined,
      hearingCouncilName: undefined,
      categoryName: undefined,
      mutoonName: undefined,
      badgeImageURL: '',
    } as any);
  };
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `programName.${currentTabValue}`,
      methods.watch().programName!?.[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);

  useEffect(() => {
    if (file && (hasAddedBadge || state?.badge || badgeId)) uploadBadgeImg({ file: file });
  }, [file, hasAddedBadge]);
  // #endregion useEffect

  return {
    isLoadingBadge,
    methods,
    handleSubmit,
    onSubmit,
    badgeId,
    currentTabValue,
    file,
    setFile,
    mutate,
    badgeIdParam,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    langsWithErrors,
    // Handlers
    handleChangeTab,
    handleDeleteBadgeImg,
    handleDropSingleFile,
    // Services
    uploadBadgeImg,
    addBadge,
    editBadge,
    isUploadingBadgeImg,
  };
};

export default useAddNewGeneralBadge;
