import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import {
  useDeleteSoundMutation,
  useDeleteTranslationMutation,
  useSoundsByMatnSectionIdQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import AddNewCategoryDialog from 'src/sections/dialogs/add/AddNewCategoryDialog';
import { MatnSectionDiffWordStateType } from 'src/types/matn-section-diff-words';
import MatnSectionSoundsRow from './matn-section-sounds-row';
import { SOUND_TABLE_HEAD } from 'src/constants/tables-heads';

const MatnSectionSounds = () => {
  // #region States and hooks
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const addDialog = useBoolean();
  const queryClient = useQueryClient();
  const { categoryId, levelId, matnId, matnSectionId } = useParams();
  const { state }: { state: MatnSectionDiffWordStateType | undefined } = useLocation();
  const methods = useForm();
  const { t, allLangs, currentLang } = useLocales();
  const [search, setSearch] = useState<any>();

  const categoryName = state ? state.category?.name[currentLang.value] : 'Levels';
  const levelName = state ? state.level.name[currentLang.value] : 'Mutoon';
  const matnName = state ? state.matn.name[currentLang.value] : 'Matn';
  const matnSectionName = state ? state.matn.name[currentLang.value] : 'Matn section';
  // #endregion States and hooks

  // #region Services
  const { data, isLoading } = useSoundsByMatnSectionIdQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    matnSectionId,
  });

  const { mutate, isLoading: isDeleting } = useDeleteSoundMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['SoundsByMatnSectionId'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteMatnSection = (soundId: string) => {
    mutate({ soundId });
  };
  // #endregion Handlers

  return (
    <Container>
      <Helmet>
        <title>{t('tables.matnSections.sounds')}</title>
      </Helmet>
      <CustomBreadcrumbs
        links={[
          {
            name: t('breadcrumbs.mutoon'),
          },
          {
            name: t('breadcrumbs.categories'),
            href: paths.dashboard.mutoon.categories,
          },
          {
            name: categoryName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}`,
          },
          {
            name: levelName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}`,
          },
          {
            name: matnName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}/${matnId}`,
          },
          {
            name: matnSectionName,
          },
        ]}
      />
      <Card>
        <FormProvider methods={methods}>
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom headLabel={SOUND_TABLE_HEAD(t)} />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.sounds?.map((row) => (
                        <MatnSectionSoundsRow
                          key={row.soundId}
                          row={row}
                          deleteHandler={handleDeleteMatnSection}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.sounds_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
        <AddNewCategoryDialog open={addDialog.value} onClose={addDialog.onFalse} />
      </Card>
    </Container>
  );
};

export default MatnSectionSounds;
