import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchData } from 'src/api/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CertNodeType: { input: any; output: any; }
  ContentType: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DonationType: { input: any; output: any; }
  DynamicVariableType: { input: any; output: any; }
  IntervalType: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  MeetingType: { input: any; output: any; }
  PlanType: { input: any; output: any; }
  QuestionType: { input: any; output: any; }
  TransactionStatus: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  generalBadgeType: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  userRole: { input: any; output: any; }
};

export type Achievement = {
  __typename?: 'Achievement';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dailyRose?: Maybe<Scalars['Int']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  intervalType?: Maybe<Scalars['String']['output']>;
  remainingSections?: Maybe<Scalars['Int']['output']>;
  savedSections?: Maybe<Scalars['Int']['output']>;
  specificHour?: Maybe<Scalars['Int']['output']>;
};

export type Admin = {
  __typename?: 'Admin';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<AdminRole>;
  roleId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userSessions?: Maybe<Array<UserHearingCouncil>>;
  username: Scalars['String']['output'];
};

export type AdminAuthResponse = {
  __typename?: 'AdminAuthResponse';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Admin>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type Answer = {
  __typename?: 'Answer';
  /** Id of the answer */
  answerId: Scalars['ID']['output'];
  /** Text of the answer */
  answerText: Scalars['JSONObject']['output'];
  /** Creation timestamp of the answer */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Question associated with the answer */
  question?: Maybe<Question>;
  /** ID of the associated question */
  questionId: Scalars['String']['output'];
  /** Last update timestamp of the answer */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnswerInput = {
  answerId?: InputMaybe<Scalars['ID']['input']>;
  answerIndex?: InputMaybe<Scalars['Int']['input']>;
  answerString?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
};

export type AppleSigninInput = {
  authorizationCode: Scalars['String']['input'];
  credential: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  isRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type Badge = {
  __typename?: 'Badge';
  UserBadge?: Maybe<UserBadge>;
  badgeId?: Maybe<Scalars['ID']['output']>;
  badgeImageURL?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  generalType?: Maybe<Scalars['String']['output']>;
  hearingCouncil?: Maybe<HearingCouncil>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  mutoon?: Maybe<Mutoon>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  programName?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BadgeDayStreaks = {
  __typename?: 'BadgeDayStreaks';
  /** badge object that user earned for the day. */
  Badge?: Maybe<Badge>;
  /** Number of days user has been active. */
  dayStreaks?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Category = {
  __typename?: 'Category';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['JSONObject']['output']>;
  mutoonCategory?: Maybe<Array<MutoonCategory>>;
  name?: Maybe<Scalars['JSONObject']['output']>;
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['String']['output']>;
  subcategories?: Maybe<Array<Category>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Boolean expression to compare columns of type "CertNodeType". All fields are combined with logical 'AND'. */
export type CertNodeType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['CertNodeType']['input']>;
  _gt?: InputMaybe<Scalars['CertNodeType']['input']>;
  _gte?: InputMaybe<Scalars['CertNodeType']['input']>;
  _in?: InputMaybe<Array<Scalars['CertNodeType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['CertNodeType']['input']>;
  _lte?: InputMaybe<Scalars['CertNodeType']['input']>;
  _neq?: InputMaybe<Scalars['CertNodeType']['input']>;
  _nin?: InputMaybe<Array<Scalars['CertNodeType']['input']>>;
};

export type Certificate = {
  __typename?: 'Certificate';
  body?: Maybe<Scalars['String']['output']>;
  bodyFontColor?: Maybe<Scalars['String']['output']>;
  bodyFontSize?: Maybe<Scalars['Float']['output']>;
  bodyMaxLength?: Maybe<Scalars['Float']['output']>;
  bodyXPosition?: Maybe<Scalars['Float']['output']>;
  bodyYPosition?: Maybe<Scalars['Float']['output']>;
  certificateId: Scalars['ID']['output'];
  certificateNumber?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dateFontColor?: Maybe<Scalars['String']['output']>;
  dateFontSize?: Maybe<Scalars['Float']['output']>;
  dateXPosition?: Maybe<Scalars['Float']['output']>;
  dateYPosition?: Maybe<Scalars['Float']['output']>;
  exam?: Maybe<Exam>;
  image?: Maybe<Scalars['String']['output']>;
  mutoon?: Maybe<Mutoon>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  numberFontColor?: Maybe<Scalars['String']['output']>;
  numberFontSize?: Maybe<Scalars['Float']['output']>;
  numberXPosition?: Maybe<Scalars['Float']['output']>;
  numberYPosition?: Maybe<Scalars['Float']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  role1FontColor?: Maybe<Scalars['String']['output']>;
  role1FontSize?: Maybe<Scalars['Float']['output']>;
  role1Signature?: Maybe<Scalars['String']['output']>;
  role1Title?: Maybe<Scalars['String']['output']>;
  role1XPosition?: Maybe<Scalars['Float']['output']>;
  role1YPosition?: Maybe<Scalars['Float']['output']>;
  role2FontColor?: Maybe<Scalars['String']['output']>;
  role2FontSize?: Maybe<Scalars['Float']['output']>;
  role2Title?: Maybe<Scalars['String']['output']>;
  role2XPosition?: Maybe<Scalars['Float']['output']>;
  role2YPosition?: Maybe<Scalars['Float']['output']>;
  showDate?: Maybe<Scalars['String']['output']>;
  showNumber?: Maybe<Scalars['String']['output']>;
  showStudentName?: Maybe<Scalars['String']['output']>;
  studentNameFontColor?: Maybe<Scalars['String']['output']>;
  studentNameFontSize?: Maybe<Scalars['Float']['output']>;
  studentNameXPosition?: Maybe<Scalars['Float']['output']>;
  studentNameYPosition?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleFontColor?: Maybe<Scalars['String']['output']>;
  titleFontSize?: Maybe<Scalars['Float']['output']>;
  titleXPosition?: Maybe<Scalars['Float']['output']>;
  titleYPosition?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userCertificates?: Maybe<Array<UserCertificate>>;
};

export type Contact = {
  __typename?: 'Contact';
  contactId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "ContentType". All fields are combined with logical 'AND'. */
export type ContentType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ContentType']['input']>;
  _gt?: InputMaybe<Scalars['ContentType']['input']>;
  _gte?: InputMaybe<Scalars['ContentType']['input']>;
  _in?: InputMaybe<Array<Scalars['ContentType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['ContentType']['input']>;
  _lte?: InputMaybe<Scalars['ContentType']['input']>;
  _neq?: InputMaybe<Scalars['ContentType']['input']>;
  _nin?: InputMaybe<Array<Scalars['ContentType']['input']>>;
};

export type CreateAdminInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  status: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateGeneralMessageNotificationsInput = {
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type CreateHearingCouncilInput = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  autoCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  certificateHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Scalars['JSONObject']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  prerequisiteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  sessionName: Scalars['JSONObject']['input'];
  sheikhName?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSessionInput = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['JSONObject']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  prerequisiteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['JSONObject']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['Float']['input']>;
  isPreferencesChoosen: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "DonationType". All fields are combined with logical 'AND'. */
export type DonationType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DonationType']['input']>;
  _gt?: InputMaybe<Scalars['DonationType']['input']>;
  _gte?: InputMaybe<Scalars['DonationType']['input']>;
  _in?: InputMaybe<Array<Scalars['DonationType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DonationType']['input']>;
  _lte?: InputMaybe<Scalars['DonationType']['input']>;
  _neq?: InputMaybe<Scalars['DonationType']['input']>;
  _nin?: InputMaybe<Array<Scalars['DonationType']['input']>>;
};

/** Boolean expression to compare columns of type "DynamicVariableType". All fields are combined with logical 'AND'. */
export type DynamicVariableType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  _gt?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  _gte?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  _in?: InputMaybe<Array<Scalars['DynamicVariableType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  _lte?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  _neq?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  _nin?: InputMaybe<Array<Scalars['DynamicVariableType']['input']>>;
};

export type Exam = {
  __typename?: 'Exam';
  /** Certificate associated with the exam */
  certificate?: Maybe<Certificate>;
  /** Id of the certificate associated with the exam */
  certificateexamId?: Maybe<Scalars['ID']['output']>;
  /** Creation timestamp of the exam */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the exam */
  description?: Maybe<Scalars['JSONObject']['output']>;
  /** Duration of the exam in minutes */
  duration: Scalars['Int']['output'];
  /** Id of the exam */
  examId: Scalars['ID']['output'];
  /** Grade A threshold */
  gradeA: Scalars['Int']['output'];
  /** Grade B threshold */
  gradeB: Scalars['Int']['output'];
  /** Grade C threshold */
  gradeC: Scalars['Int']['output'];
  /** Grade D threshold */
  gradeD: Scalars['Int']['output'];
  /** Grade F threshold */
  gradeF: Scalars['Int']['output'];
  /** Id of the hearing council associated with the exam */
  hearingCouncilHearingCouncilId?: Maybe<Scalars['ID']['output']>;
  /** Maximum number of attempts allowed for the exam */
  maxAttempts: Scalars['Int']['output'];
  /** Minimum score required to pass the exam */
  minimumScore: Scalars['Int']['output'];
  /** Id of the mutoon associated with the exam */
  mutonId?: Maybe<Scalars['ID']['output']>;
  /** Mutoon associated with the exam */
  mutoon?: Maybe<Mutoon>;
  /** List of questions associated with the exam */
  questions?: Maybe<Array<Question>>;
  /** Status of the exam (active or inactive) */
  status?: Maybe<Scalars['String']['output']>;
  /** Answers of the exam */
  title?: Maybe<Scalars['JSONObject']['output']>;
  /** Last update timestamp of the exam */
  updatedAt: Scalars['DateTime']['output'];
  /** List of exam submissions associated with the exam */
  userExams?: Maybe<Array<ExamSubmission>>;
};

export type ExamSubmission = {
  __typename?: 'ExamSubmission';
  answersCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** End time */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** Exam Object */
  exam?: Maybe<Exam>;
  /** Exam id */
  examId: Scalars['ID']['output'];
  questionsCount?: Maybe<Scalars['Int']['output']>;
  /**  grade Alphabet A,B,C,D,E,F */
  result: Scalars['String']['output'];
  /** Precentage number % of the exam */
  score: Scalars['Int']['output'];
  /** Start time */
  startTime?: Maybe<Scalars['DateTime']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User Object */
  user?: Maybe<User>;
  userCertificate?: Maybe<UserCertificate>;
  userCertificateId?: Maybe<Scalars['ID']['output']>;
  /** Id of the exam submission */
  userExamId: Scalars['ID']['output'];
  /** User id */
  userId: Scalars['ID']['output'];
};

export type ExamSubmissionResponse = {
  __typename?: 'ExamSubmissionResponse';
  answersCount?: Maybe<Scalars['Int']['output']>;
  examSubmission?: Maybe<ExamSubmission>;
  issuedCertificate?: Maybe<UserCertificate>;
  questionsCount?: Maybe<Scalars['Int']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
  userQuizRecords?: Maybe<Array<UserQuiz>>;
};

export type FetchUsersArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ForgetPasswordInput = {
  email: Scalars['String']['input'];
};

export type GoogleSignInInput = {
  credential: Scalars['String']['input'];
};

export type HearingCouncil = {
  __typename?: 'HearingCouncil';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['JSONObject']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  enable_conversation?: Maybe<Scalars['Boolean']['output']>;
  enable_raise_hand?: Maybe<Scalars['Boolean']['output']>;
  enable_recording?: Maybe<Scalars['Boolean']['output']>;
  enable_upload_files?: Maybe<Scalars['Boolean']['output']>;
  enable_video?: Maybe<Scalars['Boolean']['output']>;
  exam?: Maybe<Exam>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  hearingCouncilId: Scalars['ID']['output'];
  max_user_count?: Maybe<Scalars['Int']['output']>;
  meeting?: Maybe<Array<Meeting>>;
  mutoon?: Maybe<Mutoon>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  owners?: Maybe<Array<UserOwner>>;
  prerequisites?: Maybe<Array<Prerequisite>>;
  price?: Maybe<Scalars['Float']['output']>;
  referenceBook?: Maybe<ReferenceBook>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  sessionName?: Maybe<Scalars['JSONObject']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userHearingCouncil?: Maybe<Array<UserHearingCouncil>>;
  userRelation?: Maybe<UserHearingCouncil>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "IntervalType". All fields are combined with logical 'AND'. */
export type IntervalType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['IntervalType']['input']>;
  _gt?: InputMaybe<Scalars['IntervalType']['input']>;
  _gte?: InputMaybe<Scalars['IntervalType']['input']>;
  _in?: InputMaybe<Array<Scalars['IntervalType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['IntervalType']['input']>;
  _lte?: InputMaybe<Scalars['IntervalType']['input']>;
  _neq?: InputMaybe<Scalars['IntervalType']['input']>;
  _nin?: InputMaybe<Array<Scalars['IntervalType']['input']>>;
};

export type Level = {
  __typename?: 'Level';
  categoryId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['JSONObject']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['JSONObject']['output']>;
};

export type LoginAdminInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginUserInput = {
  email: Scalars['String']['input'];
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type Meeting = {
  __typename?: 'Meeting';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  moderatorId?: Maybe<Scalars['String']['output']>;
  notbefore: Scalars['DateTime']['output'];
  roomId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Boolean expression to compare columns of type "MeetingType". All fields are combined with logical 'AND'. */
export type MeetingType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['MeetingType']['input']>;
  _gt?: InputMaybe<Scalars['MeetingType']['input']>;
  _gte?: InputMaybe<Scalars['MeetingType']['input']>;
  _in?: InputMaybe<Array<Scalars['MeetingType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['MeetingType']['input']>;
  _lte?: InputMaybe<Scalars['MeetingType']['input']>;
  _neq?: InputMaybe<Scalars['MeetingType']['input']>;
  _nin?: InputMaybe<Array<Scalars['MeetingType']['input']>>;
};

export type Mutoon = {
  __typename?: 'Mutoon';
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  certificate?: Maybe<Array<Certificate>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['JSONObject']['output']>;
  discussionRoomRoomID?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Array<Exam>>;
  hearingCouncils?: Maybe<Array<HearingCouncil>>;
  id: Scalars['String']['output'];
  level?: Maybe<Level>;
  levelId?: Maybe<Scalars['String']['output']>;
  mutoonCategory?: Maybe<Array<MutoonCategory>>;
  mutoonSections?: Maybe<Array<MutoonSection>>;
  mutoonType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['JSONObject']['output']>;
  numberOfHadith?: Maybe<Scalars['Int']['output']>;
  plans?: Maybe<Array<Plan>>;
  referenceBook?: Maybe<ReferenceBook>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  scientificContent?: Maybe<Scalars['JSONObject']['output']>;
  sessions?: Maybe<Array<Session>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MutoonCategory = {
  __typename?: 'MutoonCategory';
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoon?: Maybe<Mutoon>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  userCertificate?: Maybe<UserCertificate>;
};

export type MutoonSection = {
  __typename?: 'MutoonSection';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['JSONObject']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['JSONObject']['output']>;
  note?: Maybe<Array<Note>>;
  planSection?: Maybe<PlanSection>;
  planSections?: Maybe<Array<PlanSection>>;
  questions?: Maybe<Array<Question>>;
  sounds?: Maybe<Array<Sound>>;
  translations?: Maybe<Array<Translation>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userQuiz?: Maybe<UserQuiz>;
  userRecord?: Maybe<Array<UserRecord>>;
};

export type MutoonSectionAndPlanSection = {
  __typename?: 'MutoonSectionAndPlanSection';
  mutoonSection?: Maybe<MutoonSection>;
  plan?: Maybe<Plan>;
  planSection?: Maybe<PlanSection>;
};

export type Note = {
  __typename?: 'Note';
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  noteId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PaginatedBadges = {
  __typename?: 'PaginatedBadges';
  badges?: Maybe<Array<Badge>>;
  meta: PagingMeta;
};

export type PaginatedNotifications = PaginatedResult & {
  __typename?: 'PaginatedNotifications';
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PaginatedResult = {
  data: Array<Scalars['JSON']['output']>;
  meta: PagingMeta;
};

export type PagingArgs = {
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
};

export type PagingMeta = {
  __typename?: 'PagingMeta';
  currentPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  next?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
  prev?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  systemCriteria: Scalars['String']['output'];
};

/** The Plan model */
export type Plan = {
  __typename?: 'Plan';
  check?: Maybe<Scalars['Int']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  interval?: Maybe<Scalars['String']['output']>;
  mutoon?: Maybe<Mutoon>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  offline?: Maybe<Scalars['Boolean']['output']>;
  offlinePlanFile?: Maybe<Scalars['String']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  planSectionSections?: Maybe<Array<PlanSection>>;
  progress?: Maybe<Scalars['Float']['output']>;
  savedSectionsCount?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  subscribe?: Maybe<Scalars['Boolean']['output']>;
  totalNotMarkedDoneSections?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type PlanSection = {
  __typename?: 'PlanSection';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  markAsDone?: Maybe<Scalars['Boolean']['output']>;
  plan?: Maybe<Plan>;
  planPlanId?: Maybe<Scalars['String']['output']>;
  planSectionId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userRecordId?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "PlanType". All fields are combined with logical 'AND'. */
export type PlanType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['PlanType']['input']>;
  _gt?: InputMaybe<Scalars['PlanType']['input']>;
  _gte?: InputMaybe<Scalars['PlanType']['input']>;
  _in?: InputMaybe<Array<Scalars['PlanType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['PlanType']['input']>;
  _lte?: InputMaybe<Scalars['PlanType']['input']>;
  _neq?: InputMaybe<Scalars['PlanType']['input']>;
  _nin?: InputMaybe<Array<Scalars['PlanType']['input']>>;
};

export type Prerequisite = {
  __typename?: 'Prerequisite';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hearingCouncil?: Maybe<HearingCouncil>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mutoon: Mutoon;
  mutoonMatnId: Scalars['String']['output'];
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Question = {
  __typename?: 'Question';
  /** List of answers associated with the question */
  answers?: Maybe<Array<Answer>>;
  /** Options of the choices */
  choicesOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** Type of the choices (single or multiple) */
  choicesType?: Maybe<Scalars['String']['output']>;
  /** Id of the correct answer */
  correctAnswerId?: Maybe<Scalars['ID']['output']>;
  /** Index of the correct answer */
  correctAnswerIndex?: Maybe<Scalars['Int']['output']>;
  /** Text of the correct answer */
  correctAnswerText?: Maybe<Scalars['JSONObject']['output']>;
  /** Creation timestamp of the question */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Text of the correct answer */
  explanation?: Maybe<Scalars['JSONObject']['output']>;
  /** Text of the correct answer */
  hint?: Maybe<Scalars['JSONObject']['output']>;
  /** Is the question a quiz */
  isQuiz: Scalars['Boolean']['output'];
  mutoonSection?: Maybe<MutoonSection>;
  /** Id of the section the question belongs to */
  mutoonSectionId?: Maybe<Scalars['ID']['output']>;
  /** Points assigned to the question */
  points: Scalars['Int']['output'];
  /** Id of the question */
  questionId: Scalars['ID']['output'];
  /** Text of the question */
  questionText: Scalars['JSONObject']['output'];
  /** Type of the question (choice or written) */
  type: Scalars['String']['output'];
  /** Last update timestamp of the question */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Boolean expression to compare columns of type "QuestionType". All fields are combined with logical 'AND'. */
export type QuestionType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['QuestionType']['input']>;
  _gt?: InputMaybe<Scalars['QuestionType']['input']>;
  _gte?: InputMaybe<Scalars['QuestionType']['input']>;
  _in?: InputMaybe<Array<Scalars['QuestionType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['QuestionType']['input']>;
  _lte?: InputMaybe<Scalars['QuestionType']['input']>;
  _neq?: InputMaybe<Scalars['QuestionType']['input']>;
  _nin?: InputMaybe<Array<Scalars['QuestionType']['input']>>;
};

export type QuizSubmissionResponse = {
  __typename?: 'QuizSubmissionResponse';
  answersCount?: Maybe<Scalars['Int']['output']>;
  questionsCount?: Maybe<Scalars['Int']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
  userQuizRecords?: Maybe<Array<UserQuiz>>;
};

export type ReferenceBook = {
  __typename?: 'ReferenceBook';
  bookAuthor?: Maybe<Scalars['JSONObject']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  bookLanguage?: Maybe<Scalars['JSONObject']['output']>;
  bookName?: Maybe<Scalars['JSONObject']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfBorn?: Maybe<Scalars['DateTime']['output']>;
  dateOfDeath?: Maybe<Scalars['DateTime']['output']>;
  fromSchool?: Maybe<Scalars['JSONObject']['output']>;
  nazemName?: Maybe<Scalars['JSONObject']['output']>;
  numberOfLines?: Maybe<Scalars['Int']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
};

export type SendEmailToContactInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
};

export type SendNotificationsResponse = {
  __typename?: 'SendNotificationsResponse';
  failureCount: Scalars['Int']['output'];
  successCount: Scalars['Int']['output'];
};

export type SendVerificationCodeInput = {
  token: Scalars['String']['input'];
};

export type Session = {
  __typename?: 'Session';
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['JSONObject']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  enable_conversation?: Maybe<Scalars['Boolean']['output']>;
  enable_raise_hand?: Maybe<Scalars['Boolean']['output']>;
  enable_recording?: Maybe<Scalars['Boolean']['output']>;
  enable_upload_files?: Maybe<Scalars['Boolean']['output']>;
  enable_video?: Maybe<Scalars['Boolean']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  max_user_count?: Maybe<Scalars['Int']['output']>;
  meeting?: Maybe<Array<Meeting>>;
  mutoon?: Maybe<Mutoon>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  prerequisites?: Maybe<Array<Prerequisite>>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['JSONObject']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userRelation?: Maybe<UserSession>;
  userSession?: Maybe<Array<UserSession>>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  verificationToken?: Maybe<Scalars['String']['output']>;
};

export type Sound = {
  __typename?: 'Sound';
  description?: Maybe<Scalars['JSONObject']['output']>;
  end?: Maybe<Scalars['Float']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['JSONObject']['output']>;
  soundId?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "TransactionStatus". All fields are combined with logical 'AND'. */
export type TransactionStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['TransactionStatus']['input']>;
  _gt?: InputMaybe<Scalars['TransactionStatus']['input']>;
  _gte?: InputMaybe<Scalars['TransactionStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['TransactionStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['TransactionStatus']['input']>;
  _lte?: InputMaybe<Scalars['TransactionStatus']['input']>;
  _neq?: InputMaybe<Scalars['TransactionStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['TransactionStatus']['input']>>;
};

export type Translation = {
  __typename?: 'Translation';
  mutoonSectionId: Scalars['String']['output'];
  translatedText?: Maybe<Scalars['JSONObject']['output']>;
  translationId?: Maybe<Scalars['String']['output']>;
  word?: Maybe<Scalars['JSONObject']['output']>;
};

export type UpdateAdminInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSessionInput = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['JSONObject']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  prerequisiteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['JSONObject']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  Note?: Maybe<Array<Note>>;
  Type?: Maybe<Scalars['String']['output']>;
  UserRecord?: Maybe<Array<UserRecord>>;
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  contributionCount?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isPreferencesChoosen: Scalars['Boolean']['output'];
  joinHearingCouncil?: Maybe<Scalars['Int']['output']>;
  joinSessionCount?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastStreak?: Maybe<Scalars['DateTime']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sharesCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  streaks?: Maybe<Scalars['Int']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type UserAchievement = {
  __typename?: 'UserAchievement';
  duration?: Maybe<Scalars['String']['output']>;
  hadith?: Maybe<Scalars['Float']['output']>;
  mutoon?: Maybe<Scalars['Float']['output']>;
  poem?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['Float']['output']>;
};

export type UserAuthResponse = {
  __typename?: 'UserAuthResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  verificationToken?: Maybe<Scalars['String']['output']>;
};

export type UserBadge = {
  __typename?: 'UserBadge';
  badge?: Maybe<Badge>;
  badgeId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateEarned?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isClaimed?: Maybe<Scalars['Boolean']['output']>;
  isEarned?: Maybe<Scalars['Boolean']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserCertificate = {
  __typename?: 'UserCertificate';
  certificate?: Maybe<Certificate>;
  certificateId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customName?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  downloaded?: Maybe<Scalars['Boolean']['output']>;
  examSubmission?: Maybe<ExamSubmission>;
  examSubmissionId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mutoon?: Maybe<Mutoon>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userHearingCouncilId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  usrHearingCouncil?: Maybe<UserHearingCouncil>;
};

export type UserCertificatesWithMutoonCategories = {
  __typename?: 'UserCertificatesWithMutoonCategories';
  categories?: Maybe<Array<Category>>;
  mutoonCategories?: Maybe<Array<MutoonCategory>>;
};

export type UserHearingCouncil = {
  __typename?: 'UserHearingCouncil';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']['output']>;
  durationAttendance?: Maybe<Scalars['Int']['output']>;
  hearingCouncil?: Maybe<HearingCouncil>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  lastActionTimestamp?: Maybe<Scalars['DateTime']['output']>;
  leftAt?: Maybe<Scalars['DateTime']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  numSubmissions?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriped?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
  userCertificate?: Maybe<UserCertificate>;
  userCertificateId?: Maybe<Scalars['ID']['output']>;
  userHearingCouncilId: Scalars['ID']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserOwner = {
  __typename?: 'UserOwner';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserQuiz = {
  __typename?: 'UserQuiz';
  answer?: Maybe<Answer>;
  /** Answer id */
  answerId?: Maybe<Scalars['ID']['output']>;
  /** Answer index */
  answerIndex?: Maybe<Scalars['Int']['output']>;
  /** Answer string */
  answerString?: Maybe<Scalars['String']['output']>;
  /** Creation timestamp of the user quiz */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Deletion timestamp of the user quiz */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Flag indicating whether the answer is correct */
  isAnswerCorrect: Scalars['Boolean']['output'];
  mutoonSection?: Maybe<MutoonSection>;
  /** Mutoon section id */
  mutoonSectionId?: Maybe<Scalars['ID']['output']>;
  question?: Maybe<Question>;
  /** Question id */
  questionId: Scalars['ID']['output'];
  user?: Maybe<User>;
  /** User id */
  userId: Scalars['ID']['output'];
  /** Id of the user quiz */
  userQuizId: Scalars['ID']['output'];
  /** Flag indicating whether weaker answers should be hidden */
  weaknessPoint: Scalars['Boolean']['output'];
};

export type UserRecord = {
  __typename?: 'UserRecord';
  adminMessage?: Maybe<Scalars['String']['output']>;
  audioRecordUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  planSections?: Maybe<Array<PlanSection>>;
  recordingId?: Maybe<Scalars['ID']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserSession = {
  __typename?: 'UserSession';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  session?: Maybe<Session>;
  sessionId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  subscriped?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSharesResponse = {
  __typename?: 'UserSharesResponse';
  Badge?: Maybe<Badge>;
  numberOfShares?: Maybe<Scalars['Int']['output']>;
};

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** columns and relationships of "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction = {
  __typename?: '_DonationHistoryToTransaction';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
};

/** aggregated selection of "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Aggregate = {
  __typename?: '_DonationHistoryToTransaction_aggregate';
  aggregate?: Maybe<_DonationHistoryToTransaction_Aggregate_Fields>;
  nodes: Array<_DonationHistoryToTransaction>;
};

/** aggregate fields of "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Aggregate_Fields = {
  __typename?: '_DonationHistoryToTransaction_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_DonationHistoryToTransaction_Max_Fields>;
  min?: Maybe<_DonationHistoryToTransaction_Min_Fields>;
};


/** aggregate fields of "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_DonationHistoryToTransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_DonationHistoryToTransaction". All fields are combined with a logical 'AND'. */
export type _DonationHistoryToTransaction_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<_DonationHistoryToTransaction_Bool_Exp>>;
  _not?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
  _or?: InputMaybe<Array<_DonationHistoryToTransaction_Bool_Exp>>;
};

/** unique or primary key constraints on table "_DonationHistoryToTransaction" */
export enum _DonationHistoryToTransaction_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  DonationHistoryToTransactionAbUnique = '_DonationHistoryToTransaction_AB_unique'
}

/** input type for inserting data into table "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type _DonationHistoryToTransaction_Max_Fields = {
  __typename?: '_DonationHistoryToTransaction_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type _DonationHistoryToTransaction_Min_Fields = {
  __typename?: '_DonationHistoryToTransaction_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Mutation_Response = {
  __typename?: '_DonationHistoryToTransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_DonationHistoryToTransaction>;
};

/** on_conflict condition type for table "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_On_Conflict = {
  constraint: _DonationHistoryToTransaction_Constraint;
  update_columns?: Array<_DonationHistoryToTransaction_Update_Column>;
  where?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
};

/** Ordering options when selecting data from "_DonationHistoryToTransaction". */
export type _DonationHistoryToTransaction_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** select columns of table "_DonationHistoryToTransaction" */
export enum _DonationHistoryToTransaction_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_DonationHistoryToTransaction" */
export type _DonationHistoryToTransaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _DonationHistoryToTransaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _DonationHistoryToTransaction_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_DonationHistoryToTransaction" */
export enum _DonationHistoryToTransaction_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type _DonationHistoryToTransaction_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_DonationHistoryToTransaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: _DonationHistoryToTransaction_Bool_Exp;
};

/** columns and relationships of "actions" */
export type Actions = {
  __typename?: 'actions';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  systemCriteria: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "actions" */
export type Actions_Aggregate = {
  __typename?: 'actions_aggregate';
  aggregate?: Maybe<Actions_Aggregate_Fields>;
  nodes: Array<Actions>;
};

/** aggregate fields of "actions" */
export type Actions_Aggregate_Fields = {
  __typename?: 'actions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Actions_Max_Fields>;
  min?: Maybe<Actions_Min_Fields>;
};


/** aggregate fields of "actions" */
export type Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "actions". All fields are combined with a logical 'AND'. */
export type Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Actions_Bool_Exp>>;
  _not?: InputMaybe<Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Actions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  systemCriteria?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "actions" */
export enum Actions_Constraint {
  /** unique or primary key constraint on columns "systemCriteria", "name" */
  ActionsNameSystemCriteriaKey = 'actions_name_systemCriteria_key',
  /** unique or primary key constraint on columns "id" */
  ActionsPkey = 'actions_pkey'
}

/** input type for inserting data into table "actions" */
export type Actions_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemCriteria?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Actions_Max_Fields = {
  __typename?: 'actions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  systemCriteria?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Actions_Min_Fields = {
  __typename?: 'actions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  systemCriteria?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "actions" */
export type Actions_Mutation_Response = {
  __typename?: 'actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Actions>;
};

/** input type for inserting object relation for remote table "actions" */
export type Actions_Obj_Rel_Insert_Input = {
  data: Actions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Actions_On_Conflict>;
};

/** on_conflict condition type for table "actions" */
export type Actions_On_Conflict = {
  constraint: Actions_Constraint;
  update_columns?: Array<Actions_Update_Column>;
  where?: InputMaybe<Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "actions". */
export type Actions_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  systemCriteria?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: actions */
export type Actions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "actions" */
export enum Actions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SystemCriteria = 'systemCriteria',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "actions" */
export type Actions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemCriteria?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "actions" */
export type Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Actions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemCriteria?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "actions" */
export enum Actions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SystemCriteria = 'systemCriteria',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Actions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Actions_Bool_Exp;
};

/** columns and relationships of "admins" */
export type Admins = {
  __typename?: 'admins';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  /** An object relationship */
  role: Roles;
  roleId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  username: Scalars['String']['output'];
};

/** aggregated selection of "admins" */
export type Admins_Aggregate = {
  __typename?: 'admins_aggregate';
  aggregate?: Maybe<Admins_Aggregate_Fields>;
  nodes: Array<Admins>;
};

export type Admins_Aggregate_Bool_Exp = {
  count?: InputMaybe<Admins_Aggregate_Bool_Exp_Count>;
};

export type Admins_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Admins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Admins_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "admins" */
export type Admins_Aggregate_Fields = {
  __typename?: 'admins_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Admins_Max_Fields>;
  min?: Maybe<Admins_Min_Fields>;
};


/** aggregate fields of "admins" */
export type Admins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Admins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "admins" */
export type Admins_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Admins_Max_Order_By>;
  min?: InputMaybe<Admins_Min_Order_By>;
};

/** input type for inserting array relation for remote table "admins" */
export type Admins_Arr_Rel_Insert_Input = {
  data: Array<Admins_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};

/** Boolean expression to filter rows from the table "admins". All fields are combined with a logical 'AND'. */
export type Admins_Bool_Exp = {
  _and?: InputMaybe<Array<Admins_Bool_Exp>>;
  _not?: InputMaybe<Admins_Bool_Exp>;
  _or?: InputMaybe<Array<Admins_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  roleId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "admins" */
export enum Admins_Constraint {
  /** unique or primary key constraint on columns "email" */
  AdminsEmailKey = 'admins_email_key',
  /** unique or primary key constraint on columns "id" */
  AdminsPkey = 'admins_pkey'
}

/** input type for inserting data into table "admins" */
export type Admins_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Admins_Max_Fields = {
  __typename?: 'admins_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "admins" */
export type Admins_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Admins_Min_Fields = {
  __typename?: 'admins_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "admins" */
export type Admins_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "admins" */
export type Admins_Mutation_Response = {
  __typename?: 'admins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Admins>;
};

/** input type for inserting object relation for remote table "admins" */
export type Admins_Obj_Rel_Insert_Input = {
  data: Admins_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};

/** on_conflict condition type for table "admins" */
export type Admins_On_Conflict = {
  constraint: Admins_Constraint;
  update_columns?: Array<Admins_Update_Column>;
  where?: InputMaybe<Admins_Bool_Exp>;
};

/** Ordering options when selecting data from "admins". */
export type Admins_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  roleId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: admins */
export type Admins_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "admins" */
export enum Admins_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "admins" */
export type Admins_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "admins" */
export type Admins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Admins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Admins_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "admins" */
export enum Admins_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export type Admins_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Admins_Set_Input>;
  /** filter the rows which have to be updated */
  where: Admins_Bool_Exp;
};

/** columns and relationships of "answers" */
export type Answers = {
  __typename?: 'answers';
  answerId: Scalars['String']['output'];
  answerText: Scalars['jsonb']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  question: Questions;
  questionId: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "answers" */
export type AnswersAnswerTextArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "answers" */
export type Answers_Aggregate = {
  __typename?: 'answers_aggregate';
  aggregate?: Maybe<Answers_Aggregate_Fields>;
  nodes: Array<Answers>;
};

export type Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Answers_Aggregate_Bool_Exp_Count>;
};

export type Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "answers" */
export type Answers_Aggregate_Fields = {
  __typename?: 'answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Answers_Max_Fields>;
  min?: Maybe<Answers_Min_Fields>;
};


/** aggregate fields of "answers" */
export type Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "answers" */
export type Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Answers_Max_Order_By>;
  min?: InputMaybe<Answers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Answers_Append_Input = {
  answerText?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "answers" */
export type Answers_Arr_Rel_Insert_Input = {
  data: Array<Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "answers". All fields are combined with a logical 'AND'. */
export type Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Answers_Bool_Exp>>;
  _not?: InputMaybe<Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Answers_Bool_Exp>>;
  answerId?: InputMaybe<String_Comparison_Exp>;
  answerText?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "answers" */
export enum Answers_Constraint {
  /** unique or primary key constraint on columns "answerId" */
  AnswersPkey = 'answers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Answers_Delete_At_Path_Input = {
  answerText?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Answers_Delete_Elem_Input = {
  answerText?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Answers_Delete_Key_Input = {
  answerText?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "answers" */
export type Answers_Insert_Input = {
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerText?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Answers_Max_Fields = {
  __typename?: 'answers_max_fields';
  answerId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "answers" */
export type Answers_Max_Order_By = {
  answerId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Answers_Min_Fields = {
  __typename?: 'answers_min_fields';
  answerId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "answers" */
export type Answers_Min_Order_By = {
  answerId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "answers" */
export type Answers_Mutation_Response = {
  __typename?: 'answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Answers>;
};

/** input type for inserting object relation for remote table "answers" */
export type Answers_Obj_Rel_Insert_Input = {
  data: Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};

/** on_conflict condition type for table "answers" */
export type Answers_On_Conflict = {
  constraint: Answers_Constraint;
  update_columns?: Array<Answers_Update_Column>;
  where?: InputMaybe<Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "answers". */
export type Answers_Order_By = {
  answerId?: InputMaybe<Order_By>;
  answerText?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answers */
export type Answers_Pk_Columns_Input = {
  answerId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Answers_Prepend_Input = {
  answerText?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "answers" */
export enum Answers_Select_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  AnswerText = 'answerText',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "answers" */
export type Answers_Set_Input = {
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerText?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "answers" */
export type Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answers_Stream_Cursor_Value_Input = {
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerText?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "answers" */
export enum Answers_Update_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  AnswerText = 'answerText',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Answers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Answers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Answers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Answers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Answers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Answers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answers_Bool_Exp;
};

/** columns and relationships of "authorized_users" */
export type Authorized_Users = {
  __typename?: 'authorized_users';
  adminMsg?: Maybe<Scalars['String']['output']>;
  authorizedUserId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  fieldOfStudy?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  notes?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  /** An array relationship */
  user_categories: Array<User_Categories>;
  /** An aggregate relationship */
  user_categories_aggregate: User_Categories_Aggregate;
};


/** columns and relationships of "authorized_users" */
export type Authorized_UsersFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "authorized_users" */
export type Authorized_UsersFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "authorized_users" */
export type Authorized_UsersUser_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


/** columns and relationships of "authorized_users" */
export type Authorized_UsersUser_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};

/** aggregated selection of "authorized_users" */
export type Authorized_Users_Aggregate = {
  __typename?: 'authorized_users_aggregate';
  aggregate?: Maybe<Authorized_Users_Aggregate_Fields>;
  nodes: Array<Authorized_Users>;
};

/** aggregate fields of "authorized_users" */
export type Authorized_Users_Aggregate_Fields = {
  __typename?: 'authorized_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Authorized_Users_Max_Fields>;
  min?: Maybe<Authorized_Users_Min_Fields>;
};


/** aggregate fields of "authorized_users" */
export type Authorized_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Authorized_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "authorized_users". All fields are combined with a logical 'AND'. */
export type Authorized_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Authorized_Users_Bool_Exp>>;
  _not?: InputMaybe<Authorized_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Authorized_Users_Bool_Exp>>;
  adminMsg?: InputMaybe<String_Comparison_Exp>;
  authorizedUserId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldOfStudy?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_categories?: InputMaybe<User_Categories_Bool_Exp>;
  user_categories_aggregate?: InputMaybe<User_Categories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "authorized_users" */
export enum Authorized_Users_Constraint {
  /** unique or primary key constraint on columns "authorizedUserId" */
  AuthorizedUsersPkey = 'authorized_users_pkey',
  /** unique or primary key constraint on columns "userId" */
  AuthorizedUsersUserIdKey = 'authorized_users_userId_key'
}

/** input type for inserting data into table "authorized_users" */
export type Authorized_Users_Insert_Input = {
  adminMsg?: InputMaybe<Scalars['String']['input']>;
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldOfStudy?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_categories?: InputMaybe<User_Categories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Authorized_Users_Max_Fields = {
  __typename?: 'authorized_users_max_fields';
  adminMsg?: Maybe<Scalars['String']['output']>;
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fieldOfStudy?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Authorized_Users_Min_Fields = {
  __typename?: 'authorized_users_min_fields';
  adminMsg?: Maybe<Scalars['String']['output']>;
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fieldOfStudy?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "authorized_users" */
export type Authorized_Users_Mutation_Response = {
  __typename?: 'authorized_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Authorized_Users>;
};

/** input type for inserting object relation for remote table "authorized_users" */
export type Authorized_Users_Obj_Rel_Insert_Input = {
  data: Authorized_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Authorized_Users_On_Conflict>;
};

/** on_conflict condition type for table "authorized_users" */
export type Authorized_Users_On_Conflict = {
  constraint: Authorized_Users_Constraint;
  update_columns?: Array<Authorized_Users_Update_Column>;
  where?: InputMaybe<Authorized_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "authorized_users". */
export type Authorized_Users_Order_By = {
  adminMsg?: InputMaybe<Order_By>;
  authorizedUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  fieldOfStudy?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  notes?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  user_categories_aggregate?: InputMaybe<User_Categories_Aggregate_Order_By>;
};

/** primary key columns input for table: authorized_users */
export type Authorized_Users_Pk_Columns_Input = {
  authorizedUserId: Scalars['String']['input'];
};

/** select columns of table "authorized_users" */
export enum Authorized_Users_Select_Column {
  /** column name */
  AdminMsg = 'adminMsg',
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FieldOfStudy = 'fieldOfStudy',
  /** column name */
  Notes = 'notes',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "authorized_users" */
export type Authorized_Users_Set_Input = {
  adminMsg?: InputMaybe<Scalars['String']['input']>;
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldOfStudy?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authorized_users" */
export type Authorized_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Authorized_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Authorized_Users_Stream_Cursor_Value_Input = {
  adminMsg?: InputMaybe<Scalars['String']['input']>;
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldOfStudy?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "authorized_users" */
export enum Authorized_Users_Update_Column {
  /** column name */
  AdminMsg = 'adminMsg',
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FieldOfStudy = 'fieldOfStudy',
  /** column name */
  Notes = 'notes',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Authorized_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Authorized_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Authorized_Users_Bool_Exp;
};

/** columns and relationships of "badges" */
export type Badges = {
  __typename?: 'badges';
  badgeId: Scalars['String']['output'];
  badgeImageURL?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  category?: Maybe<Categories>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  generalType?: Maybe<Scalars['generalBadgeType']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  hearing_council?: Maybe<Hearing_Councils>;
  /** An object relationship */
  mutoon?: Maybe<Mutoons>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  programName: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_badges: Array<User_Badges>;
  /** An aggregate relationship */
  user_badges_aggregate: User_Badges_Aggregate;
};


/** columns and relationships of "badges" */
export type BadgesProgramNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "badges" */
export type BadgesUser_BadgesArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


/** columns and relationships of "badges" */
export type BadgesUser_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};

/** aggregated selection of "badges" */
export type Badges_Aggregate = {
  __typename?: 'badges_aggregate';
  aggregate?: Maybe<Badges_Aggregate_Fields>;
  nodes: Array<Badges>;
};

export type Badges_Aggregate_Bool_Exp = {
  count?: InputMaybe<Badges_Aggregate_Bool_Exp_Count>;
};

export type Badges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Badges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "badges" */
export type Badges_Aggregate_Fields = {
  __typename?: 'badges_aggregate_fields';
  avg?: Maybe<Badges_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Badges_Max_Fields>;
  min?: Maybe<Badges_Min_Fields>;
  stddev?: Maybe<Badges_Stddev_Fields>;
  stddev_pop?: Maybe<Badges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Badges_Stddev_Samp_Fields>;
  sum?: Maybe<Badges_Sum_Fields>;
  var_pop?: Maybe<Badges_Var_Pop_Fields>;
  var_samp?: Maybe<Badges_Var_Samp_Fields>;
  variance?: Maybe<Badges_Variance_Fields>;
};


/** aggregate fields of "badges" */
export type Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "badges" */
export type Badges_Aggregate_Order_By = {
  avg?: InputMaybe<Badges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Badges_Max_Order_By>;
  min?: InputMaybe<Badges_Min_Order_By>;
  stddev?: InputMaybe<Badges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Badges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Badges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Badges_Sum_Order_By>;
  var_pop?: InputMaybe<Badges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Badges_Var_Samp_Order_By>;
  variance?: InputMaybe<Badges_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Badges_Append_Input = {
  programName?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "badges" */
export type Badges_Arr_Rel_Insert_Input = {
  data: Array<Badges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};

/** aggregate avg on columns */
export type Badges_Avg_Fields = {
  __typename?: 'badges_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "badges" */
export type Badges_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "badges". All fields are combined with a logical 'AND'. */
export type Badges_Bool_Exp = {
  _and?: InputMaybe<Array<Badges_Bool_Exp>>;
  _not?: InputMaybe<Badges_Bool_Exp>;
  _or?: InputMaybe<Array<Badges_Bool_Exp>>;
  badgeId?: InputMaybe<String_Comparison_Exp>;
  badgeImageURL?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  generalType?: InputMaybe<GeneralBadgeType_Comparison_Exp>;
  hearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  hearing_council?: InputMaybe<Hearing_Councils_Bool_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonId?: InputMaybe<String_Comparison_Exp>;
  programName?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_badges?: InputMaybe<User_Badges_Bool_Exp>;
  user_badges_aggregate?: InputMaybe<User_Badges_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "badges" */
export enum Badges_Constraint {
  /** unique or primary key constraint on columns "categoryId" */
  BadgesCategoryIdKey = 'badges_categoryId_key',
  /** unique or primary key constraint on columns "hearingCouncilId" */
  BadgesHearingCouncilIdKey = 'badges_hearingCouncilId_key',
  /** unique or primary key constraint on columns "mutoonId" */
  BadgesMutoonIdKey = 'badges_mutoonId_key',
  /** unique or primary key constraint on columns "badgeId" */
  BadgesPkey = 'badges_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Badges_Delete_At_Path_Input = {
  programName?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Badges_Delete_Elem_Input = {
  programName?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Badges_Delete_Key_Input = {
  programName?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "badges" */
export type Badges_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "badges" */
export type Badges_Insert_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  badgeImageURL?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  generalType?: InputMaybe<Scalars['generalBadgeType']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  hearing_council?: InputMaybe<Hearing_Councils_Obj_Rel_Insert_Input>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  programName?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_badges?: InputMaybe<User_Badges_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Badges_Max_Fields = {
  __typename?: 'badges_max_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  badgeImageURL?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  generalType?: Maybe<Scalars['generalBadgeType']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "badges" */
export type Badges_Max_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  badgeImageURL?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  generalType?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  mutoonId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Badges_Min_Fields = {
  __typename?: 'badges_min_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  badgeImageURL?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  generalType?: Maybe<Scalars['generalBadgeType']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "badges" */
export type Badges_Min_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  badgeImageURL?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  generalType?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  mutoonId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "badges" */
export type Badges_Mutation_Response = {
  __typename?: 'badges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Badges>;
};

/** input type for inserting object relation for remote table "badges" */
export type Badges_Obj_Rel_Insert_Input = {
  data: Badges_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};

/** on_conflict condition type for table "badges" */
export type Badges_On_Conflict = {
  constraint: Badges_Constraint;
  update_columns?: Array<Badges_Update_Column>;
  where?: InputMaybe<Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "badges". */
export type Badges_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  badgeImageURL?: InputMaybe<Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  generalType?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  hearing_council?: InputMaybe<Hearing_Councils_Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonId?: InputMaybe<Order_By>;
  programName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_badges_aggregate?: InputMaybe<User_Badges_Aggregate_Order_By>;
};

/** primary key columns input for table: badges */
export type Badges_Pk_Columns_Input = {
  badgeId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Badges_Prepend_Input = {
  programName?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "badges" */
export enum Badges_Select_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  BadgeImageUrl = 'badgeImageURL',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  GeneralType = 'generalType',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  MutoonId = 'mutoonId',
  /** column name */
  ProgramName = 'programName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "badges" */
export type Badges_Set_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  badgeImageURL?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  generalType?: InputMaybe<Scalars['generalBadgeType']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  programName?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Badges_Stddev_Fields = {
  __typename?: 'badges_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "badges" */
export type Badges_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Badges_Stddev_Pop_Fields = {
  __typename?: 'badges_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "badges" */
export type Badges_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Badges_Stddev_Samp_Fields = {
  __typename?: 'badges_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "badges" */
export type Badges_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "badges" */
export type Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badges_Stream_Cursor_Value_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  badgeImageURL?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  generalType?: InputMaybe<Scalars['generalBadgeType']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  programName?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Badges_Sum_Fields = {
  __typename?: 'badges_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "badges" */
export type Badges_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** update columns of table "badges" */
export enum Badges_Update_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  BadgeImageUrl = 'badgeImageURL',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  GeneralType = 'generalType',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  MutoonId = 'mutoonId',
  /** column name */
  ProgramName = 'programName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Badges_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Badges_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Badges_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Badges_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Badges_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Badges_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Badges_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Badges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Badges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Badges_Var_Pop_Fields = {
  __typename?: 'badges_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "badges" */
export type Badges_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Badges_Var_Samp_Fields = {
  __typename?: 'badges_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "badges" */
export type Badges_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Badges_Variance_Fields = {
  __typename?: 'badges_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "badges" */
export type Badges_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  /** An array relationship */
  badges: Array<Badges>;
  /** An aggregate relationship */
  badges_aggregate: Badges_Aggregate;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** An object relationship */
  category?: Maybe<Categories>;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  levels: Array<Levels>;
  /** An aggregate relationship */
  levels_aggregate: Levels_Aggregate;
  /** An array relationship */
  mutoonCategories: Array<Mutoon_Categories>;
  /** An aggregate relationship */
  mutoonCategories_aggregate: Mutoon_Categories_Aggregate;
  /** An array relationship */
  mutoons: Array<Mutoons>;
  /** An aggregate relationship */
  mutoons_aggregate: Mutoons_Aggregate;
  name: Scalars['jsonb']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_categories: Array<User_Categories>;
  /** An aggregate relationship */
  user_categories_aggregate: User_Categories_Aggregate;
};


/** columns and relationships of "categories" */
export type CategoriesBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "categories" */
export type CategoriesLevelsArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesMutoonCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesMutoonCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesMutoonsArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesMutoons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "categories" */
export type CategoriesUser_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesUser_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

export type Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Categories_Aggregate_Bool_Exp_Count>;
};

export type Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Categories_Max_Order_By>;
  min?: InputMaybe<Categories_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Categories_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>;
  _not?: InputMaybe<Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Bool_Exp>>;
  badges?: InputMaybe<Badges_Bool_Exp>;
  badges_aggregate?: InputMaybe<Badges_Aggregate_Bool_Exp>;
  categories?: InputMaybe<Categories_Bool_Exp>;
  categories_aggregate?: InputMaybe<Categories_Aggregate_Bool_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  levels?: InputMaybe<Levels_Bool_Exp>;
  levels_aggregate?: InputMaybe<Levels_Aggregate_Bool_Exp>;
  mutoonCategories?: InputMaybe<Mutoon_Categories_Bool_Exp>;
  mutoonCategories_aggregate?: InputMaybe<Mutoon_Categories_Aggregate_Bool_Exp>;
  mutoons?: InputMaybe<Mutoons_Bool_Exp>;
  mutoons_aggregate?: InputMaybe<Mutoons_Aggregate_Bool_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  parentId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_categories?: InputMaybe<User_Categories_Bool_Exp>;
  user_categories_aggregate?: InputMaybe<User_Categories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "categoryId" */
  CategoriesPkey = 'categories_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Categories_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Categories_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Categories_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  badges?: InputMaybe<Badges_Arr_Rel_Insert_Input>;
  categories?: InputMaybe<Categories_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  levels?: InputMaybe<Levels_Arr_Rel_Insert_Input>;
  mutoonCategories?: InputMaybe<Mutoon_Categories_Arr_Rel_Insert_Input>;
  mutoons?: InputMaybe<Mutoons_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_categories?: InputMaybe<User_Categories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  badges_aggregate?: InputMaybe<Badges_Aggregate_Order_By>;
  categories_aggregate?: InputMaybe<Categories_Aggregate_Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  levels_aggregate?: InputMaybe<Levels_Aggregate_Order_By>;
  mutoonCategories_aggregate?: InputMaybe<Mutoon_Categories_Aggregate_Order_By>;
  mutoons_aggregate?: InputMaybe<Mutoons_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_categories_aggregate?: InputMaybe<User_Categories_Aggregate_Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  categoryId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Categories_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Categories_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Categories_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Categories_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Categories_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Categories_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Categories_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp;
};

/** columns and relationships of "certificate_variables" */
export type Certificate_Variables = {
  __typename?: 'certificate_variables';
  certID?: Maybe<Scalars['String']['output']>;
  certVarId: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  dynamicVarType?: Maybe<Scalars['DynamicVariableType']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['Int']['output']>;
  fontStyle?: Maybe<Scalars['String']['output']>;
  fontWeight?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: Scalars['CertNodeType']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  x?: Maybe<Scalars['float8']['output']>;
  y?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "certificate_variables" */
export type Certificate_Variables_Aggregate = {
  __typename?: 'certificate_variables_aggregate';
  aggregate?: Maybe<Certificate_Variables_Aggregate_Fields>;
  nodes: Array<Certificate_Variables>;
};

export type Certificate_Variables_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp_Var_Samp>;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Avg = {
  arguments: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Corr = {
  arguments: Certificate_Variables_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Max = {
  arguments: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Min = {
  arguments: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Sum = {
  arguments: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Certificate_Variables_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Certificate_Variables_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "certificate_variables" */
export type Certificate_Variables_Aggregate_Fields = {
  __typename?: 'certificate_variables_aggregate_fields';
  avg?: Maybe<Certificate_Variables_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Certificate_Variables_Max_Fields>;
  min?: Maybe<Certificate_Variables_Min_Fields>;
  stddev?: Maybe<Certificate_Variables_Stddev_Fields>;
  stddev_pop?: Maybe<Certificate_Variables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Certificate_Variables_Stddev_Samp_Fields>;
  sum?: Maybe<Certificate_Variables_Sum_Fields>;
  var_pop?: Maybe<Certificate_Variables_Var_Pop_Fields>;
  var_samp?: Maybe<Certificate_Variables_Var_Samp_Fields>;
  variance?: Maybe<Certificate_Variables_Variance_Fields>;
};


/** aggregate fields of "certificate_variables" */
export type Certificate_Variables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "certificate_variables" */
export type Certificate_Variables_Aggregate_Order_By = {
  avg?: InputMaybe<Certificate_Variables_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Certificate_Variables_Max_Order_By>;
  min?: InputMaybe<Certificate_Variables_Min_Order_By>;
  stddev?: InputMaybe<Certificate_Variables_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Certificate_Variables_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Certificate_Variables_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Certificate_Variables_Sum_Order_By>;
  var_pop?: InputMaybe<Certificate_Variables_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Certificate_Variables_Var_Samp_Order_By>;
  variance?: InputMaybe<Certificate_Variables_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "certificate_variables" */
export type Certificate_Variables_Arr_Rel_Insert_Input = {
  data: Array<Certificate_Variables_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Certificate_Variables_On_Conflict>;
};

/** aggregate avg on columns */
export type Certificate_Variables_Avg_Fields = {
  __typename?: 'certificate_variables_avg_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "certificate_variables" */
export type Certificate_Variables_Avg_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "certificate_variables". All fields are combined with a logical 'AND'. */
export type Certificate_Variables_Bool_Exp = {
  _and?: InputMaybe<Array<Certificate_Variables_Bool_Exp>>;
  _not?: InputMaybe<Certificate_Variables_Bool_Exp>;
  _or?: InputMaybe<Array<Certificate_Variables_Bool_Exp>>;
  certID?: InputMaybe<String_Comparison_Exp>;
  certVarId?: InputMaybe<String_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dynamicVarType?: InputMaybe<DynamicVariableType_Comparison_Exp>;
  fontFamily?: InputMaybe<String_Comparison_Exp>;
  fontSize?: InputMaybe<Int_Comparison_Exp>;
  fontStyle?: InputMaybe<String_Comparison_Exp>;
  fontWeight?: InputMaybe<Int_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<CertNodeType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  width?: InputMaybe<Int_Comparison_Exp>;
  x?: InputMaybe<Float8_Comparison_Exp>;
  y?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "certificate_variables" */
export enum Certificate_Variables_Constraint {
  /** unique or primary key constraint on columns "certVarId" */
  CertificateVariablesPkey = 'certificate_variables_pkey'
}

/** input type for incrementing numeric columns in table "certificate_variables" */
export type Certificate_Variables_Inc_Input = {
  fontSize?: InputMaybe<Scalars['Int']['input']>;
  fontWeight?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  x?: InputMaybe<Scalars['float8']['input']>;
  y?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "certificate_variables" */
export type Certificate_Variables_Insert_Input = {
  certID?: InputMaybe<Scalars['String']['input']>;
  certVarId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dynamicVarType?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['Int']['input']>;
  fontStyle?: InputMaybe<Scalars['String']['input']>;
  fontWeight?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['CertNodeType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  x?: InputMaybe<Scalars['float8']['input']>;
  y?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Certificate_Variables_Max_Fields = {
  __typename?: 'certificate_variables_max_fields';
  certID?: Maybe<Scalars['String']['output']>;
  certVarId?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dynamicVarType?: Maybe<Scalars['DynamicVariableType']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['Int']['output']>;
  fontStyle?: Maybe<Scalars['String']['output']>;
  fontWeight?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['CertNodeType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  x?: Maybe<Scalars['float8']['output']>;
  y?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "certificate_variables" */
export type Certificate_Variables_Max_Order_By = {
  certID?: InputMaybe<Order_By>;
  certVarId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dynamicVarType?: InputMaybe<Order_By>;
  fontFamily?: InputMaybe<Order_By>;
  fontSize?: InputMaybe<Order_By>;
  fontStyle?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Certificate_Variables_Min_Fields = {
  __typename?: 'certificate_variables_min_fields';
  certID?: Maybe<Scalars['String']['output']>;
  certVarId?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dynamicVarType?: Maybe<Scalars['DynamicVariableType']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['Int']['output']>;
  fontStyle?: Maybe<Scalars['String']['output']>;
  fontWeight?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['CertNodeType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  x?: Maybe<Scalars['float8']['output']>;
  y?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "certificate_variables" */
export type Certificate_Variables_Min_Order_By = {
  certID?: InputMaybe<Order_By>;
  certVarId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dynamicVarType?: InputMaybe<Order_By>;
  fontFamily?: InputMaybe<Order_By>;
  fontSize?: InputMaybe<Order_By>;
  fontStyle?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "certificate_variables" */
export type Certificate_Variables_Mutation_Response = {
  __typename?: 'certificate_variables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Certificate_Variables>;
};

/** on_conflict condition type for table "certificate_variables" */
export type Certificate_Variables_On_Conflict = {
  constraint: Certificate_Variables_Constraint;
  update_columns?: Array<Certificate_Variables_Update_Column>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};

/** Ordering options when selecting data from "certificate_variables". */
export type Certificate_Variables_Order_By = {
  certID?: InputMaybe<Order_By>;
  certVarId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dynamicVarType?: InputMaybe<Order_By>;
  fontFamily?: InputMaybe<Order_By>;
  fontSize?: InputMaybe<Order_By>;
  fontStyle?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** primary key columns input for table: certificate_variables */
export type Certificate_Variables_Pk_Columns_Input = {
  certVarId: Scalars['String']['input'];
};

/** select columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column {
  /** column name */
  CertId = 'certID',
  /** column name */
  CertVarId = 'certVarId',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DynamicVarType = 'dynamicVarType',
  /** column name */
  FontFamily = 'fontFamily',
  /** column name */
  FontSize = 'fontSize',
  /** column name */
  FontStyle = 'fontStyle',
  /** column name */
  FontWeight = 'fontWeight',
  /** column name */
  Height = 'height',
  /** column name */
  Name = 'name',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Width = 'width',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_avg_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_corr_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_max_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_min_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_sum_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** select "certificate_variables_aggregate_bool_exp_var_samp_arguments_columns" columns of table "certificate_variables" */
export enum Certificate_Variables_Select_Column_Certificate_Variables_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** input type for updating data in table "certificate_variables" */
export type Certificate_Variables_Set_Input = {
  certID?: InputMaybe<Scalars['String']['input']>;
  certVarId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dynamicVarType?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['Int']['input']>;
  fontStyle?: InputMaybe<Scalars['String']['input']>;
  fontWeight?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['CertNodeType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  x?: InputMaybe<Scalars['float8']['input']>;
  y?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Certificate_Variables_Stddev_Fields = {
  __typename?: 'certificate_variables_stddev_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "certificate_variables" */
export type Certificate_Variables_Stddev_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Certificate_Variables_Stddev_Pop_Fields = {
  __typename?: 'certificate_variables_stddev_pop_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "certificate_variables" */
export type Certificate_Variables_Stddev_Pop_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Certificate_Variables_Stddev_Samp_Fields = {
  __typename?: 'certificate_variables_stddev_samp_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "certificate_variables" */
export type Certificate_Variables_Stddev_Samp_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "certificate_variables" */
export type Certificate_Variables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Certificate_Variables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Certificate_Variables_Stream_Cursor_Value_Input = {
  certID?: InputMaybe<Scalars['String']['input']>;
  certVarId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dynamicVarType?: InputMaybe<Scalars['DynamicVariableType']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['Int']['input']>;
  fontStyle?: InputMaybe<Scalars['String']['input']>;
  fontWeight?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['CertNodeType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  x?: InputMaybe<Scalars['float8']['input']>;
  y?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Certificate_Variables_Sum_Fields = {
  __typename?: 'certificate_variables_sum_fields';
  fontSize?: Maybe<Scalars['Int']['output']>;
  fontWeight?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  x?: Maybe<Scalars['float8']['output']>;
  y?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "certificate_variables" */
export type Certificate_Variables_Sum_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** update columns of table "certificate_variables" */
export enum Certificate_Variables_Update_Column {
  /** column name */
  CertId = 'certID',
  /** column name */
  CertVarId = 'certVarId',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DynamicVarType = 'dynamicVarType',
  /** column name */
  FontFamily = 'fontFamily',
  /** column name */
  FontSize = 'fontSize',
  /** column name */
  FontStyle = 'fontStyle',
  /** column name */
  FontWeight = 'fontWeight',
  /** column name */
  Height = 'height',
  /** column name */
  Name = 'name',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Width = 'width',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

export type Certificate_Variables_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Certificate_Variables_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Certificate_Variables_Set_Input>;
  /** filter the rows which have to be updated */
  where: Certificate_Variables_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Certificate_Variables_Var_Pop_Fields = {
  __typename?: 'certificate_variables_var_pop_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "certificate_variables" */
export type Certificate_Variables_Var_Pop_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Certificate_Variables_Var_Samp_Fields = {
  __typename?: 'certificate_variables_var_samp_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "certificate_variables" */
export type Certificate_Variables_Var_Samp_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Certificate_Variables_Variance_Fields = {
  __typename?: 'certificate_variables_variance_fields';
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "certificate_variables" */
export type Certificate_Variables_Variance_Order_By = {
  fontSize?: InputMaybe<Order_By>;
  fontWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** columns and relationships of "certificates" */
export type Certificates = {
  __typename?: 'certificates';
  certificateId: Scalars['String']['output'];
  certificateNumber?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  certificate_variables: Array<Certificate_Variables>;
  /** An aggregate relationship */
  certificate_variables_aggregate: Certificate_Variables_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  hearing_councils: Array<Hearing_Councils>;
  /** An aggregate relationship */
  hearing_councils_aggregate: Hearing_Councils_Aggregate;
  image?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_certificates: Array<User_Certificates>;
  /** An aggregate relationship */
  user_certificates_aggregate: User_Certificates_Aggregate;
};


/** columns and relationships of "certificates" */
export type CertificatesCertificate_VariablesArgs = {
  distinct_on?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificate_Variables_Order_By>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


/** columns and relationships of "certificates" */
export type CertificatesCertificate_Variables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificate_Variables_Order_By>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


/** columns and relationships of "certificates" */
export type CertificatesHearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "certificates" */
export type CertificatesHearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "certificates" */
export type CertificatesUser_CertificatesArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "certificates" */
export type CertificatesUser_Certificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};

/** aggregated selection of "certificates" */
export type Certificates_Aggregate = {
  __typename?: 'certificates_aggregate';
  aggregate?: Maybe<Certificates_Aggregate_Fields>;
  nodes: Array<Certificates>;
};

/** aggregate fields of "certificates" */
export type Certificates_Aggregate_Fields = {
  __typename?: 'certificates_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Certificates_Max_Fields>;
  min?: Maybe<Certificates_Min_Fields>;
};


/** aggregate fields of "certificates" */
export type Certificates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Certificates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "certificates". All fields are combined with a logical 'AND'. */
export type Certificates_Bool_Exp = {
  _and?: InputMaybe<Array<Certificates_Bool_Exp>>;
  _not?: InputMaybe<Certificates_Bool_Exp>;
  _or?: InputMaybe<Array<Certificates_Bool_Exp>>;
  certificateId?: InputMaybe<String_Comparison_Exp>;
  certificateNumber?: InputMaybe<String_Comparison_Exp>;
  certificate_variables?: InputMaybe<Certificate_Variables_Bool_Exp>;
  certificate_variables_aggregate?: InputMaybe<Certificate_Variables_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hearing_councils?: InputMaybe<Hearing_Councils_Bool_Exp>;
  hearing_councils_aggregate?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_certificates?: InputMaybe<User_Certificates_Bool_Exp>;
  user_certificates_aggregate?: InputMaybe<User_Certificates_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "certificates" */
export enum Certificates_Constraint {
  /** unique or primary key constraint on columns "certificateId" */
  CertificatesPkey = 'certificates_pkey'
}

/** input type for inserting data into table "certificates" */
export type Certificates_Insert_Input = {
  certificateId?: InputMaybe<Scalars['String']['input']>;
  certificateNumber?: InputMaybe<Scalars['String']['input']>;
  certificate_variables?: InputMaybe<Certificate_Variables_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hearing_councils?: InputMaybe<Hearing_Councils_Arr_Rel_Insert_Input>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_certificates?: InputMaybe<User_Certificates_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Certificates_Max_Fields = {
  __typename?: 'certificates_max_fields';
  certificateId?: Maybe<Scalars['String']['output']>;
  certificateNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Certificates_Min_Fields = {
  __typename?: 'certificates_min_fields';
  certificateId?: Maybe<Scalars['String']['output']>;
  certificateNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "certificates" */
export type Certificates_Mutation_Response = {
  __typename?: 'certificates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Certificates>;
};

/** input type for inserting object relation for remote table "certificates" */
export type Certificates_Obj_Rel_Insert_Input = {
  data: Certificates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Certificates_On_Conflict>;
};

/** on_conflict condition type for table "certificates" */
export type Certificates_On_Conflict = {
  constraint: Certificates_Constraint;
  update_columns?: Array<Certificates_Update_Column>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};

/** Ordering options when selecting data from "certificates". */
export type Certificates_Order_By = {
  certificateId?: InputMaybe<Order_By>;
  certificateNumber?: InputMaybe<Order_By>;
  certificate_variables_aggregate?: InputMaybe<Certificate_Variables_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  hearing_councils_aggregate?: InputMaybe<Hearing_Councils_Aggregate_Order_By>;
  image?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_certificates_aggregate?: InputMaybe<User_Certificates_Aggregate_Order_By>;
};

/** primary key columns input for table: certificates */
export type Certificates_Pk_Columns_Input = {
  certificateId: Scalars['String']['input'];
};

/** select columns of table "certificates" */
export enum Certificates_Select_Column {
  /** column name */
  CertificateId = 'certificateId',
  /** column name */
  CertificateNumber = 'certificateNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Image = 'image',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "certificates" */
export type Certificates_Set_Input = {
  certificateId?: InputMaybe<Scalars['String']['input']>;
  certificateNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "certificates" */
export type Certificates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Certificates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Certificates_Stream_Cursor_Value_Input = {
  certificateId?: InputMaybe<Scalars['String']['input']>;
  certificateNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "certificates" */
export enum Certificates_Update_Column {
  /** column name */
  CertificateId = 'certificateId',
  /** column name */
  CertificateNumber = 'certificateNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Image = 'image',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Certificates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Certificates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Certificates_Bool_Exp;
};

/** columns and relationships of "chat_messages" */
export type Chat_Messages = {
  __typename?: 'chat_messages';
  chatId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  senderId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** aggregated selection of "chat_messages" */
export type Chat_Messages_Aggregate = {
  __typename?: 'chat_messages_aggregate';
  aggregate?: Maybe<Chat_Messages_Aggregate_Fields>;
  nodes: Array<Chat_Messages>;
};

/** aggregate fields of "chat_messages" */
export type Chat_Messages_Aggregate_Fields = {
  __typename?: 'chat_messages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chat_Messages_Max_Fields>;
  min?: Maybe<Chat_Messages_Min_Fields>;
};


/** aggregate fields of "chat_messages" */
export type Chat_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "chat_messages". All fields are combined with a logical 'AND'. */
export type Chat_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Messages_Bool_Exp>>;
  _not?: InputMaybe<Chat_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Messages_Bool_Exp>>;
  chatId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  senderId?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_messages" */
export enum Chat_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessagesPkey = 'chat_messages_pkey'
}

/** input type for inserting data into table "chat_messages" */
export type Chat_Messages_Insert_Input = {
  chatId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Chat_Messages_Max_Fields = {
  __typename?: 'chat_messages_max_fields';
  chatId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Chat_Messages_Min_Fields = {
  __typename?: 'chat_messages_min_fields';
  chatId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chat_messages" */
export type Chat_Messages_Mutation_Response = {
  __typename?: 'chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Messages>;
};

/** on_conflict condition type for table "chat_messages" */
export type Chat_Messages_On_Conflict = {
  constraint: Chat_Messages_Constraint;
  update_columns?: Array<Chat_Messages_Update_Column>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_messages". */
export type Chat_Messages_Order_By = {
  chatId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  senderId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_messages */
export type Chat_Messages_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "chat_messages" */
export enum Chat_Messages_Select_Column {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SenderId = 'senderId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_messages" */
export type Chat_Messages_Set_Input = {
  chatId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chat_messages" */
export type Chat_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Messages_Stream_Cursor_Value_Input = {
  chatId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chat_messages" */
export enum Chat_Messages_Update_Column {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SenderId = 'senderId',
  /** column name */
  Type = 'type'
}

export type Chat_Messages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Messages_Bool_Exp;
};

/** columns and relationships of "chats" */
export type Chats = {
  __typename?: 'chats';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  creatorId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastMessageAt?: Maybe<Scalars['timestamptz']['output']>;
  type: Scalars['String']['output'];
};

/** aggregated selection of "chats" */
export type Chats_Aggregate = {
  __typename?: 'chats_aggregate';
  aggregate?: Maybe<Chats_Aggregate_Fields>;
  nodes: Array<Chats>;
};

/** aggregate fields of "chats" */
export type Chats_Aggregate_Fields = {
  __typename?: 'chats_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chats_Max_Fields>;
  min?: Maybe<Chats_Min_Fields>;
};


/** aggregate fields of "chats" */
export type Chats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "chats". All fields are combined with a logical 'AND'. */
export type Chats_Bool_Exp = {
  _and?: InputMaybe<Array<Chats_Bool_Exp>>;
  _not?: InputMaybe<Chats_Bool_Exp>;
  _or?: InputMaybe<Array<Chats_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creatorId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastMessageAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chats" */
export enum Chats_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatsPkey = 'chats_pkey'
}

/** input type for inserting data into table "chats" */
export type Chats_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastMessageAt?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Chats_Max_Fields = {
  __typename?: 'chats_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  creatorId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastMessageAt?: Maybe<Scalars['timestamptz']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Chats_Min_Fields = {
  __typename?: 'chats_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  creatorId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastMessageAt?: Maybe<Scalars['timestamptz']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chats" */
export type Chats_Mutation_Response = {
  __typename?: 'chats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chats>;
};

/** on_conflict condition type for table "chats" */
export type Chats_On_Conflict = {
  constraint: Chats_Constraint;
  update_columns?: Array<Chats_Update_Column>;
  where?: InputMaybe<Chats_Bool_Exp>;
};

/** Ordering options when selecting data from "chats". */
export type Chats_Order_By = {
  avatar?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creatorId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastMessageAt?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chats */
export type Chats_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "chats" */
export enum Chats_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  Id = 'id',
  /** column name */
  LastMessageAt = 'lastMessageAt',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chats" */
export type Chats_Set_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastMessageAt?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chats" */
export type Chats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chats_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastMessageAt?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chats" */
export enum Chats_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorId = 'creatorId',
  /** column name */
  Id = 'id',
  /** column name */
  LastMessageAt = 'lastMessageAt',
  /** column name */
  Type = 'type'
}

export type Chats_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chats_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chats_Bool_Exp;
};

/** columns and relationships of "contact_us" */
export type Contact_Us = {
  __typename?: 'contact_us';
  contactId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
  response?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "contact_us" */
export type Contact_Us_Aggregate = {
  __typename?: 'contact_us_aggregate';
  aggregate?: Maybe<Contact_Us_Aggregate_Fields>;
  nodes: Array<Contact_Us>;
};

/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_Fields = {
  __typename?: 'contact_us_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Us_Max_Fields>;
  min?: Maybe<Contact_Us_Min_Fields>;
};


/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Us_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "contact_us". All fields are combined with a logical 'AND'. */
export type Contact_Us_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Us_Bool_Exp>>;
  _not?: InputMaybe<Contact_Us_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Us_Bool_Exp>>;
  contactId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  response?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_us" */
export enum Contact_Us_Constraint {
  /** unique or primary key constraint on columns "contactId" */
  ContactUsPkey = 'contact_us_pkey'
}

/** input type for inserting data into table "contact_us" */
export type Contact_Us_Insert_Input = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contact_Us_Max_Fields = {
  __typename?: 'contact_us_max_fields';
  contactId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Contact_Us_Min_Fields = {
  __typename?: 'contact_us_min_fields';
  contactId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "contact_us" */
export type Contact_Us_Mutation_Response = {
  __typename?: 'contact_us_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Us>;
};

/** on_conflict condition type for table "contact_us" */
export type Contact_Us_On_Conflict = {
  constraint: Contact_Us_Constraint;
  update_columns?: Array<Contact_Us_Update_Column>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_us". */
export type Contact_Us_Order_By = {
  contactId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_us */
export type Contact_Us_Pk_Columns_Input = {
  contactId: Scalars['String']['input'];
};

/** select columns of table "contact_us" */
export enum Contact_Us_Select_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Reason = 'reason',
  /** column name */
  Response = 'response',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "contact_us" */
export type Contact_Us_Set_Input = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contact_us" */
export type Contact_Us_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Us_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Us_Stream_Cursor_Value_Input = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "contact_us" */
export enum Contact_Us_Update_Column {
  /** column name */
  ContactId = 'contactId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Reason = 'reason',
  /** column name */
  Response = 'response',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Contact_Us_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Us_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Us_Bool_Exp;
};

/** columns and relationships of "content_pages" */
export type Content_Pages = {
  __typename?: 'content_pages';
  content: Scalars['jsonb']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  title: Scalars['jsonb']['output'];
  type: Scalars['ContentType']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "content_pages" */
export type Content_PagesContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "content_pages" */
export type Content_PagesTitleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "content_pages" */
export type Content_Pages_Aggregate = {
  __typename?: 'content_pages_aggregate';
  aggregate?: Maybe<Content_Pages_Aggregate_Fields>;
  nodes: Array<Content_Pages>;
};

/** aggregate fields of "content_pages" */
export type Content_Pages_Aggregate_Fields = {
  __typename?: 'content_pages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Content_Pages_Max_Fields>;
  min?: Maybe<Content_Pages_Min_Fields>;
};


/** aggregate fields of "content_pages" */
export type Content_Pages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Pages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Pages_Append_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "content_pages". All fields are combined with a logical 'AND'. */
export type Content_Pages_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Pages_Bool_Exp>>;
  _not?: InputMaybe<Content_Pages_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Pages_Bool_Exp>>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<ContentType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_pages" */
export enum Content_Pages_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentPagesPkey = 'content_pages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Pages_Delete_At_Path_Input = {
  content?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Pages_Delete_Elem_Input = {
  content?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Pages_Delete_Key_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "content_pages" */
export type Content_Pages_Insert_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['ContentType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Content_Pages_Max_Fields = {
  __typename?: 'content_pages_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['ContentType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Content_Pages_Min_Fields = {
  __typename?: 'content_pages_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['ContentType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "content_pages" */
export type Content_Pages_Mutation_Response = {
  __typename?: 'content_pages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Pages>;
};

/** on_conflict condition type for table "content_pages" */
export type Content_Pages_On_Conflict = {
  constraint: Content_Pages_Constraint;
  update_columns?: Array<Content_Pages_Update_Column>;
  where?: InputMaybe<Content_Pages_Bool_Exp>;
};

/** Ordering options when selecting data from "content_pages". */
export type Content_Pages_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content_pages */
export type Content_Pages_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Pages_Prepend_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "content_pages" */
export enum Content_Pages_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "content_pages" */
export type Content_Pages_Set_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['ContentType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "content_pages" */
export type Content_Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Pages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Pages_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['ContentType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "content_pages" */
export enum Content_Pages_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Content_Pages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Pages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Pages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Pages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Pages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Pages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Pages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Pages_Bool_Exp;
};

/** columns and relationships of "content_recommendations" */
export type Content_Recommendations = {
  __typename?: 'content_recommendations';
  recommendation: Scalars['jsonb']['output'];
  recommendationId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "content_recommendations" */
export type Content_RecommendationsRecommendationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "content_recommendations" */
export type Content_Recommendations_Aggregate = {
  __typename?: 'content_recommendations_aggregate';
  aggregate?: Maybe<Content_Recommendations_Aggregate_Fields>;
  nodes: Array<Content_Recommendations>;
};

/** aggregate fields of "content_recommendations" */
export type Content_Recommendations_Aggregate_Fields = {
  __typename?: 'content_recommendations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Content_Recommendations_Max_Fields>;
  min?: Maybe<Content_Recommendations_Min_Fields>;
};


/** aggregate fields of "content_recommendations" */
export type Content_Recommendations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Recommendations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Recommendations_Append_Input = {
  recommendation?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "content_recommendations". All fields are combined with a logical 'AND'. */
export type Content_Recommendations_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Recommendations_Bool_Exp>>;
  _not?: InputMaybe<Content_Recommendations_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Recommendations_Bool_Exp>>;
  recommendation?: InputMaybe<Jsonb_Comparison_Exp>;
  recommendationId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_recommendations" */
export enum Content_Recommendations_Constraint {
  /** unique or primary key constraint on columns "recommendationId" */
  ContentRecommendationsPkey = 'content_recommendations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Recommendations_Delete_At_Path_Input = {
  recommendation?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Recommendations_Delete_Elem_Input = {
  recommendation?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Recommendations_Delete_Key_Input = {
  recommendation?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "content_recommendations" */
export type Content_Recommendations_Insert_Input = {
  recommendation?: InputMaybe<Scalars['jsonb']['input']>;
  recommendationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Content_Recommendations_Max_Fields = {
  __typename?: 'content_recommendations_max_fields';
  recommendationId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Content_Recommendations_Min_Fields = {
  __typename?: 'content_recommendations_min_fields';
  recommendationId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "content_recommendations" */
export type Content_Recommendations_Mutation_Response = {
  __typename?: 'content_recommendations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Recommendations>;
};

/** on_conflict condition type for table "content_recommendations" */
export type Content_Recommendations_On_Conflict = {
  constraint: Content_Recommendations_Constraint;
  update_columns?: Array<Content_Recommendations_Update_Column>;
  where?: InputMaybe<Content_Recommendations_Bool_Exp>;
};

/** Ordering options when selecting data from "content_recommendations". */
export type Content_Recommendations_Order_By = {
  recommendation?: InputMaybe<Order_By>;
  recommendationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content_recommendations */
export type Content_Recommendations_Pk_Columns_Input = {
  recommendationId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Recommendations_Prepend_Input = {
  recommendation?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "content_recommendations" */
export enum Content_Recommendations_Select_Column {
  /** column name */
  Recommendation = 'recommendation',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "content_recommendations" */
export type Content_Recommendations_Set_Input = {
  recommendation?: InputMaybe<Scalars['jsonb']['input']>;
  recommendationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "content_recommendations" */
export type Content_Recommendations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Recommendations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Recommendations_Stream_Cursor_Value_Input = {
  recommendation?: InputMaybe<Scalars['jsonb']['input']>;
  recommendationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "content_recommendations" */
export enum Content_Recommendations_Update_Column {
  /** column name */
  Recommendation = 'recommendation',
  /** column name */
  RecommendationId = 'recommendationId',
  /** column name */
  UserId = 'userId'
}

export type Content_Recommendations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Recommendations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Recommendations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Recommendations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Recommendations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Recommendations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Recommendations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Recommendations_Bool_Exp;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  countryCode?: Maybe<Scalars['String']['output']>;
  countryFlag?: Maybe<Scalars['String']['output']>;
  countryId: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
  countryPhoneCode?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "countries" */
export type Countries_Aggregate = {
  __typename?: 'countries_aggregate';
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "countries" */
export type Countries_Aggregate_Fields = {
  __typename?: 'countries_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
};


/** aggregate fields of "countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  countryFlag?: InputMaybe<String_Comparison_Exp>;
  countryId?: InputMaybe<String_Comparison_Exp>;
  countryName?: InputMaybe<String_Comparison_Exp>;
  countryPhoneCode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "countryId" */
  CountriesPkey = 'countries_pkey'
}

/** input type for inserting data into table "countries" */
export type Countries_Insert_Input = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryFlag?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  countryPhoneCode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: 'countries_max_fields';
  countryCode?: Maybe<Scalars['String']['output']>;
  countryFlag?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  countryPhoneCode?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: 'countries_min_fields';
  countryCode?: Maybe<Scalars['String']['output']>;
  countryFlag?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  countryPhoneCode?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "countries" */
export type Countries_Mutation_Response = {
  __typename?: 'countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** on_conflict condition type for table "countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  countryFlag?: InputMaybe<Order_By>;
  countryId?: InputMaybe<Order_By>;
  countryName?: InputMaybe<Order_By>;
  countryPhoneCode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: countries */
export type Countries_Pk_Columns_Input = {
  countryId: Scalars['String']['input'];
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CountryFlag = 'countryFlag',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CountryName = 'countryName',
  /** column name */
  CountryPhoneCode = 'countryPhoneCode'
}

/** input type for updating data in table "countries" */
export type Countries_Set_Input = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryFlag?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  countryPhoneCode?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryFlag?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  countryPhoneCode?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "countries" */
export enum Countries_Update_Column {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CountryFlag = 'countryFlag',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CountryName = 'countryName',
  /** column name */
  CountryPhoneCode = 'countryPhoneCode'
}

export type Countries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Countries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Countries_Bool_Exp;
};

export type CreateCertficateInput = {
  customName?: InputMaybe<Scalars['String']['input']>;
  examSubmissionId?: InputMaybe<Scalars['ID']['input']>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "discussion_rooms" */
export type Discussion_Rooms = {
  __typename?: 'discussion_rooms';
  details?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  memberCount: Scalars['Int']['output'];
  roomAudioURL: Scalars['String']['output'];
  roomId: Scalars['String']['output'];
  roomImageURL?: Maybe<Scalars['String']['output']>;
  roomName: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "discussion_rooms" */
export type Discussion_Rooms_Aggregate = {
  __typename?: 'discussion_rooms_aggregate';
  aggregate?: Maybe<Discussion_Rooms_Aggregate_Fields>;
  nodes: Array<Discussion_Rooms>;
};

/** aggregate fields of "discussion_rooms" */
export type Discussion_Rooms_Aggregate_Fields = {
  __typename?: 'discussion_rooms_aggregate_fields';
  avg?: Maybe<Discussion_Rooms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Discussion_Rooms_Max_Fields>;
  min?: Maybe<Discussion_Rooms_Min_Fields>;
  stddev?: Maybe<Discussion_Rooms_Stddev_Fields>;
  stddev_pop?: Maybe<Discussion_Rooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discussion_Rooms_Stddev_Samp_Fields>;
  sum?: Maybe<Discussion_Rooms_Sum_Fields>;
  var_pop?: Maybe<Discussion_Rooms_Var_Pop_Fields>;
  var_samp?: Maybe<Discussion_Rooms_Var_Samp_Fields>;
  variance?: Maybe<Discussion_Rooms_Variance_Fields>;
};


/** aggregate fields of "discussion_rooms" */
export type Discussion_Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Discussion_Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Discussion_Rooms_Avg_Fields = {
  __typename?: 'discussion_rooms_avg_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "discussion_rooms". All fields are combined with a logical 'AND'. */
export type Discussion_Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<Discussion_Rooms_Bool_Exp>>;
  _not?: InputMaybe<Discussion_Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<Discussion_Rooms_Bool_Exp>>;
  details?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  memberCount?: InputMaybe<Int_Comparison_Exp>;
  roomAudioURL?: InputMaybe<String_Comparison_Exp>;
  roomId?: InputMaybe<String_Comparison_Exp>;
  roomImageURL?: InputMaybe<String_Comparison_Exp>;
  roomName?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "discussion_rooms" */
export enum Discussion_Rooms_Constraint {
  /** unique or primary key constraint on columns "roomId" */
  DiscussionRoomsPkey = 'discussion_rooms_pkey'
}

/** input type for incrementing numeric columns in table "discussion_rooms" */
export type Discussion_Rooms_Inc_Input = {
  memberCount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "discussion_rooms" */
export type Discussion_Rooms_Insert_Input = {
  details?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberCount?: InputMaybe<Scalars['Int']['input']>;
  roomAudioURL?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  roomImageURL?: InputMaybe<Scalars['String']['input']>;
  roomName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Discussion_Rooms_Max_Fields = {
  __typename?: 'discussion_rooms_max_fields';
  details?: Maybe<Scalars['String']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  roomAudioURL?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomImageURL?: Maybe<Scalars['String']['output']>;
  roomName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Discussion_Rooms_Min_Fields = {
  __typename?: 'discussion_rooms_min_fields';
  details?: Maybe<Scalars['String']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  roomAudioURL?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomImageURL?: Maybe<Scalars['String']['output']>;
  roomName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "discussion_rooms" */
export type Discussion_Rooms_Mutation_Response = {
  __typename?: 'discussion_rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Discussion_Rooms>;
};

/** input type for inserting object relation for remote table "discussion_rooms" */
export type Discussion_Rooms_Obj_Rel_Insert_Input = {
  data: Discussion_Rooms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Discussion_Rooms_On_Conflict>;
};

/** on_conflict condition type for table "discussion_rooms" */
export type Discussion_Rooms_On_Conflict = {
  constraint: Discussion_Rooms_Constraint;
  update_columns?: Array<Discussion_Rooms_Update_Column>;
  where?: InputMaybe<Discussion_Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "discussion_rooms". */
export type Discussion_Rooms_Order_By = {
  details?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  memberCount?: InputMaybe<Order_By>;
  roomAudioURL?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  roomImageURL?: InputMaybe<Order_By>;
  roomName?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: discussion_rooms */
export type Discussion_Rooms_Pk_Columns_Input = {
  roomId: Scalars['String']['input'];
};

/** select columns of table "discussion_rooms" */
export enum Discussion_Rooms_Select_Column {
  /** column name */
  Details = 'details',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  MemberCount = 'memberCount',
  /** column name */
  RoomAudioUrl = 'roomAudioURL',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  RoomImageUrl = 'roomImageURL',
  /** column name */
  RoomName = 'roomName',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "discussion_rooms" */
export type Discussion_Rooms_Set_Input = {
  details?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberCount?: InputMaybe<Scalars['Int']['input']>;
  roomAudioURL?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  roomImageURL?: InputMaybe<Scalars['String']['input']>;
  roomName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Discussion_Rooms_Stddev_Fields = {
  __typename?: 'discussion_rooms_stddev_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Discussion_Rooms_Stddev_Pop_Fields = {
  __typename?: 'discussion_rooms_stddev_pop_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Discussion_Rooms_Stddev_Samp_Fields = {
  __typename?: 'discussion_rooms_stddev_samp_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "discussion_rooms" */
export type Discussion_Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discussion_Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Discussion_Rooms_Stream_Cursor_Value_Input = {
  details?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberCount?: InputMaybe<Scalars['Int']['input']>;
  roomAudioURL?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  roomImageURL?: InputMaybe<Scalars['String']['input']>;
  roomName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Discussion_Rooms_Sum_Fields = {
  __typename?: 'discussion_rooms_sum_fields';
  memberCount?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "discussion_rooms" */
export enum Discussion_Rooms_Update_Column {
  /** column name */
  Details = 'details',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  MemberCount = 'memberCount',
  /** column name */
  RoomAudioUrl = 'roomAudioURL',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  RoomImageUrl = 'roomImageURL',
  /** column name */
  RoomName = 'roomName',
  /** column name */
  Title = 'title'
}

export type Discussion_Rooms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Discussion_Rooms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Discussion_Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Discussion_Rooms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Discussion_Rooms_Var_Pop_Fields = {
  __typename?: 'discussion_rooms_var_pop_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Discussion_Rooms_Var_Samp_Fields = {
  __typename?: 'discussion_rooms_var_samp_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Discussion_Rooms_Variance_Fields = {
  __typename?: 'discussion_rooms_variance_fields';
  memberCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "donation_histories" */
export type Donation_Histories = {
  __typename?: 'donation_histories';
  amount: Scalars['float8']['output'];
  donationDate: Scalars['timestamp']['output'];
  donationHistoryId: Scalars['String']['output'];
  donationId: Scalars['String']['output'];
  donationType: Scalars['DonationType']['output'];
  donorType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  rememberMe: Scalars['Boolean']['output'];
};

/** aggregated selection of "donation_histories" */
export type Donation_Histories_Aggregate = {
  __typename?: 'donation_histories_aggregate';
  aggregate?: Maybe<Donation_Histories_Aggregate_Fields>;
  nodes: Array<Donation_Histories>;
};

/** aggregate fields of "donation_histories" */
export type Donation_Histories_Aggregate_Fields = {
  __typename?: 'donation_histories_aggregate_fields';
  avg?: Maybe<Donation_Histories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Donation_Histories_Max_Fields>;
  min?: Maybe<Donation_Histories_Min_Fields>;
  stddev?: Maybe<Donation_Histories_Stddev_Fields>;
  stddev_pop?: Maybe<Donation_Histories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Donation_Histories_Stddev_Samp_Fields>;
  sum?: Maybe<Donation_Histories_Sum_Fields>;
  var_pop?: Maybe<Donation_Histories_Var_Pop_Fields>;
  var_samp?: Maybe<Donation_Histories_Var_Samp_Fields>;
  variance?: Maybe<Donation_Histories_Variance_Fields>;
};


/** aggregate fields of "donation_histories" */
export type Donation_Histories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Donation_Histories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Donation_Histories_Avg_Fields = {
  __typename?: 'donation_histories_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "donation_histories". All fields are combined with a logical 'AND'. */
export type Donation_Histories_Bool_Exp = {
  _and?: InputMaybe<Array<Donation_Histories_Bool_Exp>>;
  _not?: InputMaybe<Donation_Histories_Bool_Exp>;
  _or?: InputMaybe<Array<Donation_Histories_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  donationDate?: InputMaybe<Timestamp_Comparison_Exp>;
  donationHistoryId?: InputMaybe<String_Comparison_Exp>;
  donationId?: InputMaybe<String_Comparison_Exp>;
  donationType?: InputMaybe<DonationType_Comparison_Exp>;
  donorType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  rememberMe?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "donation_histories" */
export enum Donation_Histories_Constraint {
  /** unique or primary key constraint on columns "donationHistoryId" */
  DonationHistoriesPkey = 'donation_histories_pkey'
}

/** input type for incrementing numeric columns in table "donation_histories" */
export type Donation_Histories_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "donation_histories" */
export type Donation_Histories_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationDate?: InputMaybe<Scalars['timestamp']['input']>;
  donationHistoryId?: InputMaybe<Scalars['String']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  donationType?: InputMaybe<Scalars['DonationType']['input']>;
  donorType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Donation_Histories_Max_Fields = {
  __typename?: 'donation_histories_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  donationDate?: Maybe<Scalars['timestamp']['output']>;
  donationHistoryId?: Maybe<Scalars['String']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  donationType?: Maybe<Scalars['DonationType']['output']>;
  donorType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Donation_Histories_Min_Fields = {
  __typename?: 'donation_histories_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  donationDate?: Maybe<Scalars['timestamp']['output']>;
  donationHistoryId?: Maybe<Scalars['String']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  donationType?: Maybe<Scalars['DonationType']['output']>;
  donorType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "donation_histories" */
export type Donation_Histories_Mutation_Response = {
  __typename?: 'donation_histories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Donation_Histories>;
};

/** on_conflict condition type for table "donation_histories" */
export type Donation_Histories_On_Conflict = {
  constraint: Donation_Histories_Constraint;
  update_columns?: Array<Donation_Histories_Update_Column>;
  where?: InputMaybe<Donation_Histories_Bool_Exp>;
};

/** Ordering options when selecting data from "donation_histories". */
export type Donation_Histories_Order_By = {
  amount?: InputMaybe<Order_By>;
  donationDate?: InputMaybe<Order_By>;
  donationHistoryId?: InputMaybe<Order_By>;
  donationId?: InputMaybe<Order_By>;
  donationType?: InputMaybe<Order_By>;
  donorType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rememberMe?: InputMaybe<Order_By>;
};

/** primary key columns input for table: donation_histories */
export type Donation_Histories_Pk_Columns_Input = {
  donationHistoryId: Scalars['String']['input'];
};

/** select columns of table "donation_histories" */
export enum Donation_Histories_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  DonationDate = 'donationDate',
  /** column name */
  DonationHistoryId = 'donationHistoryId',
  /** column name */
  DonationId = 'donationId',
  /** column name */
  DonationType = 'donationType',
  /** column name */
  DonorType = 'donorType',
  /** column name */
  Id = 'id',
  /** column name */
  RememberMe = 'rememberMe'
}

/** input type for updating data in table "donation_histories" */
export type Donation_Histories_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationDate?: InputMaybe<Scalars['timestamp']['input']>;
  donationHistoryId?: InputMaybe<Scalars['String']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  donationType?: InputMaybe<Scalars['DonationType']['input']>;
  donorType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Donation_Histories_Stddev_Fields = {
  __typename?: 'donation_histories_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Donation_Histories_Stddev_Pop_Fields = {
  __typename?: 'donation_histories_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Donation_Histories_Stddev_Samp_Fields = {
  __typename?: 'donation_histories_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "donation_histories" */
export type Donation_Histories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Donation_Histories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Donation_Histories_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationDate?: InputMaybe<Scalars['timestamp']['input']>;
  donationHistoryId?: InputMaybe<Scalars['String']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  donationType?: InputMaybe<Scalars['DonationType']['input']>;
  donorType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Donation_Histories_Sum_Fields = {
  __typename?: 'donation_histories_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "donation_histories" */
export enum Donation_Histories_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  DonationDate = 'donationDate',
  /** column name */
  DonationHistoryId = 'donationHistoryId',
  /** column name */
  DonationId = 'donationId',
  /** column name */
  DonationType = 'donationType',
  /** column name */
  DonorType = 'donorType',
  /** column name */
  Id = 'id',
  /** column name */
  RememberMe = 'rememberMe'
}

export type Donation_Histories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Donation_Histories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Donation_Histories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Donation_Histories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Donation_Histories_Var_Pop_Fields = {
  __typename?: 'donation_histories_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Donation_Histories_Var_Samp_Fields = {
  __typename?: 'donation_histories_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Donation_Histories_Variance_Fields = {
  __typename?: 'donation_histories_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "donations" */
export type Donations = {
  __typename?: 'donations';
  amount: Scalars['float8']['output'];
  donationId: Scalars['String']['output'];
  goal?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "donations" */
export type Donations_Aggregate = {
  __typename?: 'donations_aggregate';
  aggregate?: Maybe<Donations_Aggregate_Fields>;
  nodes: Array<Donations>;
};

/** aggregate fields of "donations" */
export type Donations_Aggregate_Fields = {
  __typename?: 'donations_aggregate_fields';
  avg?: Maybe<Donations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Donations_Max_Fields>;
  min?: Maybe<Donations_Min_Fields>;
  stddev?: Maybe<Donations_Stddev_Fields>;
  stddev_pop?: Maybe<Donations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Donations_Stddev_Samp_Fields>;
  sum?: Maybe<Donations_Sum_Fields>;
  var_pop?: Maybe<Donations_Var_Pop_Fields>;
  var_samp?: Maybe<Donations_Var_Samp_Fields>;
  variance?: Maybe<Donations_Variance_Fields>;
};


/** aggregate fields of "donations" */
export type Donations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Donations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Donations_Avg_Fields = {
  __typename?: 'donations_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "donations". All fields are combined with a logical 'AND'. */
export type Donations_Bool_Exp = {
  _and?: InputMaybe<Array<Donations_Bool_Exp>>;
  _not?: InputMaybe<Donations_Bool_Exp>;
  _or?: InputMaybe<Array<Donations_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  donationId?: InputMaybe<String_Comparison_Exp>;
  goal?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "donations" */
export enum Donations_Constraint {
  /** unique or primary key constraint on columns "donationId" */
  DonationsPkey = 'donations_pkey'
}

/** input type for incrementing numeric columns in table "donations" */
export type Donations_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "donations" */
export type Donations_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  goal?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Donations_Max_Fields = {
  __typename?: 'donations_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  goal?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Donations_Min_Fields = {
  __typename?: 'donations_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  goal?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "donations" */
export type Donations_Mutation_Response = {
  __typename?: 'donations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Donations>;
};

/** on_conflict condition type for table "donations" */
export type Donations_On_Conflict = {
  constraint: Donations_Constraint;
  update_columns?: Array<Donations_Update_Column>;
  where?: InputMaybe<Donations_Bool_Exp>;
};

/** Ordering options when selecting data from "donations". */
export type Donations_Order_By = {
  amount?: InputMaybe<Order_By>;
  donationId?: InputMaybe<Order_By>;
  goal?: InputMaybe<Order_By>;
};

/** primary key columns input for table: donations */
export type Donations_Pk_Columns_Input = {
  donationId: Scalars['String']['input'];
};

/** select columns of table "donations" */
export enum Donations_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  DonationId = 'donationId',
  /** column name */
  Goal = 'goal'
}

/** input type for updating data in table "donations" */
export type Donations_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  goal?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Donations_Stddev_Fields = {
  __typename?: 'donations_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Donations_Stddev_Pop_Fields = {
  __typename?: 'donations_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Donations_Stddev_Samp_Fields = {
  __typename?: 'donations_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "donations" */
export type Donations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Donations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Donations_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  goal?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Donations_Sum_Fields = {
  __typename?: 'donations_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "donations" */
export enum Donations_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  DonationId = 'donationId',
  /** column name */
  Goal = 'goal'
}

export type Donations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Donations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Donations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Donations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Donations_Var_Pop_Fields = {
  __typename?: 'donations_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Donations_Var_Samp_Fields = {
  __typename?: 'donations_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Donations_Variance_Fields = {
  __typename?: 'donations_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "event_reminders" */
export type Event_Reminders = {
  __typename?: 'event_reminders';
  eventId: Scalars['String']['output'];
  reminderId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "event_reminders" */
export type Event_Reminders_Aggregate = {
  __typename?: 'event_reminders_aggregate';
  aggregate?: Maybe<Event_Reminders_Aggregate_Fields>;
  nodes: Array<Event_Reminders>;
};

/** aggregate fields of "event_reminders" */
export type Event_Reminders_Aggregate_Fields = {
  __typename?: 'event_reminders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Event_Reminders_Max_Fields>;
  min?: Maybe<Event_Reminders_Min_Fields>;
};


/** aggregate fields of "event_reminders" */
export type Event_Reminders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Reminders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "event_reminders". All fields are combined with a logical 'AND'. */
export type Event_Reminders_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Reminders_Bool_Exp>>;
  _not?: InputMaybe<Event_Reminders_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Reminders_Bool_Exp>>;
  eventId?: InputMaybe<String_Comparison_Exp>;
  reminderId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_reminders" */
export enum Event_Reminders_Constraint {
  /** unique or primary key constraint on columns "reminderId" */
  EventRemindersPkey = 'event_reminders_pkey'
}

/** input type for inserting data into table "event_reminders" */
export type Event_Reminders_Insert_Input = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  reminderId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Event_Reminders_Max_Fields = {
  __typename?: 'event_reminders_max_fields';
  eventId?: Maybe<Scalars['String']['output']>;
  reminderId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Event_Reminders_Min_Fields = {
  __typename?: 'event_reminders_min_fields';
  eventId?: Maybe<Scalars['String']['output']>;
  reminderId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "event_reminders" */
export type Event_Reminders_Mutation_Response = {
  __typename?: 'event_reminders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Reminders>;
};

/** on_conflict condition type for table "event_reminders" */
export type Event_Reminders_On_Conflict = {
  constraint: Event_Reminders_Constraint;
  update_columns?: Array<Event_Reminders_Update_Column>;
  where?: InputMaybe<Event_Reminders_Bool_Exp>;
};

/** Ordering options when selecting data from "event_reminders". */
export type Event_Reminders_Order_By = {
  eventId?: InputMaybe<Order_By>;
  reminderId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_reminders */
export type Event_Reminders_Pk_Columns_Input = {
  reminderId: Scalars['String']['input'];
};

/** select columns of table "event_reminders" */
export enum Event_Reminders_Select_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  ReminderId = 'reminderId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "event_reminders" */
export type Event_Reminders_Set_Input = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  reminderId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "event_reminders" */
export type Event_Reminders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Reminders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Reminders_Stream_Cursor_Value_Input = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  reminderId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "event_reminders" */
export enum Event_Reminders_Update_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  ReminderId = 'reminderId',
  /** column name */
  UserId = 'userId'
}

export type Event_Reminders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Reminders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Reminders_Bool_Exp;
};

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'events';
  eventContent: Scalars['jsonb']['output'];
  eventDate: Scalars['timestamp']['output'];
  eventId: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
};


/** columns and relationships of "events" */
export type EventsEventContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Events_Append_Input = {
  eventContent?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  eventContent?: InputMaybe<Jsonb_Comparison_Exp>;
  eventDate?: InputMaybe<Timestamp_Comparison_Exp>;
  eventId?: InputMaybe<String_Comparison_Exp>;
  eventType?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint on columns "eventId" */
  EventsPkey = 'events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Events_Delete_At_Path_Input = {
  eventContent?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Events_Delete_Elem_Input = {
  eventContent?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Events_Delete_Key_Input = {
  eventContent?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  eventContent?: InputMaybe<Scalars['jsonb']['input']>;
  eventDate?: InputMaybe<Scalars['timestamp']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  eventDate?: Maybe<Scalars['timestamp']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  eventDate?: Maybe<Scalars['timestamp']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** on_conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type Events_Order_By = {
  eventContent?: InputMaybe<Order_By>;
  eventDate?: InputMaybe<Order_By>;
  eventId?: InputMaybe<Order_By>;
  eventType?: InputMaybe<Order_By>;
};

/** primary key columns input for table: events */
export type Events_Pk_Columns_Input = {
  eventId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Events_Prepend_Input = {
  eventContent?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  EventContent = 'eventContent',
  /** column name */
  EventDate = 'eventDate',
  /** column name */
  EventId = 'eventId',
  /** column name */
  EventType = 'eventType'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  eventContent?: InputMaybe<Scalars['jsonb']['input']>;
  eventDate?: InputMaybe<Scalars['timestamp']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "events" */
export type Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_Stream_Cursor_Value_Input = {
  eventContent?: InputMaybe<Scalars['jsonb']['input']>;
  eventDate?: InputMaybe<Scalars['timestamp']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  EventContent = 'eventContent',
  /** column name */
  EventDate = 'eventDate',
  /** column name */
  EventId = 'eventId',
  /** column name */
  EventType = 'eventType'
}

export type Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Events_Bool_Exp;
};

/** columns and relationships of "exams" */
export type Exams = {
  __typename?: 'exams';
  /** An object relationship */
  certificate?: Maybe<Certificates>;
  certificateexamId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['jsonb']['output']>;
  duration: Scalars['Int']['output'];
  examId: Scalars['String']['output'];
  gradeA: Scalars['Int']['output'];
  gradeB: Scalars['Int']['output'];
  gradeC: Scalars['Int']['output'];
  gradeD: Scalars['Int']['output'];
  gradeF: Scalars['Int']['output'];
  hearingCouncilHearingCouncilId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  hearing_council?: Maybe<Hearing_Councils>;
  limit: Scalars['Int']['output'];
  maxAttempts: Scalars['Int']['output'];
  minimumScore: Scalars['Int']['output'];
  mutonId: Scalars['String']['output'];
  /** An object relationship */
  mutoon: Mutoons;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  status: Scalars['String']['output'];
  title?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_exams: Array<User_Exams>;
  /** An aggregate relationship */
  user_exams_aggregate: User_Exams_Aggregate;
};


/** columns and relationships of "exams" */
export type ExamsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "exams" */
export type ExamsQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "exams" */
export type ExamsQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "exams" */
export type ExamsTitleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "exams" */
export type ExamsUser_ExamsArgs = {
  distinct_on?: InputMaybe<Array<User_Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Exams_Order_By>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};


/** columns and relationships of "exams" */
export type ExamsUser_Exams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Exams_Order_By>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};

/** aggregated selection of "exams" */
export type Exams_Aggregate = {
  __typename?: 'exams_aggregate';
  aggregate?: Maybe<Exams_Aggregate_Fields>;
  nodes: Array<Exams>;
};

/** aggregate fields of "exams" */
export type Exams_Aggregate_Fields = {
  __typename?: 'exams_aggregate_fields';
  avg?: Maybe<Exams_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Exams_Max_Fields>;
  min?: Maybe<Exams_Min_Fields>;
  stddev?: Maybe<Exams_Stddev_Fields>;
  stddev_pop?: Maybe<Exams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Exams_Stddev_Samp_Fields>;
  sum?: Maybe<Exams_Sum_Fields>;
  var_pop?: Maybe<Exams_Var_Pop_Fields>;
  var_samp?: Maybe<Exams_Var_Samp_Fields>;
  variance?: Maybe<Exams_Variance_Fields>;
};


/** aggregate fields of "exams" */
export type Exams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Exams_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Exams_Avg_Fields = {
  __typename?: 'exams_avg_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "exams". All fields are combined with a logical 'AND'. */
export type Exams_Bool_Exp = {
  _and?: InputMaybe<Array<Exams_Bool_Exp>>;
  _not?: InputMaybe<Exams_Bool_Exp>;
  _or?: InputMaybe<Array<Exams_Bool_Exp>>;
  certificate?: InputMaybe<Certificates_Bool_Exp>;
  certificateexamId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  examId?: InputMaybe<String_Comparison_Exp>;
  gradeA?: InputMaybe<Int_Comparison_Exp>;
  gradeB?: InputMaybe<Int_Comparison_Exp>;
  gradeC?: InputMaybe<Int_Comparison_Exp>;
  gradeD?: InputMaybe<Int_Comparison_Exp>;
  gradeF?: InputMaybe<Int_Comparison_Exp>;
  hearingCouncilHearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  hearing_council?: InputMaybe<Hearing_Councils_Bool_Exp>;
  limit?: InputMaybe<Int_Comparison_Exp>;
  maxAttempts?: InputMaybe<Int_Comparison_Exp>;
  minimumScore?: InputMaybe<Int_Comparison_Exp>;
  mutonId?: InputMaybe<String_Comparison_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_exams?: InputMaybe<User_Exams_Bool_Exp>;
  user_exams_aggregate?: InputMaybe<User_Exams_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "exams" */
export enum Exams_Constraint {
  /** unique or primary key constraint on columns "hearingCouncilHearingCouncilId" */
  ExamsHearingCouncilHearingCouncilIdKey = 'exams_hearingCouncilHearingCouncilId_key',
  /** unique or primary key constraint on columns "mutonId" */
  ExamsMutonIdKey = 'exams_mutonId_key',
  /** unique or primary key constraint on columns "examId" */
  ExamsPkey = 'exams_pkey',
  /** unique or primary key constraint on columns "title" */
  ExamsTitleKey = 'exams_title_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Exams_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Exams_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Exams_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "exams" */
export type Exams_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  gradeA?: InputMaybe<Scalars['Int']['input']>;
  gradeB?: InputMaybe<Scalars['Int']['input']>;
  gradeC?: InputMaybe<Scalars['Int']['input']>;
  gradeD?: InputMaybe<Scalars['Int']['input']>;
  gradeF?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minimumScore?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "exams" */
export type Exams_Insert_Input = {
  certificate?: InputMaybe<Certificates_Obj_Rel_Insert_Input>;
  certificateexamId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  gradeA?: InputMaybe<Scalars['Int']['input']>;
  gradeB?: InputMaybe<Scalars['Int']['input']>;
  gradeC?: InputMaybe<Scalars['Int']['input']>;
  gradeD?: InputMaybe<Scalars['Int']['input']>;
  gradeF?: InputMaybe<Scalars['Int']['input']>;
  hearingCouncilHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  hearing_council?: InputMaybe<Hearing_Councils_Obj_Rel_Insert_Input>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minimumScore?: InputMaybe<Scalars['Int']['input']>;
  mutonId?: InputMaybe<Scalars['String']['input']>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_exams?: InputMaybe<User_Exams_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Exams_Max_Fields = {
  __typename?: 'exams_max_fields';
  certificateexamId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  gradeA?: Maybe<Scalars['Int']['output']>;
  gradeB?: Maybe<Scalars['Int']['output']>;
  gradeC?: Maybe<Scalars['Int']['output']>;
  gradeD?: Maybe<Scalars['Int']['output']>;
  gradeF?: Maybe<Scalars['Int']['output']>;
  hearingCouncilHearingCouncilId?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minimumScore?: Maybe<Scalars['Int']['output']>;
  mutonId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Exams_Min_Fields = {
  __typename?: 'exams_min_fields';
  certificateexamId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  gradeA?: Maybe<Scalars['Int']['output']>;
  gradeB?: Maybe<Scalars['Int']['output']>;
  gradeC?: Maybe<Scalars['Int']['output']>;
  gradeD?: Maybe<Scalars['Int']['output']>;
  gradeF?: Maybe<Scalars['Int']['output']>;
  hearingCouncilHearingCouncilId?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minimumScore?: Maybe<Scalars['Int']['output']>;
  mutonId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "exams" */
export type Exams_Mutation_Response = {
  __typename?: 'exams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Exams>;
};

/** input type for inserting object relation for remote table "exams" */
export type Exams_Obj_Rel_Insert_Input = {
  data: Exams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Exams_On_Conflict>;
};

/** on_conflict condition type for table "exams" */
export type Exams_On_Conflict = {
  constraint: Exams_Constraint;
  update_columns?: Array<Exams_Update_Column>;
  where?: InputMaybe<Exams_Bool_Exp>;
};

/** Ordering options when selecting data from "exams". */
export type Exams_Order_By = {
  certificate?: InputMaybe<Certificates_Order_By>;
  certificateexamId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  examId?: InputMaybe<Order_By>;
  gradeA?: InputMaybe<Order_By>;
  gradeB?: InputMaybe<Order_By>;
  gradeC?: InputMaybe<Order_By>;
  gradeD?: InputMaybe<Order_By>;
  gradeF?: InputMaybe<Order_By>;
  hearingCouncilHearingCouncilId?: InputMaybe<Order_By>;
  hearing_council?: InputMaybe<Hearing_Councils_Order_By>;
  limit?: InputMaybe<Order_By>;
  maxAttempts?: InputMaybe<Order_By>;
  minimumScore?: InputMaybe<Order_By>;
  mutonId?: InputMaybe<Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_exams_aggregate?: InputMaybe<User_Exams_Aggregate_Order_By>;
};

/** primary key columns input for table: exams */
export type Exams_Pk_Columns_Input = {
  examId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Exams_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "exams" */
export enum Exams_Select_Column {
  /** column name */
  CertificateexamId = 'certificateexamId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExamId = 'examId',
  /** column name */
  GradeA = 'gradeA',
  /** column name */
  GradeB = 'gradeB',
  /** column name */
  GradeC = 'gradeC',
  /** column name */
  GradeD = 'gradeD',
  /** column name */
  GradeF = 'gradeF',
  /** column name */
  HearingCouncilHearingCouncilId = 'hearingCouncilHearingCouncilId',
  /** column name */
  Limit = 'limit',
  /** column name */
  MaxAttempts = 'maxAttempts',
  /** column name */
  MinimumScore = 'minimumScore',
  /** column name */
  MutonId = 'mutonId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "exams" */
export type Exams_Set_Input = {
  certificateexamId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  gradeA?: InputMaybe<Scalars['Int']['input']>;
  gradeB?: InputMaybe<Scalars['Int']['input']>;
  gradeC?: InputMaybe<Scalars['Int']['input']>;
  gradeD?: InputMaybe<Scalars['Int']['input']>;
  gradeF?: InputMaybe<Scalars['Int']['input']>;
  hearingCouncilHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minimumScore?: InputMaybe<Scalars['Int']['input']>;
  mutonId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Exams_Stddev_Fields = {
  __typename?: 'exams_stddev_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Exams_Stddev_Pop_Fields = {
  __typename?: 'exams_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Exams_Stddev_Samp_Fields = {
  __typename?: 'exams_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "exams" */
export type Exams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exams_Stream_Cursor_Value_Input = {
  certificateexamId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  gradeA?: InputMaybe<Scalars['Int']['input']>;
  gradeB?: InputMaybe<Scalars['Int']['input']>;
  gradeC?: InputMaybe<Scalars['Int']['input']>;
  gradeD?: InputMaybe<Scalars['Int']['input']>;
  gradeF?: InputMaybe<Scalars['Int']['input']>;
  hearingCouncilHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minimumScore?: InputMaybe<Scalars['Int']['input']>;
  mutonId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Exams_Sum_Fields = {
  __typename?: 'exams_sum_fields';
  duration?: Maybe<Scalars['Int']['output']>;
  gradeA?: Maybe<Scalars['Int']['output']>;
  gradeB?: Maybe<Scalars['Int']['output']>;
  gradeC?: Maybe<Scalars['Int']['output']>;
  gradeD?: Maybe<Scalars['Int']['output']>;
  gradeF?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  minimumScore?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "exams" */
export enum Exams_Update_Column {
  /** column name */
  CertificateexamId = 'certificateexamId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExamId = 'examId',
  /** column name */
  GradeA = 'gradeA',
  /** column name */
  GradeB = 'gradeB',
  /** column name */
  GradeC = 'gradeC',
  /** column name */
  GradeD = 'gradeD',
  /** column name */
  GradeF = 'gradeF',
  /** column name */
  HearingCouncilHearingCouncilId = 'hearingCouncilHearingCouncilId',
  /** column name */
  Limit = 'limit',
  /** column name */
  MaxAttempts = 'maxAttempts',
  /** column name */
  MinimumScore = 'minimumScore',
  /** column name */
  MutonId = 'mutonId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Exams_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Exams_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Exams_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Exams_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Exams_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Exams_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Exams_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exams_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Exams_Var_Pop_Fields = {
  __typename?: 'exams_var_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Exams_Var_Samp_Fields = {
  __typename?: 'exams_var_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Exams_Variance_Fields = {
  __typename?: 'exams_variance_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  gradeA?: Maybe<Scalars['Float']['output']>;
  gradeB?: Maybe<Scalars['Float']['output']>;
  gradeC?: Maybe<Scalars['Float']['output']>;
  gradeD?: Maybe<Scalars['Float']['output']>;
  gradeF?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  maxAttempts?: Maybe<Scalars['Float']['output']>;
  minimumScore?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "faqs" */
export type Faqs = {
  __typename?: 'faqs';
  answer: Scalars['jsonb']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  question: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "faqs" */
export type FaqsAnswerArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "faqs" */
export type FaqsQuestionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "faqs" */
export type Faqs_Aggregate = {
  __typename?: 'faqs_aggregate';
  aggregate?: Maybe<Faqs_Aggregate_Fields>;
  nodes: Array<Faqs>;
};

/** aggregate fields of "faqs" */
export type Faqs_Aggregate_Fields = {
  __typename?: 'faqs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Faqs_Max_Fields>;
  min?: Maybe<Faqs_Min_Fields>;
};


/** aggregate fields of "faqs" */
export type Faqs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Faqs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Faqs_Append_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "faqs". All fields are combined with a logical 'AND'. */
export type Faqs_Bool_Exp = {
  _and?: InputMaybe<Array<Faqs_Bool_Exp>>;
  _not?: InputMaybe<Faqs_Bool_Exp>;
  _or?: InputMaybe<Array<Faqs_Bool_Exp>>;
  answer?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "faqs" */
export enum Faqs_Constraint {
  /** unique or primary key constraint on columns "id" */
  FaqsPkey = 'faqs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Faqs_Delete_At_Path_Input = {
  answer?: InputMaybe<Array<Scalars['String']['input']>>;
  question?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Faqs_Delete_Elem_Input = {
  answer?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Faqs_Delete_Key_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "faqs" */
export type Faqs_Insert_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Faqs_Max_Fields = {
  __typename?: 'faqs_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Faqs_Min_Fields = {
  __typename?: 'faqs_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "faqs" */
export type Faqs_Mutation_Response = {
  __typename?: 'faqs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Faqs>;
};

/** on_conflict condition type for table "faqs" */
export type Faqs_On_Conflict = {
  constraint: Faqs_Constraint;
  update_columns?: Array<Faqs_Update_Column>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};

/** Ordering options when selecting data from "faqs". */
export type Faqs_Order_By = {
  answer?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faqs */
export type Faqs_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Faqs_Prepend_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "faqs" */
export enum Faqs_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "faqs" */
export type Faqs_Set_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "faqs" */
export type Faqs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Faqs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Faqs_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "faqs" */
export enum Faqs_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Faqs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Faqs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Faqs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Faqs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Faqs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Faqs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Faqs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Faqs_Bool_Exp;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "files" */
export type Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  authorizedUserId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  schoolUserId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "files" */
export type Files_Max_Order_By = {
  authorizedUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "files" */
export type Files_Min_Order_By = {
  authorizedUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  authorizedUserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SchoolUserId = 'schoolUserId',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SchoolUserId = 'schoolUserId',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

export type Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** Boolean expression to compare columns of type "generalBadgeType". All fields are combined with logical 'AND'. */
export type GeneralBadgeType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['generalBadgeType']['input']>;
  _gt?: InputMaybe<Scalars['generalBadgeType']['input']>;
  _gte?: InputMaybe<Scalars['generalBadgeType']['input']>;
  _in?: InputMaybe<Array<Scalars['generalBadgeType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['generalBadgeType']['input']>;
  _lte?: InputMaybe<Scalars['generalBadgeType']['input']>;
  _neq?: InputMaybe<Scalars['generalBadgeType']['input']>;
  _nin?: InputMaybe<Array<Scalars['generalBadgeType']['input']>>;
};

/** columns and relationships of "group_members" */
export type Group_Members = {
  __typename?: 'group_members';
  chatId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  groupMessagesDeletionDate?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "group_members" */
export type Group_Members_Aggregate = {
  __typename?: 'group_members_aggregate';
  aggregate?: Maybe<Group_Members_Aggregate_Fields>;
  nodes: Array<Group_Members>;
};

/** aggregate fields of "group_members" */
export type Group_Members_Aggregate_Fields = {
  __typename?: 'group_members_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Group_Members_Max_Fields>;
  min?: Maybe<Group_Members_Min_Fields>;
};


/** aggregate fields of "group_members" */
export type Group_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "group_members". All fields are combined with a logical 'AND'. */
export type Group_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Members_Bool_Exp>>;
  _not?: InputMaybe<Group_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Members_Bool_Exp>>;
  chatId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  groupMessagesDeletionDate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_members" */
export enum Group_Members_Constraint {
  /** unique or primary key constraint on columns "chatId", "userId" */
  GroupMembersChatIdUserIdKey = 'group_members_chatId_userId_key',
  /** unique or primary key constraint on columns "id" */
  GroupMembersPkey = 'group_members_pkey'
}

/** input type for inserting data into table "group_members" */
export type Group_Members_Insert_Input = {
  chatId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  groupMessagesDeletionDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Group_Members_Max_Fields = {
  __typename?: 'group_members_max_fields';
  chatId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  groupMessagesDeletionDate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Group_Members_Min_Fields = {
  __typename?: 'group_members_min_fields';
  chatId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  groupMessagesDeletionDate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "group_members" */
export type Group_Members_Mutation_Response = {
  __typename?: 'group_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Members>;
};

/** on_conflict condition type for table "group_members" */
export type Group_Members_On_Conflict = {
  constraint: Group_Members_Constraint;
  update_columns?: Array<Group_Members_Update_Column>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "group_members". */
export type Group_Members_Order_By = {
  chatId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  groupMessagesDeletionDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_members */
export type Group_Members_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "group_members" */
export enum Group_Members_Select_Column {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupMessagesDeletionDate = 'groupMessagesDeletionDate',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "group_members" */
export type Group_Members_Set_Input = {
  chatId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  groupMessagesDeletionDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "group_members" */
export type Group_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Members_Stream_Cursor_Value_Input = {
  chatId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  groupMessagesDeletionDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "group_members" */
export enum Group_Members_Update_Column {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupMessagesDeletionDate = 'groupMessagesDeletionDate',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

export type Group_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Group_Members_Bool_Exp;
};

/** columns and relationships of "hearing_councils" */
export type Hearing_Councils = {
  __typename?: 'hearing_councils';
  appointmentLimit: Scalars['Int']['output'];
  autoCertificate?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  badges: Array<Badges>;
  /** An aggregate relationship */
  badges_aggregate: Badges_Aggregate;
  certificateHearingCouncilId?: Maybe<Scalars['String']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['jsonb']['output']>;
  duration: Scalars['Int']['output'];
  enable_conversation?: Maybe<Scalars['Boolean']['output']>;
  enable_raise_hand?: Maybe<Scalars['Boolean']['output']>;
  enable_recording?: Maybe<Scalars['Boolean']['output']>;
  enable_upload_files?: Maybe<Scalars['Boolean']['output']>;
  enable_video?: Maybe<Scalars['Boolean']['output']>;
  expiryDate?: Maybe<Scalars['timestamptz']['output']>;
  frequency: Scalars['String']['output'];
  hearingCouncilId: Scalars['String']['output'];
  /** An array relationship */
  hearing_councils_user_hearing_councils: Array<User_Hearing_Councils>;
  /** An aggregate relationship */
  hearing_councils_user_hearing_councils_aggregate: User_Hearing_Councils_Aggregate;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  mutoon?: Maybe<Mutoons>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  prerequisites: Array<Prerequisites>;
  /** An aggregate relationship */
  prerequisites_aggregate: Prerequisites_Aggregate;
  price?: Maybe<Scalars['float8']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  sessionName?: Maybe<Scalars['jsonb']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stream_key?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  watch_link?: Maybe<Scalars['String']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsHearing_Councils_User_Hearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsHearing_Councils_User_Hearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsPrerequisitesArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsPrerequisites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


/** columns and relationships of "hearing_councils" */
export type Hearing_CouncilsSessionNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "hearing_councils" */
export type Hearing_Councils_Aggregate = {
  __typename?: 'hearing_councils_aggregate';
  aggregate?: Maybe<Hearing_Councils_Aggregate_Fields>;
  nodes: Array<Hearing_Councils>;
};

export type Hearing_Councils_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp_Var_Samp>;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Avg = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Bool_And = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Corr = {
  arguments: Hearing_Councils_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Max = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Min = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Sum = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Hearing_Councils_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Hearing_Councils_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "hearing_councils" */
export type Hearing_Councils_Aggregate_Fields = {
  __typename?: 'hearing_councils_aggregate_fields';
  avg?: Maybe<Hearing_Councils_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Hearing_Councils_Max_Fields>;
  min?: Maybe<Hearing_Councils_Min_Fields>;
  stddev?: Maybe<Hearing_Councils_Stddev_Fields>;
  stddev_pop?: Maybe<Hearing_Councils_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hearing_Councils_Stddev_Samp_Fields>;
  sum?: Maybe<Hearing_Councils_Sum_Fields>;
  var_pop?: Maybe<Hearing_Councils_Var_Pop_Fields>;
  var_samp?: Maybe<Hearing_Councils_Var_Samp_Fields>;
  variance?: Maybe<Hearing_Councils_Variance_Fields>;
};


/** aggregate fields of "hearing_councils" */
export type Hearing_Councils_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "hearing_councils" */
export type Hearing_Councils_Aggregate_Order_By = {
  avg?: InputMaybe<Hearing_Councils_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hearing_Councils_Max_Order_By>;
  min?: InputMaybe<Hearing_Councils_Min_Order_By>;
  stddev?: InputMaybe<Hearing_Councils_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hearing_Councils_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hearing_Councils_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hearing_Councils_Sum_Order_By>;
  var_pop?: InputMaybe<Hearing_Councils_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hearing_Councils_Var_Samp_Order_By>;
  variance?: InputMaybe<Hearing_Councils_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hearing_Councils_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "hearing_councils" */
export type Hearing_Councils_Arr_Rel_Insert_Input = {
  data: Array<Hearing_Councils_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hearing_Councils_On_Conflict>;
};

/** aggregate avg on columns */
export type Hearing_Councils_Avg_Fields = {
  __typename?: 'hearing_councils_avg_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "hearing_councils" */
export type Hearing_Councils_Avg_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hearing_councils". All fields are combined with a logical 'AND'. */
export type Hearing_Councils_Bool_Exp = {
  _and?: InputMaybe<Array<Hearing_Councils_Bool_Exp>>;
  _not?: InputMaybe<Hearing_Councils_Bool_Exp>;
  _or?: InputMaybe<Array<Hearing_Councils_Bool_Exp>>;
  appointmentLimit?: InputMaybe<Int_Comparison_Exp>;
  autoCertificate?: InputMaybe<Boolean_Comparison_Exp>;
  badges?: InputMaybe<Badges_Bool_Exp>;
  badges_aggregate?: InputMaybe<Badges_Aggregate_Bool_Exp>;
  certificateHearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  courseFileURL?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  days?: InputMaybe<String_Array_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  enable_conversation?: InputMaybe<Boolean_Comparison_Exp>;
  enable_raise_hand?: InputMaybe<Boolean_Comparison_Exp>;
  enable_recording?: InputMaybe<Boolean_Comparison_Exp>;
  enable_upload_files?: InputMaybe<Boolean_Comparison_Exp>;
  enable_video?: InputMaybe<Boolean_Comparison_Exp>;
  expiryDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  hearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  hearing_councils_user_hearing_councils?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  hearing_councils_user_hearing_councils_aggregate?: InputMaybe<User_Hearing_Councils_Aggregate_Bool_Exp>;
  max_user_count?: InputMaybe<Int_Comparison_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonMatnId?: InputMaybe<String_Comparison_Exp>;
  prerequisites?: InputMaybe<Prerequisites_Bool_Exp>;
  prerequisites_aggregate?: InputMaybe<Prerequisites_Aggregate_Bool_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  referenceBookBookId?: InputMaybe<String_Comparison_Exp>;
  roomId?: InputMaybe<String_Comparison_Exp>;
  sessionName?: InputMaybe<Jsonb_Comparison_Exp>;
  sheikhName?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stream_key?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  watch_link?: InputMaybe<String_Comparison_Exp>;
  youTubeLink?: InputMaybe<String_Comparison_Exp>;
  zoomLink?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hearing_councils" */
export enum Hearing_Councils_Constraint {
  /** unique or primary key constraint on columns "hearingCouncilId" */
  HearingCouncilsPkey = 'hearing_councils_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hearing_Councils_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  sessionName?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hearing_Councils_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  sessionName?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hearing_Councils_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  sessionName?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "hearing_councils" */
export type Hearing_Councils_Inc_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "hearing_councils" */
export type Hearing_Councils_Insert_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  autoCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  badges?: InputMaybe<Badges_Arr_Rel_Insert_Input>;
  certificateHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  hearing_councils_user_hearing_councils?: InputMaybe<User_Hearing_Councils_Arr_Rel_Insert_Input>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  prerequisites?: InputMaybe<Prerequisites_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['float8']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stream_key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  watch_link?: InputMaybe<Scalars['String']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Hearing_Councils_Max_Fields = {
  __typename?: 'hearing_councils_max_fields';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  certificateHearingCouncilId?: Maybe<Scalars['String']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  expiryDate?: Maybe<Scalars['timestamptz']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stream_key?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  watch_link?: Maybe<Scalars['String']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "hearing_councils" */
export type Hearing_Councils_Max_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  certificateHearingCouncilId?: InputMaybe<Order_By>;
  courseFileURL?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  sheikhName?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stream_key?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  watch_link?: InputMaybe<Order_By>;
  youTubeLink?: InputMaybe<Order_By>;
  zoomLink?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hearing_Councils_Min_Fields = {
  __typename?: 'hearing_councils_min_fields';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  certificateHearingCouncilId?: Maybe<Scalars['String']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  expiryDate?: Maybe<Scalars['timestamptz']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stream_key?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  watch_link?: Maybe<Scalars['String']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "hearing_councils" */
export type Hearing_Councils_Min_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  certificateHearingCouncilId?: InputMaybe<Order_By>;
  courseFileURL?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  sheikhName?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stream_key?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  watch_link?: InputMaybe<Order_By>;
  youTubeLink?: InputMaybe<Order_By>;
  zoomLink?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hearing_councils" */
export type Hearing_Councils_Mutation_Response = {
  __typename?: 'hearing_councils_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Hearing_Councils>;
};

/** input type for inserting object relation for remote table "hearing_councils" */
export type Hearing_Councils_Obj_Rel_Insert_Input = {
  data: Hearing_Councils_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hearing_Councils_On_Conflict>;
};

/** on_conflict condition type for table "hearing_councils" */
export type Hearing_Councils_On_Conflict = {
  constraint: Hearing_Councils_Constraint;
  update_columns?: Array<Hearing_Councils_Update_Column>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};

/** Ordering options when selecting data from "hearing_councils". */
export type Hearing_Councils_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  autoCertificate?: InputMaybe<Order_By>;
  badges_aggregate?: InputMaybe<Badges_Aggregate_Order_By>;
  certificateHearingCouncilId?: InputMaybe<Order_By>;
  courseFileURL?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  enable_conversation?: InputMaybe<Order_By>;
  enable_raise_hand?: InputMaybe<Order_By>;
  enable_recording?: InputMaybe<Order_By>;
  enable_upload_files?: InputMaybe<Order_By>;
  enable_video?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  hearing_councils_user_hearing_councils_aggregate?: InputMaybe<User_Hearing_Councils_Aggregate_Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  prerequisites_aggregate?: InputMaybe<Prerequisites_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  sessionName?: InputMaybe<Order_By>;
  sheikhName?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stream_key?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  watch_link?: InputMaybe<Order_By>;
  youTubeLink?: InputMaybe<Order_By>;
  zoomLink?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hearing_councils */
export type Hearing_Councils_Pk_Columns_Input = {
  hearingCouncilId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hearing_Councils_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column {
  /** column name */
  AppointmentLimit = 'appointmentLimit',
  /** column name */
  AutoCertificate = 'autoCertificate',
  /** column name */
  CertificateHearingCouncilId = 'certificateHearingCouncilId',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Days = 'days',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EnableConversation = 'enable_conversation',
  /** column name */
  EnableRaiseHand = 'enable_raise_hand',
  /** column name */
  EnableRecording = 'enable_recording',
  /** column name */
  EnableUploadFiles = 'enable_upload_files',
  /** column name */
  EnableVideo = 'enable_video',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  MaxUserCount = 'max_user_count',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Price = 'price',
  /** column name */
  ReferenceBookBookId = 'referenceBookBookId',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  SessionName = 'sessionName',
  /** column name */
  SheikhName = 'sheikhName',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  StreamKey = 'stream_key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WatchLink = 'watch_link',
  /** column name */
  YouTubeLink = 'youTubeLink',
  /** column name */
  ZoomLink = 'zoomLink'
}

/** select "hearing_councils_aggregate_bool_exp_avg_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_bool_and_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AutoCertificate = 'autoCertificate',
  /** column name */
  EnableConversation = 'enable_conversation',
  /** column name */
  EnableRaiseHand = 'enable_raise_hand',
  /** column name */
  EnableRecording = 'enable_recording',
  /** column name */
  EnableUploadFiles = 'enable_upload_files',
  /** column name */
  EnableVideo = 'enable_video'
}

/** select "hearing_councils_aggregate_bool_exp_bool_or_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AutoCertificate = 'autoCertificate',
  /** column name */
  EnableConversation = 'enable_conversation',
  /** column name */
  EnableRaiseHand = 'enable_raise_hand',
  /** column name */
  EnableRecording = 'enable_recording',
  /** column name */
  EnableUploadFiles = 'enable_upload_files',
  /** column name */
  EnableVideo = 'enable_video'
}

/** select "hearing_councils_aggregate_bool_exp_corr_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_max_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_min_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_sum_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "hearing_councils_aggregate_bool_exp_var_samp_arguments_columns" columns of table "hearing_councils" */
export enum Hearing_Councils_Select_Column_Hearing_Councils_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "hearing_councils" */
export type Hearing_Councils_Set_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  autoCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  certificateHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stream_key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  watch_link?: InputMaybe<Scalars['String']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Hearing_Councils_Stddev_Fields = {
  __typename?: 'hearing_councils_stddev_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "hearing_councils" */
export type Hearing_Councils_Stddev_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hearing_Councils_Stddev_Pop_Fields = {
  __typename?: 'hearing_councils_stddev_pop_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "hearing_councils" */
export type Hearing_Councils_Stddev_Pop_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hearing_Councils_Stddev_Samp_Fields = {
  __typename?: 'hearing_councils_stddev_samp_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "hearing_councils" */
export type Hearing_Councils_Stddev_Samp_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hearing_councils" */
export type Hearing_Councils_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hearing_Councils_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hearing_Councils_Stream_Cursor_Value_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  autoCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  certificateHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stream_key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  watch_link?: InputMaybe<Scalars['String']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Hearing_Councils_Sum_Fields = {
  __typename?: 'hearing_councils_sum_fields';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "hearing_councils" */
export type Hearing_Councils_Sum_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** update columns of table "hearing_councils" */
export enum Hearing_Councils_Update_Column {
  /** column name */
  AppointmentLimit = 'appointmentLimit',
  /** column name */
  AutoCertificate = 'autoCertificate',
  /** column name */
  CertificateHearingCouncilId = 'certificateHearingCouncilId',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Days = 'days',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EnableConversation = 'enable_conversation',
  /** column name */
  EnableRaiseHand = 'enable_raise_hand',
  /** column name */
  EnableRecording = 'enable_recording',
  /** column name */
  EnableUploadFiles = 'enable_upload_files',
  /** column name */
  EnableVideo = 'enable_video',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  MaxUserCount = 'max_user_count',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Price = 'price',
  /** column name */
  ReferenceBookBookId = 'referenceBookBookId',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  SessionName = 'sessionName',
  /** column name */
  SheikhName = 'sheikhName',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  StreamKey = 'stream_key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WatchLink = 'watch_link',
  /** column name */
  YouTubeLink = 'youTubeLink',
  /** column name */
  ZoomLink = 'zoomLink'
}

export type Hearing_Councils_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hearing_Councils_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hearing_Councils_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hearing_Councils_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hearing_Councils_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hearing_Councils_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hearing_Councils_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hearing_Councils_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hearing_Councils_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hearing_Councils_Var_Pop_Fields = {
  __typename?: 'hearing_councils_var_pop_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "hearing_councils" */
export type Hearing_Councils_Var_Pop_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hearing_Councils_Var_Samp_Fields = {
  __typename?: 'hearing_councils_var_samp_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "hearing_councils" */
export type Hearing_Councils_Var_Samp_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hearing_Councils_Variance_Fields = {
  __typename?: 'hearing_councils_variance_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "hearing_councils" */
export type Hearing_Councils_Variance_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

export type JoinHearingCouncilResponse = {
  __typename?: 'joinHearingCouncilResponse';
  Badge?: Maybe<Badge>;
  join_link?: Maybe<Scalars['String']['output']>;
  stream_key?: Maybe<Scalars['String']['output']>;
  watch_link?: Maybe<Scalars['String']['output']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "knowledge_bases" */
export type Knowledge_Bases = {
  __typename?: 'knowledge_bases';
  content: Scalars['jsonb']['output'];
  cover_url?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  knowledgeBaseId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  title: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "knowledge_bases" */
export type Knowledge_BasesContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "knowledge_bases" */
export type Knowledge_BasesTitleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "knowledge_bases" */
export type Knowledge_Bases_Aggregate = {
  __typename?: 'knowledge_bases_aggregate';
  aggregate?: Maybe<Knowledge_Bases_Aggregate_Fields>;
  nodes: Array<Knowledge_Bases>;
};

/** aggregate fields of "knowledge_bases" */
export type Knowledge_Bases_Aggregate_Fields = {
  __typename?: 'knowledge_bases_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Knowledge_Bases_Max_Fields>;
  min?: Maybe<Knowledge_Bases_Min_Fields>;
};


/** aggregate fields of "knowledge_bases" */
export type Knowledge_Bases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Knowledge_Bases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Knowledge_Bases_Append_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "knowledge_bases". All fields are combined with a logical 'AND'. */
export type Knowledge_Bases_Bool_Exp = {
  _and?: InputMaybe<Array<Knowledge_Bases_Bool_Exp>>;
  _not?: InputMaybe<Knowledge_Bases_Bool_Exp>;
  _or?: InputMaybe<Array<Knowledge_Bases_Bool_Exp>>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  cover_url?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  knowledgeBaseId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "knowledge_bases" */
export enum Knowledge_Bases_Constraint {
  /** unique or primary key constraint on columns "knowledgeBaseId" */
  KnowledgeBasesPkey = 'knowledge_bases_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Knowledge_Bases_Delete_At_Path_Input = {
  content?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Knowledge_Bases_Delete_Elem_Input = {
  content?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Knowledge_Bases_Delete_Key_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "knowledge_bases" */
export type Knowledge_Bases_Insert_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  cover_url?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  knowledgeBaseId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Knowledge_Bases_Max_Fields = {
  __typename?: 'knowledge_bases_max_fields';
  cover_url?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  knowledgeBaseId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Knowledge_Bases_Min_Fields = {
  __typename?: 'knowledge_bases_min_fields';
  cover_url?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  knowledgeBaseId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "knowledge_bases" */
export type Knowledge_Bases_Mutation_Response = {
  __typename?: 'knowledge_bases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Knowledge_Bases>;
};

/** on_conflict condition type for table "knowledge_bases" */
export type Knowledge_Bases_On_Conflict = {
  constraint: Knowledge_Bases_Constraint;
  update_columns?: Array<Knowledge_Bases_Update_Column>;
  where?: InputMaybe<Knowledge_Bases_Bool_Exp>;
};

/** Ordering options when selecting data from "knowledge_bases". */
export type Knowledge_Bases_Order_By = {
  content?: InputMaybe<Order_By>;
  cover_url?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  knowledgeBaseId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: knowledge_bases */
export type Knowledge_Bases_Pk_Columns_Input = {
  knowledgeBaseId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Knowledge_Bases_Prepend_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "knowledge_bases" */
export enum Knowledge_Bases_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CoverUrl = 'cover_url',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  KnowledgeBaseId = 'knowledgeBaseId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "knowledge_bases" */
export type Knowledge_Bases_Set_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  cover_url?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  knowledgeBaseId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "knowledge_bases" */
export type Knowledge_Bases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Knowledge_Bases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Knowledge_Bases_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  cover_url?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  knowledgeBaseId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "knowledge_bases" */
export enum Knowledge_Bases_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CoverUrl = 'cover_url',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  KnowledgeBaseId = 'knowledgeBaseId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Knowledge_Bases_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Knowledge_Bases_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Knowledge_Bases_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Knowledge_Bases_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Knowledge_Bases_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Knowledge_Bases_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Knowledge_Bases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Knowledge_Bases_Bool_Exp;
};

/** columns and relationships of "lectures_and_webinars" */
export type Lectures_And_Webinars = {
  __typename?: 'lectures_and_webinars';
  lectureId: Scalars['String']['output'];
  lectureType: Scalars['String']['output'];
  lectureUrl: Scalars['String']['output'];
};

/** aggregated selection of "lectures_and_webinars" */
export type Lectures_And_Webinars_Aggregate = {
  __typename?: 'lectures_and_webinars_aggregate';
  aggregate?: Maybe<Lectures_And_Webinars_Aggregate_Fields>;
  nodes: Array<Lectures_And_Webinars>;
};

/** aggregate fields of "lectures_and_webinars" */
export type Lectures_And_Webinars_Aggregate_Fields = {
  __typename?: 'lectures_and_webinars_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Lectures_And_Webinars_Max_Fields>;
  min?: Maybe<Lectures_And_Webinars_Min_Fields>;
};


/** aggregate fields of "lectures_and_webinars" */
export type Lectures_And_Webinars_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lectures_And_Webinars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "lectures_and_webinars". All fields are combined with a logical 'AND'. */
export type Lectures_And_Webinars_Bool_Exp = {
  _and?: InputMaybe<Array<Lectures_And_Webinars_Bool_Exp>>;
  _not?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
  _or?: InputMaybe<Array<Lectures_And_Webinars_Bool_Exp>>;
  lectureId?: InputMaybe<String_Comparison_Exp>;
  lectureType?: InputMaybe<String_Comparison_Exp>;
  lectureUrl?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lectures_and_webinars" */
export enum Lectures_And_Webinars_Constraint {
  /** unique or primary key constraint on columns "lectureId" */
  LecturesAndWebinarsPkey = 'lectures_and_webinars_pkey'
}

/** input type for inserting data into table "lectures_and_webinars" */
export type Lectures_And_Webinars_Insert_Input = {
  lectureId?: InputMaybe<Scalars['String']['input']>;
  lectureType?: InputMaybe<Scalars['String']['input']>;
  lectureUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Lectures_And_Webinars_Max_Fields = {
  __typename?: 'lectures_and_webinars_max_fields';
  lectureId?: Maybe<Scalars['String']['output']>;
  lectureType?: Maybe<Scalars['String']['output']>;
  lectureUrl?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Lectures_And_Webinars_Min_Fields = {
  __typename?: 'lectures_and_webinars_min_fields';
  lectureId?: Maybe<Scalars['String']['output']>;
  lectureType?: Maybe<Scalars['String']['output']>;
  lectureUrl?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "lectures_and_webinars" */
export type Lectures_And_Webinars_Mutation_Response = {
  __typename?: 'lectures_and_webinars_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Lectures_And_Webinars>;
};

/** on_conflict condition type for table "lectures_and_webinars" */
export type Lectures_And_Webinars_On_Conflict = {
  constraint: Lectures_And_Webinars_Constraint;
  update_columns?: Array<Lectures_And_Webinars_Update_Column>;
  where?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
};

/** Ordering options when selecting data from "lectures_and_webinars". */
export type Lectures_And_Webinars_Order_By = {
  lectureId?: InputMaybe<Order_By>;
  lectureType?: InputMaybe<Order_By>;
  lectureUrl?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lectures_and_webinars */
export type Lectures_And_Webinars_Pk_Columns_Input = {
  lectureId: Scalars['String']['input'];
};

/** select columns of table "lectures_and_webinars" */
export enum Lectures_And_Webinars_Select_Column {
  /** column name */
  LectureId = 'lectureId',
  /** column name */
  LectureType = 'lectureType',
  /** column name */
  LectureUrl = 'lectureUrl'
}

/** input type for updating data in table "lectures_and_webinars" */
export type Lectures_And_Webinars_Set_Input = {
  lectureId?: InputMaybe<Scalars['String']['input']>;
  lectureType?: InputMaybe<Scalars['String']['input']>;
  lectureUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "lectures_and_webinars" */
export type Lectures_And_Webinars_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lectures_And_Webinars_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lectures_And_Webinars_Stream_Cursor_Value_Input = {
  lectureId?: InputMaybe<Scalars['String']['input']>;
  lectureType?: InputMaybe<Scalars['String']['input']>;
  lectureUrl?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "lectures_and_webinars" */
export enum Lectures_And_Webinars_Update_Column {
  /** column name */
  LectureId = 'lectureId',
  /** column name */
  LectureType = 'lectureType',
  /** column name */
  LectureUrl = 'lectureUrl'
}

export type Lectures_And_Webinars_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lectures_And_Webinars_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lectures_And_Webinars_Bool_Exp;
};

/** columns and relationships of "levels" */
export type Levels = {
  __typename?: 'levels';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['jsonb']['output']>;
  levelId: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "levels" */
export type LevelsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "levels" */
export type LevelsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "levels" */
export type Levels_Aggregate = {
  __typename?: 'levels_aggregate';
  aggregate?: Maybe<Levels_Aggregate_Fields>;
  nodes: Array<Levels>;
};

export type Levels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Levels_Aggregate_Bool_Exp_Count>;
};

export type Levels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Levels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "levels" */
export type Levels_Aggregate_Fields = {
  __typename?: 'levels_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Levels_Max_Fields>;
  min?: Maybe<Levels_Min_Fields>;
};


/** aggregate fields of "levels" */
export type Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "levels" */
export type Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Levels_Max_Order_By>;
  min?: InputMaybe<Levels_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Levels_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "levels" */
export type Levels_Arr_Rel_Insert_Input = {
  data: Array<Levels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "levels". All fields are combined with a logical 'AND'. */
export type Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Levels_Bool_Exp>>;
  _not?: InputMaybe<Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Levels_Bool_Exp>>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  levelId?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "levels" */
export enum Levels_Constraint {
  /** unique or primary key constraint on columns "levelId" */
  LevelsPkey = 'levels_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Levels_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Levels_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Levels_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "levels" */
export type Levels_Insert_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Levels_Max_Fields = {
  __typename?: 'levels_max_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "levels" */
export type Levels_Max_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  levelId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Levels_Min_Fields = {
  __typename?: 'levels_min_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "levels" */
export type Levels_Min_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  levelId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "levels" */
export type Levels_Mutation_Response = {
  __typename?: 'levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Levels>;
};

/** input type for inserting object relation for remote table "levels" */
export type Levels_Obj_Rel_Insert_Input = {
  data: Levels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};

/** on_conflict condition type for table "levels" */
export type Levels_On_Conflict = {
  constraint: Levels_Constraint;
  update_columns?: Array<Levels_Update_Column>;
  where?: InputMaybe<Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "levels". */
export type Levels_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  levelId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: levels */
export type Levels_Pk_Columns_Input = {
  levelId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Levels_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "levels" */
export enum Levels_Select_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  LevelId = 'levelId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "levels" */
export type Levels_Set_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "levels" */
export type Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Levels_Stream_Cursor_Value_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "levels" */
export enum Levels_Update_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  LevelId = 'levelId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Levels_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Levels_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Levels_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Levels_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Levels_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Levels_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Levels_Bool_Exp;
};

/** columns and relationships of "media_messages" */
export type Media_Messages = {
  __typename?: 'media_messages';
  id: Scalars['String']['output'];
  messageId: Scalars['String']['output'];
  meta: Scalars['jsonb']['output'];
  textContent?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


/** columns and relationships of "media_messages" */
export type Media_MessagesMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "media_messages" */
export type Media_Messages_Aggregate = {
  __typename?: 'media_messages_aggregate';
  aggregate?: Maybe<Media_Messages_Aggregate_Fields>;
  nodes: Array<Media_Messages>;
};

/** aggregate fields of "media_messages" */
export type Media_Messages_Aggregate_Fields = {
  __typename?: 'media_messages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Media_Messages_Max_Fields>;
  min?: Maybe<Media_Messages_Min_Fields>;
};


/** aggregate fields of "media_messages" */
export type Media_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Media_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Media_Messages_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "media_messages". All fields are combined with a logical 'AND'. */
export type Media_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Media_Messages_Bool_Exp>>;
  _not?: InputMaybe<Media_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Media_Messages_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  messageId?: InputMaybe<String_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  textContent?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "media_messages" */
export enum Media_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediaMessagesPkey = 'media_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Media_Messages_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Media_Messages_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Media_Messages_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "media_messages" */
export type Media_Messages_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  textContent?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Media_Messages_Max_Fields = {
  __typename?: 'media_messages_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  textContent?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Media_Messages_Min_Fields = {
  __typename?: 'media_messages_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  textContent?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "media_messages" */
export type Media_Messages_Mutation_Response = {
  __typename?: 'media_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Media_Messages>;
};

/** on_conflict condition type for table "media_messages" */
export type Media_Messages_On_Conflict = {
  constraint: Media_Messages_Constraint;
  update_columns?: Array<Media_Messages_Update_Column>;
  where?: InputMaybe<Media_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "media_messages". */
export type Media_Messages_Order_By = {
  id?: InputMaybe<Order_By>;
  messageId?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  textContent?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: media_messages */
export type Media_Messages_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Media_Messages_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "media_messages" */
export enum Media_Messages_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  Meta = 'meta',
  /** column name */
  TextContent = 'textContent',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "media_messages" */
export type Media_Messages_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  textContent?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "media_messages" */
export type Media_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Media_Messages_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  textContent?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "media_messages" */
export enum Media_Messages_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  Meta = 'meta',
  /** column name */
  TextContent = 'textContent',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

export type Media_Messages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Media_Messages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Media_Messages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Media_Messages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Media_Messages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Media_Messages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Media_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Media_Messages_Bool_Exp;
};

/** columns and relationships of "meetings" */
export type Meetings = {
  __typename?: 'meetings';
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discussionRoomId?: Maybe<Scalars['String']['output']>;
  expires: Scalars['timestamp']['output'];
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  moderatorId: Scalars['String']['output'];
  notbefore: Scalars['timestamp']['output'];
  roomId: Scalars['String']['output'];
  scientificSessionId?: Maybe<Scalars['String']['output']>;
  type: Scalars['MeetingType']['output'];
  updatedAt: Scalars['timestamp']['output'];
};

/** aggregated selection of "meetings" */
export type Meetings_Aggregate = {
  __typename?: 'meetings_aggregate';
  aggregate?: Maybe<Meetings_Aggregate_Fields>;
  nodes: Array<Meetings>;
};

/** aggregate fields of "meetings" */
export type Meetings_Aggregate_Fields = {
  __typename?: 'meetings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Meetings_Max_Fields>;
  min?: Maybe<Meetings_Min_Fields>;
};


/** aggregate fields of "meetings" */
export type Meetings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meetings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "meetings". All fields are combined with a logical 'AND'. */
export type Meetings_Bool_Exp = {
  _and?: InputMaybe<Array<Meetings_Bool_Exp>>;
  _not?: InputMaybe<Meetings_Bool_Exp>;
  _or?: InputMaybe<Array<Meetings_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discussionRoomId?: InputMaybe<String_Comparison_Exp>;
  expires?: InputMaybe<Timestamp_Comparison_Exp>;
  hearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  moderatorId?: InputMaybe<String_Comparison_Exp>;
  notbefore?: InputMaybe<Timestamp_Comparison_Exp>;
  roomId?: InputMaybe<String_Comparison_Exp>;
  scientificSessionId?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<MeetingType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "meetings" */
export enum Meetings_Constraint {
  /** unique or primary key constraint on columns "id" */
  MeetingsPkey = 'meetings_pkey'
}

/** input type for inserting data into table "meetings" */
export type Meetings_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussionRoomId?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['timestamp']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  moderatorId?: InputMaybe<Scalars['String']['input']>;
  notbefore?: InputMaybe<Scalars['timestamp']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  scientificSessionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['MeetingType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Meetings_Max_Fields = {
  __typename?: 'meetings_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discussionRoomId?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['timestamp']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  moderatorId?: Maybe<Scalars['String']['output']>;
  notbefore?: Maybe<Scalars['timestamp']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  scientificSessionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['MeetingType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Meetings_Min_Fields = {
  __typename?: 'meetings_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discussionRoomId?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['timestamp']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  moderatorId?: Maybe<Scalars['String']['output']>;
  notbefore?: Maybe<Scalars['timestamp']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  scientificSessionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['MeetingType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "meetings" */
export type Meetings_Mutation_Response = {
  __typename?: 'meetings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Meetings>;
};

/** on_conflict condition type for table "meetings" */
export type Meetings_On_Conflict = {
  constraint: Meetings_Constraint;
  update_columns?: Array<Meetings_Update_Column>;
  where?: InputMaybe<Meetings_Bool_Exp>;
};

/** Ordering options when selecting data from "meetings". */
export type Meetings_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discussionRoomId?: InputMaybe<Order_By>;
  expires?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  moderatorId?: InputMaybe<Order_By>;
  notbefore?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  scientificSessionId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: meetings */
export type Meetings_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "meetings" */
export enum Meetings_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionRoomId = 'discussionRoomId',
  /** column name */
  Expires = 'expires',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  ModeratorId = 'moderatorId',
  /** column name */
  Notbefore = 'notbefore',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  ScientificSessionId = 'scientificSessionId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "meetings" */
export type Meetings_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussionRoomId?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['timestamp']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  moderatorId?: InputMaybe<Scalars['String']['input']>;
  notbefore?: InputMaybe<Scalars['timestamp']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  scientificSessionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['MeetingType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "meetings" */
export type Meetings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meetings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meetings_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussionRoomId?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['timestamp']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  moderatorId?: InputMaybe<Scalars['String']['input']>;
  notbefore?: InputMaybe<Scalars['timestamp']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  scientificSessionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['MeetingType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "meetings" */
export enum Meetings_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionRoomId = 'discussionRoomId',
  /** column name */
  Expires = 'expires',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  ModeratorId = 'moderatorId',
  /** column name */
  Notbefore = 'notbefore',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  ScientificSessionId = 'scientificSessionId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Meetings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meetings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meetings_Bool_Exp;
};

/** columns and relationships of "message_recipients" */
export type Message_Recipients = {
  __typename?: 'message_recipients';
  groupMemberId: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  messageId: Scalars['String']['output'];
};

/** aggregated selection of "message_recipients" */
export type Message_Recipients_Aggregate = {
  __typename?: 'message_recipients_aggregate';
  aggregate?: Maybe<Message_Recipients_Aggregate_Fields>;
  nodes: Array<Message_Recipients>;
};

/** aggregate fields of "message_recipients" */
export type Message_Recipients_Aggregate_Fields = {
  __typename?: 'message_recipients_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Message_Recipients_Max_Fields>;
  min?: Maybe<Message_Recipients_Min_Fields>;
};


/** aggregate fields of "message_recipients" */
export type Message_Recipients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Message_Recipients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "message_recipients". All fields are combined with a logical 'AND'. */
export type Message_Recipients_Bool_Exp = {
  _and?: InputMaybe<Array<Message_Recipients_Bool_Exp>>;
  _not?: InputMaybe<Message_Recipients_Bool_Exp>;
  _or?: InputMaybe<Array<Message_Recipients_Bool_Exp>>;
  groupMemberId?: InputMaybe<String_Comparison_Exp>;
  isRead?: InputMaybe<Boolean_Comparison_Exp>;
  messageId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_recipients" */
export enum Message_Recipients_Constraint {
  /** unique or primary key constraint on columns "messageId", "groupMemberId" */
  MessageRecipientsPkey = 'message_recipients_pkey'
}

/** input type for inserting data into table "message_recipients" */
export type Message_Recipients_Insert_Input = {
  groupMemberId?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Message_Recipients_Max_Fields = {
  __typename?: 'message_recipients_max_fields';
  groupMemberId?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Message_Recipients_Min_Fields = {
  __typename?: 'message_recipients_min_fields';
  groupMemberId?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "message_recipients" */
export type Message_Recipients_Mutation_Response = {
  __typename?: 'message_recipients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Recipients>;
};

/** on_conflict condition type for table "message_recipients" */
export type Message_Recipients_On_Conflict = {
  constraint: Message_Recipients_Constraint;
  update_columns?: Array<Message_Recipients_Update_Column>;
  where?: InputMaybe<Message_Recipients_Bool_Exp>;
};

/** Ordering options when selecting data from "message_recipients". */
export type Message_Recipients_Order_By = {
  groupMemberId?: InputMaybe<Order_By>;
  isRead?: InputMaybe<Order_By>;
  messageId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: message_recipients */
export type Message_Recipients_Pk_Columns_Input = {
  groupMemberId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};

/** select columns of table "message_recipients" */
export enum Message_Recipients_Select_Column {
  /** column name */
  GroupMemberId = 'groupMemberId',
  /** column name */
  IsRead = 'isRead',
  /** column name */
  MessageId = 'messageId'
}

/** input type for updating data in table "message_recipients" */
export type Message_Recipients_Set_Input = {
  groupMemberId?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "message_recipients" */
export type Message_Recipients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Message_Recipients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Message_Recipients_Stream_Cursor_Value_Input = {
  groupMemberId?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "message_recipients" */
export enum Message_Recipients_Update_Column {
  /** column name */
  GroupMemberId = 'groupMemberId',
  /** column name */
  IsRead = 'isRead',
  /** column name */
  MessageId = 'messageId'
}

export type Message_Recipients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Message_Recipients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Message_Recipients_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  SendEmailToContact: Contact;
  appleSignin?: Maybe<UserAuthResponse>;
  attendOnsite: UserHearingCouncil;
  claimBadge: Badge;
  createAdmin?: Maybe<AdminAuthResponse>;
  createGeneralMessageNotifications: SendNotificationsResponse;
  createHearingCouncil: HearingCouncil;
  createSession: Session;
  createUserExamCertificate: ExamSubmission;
  createUserHearingCouncilCertificate: Array<UserHearingCouncil>;
  deActivateAdmin?: Maybe<Admin>;
  deActivateUser?: Maybe<User>;
  /** delete data from the table: "_DonationHistoryToTransaction" */
  delete__DonationHistoryToTransaction?: Maybe<_DonationHistoryToTransaction_Mutation_Response>;
  /** delete data from the table: "actions" */
  delete_actions?: Maybe<Actions_Mutation_Response>;
  /** delete single row from the table: "actions" */
  delete_actions_by_pk?: Maybe<Actions>;
  /** delete data from the table: "admins" */
  delete_admins?: Maybe<Admins_Mutation_Response>;
  /** delete single row from the table: "admins" */
  delete_admins_by_pk?: Maybe<Admins>;
  /** delete data from the table: "answers" */
  delete_answers?: Maybe<Answers_Mutation_Response>;
  /** delete single row from the table: "answers" */
  delete_answers_by_pk?: Maybe<Answers>;
  /** delete data from the table: "authorized_users" */
  delete_authorized_users?: Maybe<Authorized_Users_Mutation_Response>;
  /** delete single row from the table: "authorized_users" */
  delete_authorized_users_by_pk?: Maybe<Authorized_Users>;
  /** delete data from the table: "badges" */
  delete_badges?: Maybe<Badges_Mutation_Response>;
  /** delete single row from the table: "badges" */
  delete_badges_by_pk?: Maybe<Badges>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "certificate_variables" */
  delete_certificate_variables?: Maybe<Certificate_Variables_Mutation_Response>;
  /** delete single row from the table: "certificate_variables" */
  delete_certificate_variables_by_pk?: Maybe<Certificate_Variables>;
  /** delete data from the table: "certificates" */
  delete_certificates?: Maybe<Certificates_Mutation_Response>;
  /** delete single row from the table: "certificates" */
  delete_certificates_by_pk?: Maybe<Certificates>;
  /** delete data from the table: "chat_messages" */
  delete_chat_messages?: Maybe<Chat_Messages_Mutation_Response>;
  /** delete single row from the table: "chat_messages" */
  delete_chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** delete data from the table: "chats" */
  delete_chats?: Maybe<Chats_Mutation_Response>;
  /** delete single row from the table: "chats" */
  delete_chats_by_pk?: Maybe<Chats>;
  /** delete data from the table: "contact_us" */
  delete_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** delete single row from the table: "contact_us" */
  delete_contact_us_by_pk?: Maybe<Contact_Us>;
  /** delete data from the table: "content_pages" */
  delete_content_pages?: Maybe<Content_Pages_Mutation_Response>;
  /** delete single row from the table: "content_pages" */
  delete_content_pages_by_pk?: Maybe<Content_Pages>;
  /** delete data from the table: "content_recommendations" */
  delete_content_recommendations?: Maybe<Content_Recommendations_Mutation_Response>;
  /** delete single row from the table: "content_recommendations" */
  delete_content_recommendations_by_pk?: Maybe<Content_Recommendations>;
  /** delete data from the table: "countries" */
  delete_countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "countries" */
  delete_countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "discussion_rooms" */
  delete_discussion_rooms?: Maybe<Discussion_Rooms_Mutation_Response>;
  /** delete single row from the table: "discussion_rooms" */
  delete_discussion_rooms_by_pk?: Maybe<Discussion_Rooms>;
  /** delete data from the table: "donation_histories" */
  delete_donation_histories?: Maybe<Donation_Histories_Mutation_Response>;
  /** delete single row from the table: "donation_histories" */
  delete_donation_histories_by_pk?: Maybe<Donation_Histories>;
  /** delete data from the table: "donations" */
  delete_donations?: Maybe<Donations_Mutation_Response>;
  /** delete single row from the table: "donations" */
  delete_donations_by_pk?: Maybe<Donations>;
  /** delete data from the table: "event_reminders" */
  delete_event_reminders?: Maybe<Event_Reminders_Mutation_Response>;
  /** delete single row from the table: "event_reminders" */
  delete_event_reminders_by_pk?: Maybe<Event_Reminders>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "exams" */
  delete_exams?: Maybe<Exams_Mutation_Response>;
  /** delete single row from the table: "exams" */
  delete_exams_by_pk?: Maybe<Exams>;
  /** delete data from the table: "faqs" */
  delete_faqs?: Maybe<Faqs_Mutation_Response>;
  /** delete single row from the table: "faqs" */
  delete_faqs_by_pk?: Maybe<Faqs>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "group_members" */
  delete_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** delete single row from the table: "group_members" */
  delete_group_members_by_pk?: Maybe<Group_Members>;
  /** delete data from the table: "hearing_councils" */
  delete_hearing_councils?: Maybe<Hearing_Councils_Mutation_Response>;
  /** delete single row from the table: "hearing_councils" */
  delete_hearing_councils_by_pk?: Maybe<Hearing_Councils>;
  /** delete data from the table: "knowledge_bases" */
  delete_knowledge_bases?: Maybe<Knowledge_Bases_Mutation_Response>;
  /** delete single row from the table: "knowledge_bases" */
  delete_knowledge_bases_by_pk?: Maybe<Knowledge_Bases>;
  /** delete data from the table: "lectures_and_webinars" */
  delete_lectures_and_webinars?: Maybe<Lectures_And_Webinars_Mutation_Response>;
  /** delete single row from the table: "lectures_and_webinars" */
  delete_lectures_and_webinars_by_pk?: Maybe<Lectures_And_Webinars>;
  /** delete data from the table: "levels" */
  delete_levels?: Maybe<Levels_Mutation_Response>;
  /** delete single row from the table: "levels" */
  delete_levels_by_pk?: Maybe<Levels>;
  /** delete data from the table: "media_messages" */
  delete_media_messages?: Maybe<Media_Messages_Mutation_Response>;
  /** delete single row from the table: "media_messages" */
  delete_media_messages_by_pk?: Maybe<Media_Messages>;
  /** delete data from the table: "meetings" */
  delete_meetings?: Maybe<Meetings_Mutation_Response>;
  /** delete single row from the table: "meetings" */
  delete_meetings_by_pk?: Maybe<Meetings>;
  /** delete data from the table: "message_recipients" */
  delete_message_recipients?: Maybe<Message_Recipients_Mutation_Response>;
  /** delete single row from the table: "message_recipients" */
  delete_message_recipients_by_pk?: Maybe<Message_Recipients>;
  /** delete data from the table: "mutoon_categories" */
  delete_mutoon_categories?: Maybe<Mutoon_Categories_Mutation_Response>;
  /** delete single row from the table: "mutoon_categories" */
  delete_mutoon_categories_by_pk?: Maybe<Mutoon_Categories>;
  /** delete data from the table: "mutoon_sections" */
  delete_mutoon_sections?: Maybe<Mutoon_Sections_Mutation_Response>;
  /** delete single row from the table: "mutoon_sections" */
  delete_mutoon_sections_by_pk?: Maybe<Mutoon_Sections>;
  /** delete data from the table: "mutoons" */
  delete_mutoons?: Maybe<Mutoons_Mutation_Response>;
  /** delete single row from the table: "mutoons" */
  delete_mutoons_by_pk?: Maybe<Mutoons>;
  /** delete data from the table: "notes" */
  delete_notes?: Maybe<Notes_Mutation_Response>;
  /** delete single row from the table: "notes" */
  delete_notes_by_pk?: Maybe<Notes>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "payment_methods" */
  delete_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** delete single row from the table: "payment_methods" */
  delete_payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** delete data from the table: "plan_sections" */
  delete_plan_sections?: Maybe<Plan_Sections_Mutation_Response>;
  /** delete single row from the table: "plan_sections" */
  delete_plan_sections_by_pk?: Maybe<Plan_Sections>;
  /** delete data from the table: "plans" */
  delete_plans?: Maybe<Plans_Mutation_Response>;
  /** delete single row from the table: "plans" */
  delete_plans_by_pk?: Maybe<Plans>;
  /** delete data from the table: "platform_settings" */
  delete_platform_settings?: Maybe<Platform_Settings_Mutation_Response>;
  /** delete single row from the table: "platform_settings" */
  delete_platform_settings_by_pk?: Maybe<Platform_Settings>;
  /** delete data from the table: "prerequisites" */
  delete_prerequisites?: Maybe<Prerequisites_Mutation_Response>;
  /** delete single row from the table: "prerequisites" */
  delete_prerequisites_by_pk?: Maybe<Prerequisites>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "ratings_and_reviews" */
  delete_ratings_and_reviews?: Maybe<Ratings_And_Reviews_Mutation_Response>;
  /** delete single row from the table: "ratings_and_reviews" */
  delete_ratings_and_reviews_by_pk?: Maybe<Ratings_And_Reviews>;
  /** delete data from the table: "reference_books" */
  delete_reference_books?: Maybe<Reference_Books_Mutation_Response>;
  /** delete single row from the table: "reference_books" */
  delete_reference_books_by_pk?: Maybe<Reference_Books>;
  /** delete data from the table: "reports" */
  delete_reports?: Maybe<Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete data from the table: "roles_actions" */
  delete_roles_actions?: Maybe<Roles_Actions_Mutation_Response>;
  /** delete single row from the table: "roles_actions" */
  delete_roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "scheduled_notifications" */
  delete_scheduled_notifications?: Maybe<Scheduled_Notifications_Mutation_Response>;
  /** delete single row from the table: "scheduled_notifications" */
  delete_scheduled_notifications_by_pk?: Maybe<Scheduled_Notifications>;
  /** delete data from the table: "school_users" */
  delete_school_users?: Maybe<School_Users_Mutation_Response>;
  /** delete single row from the table: "school_users" */
  delete_school_users_by_pk?: Maybe<School_Users>;
  /** delete data from the table: "scientific_sessions" */
  delete_scientific_sessions?: Maybe<Scientific_Sessions_Mutation_Response>;
  /** delete single row from the table: "scientific_sessions" */
  delete_scientific_sessions_by_pk?: Maybe<Scientific_Sessions>;
  /** delete data from the table: "self_recitations" */
  delete_self_recitations?: Maybe<Self_Recitations_Mutation_Response>;
  /** delete single row from the table: "self_recitations" */
  delete_self_recitations_by_pk?: Maybe<Self_Recitations>;
  /** delete data from the table: "sessions" */
  delete_sessions?: Maybe<Sessions_Mutation_Response>;
  /** delete single row from the table: "sessions" */
  delete_sessions_by_pk?: Maybe<Sessions>;
  /** delete data from the table: "sounds" */
  delete_sounds?: Maybe<Sounds_Mutation_Response>;
  /** delete single row from the table: "sounds" */
  delete_sounds_by_pk?: Maybe<Sounds>;
  /** delete data from the table: "statistics" */
  delete_statistics?: Maybe<Statistics_Mutation_Response>;
  /** delete single row from the table: "statistics" */
  delete_statistics_by_pk?: Maybe<Statistics>;
  /** delete data from the table: "students" */
  delete_students?: Maybe<Students_Mutation_Response>;
  /** delete single row from the table: "students" */
  delete_students_by_pk?: Maybe<Students>;
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** delete data from the table: "text_messages" */
  delete_text_messages?: Maybe<Text_Messages_Mutation_Response>;
  /** delete single row from the table: "text_messages" */
  delete_text_messages_by_pk?: Maybe<Text_Messages>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "translations" */
  delete_translations?: Maybe<Translations_Mutation_Response>;
  /** delete single row from the table: "translations" */
  delete_translations_by_pk?: Maybe<Translations>;
  /** delete data from the table: "user_badges" */
  delete_user_badges?: Maybe<User_Badges_Mutation_Response>;
  /** delete single row from the table: "user_badges" */
  delete_user_badges_by_pk?: Maybe<User_Badges>;
  /** delete data from the table: "user_categories" */
  delete_user_categories?: Maybe<User_Categories_Mutation_Response>;
  /** delete single row from the table: "user_categories" */
  delete_user_categories_by_pk?: Maybe<User_Categories>;
  /** delete data from the table: "user_certificates" */
  delete_user_certificates?: Maybe<User_Certificates_Mutation_Response>;
  /** delete single row from the table: "user_certificates" */
  delete_user_certificates_by_pk?: Maybe<User_Certificates>;
  /** delete data from the table: "user_credit_card_infos" */
  delete_user_credit_card_infos?: Maybe<User_Credit_Card_Infos_Mutation_Response>;
  /** delete single row from the table: "user_credit_card_infos" */
  delete_user_credit_card_infos_by_pk?: Maybe<User_Credit_Card_Infos>;
  /** delete data from the table: "user_discussion_rooms" */
  delete_user_discussion_rooms?: Maybe<User_Discussion_Rooms_Mutation_Response>;
  /** delete single row from the table: "user_discussion_rooms" */
  delete_user_discussion_rooms_by_pk?: Maybe<User_Discussion_Rooms>;
  /** delete data from the table: "user_exams" */
  delete_user_exams?: Maybe<User_Exams_Mutation_Response>;
  /** delete single row from the table: "user_exams" */
  delete_user_exams_by_pk?: Maybe<User_Exams>;
  /** delete data from the table: "user_hearing_councils" */
  delete_user_hearing_councils?: Maybe<User_Hearing_Councils_Mutation_Response>;
  /** delete single row from the table: "user_hearing_councils" */
  delete_user_hearing_councils_by_pk?: Maybe<User_Hearing_Councils>;
  /** delete data from the table: "user_meetings" */
  delete_user_meetings?: Maybe<User_Meetings_Mutation_Response>;
  /** delete single row from the table: "user_meetings" */
  delete_user_meetings_by_pk?: Maybe<User_Meetings>;
  /** delete data from the table: "user_preferences" */
  delete_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** delete single row from the table: "user_preferences" */
  delete_user_preferences_by_pk?: Maybe<User_Preferences>;
  /** delete data from the table: "user_quizes" */
  delete_user_quizes?: Maybe<User_Quizes_Mutation_Response>;
  /** delete single row from the table: "user_quizes" */
  delete_user_quizes_by_pk?: Maybe<User_Quizes>;
  /** delete data from the table: "user_records" */
  delete_user_records?: Maybe<User_Records_Mutation_Response>;
  /** delete single row from the table: "user_records" */
  delete_user_records_by_pk?: Maybe<User_Records>;
  /** delete data from the table: "user_scientific_sessions" */
  delete_user_scientific_sessions?: Maybe<User_Scientific_Sessions_Mutation_Response>;
  /** delete single row from the table: "user_scientific_sessions" */
  delete_user_scientific_sessions_by_pk?: Maybe<User_Scientific_Sessions>;
  /** delete data from the table: "user_sessions" */
  delete_user_sessions?: Maybe<User_Sessions_Mutation_Response>;
  /** delete single row from the table: "user_sessions" */
  delete_user_sessions_by_pk?: Maybe<User_Sessions>;
  /** delete data from the table: "user_subscriptions" */
  delete_user_subscriptions?: Maybe<User_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "user_subscriptions" */
  delete_user_subscriptions_by_pk?: Maybe<User_Subscriptions>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  forgetPassword?: Maybe<SignUpResponse>;
  getAchievementsByPlanId: Achievement;
  googleSignin?: Maybe<UserAuthResponse>;
  /** insert data into the table: "_DonationHistoryToTransaction" */
  insert__DonationHistoryToTransaction?: Maybe<_DonationHistoryToTransaction_Mutation_Response>;
  /** insert a single row into the table: "_DonationHistoryToTransaction" */
  insert__DonationHistoryToTransaction_one?: Maybe<_DonationHistoryToTransaction>;
  /** insert data into the table: "actions" */
  insert_actions?: Maybe<Actions_Mutation_Response>;
  /** insert a single row into the table: "actions" */
  insert_actions_one?: Maybe<Actions>;
  /** insert data into the table: "admins" */
  insert_admins?: Maybe<Admins_Mutation_Response>;
  /** insert a single row into the table: "admins" */
  insert_admins_one?: Maybe<Admins>;
  /** insert data into the table: "answers" */
  insert_answers?: Maybe<Answers_Mutation_Response>;
  /** insert a single row into the table: "answers" */
  insert_answers_one?: Maybe<Answers>;
  /** insert data into the table: "authorized_users" */
  insert_authorized_users?: Maybe<Authorized_Users_Mutation_Response>;
  /** insert a single row into the table: "authorized_users" */
  insert_authorized_users_one?: Maybe<Authorized_Users>;
  /** insert data into the table: "badges" */
  insert_badges?: Maybe<Badges_Mutation_Response>;
  /** insert a single row into the table: "badges" */
  insert_badges_one?: Maybe<Badges>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "certificate_variables" */
  insert_certificate_variables?: Maybe<Certificate_Variables_Mutation_Response>;
  /** insert a single row into the table: "certificate_variables" */
  insert_certificate_variables_one?: Maybe<Certificate_Variables>;
  /** insert data into the table: "certificates" */
  insert_certificates?: Maybe<Certificates_Mutation_Response>;
  /** insert a single row into the table: "certificates" */
  insert_certificates_one?: Maybe<Certificates>;
  /** insert data into the table: "chat_messages" */
  insert_chat_messages?: Maybe<Chat_Messages_Mutation_Response>;
  /** insert a single row into the table: "chat_messages" */
  insert_chat_messages_one?: Maybe<Chat_Messages>;
  /** insert data into the table: "chats" */
  insert_chats?: Maybe<Chats_Mutation_Response>;
  /** insert a single row into the table: "chats" */
  insert_chats_one?: Maybe<Chats>;
  /** insert data into the table: "contact_us" */
  insert_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** insert a single row into the table: "contact_us" */
  insert_contact_us_one?: Maybe<Contact_Us>;
  /** insert data into the table: "content_pages" */
  insert_content_pages?: Maybe<Content_Pages_Mutation_Response>;
  /** insert a single row into the table: "content_pages" */
  insert_content_pages_one?: Maybe<Content_Pages>;
  /** insert data into the table: "content_recommendations" */
  insert_content_recommendations?: Maybe<Content_Recommendations_Mutation_Response>;
  /** insert a single row into the table: "content_recommendations" */
  insert_content_recommendations_one?: Maybe<Content_Recommendations>;
  /** insert data into the table: "countries" */
  insert_countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "countries" */
  insert_countries_one?: Maybe<Countries>;
  /** insert data into the table: "discussion_rooms" */
  insert_discussion_rooms?: Maybe<Discussion_Rooms_Mutation_Response>;
  /** insert a single row into the table: "discussion_rooms" */
  insert_discussion_rooms_one?: Maybe<Discussion_Rooms>;
  /** insert data into the table: "donation_histories" */
  insert_donation_histories?: Maybe<Donation_Histories_Mutation_Response>;
  /** insert a single row into the table: "donation_histories" */
  insert_donation_histories_one?: Maybe<Donation_Histories>;
  /** insert data into the table: "donations" */
  insert_donations?: Maybe<Donations_Mutation_Response>;
  /** insert a single row into the table: "donations" */
  insert_donations_one?: Maybe<Donations>;
  /** insert data into the table: "event_reminders" */
  insert_event_reminders?: Maybe<Event_Reminders_Mutation_Response>;
  /** insert a single row into the table: "event_reminders" */
  insert_event_reminders_one?: Maybe<Event_Reminders>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "exams" */
  insert_exams?: Maybe<Exams_Mutation_Response>;
  /** insert a single row into the table: "exams" */
  insert_exams_one?: Maybe<Exams>;
  /** insert data into the table: "faqs" */
  insert_faqs?: Maybe<Faqs_Mutation_Response>;
  /** insert a single row into the table: "faqs" */
  insert_faqs_one?: Maybe<Faqs>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "group_members" */
  insert_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** insert a single row into the table: "group_members" */
  insert_group_members_one?: Maybe<Group_Members>;
  /** insert data into the table: "hearing_councils" */
  insert_hearing_councils?: Maybe<Hearing_Councils_Mutation_Response>;
  /** insert a single row into the table: "hearing_councils" */
  insert_hearing_councils_one?: Maybe<Hearing_Councils>;
  /** insert data into the table: "knowledge_bases" */
  insert_knowledge_bases?: Maybe<Knowledge_Bases_Mutation_Response>;
  /** insert a single row into the table: "knowledge_bases" */
  insert_knowledge_bases_one?: Maybe<Knowledge_Bases>;
  /** insert data into the table: "lectures_and_webinars" */
  insert_lectures_and_webinars?: Maybe<Lectures_And_Webinars_Mutation_Response>;
  /** insert a single row into the table: "lectures_and_webinars" */
  insert_lectures_and_webinars_one?: Maybe<Lectures_And_Webinars>;
  /** insert data into the table: "levels" */
  insert_levels?: Maybe<Levels_Mutation_Response>;
  /** insert a single row into the table: "levels" */
  insert_levels_one?: Maybe<Levels>;
  /** insert data into the table: "media_messages" */
  insert_media_messages?: Maybe<Media_Messages_Mutation_Response>;
  /** insert a single row into the table: "media_messages" */
  insert_media_messages_one?: Maybe<Media_Messages>;
  /** insert data into the table: "meetings" */
  insert_meetings?: Maybe<Meetings_Mutation_Response>;
  /** insert a single row into the table: "meetings" */
  insert_meetings_one?: Maybe<Meetings>;
  /** insert data into the table: "message_recipients" */
  insert_message_recipients?: Maybe<Message_Recipients_Mutation_Response>;
  /** insert a single row into the table: "message_recipients" */
  insert_message_recipients_one?: Maybe<Message_Recipients>;
  /** insert data into the table: "mutoon_categories" */
  insert_mutoon_categories?: Maybe<Mutoon_Categories_Mutation_Response>;
  /** insert a single row into the table: "mutoon_categories" */
  insert_mutoon_categories_one?: Maybe<Mutoon_Categories>;
  /** insert data into the table: "mutoon_sections" */
  insert_mutoon_sections?: Maybe<Mutoon_Sections_Mutation_Response>;
  /** insert a single row into the table: "mutoon_sections" */
  insert_mutoon_sections_one?: Maybe<Mutoon_Sections>;
  /** insert data into the table: "mutoons" */
  insert_mutoons?: Maybe<Mutoons_Mutation_Response>;
  /** insert a single row into the table: "mutoons" */
  insert_mutoons_one?: Maybe<Mutoons>;
  /** insert data into the table: "notes" */
  insert_notes?: Maybe<Notes_Mutation_Response>;
  /** insert a single row into the table: "notes" */
  insert_notes_one?: Maybe<Notes>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "payment_methods" */
  insert_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** insert a single row into the table: "payment_methods" */
  insert_payment_methods_one?: Maybe<Payment_Methods>;
  /** insert data into the table: "plan_sections" */
  insert_plan_sections?: Maybe<Plan_Sections_Mutation_Response>;
  /** insert a single row into the table: "plan_sections" */
  insert_plan_sections_one?: Maybe<Plan_Sections>;
  /** insert data into the table: "plans" */
  insert_plans?: Maybe<Plans_Mutation_Response>;
  /** insert a single row into the table: "plans" */
  insert_plans_one?: Maybe<Plans>;
  /** insert data into the table: "platform_settings" */
  insert_platform_settings?: Maybe<Platform_Settings_Mutation_Response>;
  /** insert a single row into the table: "platform_settings" */
  insert_platform_settings_one?: Maybe<Platform_Settings>;
  /** insert data into the table: "prerequisites" */
  insert_prerequisites?: Maybe<Prerequisites_Mutation_Response>;
  /** insert a single row into the table: "prerequisites" */
  insert_prerequisites_one?: Maybe<Prerequisites>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "ratings_and_reviews" */
  insert_ratings_and_reviews?: Maybe<Ratings_And_Reviews_Mutation_Response>;
  /** insert a single row into the table: "ratings_and_reviews" */
  insert_ratings_and_reviews_one?: Maybe<Ratings_And_Reviews>;
  /** insert data into the table: "reference_books" */
  insert_reference_books?: Maybe<Reference_Books_Mutation_Response>;
  /** insert a single row into the table: "reference_books" */
  insert_reference_books_one?: Maybe<Reference_Books>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert data into the table: "roles_actions" */
  insert_roles_actions?: Maybe<Roles_Actions_Mutation_Response>;
  /** insert a single row into the table: "roles_actions" */
  insert_roles_actions_one?: Maybe<Roles_Actions>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "scheduled_notifications" */
  insert_scheduled_notifications?: Maybe<Scheduled_Notifications_Mutation_Response>;
  /** insert a single row into the table: "scheduled_notifications" */
  insert_scheduled_notifications_one?: Maybe<Scheduled_Notifications>;
  /** insert data into the table: "school_users" */
  insert_school_users?: Maybe<School_Users_Mutation_Response>;
  /** insert a single row into the table: "school_users" */
  insert_school_users_one?: Maybe<School_Users>;
  /** insert data into the table: "scientific_sessions" */
  insert_scientific_sessions?: Maybe<Scientific_Sessions_Mutation_Response>;
  /** insert a single row into the table: "scientific_sessions" */
  insert_scientific_sessions_one?: Maybe<Scientific_Sessions>;
  /** insert data into the table: "self_recitations" */
  insert_self_recitations?: Maybe<Self_Recitations_Mutation_Response>;
  /** insert a single row into the table: "self_recitations" */
  insert_self_recitations_one?: Maybe<Self_Recitations>;
  /** insert data into the table: "sessions" */
  insert_sessions?: Maybe<Sessions_Mutation_Response>;
  /** insert a single row into the table: "sessions" */
  insert_sessions_one?: Maybe<Sessions>;
  /** insert data into the table: "sounds" */
  insert_sounds?: Maybe<Sounds_Mutation_Response>;
  /** insert a single row into the table: "sounds" */
  insert_sounds_one?: Maybe<Sounds>;
  /** insert data into the table: "statistics" */
  insert_statistics?: Maybe<Statistics_Mutation_Response>;
  /** insert a single row into the table: "statistics" */
  insert_statistics_one?: Maybe<Statistics>;
  /** insert data into the table: "students" */
  insert_students?: Maybe<Students_Mutation_Response>;
  /** insert a single row into the table: "students" */
  insert_students_one?: Maybe<Students>;
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<Subscriptions>;
  /** insert data into the table: "text_messages" */
  insert_text_messages?: Maybe<Text_Messages_Mutation_Response>;
  /** insert a single row into the table: "text_messages" */
  insert_text_messages_one?: Maybe<Text_Messages>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "translations" */
  insert_translations?: Maybe<Translations_Mutation_Response>;
  /** insert a single row into the table: "translations" */
  insert_translations_one?: Maybe<Translations>;
  /** insert data into the table: "user_badges" */
  insert_user_badges?: Maybe<User_Badges_Mutation_Response>;
  /** insert a single row into the table: "user_badges" */
  insert_user_badges_one?: Maybe<User_Badges>;
  /** insert data into the table: "user_categories" */
  insert_user_categories?: Maybe<User_Categories_Mutation_Response>;
  /** insert a single row into the table: "user_categories" */
  insert_user_categories_one?: Maybe<User_Categories>;
  /** insert data into the table: "user_certificates" */
  insert_user_certificates?: Maybe<User_Certificates_Mutation_Response>;
  /** insert a single row into the table: "user_certificates" */
  insert_user_certificates_one?: Maybe<User_Certificates>;
  /** insert data into the table: "user_credit_card_infos" */
  insert_user_credit_card_infos?: Maybe<User_Credit_Card_Infos_Mutation_Response>;
  /** insert a single row into the table: "user_credit_card_infos" */
  insert_user_credit_card_infos_one?: Maybe<User_Credit_Card_Infos>;
  /** insert data into the table: "user_discussion_rooms" */
  insert_user_discussion_rooms?: Maybe<User_Discussion_Rooms_Mutation_Response>;
  /** insert a single row into the table: "user_discussion_rooms" */
  insert_user_discussion_rooms_one?: Maybe<User_Discussion_Rooms>;
  /** insert data into the table: "user_exams" */
  insert_user_exams?: Maybe<User_Exams_Mutation_Response>;
  /** insert a single row into the table: "user_exams" */
  insert_user_exams_one?: Maybe<User_Exams>;
  /** insert data into the table: "user_hearing_councils" */
  insert_user_hearing_councils?: Maybe<User_Hearing_Councils_Mutation_Response>;
  /** insert a single row into the table: "user_hearing_councils" */
  insert_user_hearing_councils_one?: Maybe<User_Hearing_Councils>;
  /** insert data into the table: "user_meetings" */
  insert_user_meetings?: Maybe<User_Meetings_Mutation_Response>;
  /** insert a single row into the table: "user_meetings" */
  insert_user_meetings_one?: Maybe<User_Meetings>;
  /** insert data into the table: "user_preferences" */
  insert_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** insert a single row into the table: "user_preferences" */
  insert_user_preferences_one?: Maybe<User_Preferences>;
  /** insert data into the table: "user_quizes" */
  insert_user_quizes?: Maybe<User_Quizes_Mutation_Response>;
  /** insert a single row into the table: "user_quizes" */
  insert_user_quizes_one?: Maybe<User_Quizes>;
  /** insert data into the table: "user_records" */
  insert_user_records?: Maybe<User_Records_Mutation_Response>;
  /** insert a single row into the table: "user_records" */
  insert_user_records_one?: Maybe<User_Records>;
  /** insert data into the table: "user_scientific_sessions" */
  insert_user_scientific_sessions?: Maybe<User_Scientific_Sessions_Mutation_Response>;
  /** insert a single row into the table: "user_scientific_sessions" */
  insert_user_scientific_sessions_one?: Maybe<User_Scientific_Sessions>;
  /** insert data into the table: "user_sessions" */
  insert_user_sessions?: Maybe<User_Sessions_Mutation_Response>;
  /** insert a single row into the table: "user_sessions" */
  insert_user_sessions_one?: Maybe<User_Sessions>;
  /** insert data into the table: "user_subscriptions" */
  insert_user_subscriptions?: Maybe<User_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "user_subscriptions" */
  insert_user_subscriptions_one?: Maybe<User_Subscriptions>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  joinHearingCouncil: JoinHearingCouncilResponse;
  joinSession: Scalars['String']['output'];
  loginAdmin?: Maybe<AdminAuthResponse>;
  loginUser?: Maybe<UserAuthResponse>;
  markPlanSectionsAsDone: Plan;
  reActivateAdmin?: Maybe<Admin>;
  reActivateUser?: Maybe<User>;
  removeAdmin?: Maybe<Admin>;
  removeMySession: Scalars['String']['output'];
  removeUser?: Maybe<User>;
  resendVerificationCode?: Maybe<SignUpResponse>;
  resetUserPassword?: Maybe<User>;
  signupUser?: Maybe<SignUpResponse>;
  submitAttendenceByMints: UserHearingCouncil;
  submitExam?: Maybe<ExamSubmissionResponse>;
  submitQuiz?: Maybe<QuizSubmissionResponse>;
  subscribeSession: Session;
  unsubscribeSession: Session;
  updateAdmin?: Maybe<Admin>;
  updateSession: Session;
  updateUserProfile?: Maybe<User>;
  /** update data of the table: "_DonationHistoryToTransaction" */
  update__DonationHistoryToTransaction?: Maybe<_DonationHistoryToTransaction_Mutation_Response>;
  /** update multiples rows of table: "_DonationHistoryToTransaction" */
  update__DonationHistoryToTransaction_many?: Maybe<Array<Maybe<_DonationHistoryToTransaction_Mutation_Response>>>;
  /** update data of the table: "actions" */
  update_actions?: Maybe<Actions_Mutation_Response>;
  /** update single row of the table: "actions" */
  update_actions_by_pk?: Maybe<Actions>;
  /** update multiples rows of table: "actions" */
  update_actions_many?: Maybe<Array<Maybe<Actions_Mutation_Response>>>;
  /** update data of the table: "admins" */
  update_admins?: Maybe<Admins_Mutation_Response>;
  /** update single row of the table: "admins" */
  update_admins_by_pk?: Maybe<Admins>;
  /** update multiples rows of table: "admins" */
  update_admins_many?: Maybe<Array<Maybe<Admins_Mutation_Response>>>;
  /** update data of the table: "answers" */
  update_answers?: Maybe<Answers_Mutation_Response>;
  /** update single row of the table: "answers" */
  update_answers_by_pk?: Maybe<Answers>;
  /** update multiples rows of table: "answers" */
  update_answers_many?: Maybe<Array<Maybe<Answers_Mutation_Response>>>;
  /** update data of the table: "authorized_users" */
  update_authorized_users?: Maybe<Authorized_Users_Mutation_Response>;
  /** update single row of the table: "authorized_users" */
  update_authorized_users_by_pk?: Maybe<Authorized_Users>;
  /** update multiples rows of table: "authorized_users" */
  update_authorized_users_many?: Maybe<Array<Maybe<Authorized_Users_Mutation_Response>>>;
  /** update data of the table: "badges" */
  update_badges?: Maybe<Badges_Mutation_Response>;
  /** update single row of the table: "badges" */
  update_badges_by_pk?: Maybe<Badges>;
  /** update multiples rows of table: "badges" */
  update_badges_many?: Maybe<Array<Maybe<Badges_Mutation_Response>>>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>;
  /** update data of the table: "certificate_variables" */
  update_certificate_variables?: Maybe<Certificate_Variables_Mutation_Response>;
  /** update single row of the table: "certificate_variables" */
  update_certificate_variables_by_pk?: Maybe<Certificate_Variables>;
  /** update multiples rows of table: "certificate_variables" */
  update_certificate_variables_many?: Maybe<Array<Maybe<Certificate_Variables_Mutation_Response>>>;
  /** update data of the table: "certificates" */
  update_certificates?: Maybe<Certificates_Mutation_Response>;
  /** update single row of the table: "certificates" */
  update_certificates_by_pk?: Maybe<Certificates>;
  /** update multiples rows of table: "certificates" */
  update_certificates_many?: Maybe<Array<Maybe<Certificates_Mutation_Response>>>;
  /** update data of the table: "chat_messages" */
  update_chat_messages?: Maybe<Chat_Messages_Mutation_Response>;
  /** update single row of the table: "chat_messages" */
  update_chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** update multiples rows of table: "chat_messages" */
  update_chat_messages_many?: Maybe<Array<Maybe<Chat_Messages_Mutation_Response>>>;
  /** update data of the table: "chats" */
  update_chats?: Maybe<Chats_Mutation_Response>;
  /** update single row of the table: "chats" */
  update_chats_by_pk?: Maybe<Chats>;
  /** update multiples rows of table: "chats" */
  update_chats_many?: Maybe<Array<Maybe<Chats_Mutation_Response>>>;
  /** update data of the table: "contact_us" */
  update_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** update single row of the table: "contact_us" */
  update_contact_us_by_pk?: Maybe<Contact_Us>;
  /** update multiples rows of table: "contact_us" */
  update_contact_us_many?: Maybe<Array<Maybe<Contact_Us_Mutation_Response>>>;
  /** update data of the table: "content_pages" */
  update_content_pages?: Maybe<Content_Pages_Mutation_Response>;
  /** update single row of the table: "content_pages" */
  update_content_pages_by_pk?: Maybe<Content_Pages>;
  /** update multiples rows of table: "content_pages" */
  update_content_pages_many?: Maybe<Array<Maybe<Content_Pages_Mutation_Response>>>;
  /** update data of the table: "content_recommendations" */
  update_content_recommendations?: Maybe<Content_Recommendations_Mutation_Response>;
  /** update single row of the table: "content_recommendations" */
  update_content_recommendations_by_pk?: Maybe<Content_Recommendations>;
  /** update multiples rows of table: "content_recommendations" */
  update_content_recommendations_many?: Maybe<Array<Maybe<Content_Recommendations_Mutation_Response>>>;
  /** update data of the table: "countries" */
  update_countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "countries" */
  update_countries_by_pk?: Maybe<Countries>;
  /** update multiples rows of table: "countries" */
  update_countries_many?: Maybe<Array<Maybe<Countries_Mutation_Response>>>;
  /** update data of the table: "discussion_rooms" */
  update_discussion_rooms?: Maybe<Discussion_Rooms_Mutation_Response>;
  /** update single row of the table: "discussion_rooms" */
  update_discussion_rooms_by_pk?: Maybe<Discussion_Rooms>;
  /** update multiples rows of table: "discussion_rooms" */
  update_discussion_rooms_many?: Maybe<Array<Maybe<Discussion_Rooms_Mutation_Response>>>;
  /** update data of the table: "donation_histories" */
  update_donation_histories?: Maybe<Donation_Histories_Mutation_Response>;
  /** update single row of the table: "donation_histories" */
  update_donation_histories_by_pk?: Maybe<Donation_Histories>;
  /** update multiples rows of table: "donation_histories" */
  update_donation_histories_many?: Maybe<Array<Maybe<Donation_Histories_Mutation_Response>>>;
  /** update data of the table: "donations" */
  update_donations?: Maybe<Donations_Mutation_Response>;
  /** update single row of the table: "donations" */
  update_donations_by_pk?: Maybe<Donations>;
  /** update multiples rows of table: "donations" */
  update_donations_many?: Maybe<Array<Maybe<Donations_Mutation_Response>>>;
  /** update data of the table: "event_reminders" */
  update_event_reminders?: Maybe<Event_Reminders_Mutation_Response>;
  /** update single row of the table: "event_reminders" */
  update_event_reminders_by_pk?: Maybe<Event_Reminders>;
  /** update multiples rows of table: "event_reminders" */
  update_event_reminders_many?: Maybe<Array<Maybe<Event_Reminders_Mutation_Response>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "exams" */
  update_exams?: Maybe<Exams_Mutation_Response>;
  /** update single row of the table: "exams" */
  update_exams_by_pk?: Maybe<Exams>;
  /** update multiples rows of table: "exams" */
  update_exams_many?: Maybe<Array<Maybe<Exams_Mutation_Response>>>;
  /** update data of the table: "faqs" */
  update_faqs?: Maybe<Faqs_Mutation_Response>;
  /** update single row of the table: "faqs" */
  update_faqs_by_pk?: Maybe<Faqs>;
  /** update multiples rows of table: "faqs" */
  update_faqs_many?: Maybe<Array<Maybe<Faqs_Mutation_Response>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "group_members" */
  update_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** update single row of the table: "group_members" */
  update_group_members_by_pk?: Maybe<Group_Members>;
  /** update multiples rows of table: "group_members" */
  update_group_members_many?: Maybe<Array<Maybe<Group_Members_Mutation_Response>>>;
  /** update data of the table: "hearing_councils" */
  update_hearing_councils?: Maybe<Hearing_Councils_Mutation_Response>;
  /** update single row of the table: "hearing_councils" */
  update_hearing_councils_by_pk?: Maybe<Hearing_Councils>;
  /** update multiples rows of table: "hearing_councils" */
  update_hearing_councils_many?: Maybe<Array<Maybe<Hearing_Councils_Mutation_Response>>>;
  /** update data of the table: "knowledge_bases" */
  update_knowledge_bases?: Maybe<Knowledge_Bases_Mutation_Response>;
  /** update single row of the table: "knowledge_bases" */
  update_knowledge_bases_by_pk?: Maybe<Knowledge_Bases>;
  /** update multiples rows of table: "knowledge_bases" */
  update_knowledge_bases_many?: Maybe<Array<Maybe<Knowledge_Bases_Mutation_Response>>>;
  /** update data of the table: "lectures_and_webinars" */
  update_lectures_and_webinars?: Maybe<Lectures_And_Webinars_Mutation_Response>;
  /** update single row of the table: "lectures_and_webinars" */
  update_lectures_and_webinars_by_pk?: Maybe<Lectures_And_Webinars>;
  /** update multiples rows of table: "lectures_and_webinars" */
  update_lectures_and_webinars_many?: Maybe<Array<Maybe<Lectures_And_Webinars_Mutation_Response>>>;
  /** update data of the table: "levels" */
  update_levels?: Maybe<Levels_Mutation_Response>;
  /** update single row of the table: "levels" */
  update_levels_by_pk?: Maybe<Levels>;
  /** update multiples rows of table: "levels" */
  update_levels_many?: Maybe<Array<Maybe<Levels_Mutation_Response>>>;
  /** update data of the table: "media_messages" */
  update_media_messages?: Maybe<Media_Messages_Mutation_Response>;
  /** update single row of the table: "media_messages" */
  update_media_messages_by_pk?: Maybe<Media_Messages>;
  /** update multiples rows of table: "media_messages" */
  update_media_messages_many?: Maybe<Array<Maybe<Media_Messages_Mutation_Response>>>;
  /** update data of the table: "meetings" */
  update_meetings?: Maybe<Meetings_Mutation_Response>;
  /** update single row of the table: "meetings" */
  update_meetings_by_pk?: Maybe<Meetings>;
  /** update multiples rows of table: "meetings" */
  update_meetings_many?: Maybe<Array<Maybe<Meetings_Mutation_Response>>>;
  /** update data of the table: "message_recipients" */
  update_message_recipients?: Maybe<Message_Recipients_Mutation_Response>;
  /** update single row of the table: "message_recipients" */
  update_message_recipients_by_pk?: Maybe<Message_Recipients>;
  /** update multiples rows of table: "message_recipients" */
  update_message_recipients_many?: Maybe<Array<Maybe<Message_Recipients_Mutation_Response>>>;
  /** update data of the table: "mutoon_categories" */
  update_mutoon_categories?: Maybe<Mutoon_Categories_Mutation_Response>;
  /** update single row of the table: "mutoon_categories" */
  update_mutoon_categories_by_pk?: Maybe<Mutoon_Categories>;
  /** update multiples rows of table: "mutoon_categories" */
  update_mutoon_categories_many?: Maybe<Array<Maybe<Mutoon_Categories_Mutation_Response>>>;
  /** update data of the table: "mutoon_sections" */
  update_mutoon_sections?: Maybe<Mutoon_Sections_Mutation_Response>;
  /** update single row of the table: "mutoon_sections" */
  update_mutoon_sections_by_pk?: Maybe<Mutoon_Sections>;
  /** update multiples rows of table: "mutoon_sections" */
  update_mutoon_sections_many?: Maybe<Array<Maybe<Mutoon_Sections_Mutation_Response>>>;
  /** update data of the table: "mutoons" */
  update_mutoons?: Maybe<Mutoons_Mutation_Response>;
  /** update single row of the table: "mutoons" */
  update_mutoons_by_pk?: Maybe<Mutoons>;
  /** update multiples rows of table: "mutoons" */
  update_mutoons_many?: Maybe<Array<Maybe<Mutoons_Mutation_Response>>>;
  /** update data of the table: "notes" */
  update_notes?: Maybe<Notes_Mutation_Response>;
  /** update single row of the table: "notes" */
  update_notes_by_pk?: Maybe<Notes>;
  /** update multiples rows of table: "notes" */
  update_notes_many?: Maybe<Array<Maybe<Notes_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "payment_methods" */
  update_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** update single row of the table: "payment_methods" */
  update_payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** update multiples rows of table: "payment_methods" */
  update_payment_methods_many?: Maybe<Array<Maybe<Payment_Methods_Mutation_Response>>>;
  /** update data of the table: "plan_sections" */
  update_plan_sections?: Maybe<Plan_Sections_Mutation_Response>;
  /** update single row of the table: "plan_sections" */
  update_plan_sections_by_pk?: Maybe<Plan_Sections>;
  /** update multiples rows of table: "plan_sections" */
  update_plan_sections_many?: Maybe<Array<Maybe<Plan_Sections_Mutation_Response>>>;
  /** update data of the table: "plans" */
  update_plans?: Maybe<Plans_Mutation_Response>;
  /** update single row of the table: "plans" */
  update_plans_by_pk?: Maybe<Plans>;
  /** update multiples rows of table: "plans" */
  update_plans_many?: Maybe<Array<Maybe<Plans_Mutation_Response>>>;
  /** update data of the table: "platform_settings" */
  update_platform_settings?: Maybe<Platform_Settings_Mutation_Response>;
  /** update single row of the table: "platform_settings" */
  update_platform_settings_by_pk?: Maybe<Platform_Settings>;
  /** update multiples rows of table: "platform_settings" */
  update_platform_settings_many?: Maybe<Array<Maybe<Platform_Settings_Mutation_Response>>>;
  /** update data of the table: "prerequisites" */
  update_prerequisites?: Maybe<Prerequisites_Mutation_Response>;
  /** update single row of the table: "prerequisites" */
  update_prerequisites_by_pk?: Maybe<Prerequisites>;
  /** update multiples rows of table: "prerequisites" */
  update_prerequisites_many?: Maybe<Array<Maybe<Prerequisites_Mutation_Response>>>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<Questions_Mutation_Response>>>;
  /** update data of the table: "ratings_and_reviews" */
  update_ratings_and_reviews?: Maybe<Ratings_And_Reviews_Mutation_Response>;
  /** update single row of the table: "ratings_and_reviews" */
  update_ratings_and_reviews_by_pk?: Maybe<Ratings_And_Reviews>;
  /** update multiples rows of table: "ratings_and_reviews" */
  update_ratings_and_reviews_many?: Maybe<Array<Maybe<Ratings_And_Reviews_Mutation_Response>>>;
  /** update data of the table: "reference_books" */
  update_reference_books?: Maybe<Reference_Books_Mutation_Response>;
  /** update single row of the table: "reference_books" */
  update_reference_books_by_pk?: Maybe<Reference_Books>;
  /** update multiples rows of table: "reference_books" */
  update_reference_books_many?: Maybe<Array<Maybe<Reference_Books_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<Reports_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update data of the table: "roles_actions" */
  update_roles_actions?: Maybe<Roles_Actions_Mutation_Response>;
  /** update single row of the table: "roles_actions" */
  update_roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** update multiples rows of table: "roles_actions" */
  update_roles_actions_many?: Maybe<Array<Maybe<Roles_Actions_Mutation_Response>>>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "scheduled_notifications" */
  update_scheduled_notifications?: Maybe<Scheduled_Notifications_Mutation_Response>;
  /** update single row of the table: "scheduled_notifications" */
  update_scheduled_notifications_by_pk?: Maybe<Scheduled_Notifications>;
  /** update multiples rows of table: "scheduled_notifications" */
  update_scheduled_notifications_many?: Maybe<Array<Maybe<Scheduled_Notifications_Mutation_Response>>>;
  /** update data of the table: "school_users" */
  update_school_users?: Maybe<School_Users_Mutation_Response>;
  /** update single row of the table: "school_users" */
  update_school_users_by_pk?: Maybe<School_Users>;
  /** update multiples rows of table: "school_users" */
  update_school_users_many?: Maybe<Array<Maybe<School_Users_Mutation_Response>>>;
  /** update data of the table: "scientific_sessions" */
  update_scientific_sessions?: Maybe<Scientific_Sessions_Mutation_Response>;
  /** update single row of the table: "scientific_sessions" */
  update_scientific_sessions_by_pk?: Maybe<Scientific_Sessions>;
  /** update multiples rows of table: "scientific_sessions" */
  update_scientific_sessions_many?: Maybe<Array<Maybe<Scientific_Sessions_Mutation_Response>>>;
  /** update data of the table: "self_recitations" */
  update_self_recitations?: Maybe<Self_Recitations_Mutation_Response>;
  /** update single row of the table: "self_recitations" */
  update_self_recitations_by_pk?: Maybe<Self_Recitations>;
  /** update multiples rows of table: "self_recitations" */
  update_self_recitations_many?: Maybe<Array<Maybe<Self_Recitations_Mutation_Response>>>;
  /** update data of the table: "sessions" */
  update_sessions?: Maybe<Sessions_Mutation_Response>;
  /** update single row of the table: "sessions" */
  update_sessions_by_pk?: Maybe<Sessions>;
  /** update multiples rows of table: "sessions" */
  update_sessions_many?: Maybe<Array<Maybe<Sessions_Mutation_Response>>>;
  /** update data of the table: "sounds" */
  update_sounds?: Maybe<Sounds_Mutation_Response>;
  /** update single row of the table: "sounds" */
  update_sounds_by_pk?: Maybe<Sounds>;
  /** update multiples rows of table: "sounds" */
  update_sounds_many?: Maybe<Array<Maybe<Sounds_Mutation_Response>>>;
  /** update data of the table: "statistics" */
  update_statistics?: Maybe<Statistics_Mutation_Response>;
  /** update single row of the table: "statistics" */
  update_statistics_by_pk?: Maybe<Statistics>;
  /** update multiples rows of table: "statistics" */
  update_statistics_many?: Maybe<Array<Maybe<Statistics_Mutation_Response>>>;
  /** update data of the table: "students" */
  update_students?: Maybe<Students_Mutation_Response>;
  /** update single row of the table: "students" */
  update_students_by_pk?: Maybe<Students>;
  /** update multiples rows of table: "students" */
  update_students_many?: Maybe<Array<Maybe<Students_Mutation_Response>>>;
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** update multiples rows of table: "subscriptions" */
  update_subscriptions_many?: Maybe<Array<Maybe<Subscriptions_Mutation_Response>>>;
  /** update data of the table: "text_messages" */
  update_text_messages?: Maybe<Text_Messages_Mutation_Response>;
  /** update single row of the table: "text_messages" */
  update_text_messages_by_pk?: Maybe<Text_Messages>;
  /** update multiples rows of table: "text_messages" */
  update_text_messages_many?: Maybe<Array<Maybe<Text_Messages_Mutation_Response>>>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<Array<Maybe<Transactions_Mutation_Response>>>;
  /** update data of the table: "translations" */
  update_translations?: Maybe<Translations_Mutation_Response>;
  /** update single row of the table: "translations" */
  update_translations_by_pk?: Maybe<Translations>;
  /** update multiples rows of table: "translations" */
  update_translations_many?: Maybe<Array<Maybe<Translations_Mutation_Response>>>;
  /** update data of the table: "user_badges" */
  update_user_badges?: Maybe<User_Badges_Mutation_Response>;
  /** update single row of the table: "user_badges" */
  update_user_badges_by_pk?: Maybe<User_Badges>;
  /** update multiples rows of table: "user_badges" */
  update_user_badges_many?: Maybe<Array<Maybe<User_Badges_Mutation_Response>>>;
  /** update data of the table: "user_categories" */
  update_user_categories?: Maybe<User_Categories_Mutation_Response>;
  /** update single row of the table: "user_categories" */
  update_user_categories_by_pk?: Maybe<User_Categories>;
  /** update multiples rows of table: "user_categories" */
  update_user_categories_many?: Maybe<Array<Maybe<User_Categories_Mutation_Response>>>;
  /** update data of the table: "user_certificates" */
  update_user_certificates?: Maybe<User_Certificates_Mutation_Response>;
  /** update single row of the table: "user_certificates" */
  update_user_certificates_by_pk?: Maybe<User_Certificates>;
  /** update multiples rows of table: "user_certificates" */
  update_user_certificates_many?: Maybe<Array<Maybe<User_Certificates_Mutation_Response>>>;
  /** update data of the table: "user_credit_card_infos" */
  update_user_credit_card_infos?: Maybe<User_Credit_Card_Infos_Mutation_Response>;
  /** update single row of the table: "user_credit_card_infos" */
  update_user_credit_card_infos_by_pk?: Maybe<User_Credit_Card_Infos>;
  /** update multiples rows of table: "user_credit_card_infos" */
  update_user_credit_card_infos_many?: Maybe<Array<Maybe<User_Credit_Card_Infos_Mutation_Response>>>;
  /** update data of the table: "user_discussion_rooms" */
  update_user_discussion_rooms?: Maybe<User_Discussion_Rooms_Mutation_Response>;
  /** update single row of the table: "user_discussion_rooms" */
  update_user_discussion_rooms_by_pk?: Maybe<User_Discussion_Rooms>;
  /** update multiples rows of table: "user_discussion_rooms" */
  update_user_discussion_rooms_many?: Maybe<Array<Maybe<User_Discussion_Rooms_Mutation_Response>>>;
  /** update data of the table: "user_exams" */
  update_user_exams?: Maybe<User_Exams_Mutation_Response>;
  /** update single row of the table: "user_exams" */
  update_user_exams_by_pk?: Maybe<User_Exams>;
  /** update multiples rows of table: "user_exams" */
  update_user_exams_many?: Maybe<Array<Maybe<User_Exams_Mutation_Response>>>;
  /** update data of the table: "user_hearing_councils" */
  update_user_hearing_councils?: Maybe<User_Hearing_Councils_Mutation_Response>;
  /** update single row of the table: "user_hearing_councils" */
  update_user_hearing_councils_by_pk?: Maybe<User_Hearing_Councils>;
  /** update multiples rows of table: "user_hearing_councils" */
  update_user_hearing_councils_many?: Maybe<Array<Maybe<User_Hearing_Councils_Mutation_Response>>>;
  /** update data of the table: "user_meetings" */
  update_user_meetings?: Maybe<User_Meetings_Mutation_Response>;
  /** update single row of the table: "user_meetings" */
  update_user_meetings_by_pk?: Maybe<User_Meetings>;
  /** update multiples rows of table: "user_meetings" */
  update_user_meetings_many?: Maybe<Array<Maybe<User_Meetings_Mutation_Response>>>;
  /** update data of the table: "user_preferences" */
  update_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** update single row of the table: "user_preferences" */
  update_user_preferences_by_pk?: Maybe<User_Preferences>;
  /** update multiples rows of table: "user_preferences" */
  update_user_preferences_many?: Maybe<Array<Maybe<User_Preferences_Mutation_Response>>>;
  /** update data of the table: "user_quizes" */
  update_user_quizes?: Maybe<User_Quizes_Mutation_Response>;
  /** update single row of the table: "user_quizes" */
  update_user_quizes_by_pk?: Maybe<User_Quizes>;
  /** update multiples rows of table: "user_quizes" */
  update_user_quizes_many?: Maybe<Array<Maybe<User_Quizes_Mutation_Response>>>;
  /** update data of the table: "user_records" */
  update_user_records?: Maybe<User_Records_Mutation_Response>;
  /** update single row of the table: "user_records" */
  update_user_records_by_pk?: Maybe<User_Records>;
  /** update multiples rows of table: "user_records" */
  update_user_records_many?: Maybe<Array<Maybe<User_Records_Mutation_Response>>>;
  /** update data of the table: "user_scientific_sessions" */
  update_user_scientific_sessions?: Maybe<User_Scientific_Sessions_Mutation_Response>;
  /** update single row of the table: "user_scientific_sessions" */
  update_user_scientific_sessions_by_pk?: Maybe<User_Scientific_Sessions>;
  /** update multiples rows of table: "user_scientific_sessions" */
  update_user_scientific_sessions_many?: Maybe<Array<Maybe<User_Scientific_Sessions_Mutation_Response>>>;
  /** update data of the table: "user_sessions" */
  update_user_sessions?: Maybe<User_Sessions_Mutation_Response>;
  /** update single row of the table: "user_sessions" */
  update_user_sessions_by_pk?: Maybe<User_Sessions>;
  /** update multiples rows of table: "user_sessions" */
  update_user_sessions_many?: Maybe<Array<Maybe<User_Sessions_Mutation_Response>>>;
  /** update data of the table: "user_subscriptions" */
  update_user_subscriptions?: Maybe<User_Subscriptions_Mutation_Response>;
  /** update single row of the table: "user_subscriptions" */
  update_user_subscriptions_by_pk?: Maybe<User_Subscriptions>;
  /** update multiples rows of table: "user_subscriptions" */
  update_user_subscriptions_many?: Maybe<Array<Maybe<User_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  userSharesCount: UserSharesResponse;
  userStreaks: BadgeDayStreaks;
  verifyAccount?: Maybe<UserAuthResponse>;
};


/** mutation root */
export type Mutation_RootSendEmailToContactArgs = {
  SendEmailToContactInput: SendEmailToContactInput;
};


/** mutation root */
export type Mutation_RootAppleSigninArgs = {
  appleSigninInput: AppleSigninInput;
};


/** mutation root */
export type Mutation_RootAttendOnsiteArgs = {
  lang: Scalars['String']['input'];
  lat: Scalars['String']['input'];
  userHearingCouncilId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootClaimBadgeArgs = {
  badgeId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateAdminArgs = {
  createAdminInput: CreateAdminInput;
};


/** mutation root */
export type Mutation_RootCreateGeneralMessageNotificationsArgs = {
  input: CreateGeneralMessageNotificationsInput;
};


/** mutation root */
export type Mutation_RootCreateHearingCouncilArgs = {
  createHearingCouncilInput: CreateHearingCouncilInput;
  mutoonMatnId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateSessionArgs = {
  createSessionInput: CreateSessionInput;
  mutoonMatnId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateUserExamCertificateArgs = {
  createCertficateInput: CreateCertficateInput;
};


/** mutation root */
export type Mutation_RootCreateUserHearingCouncilCertificateArgs = {
  createCertficateInput: Array<CreateCertficateInput>;
};


/** mutation root */
export type Mutation_RootDeActivateAdminArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootDeActivateUserArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootDelete__DonationHistoryToTransactionArgs = {
  where: _DonationHistoryToTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActionsArgs = {
  where: Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Actions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AdminsArgs = {
  where: Admins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admins_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AnswersArgs = {
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answers_By_PkArgs = {
  answerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Authorized_UsersArgs = {
  where: Authorized_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Authorized_Users_By_PkArgs = {
  authorizedUserId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BadgesArgs = {
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badges_By_PkArgs = {
  badgeId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  categoryId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Certificate_VariablesArgs = {
  where: Certificate_Variables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Variables_By_PkArgs = {
  certVarId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CertificatesArgs = {
  where: Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificates_By_PkArgs = {
  certificateId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Chat_MessagesArgs = {
  where: Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChatsArgs = {
  where: Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chats_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_UsArgs = {
  where: Contact_Us_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Us_By_PkArgs = {
  contactId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Content_PagesArgs = {
  where: Content_Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Pages_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Content_RecommendationsArgs = {
  where: Content_Recommendations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Recommendations_By_PkArgs = {
  recommendationId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  countryId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Discussion_RoomsArgs = {
  where: Discussion_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discussion_Rooms_By_PkArgs = {
  roomId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Donation_HistoriesArgs = {
  where: Donation_Histories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Donation_Histories_By_PkArgs = {
  donationHistoryId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DonationsArgs = {
  where: Donations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Donations_By_PkArgs = {
  donationId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Event_RemindersArgs = {
  where: Event_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Reminders_By_PkArgs = {
  reminderId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  eventId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ExamsArgs = {
  where: Exams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Exams_By_PkArgs = {
  examId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FaqsArgs = {
  where: Faqs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faqs_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Group_MembersArgs = {
  where: Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Members_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Hearing_CouncilsArgs = {
  where: Hearing_Councils_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hearing_Councils_By_PkArgs = {
  hearingCouncilId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Knowledge_BasesArgs = {
  where: Knowledge_Bases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Knowledge_Bases_By_PkArgs = {
  knowledgeBaseId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Lectures_And_WebinarsArgs = {
  where: Lectures_And_Webinars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lectures_And_Webinars_By_PkArgs = {
  lectureId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LevelsArgs = {
  where: Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Levels_By_PkArgs = {
  levelId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Media_MessagesArgs = {
  where: Media_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Media_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MeetingsArgs = {
  where: Meetings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Meetings_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Message_RecipientsArgs = {
  where: Message_Recipients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Message_Recipients_By_PkArgs = {
  groupMemberId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mutoon_CategoriesArgs = {
  where: Mutoon_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mutoon_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mutoon_SectionsArgs = {
  where: Mutoon_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mutoon_Sections_By_PkArgs = {
  mutoonSectionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MutoonsArgs = {
  where: Mutoons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mutoons_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotesArgs = {
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notes_By_PkArgs = {
  noteId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_MethodsArgs = {
  where: Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Methods_By_PkArgs = {
  paymentMethodId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plan_SectionsArgs = {
  where: Plan_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_Sections_By_PkArgs = {
  planSectionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PlansArgs = {
  where: Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plans_By_PkArgs = {
  planId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Platform_SettingsArgs = {
  where: Platform_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Platform_Settings_By_PkArgs = {
  settingId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PrerequisitesArgs = {
  where: Prerequisites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prerequisites_By_PkArgs = {
  prerequisiteId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  questionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Ratings_And_ReviewsArgs = {
  where: Ratings_And_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ratings_And_Reviews_By_PkArgs = {
  reviewId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reference_BooksArgs = {
  where: Reference_Books_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reference_Books_By_PkArgs = {
  bookId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReportsArgs = {
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reports_By_PkArgs = {
  reportId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_ActionsArgs = {
  where: Roles_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_Actions_By_PkArgs = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_NotificationsArgs = {
  where: Scheduled_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_School_UsersArgs = {
  where: School_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_School_Users_By_PkArgs = {
  schoolUserId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Scientific_SessionsArgs = {
  where: Scientific_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scientific_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Self_RecitationsArgs = {
  where: Self_Recitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Self_Recitations_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SessionsArgs = {
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SoundsArgs = {
  where: Sounds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sounds_By_PkArgs = {
  soundId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_StatisticsArgs = {
  where: Statistics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistics_By_PkArgs = {
  statisticId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_StudentsArgs = {
  where: Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Students_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Text_MessagesArgs = {
  where: Text_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Text_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  transactionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TranslationsArgs = {
  where: Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Translations_By_PkArgs = {
  translationId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_BadgesArgs = {
  where: User_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Badges_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_CategoriesArgs = {
  where: User_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_CertificatesArgs = {
  where: User_Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Certificates_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Credit_Card_InfosArgs = {
  where: User_Credit_Card_Infos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Credit_Card_Infos_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Discussion_RoomsArgs = {
  where: User_Discussion_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Discussion_Rooms_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_ExamsArgs = {
  where: User_Exams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Exams_By_PkArgs = {
  userExamId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Hearing_CouncilsArgs = {
  where: User_Hearing_Councils_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Hearing_Councils_By_PkArgs = {
  userHearingCouncilId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_MeetingsArgs = {
  where: User_Meetings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Meetings_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_PreferencesArgs = {
  where: User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Preferences_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_QuizesArgs = {
  where: User_Quizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Quizes_By_PkArgs = {
  userQuizId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_RecordsArgs = {
  where: User_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Records_By_PkArgs = {
  recordingId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Scientific_SessionsArgs = {
  where: User_Scientific_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Scientific_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_SessionsArgs = {
  where: User_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_SubscriptionsArgs = {
  where: User_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootForgetPasswordArgs = {
  forgetPasswordInput: ForgetPasswordInput;
};


/** mutation root */
export type Mutation_RootGetAchievementsByPlanIdArgs = {
  planId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootGoogleSigninArgs = {
  googleSignInInput: GoogleSignInInput;
};


/** mutation root */
export type Mutation_RootInsert__DonationHistoryToTransactionArgs = {
  objects: Array<_DonationHistoryToTransaction_Insert_Input>;
  on_conflict?: InputMaybe<_DonationHistoryToTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__DonationHistoryToTransaction_OneArgs = {
  object: _DonationHistoryToTransaction_Insert_Input;
  on_conflict?: InputMaybe<_DonationHistoryToTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActionsArgs = {
  objects: Array<Actions_Insert_Input>;
  on_conflict?: InputMaybe<Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_OneArgs = {
  object: Actions_Insert_Input;
  on_conflict?: InputMaybe<Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AdminsArgs = {
  objects: Array<Admins_Insert_Input>;
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admins_OneArgs = {
  object: Admins_Insert_Input;
  on_conflict?: InputMaybe<Admins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AnswersArgs = {
  objects: Array<Answers_Insert_Input>;
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answers_OneArgs = {
  object: Answers_Insert_Input;
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authorized_UsersArgs = {
  objects: Array<Authorized_Users_Insert_Input>;
  on_conflict?: InputMaybe<Authorized_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authorized_Users_OneArgs = {
  object: Authorized_Users_Insert_Input;
  on_conflict?: InputMaybe<Authorized_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BadgesArgs = {
  objects: Array<Badges_Insert_Input>;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badges_OneArgs = {
  object: Badges_Insert_Input;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_VariablesArgs = {
  objects: Array<Certificate_Variables_Insert_Input>;
  on_conflict?: InputMaybe<Certificate_Variables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Variables_OneArgs = {
  object: Certificate_Variables_Insert_Input;
  on_conflict?: InputMaybe<Certificate_Variables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CertificatesArgs = {
  objects: Array<Certificates_Insert_Input>;
  on_conflict?: InputMaybe<Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificates_OneArgs = {
  object: Certificates_Insert_Input;
  on_conflict?: InputMaybe<Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_MessagesArgs = {
  objects: Array<Chat_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Messages_OneArgs = {
  object: Chat_Messages_Insert_Input;
  on_conflict?: InputMaybe<Chat_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChatsArgs = {
  objects: Array<Chats_Insert_Input>;
  on_conflict?: InputMaybe<Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chats_OneArgs = {
  object: Chats_Insert_Input;
  on_conflict?: InputMaybe<Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_UsArgs = {
  objects: Array<Contact_Us_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Us_OneArgs = {
  object: Contact_Us_Insert_Input;
  on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_PagesArgs = {
  objects: Array<Content_Pages_Insert_Input>;
  on_conflict?: InputMaybe<Content_Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Pages_OneArgs = {
  object: Content_Pages_Insert_Input;
  on_conflict?: InputMaybe<Content_Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_RecommendationsArgs = {
  objects: Array<Content_Recommendations_Insert_Input>;
  on_conflict?: InputMaybe<Content_Recommendations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Recommendations_OneArgs = {
  object: Content_Recommendations_Insert_Input;
  on_conflict?: InputMaybe<Content_Recommendations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discussion_RoomsArgs = {
  objects: Array<Discussion_Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Discussion_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discussion_Rooms_OneArgs = {
  object: Discussion_Rooms_Insert_Input;
  on_conflict?: InputMaybe<Discussion_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Donation_HistoriesArgs = {
  objects: Array<Donation_Histories_Insert_Input>;
  on_conflict?: InputMaybe<Donation_Histories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Donation_Histories_OneArgs = {
  object: Donation_Histories_Insert_Input;
  on_conflict?: InputMaybe<Donation_Histories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DonationsArgs = {
  objects: Array<Donations_Insert_Input>;
  on_conflict?: InputMaybe<Donations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Donations_OneArgs = {
  object: Donations_Insert_Input;
  on_conflict?: InputMaybe<Donations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_RemindersArgs = {
  objects: Array<Event_Reminders_Insert_Input>;
  on_conflict?: InputMaybe<Event_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Reminders_OneArgs = {
  object: Event_Reminders_Insert_Input;
  on_conflict?: InputMaybe<Event_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExamsArgs = {
  objects: Array<Exams_Insert_Input>;
  on_conflict?: InputMaybe<Exams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Exams_OneArgs = {
  object: Exams_Insert_Input;
  on_conflict?: InputMaybe<Exams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FaqsArgs = {
  objects: Array<Faqs_Insert_Input>;
  on_conflict?: InputMaybe<Faqs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faqs_OneArgs = {
  object: Faqs_Insert_Input;
  on_conflict?: InputMaybe<Faqs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_MembersArgs = {
  objects: Array<Group_Members_Insert_Input>;
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Members_OneArgs = {
  object: Group_Members_Insert_Input;
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hearing_CouncilsArgs = {
  objects: Array<Hearing_Councils_Insert_Input>;
  on_conflict?: InputMaybe<Hearing_Councils_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hearing_Councils_OneArgs = {
  object: Hearing_Councils_Insert_Input;
  on_conflict?: InputMaybe<Hearing_Councils_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knowledge_BasesArgs = {
  objects: Array<Knowledge_Bases_Insert_Input>;
  on_conflict?: InputMaybe<Knowledge_Bases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knowledge_Bases_OneArgs = {
  object: Knowledge_Bases_Insert_Input;
  on_conflict?: InputMaybe<Knowledge_Bases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lectures_And_WebinarsArgs = {
  objects: Array<Lectures_And_Webinars_Insert_Input>;
  on_conflict?: InputMaybe<Lectures_And_Webinars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lectures_And_Webinars_OneArgs = {
  object: Lectures_And_Webinars_Insert_Input;
  on_conflict?: InputMaybe<Lectures_And_Webinars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LevelsArgs = {
  objects: Array<Levels_Insert_Input>;
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Levels_OneArgs = {
  object: Levels_Insert_Input;
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Media_MessagesArgs = {
  objects: Array<Media_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Media_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Media_Messages_OneArgs = {
  object: Media_Messages_Insert_Input;
  on_conflict?: InputMaybe<Media_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MeetingsArgs = {
  objects: Array<Meetings_Insert_Input>;
  on_conflict?: InputMaybe<Meetings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meetings_OneArgs = {
  object: Meetings_Insert_Input;
  on_conflict?: InputMaybe<Meetings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_RecipientsArgs = {
  objects: Array<Message_Recipients_Insert_Input>;
  on_conflict?: InputMaybe<Message_Recipients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_Recipients_OneArgs = {
  object: Message_Recipients_Insert_Input;
  on_conflict?: InputMaybe<Message_Recipients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mutoon_CategoriesArgs = {
  objects: Array<Mutoon_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Mutoon_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mutoon_Categories_OneArgs = {
  object: Mutoon_Categories_Insert_Input;
  on_conflict?: InputMaybe<Mutoon_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mutoon_SectionsArgs = {
  objects: Array<Mutoon_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Mutoon_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mutoon_Sections_OneArgs = {
  object: Mutoon_Sections_Insert_Input;
  on_conflict?: InputMaybe<Mutoon_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MutoonsArgs = {
  objects: Array<Mutoons_Insert_Input>;
  on_conflict?: InputMaybe<Mutoons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mutoons_OneArgs = {
  object: Mutoons_Insert_Input;
  on_conflict?: InputMaybe<Mutoons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotesArgs = {
  objects: Array<Notes_Insert_Input>;
  on_conflict?: InputMaybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notes_OneArgs = {
  object: Notes_Insert_Input;
  on_conflict?: InputMaybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_MethodsArgs = {
  objects: Array<Payment_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Methods_OneArgs = {
  object: Payment_Methods_Insert_Input;
  on_conflict?: InputMaybe<Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_SectionsArgs = {
  objects: Array<Plan_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Plan_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_Sections_OneArgs = {
  object: Plan_Sections_Insert_Input;
  on_conflict?: InputMaybe<Plan_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlansArgs = {
  objects: Array<Plans_Insert_Input>;
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plans_OneArgs = {
  object: Plans_Insert_Input;
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Platform_SettingsArgs = {
  objects: Array<Platform_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Platform_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Platform_Settings_OneArgs = {
  object: Platform_Settings_Insert_Input;
  on_conflict?: InputMaybe<Platform_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrerequisitesArgs = {
  objects: Array<Prerequisites_Insert_Input>;
  on_conflict?: InputMaybe<Prerequisites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prerequisites_OneArgs = {
  object: Prerequisites_Insert_Input;
  on_conflict?: InputMaybe<Prerequisites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ratings_And_ReviewsArgs = {
  objects: Array<Ratings_And_Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Ratings_And_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ratings_And_Reviews_OneArgs = {
  object: Ratings_And_Reviews_Insert_Input;
  on_conflict?: InputMaybe<Ratings_And_Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reference_BooksArgs = {
  objects: Array<Reference_Books_Insert_Input>;
  on_conflict?: InputMaybe<Reference_Books_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reference_Books_OneArgs = {
  object: Reference_Books_Insert_Input;
  on_conflict?: InputMaybe<Reference_Books_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: Array<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_ActionsArgs = {
  objects: Array<Roles_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Roles_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_Actions_OneArgs = {
  object: Roles_Actions_Insert_Input;
  on_conflict?: InputMaybe<Roles_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_NotificationsArgs = {
  objects: Array<Scheduled_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Scheduled_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Notifications_OneArgs = {
  object: Scheduled_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Scheduled_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_UsersArgs = {
  objects: Array<School_Users_Insert_Input>;
  on_conflict?: InputMaybe<School_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_Users_OneArgs = {
  object: School_Users_Insert_Input;
  on_conflict?: InputMaybe<School_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scientific_SessionsArgs = {
  objects: Array<Scientific_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Scientific_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scientific_Sessions_OneArgs = {
  object: Scientific_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Scientific_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Self_RecitationsArgs = {
  objects: Array<Self_Recitations_Insert_Input>;
  on_conflict?: InputMaybe<Self_Recitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Self_Recitations_OneArgs = {
  object: Self_Recitations_Insert_Input;
  on_conflict?: InputMaybe<Self_Recitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionsArgs = {
  objects: Array<Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sessions_OneArgs = {
  object: Sessions_Insert_Input;
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SoundsArgs = {
  objects: Array<Sounds_Insert_Input>;
  on_conflict?: InputMaybe<Sounds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sounds_OneArgs = {
  object: Sounds_Insert_Input;
  on_conflict?: InputMaybe<Sounds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StatisticsArgs = {
  objects: Array<Statistics_Insert_Input>;
  on_conflict?: InputMaybe<Statistics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistics_OneArgs = {
  object: Statistics_Insert_Input;
  on_conflict?: InputMaybe<Statistics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StudentsArgs = {
  objects: Array<Students_Insert_Input>;
  on_conflict?: InputMaybe<Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Students_OneArgs = {
  object: Students_Insert_Input;
  on_conflict?: InputMaybe<Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: Array<Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_MessagesArgs = {
  objects: Array<Text_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Text_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_Messages_OneArgs = {
  object: Text_Messages_Insert_Input;
  on_conflict?: InputMaybe<Text_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TranslationsArgs = {
  objects: Array<Translations_Insert_Input>;
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Translations_OneArgs = {
  object: Translations_Insert_Input;
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_BadgesArgs = {
  objects: Array<User_Badges_Insert_Input>;
  on_conflict?: InputMaybe<User_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Badges_OneArgs = {
  object: User_Badges_Insert_Input;
  on_conflict?: InputMaybe<User_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_CategoriesArgs = {
  objects: Array<User_Categories_Insert_Input>;
  on_conflict?: InputMaybe<User_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Categories_OneArgs = {
  object: User_Categories_Insert_Input;
  on_conflict?: InputMaybe<User_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_CertificatesArgs = {
  objects: Array<User_Certificates_Insert_Input>;
  on_conflict?: InputMaybe<User_Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Certificates_OneArgs = {
  object: User_Certificates_Insert_Input;
  on_conflict?: InputMaybe<User_Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Credit_Card_InfosArgs = {
  objects: Array<User_Credit_Card_Infos_Insert_Input>;
  on_conflict?: InputMaybe<User_Credit_Card_Infos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Credit_Card_Infos_OneArgs = {
  object: User_Credit_Card_Infos_Insert_Input;
  on_conflict?: InputMaybe<User_Credit_Card_Infos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Discussion_RoomsArgs = {
  objects: Array<User_Discussion_Rooms_Insert_Input>;
  on_conflict?: InputMaybe<User_Discussion_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Discussion_Rooms_OneArgs = {
  object: User_Discussion_Rooms_Insert_Input;
  on_conflict?: InputMaybe<User_Discussion_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ExamsArgs = {
  objects: Array<User_Exams_Insert_Input>;
  on_conflict?: InputMaybe<User_Exams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Exams_OneArgs = {
  object: User_Exams_Insert_Input;
  on_conflict?: InputMaybe<User_Exams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Hearing_CouncilsArgs = {
  objects: Array<User_Hearing_Councils_Insert_Input>;
  on_conflict?: InputMaybe<User_Hearing_Councils_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Hearing_Councils_OneArgs = {
  object: User_Hearing_Councils_Insert_Input;
  on_conflict?: InputMaybe<User_Hearing_Councils_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_MeetingsArgs = {
  objects: Array<User_Meetings_Insert_Input>;
  on_conflict?: InputMaybe<User_Meetings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Meetings_OneArgs = {
  object: User_Meetings_Insert_Input;
  on_conflict?: InputMaybe<User_Meetings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PreferencesArgs = {
  objects: Array<User_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Preferences_OneArgs = {
  object: User_Preferences_Insert_Input;
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_QuizesArgs = {
  objects: Array<User_Quizes_Insert_Input>;
  on_conflict?: InputMaybe<User_Quizes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Quizes_OneArgs = {
  object: User_Quizes_Insert_Input;
  on_conflict?: InputMaybe<User_Quizes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RecordsArgs = {
  objects: Array<User_Records_Insert_Input>;
  on_conflict?: InputMaybe<User_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Records_OneArgs = {
  object: User_Records_Insert_Input;
  on_conflict?: InputMaybe<User_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Scientific_SessionsArgs = {
  objects: Array<User_Scientific_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<User_Scientific_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Scientific_Sessions_OneArgs = {
  object: User_Scientific_Sessions_Insert_Input;
  on_conflict?: InputMaybe<User_Scientific_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_SessionsArgs = {
  objects: Array<User_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<User_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Sessions_OneArgs = {
  object: User_Sessions_Insert_Input;
  on_conflict?: InputMaybe<User_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_SubscriptionsArgs = {
  objects: Array<User_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<User_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Subscriptions_OneArgs = {
  object: User_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<User_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootJoinHearingCouncilArgs = {
  hearingCouncilId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootJoinSessionArgs = {
  sessionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootLoginAdminArgs = {
  loginAdminInput: LoginAdminInput;
};


/** mutation root */
export type Mutation_RootLoginUserArgs = {
  loginUserInput: LoginUserInput;
};


/** mutation root */
export type Mutation_RootMarkPlanSectionsAsDoneArgs = {
  planSectionIds: Array<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootReActivateAdminArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootReActivateUserArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootRemoveAdminArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRemoveMySessionArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRemoveUserArgs = {
  id: Scalars['ID']['input'];
};


/** mutation root */
export type Mutation_RootResendVerificationCodeArgs = {
  resendVerificationCodeInput: SendVerificationCodeInput;
};


/** mutation root */
export type Mutation_RootResetUserPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


/** mutation root */
export type Mutation_RootSignupUserArgs = {
  createUserInput: CreateUserInput;
};


/** mutation root */
export type Mutation_RootSubmitAttendenceByMintsArgs = {
  hearingCouncilId: Scalars['String']['input'];
  reset: Scalars['Boolean']['input'];
  totalMintues: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootSubmitExamArgs = {
  answers: Array<AnswerInput>;
  examId: Scalars['String']['input'];
  planId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitQuizArgs = {
  answers: Array<AnswerInput>;
};


/** mutation root */
export type Mutation_RootSubscribeSessionArgs = {
  sessionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUnsubscribeSessionArgs = {
  sessionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateAdminArgs = {
  updateAdminInput: UpdateAdminInput;
};


/** mutation root */
export type Mutation_RootUpdateSessionArgs = {
  updateMySessionInput: UpdateSessionInput;
};


/** mutation root */
export type Mutation_RootUpdateUserProfileArgs = {
  updateUserProfile: UpdateUserProfileInput;
};


/** mutation root */
export type Mutation_RootUpdate__DonationHistoryToTransactionArgs = {
  _set?: InputMaybe<_DonationHistoryToTransaction_Set_Input>;
  where: _DonationHistoryToTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__DonationHistoryToTransaction_ManyArgs = {
  updates: Array<_DonationHistoryToTransaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ActionsArgs = {
  _set?: InputMaybe<Actions_Set_Input>;
  where: Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_By_PkArgs = {
  _set?: InputMaybe<Actions_Set_Input>;
  pk_columns: Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_ManyArgs = {
  updates: Array<Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AdminsArgs = {
  _set?: InputMaybe<Admins_Set_Input>;
  where: Admins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admins_By_PkArgs = {
  _set?: InputMaybe<Admins_Set_Input>;
  pk_columns: Admins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admins_ManyArgs = {
  updates: Array<Admins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AnswersArgs = {
  _append?: InputMaybe<Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Answers_Prepend_Input>;
  _set?: InputMaybe<Answers_Set_Input>;
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_By_PkArgs = {
  _append?: InputMaybe<Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Answers_Prepend_Input>;
  _set?: InputMaybe<Answers_Set_Input>;
  pk_columns: Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_ManyArgs = {
  updates: Array<Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Authorized_UsersArgs = {
  _set?: InputMaybe<Authorized_Users_Set_Input>;
  where: Authorized_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Authorized_Users_By_PkArgs = {
  _set?: InputMaybe<Authorized_Users_Set_Input>;
  pk_columns: Authorized_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Authorized_Users_ManyArgs = {
  updates: Array<Authorized_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BadgesArgs = {
  _append?: InputMaybe<Badges_Append_Input>;
  _delete_at_path?: InputMaybe<Badges_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Badges_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Badges_Delete_Key_Input>;
  _inc?: InputMaybe<Badges_Inc_Input>;
  _prepend?: InputMaybe<Badges_Prepend_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_By_PkArgs = {
  _append?: InputMaybe<Badges_Append_Input>;
  _delete_at_path?: InputMaybe<Badges_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Badges_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Badges_Delete_Key_Input>;
  _inc?: InputMaybe<Badges_Inc_Input>;
  _prepend?: InputMaybe<Badges_Prepend_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  pk_columns: Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_ManyArgs = {
  updates: Array<Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _append?: InputMaybe<Categories_Append_Input>;
  _delete_at_path?: InputMaybe<Categories_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Categories_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Categories_Delete_Key_Input>;
  _prepend?: InputMaybe<Categories_Prepend_Input>;
  _set?: InputMaybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _append?: InputMaybe<Categories_Append_Input>;
  _delete_at_path?: InputMaybe<Categories_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Categories_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Categories_Delete_Key_Input>;
  _prepend?: InputMaybe<Categories_Prepend_Input>;
  _set?: InputMaybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_VariablesArgs = {
  _inc?: InputMaybe<Certificate_Variables_Inc_Input>;
  _set?: InputMaybe<Certificate_Variables_Set_Input>;
  where: Certificate_Variables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Variables_By_PkArgs = {
  _inc?: InputMaybe<Certificate_Variables_Inc_Input>;
  _set?: InputMaybe<Certificate_Variables_Set_Input>;
  pk_columns: Certificate_Variables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Variables_ManyArgs = {
  updates: Array<Certificate_Variables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CertificatesArgs = {
  _set?: InputMaybe<Certificates_Set_Input>;
  where: Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificates_By_PkArgs = {
  _set?: InputMaybe<Certificates_Set_Input>;
  pk_columns: Certificates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificates_ManyArgs = {
  updates: Array<Certificates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_MessagesArgs = {
  _set?: InputMaybe<Chat_Messages_Set_Input>;
  where: Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_By_PkArgs = {
  _set?: InputMaybe<Chat_Messages_Set_Input>;
  pk_columns: Chat_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_ManyArgs = {
  updates: Array<Chat_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ChatsArgs = {
  _set?: InputMaybe<Chats_Set_Input>;
  where: Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chats_By_PkArgs = {
  _set?: InputMaybe<Chats_Set_Input>;
  pk_columns: Chats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chats_ManyArgs = {
  updates: Array<Chats_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_UsArgs = {
  _set?: InputMaybe<Contact_Us_Set_Input>;
  where: Contact_Us_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_By_PkArgs = {
  _set?: InputMaybe<Contact_Us_Set_Input>;
  pk_columns: Contact_Us_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_ManyArgs = {
  updates: Array<Contact_Us_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_PagesArgs = {
  _append?: InputMaybe<Content_Pages_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Pages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Pages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Pages_Delete_Key_Input>;
  _prepend?: InputMaybe<Content_Pages_Prepend_Input>;
  _set?: InputMaybe<Content_Pages_Set_Input>;
  where: Content_Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Pages_By_PkArgs = {
  _append?: InputMaybe<Content_Pages_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Pages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Pages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Pages_Delete_Key_Input>;
  _prepend?: InputMaybe<Content_Pages_Prepend_Input>;
  _set?: InputMaybe<Content_Pages_Set_Input>;
  pk_columns: Content_Pages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Pages_ManyArgs = {
  updates: Array<Content_Pages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_RecommendationsArgs = {
  _append?: InputMaybe<Content_Recommendations_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Recommendations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Recommendations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Recommendations_Delete_Key_Input>;
  _prepend?: InputMaybe<Content_Recommendations_Prepend_Input>;
  _set?: InputMaybe<Content_Recommendations_Set_Input>;
  where: Content_Recommendations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Recommendations_By_PkArgs = {
  _append?: InputMaybe<Content_Recommendations_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Recommendations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Recommendations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Recommendations_Delete_Key_Input>;
  _prepend?: InputMaybe<Content_Recommendations_Prepend_Input>;
  _set?: InputMaybe<Content_Recommendations_Set_Input>;
  pk_columns: Content_Recommendations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Recommendations_ManyArgs = {
  updates: Array<Content_Recommendations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _set?: InputMaybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _set?: InputMaybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_ManyArgs = {
  updates: Array<Countries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Discussion_RoomsArgs = {
  _inc?: InputMaybe<Discussion_Rooms_Inc_Input>;
  _set?: InputMaybe<Discussion_Rooms_Set_Input>;
  where: Discussion_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discussion_Rooms_By_PkArgs = {
  _inc?: InputMaybe<Discussion_Rooms_Inc_Input>;
  _set?: InputMaybe<Discussion_Rooms_Set_Input>;
  pk_columns: Discussion_Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Discussion_Rooms_ManyArgs = {
  updates: Array<Discussion_Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Donation_HistoriesArgs = {
  _inc?: InputMaybe<Donation_Histories_Inc_Input>;
  _set?: InputMaybe<Donation_Histories_Set_Input>;
  where: Donation_Histories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Donation_Histories_By_PkArgs = {
  _inc?: InputMaybe<Donation_Histories_Inc_Input>;
  _set?: InputMaybe<Donation_Histories_Set_Input>;
  pk_columns: Donation_Histories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Donation_Histories_ManyArgs = {
  updates: Array<Donation_Histories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DonationsArgs = {
  _inc?: InputMaybe<Donations_Inc_Input>;
  _set?: InputMaybe<Donations_Set_Input>;
  where: Donations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Donations_By_PkArgs = {
  _inc?: InputMaybe<Donations_Inc_Input>;
  _set?: InputMaybe<Donations_Set_Input>;
  pk_columns: Donations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Donations_ManyArgs = {
  updates: Array<Donations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_RemindersArgs = {
  _set?: InputMaybe<Event_Reminders_Set_Input>;
  where: Event_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Reminders_By_PkArgs = {
  _set?: InputMaybe<Event_Reminders_Set_Input>;
  pk_columns: Event_Reminders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Reminders_ManyArgs = {
  updates: Array<Event_Reminders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _append?: InputMaybe<Events_Append_Input>;
  _delete_at_path?: InputMaybe<Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Events_Prepend_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _append?: InputMaybe<Events_Append_Input>;
  _delete_at_path?: InputMaybe<Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Events_Prepend_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_ManyArgs = {
  updates: Array<Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExamsArgs = {
  _append?: InputMaybe<Exams_Append_Input>;
  _delete_at_path?: InputMaybe<Exams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Exams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Exams_Delete_Key_Input>;
  _inc?: InputMaybe<Exams_Inc_Input>;
  _prepend?: InputMaybe<Exams_Prepend_Input>;
  _set?: InputMaybe<Exams_Set_Input>;
  where: Exams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Exams_By_PkArgs = {
  _append?: InputMaybe<Exams_Append_Input>;
  _delete_at_path?: InputMaybe<Exams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Exams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Exams_Delete_Key_Input>;
  _inc?: InputMaybe<Exams_Inc_Input>;
  _prepend?: InputMaybe<Exams_Prepend_Input>;
  _set?: InputMaybe<Exams_Set_Input>;
  pk_columns: Exams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Exams_ManyArgs = {
  updates: Array<Exams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FaqsArgs = {
  _append?: InputMaybe<Faqs_Append_Input>;
  _delete_at_path?: InputMaybe<Faqs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Faqs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Faqs_Delete_Key_Input>;
  _prepend?: InputMaybe<Faqs_Prepend_Input>;
  _set?: InputMaybe<Faqs_Set_Input>;
  where: Faqs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faqs_By_PkArgs = {
  _append?: InputMaybe<Faqs_Append_Input>;
  _delete_at_path?: InputMaybe<Faqs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Faqs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Faqs_Delete_Key_Input>;
  _prepend?: InputMaybe<Faqs_Prepend_Input>;
  _set?: InputMaybe<Faqs_Set_Input>;
  pk_columns: Faqs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faqs_ManyArgs = {
  updates: Array<Faqs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_MembersArgs = {
  _set?: InputMaybe<Group_Members_Set_Input>;
  where: Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Members_By_PkArgs = {
  _set?: InputMaybe<Group_Members_Set_Input>;
  pk_columns: Group_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Members_ManyArgs = {
  updates: Array<Group_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hearing_CouncilsArgs = {
  _append?: InputMaybe<Hearing_Councils_Append_Input>;
  _delete_at_path?: InputMaybe<Hearing_Councils_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hearing_Councils_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hearing_Councils_Delete_Key_Input>;
  _inc?: InputMaybe<Hearing_Councils_Inc_Input>;
  _prepend?: InputMaybe<Hearing_Councils_Prepend_Input>;
  _set?: InputMaybe<Hearing_Councils_Set_Input>;
  where: Hearing_Councils_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hearing_Councils_By_PkArgs = {
  _append?: InputMaybe<Hearing_Councils_Append_Input>;
  _delete_at_path?: InputMaybe<Hearing_Councils_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hearing_Councils_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hearing_Councils_Delete_Key_Input>;
  _inc?: InputMaybe<Hearing_Councils_Inc_Input>;
  _prepend?: InputMaybe<Hearing_Councils_Prepend_Input>;
  _set?: InputMaybe<Hearing_Councils_Set_Input>;
  pk_columns: Hearing_Councils_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hearing_Councils_ManyArgs = {
  updates: Array<Hearing_Councils_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Knowledge_BasesArgs = {
  _append?: InputMaybe<Knowledge_Bases_Append_Input>;
  _delete_at_path?: InputMaybe<Knowledge_Bases_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Knowledge_Bases_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Knowledge_Bases_Delete_Key_Input>;
  _prepend?: InputMaybe<Knowledge_Bases_Prepend_Input>;
  _set?: InputMaybe<Knowledge_Bases_Set_Input>;
  where: Knowledge_Bases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Knowledge_Bases_By_PkArgs = {
  _append?: InputMaybe<Knowledge_Bases_Append_Input>;
  _delete_at_path?: InputMaybe<Knowledge_Bases_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Knowledge_Bases_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Knowledge_Bases_Delete_Key_Input>;
  _prepend?: InputMaybe<Knowledge_Bases_Prepend_Input>;
  _set?: InputMaybe<Knowledge_Bases_Set_Input>;
  pk_columns: Knowledge_Bases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Knowledge_Bases_ManyArgs = {
  updates: Array<Knowledge_Bases_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lectures_And_WebinarsArgs = {
  _set?: InputMaybe<Lectures_And_Webinars_Set_Input>;
  where: Lectures_And_Webinars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lectures_And_Webinars_By_PkArgs = {
  _set?: InputMaybe<Lectures_And_Webinars_Set_Input>;
  pk_columns: Lectures_And_Webinars_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lectures_And_Webinars_ManyArgs = {
  updates: Array<Lectures_And_Webinars_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LevelsArgs = {
  _append?: InputMaybe<Levels_Append_Input>;
  _delete_at_path?: InputMaybe<Levels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Levels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Levels_Delete_Key_Input>;
  _prepend?: InputMaybe<Levels_Prepend_Input>;
  _set?: InputMaybe<Levels_Set_Input>;
  where: Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Levels_By_PkArgs = {
  _append?: InputMaybe<Levels_Append_Input>;
  _delete_at_path?: InputMaybe<Levels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Levels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Levels_Delete_Key_Input>;
  _prepend?: InputMaybe<Levels_Prepend_Input>;
  _set?: InputMaybe<Levels_Set_Input>;
  pk_columns: Levels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Levels_ManyArgs = {
  updates: Array<Levels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Media_MessagesArgs = {
  _append?: InputMaybe<Media_Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Media_Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Media_Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Media_Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Media_Messages_Prepend_Input>;
  _set?: InputMaybe<Media_Messages_Set_Input>;
  where: Media_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Media_Messages_By_PkArgs = {
  _append?: InputMaybe<Media_Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Media_Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Media_Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Media_Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Media_Messages_Prepend_Input>;
  _set?: InputMaybe<Media_Messages_Set_Input>;
  pk_columns: Media_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Media_Messages_ManyArgs = {
  updates: Array<Media_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MeetingsArgs = {
  _set?: InputMaybe<Meetings_Set_Input>;
  where: Meetings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Meetings_By_PkArgs = {
  _set?: InputMaybe<Meetings_Set_Input>;
  pk_columns: Meetings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Meetings_ManyArgs = {
  updates: Array<Meetings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Message_RecipientsArgs = {
  _set?: InputMaybe<Message_Recipients_Set_Input>;
  where: Message_Recipients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Message_Recipients_By_PkArgs = {
  _set?: InputMaybe<Message_Recipients_Set_Input>;
  pk_columns: Message_Recipients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Message_Recipients_ManyArgs = {
  updates: Array<Message_Recipients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoon_CategoriesArgs = {
  _set?: InputMaybe<Mutoon_Categories_Set_Input>;
  where: Mutoon_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoon_Categories_By_PkArgs = {
  _set?: InputMaybe<Mutoon_Categories_Set_Input>;
  pk_columns: Mutoon_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoon_Categories_ManyArgs = {
  updates: Array<Mutoon_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoon_SectionsArgs = {
  _append?: InputMaybe<Mutoon_Sections_Append_Input>;
  _delete_at_path?: InputMaybe<Mutoon_Sections_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mutoon_Sections_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mutoon_Sections_Delete_Key_Input>;
  _prepend?: InputMaybe<Mutoon_Sections_Prepend_Input>;
  _set?: InputMaybe<Mutoon_Sections_Set_Input>;
  where: Mutoon_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoon_Sections_By_PkArgs = {
  _append?: InputMaybe<Mutoon_Sections_Append_Input>;
  _delete_at_path?: InputMaybe<Mutoon_Sections_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mutoon_Sections_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mutoon_Sections_Delete_Key_Input>;
  _prepend?: InputMaybe<Mutoon_Sections_Prepend_Input>;
  _set?: InputMaybe<Mutoon_Sections_Set_Input>;
  pk_columns: Mutoon_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoon_Sections_ManyArgs = {
  updates: Array<Mutoon_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MutoonsArgs = {
  _append?: InputMaybe<Mutoons_Append_Input>;
  _delete_at_path?: InputMaybe<Mutoons_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mutoons_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mutoons_Delete_Key_Input>;
  _inc?: InputMaybe<Mutoons_Inc_Input>;
  _prepend?: InputMaybe<Mutoons_Prepend_Input>;
  _set?: InputMaybe<Mutoons_Set_Input>;
  where: Mutoons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoons_By_PkArgs = {
  _append?: InputMaybe<Mutoons_Append_Input>;
  _delete_at_path?: InputMaybe<Mutoons_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mutoons_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mutoons_Delete_Key_Input>;
  _inc?: InputMaybe<Mutoons_Inc_Input>;
  _prepend?: InputMaybe<Mutoons_Prepend_Input>;
  _set?: InputMaybe<Mutoons_Set_Input>;
  pk_columns: Mutoons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mutoons_ManyArgs = {
  updates: Array<Mutoons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotesArgs = {
  _set?: InputMaybe<Notes_Set_Input>;
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_By_PkArgs = {
  _set?: InputMaybe<Notes_Set_Input>;
  pk_columns: Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_ManyArgs = {
  updates: Array<Notes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_MethodsArgs = {
  _set?: InputMaybe<Payment_Methods_Set_Input>;
  where: Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_By_PkArgs = {
  _set?: InputMaybe<Payment_Methods_Set_Input>;
  pk_columns: Payment_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_ManyArgs = {
  updates: Array<Payment_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_SectionsArgs = {
  _set?: InputMaybe<Plan_Sections_Set_Input>;
  where: Plan_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Sections_By_PkArgs = {
  _set?: InputMaybe<Plan_Sections_Set_Input>;
  pk_columns: Plan_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Sections_ManyArgs = {
  updates: Array<Plan_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PlansArgs = {
  _inc?: InputMaybe<Plans_Inc_Input>;
  _set?: InputMaybe<Plans_Set_Input>;
  where: Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plans_By_PkArgs = {
  _inc?: InputMaybe<Plans_Inc_Input>;
  _set?: InputMaybe<Plans_Set_Input>;
  pk_columns: Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plans_ManyArgs = {
  updates: Array<Plans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Platform_SettingsArgs = {
  _set?: InputMaybe<Platform_Settings_Set_Input>;
  where: Platform_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Platform_Settings_By_PkArgs = {
  _set?: InputMaybe<Platform_Settings_Set_Input>;
  pk_columns: Platform_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Platform_Settings_ManyArgs = {
  updates: Array<Platform_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PrerequisitesArgs = {
  _set?: InputMaybe<Prerequisites_Set_Input>;
  where: Prerequisites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prerequisites_By_PkArgs = {
  _set?: InputMaybe<Prerequisites_Set_Input>;
  pk_columns: Prerequisites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prerequisites_ManyArgs = {
  updates: Array<Prerequisites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _append?: InputMaybe<Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Questions_Inc_Input>;
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _append?: InputMaybe<Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Questions_Inc_Input>;
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_ManyArgs = {
  updates: Array<Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ratings_And_ReviewsArgs = {
  _inc?: InputMaybe<Ratings_And_Reviews_Inc_Input>;
  _set?: InputMaybe<Ratings_And_Reviews_Set_Input>;
  where: Ratings_And_Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ratings_And_Reviews_By_PkArgs = {
  _inc?: InputMaybe<Ratings_And_Reviews_Inc_Input>;
  _set?: InputMaybe<Ratings_And_Reviews_Set_Input>;
  pk_columns: Ratings_And_Reviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ratings_And_Reviews_ManyArgs = {
  updates: Array<Ratings_And_Reviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reference_BooksArgs = {
  _append?: InputMaybe<Reference_Books_Append_Input>;
  _delete_at_path?: InputMaybe<Reference_Books_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reference_Books_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reference_Books_Delete_Key_Input>;
  _inc?: InputMaybe<Reference_Books_Inc_Input>;
  _prepend?: InputMaybe<Reference_Books_Prepend_Input>;
  _set?: InputMaybe<Reference_Books_Set_Input>;
  where: Reference_Books_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reference_Books_By_PkArgs = {
  _append?: InputMaybe<Reference_Books_Append_Input>;
  _delete_at_path?: InputMaybe<Reference_Books_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reference_Books_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reference_Books_Delete_Key_Input>;
  _inc?: InputMaybe<Reference_Books_Inc_Input>;
  _prepend?: InputMaybe<Reference_Books_Prepend_Input>;
  _set?: InputMaybe<Reference_Books_Set_Input>;
  pk_columns: Reference_Books_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reference_Books_ManyArgs = {
  updates: Array<Reference_Books_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: Array<Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ActionsArgs = {
  _set?: InputMaybe<Roles_Actions_Set_Input>;
  where: Roles_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_Actions_By_PkArgs = {
  _set?: InputMaybe<Roles_Actions_Set_Input>;
  pk_columns: Roles_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_Actions_ManyArgs = {
  updates: Array<Roles_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_NotificationsArgs = {
  _append?: InputMaybe<Scheduled_Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Scheduled_Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scheduled_Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scheduled_Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Scheduled_Notifications_Prepend_Input>;
  _set?: InputMaybe<Scheduled_Notifications_Set_Input>;
  where: Scheduled_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Notifications_By_PkArgs = {
  _append?: InputMaybe<Scheduled_Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Scheduled_Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scheduled_Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scheduled_Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Scheduled_Notifications_Prepend_Input>;
  _set?: InputMaybe<Scheduled_Notifications_Set_Input>;
  pk_columns: Scheduled_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Notifications_ManyArgs = {
  updates: Array<Scheduled_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_School_UsersArgs = {
  _set?: InputMaybe<School_Users_Set_Input>;
  where: School_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_School_Users_By_PkArgs = {
  _set?: InputMaybe<School_Users_Set_Input>;
  pk_columns: School_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_School_Users_ManyArgs = {
  updates: Array<School_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scientific_SessionsArgs = {
  _append?: InputMaybe<Scientific_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Scientific_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scientific_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scientific_Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Scientific_Sessions_Inc_Input>;
  _prepend?: InputMaybe<Scientific_Sessions_Prepend_Input>;
  _set?: InputMaybe<Scientific_Sessions_Set_Input>;
  where: Scientific_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scientific_Sessions_By_PkArgs = {
  _append?: InputMaybe<Scientific_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Scientific_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scientific_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scientific_Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Scientific_Sessions_Inc_Input>;
  _prepend?: InputMaybe<Scientific_Sessions_Prepend_Input>;
  _set?: InputMaybe<Scientific_Sessions_Set_Input>;
  pk_columns: Scientific_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scientific_Sessions_ManyArgs = {
  updates: Array<Scientific_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Self_RecitationsArgs = {
  _set?: InputMaybe<Self_Recitations_Set_Input>;
  where: Self_Recitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Self_Recitations_By_PkArgs = {
  _set?: InputMaybe<Self_Recitations_Set_Input>;
  pk_columns: Self_Recitations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Self_Recitations_ManyArgs = {
  updates: Array<Self_Recitations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SessionsArgs = {
  _append?: InputMaybe<Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Sessions_Inc_Input>;
  _prepend?: InputMaybe<Sessions_Prepend_Input>;
  _set?: InputMaybe<Sessions_Set_Input>;
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_By_PkArgs = {
  _append?: InputMaybe<Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Sessions_Inc_Input>;
  _prepend?: InputMaybe<Sessions_Prepend_Input>;
  _set?: InputMaybe<Sessions_Set_Input>;
  pk_columns: Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_ManyArgs = {
  updates: Array<Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SoundsArgs = {
  _append?: InputMaybe<Sounds_Append_Input>;
  _delete_at_path?: InputMaybe<Sounds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sounds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sounds_Delete_Key_Input>;
  _inc?: InputMaybe<Sounds_Inc_Input>;
  _prepend?: InputMaybe<Sounds_Prepend_Input>;
  _set?: InputMaybe<Sounds_Set_Input>;
  where: Sounds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sounds_By_PkArgs = {
  _append?: InputMaybe<Sounds_Append_Input>;
  _delete_at_path?: InputMaybe<Sounds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sounds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sounds_Delete_Key_Input>;
  _inc?: InputMaybe<Sounds_Inc_Input>;
  _prepend?: InputMaybe<Sounds_Prepend_Input>;
  _set?: InputMaybe<Sounds_Set_Input>;
  pk_columns: Sounds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sounds_ManyArgs = {
  updates: Array<Sounds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StatisticsArgs = {
  _append?: InputMaybe<Statistics_Append_Input>;
  _delete_at_path?: InputMaybe<Statistics_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Statistics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Statistics_Delete_Key_Input>;
  _prepend?: InputMaybe<Statistics_Prepend_Input>;
  _set?: InputMaybe<Statistics_Set_Input>;
  where: Statistics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistics_By_PkArgs = {
  _append?: InputMaybe<Statistics_Append_Input>;
  _delete_at_path?: InputMaybe<Statistics_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Statistics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Statistics_Delete_Key_Input>;
  _prepend?: InputMaybe<Statistics_Prepend_Input>;
  _set?: InputMaybe<Statistics_Set_Input>;
  pk_columns: Statistics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistics_ManyArgs = {
  updates: Array<Statistics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StudentsArgs = {
  _set?: InputMaybe<Students_Set_Input>;
  where: Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Students_By_PkArgs = {
  _set?: InputMaybe<Students_Set_Input>;
  pk_columns: Students_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Students_ManyArgs = {
  updates: Array<Students_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  pk_columns: Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: Array<Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Text_MessagesArgs = {
  _set?: InputMaybe<Text_Messages_Set_Input>;
  where: Text_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Messages_By_PkArgs = {
  _set?: InputMaybe<Text_Messages_Set_Input>;
  pk_columns: Text_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Messages_ManyArgs = {
  updates: Array<Text_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_ManyArgs = {
  updates: Array<Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TranslationsArgs = {
  _append?: InputMaybe<Translations_Append_Input>;
  _delete_at_path?: InputMaybe<Translations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Translations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Translations_Delete_Key_Input>;
  _prepend?: InputMaybe<Translations_Prepend_Input>;
  _set?: InputMaybe<Translations_Set_Input>;
  where: Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Translations_By_PkArgs = {
  _append?: InputMaybe<Translations_Append_Input>;
  _delete_at_path?: InputMaybe<Translations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Translations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Translations_Delete_Key_Input>;
  _prepend?: InputMaybe<Translations_Prepend_Input>;
  _set?: InputMaybe<Translations_Set_Input>;
  pk_columns: Translations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Translations_ManyArgs = {
  updates: Array<Translations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_BadgesArgs = {
  _inc?: InputMaybe<User_Badges_Inc_Input>;
  _set?: InputMaybe<User_Badges_Set_Input>;
  where: User_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Badges_By_PkArgs = {
  _inc?: InputMaybe<User_Badges_Inc_Input>;
  _set?: InputMaybe<User_Badges_Set_Input>;
  pk_columns: User_Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Badges_ManyArgs = {
  updates: Array<User_Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_CategoriesArgs = {
  _set?: InputMaybe<User_Categories_Set_Input>;
  where: User_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Categories_By_PkArgs = {
  _set?: InputMaybe<User_Categories_Set_Input>;
  pk_columns: User_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Categories_ManyArgs = {
  updates: Array<User_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_CertificatesArgs = {
  _set?: InputMaybe<User_Certificates_Set_Input>;
  where: User_Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Certificates_By_PkArgs = {
  _set?: InputMaybe<User_Certificates_Set_Input>;
  pk_columns: User_Certificates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Certificates_ManyArgs = {
  updates: Array<User_Certificates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Credit_Card_InfosArgs = {
  _set?: InputMaybe<User_Credit_Card_Infos_Set_Input>;
  where: User_Credit_Card_Infos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Credit_Card_Infos_By_PkArgs = {
  _set?: InputMaybe<User_Credit_Card_Infos_Set_Input>;
  pk_columns: User_Credit_Card_Infos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Credit_Card_Infos_ManyArgs = {
  updates: Array<User_Credit_Card_Infos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Discussion_RoomsArgs = {
  _set?: InputMaybe<User_Discussion_Rooms_Set_Input>;
  where: User_Discussion_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Discussion_Rooms_By_PkArgs = {
  _set?: InputMaybe<User_Discussion_Rooms_Set_Input>;
  pk_columns: User_Discussion_Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Discussion_Rooms_ManyArgs = {
  updates: Array<User_Discussion_Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ExamsArgs = {
  _inc?: InputMaybe<User_Exams_Inc_Input>;
  _set?: InputMaybe<User_Exams_Set_Input>;
  where: User_Exams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Exams_By_PkArgs = {
  _inc?: InputMaybe<User_Exams_Inc_Input>;
  _set?: InputMaybe<User_Exams_Set_Input>;
  pk_columns: User_Exams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Exams_ManyArgs = {
  updates: Array<User_Exams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Hearing_CouncilsArgs = {
  _inc?: InputMaybe<User_Hearing_Councils_Inc_Input>;
  _set?: InputMaybe<User_Hearing_Councils_Set_Input>;
  where: User_Hearing_Councils_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Hearing_Councils_By_PkArgs = {
  _inc?: InputMaybe<User_Hearing_Councils_Inc_Input>;
  _set?: InputMaybe<User_Hearing_Councils_Set_Input>;
  pk_columns: User_Hearing_Councils_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Hearing_Councils_ManyArgs = {
  updates: Array<User_Hearing_Councils_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_MeetingsArgs = {
  _inc?: InputMaybe<User_Meetings_Inc_Input>;
  _set?: InputMaybe<User_Meetings_Set_Input>;
  where: User_Meetings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Meetings_By_PkArgs = {
  _inc?: InputMaybe<User_Meetings_Inc_Input>;
  _set?: InputMaybe<User_Meetings_Set_Input>;
  pk_columns: User_Meetings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Meetings_ManyArgs = {
  updates: Array<User_Meetings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PreferencesArgs = {
  _set?: InputMaybe<User_Preferences_Set_Input>;
  where: User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Preferences_By_PkArgs = {
  _set?: InputMaybe<User_Preferences_Set_Input>;
  pk_columns: User_Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Preferences_ManyArgs = {
  updates: Array<User_Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_QuizesArgs = {
  _inc?: InputMaybe<User_Quizes_Inc_Input>;
  _set?: InputMaybe<User_Quizes_Set_Input>;
  where: User_Quizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Quizes_By_PkArgs = {
  _inc?: InputMaybe<User_Quizes_Inc_Input>;
  _set?: InputMaybe<User_Quizes_Set_Input>;
  pk_columns: User_Quizes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Quizes_ManyArgs = {
  updates: Array<User_Quizes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RecordsArgs = {
  _set?: InputMaybe<User_Records_Set_Input>;
  where: User_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Records_By_PkArgs = {
  _set?: InputMaybe<User_Records_Set_Input>;
  pk_columns: User_Records_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Records_ManyArgs = {
  updates: Array<User_Records_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Scientific_SessionsArgs = {
  _set?: InputMaybe<User_Scientific_Sessions_Set_Input>;
  where: User_Scientific_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Scientific_Sessions_By_PkArgs = {
  _set?: InputMaybe<User_Scientific_Sessions_Set_Input>;
  pk_columns: User_Scientific_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Scientific_Sessions_ManyArgs = {
  updates: Array<User_Scientific_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_SessionsArgs = {
  _inc?: InputMaybe<User_Sessions_Inc_Input>;
  _set?: InputMaybe<User_Sessions_Set_Input>;
  where: User_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Sessions_By_PkArgs = {
  _inc?: InputMaybe<User_Sessions_Inc_Input>;
  _set?: InputMaybe<User_Sessions_Set_Input>;
  pk_columns: User_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Sessions_ManyArgs = {
  updates: Array<User_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_SubscriptionsArgs = {
  _set?: InputMaybe<User_Subscriptions_Set_Input>;
  where: User_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Subscriptions_By_PkArgs = {
  _set?: InputMaybe<User_Subscriptions_Set_Input>;
  pk_columns: User_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Subscriptions_ManyArgs = {
  updates: Array<User_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootVerifyAccountArgs = {
  verifyAccountInput: VerifyAccountInput;
};

/** columns and relationships of "mutoon_categories" */
export type Mutoon_Categories = {
  __typename?: 'mutoon_categories';
  /** An object relationship */
  category: Categories;
  categoryId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  mutoon: Mutoons;
  mutoonId: Scalars['String']['output'];
};

/** aggregated selection of "mutoon_categories" */
export type Mutoon_Categories_Aggregate = {
  __typename?: 'mutoon_categories_aggregate';
  aggregate?: Maybe<Mutoon_Categories_Aggregate_Fields>;
  nodes: Array<Mutoon_Categories>;
};

export type Mutoon_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mutoon_Categories_Aggregate_Bool_Exp_Count>;
};

export type Mutoon_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mutoon_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mutoon_categories" */
export type Mutoon_Categories_Aggregate_Fields = {
  __typename?: 'mutoon_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Mutoon_Categories_Max_Fields>;
  min?: Maybe<Mutoon_Categories_Min_Fields>;
};


/** aggregate fields of "mutoon_categories" */
export type Mutoon_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "mutoon_categories" */
export type Mutoon_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mutoon_Categories_Max_Order_By>;
  min?: InputMaybe<Mutoon_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mutoon_categories" */
export type Mutoon_Categories_Arr_Rel_Insert_Input = {
  data: Array<Mutoon_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mutoon_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mutoon_categories". All fields are combined with a logical 'AND'. */
export type Mutoon_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Mutoon_Categories_Bool_Exp>>;
  _not?: InputMaybe<Mutoon_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Mutoon_Categories_Bool_Exp>>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mutoon_categories" */
export enum Mutoon_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  MutoonCategoriesPkey = 'mutoon_categories_pkey'
}

/** input type for inserting data into table "mutoon_categories" */
export type Mutoon_Categories_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mutoon_Categories_Max_Fields = {
  __typename?: 'mutoon_categories_max_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoonId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "mutoon_categories" */
export type Mutoon_Categories_Max_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoonId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mutoon_Categories_Min_Fields = {
  __typename?: 'mutoon_categories_min_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoonId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "mutoon_categories" */
export type Mutoon_Categories_Min_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoonId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mutoon_categories" */
export type Mutoon_Categories_Mutation_Response = {
  __typename?: 'mutoon_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mutoon_Categories>;
};

/** on_conflict condition type for table "mutoon_categories" */
export type Mutoon_Categories_On_Conflict = {
  constraint: Mutoon_Categories_Constraint;
  update_columns?: Array<Mutoon_Categories_Update_Column>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "mutoon_categories". */
export type Mutoon_Categories_Order_By = {
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mutoon_categories */
export type Mutoon_Categories_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "mutoon_categories" */
export enum Mutoon_Categories_Select_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  MutoonId = 'mutoonId'
}

/** input type for updating data in table "mutoon_categories" */
export type Mutoon_Categories_Set_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "mutoon_categories" */
export type Mutoon_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mutoon_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mutoon_Categories_Stream_Cursor_Value_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "mutoon_categories" */
export enum Mutoon_Categories_Update_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  MutoonId = 'mutoonId'
}

export type Mutoon_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mutoon_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mutoon_Categories_Bool_Exp;
};

/** columns and relationships of "mutoon_sections" */
export type Mutoon_Sections = {
  __typename?: 'mutoon_sections';
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['jsonb']['output']>;
  discussionRoomRoomId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  discussion_room?: Maybe<Discussion_Rooms>;
  /** An object relationship */
  mutoon?: Maybe<Mutoons>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  mutoonSectionId: Scalars['String']['output'];
  name: Scalars['jsonb']['output'];
  /** An array relationship */
  note: Array<Notes>;
  /** An aggregate relationship */
  note_aggregate: Notes_Aggregate;
  /** An array relationship */
  plan_sections: Array<Plan_Sections>;
  /** An aggregate relationship */
  plan_sections_aggregate: Plan_Sections_Aggregate;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  sounds: Array<Sounds>;
  /** An aggregate relationship */
  sounds_aggregate: Sounds_Aggregate;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsNoteArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsPlan_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsPlan_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsSoundsArgs = {
  distinct_on?: InputMaybe<Array<Sounds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sounds_Order_By>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsSounds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sounds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sounds_Order_By>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


/** columns and relationships of "mutoon_sections" */
export type Mutoon_SectionsTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** aggregated selection of "mutoon_sections" */
export type Mutoon_Sections_Aggregate = {
  __typename?: 'mutoon_sections_aggregate';
  aggregate?: Maybe<Mutoon_Sections_Aggregate_Fields>;
  nodes: Array<Mutoon_Sections>;
};

export type Mutoon_Sections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mutoon_Sections_Aggregate_Bool_Exp_Count>;
};

export type Mutoon_Sections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mutoon_sections" */
export type Mutoon_Sections_Aggregate_Fields = {
  __typename?: 'mutoon_sections_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Mutoon_Sections_Max_Fields>;
  min?: Maybe<Mutoon_Sections_Min_Fields>;
};


/** aggregate fields of "mutoon_sections" */
export type Mutoon_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "mutoon_sections" */
export type Mutoon_Sections_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mutoon_Sections_Max_Order_By>;
  min?: InputMaybe<Mutoon_Sections_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mutoon_Sections_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "mutoon_sections" */
export type Mutoon_Sections_Arr_Rel_Insert_Input = {
  data: Array<Mutoon_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mutoon_Sections_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mutoon_sections". All fields are combined with a logical 'AND'. */
export type Mutoon_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Mutoon_Sections_Bool_Exp>>;
  _not?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Mutoon_Sections_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  discussionRoomRoomId?: InputMaybe<String_Comparison_Exp>;
  discussion_room?: InputMaybe<Discussion_Rooms_Bool_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonMatnId?: InputMaybe<String_Comparison_Exp>;
  mutoonSectionId?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  note?: InputMaybe<Notes_Bool_Exp>;
  note_aggregate?: InputMaybe<Notes_Aggregate_Bool_Exp>;
  plan_sections?: InputMaybe<Plan_Sections_Bool_Exp>;
  plan_sections_aggregate?: InputMaybe<Plan_Sections_Aggregate_Bool_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  referenceBookBookId?: InputMaybe<String_Comparison_Exp>;
  sounds?: InputMaybe<Sounds_Bool_Exp>;
  sounds_aggregate?: InputMaybe<Sounds_Aggregate_Bool_Exp>;
  translations?: InputMaybe<Translations_Bool_Exp>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mutoon_sections" */
export enum Mutoon_Sections_Constraint {
  /** unique or primary key constraint on columns "mutoonSectionId" */
  MutoonSectionsPkey = 'mutoon_sections_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mutoon_Sections_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mutoon_Sections_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mutoon_Sections_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "mutoon_sections" */
export type Mutoon_Sections_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  discussionRoomRoomId?: InputMaybe<Scalars['String']['input']>;
  discussion_room?: InputMaybe<Discussion_Rooms_Obj_Rel_Insert_Input>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  note?: InputMaybe<Notes_Arr_Rel_Insert_Input>;
  plan_sections?: InputMaybe<Plan_Sections_Arr_Rel_Insert_Input>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  sounds?: InputMaybe<Sounds_Arr_Rel_Insert_Input>;
  translations?: InputMaybe<Translations_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mutoon_Sections_Max_Fields = {
  __typename?: 'mutoon_sections_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  discussionRoomRoomId?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "mutoon_sections" */
export type Mutoon_Sections_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discussionRoomRoomId?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mutoon_Sections_Min_Fields = {
  __typename?: 'mutoon_sections_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  discussionRoomRoomId?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "mutoon_sections" */
export type Mutoon_Sections_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discussionRoomRoomId?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mutoon_sections" */
export type Mutoon_Sections_Mutation_Response = {
  __typename?: 'mutoon_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mutoon_Sections>;
};

/** input type for inserting object relation for remote table "mutoon_sections" */
export type Mutoon_Sections_Obj_Rel_Insert_Input = {
  data: Mutoon_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mutoon_Sections_On_Conflict>;
};

/** on_conflict condition type for table "mutoon_sections" */
export type Mutoon_Sections_On_Conflict = {
  constraint: Mutoon_Sections_Constraint;
  update_columns?: Array<Mutoon_Sections_Update_Column>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "mutoon_sections". */
export type Mutoon_Sections_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discussionRoomRoomId?: InputMaybe<Order_By>;
  discussion_room?: InputMaybe<Discussion_Rooms_Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note_aggregate?: InputMaybe<Notes_Aggregate_Order_By>;
  plan_sections_aggregate?: InputMaybe<Plan_Sections_Aggregate_Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  sounds_aggregate?: InputMaybe<Sounds_Aggregate_Order_By>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mutoon_sections */
export type Mutoon_Sections_Pk_Columns_Input = {
  mutoonSectionId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mutoon_Sections_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "mutoon_sections" */
export enum Mutoon_Sections_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionRoomRoomId = 'discussionRoomRoomId',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  Name = 'name',
  /** column name */
  ReferenceBookBookId = 'referenceBookBookId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "mutoon_sections" */
export type Mutoon_Sections_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  discussionRoomRoomId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "mutoon_sections" */
export type Mutoon_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mutoon_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mutoon_Sections_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  discussionRoomRoomId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "mutoon_sections" */
export enum Mutoon_Sections_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionRoomRoomId = 'discussionRoomRoomId',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  Name = 'name',
  /** column name */
  ReferenceBookBookId = 'referenceBookBookId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Mutoon_Sections_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mutoon_Sections_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mutoon_Sections_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mutoon_Sections_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mutoon_Sections_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mutoon_Sections_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mutoon_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mutoon_Sections_Bool_Exp;
};

/** columns and relationships of "mutoons" */
export type Mutoons = {
  __typename?: 'mutoons';
  /** An array relationship */
  badges: Array<Badges>;
  /** An aggregate relationship */
  badges_aggregate: Badges_Aggregate;
  /** An object relationship */
  category?: Maybe<Categories>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['jsonb']['output']>;
  discussionRoomRoomID?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  exam?: Maybe<Exams>;
  /** An array relationship */
  hearing_councils: Array<Hearing_Councils>;
  /** An aggregate relationship */
  hearing_councils_aggregate: Hearing_Councils_Aggregate;
  id: Scalars['String']['output'];
  /** An object relationship */
  level?: Maybe<Levels>;
  levelId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  mutoonCategories: Array<Mutoon_Categories>;
  /** An aggregate relationship */
  mutoonCategories_aggregate: Mutoon_Categories_Aggregate;
  mutoonType?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  mutoon_sections: Array<Mutoon_Sections>;
  /** An aggregate relationship */
  mutoon_sections_aggregate: Mutoon_Sections_Aggregate;
  name: Scalars['jsonb']['output'];
  numberOfHadith: Scalars['Int']['output'];
  /** An array relationship */
  plans: Array<Plans>;
  /** An aggregate relationship */
  plans_aggregate: Plans_Aggregate;
  /** An object relationship */
  referenceBook?: Maybe<Reference_Books>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  scientificContent?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_certificates: Array<User_Certificates>;
  /** An aggregate relationship */
  user_certificates_aggregate: User_Certificates_Aggregate;
};


/** columns and relationships of "mutoons" */
export type MutoonsBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "mutoons" */
export type MutoonsHearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsHearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsMutoonCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsMutoonCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsMutoon_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsMutoon_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "mutoons" */
export type MutoonsPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsScientificContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "mutoons" */
export type MutoonsUser_CertificatesArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "mutoons" */
export type MutoonsUser_Certificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};

/** aggregated selection of "mutoons" */
export type Mutoons_Aggregate = {
  __typename?: 'mutoons_aggregate';
  aggregate?: Maybe<Mutoons_Aggregate_Fields>;
  nodes: Array<Mutoons>;
};

export type Mutoons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mutoons_Aggregate_Bool_Exp_Count>;
};

export type Mutoons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mutoons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mutoons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mutoons" */
export type Mutoons_Aggregate_Fields = {
  __typename?: 'mutoons_aggregate_fields';
  avg?: Maybe<Mutoons_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mutoons_Max_Fields>;
  min?: Maybe<Mutoons_Min_Fields>;
  stddev?: Maybe<Mutoons_Stddev_Fields>;
  stddev_pop?: Maybe<Mutoons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mutoons_Stddev_Samp_Fields>;
  sum?: Maybe<Mutoons_Sum_Fields>;
  var_pop?: Maybe<Mutoons_Var_Pop_Fields>;
  var_samp?: Maybe<Mutoons_Var_Samp_Fields>;
  variance?: Maybe<Mutoons_Variance_Fields>;
};


/** aggregate fields of "mutoons" */
export type Mutoons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mutoons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "mutoons" */
export type Mutoons_Aggregate_Order_By = {
  avg?: InputMaybe<Mutoons_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mutoons_Max_Order_By>;
  min?: InputMaybe<Mutoons_Min_Order_By>;
  stddev?: InputMaybe<Mutoons_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mutoons_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mutoons_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mutoons_Sum_Order_By>;
  var_pop?: InputMaybe<Mutoons_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mutoons_Var_Samp_Order_By>;
  variance?: InputMaybe<Mutoons_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mutoons_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  scientificContent?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "mutoons" */
export type Mutoons_Arr_Rel_Insert_Input = {
  data: Array<Mutoons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mutoons_On_Conflict>;
};

/** aggregate avg on columns */
export type Mutoons_Avg_Fields = {
  __typename?: 'mutoons_avg_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "mutoons" */
export type Mutoons_Avg_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mutoons". All fields are combined with a logical 'AND'. */
export type Mutoons_Bool_Exp = {
  _and?: InputMaybe<Array<Mutoons_Bool_Exp>>;
  _not?: InputMaybe<Mutoons_Bool_Exp>;
  _or?: InputMaybe<Array<Mutoons_Bool_Exp>>;
  badges?: InputMaybe<Badges_Bool_Exp>;
  badges_aggregate?: InputMaybe<Badges_Aggregate_Bool_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  discussionRoomRoomID?: InputMaybe<String_Comparison_Exp>;
  exam?: InputMaybe<Exams_Bool_Exp>;
  hearing_councils?: InputMaybe<Hearing_Councils_Bool_Exp>;
  hearing_councils_aggregate?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Levels_Bool_Exp>;
  levelId?: InputMaybe<String_Comparison_Exp>;
  mutoonCategories?: InputMaybe<Mutoon_Categories_Bool_Exp>;
  mutoonCategories_aggregate?: InputMaybe<Mutoon_Categories_Aggregate_Bool_Exp>;
  mutoonType?: InputMaybe<String_Comparison_Exp>;
  mutoon_sections?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  mutoon_sections_aggregate?: InputMaybe<Mutoon_Sections_Aggregate_Bool_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  numberOfHadith?: InputMaybe<Int_Comparison_Exp>;
  plans?: InputMaybe<Plans_Bool_Exp>;
  plans_aggregate?: InputMaybe<Plans_Aggregate_Bool_Exp>;
  referenceBook?: InputMaybe<Reference_Books_Bool_Exp>;
  referenceBookBookId?: InputMaybe<String_Comparison_Exp>;
  scientificContent?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_certificates?: InputMaybe<User_Certificates_Bool_Exp>;
  user_certificates_aggregate?: InputMaybe<User_Certificates_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "mutoons" */
export enum Mutoons_Constraint {
  /** unique or primary key constraint on columns "id" */
  MutoonsPkey = 'mutoons_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mutoons_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  scientificContent?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mutoons_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
  scientificContent?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mutoons_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scientificContent?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "mutoons" */
export type Mutoons_Inc_Input = {
  numberOfHadith?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "mutoons" */
export type Mutoons_Insert_Input = {
  badges?: InputMaybe<Badges_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  discussionRoomRoomID?: InputMaybe<Scalars['String']['input']>;
  exam?: InputMaybe<Exams_Obj_Rel_Insert_Input>;
  hearing_councils?: InputMaybe<Hearing_Councils_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Levels_Obj_Rel_Insert_Input>;
  levelId?: InputMaybe<Scalars['String']['input']>;
  mutoonCategories?: InputMaybe<Mutoon_Categories_Arr_Rel_Insert_Input>;
  mutoonType?: InputMaybe<Scalars['String']['input']>;
  mutoon_sections?: InputMaybe<Mutoon_Sections_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfHadith?: InputMaybe<Scalars['Int']['input']>;
  plans?: InputMaybe<Plans_Arr_Rel_Insert_Input>;
  referenceBook?: InputMaybe<Reference_Books_Obj_Rel_Insert_Input>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  scientificContent?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_certificates?: InputMaybe<User_Certificates_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mutoons_Max_Fields = {
  __typename?: 'mutoons_max_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  discussionRoomRoomID?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
  mutoonType?: Maybe<Scalars['String']['output']>;
  numberOfHadith?: Maybe<Scalars['Int']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "mutoons" */
export type Mutoons_Max_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discussionRoomRoomID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  levelId?: InputMaybe<Order_By>;
  mutoonType?: InputMaybe<Order_By>;
  numberOfHadith?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mutoons_Min_Fields = {
  __typename?: 'mutoons_min_fields';
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  discussionRoomRoomID?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
  mutoonType?: Maybe<Scalars['String']['output']>;
  numberOfHadith?: Maybe<Scalars['Int']['output']>;
  referenceBookBookId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "mutoons" */
export type Mutoons_Min_Order_By = {
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discussionRoomRoomID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  levelId?: InputMaybe<Order_By>;
  mutoonType?: InputMaybe<Order_By>;
  numberOfHadith?: InputMaybe<Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mutoons" */
export type Mutoons_Mutation_Response = {
  __typename?: 'mutoons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mutoons>;
};

/** input type for inserting object relation for remote table "mutoons" */
export type Mutoons_Obj_Rel_Insert_Input = {
  data: Mutoons_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mutoons_On_Conflict>;
};

/** on_conflict condition type for table "mutoons" */
export type Mutoons_On_Conflict = {
  constraint: Mutoons_Constraint;
  update_columns?: Array<Mutoons_Update_Column>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};

/** Ordering options when selecting data from "mutoons". */
export type Mutoons_Order_By = {
  badges_aggregate?: InputMaybe<Badges_Aggregate_Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discussionRoomRoomID?: InputMaybe<Order_By>;
  exam?: InputMaybe<Exams_Order_By>;
  hearing_councils_aggregate?: InputMaybe<Hearing_Councils_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Levels_Order_By>;
  levelId?: InputMaybe<Order_By>;
  mutoonCategories_aggregate?: InputMaybe<Mutoon_Categories_Aggregate_Order_By>;
  mutoonType?: InputMaybe<Order_By>;
  mutoon_sections_aggregate?: InputMaybe<Mutoon_Sections_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  numberOfHadith?: InputMaybe<Order_By>;
  plans_aggregate?: InputMaybe<Plans_Aggregate_Order_By>;
  referenceBook?: InputMaybe<Reference_Books_Order_By>;
  referenceBookBookId?: InputMaybe<Order_By>;
  scientificContent?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_certificates_aggregate?: InputMaybe<User_Certificates_Aggregate_Order_By>;
};

/** primary key columns input for table: mutoons */
export type Mutoons_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mutoons_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  scientificContent?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "mutoons" */
export enum Mutoons_Select_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionRoomRoomId = 'discussionRoomRoomID',
  /** column name */
  Id = 'id',
  /** column name */
  LevelId = 'levelId',
  /** column name */
  MutoonType = 'mutoonType',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfHadith = 'numberOfHadith',
  /** column name */
  ReferenceBookBookId = 'referenceBookBookId',
  /** column name */
  ScientificContent = 'scientificContent',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "mutoons" */
export type Mutoons_Set_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  discussionRoomRoomID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
  mutoonType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfHadith?: InputMaybe<Scalars['Int']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  scientificContent?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mutoons_Stddev_Fields = {
  __typename?: 'mutoons_stddev_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "mutoons" */
export type Mutoons_Stddev_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mutoons_Stddev_Pop_Fields = {
  __typename?: 'mutoons_stddev_pop_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "mutoons" */
export type Mutoons_Stddev_Pop_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mutoons_Stddev_Samp_Fields = {
  __typename?: 'mutoons_stddev_samp_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "mutoons" */
export type Mutoons_Stddev_Samp_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mutoons" */
export type Mutoons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mutoons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mutoons_Stream_Cursor_Value_Input = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  discussionRoomRoomID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
  mutoonType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfHadith?: InputMaybe<Scalars['Int']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
  scientificContent?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mutoons_Sum_Fields = {
  __typename?: 'mutoons_sum_fields';
  numberOfHadith?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "mutoons" */
export type Mutoons_Sum_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** update columns of table "mutoons" */
export enum Mutoons_Update_Column {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionRoomRoomId = 'discussionRoomRoomID',
  /** column name */
  Id = 'id',
  /** column name */
  LevelId = 'levelId',
  /** column name */
  MutoonType = 'mutoonType',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfHadith = 'numberOfHadith',
  /** column name */
  ReferenceBookBookId = 'referenceBookBookId',
  /** column name */
  ScientificContent = 'scientificContent',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Mutoons_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mutoons_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mutoons_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mutoons_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mutoons_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mutoons_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mutoons_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mutoons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mutoons_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mutoons_Var_Pop_Fields = {
  __typename?: 'mutoons_var_pop_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "mutoons" */
export type Mutoons_Var_Pop_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mutoons_Var_Samp_Fields = {
  __typename?: 'mutoons_var_samp_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "mutoons" */
export type Mutoons_Var_Samp_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mutoons_Variance_Fields = {
  __typename?: 'mutoons_variance_fields';
  numberOfHadith?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "mutoons" */
export type Mutoons_Variance_Order_By = {
  numberOfHadith?: InputMaybe<Order_By>;
};

/** columns and relationships of "notes" */
export type Notes = {
  __typename?: 'notes';
  color: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  mutoon_section?: Maybe<Mutoon_Sections>;
  noteId: Scalars['String']['output'];
  planSectionId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  plan_section?: Maybe<Plan_Sections>;
  sectionId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "notes" */
export type Notes_Aggregate = {
  __typename?: 'notes_aggregate';
  aggregate?: Maybe<Notes_Aggregate_Fields>;
  nodes: Array<Notes>;
};

export type Notes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notes_Aggregate_Bool_Exp_Count>;
};

export type Notes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notes" */
export type Notes_Aggregate_Fields = {
  __typename?: 'notes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notes_Max_Fields>;
  min?: Maybe<Notes_Min_Fields>;
};


/** aggregate fields of "notes" */
export type Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notes" */
export type Notes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notes_Max_Order_By>;
  min?: InputMaybe<Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notes" */
export type Notes_Arr_Rel_Insert_Input = {
  data: Array<Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notes". All fields are combined with a logical 'AND'. */
export type Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Notes_Bool_Exp>>;
  _not?: InputMaybe<Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Notes_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  noteId?: InputMaybe<String_Comparison_Exp>;
  planSectionId?: InputMaybe<String_Comparison_Exp>;
  plan_section?: InputMaybe<Plan_Sections_Bool_Exp>;
  sectionId?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notes" */
export enum Notes_Constraint {
  /** unique or primary key constraint on columns "noteId" */
  NotesPkey = 'notes_pkey'
}

/** input type for inserting data into table "notes" */
export type Notes_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  noteId?: InputMaybe<Scalars['String']['input']>;
  planSectionId?: InputMaybe<Scalars['String']['input']>;
  plan_section?: InputMaybe<Plan_Sections_Obj_Rel_Insert_Input>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notes_Max_Fields = {
  __typename?: 'notes_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  noteId?: Maybe<Scalars['String']['output']>;
  planSectionId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "notes" */
export type Notes_Max_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  noteId?: InputMaybe<Order_By>;
  planSectionId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notes_Min_Fields = {
  __typename?: 'notes_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  noteId?: Maybe<Scalars['String']['output']>;
  planSectionId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "notes" */
export type Notes_Min_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  noteId?: InputMaybe<Order_By>;
  planSectionId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notes" */
export type Notes_Mutation_Response = {
  __typename?: 'notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notes>;
};

/** on_conflict condition type for table "notes" */
export type Notes_On_Conflict = {
  constraint: Notes_Constraint;
  update_columns?: Array<Notes_Update_Column>;
  where?: InputMaybe<Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "notes". */
export type Notes_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Order_By>;
  noteId?: InputMaybe<Order_By>;
  planSectionId?: InputMaybe<Order_By>;
  plan_section?: InputMaybe<Plan_Sections_Order_By>;
  sectionId?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notes */
export type Notes_Pk_Columns_Input = {
  noteId: Scalars['String']['input'];
};

/** select columns of table "notes" */
export enum Notes_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  NoteId = 'noteId',
  /** column name */
  PlanSectionId = 'planSectionId',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "notes" */
export type Notes_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  noteId?: InputMaybe<Scalars['String']['input']>;
  planSectionId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notes" */
export type Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notes_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  noteId?: InputMaybe<Scalars['String']['input']>;
  planSectionId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notes" */
export enum Notes_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  NoteId = 'noteId',
  /** column name */
  PlanSectionId = 'planSectionId',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Notes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notes_Bool_Exp;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  notification?: Maybe<Scalars['jsonb']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "notifications" */
export type NotificationsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "notifications" */
export type NotificationsNotificationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  notification?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  notification?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notifications_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  notification?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notifications_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
  notification?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notifications_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  body?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  notification?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Notification = 'notification',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Notification = 'notification',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment_methods" */
export type Payment_Methods = {
  __typename?: 'payment_methods';
  methodName: Scalars['String']['output'];
  paymentMethodId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** aggregated selection of "payment_methods" */
export type Payment_Methods_Aggregate = {
  __typename?: 'payment_methods_aggregate';
  aggregate?: Maybe<Payment_Methods_Aggregate_Fields>;
  nodes: Array<Payment_Methods>;
};

/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_Fields = {
  __typename?: 'payment_methods_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Payment_Methods_Max_Fields>;
  min?: Maybe<Payment_Methods_Min_Fields>;
};


/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "payment_methods". All fields are combined with a logical 'AND'. */
export type Payment_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Methods_Bool_Exp>>;
  _not?: InputMaybe<Payment_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Methods_Bool_Exp>>;
  methodName?: InputMaybe<String_Comparison_Exp>;
  paymentMethodId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_methods" */
export enum Payment_Methods_Constraint {
  /** unique or primary key constraint on columns "paymentMethodId" */
  PaymentMethodsPkey = 'payment_methods_pkey'
}

/** input type for inserting data into table "payment_methods" */
export type Payment_Methods_Insert_Input = {
  methodName?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Payment_Methods_Max_Fields = {
  __typename?: 'payment_methods_max_fields';
  methodName?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Payment_Methods_Min_Fields = {
  __typename?: 'payment_methods_min_fields';
  methodName?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "payment_methods" */
export type Payment_Methods_Mutation_Response = {
  __typename?: 'payment_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Methods>;
};

/** on_conflict condition type for table "payment_methods" */
export type Payment_Methods_On_Conflict = {
  constraint: Payment_Methods_Constraint;
  update_columns?: Array<Payment_Methods_Update_Column>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_methods". */
export type Payment_Methods_Order_By = {
  methodName?: InputMaybe<Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_methods */
export type Payment_Methods_Pk_Columns_Input = {
  paymentMethodId: Scalars['String']['input'];
};

/** select columns of table "payment_methods" */
export enum Payment_Methods_Select_Column {
  /** column name */
  MethodName = 'methodName',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "payment_methods" */
export type Payment_Methods_Set_Input = {
  methodName?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "payment_methods" */
export type Payment_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Methods_Stream_Cursor_Value_Input = {
  methodName?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "payment_methods" */
export enum Payment_Methods_Update_Column {
  /** column name */
  MethodName = 'methodName',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  Status = 'status'
}

export type Payment_Methods_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Methods_Bool_Exp;
};

/** columns and relationships of "plan_sections" */
export type Plan_Sections = {
  __typename?: 'plan_sections';
  createdAt: Scalars['timestamptz']['output'];
  markAsDone: Scalars['Boolean']['output'];
  /** An object relationship */
  mutoon_section?: Maybe<Mutoon_Sections>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** An object relationship */
  plan?: Maybe<Plans>;
  planPlanId?: Maybe<Scalars['String']['output']>;
  planSectionId: Scalars['String']['output'];
  sectionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  userRecord?: Maybe<User_Records>;
  userRecordId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "plan_sections" */
export type Plan_SectionsNotesArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


/** columns and relationships of "plan_sections" */
export type Plan_SectionsNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};

/** aggregated selection of "plan_sections" */
export type Plan_Sections_Aggregate = {
  __typename?: 'plan_sections_aggregate';
  aggregate?: Maybe<Plan_Sections_Aggregate_Fields>;
  nodes: Array<Plan_Sections>;
};

export type Plan_Sections_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plan_Sections_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plan_Sections_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plan_Sections_Aggregate_Bool_Exp_Count>;
};

export type Plan_Sections_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plan_Sections_Select_Column_Plan_Sections_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Sections_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Sections_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plan_Sections_Select_Column_Plan_Sections_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Sections_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Sections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Sections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plan_sections" */
export type Plan_Sections_Aggregate_Fields = {
  __typename?: 'plan_sections_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Plan_Sections_Max_Fields>;
  min?: Maybe<Plan_Sections_Min_Fields>;
};


/** aggregate fields of "plan_sections" */
export type Plan_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plan_sections" */
export type Plan_Sections_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plan_Sections_Max_Order_By>;
  min?: InputMaybe<Plan_Sections_Min_Order_By>;
};

/** input type for inserting array relation for remote table "plan_sections" */
export type Plan_Sections_Arr_Rel_Insert_Input = {
  data: Array<Plan_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_Sections_On_Conflict>;
};

/** Boolean expression to filter rows from the table "plan_sections". All fields are combined with a logical 'AND'. */
export type Plan_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Plan_Sections_Bool_Exp>>;
  _not?: InputMaybe<Plan_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Plan_Sections_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  markAsDone?: InputMaybe<Boolean_Comparison_Exp>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  notes?: InputMaybe<Notes_Bool_Exp>;
  notes_aggregate?: InputMaybe<Notes_Aggregate_Bool_Exp>;
  plan?: InputMaybe<Plans_Bool_Exp>;
  planPlanId?: InputMaybe<String_Comparison_Exp>;
  planSectionId?: InputMaybe<String_Comparison_Exp>;
  sectionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userRecord?: InputMaybe<User_Records_Bool_Exp>;
  userRecordId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan_sections" */
export enum Plan_Sections_Constraint {
  /** unique or primary key constraint on columns "planSectionId" */
  PlanSectionsPkey = 'plan_sections_pkey'
}

/** input type for inserting data into table "plan_sections" */
export type Plan_Sections_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  markAsDone?: InputMaybe<Scalars['Boolean']['input']>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  notes?: InputMaybe<Notes_Arr_Rel_Insert_Input>;
  plan?: InputMaybe<Plans_Obj_Rel_Insert_Input>;
  planPlanId?: InputMaybe<Scalars['String']['input']>;
  planSectionId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userRecord?: InputMaybe<User_Records_Obj_Rel_Insert_Input>;
  userRecordId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Plan_Sections_Max_Fields = {
  __typename?: 'plan_sections_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  planPlanId?: Maybe<Scalars['String']['output']>;
  planSectionId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userRecordId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "plan_sections" */
export type Plan_Sections_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  planPlanId?: InputMaybe<Order_By>;
  planSectionId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userRecordId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Sections_Min_Fields = {
  __typename?: 'plan_sections_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  planPlanId?: Maybe<Scalars['String']['output']>;
  planSectionId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userRecordId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "plan_sections" */
export type Plan_Sections_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  planPlanId?: InputMaybe<Order_By>;
  planSectionId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userRecordId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plan_sections" */
export type Plan_Sections_Mutation_Response = {
  __typename?: 'plan_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan_Sections>;
};

/** input type for inserting object relation for remote table "plan_sections" */
export type Plan_Sections_Obj_Rel_Insert_Input = {
  data: Plan_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_Sections_On_Conflict>;
};

/** on_conflict condition type for table "plan_sections" */
export type Plan_Sections_On_Conflict = {
  constraint: Plan_Sections_Constraint;
  update_columns?: Array<Plan_Sections_Update_Column>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "plan_sections". */
export type Plan_Sections_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  markAsDone?: InputMaybe<Order_By>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Order_By>;
  notes_aggregate?: InputMaybe<Notes_Aggregate_Order_By>;
  plan?: InputMaybe<Plans_Order_By>;
  planPlanId?: InputMaybe<Order_By>;
  planSectionId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userRecord?: InputMaybe<User_Records_Order_By>;
  userRecordId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plan_sections */
export type Plan_Sections_Pk_Columns_Input = {
  planSectionId: Scalars['String']['input'];
};

/** select columns of table "plan_sections" */
export enum Plan_Sections_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  MarkAsDone = 'markAsDone',
  /** column name */
  PlanPlanId = 'planPlanId',
  /** column name */
  PlanSectionId = 'planSectionId',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserRecordId = 'userRecordId'
}

/** select "plan_sections_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plan_sections" */
export enum Plan_Sections_Select_Column_Plan_Sections_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  MarkAsDone = 'markAsDone'
}

/** select "plan_sections_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plan_sections" */
export enum Plan_Sections_Select_Column_Plan_Sections_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  MarkAsDone = 'markAsDone'
}

/** input type for updating data in table "plan_sections" */
export type Plan_Sections_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  markAsDone?: InputMaybe<Scalars['Boolean']['input']>;
  planPlanId?: InputMaybe<Scalars['String']['input']>;
  planSectionId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userRecordId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "plan_sections" */
export type Plan_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Sections_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  markAsDone?: InputMaybe<Scalars['Boolean']['input']>;
  planPlanId?: InputMaybe<Scalars['String']['input']>;
  planSectionId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userRecordId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "plan_sections" */
export enum Plan_Sections_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  MarkAsDone = 'markAsDone',
  /** column name */
  PlanPlanId = 'planPlanId',
  /** column name */
  PlanSectionId = 'planSectionId',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserRecordId = 'userRecordId'
}

export type Plan_Sections_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plan_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plan_Sections_Bool_Exp;
};

/** columns and relationships of "plans" */
export type Plans = {
  __typename?: 'plans';
  check: Scalars['Int']['output'];
  completedPlanSectionInt?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  dailyRose?: Maybe<Scalars['Int']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  interval: Scalars['IntervalType']['output'];
  /** An object relationship */
  mutoon: Mutoons;
  mutoonMatnId: Scalars['String']['output'];
  offline: Scalars['Boolean']['output'];
  offlinePlanFile?: Maybe<Scalars['String']['output']>;
  perPeriod?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  planIntervalIntervalId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  plan_sections: Array<Plan_Sections>;
  /** An aggregate relationship */
  plan_sections_aggregate: Plan_Sections_Aggregate;
  progress?: Maybe<Scalars['Int']['output']>;
  specificHour?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
  subscribe: Scalars['Boolean']['output'];
  total?: Maybe<Scalars['Int']['output']>;
  type: Scalars['PlanType']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};


/** columns and relationships of "plans" */
export type PlansPlan_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansPlan_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};

/** aggregated selection of "plans" */
export type Plans_Aggregate = {
  __typename?: 'plans_aggregate';
  aggregate?: Maybe<Plans_Aggregate_Fields>;
  nodes: Array<Plans>;
};

export type Plans_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plans_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plans_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plans_Aggregate_Bool_Exp_Count>;
};

export type Plans_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plans_Select_Column_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plans_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plans_Select_Column_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plans_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plans_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_Fields = {
  __typename?: 'plans_aggregate_fields';
  avg?: Maybe<Plans_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plans_Max_Fields>;
  min?: Maybe<Plans_Min_Fields>;
  stddev?: Maybe<Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Plans_Sum_Fields>;
  var_pop?: Maybe<Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Plans_Var_Samp_Fields>;
  variance?: Maybe<Plans_Variance_Fields>;
};


/** aggregate fields of "plans" */
export type Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plans" */
export type Plans_Aggregate_Order_By = {
  avg?: InputMaybe<Plans_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plans_Max_Order_By>;
  min?: InputMaybe<Plans_Min_Order_By>;
  stddev?: InputMaybe<Plans_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plans_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plans_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plans_Sum_Order_By>;
  var_pop?: InputMaybe<Plans_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plans_Var_Samp_Order_By>;
  variance?: InputMaybe<Plans_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plans" */
export type Plans_Arr_Rel_Insert_Input = {
  data: Array<Plans_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** aggregate avg on columns */
export type Plans_Avg_Fields = {
  __typename?: 'plans_avg_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plans" */
export type Plans_Avg_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type Plans_Bool_Exp = {
  _and?: InputMaybe<Array<Plans_Bool_Exp>>;
  _not?: InputMaybe<Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Plans_Bool_Exp>>;
  check?: InputMaybe<Int_Comparison_Exp>;
  completedPlanSectionInt?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dailyRose?: InputMaybe<Int_Comparison_Exp>;
  days?: InputMaybe<String_Array_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  interval?: InputMaybe<IntervalType_Comparison_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonMatnId?: InputMaybe<String_Comparison_Exp>;
  offline?: InputMaybe<Boolean_Comparison_Exp>;
  offlinePlanFile?: InputMaybe<String_Comparison_Exp>;
  perPeriod?: InputMaybe<Int_Comparison_Exp>;
  period?: InputMaybe<String_Comparison_Exp>;
  planId?: InputMaybe<String_Comparison_Exp>;
  planIntervalIntervalId?: InputMaybe<String_Comparison_Exp>;
  plan_sections?: InputMaybe<Plan_Sections_Bool_Exp>;
  plan_sections_aggregate?: InputMaybe<Plan_Sections_Aggregate_Bool_Exp>;
  progress?: InputMaybe<Int_Comparison_Exp>;
  specificHour?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  subscribe?: InputMaybe<Boolean_Comparison_Exp>;
  total?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<PlanType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plans" */
export enum Plans_Constraint {
  /** unique or primary key constraint on columns "planId" */
  PlansPkey = 'plans_pkey',
  /** unique or primary key constraint on columns "planIntervalIntervalId" */
  PlansPlanIntervalIntervalIdKey = 'plans_planIntervalIntervalId_key'
}

/** input type for incrementing numeric columns in table "plans" */
export type Plans_Inc_Input = {
  check?: InputMaybe<Scalars['Int']['input']>;
  completedPlanSectionInt?: InputMaybe<Scalars['Int']['input']>;
  dailyRose?: InputMaybe<Scalars['Int']['input']>;
  perPeriod?: InputMaybe<Scalars['Int']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  specificHour?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "plans" */
export type Plans_Insert_Input = {
  check?: InputMaybe<Scalars['Int']['input']>;
  completedPlanSectionInt?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dailyRose?: InputMaybe<Scalars['Int']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  interval?: InputMaybe<Scalars['IntervalType']['input']>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  offline?: InputMaybe<Scalars['Boolean']['input']>;
  offlinePlanFile?: InputMaybe<Scalars['String']['input']>;
  perPeriod?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  planIntervalIntervalId?: InputMaybe<Scalars['String']['input']>;
  plan_sections?: InputMaybe<Plan_Sections_Arr_Rel_Insert_Input>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  specificHour?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subscribe?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['PlanType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Plans_Max_Fields = {
  __typename?: 'plans_max_fields';
  check?: Maybe<Scalars['Int']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dailyRose?: Maybe<Scalars['Int']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  interval?: Maybe<Scalars['IntervalType']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  offlinePlanFile?: Maybe<Scalars['String']['output']>;
  perPeriod?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  planId?: Maybe<Scalars['String']['output']>;
  planIntervalIntervalId?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  specificHour?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['PlanType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "plans" */
export type Plans_Max_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  offlinePlanFile?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  planId?: InputMaybe<Order_By>;
  planIntervalIntervalId?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plans_Min_Fields = {
  __typename?: 'plans_min_fields';
  check?: Maybe<Scalars['Int']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dailyRose?: Maybe<Scalars['Int']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  interval?: Maybe<Scalars['IntervalType']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  offlinePlanFile?: Maybe<Scalars['String']['output']>;
  perPeriod?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  planId?: Maybe<Scalars['String']['output']>;
  planIntervalIntervalId?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  specificHour?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['PlanType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "plans" */
export type Plans_Min_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  offlinePlanFile?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  planId?: InputMaybe<Order_By>;
  planIntervalIntervalId?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plans" */
export type Plans_Mutation_Response = {
  __typename?: 'plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** input type for inserting object relation for remote table "plans" */
export type Plans_Obj_Rel_Insert_Input = {
  data: Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** on_conflict condition type for table "plans" */
export type Plans_On_Conflict = {
  constraint: Plans_Constraint;
  update_columns?: Array<Plans_Update_Column>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "plans". */
export type Plans_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  offline?: InputMaybe<Order_By>;
  offlinePlanFile?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  planId?: InputMaybe<Order_By>;
  planIntervalIntervalId?: InputMaybe<Order_By>;
  plan_sections_aggregate?: InputMaybe<Plan_Sections_Aggregate_Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscribe?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plans */
export type Plans_Pk_Columns_Input = {
  planId: Scalars['String']['input'];
};

/** select columns of table "plans" */
export enum Plans_Select_Column {
  /** column name */
  Check = 'check',
  /** column name */
  CompletedPlanSectionInt = 'completedPlanSectionInt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DailyRose = 'dailyRose',
  /** column name */
  Days = 'days',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Interval = 'interval',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Offline = 'offline',
  /** column name */
  OfflinePlanFile = 'offlinePlanFile',
  /** column name */
  PerPeriod = 'perPeriod',
  /** column name */
  Period = 'period',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PlanIntervalIntervalId = 'planIntervalIntervalId',
  /** column name */
  Progress = 'progress',
  /** column name */
  SpecificHour = 'specificHour',
  /** column name */
  Status = 'status',
  /** column name */
  Subscribe = 'subscribe',
  /** column name */
  Total = 'total',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** select "plans_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plans" */
export enum Plans_Select_Column_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Offline = 'offline',
  /** column name */
  Subscribe = 'subscribe'
}

/** select "plans_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plans" */
export enum Plans_Select_Column_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Offline = 'offline',
  /** column name */
  Subscribe = 'subscribe'
}

/** input type for updating data in table "plans" */
export type Plans_Set_Input = {
  check?: InputMaybe<Scalars['Int']['input']>;
  completedPlanSectionInt?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dailyRose?: InputMaybe<Scalars['Int']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  interval?: InputMaybe<Scalars['IntervalType']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  offline?: InputMaybe<Scalars['Boolean']['input']>;
  offlinePlanFile?: InputMaybe<Scalars['String']['input']>;
  perPeriod?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  planIntervalIntervalId?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  specificHour?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subscribe?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['PlanType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Plans_Stddev_Fields = {
  __typename?: 'plans_stddev_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plans" */
export type Plans_Stddev_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plans_Stddev_Pop_Fields = {
  __typename?: 'plans_stddev_pop_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plans" */
export type Plans_Stddev_Pop_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plans_Stddev_Samp_Fields = {
  __typename?: 'plans_stddev_samp_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plans" */
export type Plans_Stddev_Samp_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plans" */
export type Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plans_Stream_Cursor_Value_Input = {
  check?: InputMaybe<Scalars['Int']['input']>;
  completedPlanSectionInt?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dailyRose?: InputMaybe<Scalars['Int']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  interval?: InputMaybe<Scalars['IntervalType']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  offline?: InputMaybe<Scalars['Boolean']['input']>;
  offlinePlanFile?: InputMaybe<Scalars['String']['input']>;
  perPeriod?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  planIntervalIntervalId?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  specificHour?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subscribe?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['PlanType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Plans_Sum_Fields = {
  __typename?: 'plans_sum_fields';
  check?: Maybe<Scalars['Int']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Int']['output']>;
  dailyRose?: Maybe<Scalars['Int']['output']>;
  perPeriod?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  specificHour?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "plans" */
export type Plans_Sum_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** update columns of table "plans" */
export enum Plans_Update_Column {
  /** column name */
  Check = 'check',
  /** column name */
  CompletedPlanSectionInt = 'completedPlanSectionInt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DailyRose = 'dailyRose',
  /** column name */
  Days = 'days',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Interval = 'interval',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Offline = 'offline',
  /** column name */
  OfflinePlanFile = 'offlinePlanFile',
  /** column name */
  PerPeriod = 'perPeriod',
  /** column name */
  Period = 'period',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PlanIntervalIntervalId = 'planIntervalIntervalId',
  /** column name */
  Progress = 'progress',
  /** column name */
  SpecificHour = 'specificHour',
  /** column name */
  Status = 'status',
  /** column name */
  Subscribe = 'subscribe',
  /** column name */
  Total = 'total',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Plans_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plans_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plans_Var_Pop_Fields = {
  __typename?: 'plans_var_pop_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plans" */
export type Plans_Var_Pop_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plans_Var_Samp_Fields = {
  __typename?: 'plans_var_samp_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plans" */
export type Plans_Var_Samp_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plans_Variance_Fields = {
  __typename?: 'plans_variance_fields';
  check?: Maybe<Scalars['Float']['output']>;
  completedPlanSectionInt?: Maybe<Scalars['Float']['output']>;
  dailyRose?: Maybe<Scalars['Float']['output']>;
  perPeriod?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  specificHour?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plans" */
export type Plans_Variance_Order_By = {
  check?: InputMaybe<Order_By>;
  completedPlanSectionInt?: InputMaybe<Order_By>;
  dailyRose?: InputMaybe<Order_By>;
  perPeriod?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  specificHour?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** columns and relationships of "platform_settings" */
export type Platform_Settings = {
  __typename?: 'platform_settings';
  settingId: Scalars['String']['output'];
  settingName: Scalars['String']['output'];
  settingValue: Scalars['String']['output'];
};

/** aggregated selection of "platform_settings" */
export type Platform_Settings_Aggregate = {
  __typename?: 'platform_settings_aggregate';
  aggregate?: Maybe<Platform_Settings_Aggregate_Fields>;
  nodes: Array<Platform_Settings>;
};

/** aggregate fields of "platform_settings" */
export type Platform_Settings_Aggregate_Fields = {
  __typename?: 'platform_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Platform_Settings_Max_Fields>;
  min?: Maybe<Platform_Settings_Min_Fields>;
};


/** aggregate fields of "platform_settings" */
export type Platform_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Platform_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_settings". All fields are combined with a logical 'AND'. */
export type Platform_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Platform_Settings_Bool_Exp>>;
  _not?: InputMaybe<Platform_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Platform_Settings_Bool_Exp>>;
  settingId?: InputMaybe<String_Comparison_Exp>;
  settingName?: InputMaybe<String_Comparison_Exp>;
  settingValue?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "platform_settings" */
export enum Platform_Settings_Constraint {
  /** unique or primary key constraint on columns "settingId" */
  PlatformSettingsPkey = 'platform_settings_pkey'
}

/** input type for inserting data into table "platform_settings" */
export type Platform_Settings_Insert_Input = {
  settingId?: InputMaybe<Scalars['String']['input']>;
  settingName?: InputMaybe<Scalars['String']['input']>;
  settingValue?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Platform_Settings_Max_Fields = {
  __typename?: 'platform_settings_max_fields';
  settingId?: Maybe<Scalars['String']['output']>;
  settingName?: Maybe<Scalars['String']['output']>;
  settingValue?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Platform_Settings_Min_Fields = {
  __typename?: 'platform_settings_min_fields';
  settingId?: Maybe<Scalars['String']['output']>;
  settingName?: Maybe<Scalars['String']['output']>;
  settingValue?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_settings" */
export type Platform_Settings_Mutation_Response = {
  __typename?: 'platform_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Platform_Settings>;
};

/** on_conflict condition type for table "platform_settings" */
export type Platform_Settings_On_Conflict = {
  constraint: Platform_Settings_Constraint;
  update_columns?: Array<Platform_Settings_Update_Column>;
  where?: InputMaybe<Platform_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "platform_settings". */
export type Platform_Settings_Order_By = {
  settingId?: InputMaybe<Order_By>;
  settingName?: InputMaybe<Order_By>;
  settingValue?: InputMaybe<Order_By>;
};

/** primary key columns input for table: platform_settings */
export type Platform_Settings_Pk_Columns_Input = {
  settingId: Scalars['String']['input'];
};

/** select columns of table "platform_settings" */
export enum Platform_Settings_Select_Column {
  /** column name */
  SettingId = 'settingId',
  /** column name */
  SettingName = 'settingName',
  /** column name */
  SettingValue = 'settingValue'
}

/** input type for updating data in table "platform_settings" */
export type Platform_Settings_Set_Input = {
  settingId?: InputMaybe<Scalars['String']['input']>;
  settingName?: InputMaybe<Scalars['String']['input']>;
  settingValue?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_settings" */
export type Platform_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Platform_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Platform_Settings_Stream_Cursor_Value_Input = {
  settingId?: InputMaybe<Scalars['String']['input']>;
  settingName?: InputMaybe<Scalars['String']['input']>;
  settingValue?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_settings" */
export enum Platform_Settings_Update_Column {
  /** column name */
  SettingId = 'settingId',
  /** column name */
  SettingName = 'settingName',
  /** column name */
  SettingValue = 'settingValue'
}

export type Platform_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Platform_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Platform_Settings_Bool_Exp;
};

/** columns and relationships of "prerequisites" */
export type Prerequisites = {
  __typename?: 'prerequisites';
  createdAt: Scalars['timestamptz']['output'];
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  hearing_council?: Maybe<Hearing_Councils>;
  /** An object relationship */
  mutoon: Mutoons;
  mutoonMatnId: Scalars['String']['output'];
  prerequisiteId: Scalars['String']['output'];
  /** An object relationship */
  session?: Maybe<Sessions>;
  sessionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "prerequisites" */
export type Prerequisites_Aggregate = {
  __typename?: 'prerequisites_aggregate';
  aggregate?: Maybe<Prerequisites_Aggregate_Fields>;
  nodes: Array<Prerequisites>;
};

export type Prerequisites_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prerequisites_Aggregate_Bool_Exp_Count>;
};

export type Prerequisites_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prerequisites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Prerequisites_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "prerequisites" */
export type Prerequisites_Aggregate_Fields = {
  __typename?: 'prerequisites_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Prerequisites_Max_Fields>;
  min?: Maybe<Prerequisites_Min_Fields>;
};


/** aggregate fields of "prerequisites" */
export type Prerequisites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prerequisites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "prerequisites" */
export type Prerequisites_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prerequisites_Max_Order_By>;
  min?: InputMaybe<Prerequisites_Min_Order_By>;
};

/** input type for inserting array relation for remote table "prerequisites" */
export type Prerequisites_Arr_Rel_Insert_Input = {
  data: Array<Prerequisites_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prerequisites_On_Conflict>;
};

/** Boolean expression to filter rows from the table "prerequisites". All fields are combined with a logical 'AND'. */
export type Prerequisites_Bool_Exp = {
  _and?: InputMaybe<Array<Prerequisites_Bool_Exp>>;
  _not?: InputMaybe<Prerequisites_Bool_Exp>;
  _or?: InputMaybe<Array<Prerequisites_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  hearing_council?: InputMaybe<Hearing_Councils_Bool_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonMatnId?: InputMaybe<String_Comparison_Exp>;
  prerequisiteId?: InputMaybe<String_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  sessionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "prerequisites" */
export enum Prerequisites_Constraint {
  /** unique or primary key constraint on columns "prerequisiteId" */
  PrerequisitesPkey = 'prerequisites_pkey'
}

/** input type for inserting data into table "prerequisites" */
export type Prerequisites_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  hearing_council?: InputMaybe<Hearing_Councils_Obj_Rel_Insert_Input>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  prerequisiteId?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Prerequisites_Max_Fields = {
  __typename?: 'prerequisites_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  prerequisiteId?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "prerequisites" */
export type Prerequisites_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  prerequisiteId?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prerequisites_Min_Fields = {
  __typename?: 'prerequisites_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  prerequisiteId?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "prerequisites" */
export type Prerequisites_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  prerequisiteId?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "prerequisites" */
export type Prerequisites_Mutation_Response = {
  __typename?: 'prerequisites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Prerequisites>;
};

/** on_conflict condition type for table "prerequisites" */
export type Prerequisites_On_Conflict = {
  constraint: Prerequisites_Constraint;
  update_columns?: Array<Prerequisites_Update_Column>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};

/** Ordering options when selecting data from "prerequisites". */
export type Prerequisites_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  hearing_council?: InputMaybe<Hearing_Councils_Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  prerequisiteId?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  sessionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prerequisites */
export type Prerequisites_Pk_Columns_Input = {
  prerequisiteId: Scalars['String']['input'];
};

/** select columns of table "prerequisites" */
export enum Prerequisites_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  PrerequisiteId = 'prerequisiteId',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "prerequisites" */
export type Prerequisites_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  prerequisiteId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "prerequisites" */
export type Prerequisites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prerequisites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prerequisites_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  prerequisiteId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "prerequisites" */
export enum Prerequisites_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  PrerequisiteId = 'prerequisiteId',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Prerequisites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prerequisites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prerequisites_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  FinalQuestionsByMatnId: Exam;
  WeaknessMutoonSections: Array<MutoonSection>;
  /** fetch data from the table: "_DonationHistoryToTransaction" */
  _DonationHistoryToTransaction: Array<_DonationHistoryToTransaction>;
  /** fetch aggregated fields from the table: "_DonationHistoryToTransaction" */
  _DonationHistoryToTransaction_aggregate: _DonationHistoryToTransaction_Aggregate;
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate;
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>;
  /** An array relationship */
  admins: Array<Admins>;
  /** An aggregate relationship */
  admins_aggregate: Admins_Aggregate;
  /** fetch data from the table: "admins" using primary key columns */
  admins_by_pk?: Maybe<Admins>;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<Answers>;
  /** fetch data from the table: "authorized_users" */
  authorized_users: Array<Authorized_Users>;
  /** fetch aggregated fields from the table: "authorized_users" */
  authorized_users_aggregate: Authorized_Users_Aggregate;
  /** fetch data from the table: "authorized_users" using primary key columns */
  authorized_users_by_pk?: Maybe<Authorized_Users>;
  /** An array relationship */
  badges: Array<Badges>;
  /** An aggregate relationship */
  badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "badges" using primary key columns */
  badges_by_pk?: Maybe<Badges>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  certificate_variables: Array<Certificate_Variables>;
  /** An aggregate relationship */
  certificate_variables_aggregate: Certificate_Variables_Aggregate;
  /** fetch data from the table: "certificate_variables" using primary key columns */
  certificate_variables_by_pk?: Maybe<Certificate_Variables>;
  /** fetch data from the table: "certificates" */
  certificates: Array<Certificates>;
  /** fetch aggregated fields from the table: "certificates" */
  certificates_aggregate: Certificates_Aggregate;
  /** fetch data from the table: "certificates" using primary key columns */
  certificates_by_pk?: Maybe<Certificates>;
  /** fetch data from the table: "chat_messages" */
  chat_messages: Array<Chat_Messages>;
  /** fetch aggregated fields from the table: "chat_messages" */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** fetch data from the table: "chats" */
  chats: Array<Chats>;
  /** fetch aggregated fields from the table: "chats" */
  chats_aggregate: Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table: "contact_us" */
  contact_us: Array<Contact_Us>;
  /** fetch aggregated fields from the table: "contact_us" */
  contact_us_aggregate: Contact_Us_Aggregate;
  /** fetch data from the table: "contact_us" using primary key columns */
  contact_us_by_pk?: Maybe<Contact_Us>;
  /** fetch data from the table: "content_pages" */
  content_pages: Array<Content_Pages>;
  /** fetch aggregated fields from the table: "content_pages" */
  content_pages_aggregate: Content_Pages_Aggregate;
  /** fetch data from the table: "content_pages" using primary key columns */
  content_pages_by_pk?: Maybe<Content_Pages>;
  /** fetch data from the table: "content_recommendations" */
  content_recommendations: Array<Content_Recommendations>;
  /** fetch aggregated fields from the table: "content_recommendations" */
  content_recommendations_aggregate: Content_Recommendations_Aggregate;
  /** fetch data from the table: "content_recommendations" using primary key columns */
  content_recommendations_by_pk?: Maybe<Content_Recommendations>;
  countUsers: Scalars['Float']['output'];
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "discussion_rooms" */
  discussion_rooms: Array<Discussion_Rooms>;
  /** fetch aggregated fields from the table: "discussion_rooms" */
  discussion_rooms_aggregate: Discussion_Rooms_Aggregate;
  /** fetch data from the table: "discussion_rooms" using primary key columns */
  discussion_rooms_by_pk?: Maybe<Discussion_Rooms>;
  /** fetch data from the table: "donation_histories" */
  donation_histories: Array<Donation_Histories>;
  /** fetch aggregated fields from the table: "donation_histories" */
  donation_histories_aggregate: Donation_Histories_Aggregate;
  /** fetch data from the table: "donation_histories" using primary key columns */
  donation_histories_by_pk?: Maybe<Donation_Histories>;
  /** fetch data from the table: "donations" */
  donations: Array<Donations>;
  /** fetch aggregated fields from the table: "donations" */
  donations_aggregate: Donations_Aggregate;
  /** fetch data from the table: "donations" using primary key columns */
  donations_by_pk?: Maybe<Donations>;
  /** fetch data from the table: "event_reminders" */
  event_reminders: Array<Event_Reminders>;
  /** fetch aggregated fields from the table: "event_reminders" */
  event_reminders_aggregate: Event_Reminders_Aggregate;
  /** fetch data from the table: "event_reminders" using primary key columns */
  event_reminders_by_pk?: Maybe<Event_Reminders>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "exams" */
  exams: Array<Exams>;
  /** fetch aggregated fields from the table: "exams" */
  exams_aggregate: Exams_Aggregate;
  /** fetch data from the table: "exams" using primary key columns */
  exams_by_pk?: Maybe<Exams>;
  /** fetch data from the table: "faqs" */
  faqs: Array<Faqs>;
  /** fetch aggregated fields from the table: "faqs" */
  faqs_aggregate: Faqs_Aggregate;
  /** fetch data from the table: "faqs" using primary key columns */
  faqs_by_pk?: Maybe<Faqs>;
  fetchAuthAdmin?: Maybe<Admin>;
  fetchUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  findAllHearingCouncils: Array<HearingCouncil>;
  findAllSessions: Array<Session>;
  findOneHearingCouncil: HearingCouncil;
  findOneSession: Session;
  getAllBadges: PaginatedBadges;
  getAllMutoonsByAuthUser: Array<Mutoon>;
  getAllUnMarkedDoneSectionsOfMutoonByPlanID: Array<MutoonSectionAndPlanSection>;
  getBadgeById?: Maybe<Badge>;
  getNotifications: PaginatedNotifications;
  getPlansAchievementsForUser: Array<UserAchievement>;
  getPlansByDate: Array<Plan>;
  getUserCertificatesByMutoonId: UserCertificate;
  getUserCertificatesWithMutoonCategories: UserCertificatesWithMutoonCategories;
  /** fetch data from the table: "group_members" */
  group_members: Array<Group_Members>;
  /** fetch aggregated fields from the table: "group_members" */
  group_members_aggregate: Group_Members_Aggregate;
  /** fetch data from the table: "group_members" using primary key columns */
  group_members_by_pk?: Maybe<Group_Members>;
  /** An array relationship */
  hearing_councils: Array<Hearing_Councils>;
  /** An aggregate relationship */
  hearing_councils_aggregate: Hearing_Councils_Aggregate;
  /** fetch data from the table: "hearing_councils" using primary key columns */
  hearing_councils_by_pk?: Maybe<Hearing_Councils>;
  /** fetch data from the table: "knowledge_bases" */
  knowledge_bases: Array<Knowledge_Bases>;
  /** fetch aggregated fields from the table: "knowledge_bases" */
  knowledge_bases_aggregate: Knowledge_Bases_Aggregate;
  /** fetch data from the table: "knowledge_bases" using primary key columns */
  knowledge_bases_by_pk?: Maybe<Knowledge_Bases>;
  /** fetch data from the table: "lectures_and_webinars" */
  lectures_and_webinars: Array<Lectures_And_Webinars>;
  /** fetch aggregated fields from the table: "lectures_and_webinars" */
  lectures_and_webinars_aggregate: Lectures_And_Webinars_Aggregate;
  /** fetch data from the table: "lectures_and_webinars" using primary key columns */
  lectures_and_webinars_by_pk?: Maybe<Lectures_And_Webinars>;
  /** An array relationship */
  levels: Array<Levels>;
  /** An aggregate relationship */
  levels_aggregate: Levels_Aggregate;
  /** fetch data from the table: "levels" using primary key columns */
  levels_by_pk?: Maybe<Levels>;
  /** fetch data from the table: "media_messages" */
  media_messages: Array<Media_Messages>;
  /** fetch aggregated fields from the table: "media_messages" */
  media_messages_aggregate: Media_Messages_Aggregate;
  /** fetch data from the table: "media_messages" using primary key columns */
  media_messages_by_pk?: Maybe<Media_Messages>;
  /** fetch data from the table: "meetings" */
  meetings: Array<Meetings>;
  /** fetch aggregated fields from the table: "meetings" */
  meetings_aggregate: Meetings_Aggregate;
  /** fetch data from the table: "meetings" using primary key columns */
  meetings_by_pk?: Maybe<Meetings>;
  /** fetch data from the table: "message_recipients" */
  message_recipients: Array<Message_Recipients>;
  /** fetch aggregated fields from the table: "message_recipients" */
  message_recipients_aggregate: Message_Recipients_Aggregate;
  /** fetch data from the table: "message_recipients" using primary key columns */
  message_recipients_by_pk?: Maybe<Message_Recipients>;
  /** fetch data from the table: "mutoon_categories" */
  mutoon_categories: Array<Mutoon_Categories>;
  /** fetch aggregated fields from the table: "mutoon_categories" */
  mutoon_categories_aggregate: Mutoon_Categories_Aggregate;
  /** fetch data from the table: "mutoon_categories" using primary key columns */
  mutoon_categories_by_pk?: Maybe<Mutoon_Categories>;
  /** An array relationship */
  mutoon_sections: Array<Mutoon_Sections>;
  /** An aggregate relationship */
  mutoon_sections_aggregate: Mutoon_Sections_Aggregate;
  /** fetch data from the table: "mutoon_sections" using primary key columns */
  mutoon_sections_by_pk?: Maybe<Mutoon_Sections>;
  /** An array relationship */
  mutoons: Array<Mutoons>;
  /** An aggregate relationship */
  mutoons_aggregate: Mutoons_Aggregate;
  /** fetch data from the table: "mutoons" using primary key columns */
  mutoons_by_pk?: Maybe<Mutoons>;
  mySessions: Array<Session>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "payment_methods" */
  payment_methods: Array<Payment_Methods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** An array relationship */
  plan_sections: Array<Plan_Sections>;
  /** An aggregate relationship */
  plan_sections_aggregate: Plan_Sections_Aggregate;
  /** fetch data from the table: "plan_sections" using primary key columns */
  plan_sections_by_pk?: Maybe<Plan_Sections>;
  /** An array relationship */
  plans: Array<Plans>;
  /** An aggregate relationship */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** fetch data from the table: "platform_settings" */
  platform_settings: Array<Platform_Settings>;
  /** fetch aggregated fields from the table: "platform_settings" */
  platform_settings_aggregate: Platform_Settings_Aggregate;
  /** fetch data from the table: "platform_settings" using primary key columns */
  platform_settings_by_pk?: Maybe<Platform_Settings>;
  /** An array relationship */
  prerequisites: Array<Prerequisites>;
  /** An aggregate relationship */
  prerequisites_aggregate: Prerequisites_Aggregate;
  /** fetch data from the table: "prerequisites" using primary key columns */
  prerequisites_by_pk?: Maybe<Prerequisites>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  quizQuestionsByMatnSections: Array<Question>;
  /** fetch data from the table: "ratings_and_reviews" */
  ratings_and_reviews: Array<Ratings_And_Reviews>;
  /** fetch aggregated fields from the table: "ratings_and_reviews" */
  ratings_and_reviews_aggregate: Ratings_And_Reviews_Aggregate;
  /** fetch data from the table: "ratings_and_reviews" using primary key columns */
  ratings_and_reviews_by_pk?: Maybe<Ratings_And_Reviews>;
  /** fetch data from the table: "reference_books" */
  reference_books: Array<Reference_Books>;
  /** fetch aggregated fields from the table: "reference_books" */
  reference_books_aggregate: Reference_Books_Aggregate;
  /** fetch data from the table: "reference_books" using primary key columns */
  reference_books_by_pk?: Maybe<Reference_Books>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch aggregated fields from the table: "reports" */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** An array relationship */
  roles_actions: Array<Roles_Actions>;
  /** An aggregate relationship */
  roles_actions_aggregate: Roles_Actions_Aggregate;
  /** fetch data from the table: "roles_actions" using primary key columns */
  roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "scheduled_notifications" */
  scheduled_notifications: Array<Scheduled_Notifications>;
  /** fetch aggregated fields from the table: "scheduled_notifications" */
  scheduled_notifications_aggregate: Scheduled_Notifications_Aggregate;
  /** fetch data from the table: "scheduled_notifications" using primary key columns */
  scheduled_notifications_by_pk?: Maybe<Scheduled_Notifications>;
  /** fetch data from the table: "school_users" */
  school_users: Array<School_Users>;
  /** fetch aggregated fields from the table: "school_users" */
  school_users_aggregate: School_Users_Aggregate;
  /** fetch data from the table: "school_users" using primary key columns */
  school_users_by_pk?: Maybe<School_Users>;
  /** fetch data from the table: "scientific_sessions" */
  scientific_sessions: Array<Scientific_Sessions>;
  /** fetch aggregated fields from the table: "scientific_sessions" */
  scientific_sessions_aggregate: Scientific_Sessions_Aggregate;
  /** fetch data from the table: "scientific_sessions" using primary key columns */
  scientific_sessions_by_pk?: Maybe<Scientific_Sessions>;
  /** fetch data from the table: "self_recitations" */
  self_recitations: Array<Self_Recitations>;
  /** fetch aggregated fields from the table: "self_recitations" */
  self_recitations_aggregate: Self_Recitations_Aggregate;
  /** fetch data from the table: "self_recitations" using primary key columns */
  self_recitations_by_pk?: Maybe<Self_Recitations>;
  /** fetch data from the table: "sessions" */
  sessions: Array<Sessions>;
  /** fetch aggregated fields from the table: "sessions" */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** An array relationship */
  sounds: Array<Sounds>;
  /** An aggregate relationship */
  sounds_aggregate: Sounds_Aggregate;
  /** fetch data from the table: "sounds" using primary key columns */
  sounds_by_pk?: Maybe<Sounds>;
  /** fetch data from the table: "statistics" */
  statistics: Array<Statistics>;
  /** fetch aggregated fields from the table: "statistics" */
  statistics_aggregate: Statistics_Aggregate;
  /** fetch data from the table: "statistics" using primary key columns */
  statistics_by_pk?: Maybe<Statistics>;
  /** An array relationship */
  students: Array<Students>;
  /** An aggregate relationship */
  students_aggregate: Students_Aggregate;
  /** fetch data from the table: "students" using primary key columns */
  students_by_pk?: Maybe<Students>;
  /** fetch data from the table: "subscriptions" */
  subscriptions: Array<Subscriptions>;
  /** fetch aggregated fields from the table: "subscriptions" */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table: "text_messages" */
  text_messages: Array<Text_Messages>;
  /** fetch aggregated fields from the table: "text_messages" */
  text_messages_aggregate: Text_Messages_Aggregate;
  /** fetch data from the table: "text_messages" using primary key columns */
  text_messages_by_pk?: Maybe<Text_Messages>;
  todayUserSessions: Array<Session>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  /** fetch data from the table: "translations" using primary key columns */
  translations_by_pk?: Maybe<Translations>;
  /** An array relationship */
  user_badges: Array<User_Badges>;
  /** An aggregate relationship */
  user_badges_aggregate: User_Badges_Aggregate;
  /** fetch data from the table: "user_badges" using primary key columns */
  user_badges_by_pk?: Maybe<User_Badges>;
  /** An array relationship */
  user_categories: Array<User_Categories>;
  /** An aggregate relationship */
  user_categories_aggregate: User_Categories_Aggregate;
  /** fetch data from the table: "user_categories" using primary key columns */
  user_categories_by_pk?: Maybe<User_Categories>;
  /** An array relationship */
  user_certificates: Array<User_Certificates>;
  /** An aggregate relationship */
  user_certificates_aggregate: User_Certificates_Aggregate;
  /** fetch data from the table: "user_certificates" using primary key columns */
  user_certificates_by_pk?: Maybe<User_Certificates>;
  /** fetch data from the table: "user_credit_card_infos" */
  user_credit_card_infos: Array<User_Credit_Card_Infos>;
  /** fetch aggregated fields from the table: "user_credit_card_infos" */
  user_credit_card_infos_aggregate: User_Credit_Card_Infos_Aggregate;
  /** fetch data from the table: "user_credit_card_infos" using primary key columns */
  user_credit_card_infos_by_pk?: Maybe<User_Credit_Card_Infos>;
  /** fetch data from the table: "user_discussion_rooms" */
  user_discussion_rooms: Array<User_Discussion_Rooms>;
  /** fetch aggregated fields from the table: "user_discussion_rooms" */
  user_discussion_rooms_aggregate: User_Discussion_Rooms_Aggregate;
  /** fetch data from the table: "user_discussion_rooms" using primary key columns */
  user_discussion_rooms_by_pk?: Maybe<User_Discussion_Rooms>;
  /** An array relationship */
  user_exams: Array<User_Exams>;
  /** An aggregate relationship */
  user_exams_aggregate: User_Exams_Aggregate;
  /** fetch data from the table: "user_exams" using primary key columns */
  user_exams_by_pk?: Maybe<User_Exams>;
  /** An array relationship */
  user_hearing_councils: Array<User_Hearing_Councils>;
  /** An aggregate relationship */
  user_hearing_councils_aggregate: User_Hearing_Councils_Aggregate;
  /** fetch data from the table: "user_hearing_councils" using primary key columns */
  user_hearing_councils_by_pk?: Maybe<User_Hearing_Councils>;
  /** fetch data from the table: "user_meetings" */
  user_meetings: Array<User_Meetings>;
  /** fetch aggregated fields from the table: "user_meetings" */
  user_meetings_aggregate: User_Meetings_Aggregate;
  /** fetch data from the table: "user_meetings" using primary key columns */
  user_meetings_by_pk?: Maybe<User_Meetings>;
  /** fetch data from the table: "user_preferences" */
  user_preferences: Array<User_Preferences>;
  /** fetch aggregated fields from the table: "user_preferences" */
  user_preferences_aggregate: User_Preferences_Aggregate;
  /** fetch data from the table: "user_preferences" using primary key columns */
  user_preferences_by_pk?: Maybe<User_Preferences>;
  /** An array relationship */
  user_quizes: Array<User_Quizes>;
  /** An aggregate relationship */
  user_quizes_aggregate: User_Quizes_Aggregate;
  /** fetch data from the table: "user_quizes" using primary key columns */
  user_quizes_by_pk?: Maybe<User_Quizes>;
  /** An array relationship */
  user_records: Array<User_Records>;
  /** An aggregate relationship */
  user_records_aggregate: User_Records_Aggregate;
  /** fetch data from the table: "user_records" using primary key columns */
  user_records_by_pk?: Maybe<User_Records>;
  /** An array relationship */
  user_scientific_sessions: Array<User_Scientific_Sessions>;
  /** An aggregate relationship */
  user_scientific_sessions_aggregate: User_Scientific_Sessions_Aggregate;
  /** fetch data from the table: "user_scientific_sessions" using primary key columns */
  user_scientific_sessions_by_pk?: Maybe<User_Scientific_Sessions>;
  /** An array relationship */
  user_sessions: Array<User_Sessions>;
  /** An aggregate relationship */
  user_sessions_aggregate: User_Sessions_Aggregate;
  /** fetch data from the table: "user_sessions" using primary key columns */
  user_sessions_by_pk?: Maybe<User_Sessions>;
  /** fetch data from the table: "user_subscriptions" */
  user_subscriptions: Array<User_Subscriptions>;
  /** fetch aggregated fields from the table: "user_subscriptions" */
  user_subscriptions_aggregate: User_Subscriptions_Aggregate;
  /** fetch data from the table: "user_subscriptions" using primary key columns */
  user_subscriptions_by_pk?: Maybe<User_Subscriptions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootFinalQuestionsByMatnIdArgs = {
  matnId: Scalars['String']['input'];
};


export type Query_RootWeaknessMutoonSectionsArgs = {
  matnId: Scalars['String']['input'];
};


export type Query_Root_DonationHistoryToTransactionArgs = {
  distinct_on?: InputMaybe<Array<_DonationHistoryToTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_DonationHistoryToTransaction_Order_By>>;
  where?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
};


export type Query_Root_DonationHistoryToTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_DonationHistoryToTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_DonationHistoryToTransaction_Order_By>>;
  where?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
};


export type Query_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Query_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Query_RootActions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAdminsArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Query_RootAdmins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Query_RootAdmins_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Query_RootAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Query_RootAnswers_By_PkArgs = {
  answerId: Scalars['String']['input'];
};


export type Query_RootAuthorized_UsersArgs = {
  distinct_on?: InputMaybe<Array<Authorized_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authorized_Users_Order_By>>;
  where?: InputMaybe<Authorized_Users_Bool_Exp>;
};


export type Query_RootAuthorized_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authorized_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authorized_Users_Order_By>>;
  where?: InputMaybe<Authorized_Users_Bool_Exp>;
};


export type Query_RootAuthorized_Users_By_PkArgs = {
  authorizedUserId: Scalars['String']['input'];
};


export type Query_RootBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_By_PkArgs = {
  badgeId: Scalars['String']['input'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  categoryId: Scalars['String']['input'];
};


export type Query_RootCertificate_VariablesArgs = {
  distinct_on?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificate_Variables_Order_By>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


export type Query_RootCertificate_Variables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificate_Variables_Order_By>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


export type Query_RootCertificate_Variables_By_PkArgs = {
  certVarId: Scalars['String']['input'];
};


export type Query_RootCertificatesArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Query_RootCertificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Query_RootCertificates_By_PkArgs = {
  certificateId: Scalars['String']['input'];
};


export type Query_RootChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};


export type Query_RootChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};


export type Query_RootChat_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootChatsArgs = {
  distinct_on?: InputMaybe<Array<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Query_RootChats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Query_RootChats_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootContact_UsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Query_RootContact_Us_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Query_RootContact_Us_By_PkArgs = {
  contactId: Scalars['String']['input'];
};


export type Query_RootContent_PagesArgs = {
  distinct_on?: InputMaybe<Array<Content_Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Pages_Order_By>>;
  where?: InputMaybe<Content_Pages_Bool_Exp>;
};


export type Query_RootContent_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Pages_Order_By>>;
  where?: InputMaybe<Content_Pages_Bool_Exp>;
};


export type Query_RootContent_Pages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootContent_RecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Content_Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Recommendations_Order_By>>;
  where?: InputMaybe<Content_Recommendations_Bool_Exp>;
};


export type Query_RootContent_Recommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Recommendations_Order_By>>;
  where?: InputMaybe<Content_Recommendations_Bool_Exp>;
};


export type Query_RootContent_Recommendations_By_PkArgs = {
  recommendationId: Scalars['String']['input'];
};


export type Query_RootCountUsersArgs = {
  fetchUsersArgs?: InputMaybe<FetchUsersArgs>;
};


export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  countryId: Scalars['String']['input'];
};


export type Query_RootDiscussion_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discussion_Rooms_Order_By>>;
  where?: InputMaybe<Discussion_Rooms_Bool_Exp>;
};


export type Query_RootDiscussion_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discussion_Rooms_Order_By>>;
  where?: InputMaybe<Discussion_Rooms_Bool_Exp>;
};


export type Query_RootDiscussion_Rooms_By_PkArgs = {
  roomId: Scalars['String']['input'];
};


export type Query_RootDonation_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Donation_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donation_Histories_Order_By>>;
  where?: InputMaybe<Donation_Histories_Bool_Exp>;
};


export type Query_RootDonation_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Donation_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donation_Histories_Order_By>>;
  where?: InputMaybe<Donation_Histories_Bool_Exp>;
};


export type Query_RootDonation_Histories_By_PkArgs = {
  donationHistoryId: Scalars['String']['input'];
};


export type Query_RootDonationsArgs = {
  distinct_on?: InputMaybe<Array<Donations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donations_Order_By>>;
  where?: InputMaybe<Donations_Bool_Exp>;
};


export type Query_RootDonations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Donations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donations_Order_By>>;
  where?: InputMaybe<Donations_Bool_Exp>;
};


export type Query_RootDonations_By_PkArgs = {
  donationId: Scalars['String']['input'];
};


export type Query_RootEvent_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Event_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Reminders_Order_By>>;
  where?: InputMaybe<Event_Reminders_Bool_Exp>;
};


export type Query_RootEvent_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Reminders_Order_By>>;
  where?: InputMaybe<Event_Reminders_Bool_Exp>;
};


export type Query_RootEvent_Reminders_By_PkArgs = {
  reminderId: Scalars['String']['input'];
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  eventId: Scalars['String']['input'];
};


export type Query_RootExamsArgs = {
  distinct_on?: InputMaybe<Array<Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exams_Order_By>>;
  where?: InputMaybe<Exams_Bool_Exp>;
};


export type Query_RootExams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exams_Order_By>>;
  where?: InputMaybe<Exams_Bool_Exp>;
};


export type Query_RootExams_By_PkArgs = {
  examId: Scalars['String']['input'];
};


export type Query_RootFaqsArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Query_RootFaqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Query_RootFaqs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootFindOneHearingCouncilArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootFindOneSessionArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootGetAllBadgesArgs = {
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  isEarned?: InputMaybe<Scalars['Boolean']['input']>;
  isGeneralType?: InputMaybe<Scalars['Boolean']['input']>;
  isMine?: InputMaybe<Scalars['Boolean']['input']>;
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
};


export type Query_RootGetAllUnMarkedDoneSectionsOfMutoonByPlanIdArgs = {
  planId: Scalars['String']['input'];
};


export type Query_RootGetBadgeByIdArgs = {
  badgeId: Scalars['String']['input'];
};


export type Query_RootGetNotificationsArgs = {
  pagingArgs: PagingArgs;
};


export type Query_RootGetPlansByDateArgs = {
  day: Scalars['Float']['input'];
  month: Scalars['Float']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Float']['input'];
};


export type Query_RootGetUserCertificatesByMutoonIdArgs = {
  mutoonId: Scalars['String']['input'];
};


export type Query_RootGetUserCertificatesWithMutoonCategoriesArgs = {
  parentId?: InputMaybe<Scalars['String']['input']>;
};


export type Query_RootGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Query_RootGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Query_RootGroup_Members_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootHearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


export type Query_RootHearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


export type Query_RootHearing_Councils_By_PkArgs = {
  hearingCouncilId: Scalars['String']['input'];
};


export type Query_RootKnowledge_BasesArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Bases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Knowledge_Bases_Order_By>>;
  where?: InputMaybe<Knowledge_Bases_Bool_Exp>;
};


export type Query_RootKnowledge_Bases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Bases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Knowledge_Bases_Order_By>>;
  where?: InputMaybe<Knowledge_Bases_Bool_Exp>;
};


export type Query_RootKnowledge_Bases_By_PkArgs = {
  knowledgeBaseId: Scalars['String']['input'];
};


export type Query_RootLectures_And_WebinarsArgs = {
  distinct_on?: InputMaybe<Array<Lectures_And_Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lectures_And_Webinars_Order_By>>;
  where?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
};


export type Query_RootLectures_And_Webinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lectures_And_Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lectures_And_Webinars_Order_By>>;
  where?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
};


export type Query_RootLectures_And_Webinars_By_PkArgs = {
  lectureId: Scalars['String']['input'];
};


export type Query_RootLevelsArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_By_PkArgs = {
  levelId: Scalars['String']['input'];
};


export type Query_RootMedia_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Media_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Media_Messages_Order_By>>;
  where?: InputMaybe<Media_Messages_Bool_Exp>;
};


export type Query_RootMedia_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Media_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Media_Messages_Order_By>>;
  where?: InputMaybe<Media_Messages_Bool_Exp>;
};


export type Query_RootMedia_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootMeetingsArgs = {
  distinct_on?: InputMaybe<Array<Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Meetings_Order_By>>;
  where?: InputMaybe<Meetings_Bool_Exp>;
};


export type Query_RootMeetings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Meetings_Order_By>>;
  where?: InputMaybe<Meetings_Bool_Exp>;
};


export type Query_RootMeetings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootMessage_RecipientsArgs = {
  distinct_on?: InputMaybe<Array<Message_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Message_Recipients_Order_By>>;
  where?: InputMaybe<Message_Recipients_Bool_Exp>;
};


export type Query_RootMessage_Recipients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Message_Recipients_Order_By>>;
  where?: InputMaybe<Message_Recipients_Bool_Exp>;
};


export type Query_RootMessage_Recipients_By_PkArgs = {
  groupMemberId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};


export type Query_RootMutoon_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


export type Query_RootMutoon_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


export type Query_RootMutoon_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootMutoon_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


export type Query_RootMutoon_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


export type Query_RootMutoon_Sections_By_PkArgs = {
  mutoonSectionId: Scalars['String']['input'];
};


export type Query_RootMutoonsArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


export type Query_RootMutoons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


export type Query_RootMutoons_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootNotesArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_By_PkArgs = {
  noteId: Scalars['String']['input'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPayment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Query_RootPayment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Query_RootPayment_Methods_By_PkArgs = {
  paymentMethodId: Scalars['String']['input'];
};


export type Query_RootPlan_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


export type Query_RootPlan_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


export type Query_RootPlan_Sections_By_PkArgs = {
  planSectionId: Scalars['String']['input'];
};


export type Query_RootPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


export type Query_RootPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


export type Query_RootPlans_By_PkArgs = {
  planId: Scalars['String']['input'];
};


export type Query_RootPlatform_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Platform_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Platform_Settings_Order_By>>;
  where?: InputMaybe<Platform_Settings_Bool_Exp>;
};


export type Query_RootPlatform_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Platform_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Platform_Settings_Order_By>>;
  where?: InputMaybe<Platform_Settings_Bool_Exp>;
};


export type Query_RootPlatform_Settings_By_PkArgs = {
  settingId: Scalars['String']['input'];
};


export type Query_RootPrerequisitesArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


export type Query_RootPrerequisites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


export type Query_RootPrerequisites_By_PkArgs = {
  prerequisiteId: Scalars['String']['input'];
};


export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_By_PkArgs = {
  questionId: Scalars['String']['input'];
};


export type Query_RootQuizQuestionsByMatnSectionsArgs = {
  limit: Scalars['Int']['input'];
  matnSections: Array<Scalars['String']['input']>;
};


export type Query_RootRatings_And_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Ratings_And_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ratings_And_Reviews_Order_By>>;
  where?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
};


export type Query_RootRatings_And_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ratings_And_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ratings_And_Reviews_Order_By>>;
  where?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
};


export type Query_RootRatings_And_Reviews_By_PkArgs = {
  reviewId: Scalars['String']['input'];
};


export type Query_RootReference_BooksArgs = {
  distinct_on?: InputMaybe<Array<Reference_Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reference_Books_Order_By>>;
  where?: InputMaybe<Reference_Books_Bool_Exp>;
};


export type Query_RootReference_Books_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reference_Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reference_Books_Order_By>>;
  where?: InputMaybe<Reference_Books_Bool_Exp>;
};


export type Query_RootReference_Books_By_PkArgs = {
  bookId: Scalars['String']['input'];
};


export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  reportId: Scalars['String']['input'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Query_RootRoles_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Query_RootRoles_Actions_By_PkArgs = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootScheduled_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scheduled_Notifications_Order_By>>;
  where?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
};


export type Query_RootScheduled_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scheduled_Notifications_Order_By>>;
  where?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
};


export type Query_RootScheduled_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSchool_UsersArgs = {
  distinct_on?: InputMaybe<Array<School_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<School_Users_Order_By>>;
  where?: InputMaybe<School_Users_Bool_Exp>;
};


export type Query_RootSchool_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<School_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<School_Users_Order_By>>;
  where?: InputMaybe<School_Users_Bool_Exp>;
};


export type Query_RootSchool_Users_By_PkArgs = {
  schoolUserId: Scalars['String']['input'];
};


export type Query_RootScientific_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scientific_Sessions_Order_By>>;
  where?: InputMaybe<Scientific_Sessions_Bool_Exp>;
};


export type Query_RootScientific_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scientific_Sessions_Order_By>>;
  where?: InputMaybe<Scientific_Sessions_Bool_Exp>;
};


export type Query_RootScientific_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSelf_RecitationsArgs = {
  distinct_on?: InputMaybe<Array<Self_Recitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Self_Recitations_Order_By>>;
  where?: InputMaybe<Self_Recitations_Bool_Exp>;
};


export type Query_RootSelf_Recitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Self_Recitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Self_Recitations_Order_By>>;
  where?: InputMaybe<Self_Recitations_Bool_Exp>;
};


export type Query_RootSelf_Recitations_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSoundsArgs = {
  distinct_on?: InputMaybe<Array<Sounds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sounds_Order_By>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


export type Query_RootSounds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sounds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sounds_Order_By>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


export type Query_RootSounds_By_PkArgs = {
  soundId: Scalars['String']['input'];
};


export type Query_RootStatisticsArgs = {
  distinct_on?: InputMaybe<Array<Statistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Statistics_Order_By>>;
  where?: InputMaybe<Statistics_Bool_Exp>;
};


export type Query_RootStatistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Statistics_Order_By>>;
  where?: InputMaybe<Statistics_Bool_Exp>;
};


export type Query_RootStatistics_By_PkArgs = {
  statisticId: Scalars['String']['input'];
};


export type Query_RootStudentsArgs = {
  distinct_on?: InputMaybe<Array<Students_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Students_Order_By>>;
  where?: InputMaybe<Students_Bool_Exp>;
};


export type Query_RootStudents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Students_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Students_Order_By>>;
  where?: InputMaybe<Students_Bool_Exp>;
};


export type Query_RootStudents_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootText_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Query_RootText_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Query_RootText_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_By_PkArgs = {
  transactionId: Scalars['String']['input'];
};


export type Query_RootTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Query_RootTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Query_RootTranslations_By_PkArgs = {
  translationId: Scalars['String']['input'];
};


export type Query_RootUser_BadgesArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Query_RootUser_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Query_RootUser_Badges_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


export type Query_RootUser_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


export type Query_RootUser_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_CertificatesArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


export type Query_RootUser_Certificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


export type Query_RootUser_Certificates_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_Credit_Card_InfosArgs = {
  distinct_on?: InputMaybe<Array<User_Credit_Card_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Credit_Card_Infos_Order_By>>;
  where?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
};


export type Query_RootUser_Credit_Card_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Credit_Card_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Credit_Card_Infos_Order_By>>;
  where?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
};


export type Query_RootUser_Credit_Card_Infos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_Discussion_RoomsArgs = {
  distinct_on?: InputMaybe<Array<User_Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Discussion_Rooms_Order_By>>;
  where?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
};


export type Query_RootUser_Discussion_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Discussion_Rooms_Order_By>>;
  where?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
};


export type Query_RootUser_Discussion_Rooms_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_ExamsArgs = {
  distinct_on?: InputMaybe<Array<User_Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Exams_Order_By>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};


export type Query_RootUser_Exams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Exams_Order_By>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};


export type Query_RootUser_Exams_By_PkArgs = {
  userExamId: Scalars['String']['input'];
};


export type Query_RootUser_Hearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


export type Query_RootUser_Hearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


export type Query_RootUser_Hearing_Councils_By_PkArgs = {
  userHearingCouncilId: Scalars['String']['input'];
};


export type Query_RootUser_MeetingsArgs = {
  distinct_on?: InputMaybe<Array<User_Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Meetings_Order_By>>;
  where?: InputMaybe<User_Meetings_Bool_Exp>;
};


export type Query_RootUser_Meetings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Meetings_Order_By>>;
  where?: InputMaybe<User_Meetings_Bool_Exp>;
};


export type Query_RootUser_Meetings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Query_RootUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Query_RootUser_Preferences_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_QuizesArgs = {
  distinct_on?: InputMaybe<Array<User_Quizes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Quizes_Order_By>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};


export type Query_RootUser_Quizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Quizes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Quizes_Order_By>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};


export type Query_RootUser_Quizes_By_PkArgs = {
  userQuizId: Scalars['String']['input'];
};


export type Query_RootUser_RecordsArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


export type Query_RootUser_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


export type Query_RootUser_Records_By_PkArgs = {
  recordingId: Scalars['String']['input'];
};


export type Query_RootUser_Scientific_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


export type Query_RootUser_Scientific_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


export type Query_RootUser_Scientific_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Query_RootUser_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Query_RootUser_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<User_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Subscriptions_Order_By>>;
  where?: InputMaybe<User_Subscriptions_Bool_Exp>;
};


export type Query_RootUser_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Subscriptions_Order_By>>;
  where?: InputMaybe<User_Subscriptions_Bool_Exp>;
};


export type Query_RootUser_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  choicesOptions?: Maybe<Array<Scalars['String']['output']>>;
  choicesType?: Maybe<Scalars['String']['output']>;
  correctAnswerId?: Maybe<Scalars['String']['output']>;
  correctAnswerIndex?: Maybe<Scalars['Int']['output']>;
  correctAnswerText?: Maybe<Scalars['jsonb']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  exam?: Maybe<Exams>;
  examId?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['jsonb']['output']>;
  hint?: Maybe<Scalars['jsonb']['output']>;
  isQuiz: Scalars['Boolean']['output'];
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mutoon_section?: Maybe<Mutoon_Sections>;
  points: Scalars['Int']['output'];
  questionId: Scalars['String']['output'];
  questionText: Scalars['jsonb']['output'];
  type: Scalars['QuestionType']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_quizes: Array<User_Quizes>;
  /** An aggregate relationship */
  user_quizes_aggregate: User_Quizes_Aggregate;
};


/** columns and relationships of "questions" */
export type QuestionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsCorrectAnswerTextArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "questions" */
export type QuestionsExplanationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "questions" */
export type QuestionsHintArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestionTextArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "questions" */
export type QuestionsUser_QuizesArgs = {
  distinct_on?: InputMaybe<Array<User_Quizes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Quizes_Order_By>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsUser_Quizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Quizes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Quizes_Order_By>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

export type Questions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Questions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Questions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Questions_Aggregate_Bool_Exp_Count>;
};

export type Questions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<Questions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
  stddev?: Maybe<Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questions_Sum_Fields>;
  var_pop?: Maybe<Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questions_Var_Samp_Fields>;
  variance?: Maybe<Questions_Variance_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Max_Order_By>;
  min?: InputMaybe<Questions_Min_Order_By>;
  stddev?: InputMaybe<Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Questions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questions_Append_Input = {
  correctAnswerText?: InputMaybe<Scalars['jsonb']['input']>;
  explanation?: InputMaybe<Scalars['jsonb']['input']>;
  hint?: InputMaybe<Scalars['jsonb']['input']>;
  questionText?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Questions_Avg_Fields = {
  __typename?: 'questions_avg_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "questions" */
export type Questions_Avg_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  answers?: InputMaybe<Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Bool_Exp>;
  choicesOptions?: InputMaybe<String_Array_Comparison_Exp>;
  choicesType?: InputMaybe<String_Comparison_Exp>;
  correctAnswerId?: InputMaybe<String_Comparison_Exp>;
  correctAnswerIndex?: InputMaybe<Int_Comparison_Exp>;
  correctAnswerText?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  exam?: InputMaybe<Exams_Bool_Exp>;
  examId?: InputMaybe<String_Comparison_Exp>;
  explanation?: InputMaybe<Jsonb_Comparison_Exp>;
  hint?: InputMaybe<Jsonb_Comparison_Exp>;
  isQuiz?: InputMaybe<Boolean_Comparison_Exp>;
  mutoonSectionId?: InputMaybe<String_Comparison_Exp>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  points?: InputMaybe<Int_Comparison_Exp>;
  questionId?: InputMaybe<String_Comparison_Exp>;
  questionText?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<QuestionType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_quizes?: InputMaybe<User_Quizes_Bool_Exp>;
  user_quizes_aggregate?: InputMaybe<User_Quizes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint on columns "questionId" */
  QuestionsPkey = 'questions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questions_Delete_At_Path_Input = {
  correctAnswerText?: InputMaybe<Array<Scalars['String']['input']>>;
  explanation?: InputMaybe<Array<Scalars['String']['input']>>;
  hint?: InputMaybe<Array<Scalars['String']['input']>>;
  questionText?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questions_Delete_Elem_Input = {
  correctAnswerText?: InputMaybe<Scalars['Int']['input']>;
  explanation?: InputMaybe<Scalars['Int']['input']>;
  hint?: InputMaybe<Scalars['Int']['input']>;
  questionText?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questions_Delete_Key_Input = {
  correctAnswerText?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  hint?: InputMaybe<Scalars['String']['input']>;
  questionText?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "questions" */
export type Questions_Inc_Input = {
  correctAnswerIndex?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  answers?: InputMaybe<Answers_Arr_Rel_Insert_Input>;
  choicesOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  choicesType?: InputMaybe<Scalars['String']['input']>;
  correctAnswerId?: InputMaybe<Scalars['String']['input']>;
  correctAnswerIndex?: InputMaybe<Scalars['Int']['input']>;
  correctAnswerText?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  exam?: InputMaybe<Exams_Obj_Rel_Insert_Input>;
  examId?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['jsonb']['input']>;
  hint?: InputMaybe<Scalars['jsonb']['input']>;
  isQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  points?: InputMaybe<Scalars['Int']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  questionText?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['QuestionType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_quizes?: InputMaybe<User_Quizes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  choicesOptions?: Maybe<Array<Scalars['String']['output']>>;
  choicesType?: Maybe<Scalars['String']['output']>;
  correctAnswerId?: Maybe<Scalars['String']['output']>;
  correctAnswerIndex?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['QuestionType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  choicesOptions?: InputMaybe<Order_By>;
  choicesType?: InputMaybe<Order_By>;
  correctAnswerId?: InputMaybe<Order_By>;
  correctAnswerIndex?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  examId?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  choicesOptions?: Maybe<Array<Scalars['String']['output']>>;
  choicesType?: Maybe<Scalars['String']['output']>;
  correctAnswerId?: Maybe<Scalars['String']['output']>;
  correctAnswerIndex?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['QuestionType']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  choicesOptions?: InputMaybe<Order_By>;
  choicesType?: InputMaybe<Order_By>;
  correctAnswerId?: InputMaybe<Order_By>;
  correctAnswerIndex?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  examId?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  answers_aggregate?: InputMaybe<Answers_Aggregate_Order_By>;
  choicesOptions?: InputMaybe<Order_By>;
  choicesType?: InputMaybe<Order_By>;
  correctAnswerId?: InputMaybe<Order_By>;
  correctAnswerIndex?: InputMaybe<Order_By>;
  correctAnswerText?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  exam?: InputMaybe<Exams_Order_By>;
  examId?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  hint?: InputMaybe<Order_By>;
  isQuiz?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Order_By>;
  points?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  questionText?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_quizes_aggregate?: InputMaybe<User_Quizes_Aggregate_Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  questionId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questions_Prepend_Input = {
  correctAnswerText?: InputMaybe<Scalars['jsonb']['input']>;
  explanation?: InputMaybe<Scalars['jsonb']['input']>;
  hint?: InputMaybe<Scalars['jsonb']['input']>;
  questionText?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  ChoicesOptions = 'choicesOptions',
  /** column name */
  ChoicesType = 'choicesType',
  /** column name */
  CorrectAnswerId = 'correctAnswerId',
  /** column name */
  CorrectAnswerIndex = 'correctAnswerIndex',
  /** column name */
  CorrectAnswerText = 'correctAnswerText',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExamId = 'examId',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Hint = 'hint',
  /** column name */
  IsQuiz = 'isQuiz',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  Points = 'points',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  QuestionText = 'questionText',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "questions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questions" */
export enum Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsQuiz = 'isQuiz'
}

/** select "questions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questions" */
export enum Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsQuiz = 'isQuiz'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  choicesOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  choicesType?: InputMaybe<Scalars['String']['input']>;
  correctAnswerId?: InputMaybe<Scalars['String']['input']>;
  correctAnswerIndex?: InputMaybe<Scalars['Int']['input']>;
  correctAnswerText?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['jsonb']['input']>;
  hint?: InputMaybe<Scalars['jsonb']['input']>;
  isQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  questionText?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['QuestionType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Questions_Stddev_Fields = {
  __typename?: 'questions_stddev_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "questions" */
export type Questions_Stddev_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questions_Stddev_Pop_Fields = {
  __typename?: 'questions_stddev_pop_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "questions" */
export type Questions_Stddev_Pop_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questions_Stddev_Samp_Fields = {
  __typename?: 'questions_stddev_samp_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "questions" */
export type Questions_Stddev_Samp_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "questions" */
export type Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Stream_Cursor_Value_Input = {
  choicesOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  choicesType?: InputMaybe<Scalars['String']['input']>;
  correctAnswerId?: InputMaybe<Scalars['String']['input']>;
  correctAnswerIndex?: InputMaybe<Scalars['Int']['input']>;
  correctAnswerText?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['jsonb']['input']>;
  hint?: InputMaybe<Scalars['jsonb']['input']>;
  isQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  questionText?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['QuestionType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Questions_Sum_Fields = {
  __typename?: 'questions_sum_fields';
  correctAnswerIndex?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "questions" */
export type Questions_Sum_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  ChoicesOptions = 'choicesOptions',
  /** column name */
  ChoicesType = 'choicesType',
  /** column name */
  CorrectAnswerId = 'correctAnswerId',
  /** column name */
  CorrectAnswerIndex = 'correctAnswerIndex',
  /** column name */
  CorrectAnswerText = 'correctAnswerText',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExamId = 'examId',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Hint = 'hint',
  /** column name */
  IsQuiz = 'isQuiz',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  Points = 'points',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  QuestionText = 'questionText',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questions_Var_Pop_Fields = {
  __typename?: 'questions_var_pop_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "questions" */
export type Questions_Var_Pop_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questions_Var_Samp_Fields = {
  __typename?: 'questions_var_samp_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "questions" */
export type Questions_Var_Samp_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questions_Variance_Fields = {
  __typename?: 'questions_variance_fields';
  correctAnswerIndex?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "questions" */
export type Questions_Variance_Order_By = {
  correctAnswerIndex?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
};

/** columns and relationships of "ratings_and_reviews" */
export type Ratings_And_Reviews = {
  __typename?: 'ratings_and_reviews';
  id: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  reviewId: Scalars['String']['output'];
  reviewText: Scalars['String']['output'];
  status: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "ratings_and_reviews" */
export type Ratings_And_Reviews_Aggregate = {
  __typename?: 'ratings_and_reviews_aggregate';
  aggregate?: Maybe<Ratings_And_Reviews_Aggregate_Fields>;
  nodes: Array<Ratings_And_Reviews>;
};

/** aggregate fields of "ratings_and_reviews" */
export type Ratings_And_Reviews_Aggregate_Fields = {
  __typename?: 'ratings_and_reviews_aggregate_fields';
  avg?: Maybe<Ratings_And_Reviews_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ratings_And_Reviews_Max_Fields>;
  min?: Maybe<Ratings_And_Reviews_Min_Fields>;
  stddev?: Maybe<Ratings_And_Reviews_Stddev_Fields>;
  stddev_pop?: Maybe<Ratings_And_Reviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ratings_And_Reviews_Stddev_Samp_Fields>;
  sum?: Maybe<Ratings_And_Reviews_Sum_Fields>;
  var_pop?: Maybe<Ratings_And_Reviews_Var_Pop_Fields>;
  var_samp?: Maybe<Ratings_And_Reviews_Var_Samp_Fields>;
  variance?: Maybe<Ratings_And_Reviews_Variance_Fields>;
};


/** aggregate fields of "ratings_and_reviews" */
export type Ratings_And_Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ratings_And_Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Ratings_And_Reviews_Avg_Fields = {
  __typename?: 'ratings_and_reviews_avg_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ratings_and_reviews". All fields are combined with a logical 'AND'. */
export type Ratings_And_Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Ratings_And_Reviews_Bool_Exp>>;
  _not?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Ratings_And_Reviews_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  reviewId?: InputMaybe<String_Comparison_Exp>;
  reviewText?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ratings_and_reviews" */
export enum Ratings_And_Reviews_Constraint {
  /** unique or primary key constraint on columns "reviewId" */
  RatingsAndReviewsPkey = 'ratings_and_reviews_pkey'
}

/** input type for incrementing numeric columns in table "ratings_and_reviews" */
export type Ratings_And_Reviews_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "ratings_and_reviews" */
export type Ratings_And_Reviews_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reviewId?: InputMaybe<Scalars['String']['input']>;
  reviewText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ratings_And_Reviews_Max_Fields = {
  __typename?: 'ratings_and_reviews_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reviewId?: Maybe<Scalars['String']['output']>;
  reviewText?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ratings_And_Reviews_Min_Fields = {
  __typename?: 'ratings_and_reviews_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reviewId?: Maybe<Scalars['String']['output']>;
  reviewText?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ratings_and_reviews" */
export type Ratings_And_Reviews_Mutation_Response = {
  __typename?: 'ratings_and_reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ratings_And_Reviews>;
};

/** on_conflict condition type for table "ratings_and_reviews" */
export type Ratings_And_Reviews_On_Conflict = {
  constraint: Ratings_And_Reviews_Constraint;
  update_columns?: Array<Ratings_And_Reviews_Update_Column>;
  where?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "ratings_and_reviews". */
export type Ratings_And_Reviews_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reviewId?: InputMaybe<Order_By>;
  reviewText?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ratings_and_reviews */
export type Ratings_And_Reviews_Pk_Columns_Input = {
  reviewId: Scalars['String']['input'];
};

/** select columns of table "ratings_and_reviews" */
export enum Ratings_And_Reviews_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReviewId = 'reviewId',
  /** column name */
  ReviewText = 'reviewText',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "ratings_and_reviews" */
export type Ratings_And_Reviews_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reviewId?: InputMaybe<Scalars['String']['input']>;
  reviewText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ratings_And_Reviews_Stddev_Fields = {
  __typename?: 'ratings_and_reviews_stddev_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ratings_And_Reviews_Stddev_Pop_Fields = {
  __typename?: 'ratings_and_reviews_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ratings_And_Reviews_Stddev_Samp_Fields = {
  __typename?: 'ratings_and_reviews_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ratings_and_reviews" */
export type Ratings_And_Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ratings_And_Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ratings_And_Reviews_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reviewId?: InputMaybe<Scalars['String']['input']>;
  reviewText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ratings_And_Reviews_Sum_Fields = {
  __typename?: 'ratings_and_reviews_sum_fields';
  rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "ratings_and_reviews" */
export enum Ratings_And_Reviews_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReviewId = 'reviewId',
  /** column name */
  ReviewText = 'reviewText',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId'
}

export type Ratings_And_Reviews_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ratings_And_Reviews_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ratings_And_Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ratings_And_Reviews_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ratings_And_Reviews_Var_Pop_Fields = {
  __typename?: 'ratings_and_reviews_var_pop_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ratings_And_Reviews_Var_Samp_Fields = {
  __typename?: 'ratings_and_reviews_var_samp_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ratings_And_Reviews_Variance_Fields = {
  __typename?: 'ratings_and_reviews_variance_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reference_books" */
export type Reference_Books = {
  __typename?: 'reference_books';
  bookAuthor?: Maybe<Scalars['jsonb']['output']>;
  bookId: Scalars['String']['output'];
  bookLanguage?: Maybe<Scalars['jsonb']['output']>;
  bookName?: Maybe<Scalars['jsonb']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  dateOfBorn?: Maybe<Scalars['timestamp']['output']>;
  dateOfDeath?: Maybe<Scalars['timestamp']['output']>;
  fromSchool?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  hearing_councils: Array<Hearing_Councils>;
  /** An aggregate relationship */
  hearing_councils_aggregate: Hearing_Councils_Aggregate;
  /** An array relationship */
  mutoons: Array<Mutoons>;
  /** An aggregate relationship */
  mutoons_aggregate: Mutoons_Aggregate;
  nazemName?: Maybe<Scalars['jsonb']['output']>;
  numberOfLines?: Maybe<Scalars['Int']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "reference_books" */
export type Reference_BooksBookAuthorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksBookLanguageArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksBookNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksFromSchoolArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksHearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksHearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksMutoonsArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksMutoons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksNazemNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reference_books" */
export type Reference_BooksTitleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "reference_books" */
export type Reference_Books_Aggregate = {
  __typename?: 'reference_books_aggregate';
  aggregate?: Maybe<Reference_Books_Aggregate_Fields>;
  nodes: Array<Reference_Books>;
};

/** aggregate fields of "reference_books" */
export type Reference_Books_Aggregate_Fields = {
  __typename?: 'reference_books_aggregate_fields';
  avg?: Maybe<Reference_Books_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reference_Books_Max_Fields>;
  min?: Maybe<Reference_Books_Min_Fields>;
  stddev?: Maybe<Reference_Books_Stddev_Fields>;
  stddev_pop?: Maybe<Reference_Books_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reference_Books_Stddev_Samp_Fields>;
  sum?: Maybe<Reference_Books_Sum_Fields>;
  var_pop?: Maybe<Reference_Books_Var_Pop_Fields>;
  var_samp?: Maybe<Reference_Books_Var_Samp_Fields>;
  variance?: Maybe<Reference_Books_Variance_Fields>;
};


/** aggregate fields of "reference_books" */
export type Reference_Books_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reference_Books_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reference_Books_Append_Input = {
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Reference_Books_Avg_Fields = {
  __typename?: 'reference_books_avg_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reference_books". All fields are combined with a logical 'AND'. */
export type Reference_Books_Bool_Exp = {
  _and?: InputMaybe<Array<Reference_Books_Bool_Exp>>;
  _not?: InputMaybe<Reference_Books_Bool_Exp>;
  _or?: InputMaybe<Array<Reference_Books_Bool_Exp>>;
  bookAuthor?: InputMaybe<Jsonb_Comparison_Exp>;
  bookId?: InputMaybe<String_Comparison_Exp>;
  bookLanguage?: InputMaybe<Jsonb_Comparison_Exp>;
  bookName?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dateOfBorn?: InputMaybe<Timestamp_Comparison_Exp>;
  dateOfDeath?: InputMaybe<Timestamp_Comparison_Exp>;
  fromSchool?: InputMaybe<Jsonb_Comparison_Exp>;
  hearing_councils?: InputMaybe<Hearing_Councils_Bool_Exp>;
  hearing_councils_aggregate?: InputMaybe<Hearing_Councils_Aggregate_Bool_Exp>;
  mutoons?: InputMaybe<Mutoons_Bool_Exp>;
  mutoons_aggregate?: InputMaybe<Mutoons_Aggregate_Bool_Exp>;
  nazemName?: InputMaybe<Jsonb_Comparison_Exp>;
  numberOfLines?: InputMaybe<Int_Comparison_Exp>;
  pdfUrl?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reference_books" */
export enum Reference_Books_Constraint {
  /** unique or primary key constraint on columns "bookId" */
  ReferenceBooksPkey = 'reference_books_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reference_Books_Delete_At_Path_Input = {
  bookAuthor?: InputMaybe<Array<Scalars['String']['input']>>;
  bookLanguage?: InputMaybe<Array<Scalars['String']['input']>>;
  bookName?: InputMaybe<Array<Scalars['String']['input']>>;
  fromSchool?: InputMaybe<Array<Scalars['String']['input']>>;
  nazemName?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reference_Books_Delete_Elem_Input = {
  bookAuthor?: InputMaybe<Scalars['Int']['input']>;
  bookLanguage?: InputMaybe<Scalars['Int']['input']>;
  bookName?: InputMaybe<Scalars['Int']['input']>;
  fromSchool?: InputMaybe<Scalars['Int']['input']>;
  nazemName?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reference_Books_Delete_Key_Input = {
  bookAuthor?: InputMaybe<Scalars['String']['input']>;
  bookLanguage?: InputMaybe<Scalars['String']['input']>;
  bookName?: InputMaybe<Scalars['String']['input']>;
  fromSchool?: InputMaybe<Scalars['String']['input']>;
  nazemName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "reference_books" */
export type Reference_Books_Inc_Input = {
  numberOfLines?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reference_books" */
export type Reference_Books_Insert_Input = {
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookId?: InputMaybe<Scalars['String']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateOfBorn?: InputMaybe<Scalars['timestamp']['input']>;
  dateOfDeath?: InputMaybe<Scalars['timestamp']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  hearing_councils?: InputMaybe<Hearing_Councils_Arr_Rel_Insert_Input>;
  mutoons?: InputMaybe<Mutoons_Arr_Rel_Insert_Input>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfLines?: InputMaybe<Scalars['Int']['input']>;
  pdfUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Reference_Books_Max_Fields = {
  __typename?: 'reference_books_max_fields';
  bookId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dateOfBorn?: Maybe<Scalars['timestamp']['output']>;
  dateOfDeath?: Maybe<Scalars['timestamp']['output']>;
  numberOfLines?: Maybe<Scalars['Int']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Reference_Books_Min_Fields = {
  __typename?: 'reference_books_min_fields';
  bookId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dateOfBorn?: Maybe<Scalars['timestamp']['output']>;
  dateOfDeath?: Maybe<Scalars['timestamp']['output']>;
  numberOfLines?: Maybe<Scalars['Int']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "reference_books" */
export type Reference_Books_Mutation_Response = {
  __typename?: 'reference_books_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reference_Books>;
};

/** input type for inserting object relation for remote table "reference_books" */
export type Reference_Books_Obj_Rel_Insert_Input = {
  data: Reference_Books_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reference_Books_On_Conflict>;
};

/** on_conflict condition type for table "reference_books" */
export type Reference_Books_On_Conflict = {
  constraint: Reference_Books_Constraint;
  update_columns?: Array<Reference_Books_Update_Column>;
  where?: InputMaybe<Reference_Books_Bool_Exp>;
};

/** Ordering options when selecting data from "reference_books". */
export type Reference_Books_Order_By = {
  bookAuthor?: InputMaybe<Order_By>;
  bookId?: InputMaybe<Order_By>;
  bookLanguage?: InputMaybe<Order_By>;
  bookName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateOfBorn?: InputMaybe<Order_By>;
  dateOfDeath?: InputMaybe<Order_By>;
  fromSchool?: InputMaybe<Order_By>;
  hearing_councils_aggregate?: InputMaybe<Hearing_Councils_Aggregate_Order_By>;
  mutoons_aggregate?: InputMaybe<Mutoons_Aggregate_Order_By>;
  nazemName?: InputMaybe<Order_By>;
  numberOfLines?: InputMaybe<Order_By>;
  pdfUrl?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reference_books */
export type Reference_Books_Pk_Columns_Input = {
  bookId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reference_Books_Prepend_Input = {
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "reference_books" */
export enum Reference_Books_Select_Column {
  /** column name */
  BookAuthor = 'bookAuthor',
  /** column name */
  BookId = 'bookId',
  /** column name */
  BookLanguage = 'bookLanguage',
  /** column name */
  BookName = 'bookName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBorn = 'dateOfBorn',
  /** column name */
  DateOfDeath = 'dateOfDeath',
  /** column name */
  FromSchool = 'fromSchool',
  /** column name */
  NazemName = 'nazemName',
  /** column name */
  NumberOfLines = 'numberOfLines',
  /** column name */
  PdfUrl = 'pdfUrl',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "reference_books" */
export type Reference_Books_Set_Input = {
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookId?: InputMaybe<Scalars['String']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateOfBorn?: InputMaybe<Scalars['timestamp']['input']>;
  dateOfDeath?: InputMaybe<Scalars['timestamp']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfLines?: InputMaybe<Scalars['Int']['input']>;
  pdfUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Reference_Books_Stddev_Fields = {
  __typename?: 'reference_books_stddev_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Reference_Books_Stddev_Pop_Fields = {
  __typename?: 'reference_books_stddev_pop_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Reference_Books_Stddev_Samp_Fields = {
  __typename?: 'reference_books_stddev_samp_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reference_books" */
export type Reference_Books_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reference_Books_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reference_Books_Stream_Cursor_Value_Input = {
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookId?: InputMaybe<Scalars['String']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateOfBorn?: InputMaybe<Scalars['timestamp']['input']>;
  dateOfDeath?: InputMaybe<Scalars['timestamp']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfLines?: InputMaybe<Scalars['Int']['input']>;
  pdfUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Reference_Books_Sum_Fields = {
  __typename?: 'reference_books_sum_fields';
  numberOfLines?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "reference_books" */
export enum Reference_Books_Update_Column {
  /** column name */
  BookAuthor = 'bookAuthor',
  /** column name */
  BookId = 'bookId',
  /** column name */
  BookLanguage = 'bookLanguage',
  /** column name */
  BookName = 'bookName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateOfBorn = 'dateOfBorn',
  /** column name */
  DateOfDeath = 'dateOfDeath',
  /** column name */
  FromSchool = 'fromSchool',
  /** column name */
  NazemName = 'nazemName',
  /** column name */
  NumberOfLines = 'numberOfLines',
  /** column name */
  PdfUrl = 'pdfUrl',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Reference_Books_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reference_Books_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reference_Books_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reference_Books_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reference_Books_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reference_Books_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reference_Books_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reference_Books_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reference_Books_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reference_Books_Var_Pop_Fields = {
  __typename?: 'reference_books_var_pop_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Reference_Books_Var_Samp_Fields = {
  __typename?: 'reference_books_var_samp_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Reference_Books_Variance_Fields = {
  __typename?: 'reference_books_variance_fields';
  numberOfLines?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reports" */
export type Reports = {
  __typename?: 'reports';
  reportContent: Scalars['String']['output'];
  reportDate: Scalars['timestamp']['output'];
  reportId: Scalars['String']['output'];
  reportType: Scalars['String']['output'];
};

/** aggregated selection of "reports" */
export type Reports_Aggregate = {
  __typename?: 'reports_aggregate';
  aggregate?: Maybe<Reports_Aggregate_Fields>;
  nodes: Array<Reports>;
};

/** aggregate fields of "reports" */
export type Reports_Aggregate_Fields = {
  __typename?: 'reports_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Reports_Max_Fields>;
  min?: Maybe<Reports_Min_Fields>;
};


/** aggregate fields of "reports" */
export type Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reports_Bool_Exp>>;
  _not?: InputMaybe<Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reports_Bool_Exp>>;
  reportContent?: InputMaybe<String_Comparison_Exp>;
  reportDate?: InputMaybe<Timestamp_Comparison_Exp>;
  reportId?: InputMaybe<String_Comparison_Exp>;
  reportType?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "reportId" */
  ReportsPkey = 'reports_pkey'
}

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  reportContent?: InputMaybe<Scalars['String']['input']>;
  reportDate?: InputMaybe<Scalars['timestamp']['input']>;
  reportId?: InputMaybe<Scalars['String']['input']>;
  reportType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Reports_Max_Fields = {
  __typename?: 'reports_max_fields';
  reportContent?: Maybe<Scalars['String']['output']>;
  reportDate?: Maybe<Scalars['timestamp']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Reports_Min_Fields = {
  __typename?: 'reports_min_fields';
  reportContent?: Maybe<Scalars['String']['output']>;
  reportDate?: Maybe<Scalars['timestamp']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  constraint: Reports_Constraint;
  update_columns?: Array<Reports_Update_Column>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  reportContent?: InputMaybe<Order_By>;
  reportDate?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  reportType?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  reportId: Scalars['String']['input'];
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  ReportContent = 'reportContent',
  /** column name */
  ReportDate = 'reportDate',
  /** column name */
  ReportId = 'reportId',
  /** column name */
  ReportType = 'reportType'
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  reportContent?: InputMaybe<Scalars['String']['input']>;
  reportDate?: InputMaybe<Scalars['timestamp']['input']>;
  reportId?: InputMaybe<Scalars['String']['input']>;
  reportType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  reportContent?: InputMaybe<Scalars['String']['input']>;
  reportDate?: InputMaybe<Scalars['timestamp']['input']>;
  reportId?: InputMaybe<Scalars['String']['input']>;
  reportType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  ReportContent = 'reportContent',
  /** column name */
  ReportDate = 'reportDate',
  /** column name */
  ReportId = 'reportId',
  /** column name */
  ReportType = 'reportType'
}

export type Reports_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reports_Bool_Exp;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  /** An array relationship */
  admins: Array<Admins>;
  /** An aggregate relationship */
  admins_aggregate: Admins_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  roles_actions: Array<Roles_Actions>;
  /** An aggregate relationship */
  roles_actions_aggregate: Roles_Actions_Aggregate;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "roles" */
export type RolesAdminsArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesAdmins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRoles_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRoles_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};

/** columns and relationships of "roles_actions" */
export type Roles_Actions = {
  __typename?: 'roles_actions';
  /** An object relationship */
  action: Actions;
  actionId: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
};

/** aggregated selection of "roles_actions" */
export type Roles_Actions_Aggregate = {
  __typename?: 'roles_actions_aggregate';
  aggregate?: Maybe<Roles_Actions_Aggregate_Fields>;
  nodes: Array<Roles_Actions>;
};

export type Roles_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Roles_Actions_Aggregate_Bool_Exp_Count>;
};

export type Roles_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Roles_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "roles_actions" */
export type Roles_Actions_Aggregate_Fields = {
  __typename?: 'roles_actions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Actions_Max_Fields>;
  min?: Maybe<Roles_Actions_Min_Fields>;
};


/** aggregate fields of "roles_actions" */
export type Roles_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "roles_actions" */
export type Roles_Actions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Roles_Actions_Max_Order_By>;
  min?: InputMaybe<Roles_Actions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "roles_actions" */
export type Roles_Actions_Arr_Rel_Insert_Input = {
  data: Array<Roles_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_Actions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "roles_actions". All fields are combined with a logical 'AND'. */
export type Roles_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Actions_Bool_Exp>>;
  _not?: InputMaybe<Roles_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Actions_Bool_Exp>>;
  action?: InputMaybe<Actions_Bool_Exp>;
  actionId?: InputMaybe<String_Comparison_Exp>;
  roleId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles_actions" */
export enum Roles_Actions_Constraint {
  /** unique or primary key constraint on columns "roleId", "actionId" */
  RolesActionsPkey = 'roles_actions_pkey'
}

/** input type for inserting data into table "roles_actions" */
export type Roles_Actions_Insert_Input = {
  action?: InputMaybe<Actions_Obj_Rel_Insert_Input>;
  actionId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Roles_Actions_Max_Fields = {
  __typename?: 'roles_actions_max_fields';
  actionId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "roles_actions" */
export type Roles_Actions_Max_Order_By = {
  actionId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Actions_Min_Fields = {
  __typename?: 'roles_actions_min_fields';
  actionId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "roles_actions" */
export type Roles_Actions_Min_Order_By = {
  actionId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "roles_actions" */
export type Roles_Actions_Mutation_Response = {
  __typename?: 'roles_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles_Actions>;
};

/** on_conflict condition type for table "roles_actions" */
export type Roles_Actions_On_Conflict = {
  constraint: Roles_Actions_Constraint;
  update_columns?: Array<Roles_Actions_Update_Column>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "roles_actions". */
export type Roles_Actions_Order_By = {
  action?: InputMaybe<Actions_Order_By>;
  actionId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles_actions */
export type Roles_Actions_Pk_Columns_Input = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

/** select columns of table "roles_actions" */
export enum Roles_Actions_Select_Column {
  /** column name */
  ActionId = 'actionId',
  /** column name */
  RoleId = 'roleId'
}

/** input type for updating data in table "roles_actions" */
export type Roles_Actions_Set_Input = {
  actionId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "roles_actions" */
export type Roles_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Actions_Stream_Cursor_Value_Input = {
  actionId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "roles_actions" */
export enum Roles_Actions_Update_Column {
  /** column name */
  ActionId = 'actionId',
  /** column name */
  RoleId = 'roleId'
}

export type Roles_Actions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Actions_Bool_Exp;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  admins?: InputMaybe<Admins_Bool_Exp>;
  admins_aggregate?: InputMaybe<Admins_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  roles_actions?: InputMaybe<Roles_Actions_Bool_Exp>;
  roles_actions_aggregate?: InputMaybe<Roles_Actions_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolesNameKey = 'roles_name_key',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  admins?: InputMaybe<Admins_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles_actions?: InputMaybe<Roles_Actions_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  admins_aggregate?: InputMaybe<Admins_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  roles_actions_aggregate?: InputMaybe<Roles_Actions_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "scheduled_notifications" */
export type Scheduled_Notifications = {
  __typename?: 'scheduled_notifications';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  endDateTime?: Maybe<Scalars['timestamptz']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  notificationData: Scalars['jsonb']['output'];
  notificationTime: Scalars['timestamptz']['output'];
  objectId?: Maybe<Scalars['String']['output']>;
  sent: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "scheduled_notifications" */
export type Scheduled_NotificationsNotificationDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "scheduled_notifications" */
export type Scheduled_Notifications_Aggregate = {
  __typename?: 'scheduled_notifications_aggregate';
  aggregate?: Maybe<Scheduled_Notifications_Aggregate_Fields>;
  nodes: Array<Scheduled_Notifications>;
};

/** aggregate fields of "scheduled_notifications" */
export type Scheduled_Notifications_Aggregate_Fields = {
  __typename?: 'scheduled_notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Scheduled_Notifications_Max_Fields>;
  min?: Maybe<Scheduled_Notifications_Min_Fields>;
};


/** aggregate fields of "scheduled_notifications" */
export type Scheduled_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scheduled_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scheduled_Notifications_Append_Input = {
  notificationData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "scheduled_notifications". All fields are combined with a logical 'AND'. */
export type Scheduled_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Scheduled_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Scheduled_Notifications_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endDateTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  notificationData?: InputMaybe<Jsonb_Comparison_Exp>;
  notificationTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  objectId?: InputMaybe<String_Comparison_Exp>;
  sent?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scheduled_notifications" */
export enum Scheduled_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScheduledNotificationsPkey = 'scheduled_notifications_pkey',
  /** unique or primary key constraint on columns "userId", "objectId", "event" */
  ScheduledNotificationsUserIdObjectIdEventKey = 'scheduled_notifications_userId_objectId_event_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scheduled_Notifications_Delete_At_Path_Input = {
  notificationData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scheduled_Notifications_Delete_Elem_Input = {
  notificationData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scheduled_Notifications_Delete_Key_Input = {
  notificationData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "scheduled_notifications" */
export type Scheduled_Notifications_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endDateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notificationData?: InputMaybe<Scalars['jsonb']['input']>;
  notificationTime?: InputMaybe<Scalars['timestamptz']['input']>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  sent?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Scheduled_Notifications_Max_Fields = {
  __typename?: 'scheduled_notifications_max_fields';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endDateTime?: Maybe<Scalars['timestamptz']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  notificationTime?: Maybe<Scalars['timestamptz']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Scheduled_Notifications_Min_Fields = {
  __typename?: 'scheduled_notifications_min_fields';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endDateTime?: Maybe<Scalars['timestamptz']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  notificationTime?: Maybe<Scalars['timestamptz']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "scheduled_notifications" */
export type Scheduled_Notifications_Mutation_Response = {
  __typename?: 'scheduled_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scheduled_Notifications>;
};

/** on_conflict condition type for table "scheduled_notifications" */
export type Scheduled_Notifications_On_Conflict = {
  constraint: Scheduled_Notifications_Constraint;
  update_columns?: Array<Scheduled_Notifications_Update_Column>;
  where?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "scheduled_notifications". */
export type Scheduled_Notifications_Order_By = {
  body?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notificationData?: InputMaybe<Order_By>;
  notificationTime?: InputMaybe<Order_By>;
  objectId?: InputMaybe<Order_By>;
  sent?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scheduled_notifications */
export type Scheduled_Notifications_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scheduled_Notifications_Prepend_Input = {
  notificationData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scheduled_notifications" */
export enum Scheduled_Notifications_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDateTime = 'endDateTime',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationData = 'notificationData',
  /** column name */
  NotificationTime = 'notificationTime',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  Sent = 'sent',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "scheduled_notifications" */
export type Scheduled_Notifications_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endDateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notificationData?: InputMaybe<Scalars['jsonb']['input']>;
  notificationTime?: InputMaybe<Scalars['timestamptz']['input']>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  sent?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "scheduled_notifications" */
export type Scheduled_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scheduled_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scheduled_Notifications_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endDateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notificationData?: InputMaybe<Scalars['jsonb']['input']>;
  notificationTime?: InputMaybe<Scalars['timestamptz']['input']>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  sent?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "scheduled_notifications" */
export enum Scheduled_Notifications_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDateTime = 'endDateTime',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationData = 'notificationData',
  /** column name */
  NotificationTime = 'notificationTime',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  Sent = 'sent',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Scheduled_Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scheduled_Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scheduled_Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scheduled_Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scheduled_Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scheduled_Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scheduled_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scheduled_Notifications_Bool_Exp;
};

/** columns and relationships of "school_users" */
export type School_Users = {
  __typename?: 'school_users';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  schoolAddress?: Maybe<Scalars['String']['output']>;
  schoolName: Scalars['String']['output'];
  schoolUserId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  /** An array relationship */
  students: Array<Students>;
  /** An aggregate relationship */
  students_aggregate: Students_Aggregate;
  supervisor: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};


/** columns and relationships of "school_users" */
export type School_UsersFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "school_users" */
export type School_UsersFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "school_users" */
export type School_UsersStudentsArgs = {
  distinct_on?: InputMaybe<Array<Students_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Students_Order_By>>;
  where?: InputMaybe<Students_Bool_Exp>;
};


/** columns and relationships of "school_users" */
export type School_UsersStudents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Students_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Students_Order_By>>;
  where?: InputMaybe<Students_Bool_Exp>;
};

/** aggregated selection of "school_users" */
export type School_Users_Aggregate = {
  __typename?: 'school_users_aggregate';
  aggregate?: Maybe<School_Users_Aggregate_Fields>;
  nodes: Array<School_Users>;
};

/** aggregate fields of "school_users" */
export type School_Users_Aggregate_Fields = {
  __typename?: 'school_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<School_Users_Max_Fields>;
  min?: Maybe<School_Users_Min_Fields>;
};


/** aggregate fields of "school_users" */
export type School_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<School_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "school_users". All fields are combined with a logical 'AND'. */
export type School_Users_Bool_Exp = {
  _and?: InputMaybe<Array<School_Users_Bool_Exp>>;
  _not?: InputMaybe<School_Users_Bool_Exp>;
  _or?: InputMaybe<Array<School_Users_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  schoolAddress?: InputMaybe<String_Comparison_Exp>;
  schoolName?: InputMaybe<String_Comparison_Exp>;
  schoolUserId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  students?: InputMaybe<Students_Bool_Exp>;
  students_aggregate?: InputMaybe<Students_Aggregate_Bool_Exp>;
  supervisor?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "school_users" */
export enum School_Users_Constraint {
  /** unique or primary key constraint on columns "schoolUserId" */
  SchoolUsersPkey = 'school_users_pkey',
  /** unique or primary key constraint on columns "userId" */
  SchoolUsersUserIdKey = 'school_users_userId_key'
}

/** input type for inserting data into table "school_users" */
export type School_Users_Insert_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  schoolAddress?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  students?: InputMaybe<Students_Arr_Rel_Insert_Input>;
  supervisor?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type School_Users_Max_Fields = {
  __typename?: 'school_users_max_fields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  schoolAddress?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supervisor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type School_Users_Min_Fields = {
  __typename?: 'school_users_min_fields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  schoolAddress?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supervisor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "school_users" */
export type School_Users_Mutation_Response = {
  __typename?: 'school_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<School_Users>;
};

/** input type for inserting object relation for remote table "school_users" */
export type School_Users_Obj_Rel_Insert_Input = {
  data: School_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<School_Users_On_Conflict>;
};

/** on_conflict condition type for table "school_users" */
export type School_Users_On_Conflict = {
  constraint: School_Users_Constraint;
  update_columns?: Array<School_Users_Update_Column>;
  where?: InputMaybe<School_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "school_users". */
export type School_Users_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  schoolAddress?: InputMaybe<Order_By>;
  schoolName?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  students_aggregate?: InputMaybe<Students_Aggregate_Order_By>;
  supervisor?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: school_users */
export type School_Users_Pk_Columns_Input = {
  schoolUserId: Scalars['String']['input'];
};

/** select columns of table "school_users" */
export enum School_Users_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  SchoolAddress = 'schoolAddress',
  /** column name */
  SchoolName = 'schoolName',
  /** column name */
  SchoolUserId = 'schoolUserId',
  /** column name */
  Status = 'status',
  /** column name */
  Supervisor = 'supervisor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "school_users" */
export type School_Users_Set_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  schoolAddress?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supervisor?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "school_users" */
export type School_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: School_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type School_Users_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  schoolAddress?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supervisor?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "school_users" */
export enum School_Users_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  SchoolAddress = 'schoolAddress',
  /** column name */
  SchoolName = 'schoolName',
  /** column name */
  SchoolUserId = 'schoolUserId',
  /** column name */
  Status = 'status',
  /** column name */
  Supervisor = 'supervisor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type School_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<School_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: School_Users_Bool_Exp;
};

/** columns and relationships of "scientific_sessions" */
export type Scientific_Sessions = {
  __typename?: 'scientific_sessions';
  appointemnt?: Maybe<Scalars['Int']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  delayLimit: Scalars['Int']['output'];
  description?: Maybe<Scalars['jsonb']['output']>;
  expiryDate?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['String']['output'];
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  scientific_sessions_user_scientific_sessions: Array<User_Scientific_Sessions>;
  /** An aggregate relationship */
  scientific_sessions_user_scientific_sessions_aggregate: User_Scientific_Sessions_Aggregate;
  sessionName?: Maybe<Scalars['jsonb']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamp']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "scientific_sessions" */
export type Scientific_SessionsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "scientific_sessions" */
export type Scientific_SessionsScientific_Sessions_User_Scientific_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


/** columns and relationships of "scientific_sessions" */
export type Scientific_SessionsScientific_Sessions_User_Scientific_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


/** columns and relationships of "scientific_sessions" */
export type Scientific_SessionsSessionNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "scientific_sessions" */
export type Scientific_Sessions_Aggregate = {
  __typename?: 'scientific_sessions_aggregate';
  aggregate?: Maybe<Scientific_Sessions_Aggregate_Fields>;
  nodes: Array<Scientific_Sessions>;
};

/** aggregate fields of "scientific_sessions" */
export type Scientific_Sessions_Aggregate_Fields = {
  __typename?: 'scientific_sessions_aggregate_fields';
  avg?: Maybe<Scientific_Sessions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Scientific_Sessions_Max_Fields>;
  min?: Maybe<Scientific_Sessions_Min_Fields>;
  stddev?: Maybe<Scientific_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Scientific_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scientific_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Scientific_Sessions_Sum_Fields>;
  var_pop?: Maybe<Scientific_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Scientific_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Scientific_Sessions_Variance_Fields>;
};


/** aggregate fields of "scientific_sessions" */
export type Scientific_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scientific_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scientific_Sessions_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Scientific_Sessions_Avg_Fields = {
  __typename?: 'scientific_sessions_avg_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "scientific_sessions". All fields are combined with a logical 'AND'. */
export type Scientific_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Scientific_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Scientific_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Scientific_Sessions_Bool_Exp>>;
  appointemnt?: InputMaybe<Int_Comparison_Exp>;
  courseFileURL?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  delayLimit?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  expiryDate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  mutoonMatnId?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  scientific_sessions_user_scientific_sessions?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
  scientific_sessions_user_scientific_sessions_aggregate?: InputMaybe<User_Scientific_Sessions_Aggregate_Bool_Exp>;
  sessionName?: InputMaybe<Jsonb_Comparison_Exp>;
  sheikhName?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  youTubeLink?: InputMaybe<String_Comparison_Exp>;
  zoomLink?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scientific_sessions" */
export enum Scientific_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScientificSessionsPkey = 'scientific_sessions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scientific_Sessions_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  sessionName?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scientific_Sessions_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  sessionName?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scientific_Sessions_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  sessionName?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "scientific_sessions" */
export type Scientific_Sessions_Inc_Input = {
  appointemnt?: InputMaybe<Scalars['Int']['input']>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "scientific_sessions" */
export type Scientific_Sessions_Insert_Input = {
  appointemnt?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  scientific_sessions_user_scientific_sessions?: InputMaybe<User_Scientific_Sessions_Arr_Rel_Insert_Input>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Scientific_Sessions_Max_Fields = {
  __typename?: 'scientific_sessions_max_fields';
  appointemnt?: Maybe<Scalars['Int']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  expiryDate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamp']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Scientific_Sessions_Min_Fields = {
  __typename?: 'scientific_sessions_min_fields';
  appointemnt?: Maybe<Scalars['Int']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  expiryDate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['timestamp']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "scientific_sessions" */
export type Scientific_Sessions_Mutation_Response = {
  __typename?: 'scientific_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scientific_Sessions>;
};

/** input type for inserting object relation for remote table "scientific_sessions" */
export type Scientific_Sessions_Obj_Rel_Insert_Input = {
  data: Scientific_Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Scientific_Sessions_On_Conflict>;
};

/** on_conflict condition type for table "scientific_sessions" */
export type Scientific_Sessions_On_Conflict = {
  constraint: Scientific_Sessions_Constraint;
  update_columns?: Array<Scientific_Sessions_Update_Column>;
  where?: InputMaybe<Scientific_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "scientific_sessions". */
export type Scientific_Sessions_Order_By = {
  appointemnt?: InputMaybe<Order_By>;
  courseFileURL?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delayLimit?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  scientific_sessions_user_scientific_sessions_aggregate?: InputMaybe<User_Scientific_Sessions_Aggregate_Order_By>;
  sessionName?: InputMaybe<Order_By>;
  sheikhName?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  youTubeLink?: InputMaybe<Order_By>;
  zoomLink?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scientific_sessions */
export type Scientific_Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scientific_Sessions_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scientific_sessions" */
export enum Scientific_Sessions_Select_Column {
  /** column name */
  Appointemnt = 'appointemnt',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DelayLimit = 'delayLimit',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Price = 'price',
  /** column name */
  SessionName = 'sessionName',
  /** column name */
  SheikhName = 'sheikhName',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  YouTubeLink = 'youTubeLink',
  /** column name */
  ZoomLink = 'zoomLink'
}

/** input type for updating data in table "scientific_sessions" */
export type Scientific_Sessions_Set_Input = {
  appointemnt?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Scientific_Sessions_Stddev_Fields = {
  __typename?: 'scientific_sessions_stddev_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Scientific_Sessions_Stddev_Pop_Fields = {
  __typename?: 'scientific_sessions_stddev_pop_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Scientific_Sessions_Stddev_Samp_Fields = {
  __typename?: 'scientific_sessions_stddev_samp_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "scientific_sessions" */
export type Scientific_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scientific_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scientific_Sessions_Stream_Cursor_Value_Input = {
  appointemnt?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Scientific_Sessions_Sum_Fields = {
  __typename?: 'scientific_sessions_sum_fields';
  appointemnt?: Maybe<Scalars['Int']['output']>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "scientific_sessions" */
export enum Scientific_Sessions_Update_Column {
  /** column name */
  Appointemnt = 'appointemnt',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DelayLimit = 'delayLimit',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Price = 'price',
  /** column name */
  SessionName = 'sessionName',
  /** column name */
  SheikhName = 'sheikhName',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  YouTubeLink = 'youTubeLink',
  /** column name */
  ZoomLink = 'zoomLink'
}

export type Scientific_Sessions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scientific_Sessions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scientific_Sessions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scientific_Sessions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scientific_Sessions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Scientific_Sessions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scientific_Sessions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scientific_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scientific_Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Scientific_Sessions_Var_Pop_Fields = {
  __typename?: 'scientific_sessions_var_pop_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Scientific_Sessions_Var_Samp_Fields = {
  __typename?: 'scientific_sessions_var_samp_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Scientific_Sessions_Variance_Fields = {
  __typename?: 'scientific_sessions_variance_fields';
  appointemnt?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "self_recitations" */
export type Self_Recitations = {
  __typename?: 'self_recitations';
  createdAt: Scalars['timestamptz']['output'];
  errorType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  indices?: Maybe<Array<Scalars['Int']['output']>>;
  sectionId?: Maybe<Scalars['String']['output']>;
  sequence: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "self_recitations" */
export type Self_Recitations_Aggregate = {
  __typename?: 'self_recitations_aggregate';
  aggregate?: Maybe<Self_Recitations_Aggregate_Fields>;
  nodes: Array<Self_Recitations>;
};

/** aggregate fields of "self_recitations" */
export type Self_Recitations_Aggregate_Fields = {
  __typename?: 'self_recitations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Self_Recitations_Max_Fields>;
  min?: Maybe<Self_Recitations_Min_Fields>;
};


/** aggregate fields of "self_recitations" */
export type Self_Recitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Self_Recitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "self_recitations". All fields are combined with a logical 'AND'. */
export type Self_Recitations_Bool_Exp = {
  _and?: InputMaybe<Array<Self_Recitations_Bool_Exp>>;
  _not?: InputMaybe<Self_Recitations_Bool_Exp>;
  _or?: InputMaybe<Array<Self_Recitations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  indices?: InputMaybe<Int_Array_Comparison_Exp>;
  sectionId?: InputMaybe<String_Comparison_Exp>;
  sequence?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "self_recitations" */
export enum Self_Recitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  SelfRecitationsPkey = 'self_recitations_pkey'
}

/** input type for inserting data into table "self_recitations" */
export type Self_Recitations_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  errorType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indices?: InputMaybe<Array<Scalars['Int']['input']>>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Self_Recitations_Max_Fields = {
  __typename?: 'self_recitations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  indices?: Maybe<Array<Scalars['Int']['output']>>;
  sectionId?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Self_Recitations_Min_Fields = {
  __typename?: 'self_recitations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  indices?: Maybe<Array<Scalars['Int']['output']>>;
  sectionId?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "self_recitations" */
export type Self_Recitations_Mutation_Response = {
  __typename?: 'self_recitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Self_Recitations>;
};

/** on_conflict condition type for table "self_recitations" */
export type Self_Recitations_On_Conflict = {
  constraint: Self_Recitations_Constraint;
  update_columns?: Array<Self_Recitations_Update_Column>;
  where?: InputMaybe<Self_Recitations_Bool_Exp>;
};

/** Ordering options when selecting data from "self_recitations". */
export type Self_Recitations_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  indices?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: self_recitations */
export type Self_Recitations_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "self_recitations" */
export enum Self_Recitations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorType = 'errorType',
  /** column name */
  Id = 'id',
  /** column name */
  Indices = 'indices',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "self_recitations" */
export type Self_Recitations_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  errorType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indices?: InputMaybe<Array<Scalars['Int']['input']>>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "self_recitations" */
export type Self_Recitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Self_Recitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Self_Recitations_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  errorType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  indices?: InputMaybe<Array<Scalars['Int']['input']>>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "self_recitations" */
export enum Self_Recitations_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorType = 'errorType',
  /** column name */
  Id = 'id',
  /** column name */
  Indices = 'indices',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Self_Recitations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Self_Recitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Self_Recitations_Bool_Exp;
};

/** columns and relationships of "sessions" */
export type Sessions = {
  __typename?: 'sessions';
  appointmentLimit: Scalars['Int']['output'];
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  days?: Maybe<Array<Scalars['String']['output']>>;
  delayLimit: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['jsonb']['output']>;
  duration: Scalars['Int']['output'];
  enable_conversation?: Maybe<Scalars['Boolean']['output']>;
  enable_raise_hand?: Maybe<Scalars['Boolean']['output']>;
  enable_recording?: Maybe<Scalars['Boolean']['output']>;
  enable_upload_files?: Maybe<Scalars['Boolean']['output']>;
  enable_video?: Maybe<Scalars['Boolean']['output']>;
  expiryDate?: Maybe<Scalars['timestamptz']['output']>;
  frequency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  max_user_count?: Maybe<Scalars['Int']['output']>;
  meetingId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mutoon?: Maybe<Mutoons>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  prerequisites: Array<Prerequisites>;
  /** An aggregate relationship */
  prerequisites_aggregate: Prerequisites_Aggregate;
  price?: Maybe<Scalars['float8']['output']>;
  startDate: Scalars['timestamptz']['output'];
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['jsonb']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_sessions: Array<User_Sessions>;
  /** An aggregate relationship */
  user_sessions_aggregate: User_Sessions_Aggregate;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "sessions" */
export type SessionsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "sessions" */
export type SessionsPrerequisitesArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsPrerequisites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsTitleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "sessions" */
export type SessionsUser_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsUser_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};

/** aggregated selection of "sessions" */
export type Sessions_Aggregate = {
  __typename?: 'sessions_aggregate';
  aggregate?: Maybe<Sessions_Aggregate_Fields>;
  nodes: Array<Sessions>;
};

/** aggregate fields of "sessions" */
export type Sessions_Aggregate_Fields = {
  __typename?: 'sessions_aggregate_fields';
  avg?: Maybe<Sessions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sessions_Max_Fields>;
  min?: Maybe<Sessions_Min_Fields>;
  stddev?: Maybe<Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Sessions_Sum_Fields>;
  var_pop?: Maybe<Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Sessions_Var_Samp_Fields>;
  variance?: Maybe<Sessions_Variance_Fields>;
};


/** aggregate fields of "sessions" */
export type Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sessions_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Sessions_Avg_Fields = {
  __typename?: 'sessions_avg_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sessions". All fields are combined with a logical 'AND'. */
export type Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Sessions_Bool_Exp>>;
  _not?: InputMaybe<Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Sessions_Bool_Exp>>;
  appointmentLimit?: InputMaybe<Int_Comparison_Exp>;
  courseFileURL?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  days?: InputMaybe<String_Array_Comparison_Exp>;
  delayLimit?: InputMaybe<Int_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  enable_conversation?: InputMaybe<Boolean_Comparison_Exp>;
  enable_raise_hand?: InputMaybe<Boolean_Comparison_Exp>;
  enable_recording?: InputMaybe<Boolean_Comparison_Exp>;
  enable_upload_files?: InputMaybe<Boolean_Comparison_Exp>;
  enable_video?: InputMaybe<Boolean_Comparison_Exp>;
  expiryDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  max_user_count?: InputMaybe<Int_Comparison_Exp>;
  meetingId?: InputMaybe<String_Comparison_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonMatnId?: InputMaybe<String_Comparison_Exp>;
  prerequisites?: InputMaybe<Prerequisites_Bool_Exp>;
  prerequisites_aggregate?: InputMaybe<Prerequisites_Aggregate_Bool_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<Jsonb_Comparison_Exp>;
  transactionId?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_sessions?: InputMaybe<User_Sessions_Bool_Exp>;
  user_sessions_aggregate?: InputMaybe<User_Sessions_Aggregate_Bool_Exp>;
  youTubeLink?: InputMaybe<String_Comparison_Exp>;
  zoomLink?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sessions" */
export enum Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionsPkey = 'sessions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sessions_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sessions_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sessions_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "sessions" */
export type Sessions_Inc_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "sessions" */
export type Sessions_Insert_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  prerequisites?: InputMaybe<Prerequisites_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['float8']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_sessions?: InputMaybe<User_Sessions_Arr_Rel_Insert_Input>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Sessions_Max_Fields = {
  __typename?: 'sessions_max_fields';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  expiryDate?: Maybe<Scalars['timestamptz']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  meetingId?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Sessions_Min_Fields = {
  __typename?: 'sessions_min_fields';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  days?: Maybe<Array<Scalars['String']['output']>>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  expiryDate?: Maybe<Scalars['timestamptz']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  meetingId?: Maybe<Scalars['String']['output']>;
  mutoonMatnId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  startDate?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  youTubeLink?: Maybe<Scalars['String']['output']>;
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "sessions" */
export type Sessions_Mutation_Response = {
  __typename?: 'sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sessions>;
};

/** input type for inserting object relation for remote table "sessions" */
export type Sessions_Obj_Rel_Insert_Input = {
  data: Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};

/** on_conflict condition type for table "sessions" */
export type Sessions_On_Conflict = {
  constraint: Sessions_Constraint;
  update_columns?: Array<Sessions_Update_Column>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "sessions". */
export type Sessions_Order_By = {
  appointmentLimit?: InputMaybe<Order_By>;
  courseFileURL?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  delayLimit?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  enable_conversation?: InputMaybe<Order_By>;
  enable_raise_hand?: InputMaybe<Order_By>;
  enable_recording?: InputMaybe<Order_By>;
  enable_upload_files?: InputMaybe<Order_By>;
  enable_video?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_user_count?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonMatnId?: InputMaybe<Order_By>;
  prerequisites_aggregate?: InputMaybe<Prerequisites_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_sessions_aggregate?: InputMaybe<User_Sessions_Aggregate_Order_By>;
  youTubeLink?: InputMaybe<Order_By>;
  zoomLink?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sessions */
export type Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sessions_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "sessions" */
export enum Sessions_Select_Column {
  /** column name */
  AppointmentLimit = 'appointmentLimit',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Days = 'days',
  /** column name */
  DelayLimit = 'delayLimit',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EnableConversation = 'enable_conversation',
  /** column name */
  EnableRaiseHand = 'enable_raise_hand',
  /** column name */
  EnableRecording = 'enable_recording',
  /** column name */
  EnableUploadFiles = 'enable_upload_files',
  /** column name */
  EnableVideo = 'enable_video',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUserCount = 'max_user_count',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  YouTubeLink = 'youTubeLink',
  /** column name */
  ZoomLink = 'zoomLink'
}

/** input type for updating data in table "sessions" */
export type Sessions_Set_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Sessions_Stddev_Fields = {
  __typename?: 'sessions_stddev_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Sessions_Stddev_Pop_Fields = {
  __typename?: 'sessions_stddev_pop_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Sessions_Stddev_Samp_Fields = {
  __typename?: 'sessions_stddev_samp_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sessions" */
export type Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sessions_Stream_Cursor_Value_Input = {
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']>>;
  delayLimit?: InputMaybe<Scalars['Int']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Sessions_Sum_Fields = {
  __typename?: 'sessions_sum_fields';
  appointmentLimit?: Maybe<Scalars['Int']['output']>;
  delayLimit?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  max_user_count?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "sessions" */
export enum Sessions_Update_Column {
  /** column name */
  AppointmentLimit = 'appointmentLimit',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Days = 'days',
  /** column name */
  DelayLimit = 'delayLimit',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EnableConversation = 'enable_conversation',
  /** column name */
  EnableRaiseHand = 'enable_raise_hand',
  /** column name */
  EnableRecording = 'enable_recording',
  /** column name */
  EnableUploadFiles = 'enable_upload_files',
  /** column name */
  EnableVideo = 'enable_video',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUserCount = 'max_user_count',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  MutoonMatnId = 'mutoonMatnId',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  YouTubeLink = 'youTubeLink',
  /** column name */
  ZoomLink = 'zoomLink'
}

export type Sessions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sessions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sessions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sessions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sessions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sessions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sessions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sessions_Var_Pop_Fields = {
  __typename?: 'sessions_var_pop_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Sessions_Var_Samp_Fields = {
  __typename?: 'sessions_var_samp_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Sessions_Variance_Fields = {
  __typename?: 'sessions_variance_fields';
  appointmentLimit?: Maybe<Scalars['Float']['output']>;
  delayLimit?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  max_user_count?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "sounds" */
export type Sounds = {
  __typename?: 'sounds';
  description?: Maybe<Scalars['jsonb']['output']>;
  end?: Maybe<Scalars['float8']['output']>;
  fileUrl: Scalars['String']['output'];
  mutoonSectionId: Scalars['String']['output'];
  /** An object relationship */
  mutoon_section: Mutoon_Sections;
  name: Scalars['jsonb']['output'];
  soundId: Scalars['String']['output'];
  start?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  user_records: Array<User_Records>;
  /** An aggregate relationship */
  user_records_aggregate: User_Records_Aggregate;
};


/** columns and relationships of "sounds" */
export type SoundsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "sounds" */
export type SoundsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "sounds" */
export type SoundsUser_RecordsArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


/** columns and relationships of "sounds" */
export type SoundsUser_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};

/** aggregated selection of "sounds" */
export type Sounds_Aggregate = {
  __typename?: 'sounds_aggregate';
  aggregate?: Maybe<Sounds_Aggregate_Fields>;
  nodes: Array<Sounds>;
};

export type Sounds_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Sounds_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Sounds_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Sounds_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Sounds_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Sounds_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Sounds_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Sounds_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Sounds_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Sounds_Aggregate_Bool_Exp_Var_Samp>;
};

export type Sounds_Aggregate_Bool_Exp_Avg = {
  arguments: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Corr = {
  arguments: Sounds_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Sounds_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sounds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Sounds_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Sounds_Aggregate_Bool_Exp_Max = {
  arguments: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Min = {
  arguments: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Sum = {
  arguments: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sounds_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sounds_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "sounds" */
export type Sounds_Aggregate_Fields = {
  __typename?: 'sounds_aggregate_fields';
  avg?: Maybe<Sounds_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sounds_Max_Fields>;
  min?: Maybe<Sounds_Min_Fields>;
  stddev?: Maybe<Sounds_Stddev_Fields>;
  stddev_pop?: Maybe<Sounds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sounds_Stddev_Samp_Fields>;
  sum?: Maybe<Sounds_Sum_Fields>;
  var_pop?: Maybe<Sounds_Var_Pop_Fields>;
  var_samp?: Maybe<Sounds_Var_Samp_Fields>;
  variance?: Maybe<Sounds_Variance_Fields>;
};


/** aggregate fields of "sounds" */
export type Sounds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sounds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "sounds" */
export type Sounds_Aggregate_Order_By = {
  avg?: InputMaybe<Sounds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sounds_Max_Order_By>;
  min?: InputMaybe<Sounds_Min_Order_By>;
  stddev?: InputMaybe<Sounds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sounds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sounds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sounds_Sum_Order_By>;
  var_pop?: InputMaybe<Sounds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sounds_Var_Samp_Order_By>;
  variance?: InputMaybe<Sounds_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sounds_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "sounds" */
export type Sounds_Arr_Rel_Insert_Input = {
  data: Array<Sounds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sounds_On_Conflict>;
};

/** aggregate avg on columns */
export type Sounds_Avg_Fields = {
  __typename?: 'sounds_avg_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "sounds" */
export type Sounds_Avg_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sounds". All fields are combined with a logical 'AND'. */
export type Sounds_Bool_Exp = {
  _and?: InputMaybe<Array<Sounds_Bool_Exp>>;
  _not?: InputMaybe<Sounds_Bool_Exp>;
  _or?: InputMaybe<Array<Sounds_Bool_Exp>>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  end?: InputMaybe<Float8_Comparison_Exp>;
  fileUrl?: InputMaybe<String_Comparison_Exp>;
  mutoonSectionId?: InputMaybe<String_Comparison_Exp>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  name?: InputMaybe<Jsonb_Comparison_Exp>;
  soundId?: InputMaybe<String_Comparison_Exp>;
  start?: InputMaybe<Float8_Comparison_Exp>;
  user_records?: InputMaybe<User_Records_Bool_Exp>;
  user_records_aggregate?: InputMaybe<User_Records_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "sounds" */
export enum Sounds_Constraint {
  /** unique or primary key constraint on columns "soundId" */
  SoundsPkey = 'sounds_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sounds_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sounds_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sounds_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "sounds" */
export type Sounds_Inc_Input = {
  end?: InputMaybe<Scalars['float8']['input']>;
  start?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "sounds" */
export type Sounds_Insert_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  end?: InputMaybe<Scalars['float8']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  soundId?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['float8']['input']>;
  user_records?: InputMaybe<User_Records_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sounds_Max_Fields = {
  __typename?: 'sounds_max_fields';
  end?: Maybe<Scalars['float8']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  soundId?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "sounds" */
export type Sounds_Max_Order_By = {
  end?: InputMaybe<Order_By>;
  fileUrl?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  soundId?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sounds_Min_Fields = {
  __typename?: 'sounds_min_fields';
  end?: Maybe<Scalars['float8']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  soundId?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "sounds" */
export type Sounds_Min_Order_By = {
  end?: InputMaybe<Order_By>;
  fileUrl?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  soundId?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sounds" */
export type Sounds_Mutation_Response = {
  __typename?: 'sounds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sounds>;
};

/** input type for inserting object relation for remote table "sounds" */
export type Sounds_Obj_Rel_Insert_Input = {
  data: Sounds_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sounds_On_Conflict>;
};

/** on_conflict condition type for table "sounds" */
export type Sounds_On_Conflict = {
  constraint: Sounds_Constraint;
  update_columns?: Array<Sounds_Update_Column>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};

/** Ordering options when selecting data from "sounds". */
export type Sounds_Order_By = {
  description?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  fileUrl?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Order_By>;
  name?: InputMaybe<Order_By>;
  soundId?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  user_records_aggregate?: InputMaybe<User_Records_Aggregate_Order_By>;
};

/** primary key columns input for table: sounds */
export type Sounds_Pk_Columns_Input = {
  soundId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sounds_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "sounds" */
export enum Sounds_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  FileUrl = 'fileUrl',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  Name = 'name',
  /** column name */
  SoundId = 'soundId',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_avg_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_corr_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_max_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_min_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_sum_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** select "sounds_aggregate_bool_exp_var_samp_arguments_columns" columns of table "sounds" */
export enum Sounds_Select_Column_Sounds_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

/** input type for updating data in table "sounds" */
export type Sounds_Set_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  end?: InputMaybe<Scalars['float8']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  soundId?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Sounds_Stddev_Fields = {
  __typename?: 'sounds_stddev_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "sounds" */
export type Sounds_Stddev_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sounds_Stddev_Pop_Fields = {
  __typename?: 'sounds_stddev_pop_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "sounds" */
export type Sounds_Stddev_Pop_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sounds_Stddev_Samp_Fields = {
  __typename?: 'sounds_stddev_samp_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "sounds" */
export type Sounds_Stddev_Samp_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sounds" */
export type Sounds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sounds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sounds_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
  end?: InputMaybe<Scalars['float8']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  soundId?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Sounds_Sum_Fields = {
  __typename?: 'sounds_sum_fields';
  end?: Maybe<Scalars['float8']['output']>;
  start?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "sounds" */
export type Sounds_Sum_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** update columns of table "sounds" */
export enum Sounds_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  FileUrl = 'fileUrl',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  Name = 'name',
  /** column name */
  SoundId = 'soundId',
  /** column name */
  Start = 'start'
}

export type Sounds_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sounds_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sounds_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sounds_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sounds_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sounds_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sounds_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sounds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sounds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sounds_Var_Pop_Fields = {
  __typename?: 'sounds_var_pop_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "sounds" */
export type Sounds_Var_Pop_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sounds_Var_Samp_Fields = {
  __typename?: 'sounds_var_samp_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "sounds" */
export type Sounds_Var_Samp_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sounds_Variance_Fields = {
  __typename?: 'sounds_variance_fields';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "sounds" */
export type Sounds_Variance_Order_By = {
  end?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

/** columns and relationships of "statistics" */
export type Statistics = {
  __typename?: 'statistics';
  statisticDate: Scalars['timestamp']['output'];
  statisticId: Scalars['String']['output'];
  statisticType: Scalars['String']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "statistics" */
export type StatisticsValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "statistics" */
export type Statistics_Aggregate = {
  __typename?: 'statistics_aggregate';
  aggregate?: Maybe<Statistics_Aggregate_Fields>;
  nodes: Array<Statistics>;
};

/** aggregate fields of "statistics" */
export type Statistics_Aggregate_Fields = {
  __typename?: 'statistics_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Statistics_Max_Fields>;
  min?: Maybe<Statistics_Min_Fields>;
};


/** aggregate fields of "statistics" */
export type Statistics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Statistics_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "statistics". All fields are combined with a logical 'AND'. */
export type Statistics_Bool_Exp = {
  _and?: InputMaybe<Array<Statistics_Bool_Exp>>;
  _not?: InputMaybe<Statistics_Bool_Exp>;
  _or?: InputMaybe<Array<Statistics_Bool_Exp>>;
  statisticDate?: InputMaybe<Timestamp_Comparison_Exp>;
  statisticId?: InputMaybe<String_Comparison_Exp>;
  statisticType?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistics" */
export enum Statistics_Constraint {
  /** unique or primary key constraint on columns "statisticId" */
  StatisticsPkey = 'statistics_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Statistics_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Statistics_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Statistics_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "statistics" */
export type Statistics_Insert_Input = {
  statisticDate?: InputMaybe<Scalars['timestamp']['input']>;
  statisticId?: InputMaybe<Scalars['String']['input']>;
  statisticType?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Statistics_Max_Fields = {
  __typename?: 'statistics_max_fields';
  statisticDate?: Maybe<Scalars['timestamp']['output']>;
  statisticId?: Maybe<Scalars['String']['output']>;
  statisticType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Statistics_Min_Fields = {
  __typename?: 'statistics_min_fields';
  statisticDate?: Maybe<Scalars['timestamp']['output']>;
  statisticId?: Maybe<Scalars['String']['output']>;
  statisticType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "statistics" */
export type Statistics_Mutation_Response = {
  __typename?: 'statistics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistics>;
};

/** on_conflict condition type for table "statistics" */
export type Statistics_On_Conflict = {
  constraint: Statistics_Constraint;
  update_columns?: Array<Statistics_Update_Column>;
  where?: InputMaybe<Statistics_Bool_Exp>;
};

/** Ordering options when selecting data from "statistics". */
export type Statistics_Order_By = {
  statisticDate?: InputMaybe<Order_By>;
  statisticId?: InputMaybe<Order_By>;
  statisticType?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistics */
export type Statistics_Pk_Columns_Input = {
  statisticId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Statistics_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "statistics" */
export enum Statistics_Select_Column {
  /** column name */
  StatisticDate = 'statisticDate',
  /** column name */
  StatisticId = 'statisticId',
  /** column name */
  StatisticType = 'statisticType',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "statistics" */
export type Statistics_Set_Input = {
  statisticDate?: InputMaybe<Scalars['timestamp']['input']>;
  statisticId?: InputMaybe<Scalars['String']['input']>;
  statisticType?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "statistics" */
export type Statistics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistics_Stream_Cursor_Value_Input = {
  statisticDate?: InputMaybe<Scalars['timestamp']['input']>;
  statisticId?: InputMaybe<Scalars['String']['input']>;
  statisticType?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "statistics" */
export enum Statistics_Update_Column {
  /** column name */
  StatisticDate = 'statisticDate',
  /** column name */
  StatisticId = 'statisticId',
  /** column name */
  StatisticType = 'statisticType',
  /** column name */
  Value = 'value'
}

export type Statistics_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Statistics_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Statistics_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Statistics_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Statistics_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Statistics_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistics_Bool_Exp;
};

/** columns and relationships of "students" */
export type Students = {
  __typename?: 'students';
  birthDate?: Maybe<Scalars['date']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  schoolUserId: Scalars['String']['output'];
  /** An object relationship */
  school_user: School_Users;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_certificates: Array<User_Certificates>;
  /** An aggregate relationship */
  user_certificates_aggregate: User_Certificates_Aggregate;
  /** An array relationship */
  user_hearing_councils: Array<User_Hearing_Councils>;
  /** An aggregate relationship */
  user_hearing_councils_aggregate: User_Hearing_Councils_Aggregate;
};


/** columns and relationships of "students" */
export type StudentsUser_CertificatesArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsUser_Certificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsUser_Hearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsUser_Hearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};

/** aggregated selection of "students" */
export type Students_Aggregate = {
  __typename?: 'students_aggregate';
  aggregate?: Maybe<Students_Aggregate_Fields>;
  nodes: Array<Students>;
};

export type Students_Aggregate_Bool_Exp = {
  count?: InputMaybe<Students_Aggregate_Bool_Exp_Count>;
};

export type Students_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Students_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Students_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "students" */
export type Students_Aggregate_Fields = {
  __typename?: 'students_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Students_Max_Fields>;
  min?: Maybe<Students_Min_Fields>;
};


/** aggregate fields of "students" */
export type Students_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Students_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "students" */
export type Students_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Students_Max_Order_By>;
  min?: InputMaybe<Students_Min_Order_By>;
};

/** input type for inserting array relation for remote table "students" */
export type Students_Arr_Rel_Insert_Input = {
  data: Array<Students_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Students_On_Conflict>;
};

/** Boolean expression to filter rows from the table "students". All fields are combined with a logical 'AND'. */
export type Students_Bool_Exp = {
  _and?: InputMaybe<Array<Students_Bool_Exp>>;
  _not?: InputMaybe<Students_Bool_Exp>;
  _or?: InputMaybe<Array<Students_Bool_Exp>>;
  birthDate?: InputMaybe<Date_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fcmTokens?: InputMaybe<String_Array_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  schoolUserId?: InputMaybe<String_Comparison_Exp>;
  school_user?: InputMaybe<School_Users_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_certificates?: InputMaybe<User_Certificates_Bool_Exp>;
  user_certificates_aggregate?: InputMaybe<User_Certificates_Aggregate_Bool_Exp>;
  user_hearing_councils?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  user_hearing_councils_aggregate?: InputMaybe<User_Hearing_Councils_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "students" */
export enum Students_Constraint {
  /** unique or primary key constraint on columns "id" */
  StudentsPkey = 'students_pkey'
}

/** input type for inserting data into table "students" */
export type Students_Insert_Input = {
  birthDate?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  school_user?: InputMaybe<School_Users_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_certificates?: InputMaybe<User_Certificates_Arr_Rel_Insert_Input>;
  user_hearing_councils?: InputMaybe<User_Hearing_Councils_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Students_Max_Fields = {
  __typename?: 'students_max_fields';
  birthDate?: Maybe<Scalars['date']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "students" */
export type Students_Max_Order_By = {
  birthDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fcmTokens?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Students_Min_Fields = {
  __typename?: 'students_min_fields';
  birthDate?: Maybe<Scalars['date']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  schoolUserId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "students" */
export type Students_Min_Order_By = {
  birthDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fcmTokens?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "students" */
export type Students_Mutation_Response = {
  __typename?: 'students_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Students>;
};

/** input type for inserting object relation for remote table "students" */
export type Students_Obj_Rel_Insert_Input = {
  data: Students_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Students_On_Conflict>;
};

/** on_conflict condition type for table "students" */
export type Students_On_Conflict = {
  constraint: Students_Constraint;
  update_columns?: Array<Students_Update_Column>;
  where?: InputMaybe<Students_Bool_Exp>;
};

/** Ordering options when selecting data from "students". */
export type Students_Order_By = {
  birthDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fcmTokens?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  schoolUserId?: InputMaybe<Order_By>;
  school_user?: InputMaybe<School_Users_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_certificates_aggregate?: InputMaybe<User_Certificates_Aggregate_Order_By>;
  user_hearing_councils_aggregate?: InputMaybe<User_Hearing_Councils_Aggregate_Order_By>;
};

/** primary key columns input for table: students */
export type Students_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "students" */
export enum Students_Select_Column {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  FcmTokens = 'fcmTokens',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  SchoolUserId = 'schoolUserId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "students" */
export type Students_Set_Input = {
  birthDate?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "students" */
export type Students_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Students_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Students_Stream_Cursor_Value_Input = {
  birthDate?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "students" */
export enum Students_Update_Column {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  FcmTokens = 'fcmTokens',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  SchoolUserId = 'schoolUserId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Students_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Students_Set_Input>;
  /** filter the rows which have to be updated */
  where: Students_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "_DonationHistoryToTransaction" */
  _DonationHistoryToTransaction: Array<_DonationHistoryToTransaction>;
  /** fetch aggregated fields from the table: "_DonationHistoryToTransaction" */
  _DonationHistoryToTransaction_aggregate: _DonationHistoryToTransaction_Aggregate;
  /** fetch data from the table in a streaming manner: "_DonationHistoryToTransaction" */
  _DonationHistoryToTransaction_stream: Array<_DonationHistoryToTransaction>;
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate;
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>;
  /** fetch data from the table in a streaming manner: "actions" */
  actions_stream: Array<Actions>;
  /** An array relationship */
  admins: Array<Admins>;
  /** An aggregate relationship */
  admins_aggregate: Admins_Aggregate;
  /** fetch data from the table: "admins" using primary key columns */
  admins_by_pk?: Maybe<Admins>;
  /** fetch data from the table in a streaming manner: "admins" */
  admins_stream: Array<Admins>;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<Answers>;
  /** fetch data from the table in a streaming manner: "answers" */
  answers_stream: Array<Answers>;
  /** fetch data from the table: "authorized_users" */
  authorized_users: Array<Authorized_Users>;
  /** fetch aggregated fields from the table: "authorized_users" */
  authorized_users_aggregate: Authorized_Users_Aggregate;
  /** fetch data from the table: "authorized_users" using primary key columns */
  authorized_users_by_pk?: Maybe<Authorized_Users>;
  /** fetch data from the table in a streaming manner: "authorized_users" */
  authorized_users_stream: Array<Authorized_Users>;
  /** An array relationship */
  badges: Array<Badges>;
  /** An aggregate relationship */
  badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "badges" using primary key columns */
  badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table in a streaming manner: "badges" */
  badges_stream: Array<Badges>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>;
  /** An array relationship */
  certificate_variables: Array<Certificate_Variables>;
  /** An aggregate relationship */
  certificate_variables_aggregate: Certificate_Variables_Aggregate;
  /** fetch data from the table: "certificate_variables" using primary key columns */
  certificate_variables_by_pk?: Maybe<Certificate_Variables>;
  /** fetch data from the table in a streaming manner: "certificate_variables" */
  certificate_variables_stream: Array<Certificate_Variables>;
  /** fetch data from the table: "certificates" */
  certificates: Array<Certificates>;
  /** fetch aggregated fields from the table: "certificates" */
  certificates_aggregate: Certificates_Aggregate;
  /** fetch data from the table: "certificates" using primary key columns */
  certificates_by_pk?: Maybe<Certificates>;
  /** fetch data from the table in a streaming manner: "certificates" */
  certificates_stream: Array<Certificates>;
  /** fetch data from the table: "chat_messages" */
  chat_messages: Array<Chat_Messages>;
  /** fetch aggregated fields from the table: "chat_messages" */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** fetch data from the table in a streaming manner: "chat_messages" */
  chat_messages_stream: Array<Chat_Messages>;
  /** fetch data from the table: "chats" */
  chats: Array<Chats>;
  /** fetch aggregated fields from the table: "chats" */
  chats_aggregate: Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table in a streaming manner: "chats" */
  chats_stream: Array<Chats>;
  /** fetch data from the table: "contact_us" */
  contact_us: Array<Contact_Us>;
  /** fetch aggregated fields from the table: "contact_us" */
  contact_us_aggregate: Contact_Us_Aggregate;
  /** fetch data from the table: "contact_us" using primary key columns */
  contact_us_by_pk?: Maybe<Contact_Us>;
  /** fetch data from the table in a streaming manner: "contact_us" */
  contact_us_stream: Array<Contact_Us>;
  /** fetch data from the table: "content_pages" */
  content_pages: Array<Content_Pages>;
  /** fetch aggregated fields from the table: "content_pages" */
  content_pages_aggregate: Content_Pages_Aggregate;
  /** fetch data from the table: "content_pages" using primary key columns */
  content_pages_by_pk?: Maybe<Content_Pages>;
  /** fetch data from the table in a streaming manner: "content_pages" */
  content_pages_stream: Array<Content_Pages>;
  /** fetch data from the table: "content_recommendations" */
  content_recommendations: Array<Content_Recommendations>;
  /** fetch aggregated fields from the table: "content_recommendations" */
  content_recommendations_aggregate: Content_Recommendations_Aggregate;
  /** fetch data from the table: "content_recommendations" using primary key columns */
  content_recommendations_by_pk?: Maybe<Content_Recommendations>;
  /** fetch data from the table in a streaming manner: "content_recommendations" */
  content_recommendations_stream: Array<Content_Recommendations>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countries_stream: Array<Countries>;
  /** fetch data from the table: "discussion_rooms" */
  discussion_rooms: Array<Discussion_Rooms>;
  /** fetch aggregated fields from the table: "discussion_rooms" */
  discussion_rooms_aggregate: Discussion_Rooms_Aggregate;
  /** fetch data from the table: "discussion_rooms" using primary key columns */
  discussion_rooms_by_pk?: Maybe<Discussion_Rooms>;
  /** fetch data from the table in a streaming manner: "discussion_rooms" */
  discussion_rooms_stream: Array<Discussion_Rooms>;
  /** fetch data from the table: "donation_histories" */
  donation_histories: Array<Donation_Histories>;
  /** fetch aggregated fields from the table: "donation_histories" */
  donation_histories_aggregate: Donation_Histories_Aggregate;
  /** fetch data from the table: "donation_histories" using primary key columns */
  donation_histories_by_pk?: Maybe<Donation_Histories>;
  /** fetch data from the table in a streaming manner: "donation_histories" */
  donation_histories_stream: Array<Donation_Histories>;
  /** fetch data from the table: "donations" */
  donations: Array<Donations>;
  /** fetch aggregated fields from the table: "donations" */
  donations_aggregate: Donations_Aggregate;
  /** fetch data from the table: "donations" using primary key columns */
  donations_by_pk?: Maybe<Donations>;
  /** fetch data from the table in a streaming manner: "donations" */
  donations_stream: Array<Donations>;
  /** fetch data from the table: "event_reminders" */
  event_reminders: Array<Event_Reminders>;
  /** fetch aggregated fields from the table: "event_reminders" */
  event_reminders_aggregate: Event_Reminders_Aggregate;
  /** fetch data from the table: "event_reminders" using primary key columns */
  event_reminders_by_pk?: Maybe<Event_Reminders>;
  /** fetch data from the table in a streaming manner: "event_reminders" */
  event_reminders_stream: Array<Event_Reminders>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<Events>;
  /** fetch data from the table: "exams" */
  exams: Array<Exams>;
  /** fetch aggregated fields from the table: "exams" */
  exams_aggregate: Exams_Aggregate;
  /** fetch data from the table: "exams" using primary key columns */
  exams_by_pk?: Maybe<Exams>;
  /** fetch data from the table in a streaming manner: "exams" */
  exams_stream: Array<Exams>;
  /** fetch data from the table: "faqs" */
  faqs: Array<Faqs>;
  /** fetch aggregated fields from the table: "faqs" */
  faqs_aggregate: Faqs_Aggregate;
  /** fetch data from the table: "faqs" using primary key columns */
  faqs_by_pk?: Maybe<Faqs>;
  /** fetch data from the table in a streaming manner: "faqs" */
  faqs_stream: Array<Faqs>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "group_members" */
  group_members: Array<Group_Members>;
  /** fetch aggregated fields from the table: "group_members" */
  group_members_aggregate: Group_Members_Aggregate;
  /** fetch data from the table: "group_members" using primary key columns */
  group_members_by_pk?: Maybe<Group_Members>;
  /** fetch data from the table in a streaming manner: "group_members" */
  group_members_stream: Array<Group_Members>;
  /** An array relationship */
  hearing_councils: Array<Hearing_Councils>;
  /** An aggregate relationship */
  hearing_councils_aggregate: Hearing_Councils_Aggregate;
  /** fetch data from the table: "hearing_councils" using primary key columns */
  hearing_councils_by_pk?: Maybe<Hearing_Councils>;
  /** fetch data from the table in a streaming manner: "hearing_councils" */
  hearing_councils_stream: Array<Hearing_Councils>;
  /** fetch data from the table: "knowledge_bases" */
  knowledge_bases: Array<Knowledge_Bases>;
  /** fetch aggregated fields from the table: "knowledge_bases" */
  knowledge_bases_aggregate: Knowledge_Bases_Aggregate;
  /** fetch data from the table: "knowledge_bases" using primary key columns */
  knowledge_bases_by_pk?: Maybe<Knowledge_Bases>;
  /** fetch data from the table in a streaming manner: "knowledge_bases" */
  knowledge_bases_stream: Array<Knowledge_Bases>;
  /** fetch data from the table: "lectures_and_webinars" */
  lectures_and_webinars: Array<Lectures_And_Webinars>;
  /** fetch aggregated fields from the table: "lectures_and_webinars" */
  lectures_and_webinars_aggregate: Lectures_And_Webinars_Aggregate;
  /** fetch data from the table: "lectures_and_webinars" using primary key columns */
  lectures_and_webinars_by_pk?: Maybe<Lectures_And_Webinars>;
  /** fetch data from the table in a streaming manner: "lectures_and_webinars" */
  lectures_and_webinars_stream: Array<Lectures_And_Webinars>;
  /** An array relationship */
  levels: Array<Levels>;
  /** An aggregate relationship */
  levels_aggregate: Levels_Aggregate;
  /** fetch data from the table: "levels" using primary key columns */
  levels_by_pk?: Maybe<Levels>;
  /** fetch data from the table in a streaming manner: "levels" */
  levels_stream: Array<Levels>;
  /** fetch data from the table: "media_messages" */
  media_messages: Array<Media_Messages>;
  /** fetch aggregated fields from the table: "media_messages" */
  media_messages_aggregate: Media_Messages_Aggregate;
  /** fetch data from the table: "media_messages" using primary key columns */
  media_messages_by_pk?: Maybe<Media_Messages>;
  /** fetch data from the table in a streaming manner: "media_messages" */
  media_messages_stream: Array<Media_Messages>;
  /** fetch data from the table: "meetings" */
  meetings: Array<Meetings>;
  /** fetch aggregated fields from the table: "meetings" */
  meetings_aggregate: Meetings_Aggregate;
  /** fetch data from the table: "meetings" using primary key columns */
  meetings_by_pk?: Maybe<Meetings>;
  /** fetch data from the table in a streaming manner: "meetings" */
  meetings_stream: Array<Meetings>;
  /** fetch data from the table: "message_recipients" */
  message_recipients: Array<Message_Recipients>;
  /** fetch aggregated fields from the table: "message_recipients" */
  message_recipients_aggregate: Message_Recipients_Aggregate;
  /** fetch data from the table: "message_recipients" using primary key columns */
  message_recipients_by_pk?: Maybe<Message_Recipients>;
  /** fetch data from the table in a streaming manner: "message_recipients" */
  message_recipients_stream: Array<Message_Recipients>;
  /** fetch data from the table: "mutoon_categories" */
  mutoon_categories: Array<Mutoon_Categories>;
  /** fetch aggregated fields from the table: "mutoon_categories" */
  mutoon_categories_aggregate: Mutoon_Categories_Aggregate;
  /** fetch data from the table: "mutoon_categories" using primary key columns */
  mutoon_categories_by_pk?: Maybe<Mutoon_Categories>;
  /** fetch data from the table in a streaming manner: "mutoon_categories" */
  mutoon_categories_stream: Array<Mutoon_Categories>;
  /** An array relationship */
  mutoon_sections: Array<Mutoon_Sections>;
  /** An aggregate relationship */
  mutoon_sections_aggregate: Mutoon_Sections_Aggregate;
  /** fetch data from the table: "mutoon_sections" using primary key columns */
  mutoon_sections_by_pk?: Maybe<Mutoon_Sections>;
  /** fetch data from the table in a streaming manner: "mutoon_sections" */
  mutoon_sections_stream: Array<Mutoon_Sections>;
  /** An array relationship */
  mutoons: Array<Mutoons>;
  /** An aggregate relationship */
  mutoons_aggregate: Mutoons_Aggregate;
  /** fetch data from the table: "mutoons" using primary key columns */
  mutoons_by_pk?: Maybe<Mutoons>;
  /** fetch data from the table in a streaming manner: "mutoons" */
  mutoons_stream: Array<Mutoons>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** fetch data from the table in a streaming manner: "notes" */
  notes_stream: Array<Notes>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "payment_methods" */
  payment_methods: Array<Payment_Methods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** fetch data from the table in a streaming manner: "payment_methods" */
  payment_methods_stream: Array<Payment_Methods>;
  /** An array relationship */
  plan_sections: Array<Plan_Sections>;
  /** An aggregate relationship */
  plan_sections_aggregate: Plan_Sections_Aggregate;
  /** fetch data from the table: "plan_sections" using primary key columns */
  plan_sections_by_pk?: Maybe<Plan_Sections>;
  /** fetch data from the table in a streaming manner: "plan_sections" */
  plan_sections_stream: Array<Plan_Sections>;
  /** An array relationship */
  plans: Array<Plans>;
  /** An aggregate relationship */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** fetch data from the table in a streaming manner: "plans" */
  plans_stream: Array<Plans>;
  /** fetch data from the table: "platform_settings" */
  platform_settings: Array<Platform_Settings>;
  /** fetch aggregated fields from the table: "platform_settings" */
  platform_settings_aggregate: Platform_Settings_Aggregate;
  /** fetch data from the table: "platform_settings" using primary key columns */
  platform_settings_by_pk?: Maybe<Platform_Settings>;
  /** fetch data from the table in a streaming manner: "platform_settings" */
  platform_settings_stream: Array<Platform_Settings>;
  /** An array relationship */
  prerequisites: Array<Prerequisites>;
  /** An aggregate relationship */
  prerequisites_aggregate: Prerequisites_Aggregate;
  /** fetch data from the table: "prerequisites" using primary key columns */
  prerequisites_by_pk?: Maybe<Prerequisites>;
  /** fetch data from the table in a streaming manner: "prerequisites" */
  prerequisites_stream: Array<Prerequisites>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** fetch data from the table: "ratings_and_reviews" */
  ratings_and_reviews: Array<Ratings_And_Reviews>;
  /** fetch aggregated fields from the table: "ratings_and_reviews" */
  ratings_and_reviews_aggregate: Ratings_And_Reviews_Aggregate;
  /** fetch data from the table: "ratings_and_reviews" using primary key columns */
  ratings_and_reviews_by_pk?: Maybe<Ratings_And_Reviews>;
  /** fetch data from the table in a streaming manner: "ratings_and_reviews" */
  ratings_and_reviews_stream: Array<Ratings_And_Reviews>;
  /** fetch data from the table: "reference_books" */
  reference_books: Array<Reference_Books>;
  /** fetch aggregated fields from the table: "reference_books" */
  reference_books_aggregate: Reference_Books_Aggregate;
  /** fetch data from the table: "reference_books" using primary key columns */
  reference_books_by_pk?: Maybe<Reference_Books>;
  /** fetch data from the table in a streaming manner: "reference_books" */
  reference_books_stream: Array<Reference_Books>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch aggregated fields from the table: "reports" */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** An array relationship */
  roles_actions: Array<Roles_Actions>;
  /** An aggregate relationship */
  roles_actions_aggregate: Roles_Actions_Aggregate;
  /** fetch data from the table: "roles_actions" using primary key columns */
  roles_actions_by_pk?: Maybe<Roles_Actions>;
  /** fetch data from the table in a streaming manner: "roles_actions" */
  roles_actions_stream: Array<Roles_Actions>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "scheduled_notifications" */
  scheduled_notifications: Array<Scheduled_Notifications>;
  /** fetch aggregated fields from the table: "scheduled_notifications" */
  scheduled_notifications_aggregate: Scheduled_Notifications_Aggregate;
  /** fetch data from the table: "scheduled_notifications" using primary key columns */
  scheduled_notifications_by_pk?: Maybe<Scheduled_Notifications>;
  /** fetch data from the table in a streaming manner: "scheduled_notifications" */
  scheduled_notifications_stream: Array<Scheduled_Notifications>;
  /** fetch data from the table: "school_users" */
  school_users: Array<School_Users>;
  /** fetch aggregated fields from the table: "school_users" */
  school_users_aggregate: School_Users_Aggregate;
  /** fetch data from the table: "school_users" using primary key columns */
  school_users_by_pk?: Maybe<School_Users>;
  /** fetch data from the table in a streaming manner: "school_users" */
  school_users_stream: Array<School_Users>;
  /** fetch data from the table: "scientific_sessions" */
  scientific_sessions: Array<Scientific_Sessions>;
  /** fetch aggregated fields from the table: "scientific_sessions" */
  scientific_sessions_aggregate: Scientific_Sessions_Aggregate;
  /** fetch data from the table: "scientific_sessions" using primary key columns */
  scientific_sessions_by_pk?: Maybe<Scientific_Sessions>;
  /** fetch data from the table in a streaming manner: "scientific_sessions" */
  scientific_sessions_stream: Array<Scientific_Sessions>;
  /** fetch data from the table: "self_recitations" */
  self_recitations: Array<Self_Recitations>;
  /** fetch aggregated fields from the table: "self_recitations" */
  self_recitations_aggregate: Self_Recitations_Aggregate;
  /** fetch data from the table: "self_recitations" using primary key columns */
  self_recitations_by_pk?: Maybe<Self_Recitations>;
  /** fetch data from the table in a streaming manner: "self_recitations" */
  self_recitations_stream: Array<Self_Recitations>;
  /** fetch data from the table: "sessions" */
  sessions: Array<Sessions>;
  /** fetch aggregated fields from the table: "sessions" */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** fetch data from the table in a streaming manner: "sessions" */
  sessions_stream: Array<Sessions>;
  /** An array relationship */
  sounds: Array<Sounds>;
  /** An aggregate relationship */
  sounds_aggregate: Sounds_Aggregate;
  /** fetch data from the table: "sounds" using primary key columns */
  sounds_by_pk?: Maybe<Sounds>;
  /** fetch data from the table in a streaming manner: "sounds" */
  sounds_stream: Array<Sounds>;
  /** fetch data from the table: "statistics" */
  statistics: Array<Statistics>;
  /** fetch aggregated fields from the table: "statistics" */
  statistics_aggregate: Statistics_Aggregate;
  /** fetch data from the table: "statistics" using primary key columns */
  statistics_by_pk?: Maybe<Statistics>;
  /** fetch data from the table in a streaming manner: "statistics" */
  statistics_stream: Array<Statistics>;
  /** An array relationship */
  students: Array<Students>;
  /** An aggregate relationship */
  students_aggregate: Students_Aggregate;
  /** fetch data from the table: "students" using primary key columns */
  students_by_pk?: Maybe<Students>;
  /** fetch data from the table in a streaming manner: "students" */
  students_stream: Array<Students>;
  /** fetch data from the table: "subscriptions" */
  subscriptions: Array<Subscriptions>;
  /** fetch aggregated fields from the table: "subscriptions" */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  subscriptions_stream: Array<Subscriptions>;
  /** fetch data from the table: "text_messages" */
  text_messages: Array<Text_Messages>;
  /** fetch aggregated fields from the table: "text_messages" */
  text_messages_aggregate: Text_Messages_Aggregate;
  /** fetch data from the table: "text_messages" using primary key columns */
  text_messages_by_pk?: Maybe<Text_Messages>;
  /** fetch data from the table in a streaming manner: "text_messages" */
  text_messages_stream: Array<Text_Messages>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  transactions_stream: Array<Transactions>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  /** fetch data from the table: "translations" using primary key columns */
  translations_by_pk?: Maybe<Translations>;
  /** fetch data from the table in a streaming manner: "translations" */
  translations_stream: Array<Translations>;
  /** An array relationship */
  user_badges: Array<User_Badges>;
  /** An aggregate relationship */
  user_badges_aggregate: User_Badges_Aggregate;
  /** fetch data from the table: "user_badges" using primary key columns */
  user_badges_by_pk?: Maybe<User_Badges>;
  /** fetch data from the table in a streaming manner: "user_badges" */
  user_badges_stream: Array<User_Badges>;
  /** An array relationship */
  user_categories: Array<User_Categories>;
  /** An aggregate relationship */
  user_categories_aggregate: User_Categories_Aggregate;
  /** fetch data from the table: "user_categories" using primary key columns */
  user_categories_by_pk?: Maybe<User_Categories>;
  /** fetch data from the table in a streaming manner: "user_categories" */
  user_categories_stream: Array<User_Categories>;
  /** An array relationship */
  user_certificates: Array<User_Certificates>;
  /** An aggregate relationship */
  user_certificates_aggregate: User_Certificates_Aggregate;
  /** fetch data from the table: "user_certificates" using primary key columns */
  user_certificates_by_pk?: Maybe<User_Certificates>;
  /** fetch data from the table in a streaming manner: "user_certificates" */
  user_certificates_stream: Array<User_Certificates>;
  /** fetch data from the table: "user_credit_card_infos" */
  user_credit_card_infos: Array<User_Credit_Card_Infos>;
  /** fetch aggregated fields from the table: "user_credit_card_infos" */
  user_credit_card_infos_aggregate: User_Credit_Card_Infos_Aggregate;
  /** fetch data from the table: "user_credit_card_infos" using primary key columns */
  user_credit_card_infos_by_pk?: Maybe<User_Credit_Card_Infos>;
  /** fetch data from the table in a streaming manner: "user_credit_card_infos" */
  user_credit_card_infos_stream: Array<User_Credit_Card_Infos>;
  /** fetch data from the table: "user_discussion_rooms" */
  user_discussion_rooms: Array<User_Discussion_Rooms>;
  /** fetch aggregated fields from the table: "user_discussion_rooms" */
  user_discussion_rooms_aggregate: User_Discussion_Rooms_Aggregate;
  /** fetch data from the table: "user_discussion_rooms" using primary key columns */
  user_discussion_rooms_by_pk?: Maybe<User_Discussion_Rooms>;
  /** fetch data from the table in a streaming manner: "user_discussion_rooms" */
  user_discussion_rooms_stream: Array<User_Discussion_Rooms>;
  /** An array relationship */
  user_exams: Array<User_Exams>;
  /** An aggregate relationship */
  user_exams_aggregate: User_Exams_Aggregate;
  /** fetch data from the table: "user_exams" using primary key columns */
  user_exams_by_pk?: Maybe<User_Exams>;
  /** fetch data from the table in a streaming manner: "user_exams" */
  user_exams_stream: Array<User_Exams>;
  /** An array relationship */
  user_hearing_councils: Array<User_Hearing_Councils>;
  /** An aggregate relationship */
  user_hearing_councils_aggregate: User_Hearing_Councils_Aggregate;
  /** fetch data from the table: "user_hearing_councils" using primary key columns */
  user_hearing_councils_by_pk?: Maybe<User_Hearing_Councils>;
  /** fetch data from the table in a streaming manner: "user_hearing_councils" */
  user_hearing_councils_stream: Array<User_Hearing_Councils>;
  /** fetch data from the table: "user_meetings" */
  user_meetings: Array<User_Meetings>;
  /** fetch aggregated fields from the table: "user_meetings" */
  user_meetings_aggregate: User_Meetings_Aggregate;
  /** fetch data from the table: "user_meetings" using primary key columns */
  user_meetings_by_pk?: Maybe<User_Meetings>;
  /** fetch data from the table in a streaming manner: "user_meetings" */
  user_meetings_stream: Array<User_Meetings>;
  /** fetch data from the table: "user_preferences" */
  user_preferences: Array<User_Preferences>;
  /** fetch aggregated fields from the table: "user_preferences" */
  user_preferences_aggregate: User_Preferences_Aggregate;
  /** fetch data from the table: "user_preferences" using primary key columns */
  user_preferences_by_pk?: Maybe<User_Preferences>;
  /** fetch data from the table in a streaming manner: "user_preferences" */
  user_preferences_stream: Array<User_Preferences>;
  /** An array relationship */
  user_quizes: Array<User_Quizes>;
  /** An aggregate relationship */
  user_quizes_aggregate: User_Quizes_Aggregate;
  /** fetch data from the table: "user_quizes" using primary key columns */
  user_quizes_by_pk?: Maybe<User_Quizes>;
  /** fetch data from the table in a streaming manner: "user_quizes" */
  user_quizes_stream: Array<User_Quizes>;
  /** An array relationship */
  user_records: Array<User_Records>;
  /** An aggregate relationship */
  user_records_aggregate: User_Records_Aggregate;
  /** fetch data from the table: "user_records" using primary key columns */
  user_records_by_pk?: Maybe<User_Records>;
  /** fetch data from the table in a streaming manner: "user_records" */
  user_records_stream: Array<User_Records>;
  /** An array relationship */
  user_scientific_sessions: Array<User_Scientific_Sessions>;
  /** An aggregate relationship */
  user_scientific_sessions_aggregate: User_Scientific_Sessions_Aggregate;
  /** fetch data from the table: "user_scientific_sessions" using primary key columns */
  user_scientific_sessions_by_pk?: Maybe<User_Scientific_Sessions>;
  /** fetch data from the table in a streaming manner: "user_scientific_sessions" */
  user_scientific_sessions_stream: Array<User_Scientific_Sessions>;
  /** An array relationship */
  user_sessions: Array<User_Sessions>;
  /** An aggregate relationship */
  user_sessions_aggregate: User_Sessions_Aggregate;
  /** fetch data from the table: "user_sessions" using primary key columns */
  user_sessions_by_pk?: Maybe<User_Sessions>;
  /** fetch data from the table in a streaming manner: "user_sessions" */
  user_sessions_stream: Array<User_Sessions>;
  /** fetch data from the table: "user_subscriptions" */
  user_subscriptions: Array<User_Subscriptions>;
  /** fetch aggregated fields from the table: "user_subscriptions" */
  user_subscriptions_aggregate: User_Subscriptions_Aggregate;
  /** fetch data from the table: "user_subscriptions" using primary key columns */
  user_subscriptions_by_pk?: Maybe<User_Subscriptions>;
  /** fetch data from the table in a streaming manner: "user_subscriptions" */
  user_subscriptions_stream: Array<User_Subscriptions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_Root_DonationHistoryToTransactionArgs = {
  distinct_on?: InputMaybe<Array<_DonationHistoryToTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_DonationHistoryToTransaction_Order_By>>;
  where?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
};


export type Subscription_Root_DonationHistoryToTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_DonationHistoryToTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_DonationHistoryToTransaction_Order_By>>;
  where?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
};


export type Subscription_Root_DonationHistoryToTransaction_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_DonationHistoryToTransaction_Stream_Cursor_Input>>;
  where?: InputMaybe<_DonationHistoryToTransaction_Bool_Exp>;
};


export type Subscription_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Subscription_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Actions_Order_By>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Subscription_RootActions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootActions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Actions_Bool_Exp>;
};


export type Subscription_RootAdminsArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Subscription_RootAdmins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Admins_Order_By>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Subscription_RootAdmins_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAdmins_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Admins_Stream_Cursor_Input>>;
  where?: InputMaybe<Admins_Bool_Exp>;
};


export type Subscription_RootAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAnswers_By_PkArgs = {
  answerId: Scalars['String']['input'];
};


export type Subscription_RootAnswers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAuthorized_UsersArgs = {
  distinct_on?: InputMaybe<Array<Authorized_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authorized_Users_Order_By>>;
  where?: InputMaybe<Authorized_Users_Bool_Exp>;
};


export type Subscription_RootAuthorized_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authorized_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Authorized_Users_Order_By>>;
  where?: InputMaybe<Authorized_Users_Bool_Exp>;
};


export type Subscription_RootAuthorized_Users_By_PkArgs = {
  authorizedUserId: Scalars['String']['input'];
};


export type Subscription_RootAuthorized_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Authorized_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Authorized_Users_Bool_Exp>;
};


export type Subscription_RootBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_By_PkArgs = {
  badgeId: Scalars['String']['input'];
};


export type Subscription_RootBadges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  categoryId: Scalars['String']['input'];
};


export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCertificate_VariablesArgs = {
  distinct_on?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificate_Variables_Order_By>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


export type Subscription_RootCertificate_Variables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificate_Variables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificate_Variables_Order_By>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


export type Subscription_RootCertificate_Variables_By_PkArgs = {
  certVarId: Scalars['String']['input'];
};


export type Subscription_RootCertificate_Variables_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Certificate_Variables_Stream_Cursor_Input>>;
  where?: InputMaybe<Certificate_Variables_Bool_Exp>;
};


export type Subscription_RootCertificatesArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Subscription_RootCertificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificates_Order_By>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Subscription_RootCertificates_By_PkArgs = {
  certificateId: Scalars['String']['input'];
};


export type Subscription_RootCertificates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Certificates_Stream_Cursor_Input>>;
  where?: InputMaybe<Certificates_Bool_Exp>;
};


export type Subscription_RootChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};


export type Subscription_RootChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};


export type Subscription_RootChat_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChat_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chat_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};


export type Subscription_RootChatsArgs = {
  distinct_on?: InputMaybe<Array<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootChats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootChats_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootChats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chats_Stream_Cursor_Input>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootContact_UsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContact_Us_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContact_Us_By_PkArgs = {
  contactId: Scalars['String']['input'];
};


export type Subscription_RootContact_Us_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Us_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContent_PagesArgs = {
  distinct_on?: InputMaybe<Array<Content_Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Pages_Order_By>>;
  where?: InputMaybe<Content_Pages_Bool_Exp>;
};


export type Subscription_RootContent_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Pages_Order_By>>;
  where?: InputMaybe<Content_Pages_Bool_Exp>;
};


export type Subscription_RootContent_Pages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootContent_Pages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Content_Pages_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Pages_Bool_Exp>;
};


export type Subscription_RootContent_RecommendationsArgs = {
  distinct_on?: InputMaybe<Array<Content_Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Recommendations_Order_By>>;
  where?: InputMaybe<Content_Recommendations_Bool_Exp>;
};


export type Subscription_RootContent_Recommendations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Recommendations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Recommendations_Order_By>>;
  where?: InputMaybe<Content_Recommendations_Bool_Exp>;
};


export type Subscription_RootContent_Recommendations_By_PkArgs = {
  recommendationId: Scalars['String']['input'];
};


export type Subscription_RootContent_Recommendations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Content_Recommendations_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Recommendations_Bool_Exp>;
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  countryId: Scalars['String']['input'];
};


export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Countries_Stream_Cursor_Input>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootDiscussion_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discussion_Rooms_Order_By>>;
  where?: InputMaybe<Discussion_Rooms_Bool_Exp>;
};


export type Subscription_RootDiscussion_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discussion_Rooms_Order_By>>;
  where?: InputMaybe<Discussion_Rooms_Bool_Exp>;
};


export type Subscription_RootDiscussion_Rooms_By_PkArgs = {
  roomId: Scalars['String']['input'];
};


export type Subscription_RootDiscussion_Rooms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Discussion_Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Discussion_Rooms_Bool_Exp>;
};


export type Subscription_RootDonation_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Donation_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donation_Histories_Order_By>>;
  where?: InputMaybe<Donation_Histories_Bool_Exp>;
};


export type Subscription_RootDonation_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Donation_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donation_Histories_Order_By>>;
  where?: InputMaybe<Donation_Histories_Bool_Exp>;
};


export type Subscription_RootDonation_Histories_By_PkArgs = {
  donationHistoryId: Scalars['String']['input'];
};


export type Subscription_RootDonation_Histories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Donation_Histories_Stream_Cursor_Input>>;
  where?: InputMaybe<Donation_Histories_Bool_Exp>;
};


export type Subscription_RootDonationsArgs = {
  distinct_on?: InputMaybe<Array<Donations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donations_Order_By>>;
  where?: InputMaybe<Donations_Bool_Exp>;
};


export type Subscription_RootDonations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Donations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Donations_Order_By>>;
  where?: InputMaybe<Donations_Bool_Exp>;
};


export type Subscription_RootDonations_By_PkArgs = {
  donationId: Scalars['String']['input'];
};


export type Subscription_RootDonations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Donations_Stream_Cursor_Input>>;
  where?: InputMaybe<Donations_Bool_Exp>;
};


export type Subscription_RootEvent_RemindersArgs = {
  distinct_on?: InputMaybe<Array<Event_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Reminders_Order_By>>;
  where?: InputMaybe<Event_Reminders_Bool_Exp>;
};


export type Subscription_RootEvent_Reminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Reminders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Reminders_Order_By>>;
  where?: InputMaybe<Event_Reminders_Bool_Exp>;
};


export type Subscription_RootEvent_Reminders_By_PkArgs = {
  reminderId: Scalars['String']['input'];
};


export type Subscription_RootEvent_Reminders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Event_Reminders_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Reminders_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  eventId: Scalars['String']['input'];
};


export type Subscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootExamsArgs = {
  distinct_on?: InputMaybe<Array<Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exams_Order_By>>;
  where?: InputMaybe<Exams_Bool_Exp>;
};


export type Subscription_RootExams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exams_Order_By>>;
  where?: InputMaybe<Exams_Bool_Exp>;
};


export type Subscription_RootExams_By_PkArgs = {
  examId: Scalars['String']['input'];
};


export type Subscription_RootExams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Exams_Stream_Cursor_Input>>;
  where?: InputMaybe<Exams_Bool_Exp>;
};


export type Subscription_RootFaqsArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Subscription_RootFaqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Subscription_RootFaqs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootFaqs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Faqs_Stream_Cursor_Input>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_Members_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootGroup_Members_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Group_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootHearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


export type Subscription_RootHearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


export type Subscription_RootHearing_Councils_By_PkArgs = {
  hearingCouncilId: Scalars['String']['input'];
};


export type Subscription_RootHearing_Councils_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Hearing_Councils_Stream_Cursor_Input>>;
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
};


export type Subscription_RootKnowledge_BasesArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Bases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Knowledge_Bases_Order_By>>;
  where?: InputMaybe<Knowledge_Bases_Bool_Exp>;
};


export type Subscription_RootKnowledge_Bases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Bases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Knowledge_Bases_Order_By>>;
  where?: InputMaybe<Knowledge_Bases_Bool_Exp>;
};


export type Subscription_RootKnowledge_Bases_By_PkArgs = {
  knowledgeBaseId: Scalars['String']['input'];
};


export type Subscription_RootKnowledge_Bases_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Knowledge_Bases_Stream_Cursor_Input>>;
  where?: InputMaybe<Knowledge_Bases_Bool_Exp>;
};


export type Subscription_RootLectures_And_WebinarsArgs = {
  distinct_on?: InputMaybe<Array<Lectures_And_Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lectures_And_Webinars_Order_By>>;
  where?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
};


export type Subscription_RootLectures_And_Webinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lectures_And_Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lectures_And_Webinars_Order_By>>;
  where?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
};


export type Subscription_RootLectures_And_Webinars_By_PkArgs = {
  lectureId: Scalars['String']['input'];
};


export type Subscription_RootLectures_And_Webinars_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lectures_And_Webinars_Stream_Cursor_Input>>;
  where?: InputMaybe<Lectures_And_Webinars_Bool_Exp>;
};


export type Subscription_RootLevelsArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_By_PkArgs = {
  levelId: Scalars['String']['input'];
};


export type Subscription_RootLevels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootMedia_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Media_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Media_Messages_Order_By>>;
  where?: InputMaybe<Media_Messages_Bool_Exp>;
};


export type Subscription_RootMedia_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Media_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Media_Messages_Order_By>>;
  where?: InputMaybe<Media_Messages_Bool_Exp>;
};


export type Subscription_RootMedia_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMedia_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Media_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Media_Messages_Bool_Exp>;
};


export type Subscription_RootMeetingsArgs = {
  distinct_on?: InputMaybe<Array<Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Meetings_Order_By>>;
  where?: InputMaybe<Meetings_Bool_Exp>;
};


export type Subscription_RootMeetings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Meetings_Order_By>>;
  where?: InputMaybe<Meetings_Bool_Exp>;
};


export type Subscription_RootMeetings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMeetings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Meetings_Stream_Cursor_Input>>;
  where?: InputMaybe<Meetings_Bool_Exp>;
};


export type Subscription_RootMessage_RecipientsArgs = {
  distinct_on?: InputMaybe<Array<Message_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Message_Recipients_Order_By>>;
  where?: InputMaybe<Message_Recipients_Bool_Exp>;
};


export type Subscription_RootMessage_Recipients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Message_Recipients_Order_By>>;
  where?: InputMaybe<Message_Recipients_Bool_Exp>;
};


export type Subscription_RootMessage_Recipients_By_PkArgs = {
  groupMemberId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};


export type Subscription_RootMessage_Recipients_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Message_Recipients_Stream_Cursor_Input>>;
  where?: InputMaybe<Message_Recipients_Bool_Exp>;
};


export type Subscription_RootMutoon_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


export type Subscription_RootMutoon_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Categories_Order_By>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


export type Subscription_RootMutoon_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMutoon_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mutoon_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Mutoon_Categories_Bool_Exp>;
};


export type Subscription_RootMutoon_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


export type Subscription_RootMutoon_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoon_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


export type Subscription_RootMutoon_Sections_By_PkArgs = {
  mutoonSectionId: Scalars['String']['input'];
};


export type Subscription_RootMutoon_Sections_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mutoon_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Mutoon_Sections_Bool_Exp>;
};


export type Subscription_RootMutoonsArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


export type Subscription_RootMutoons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mutoons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mutoons_Order_By>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


export type Subscription_RootMutoons_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMutoons_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mutoons_Stream_Cursor_Input>>;
  where?: InputMaybe<Mutoons_Bool_Exp>;
};


export type Subscription_RootNotesArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_By_PkArgs = {
  noteId: Scalars['String']['input'];
};


export type Subscription_RootNotes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notes_Stream_Cursor_Input>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootPayment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_By_PkArgs = {
  paymentMethodId: Scalars['String']['input'];
};


export type Subscription_RootPayment_Methods_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Payment_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPlan_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


export type Subscription_RootPlan_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


export type Subscription_RootPlan_Sections_By_PkArgs = {
  planSectionId: Scalars['String']['input'];
};


export type Subscription_RootPlan_Sections_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plan_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


export type Subscription_RootPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlans_By_PkArgs = {
  planId: Scalars['String']['input'];
};


export type Subscription_RootPlans_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlatform_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Platform_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Platform_Settings_Order_By>>;
  where?: InputMaybe<Platform_Settings_Bool_Exp>;
};


export type Subscription_RootPlatform_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Platform_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Platform_Settings_Order_By>>;
  where?: InputMaybe<Platform_Settings_Bool_Exp>;
};


export type Subscription_RootPlatform_Settings_By_PkArgs = {
  settingId: Scalars['String']['input'];
};


export type Subscription_RootPlatform_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Platform_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Platform_Settings_Bool_Exp>;
};


export type Subscription_RootPrerequisitesArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


export type Subscription_RootPrerequisites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prerequisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Prerequisites_Order_By>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


export type Subscription_RootPrerequisites_By_PkArgs = {
  prerequisiteId: Scalars['String']['input'];
};


export type Subscription_RootPrerequisites_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Prerequisites_Stream_Cursor_Input>>;
  where?: InputMaybe<Prerequisites_Bool_Exp>;
};


export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_By_PkArgs = {
  questionId: Scalars['String']['input'];
};


export type Subscription_RootQuestions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootRatings_And_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Ratings_And_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ratings_And_Reviews_Order_By>>;
  where?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
};


export type Subscription_RootRatings_And_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ratings_And_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ratings_And_Reviews_Order_By>>;
  where?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
};


export type Subscription_RootRatings_And_Reviews_By_PkArgs = {
  reviewId: Scalars['String']['input'];
};


export type Subscription_RootRatings_And_Reviews_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ratings_And_Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Ratings_And_Reviews_Bool_Exp>;
};


export type Subscription_RootReference_BooksArgs = {
  distinct_on?: InputMaybe<Array<Reference_Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reference_Books_Order_By>>;
  where?: InputMaybe<Reference_Books_Bool_Exp>;
};


export type Subscription_RootReference_Books_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reference_Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reference_Books_Order_By>>;
  where?: InputMaybe<Reference_Books_Bool_Exp>;
};


export type Subscription_RootReference_Books_By_PkArgs = {
  bookId: Scalars['String']['input'];
};


export type Subscription_RootReference_Books_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reference_Books_Stream_Cursor_Input>>;
  where?: InputMaybe<Reference_Books_Bool_Exp>;
};


export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  reportId: Scalars['String']['input'];
};


export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Subscription_RootRoles_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Actions_Order_By>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Subscription_RootRoles_Actions_By_PkArgs = {
  actionId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};


export type Subscription_RootRoles_Actions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Actions_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootScheduled_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scheduled_Notifications_Order_By>>;
  where?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
};


export type Subscription_RootScheduled_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scheduled_Notifications_Order_By>>;
  where?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
};


export type Subscription_RootScheduled_Notifications_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootScheduled_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scheduled_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Scheduled_Notifications_Bool_Exp>;
};


export type Subscription_RootSchool_UsersArgs = {
  distinct_on?: InputMaybe<Array<School_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<School_Users_Order_By>>;
  where?: InputMaybe<School_Users_Bool_Exp>;
};


export type Subscription_RootSchool_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<School_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<School_Users_Order_By>>;
  where?: InputMaybe<School_Users_Bool_Exp>;
};


export type Subscription_RootSchool_Users_By_PkArgs = {
  schoolUserId: Scalars['String']['input'];
};


export type Subscription_RootSchool_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<School_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<School_Users_Bool_Exp>;
};


export type Subscription_RootScientific_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scientific_Sessions_Order_By>>;
  where?: InputMaybe<Scientific_Sessions_Bool_Exp>;
};


export type Subscription_RootScientific_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scientific_Sessions_Order_By>>;
  where?: InputMaybe<Scientific_Sessions_Bool_Exp>;
};


export type Subscription_RootScientific_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootScientific_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scientific_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Scientific_Sessions_Bool_Exp>;
};


export type Subscription_RootSelf_RecitationsArgs = {
  distinct_on?: InputMaybe<Array<Self_Recitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Self_Recitations_Order_By>>;
  where?: InputMaybe<Self_Recitations_Bool_Exp>;
};


export type Subscription_RootSelf_Recitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Self_Recitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Self_Recitations_Order_By>>;
  where?: InputMaybe<Self_Recitations_Bool_Exp>;
};


export type Subscription_RootSelf_Recitations_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSelf_Recitations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Self_Recitations_Stream_Cursor_Input>>;
  where?: InputMaybe<Self_Recitations_Bool_Exp>;
};


export type Subscription_RootSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSoundsArgs = {
  distinct_on?: InputMaybe<Array<Sounds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sounds_Order_By>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


export type Subscription_RootSounds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sounds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sounds_Order_By>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


export type Subscription_RootSounds_By_PkArgs = {
  soundId: Scalars['String']['input'];
};


export type Subscription_RootSounds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sounds_Stream_Cursor_Input>>;
  where?: InputMaybe<Sounds_Bool_Exp>;
};


export type Subscription_RootStatisticsArgs = {
  distinct_on?: InputMaybe<Array<Statistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Statistics_Order_By>>;
  where?: InputMaybe<Statistics_Bool_Exp>;
};


export type Subscription_RootStatistics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Statistics_Order_By>>;
  where?: InputMaybe<Statistics_Bool_Exp>;
};


export type Subscription_RootStatistics_By_PkArgs = {
  statisticId: Scalars['String']['input'];
};


export type Subscription_RootStatistics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Statistics_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistics_Bool_Exp>;
};


export type Subscription_RootStudentsArgs = {
  distinct_on?: InputMaybe<Array<Students_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Students_Order_By>>;
  where?: InputMaybe<Students_Bool_Exp>;
};


export type Subscription_RootStudents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Students_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Students_Order_By>>;
  where?: InputMaybe<Students_Bool_Exp>;
};


export type Subscription_RootStudents_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStudents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Students_Stream_Cursor_Input>>;
  where?: InputMaybe<Students_Bool_Exp>;
};


export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootText_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Subscription_RootText_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Subscription_RootText_Messages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootText_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Text_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Subscription_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_By_PkArgs = {
  transactionId: Scalars['String']['input'];
};


export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootTranslations_By_PkArgs = {
  translationId: Scalars['String']['input'];
};


export type Subscription_RootTranslations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Translations_Stream_Cursor_Input>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootUser_BadgesArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Subscription_RootUser_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Subscription_RootUser_Badges_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Badges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


export type Subscription_RootUser_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


export type Subscription_RootUser_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Categories_Order_By>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


export type Subscription_RootUser_Categories_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};


export type Subscription_RootUser_CertificatesArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


export type Subscription_RootUser_Certificates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Certificates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Certificates_Order_By>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


export type Subscription_RootUser_Certificates_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Certificates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Certificates_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};


export type Subscription_RootUser_Credit_Card_InfosArgs = {
  distinct_on?: InputMaybe<Array<User_Credit_Card_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Credit_Card_Infos_Order_By>>;
  where?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
};


export type Subscription_RootUser_Credit_Card_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Credit_Card_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Credit_Card_Infos_Order_By>>;
  where?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
};


export type Subscription_RootUser_Credit_Card_Infos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Credit_Card_Infos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Credit_Card_Infos_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
};


export type Subscription_RootUser_Discussion_RoomsArgs = {
  distinct_on?: InputMaybe<Array<User_Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Discussion_Rooms_Order_By>>;
  where?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
};


export type Subscription_RootUser_Discussion_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Discussion_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Discussion_Rooms_Order_By>>;
  where?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
};


export type Subscription_RootUser_Discussion_Rooms_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Discussion_Rooms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Discussion_Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
};


export type Subscription_RootUser_ExamsArgs = {
  distinct_on?: InputMaybe<Array<User_Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Exams_Order_By>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};


export type Subscription_RootUser_Exams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Exams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Exams_Order_By>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};


export type Subscription_RootUser_Exams_By_PkArgs = {
  userExamId: Scalars['String']['input'];
};


export type Subscription_RootUser_Exams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Exams_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};


export type Subscription_RootUser_Hearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


export type Subscription_RootUser_Hearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


export type Subscription_RootUser_Hearing_Councils_By_PkArgs = {
  userHearingCouncilId: Scalars['String']['input'];
};


export type Subscription_RootUser_Hearing_Councils_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Hearing_Councils_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


export type Subscription_RootUser_MeetingsArgs = {
  distinct_on?: InputMaybe<Array<User_Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Meetings_Order_By>>;
  where?: InputMaybe<User_Meetings_Bool_Exp>;
};


export type Subscription_RootUser_Meetings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Meetings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Meetings_Order_By>>;
  where?: InputMaybe<User_Meetings_Bool_Exp>;
};


export type Subscription_RootUser_Meetings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Meetings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Meetings_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Meetings_Bool_Exp>;
};


export type Subscription_RootUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Preferences_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Preferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_QuizesArgs = {
  distinct_on?: InputMaybe<Array<User_Quizes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Quizes_Order_By>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};


export type Subscription_RootUser_Quizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Quizes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Quizes_Order_By>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};


export type Subscription_RootUser_Quizes_By_PkArgs = {
  userQuizId: Scalars['String']['input'];
};


export type Subscription_RootUser_Quizes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Quizes_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};


export type Subscription_RootUser_RecordsArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


export type Subscription_RootUser_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


export type Subscription_RootUser_Records_By_PkArgs = {
  recordingId: Scalars['String']['input'];
};


export type Subscription_RootUser_Records_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Records_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


export type Subscription_RootUser_Scientific_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Scientific_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Scientific_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Scientific_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Scientific_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


export type Subscription_RootUser_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<User_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Subscriptions_Order_By>>;
  where?: InputMaybe<User_Subscriptions_Bool_Exp>;
};


export type Subscription_RootUser_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Subscriptions_Order_By>>;
  where?: InputMaybe<User_Subscriptions_Bool_Exp>;
};


export type Subscription_RootUser_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Subscriptions_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  __typename?: 'subscriptions';
  amount: Scalars['float8']['output'];
  courseFileURL?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  expiryDate: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sheikhName: Scalars['String']['output'];
  subscriptionDate: Scalars['timestamp']['output'];
  subscriptionName: Scalars['String']['output'];
  userTransactionId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  __typename?: 'subscriptions_aggregate';
  aggregate?: Maybe<Subscriptions_Aggregate_Fields>;
  nodes: Array<Subscriptions>;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  __typename?: 'subscriptions_aggregate_fields';
  avg?: Maybe<Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Subscriptions_Max_Fields>;
  min?: Maybe<Subscriptions_Min_Fields>;
  stddev?: Maybe<Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<Subscriptions_Sum_Fields>;
  var_pop?: Maybe<Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<Subscriptions_Variance_Fields>;
};


/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Subscriptions_Avg_Fields = {
  __typename?: 'subscriptions_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  courseFileURL?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiryDate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sheikhName?: InputMaybe<String_Comparison_Exp>;
  subscriptionDate?: InputMaybe<Timestamp_Comparison_Exp>;
  subscriptionName?: InputMaybe<String_Comparison_Exp>;
  userTransactionId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscriptions" */
export enum Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = 'subscriptions_pkey'
}

/** input type for incrementing numeric columns in table "subscriptions" */
export type Subscriptions_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  subscriptionDate?: InputMaybe<Scalars['timestamp']['input']>;
  subscriptionName?: InputMaybe<Scalars['String']['input']>;
  userTransactionId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  __typename?: 'subscriptions_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  subscriptionDate?: Maybe<Scalars['timestamp']['output']>;
  subscriptionName?: Maybe<Scalars['String']['output']>;
  userTransactionId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  __typename?: 'subscriptions_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  courseFileURL?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sheikhName?: Maybe<Scalars['String']['output']>;
  subscriptionDate?: Maybe<Scalars['timestamp']['output']>;
  subscriptionName?: Maybe<Scalars['String']['output']>;
  userTransactionId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  __typename?: 'subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscriptions>;
};

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  constraint: Subscriptions_Constraint;
  update_columns?: Array<Subscriptions_Update_Column>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  amount?: InputMaybe<Order_By>;
  courseFileURL?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sheikhName?: InputMaybe<Order_By>;
  subscriptionDate?: InputMaybe<Order_By>;
  subscriptionName?: InputMaybe<Order_By>;
  userTransactionId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SheikhName = 'sheikhName',
  /** column name */
  SubscriptionDate = 'subscriptionDate',
  /** column name */
  SubscriptionName = 'subscriptionName',
  /** column name */
  UserTransactionId = 'userTransactionId'
}

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  subscriptionDate?: InputMaybe<Scalars['timestamp']['input']>;
  subscriptionName?: InputMaybe<Scalars['String']['input']>;
  userTransactionId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Subscriptions_Stddev_Fields = {
  __typename?: 'subscriptions_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Subscriptions_Stddev_Pop_Fields = {
  __typename?: 'subscriptions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Subscriptions_Stddev_Samp_Fields = {
  __typename?: 'subscriptions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  courseFileURL?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  subscriptionDate?: InputMaybe<Scalars['timestamp']['input']>;
  subscriptionName?: InputMaybe<Scalars['String']['input']>;
  userTransactionId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Subscriptions_Sum_Fields = {
  __typename?: 'subscriptions_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "subscriptions" */
export enum Subscriptions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CourseFileUrl = 'courseFileURL',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SheikhName = 'sheikhName',
  /** column name */
  SubscriptionDate = 'subscriptionDate',
  /** column name */
  SubscriptionName = 'subscriptionName',
  /** column name */
  UserTransactionId = 'userTransactionId'
}

export type Subscriptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscriptions_Var_Pop_Fields = {
  __typename?: 'subscriptions_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Subscriptions_Var_Samp_Fields = {
  __typename?: 'subscriptions_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Subscriptions_Variance_Fields = {
  __typename?: 'subscriptions_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "text_messages" */
export type Text_Messages = {
  __typename?: 'text_messages';
  content: Scalars['String']['output'];
  id: Scalars['String']['output'];
  messageId: Scalars['String']['output'];
};

/** aggregated selection of "text_messages" */
export type Text_Messages_Aggregate = {
  __typename?: 'text_messages_aggregate';
  aggregate?: Maybe<Text_Messages_Aggregate_Fields>;
  nodes: Array<Text_Messages>;
};

/** aggregate fields of "text_messages" */
export type Text_Messages_Aggregate_Fields = {
  __typename?: 'text_messages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Text_Messages_Max_Fields>;
  min?: Maybe<Text_Messages_Min_Fields>;
};


/** aggregate fields of "text_messages" */
export type Text_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "text_messages". All fields are combined with a logical 'AND'. */
export type Text_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Messages_Bool_Exp>>;
  _not?: InputMaybe<Text_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Messages_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  messageId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "text_messages" */
export enum Text_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  TextMessagesPkey = 'text_messages_pkey'
}

/** input type for inserting data into table "text_messages" */
export type Text_Messages_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Text_Messages_Max_Fields = {
  __typename?: 'text_messages_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Text_Messages_Min_Fields = {
  __typename?: 'text_messages_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "text_messages" */
export type Text_Messages_Mutation_Response = {
  __typename?: 'text_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Messages>;
};

/** on_conflict condition type for table "text_messages" */
export type Text_Messages_On_Conflict = {
  constraint: Text_Messages_Constraint;
  update_columns?: Array<Text_Messages_Update_Column>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "text_messages". */
export type Text_Messages_Order_By = {
  content?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messageId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: text_messages */
export type Text_Messages_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "text_messages" */
export enum Text_Messages_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId'
}

/** input type for updating data in table "text_messages" */
export type Text_Messages_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "text_messages" */
export type Text_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Messages_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "text_messages" */
export enum Text_Messages_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId'
}

export type Text_Messages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Messages_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  __typename?: 'transactions';
  amount: Scalars['float8']['output'];
  donationId: Scalars['String']['output'];
  paymentMethodId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  transactionDate: Scalars['timestamp']['output'];
  transactionId: Scalars['String']['output'];
  transactionStatus: Scalars['TransactionStatus']['output'];
  userCreditCardId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  __typename?: 'transactions_aggregate_fields';
  avg?: Maybe<Transactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
  stddev?: Maybe<Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Sum_Fields>;
  var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Variance_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  __typename?: 'transactions_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Transactions_Bool_Exp>>;
  _not?: InputMaybe<Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Transactions_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  donationId?: InputMaybe<String_Comparison_Exp>;
  paymentMethodId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transactionDate?: InputMaybe<Timestamp_Comparison_Exp>;
  transactionId?: InputMaybe<String_Comparison_Exp>;
  transactionStatus?: InputMaybe<TransactionStatus_Comparison_Exp>;
  userCreditCardId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "transactionId" */
  TransactionsPkey = 'transactions_pkey'
}

/** input type for incrementing numeric columns in table "transactions" */
export type Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transactionDate?: InputMaybe<Scalars['timestamp']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  transactionStatus?: InputMaybe<Scalars['TransactionStatus']['input']>;
  userCreditCardId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  __typename?: 'transactions_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionDate?: Maybe<Scalars['timestamp']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['TransactionStatus']['output']>;
  userCreditCardId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  __typename?: 'transactions_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionDate?: Maybe<Scalars['timestamp']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['TransactionStatus']['output']>;
  userCreditCardId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint;
  update_columns?: Array<Transactions_Update_Column>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  amount?: InputMaybe<Order_By>;
  donationId?: InputMaybe<Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transactionDate?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  transactionStatus?: InputMaybe<Order_By>;
  userCreditCardId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  transactionId: Scalars['String']['input'];
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  DonationId = 'donationId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDate = 'transactionDate',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  TransactionStatus = 'transactionStatus',
  /** column name */
  UserCreditCardId = 'userCreditCardId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transactionDate?: InputMaybe<Scalars['timestamp']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  transactionStatus?: InputMaybe<Scalars['TransactionStatus']['input']>;
  userCreditCardId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  __typename?: 'transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  __typename?: 'transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  __typename?: 'transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transactionDate?: InputMaybe<Scalars['timestamp']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  transactionStatus?: InputMaybe<Scalars['TransactionStatus']['input']>;
  userCreditCardId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  __typename?: 'transactions_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  DonationId = 'donationId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDate = 'transactionDate',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  TransactionStatus = 'transactionStatus',
  /** column name */
  UserCreditCardId = 'userCreditCardId',
  /** column name */
  UserId = 'userId'
}

export type Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  __typename?: 'transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  __typename?: 'transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  __typename?: 'transactions_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "translations" */
export type Translations = {
  __typename?: 'translations';
  /** An object relationship */
  mutoonSection: Mutoon_Sections;
  mutoonSectionId: Scalars['String']['output'];
  translatedText: Scalars['jsonb']['output'];
  translationId: Scalars['String']['output'];
  word: Scalars['jsonb']['output'];
};


/** columns and relationships of "translations" */
export type TranslationsTranslatedTextArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "translations" */
export type TranslationsWordArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "translations" */
export type Translations_Aggregate = {
  __typename?: 'translations_aggregate';
  aggregate?: Maybe<Translations_Aggregate_Fields>;
  nodes: Array<Translations>;
};

export type Translations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Translations_Aggregate_Bool_Exp_Count>;
};

export type Translations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Translations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "translations" */
export type Translations_Aggregate_Fields = {
  __typename?: 'translations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Translations_Max_Fields>;
  min?: Maybe<Translations_Min_Fields>;
};


/** aggregate fields of "translations" */
export type Translations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "translations" */
export type Translations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Translations_Max_Order_By>;
  min?: InputMaybe<Translations_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Translations_Append_Input = {
  translatedText?: InputMaybe<Scalars['jsonb']['input']>;
  word?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "translations" */
export type Translations_Arr_Rel_Insert_Input = {
  data: Array<Translations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "translations". All fields are combined with a logical 'AND'. */
export type Translations_Bool_Exp = {
  _and?: InputMaybe<Array<Translations_Bool_Exp>>;
  _not?: InputMaybe<Translations_Bool_Exp>;
  _or?: InputMaybe<Array<Translations_Bool_Exp>>;
  mutoonSection?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  mutoonSectionId?: InputMaybe<String_Comparison_Exp>;
  translatedText?: InputMaybe<Jsonb_Comparison_Exp>;
  translationId?: InputMaybe<String_Comparison_Exp>;
  word?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "translations" */
export enum Translations_Constraint {
  /** unique or primary key constraint on columns "translationId" */
  TranslationsPkey = 'translations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Translations_Delete_At_Path_Input = {
  translatedText?: InputMaybe<Array<Scalars['String']['input']>>;
  word?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Translations_Delete_Elem_Input = {
  translatedText?: InputMaybe<Scalars['Int']['input']>;
  word?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Translations_Delete_Key_Input = {
  translatedText?: InputMaybe<Scalars['String']['input']>;
  word?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "translations" */
export type Translations_Insert_Input = {
  mutoonSection?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['jsonb']['input']>;
  translationId?: InputMaybe<Scalars['String']['input']>;
  word?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Translations_Max_Fields = {
  __typename?: 'translations_max_fields';
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  translationId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "translations" */
export type Translations_Max_Order_By = {
  mutoonSectionId?: InputMaybe<Order_By>;
  translationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Translations_Min_Fields = {
  __typename?: 'translations_min_fields';
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  translationId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "translations" */
export type Translations_Min_Order_By = {
  mutoonSectionId?: InputMaybe<Order_By>;
  translationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "translations" */
export type Translations_Mutation_Response = {
  __typename?: 'translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Translations>;
};

/** on_conflict condition type for table "translations" */
export type Translations_On_Conflict = {
  constraint: Translations_Constraint;
  update_columns?: Array<Translations_Update_Column>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** Ordering options when selecting data from "translations". */
export type Translations_Order_By = {
  mutoonSection?: InputMaybe<Mutoon_Sections_Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  translatedText?: InputMaybe<Order_By>;
  translationId?: InputMaybe<Order_By>;
  word?: InputMaybe<Order_By>;
};

/** primary key columns input for table: translations */
export type Translations_Pk_Columns_Input = {
  translationId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Translations_Prepend_Input = {
  translatedText?: InputMaybe<Scalars['jsonb']['input']>;
  word?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "translations" */
export enum Translations_Select_Column {
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  TranslatedText = 'translatedText',
  /** column name */
  TranslationId = 'translationId',
  /** column name */
  Word = 'word'
}

/** input type for updating data in table "translations" */
export type Translations_Set_Input = {
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['jsonb']['input']>;
  translationId?: InputMaybe<Scalars['String']['input']>;
  word?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "translations" */
export type Translations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Translations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Translations_Stream_Cursor_Value_Input = {
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['jsonb']['input']>;
  translationId?: InputMaybe<Scalars['String']['input']>;
  word?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "translations" */
export enum Translations_Update_Column {
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  TranslatedText = 'translatedText',
  /** column name */
  TranslationId = 'translationId',
  /** column name */
  Word = 'word'
}

export type Translations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Translations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Translations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Translations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Translations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Translations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Translations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Translations_Bool_Exp;
};

/** Boolean expression to compare columns of type "userRole". All fields are combined with logical 'AND'. */
export type UserRole_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['userRole']['input']>;
  _gt?: InputMaybe<Scalars['userRole']['input']>;
  _gte?: InputMaybe<Scalars['userRole']['input']>;
  _in?: InputMaybe<Array<Scalars['userRole']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['userRole']['input']>;
  _lte?: InputMaybe<Scalars['userRole']['input']>;
  _neq?: InputMaybe<Scalars['userRole']['input']>;
  _nin?: InputMaybe<Array<Scalars['userRole']['input']>>;
};

/** columns and relationships of "user_badges" */
export type User_Badges = {
  __typename?: 'user_badges';
  /** An object relationship */
  badge: Badges;
  badgeId: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  dateEarned?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['String']['output'];
  isClaimed: Scalars['Boolean']['output'];
  isEarned: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_badges" */
export type User_Badges_Aggregate = {
  __typename?: 'user_badges_aggregate';
  aggregate?: Maybe<User_Badges_Aggregate_Fields>;
  nodes: Array<User_Badges>;
};

export type User_Badges_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Badges_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Badges_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Badges_Aggregate_Bool_Exp_Count>;
};

export type User_Badges_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Badges_Select_Column_User_Badges_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Badges_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Badges_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Badges_Select_Column_User_Badges_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Badges_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Badges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Badges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_badges" */
export type User_Badges_Aggregate_Fields = {
  __typename?: 'user_badges_aggregate_fields';
  avg?: Maybe<User_Badges_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Badges_Max_Fields>;
  min?: Maybe<User_Badges_Min_Fields>;
  stddev?: Maybe<User_Badges_Stddev_Fields>;
  stddev_pop?: Maybe<User_Badges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Badges_Stddev_Samp_Fields>;
  sum?: Maybe<User_Badges_Sum_Fields>;
  var_pop?: Maybe<User_Badges_Var_Pop_Fields>;
  var_samp?: Maybe<User_Badges_Var_Samp_Fields>;
  variance?: Maybe<User_Badges_Variance_Fields>;
};


/** aggregate fields of "user_badges" */
export type User_Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_badges" */
export type User_Badges_Aggregate_Order_By = {
  avg?: InputMaybe<User_Badges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Badges_Max_Order_By>;
  min?: InputMaybe<User_Badges_Min_Order_By>;
  stddev?: InputMaybe<User_Badges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Badges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Badges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Badges_Sum_Order_By>;
  var_pop?: InputMaybe<User_Badges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Badges_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Badges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_badges" */
export type User_Badges_Arr_Rel_Insert_Input = {
  data: Array<User_Badges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Badges_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Badges_Avg_Fields = {
  __typename?: 'user_badges_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_badges" */
export type User_Badges_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_badges". All fields are combined with a logical 'AND'. */
export type User_Badges_Bool_Exp = {
  _and?: InputMaybe<Array<User_Badges_Bool_Exp>>;
  _not?: InputMaybe<User_Badges_Bool_Exp>;
  _or?: InputMaybe<Array<User_Badges_Bool_Exp>>;
  badge?: InputMaybe<Badges_Bool_Exp>;
  badgeId?: InputMaybe<String_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dateEarned?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isClaimed?: InputMaybe<Boolean_Comparison_Exp>;
  isEarned?: InputMaybe<Boolean_Comparison_Exp>;
  total?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_badges" */
export enum User_Badges_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserBadgesPkey = 'user_badges_pkey',
  /** unique or primary key constraint on columns "userId", "badgeId" */
  UserBadgesUserIdBadgeIdKey = 'user_badges_userId_badgeId_key'
}

/** input type for incrementing numeric columns in table "user_badges" */
export type User_Badges_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_badges" */
export type User_Badges_Insert_Input = {
  badge?: InputMaybe<Badges_Obj_Rel_Insert_Input>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateEarned?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  isEarned?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Badges_Max_Fields = {
  __typename?: 'user_badges_max_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dateEarned?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_badges" */
export type User_Badges_Max_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateEarned?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Badges_Min_Fields = {
  __typename?: 'user_badges_min_fields';
  badgeId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dateEarned?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_badges" */
export type User_Badges_Min_Order_By = {
  badgeId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateEarned?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_badges" */
export type User_Badges_Mutation_Response = {
  __typename?: 'user_badges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Badges>;
};

/** on_conflict condition type for table "user_badges" */
export type User_Badges_On_Conflict = {
  constraint: User_Badges_Constraint;
  update_columns?: Array<User_Badges_Update_Column>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "user_badges". */
export type User_Badges_Order_By = {
  badge?: InputMaybe<Badges_Order_By>;
  badgeId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateEarned?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isClaimed?: InputMaybe<Order_By>;
  isEarned?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_badges */
export type User_Badges_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_badges" */
export enum User_Badges_Select_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateEarned = 'dateEarned',
  /** column name */
  Id = 'id',
  /** column name */
  IsClaimed = 'isClaimed',
  /** column name */
  IsEarned = 'isEarned',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** select "user_badges_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_badges" */
export enum User_Badges_Select_Column_User_Badges_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsClaimed = 'isClaimed',
  /** column name */
  IsEarned = 'isEarned'
}

/** select "user_badges_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_badges" */
export enum User_Badges_Select_Column_User_Badges_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsClaimed = 'isClaimed',
  /** column name */
  IsEarned = 'isEarned'
}

/** input type for updating data in table "user_badges" */
export type User_Badges_Set_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateEarned?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  isEarned?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Badges_Stddev_Fields = {
  __typename?: 'user_badges_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_badges" */
export type User_Badges_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Badges_Stddev_Pop_Fields = {
  __typename?: 'user_badges_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_badges" */
export type User_Badges_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Badges_Stddev_Samp_Fields = {
  __typename?: 'user_badges_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_badges" */
export type User_Badges_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_badges" */
export type User_Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Badges_Stream_Cursor_Value_Input = {
  badgeId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateEarned?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isClaimed?: InputMaybe<Scalars['Boolean']['input']>;
  isEarned?: InputMaybe<Scalars['Boolean']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Badges_Sum_Fields = {
  __typename?: 'user_badges_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_badges" */
export type User_Badges_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** update columns of table "user_badges" */
export enum User_Badges_Update_Column {
  /** column name */
  BadgeId = 'badgeId',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateEarned = 'dateEarned',
  /** column name */
  Id = 'id',
  /** column name */
  IsClaimed = 'isClaimed',
  /** column name */
  IsEarned = 'isEarned',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Badges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Badges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Badges_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Badges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Badges_Var_Pop_Fields = {
  __typename?: 'user_badges_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_badges" */
export type User_Badges_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Badges_Var_Samp_Fields = {
  __typename?: 'user_badges_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_badges" */
export type User_Badges_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Badges_Variance_Fields = {
  __typename?: 'user_badges_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_badges" */
export type User_Badges_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_categories" */
export type User_Categories = {
  __typename?: 'user_categories';
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  authorized_user?: Maybe<Authorized_Users>;
  /** An object relationship */
  category: Categories;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_categories" */
export type User_Categories_Aggregate = {
  __typename?: 'user_categories_aggregate';
  aggregate?: Maybe<User_Categories_Aggregate_Fields>;
  nodes: Array<User_Categories>;
};

export type User_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Categories_Aggregate_Bool_Exp_Count>;
};

export type User_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_categories" */
export type User_Categories_Aggregate_Fields = {
  __typename?: 'user_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Categories_Max_Fields>;
  min?: Maybe<User_Categories_Min_Fields>;
};


/** aggregate fields of "user_categories" */
export type User_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_categories" */
export type User_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Categories_Max_Order_By>;
  min?: InputMaybe<User_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_categories" */
export type User_Categories_Arr_Rel_Insert_Input = {
  data: Array<User_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_categories". All fields are combined with a logical 'AND'. */
export type User_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<User_Categories_Bool_Exp>>;
  _not?: InputMaybe<User_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<User_Categories_Bool_Exp>>;
  authorizedUserId?: InputMaybe<String_Comparison_Exp>;
  authorized_user?: InputMaybe<Authorized_Users_Bool_Exp>;
  category?: InputMaybe<Categories_Bool_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_categories" */
export enum User_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCategoriesPkey = 'user_categories_pkey'
}

/** input type for inserting data into table "user_categories" */
export type User_Categories_Insert_Input = {
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  authorized_user?: InputMaybe<Authorized_Users_Obj_Rel_Insert_Input>;
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Categories_Max_Fields = {
  __typename?: 'user_categories_max_fields';
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_categories" */
export type User_Categories_Max_Order_By = {
  authorizedUserId?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Categories_Min_Fields = {
  __typename?: 'user_categories_min_fields';
  authorizedUserId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_categories" */
export type User_Categories_Min_Order_By = {
  authorizedUserId?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_categories" */
export type User_Categories_Mutation_Response = {
  __typename?: 'user_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Categories>;
};

/** on_conflict condition type for table "user_categories" */
export type User_Categories_On_Conflict = {
  constraint: User_Categories_Constraint;
  update_columns?: Array<User_Categories_Update_Column>;
  where?: InputMaybe<User_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "user_categories". */
export type User_Categories_Order_By = {
  authorizedUserId?: InputMaybe<Order_By>;
  authorized_user?: InputMaybe<Authorized_Users_Order_By>;
  category?: InputMaybe<Categories_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_categories */
export type User_Categories_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_categories" */
export enum User_Categories_Select_Column {
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_categories" */
export type User_Categories_Set_Input = {
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_categories" */
export type User_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Categories_Stream_Cursor_Value_Input = {
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_categories" */
export enum User_Categories_Update_Column {
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Categories_Bool_Exp;
};

/** columns and relationships of "user_certificates" */
export type User_Certificates = {
  __typename?: 'user_certificates';
  /** An object relationship */
  certificate: Certificates;
  certificateId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  credsId?: Maybe<Scalars['String']['output']>;
  customName?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  downloaded: Scalars['Boolean']['output'];
  examSubmissionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** An object relationship */
  mutoon: Mutoons;
  mutoonId: Scalars['String']['output'];
  /** An object relationship */
  student?: Maybe<Students>;
  studentId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  userExam?: Maybe<User_Exams>;
  /** An object relationship */
  userHearingCouncil?: Maybe<User_Hearing_Councils>;
  userHearingCouncilId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "user_certificates" */
export type User_Certificates_Aggregate = {
  __typename?: 'user_certificates_aggregate';
  aggregate?: Maybe<User_Certificates_Aggregate_Fields>;
  nodes: Array<User_Certificates>;
};

export type User_Certificates_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Certificates_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Certificates_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Certificates_Aggregate_Bool_Exp_Count>;
};

export type User_Certificates_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Certificates_Select_Column_User_Certificates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Certificates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Certificates_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Certificates_Select_Column_User_Certificates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Certificates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Certificates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Certificates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Certificates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_certificates" */
export type User_Certificates_Aggregate_Fields = {
  __typename?: 'user_certificates_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Certificates_Max_Fields>;
  min?: Maybe<User_Certificates_Min_Fields>;
};


/** aggregate fields of "user_certificates" */
export type User_Certificates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Certificates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_certificates" */
export type User_Certificates_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Certificates_Max_Order_By>;
  min?: InputMaybe<User_Certificates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_certificates" */
export type User_Certificates_Arr_Rel_Insert_Input = {
  data: Array<User_Certificates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Certificates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_certificates". All fields are combined with a logical 'AND'. */
export type User_Certificates_Bool_Exp = {
  _and?: InputMaybe<Array<User_Certificates_Bool_Exp>>;
  _not?: InputMaybe<User_Certificates_Bool_Exp>;
  _or?: InputMaybe<Array<User_Certificates_Bool_Exp>>;
  certificate?: InputMaybe<Certificates_Bool_Exp>;
  certificateId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credsId?: InputMaybe<String_Comparison_Exp>;
  customName?: InputMaybe<String_Comparison_Exp>;
  downloadPath?: InputMaybe<String_Comparison_Exp>;
  downloaded?: InputMaybe<Boolean_Comparison_Exp>;
  examSubmissionId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  mutoon?: InputMaybe<Mutoons_Bool_Exp>;
  mutoonId?: InputMaybe<String_Comparison_Exp>;
  student?: InputMaybe<Students_Bool_Exp>;
  studentId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userExam?: InputMaybe<User_Exams_Bool_Exp>;
  userHearingCouncil?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  userHearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_certificates" */
export enum User_Certificates_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCertificatesPkey = 'user_certificates_pkey'
}

/** input type for inserting data into table "user_certificates" */
export type User_Certificates_Insert_Input = {
  certificate?: InputMaybe<Certificates_Obj_Rel_Insert_Input>;
  certificateId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  credsId?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  downloadPath?: InputMaybe<Scalars['String']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  examSubmissionId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoon?: InputMaybe<Mutoons_Obj_Rel_Insert_Input>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  student?: InputMaybe<Students_Obj_Rel_Insert_Input>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userExam?: InputMaybe<User_Exams_Obj_Rel_Insert_Input>;
  userHearingCouncil?: InputMaybe<User_Hearing_Councils_Obj_Rel_Insert_Input>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Certificates_Max_Fields = {
  __typename?: 'user_certificates_max_fields';
  certificateId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  credsId?: Maybe<Scalars['String']['output']>;
  customName?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  examSubmissionId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userHearingCouncilId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_certificates" */
export type User_Certificates_Max_Order_By = {
  certificateId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credsId?: InputMaybe<Order_By>;
  customName?: InputMaybe<Order_By>;
  downloadPath?: InputMaybe<Order_By>;
  examSubmissionId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoonId?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userHearingCouncilId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Certificates_Min_Fields = {
  __typename?: 'user_certificates_min_fields';
  certificateId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  credsId?: Maybe<Scalars['String']['output']>;
  customName?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  examSubmissionId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mutoonId?: Maybe<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userHearingCouncilId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_certificates" */
export type User_Certificates_Min_Order_By = {
  certificateId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credsId?: InputMaybe<Order_By>;
  customName?: InputMaybe<Order_By>;
  downloadPath?: InputMaybe<Order_By>;
  examSubmissionId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoonId?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userHearingCouncilId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_certificates" */
export type User_Certificates_Mutation_Response = {
  __typename?: 'user_certificates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Certificates>;
};

/** input type for inserting object relation for remote table "user_certificates" */
export type User_Certificates_Obj_Rel_Insert_Input = {
  data: User_Certificates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Certificates_On_Conflict>;
};

/** on_conflict condition type for table "user_certificates" */
export type User_Certificates_On_Conflict = {
  constraint: User_Certificates_Constraint;
  update_columns?: Array<User_Certificates_Update_Column>;
  where?: InputMaybe<User_Certificates_Bool_Exp>;
};

/** Ordering options when selecting data from "user_certificates". */
export type User_Certificates_Order_By = {
  certificate?: InputMaybe<Certificates_Order_By>;
  certificateId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credsId?: InputMaybe<Order_By>;
  customName?: InputMaybe<Order_By>;
  downloadPath?: InputMaybe<Order_By>;
  downloaded?: InputMaybe<Order_By>;
  examSubmissionId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mutoon?: InputMaybe<Mutoons_Order_By>;
  mutoonId?: InputMaybe<Order_By>;
  student?: InputMaybe<Students_Order_By>;
  studentId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userExam?: InputMaybe<User_Exams_Order_By>;
  userHearingCouncil?: InputMaybe<User_Hearing_Councils_Order_By>;
  userHearingCouncilId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_certificates */
export type User_Certificates_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_certificates" */
export enum User_Certificates_Select_Column {
  /** column name */
  CertificateId = 'certificateId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredsId = 'credsId',
  /** column name */
  CustomName = 'customName',
  /** column name */
  DownloadPath = 'downloadPath',
  /** column name */
  Downloaded = 'downloaded',
  /** column name */
  ExamSubmissionId = 'examSubmissionId',
  /** column name */
  Id = 'id',
  /** column name */
  MutoonId = 'mutoonId',
  /** column name */
  StudentId = 'studentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserHearingCouncilId = 'userHearingCouncilId',
  /** column name */
  UserId = 'userId'
}

/** select "user_certificates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_certificates" */
export enum User_Certificates_Select_Column_User_Certificates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Downloaded = 'downloaded'
}

/** select "user_certificates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_certificates" */
export enum User_Certificates_Select_Column_User_Certificates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Downloaded = 'downloaded'
}

/** input type for updating data in table "user_certificates" */
export type User_Certificates_Set_Input = {
  certificateId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  credsId?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  downloadPath?: InputMaybe<Scalars['String']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  examSubmissionId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_certificates" */
export type User_Certificates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Certificates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Certificates_Stream_Cursor_Value_Input = {
  certificateId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  credsId?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  downloadPath?: InputMaybe<Scalars['String']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  examSubmissionId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_certificates" */
export enum User_Certificates_Update_Column {
  /** column name */
  CertificateId = 'certificateId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CredsId = 'credsId',
  /** column name */
  CustomName = 'customName',
  /** column name */
  DownloadPath = 'downloadPath',
  /** column name */
  Downloaded = 'downloaded',
  /** column name */
  ExamSubmissionId = 'examSubmissionId',
  /** column name */
  Id = 'id',
  /** column name */
  MutoonId = 'mutoonId',
  /** column name */
  StudentId = 'studentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserHearingCouncilId = 'userHearingCouncilId',
  /** column name */
  UserId = 'userId'
}

export type User_Certificates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Certificates_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Certificates_Bool_Exp;
};

/** columns and relationships of "user_credit_card_infos" */
export type User_Credit_Card_Infos = {
  __typename?: 'user_credit_card_infos';
  cardHolderName: Scalars['String']['output'];
  cardNumber: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  cvv: Scalars['String']['output'];
  expiryDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_credit_card_infos" */
export type User_Credit_Card_Infos_Aggregate = {
  __typename?: 'user_credit_card_infos_aggregate';
  aggregate?: Maybe<User_Credit_Card_Infos_Aggregate_Fields>;
  nodes: Array<User_Credit_Card_Infos>;
};

/** aggregate fields of "user_credit_card_infos" */
export type User_Credit_Card_Infos_Aggregate_Fields = {
  __typename?: 'user_credit_card_infos_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Credit_Card_Infos_Max_Fields>;
  min?: Maybe<User_Credit_Card_Infos_Min_Fields>;
};


/** aggregate fields of "user_credit_card_infos" */
export type User_Credit_Card_Infos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Credit_Card_Infos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_credit_card_infos". All fields are combined with a logical 'AND'. */
export type User_Credit_Card_Infos_Bool_Exp = {
  _and?: InputMaybe<Array<User_Credit_Card_Infos_Bool_Exp>>;
  _not?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
  _or?: InputMaybe<Array<User_Credit_Card_Infos_Bool_Exp>>;
  cardHolderName?: InputMaybe<String_Comparison_Exp>;
  cardNumber?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  cvv?: InputMaybe<String_Comparison_Exp>;
  expiryDate?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_credit_card_infos" */
export enum User_Credit_Card_Infos_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCreditCardInfosPkey = 'user_credit_card_infos_pkey'
}

/** input type for inserting data into table "user_credit_card_infos" */
export type User_Credit_Card_Infos_Insert_Input = {
  cardHolderName?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  cvv?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Credit_Card_Infos_Max_Fields = {
  __typename?: 'user_credit_card_infos_max_fields';
  cardHolderName?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  cvv?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Credit_Card_Infos_Min_Fields = {
  __typename?: 'user_credit_card_infos_min_fields';
  cardHolderName?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  cvv?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_credit_card_infos" */
export type User_Credit_Card_Infos_Mutation_Response = {
  __typename?: 'user_credit_card_infos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Credit_Card_Infos>;
};

/** on_conflict condition type for table "user_credit_card_infos" */
export type User_Credit_Card_Infos_On_Conflict = {
  constraint: User_Credit_Card_Infos_Constraint;
  update_columns?: Array<User_Credit_Card_Infos_Update_Column>;
  where?: InputMaybe<User_Credit_Card_Infos_Bool_Exp>;
};

/** Ordering options when selecting data from "user_credit_card_infos". */
export type User_Credit_Card_Infos_Order_By = {
  cardHolderName?: InputMaybe<Order_By>;
  cardNumber?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cvv?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_credit_card_infos */
export type User_Credit_Card_Infos_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_credit_card_infos" */
export enum User_Credit_Card_Infos_Select_Column {
  /** column name */
  CardHolderName = 'cardHolderName',
  /** column name */
  CardNumber = 'cardNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cvv = 'cvv',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_credit_card_infos" */
export type User_Credit_Card_Infos_Set_Input = {
  cardHolderName?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  cvv?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_credit_card_infos" */
export type User_Credit_Card_Infos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Credit_Card_Infos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Credit_Card_Infos_Stream_Cursor_Value_Input = {
  cardHolderName?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  cvv?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_credit_card_infos" */
export enum User_Credit_Card_Infos_Update_Column {
  /** column name */
  CardHolderName = 'cardHolderName',
  /** column name */
  CardNumber = 'cardNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cvv = 'cvv',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Credit_Card_Infos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Credit_Card_Infos_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Credit_Card_Infos_Bool_Exp;
};

/** columns and relationships of "user_discussion_rooms" */
export type User_Discussion_Rooms = {
  __typename?: 'user_discussion_rooms';
  discussionRoom: Scalars['String']['output'];
  id: Scalars['String']['output'];
  role: Scalars['userRole']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_discussion_rooms" */
export type User_Discussion_Rooms_Aggregate = {
  __typename?: 'user_discussion_rooms_aggregate';
  aggregate?: Maybe<User_Discussion_Rooms_Aggregate_Fields>;
  nodes: Array<User_Discussion_Rooms>;
};

/** aggregate fields of "user_discussion_rooms" */
export type User_Discussion_Rooms_Aggregate_Fields = {
  __typename?: 'user_discussion_rooms_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Discussion_Rooms_Max_Fields>;
  min?: Maybe<User_Discussion_Rooms_Min_Fields>;
};


/** aggregate fields of "user_discussion_rooms" */
export type User_Discussion_Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Discussion_Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_discussion_rooms". All fields are combined with a logical 'AND'. */
export type User_Discussion_Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<User_Discussion_Rooms_Bool_Exp>>;
  _not?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<User_Discussion_Rooms_Bool_Exp>>;
  discussionRoom?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<UserRole_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_discussion_rooms" */
export enum User_Discussion_Rooms_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserDiscussionRoomsPkey = 'user_discussion_rooms_pkey'
}

/** input type for inserting data into table "user_discussion_rooms" */
export type User_Discussion_Rooms_Insert_Input = {
  discussionRoom?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Discussion_Rooms_Max_Fields = {
  __typename?: 'user_discussion_rooms_max_fields';
  discussionRoom?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Discussion_Rooms_Min_Fields = {
  __typename?: 'user_discussion_rooms_min_fields';
  discussionRoom?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_discussion_rooms" */
export type User_Discussion_Rooms_Mutation_Response = {
  __typename?: 'user_discussion_rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Discussion_Rooms>;
};

/** on_conflict condition type for table "user_discussion_rooms" */
export type User_Discussion_Rooms_On_Conflict = {
  constraint: User_Discussion_Rooms_Constraint;
  update_columns?: Array<User_Discussion_Rooms_Update_Column>;
  where?: InputMaybe<User_Discussion_Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "user_discussion_rooms". */
export type User_Discussion_Rooms_Order_By = {
  discussionRoom?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_discussion_rooms */
export type User_Discussion_Rooms_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_discussion_rooms" */
export enum User_Discussion_Rooms_Select_Column {
  /** column name */
  DiscussionRoom = 'discussionRoom',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_discussion_rooms" */
export type User_Discussion_Rooms_Set_Input = {
  discussionRoom?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_discussion_rooms" */
export type User_Discussion_Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Discussion_Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Discussion_Rooms_Stream_Cursor_Value_Input = {
  discussionRoom?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_discussion_rooms" */
export enum User_Discussion_Rooms_Update_Column {
  /** column name */
  DiscussionRoom = 'discussionRoom',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export type User_Discussion_Rooms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Discussion_Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Discussion_Rooms_Bool_Exp;
};

/** columns and relationships of "user_exams" */
export type User_Exams = {
  __typename?: 'user_exams';
  answersCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  endTime?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  exam: Exams;
  examId: Scalars['String']['output'];
  questionsCount?: Maybe<Scalars['Int']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  startTime: Scalars['timestamptz']['output'];
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userCertificateId?: Maybe<Scalars['String']['output']>;
  userExamId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  /** An object relationship */
  user_certificate?: Maybe<User_Certificates>;
};

/** aggregated selection of "user_exams" */
export type User_Exams_Aggregate = {
  __typename?: 'user_exams_aggregate';
  aggregate?: Maybe<User_Exams_Aggregate_Fields>;
  nodes: Array<User_Exams>;
};

export type User_Exams_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Exams_Aggregate_Bool_Exp_Count>;
};

export type User_Exams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Exams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Exams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_exams" */
export type User_Exams_Aggregate_Fields = {
  __typename?: 'user_exams_aggregate_fields';
  avg?: Maybe<User_Exams_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Exams_Max_Fields>;
  min?: Maybe<User_Exams_Min_Fields>;
  stddev?: Maybe<User_Exams_Stddev_Fields>;
  stddev_pop?: Maybe<User_Exams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Exams_Stddev_Samp_Fields>;
  sum?: Maybe<User_Exams_Sum_Fields>;
  var_pop?: Maybe<User_Exams_Var_Pop_Fields>;
  var_samp?: Maybe<User_Exams_Var_Samp_Fields>;
  variance?: Maybe<User_Exams_Variance_Fields>;
};


/** aggregate fields of "user_exams" */
export type User_Exams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Exams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_exams" */
export type User_Exams_Aggregate_Order_By = {
  avg?: InputMaybe<User_Exams_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Exams_Max_Order_By>;
  min?: InputMaybe<User_Exams_Min_Order_By>;
  stddev?: InputMaybe<User_Exams_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Exams_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Exams_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Exams_Sum_Order_By>;
  var_pop?: InputMaybe<User_Exams_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Exams_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Exams_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_exams" */
export type User_Exams_Arr_Rel_Insert_Input = {
  data: Array<User_Exams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Exams_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Exams_Avg_Fields = {
  __typename?: 'user_exams_avg_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_exams" */
export type User_Exams_Avg_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_exams". All fields are combined with a logical 'AND'. */
export type User_Exams_Bool_Exp = {
  _and?: InputMaybe<Array<User_Exams_Bool_Exp>>;
  _not?: InputMaybe<User_Exams_Bool_Exp>;
  _or?: InputMaybe<Array<User_Exams_Bool_Exp>>;
  answersCount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  exam?: InputMaybe<Exams_Bool_Exp>;
  examId?: InputMaybe<String_Comparison_Exp>;
  questionsCount?: InputMaybe<Int_Comparison_Exp>;
  result?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  startTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  totalCorrectAnswers?: InputMaybe<Int_Comparison_Exp>;
  totalCountOFSubmitedquestions?: InputMaybe<Int_Comparison_Exp>;
  totalPoints?: InputMaybe<Int_Comparison_Exp>;
  totalWrongAnswers?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userCertificateId?: InputMaybe<String_Comparison_Exp>;
  userExamId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_certificate?: InputMaybe<User_Certificates_Bool_Exp>;
};

/** unique or primary key constraints on table "user_exams" */
export enum User_Exams_Constraint {
  /** unique or primary key constraint on columns "userExamId" */
  UserExamsPkey = 'user_exams_pkey',
  /** unique or primary key constraint on columns "userCertificateId" */
  UserExamsUserCertificateIdKey = 'user_exams_userCertificateId_key'
}

/** input type for incrementing numeric columns in table "user_exams" */
export type User_Exams_Inc_Input = {
  answersCount?: InputMaybe<Scalars['Int']['input']>;
  questionsCount?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  totalCorrectAnswers?: InputMaybe<Scalars['Int']['input']>;
  totalCountOFSubmitedquestions?: InputMaybe<Scalars['Int']['input']>;
  totalPoints?: InputMaybe<Scalars['Int']['input']>;
  totalWrongAnswers?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_exams" */
export type User_Exams_Insert_Input = {
  answersCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endTime?: InputMaybe<Scalars['timestamptz']['input']>;
  exam?: InputMaybe<Exams_Obj_Rel_Insert_Input>;
  examId?: InputMaybe<Scalars['String']['input']>;
  questionsCount?: InputMaybe<Scalars['Int']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['timestamptz']['input']>;
  totalCorrectAnswers?: InputMaybe<Scalars['Int']['input']>;
  totalCountOFSubmitedquestions?: InputMaybe<Scalars['Int']['input']>;
  totalPoints?: InputMaybe<Scalars['Int']['input']>;
  totalWrongAnswers?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userCertificateId?: InputMaybe<Scalars['String']['input']>;
  userExamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_certificate?: InputMaybe<User_Certificates_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Exams_Max_Fields = {
  __typename?: 'user_exams_max_fields';
  answersCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endTime?: Maybe<Scalars['timestamptz']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  questionsCount?: Maybe<Scalars['Int']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['timestamptz']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userCertificateId?: Maybe<Scalars['String']['output']>;
  userExamId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_exams" */
export type User_Exams_Max_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  examId?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userCertificateId?: InputMaybe<Order_By>;
  userExamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Exams_Min_Fields = {
  __typename?: 'user_exams_min_fields';
  answersCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endTime?: Maybe<Scalars['timestamptz']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  questionsCount?: Maybe<Scalars['Int']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['timestamptz']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userCertificateId?: Maybe<Scalars['String']['output']>;
  userExamId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_exams" */
export type User_Exams_Min_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  examId?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userCertificateId?: InputMaybe<Order_By>;
  userExamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_exams" */
export type User_Exams_Mutation_Response = {
  __typename?: 'user_exams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Exams>;
};

/** input type for inserting object relation for remote table "user_exams" */
export type User_Exams_Obj_Rel_Insert_Input = {
  data: User_Exams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Exams_On_Conflict>;
};

/** on_conflict condition type for table "user_exams" */
export type User_Exams_On_Conflict = {
  constraint: User_Exams_Constraint;
  update_columns?: Array<User_Exams_Update_Column>;
  where?: InputMaybe<User_Exams_Bool_Exp>;
};

/** Ordering options when selecting data from "user_exams". */
export type User_Exams_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  exam?: InputMaybe<Exams_Order_By>;
  examId?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userCertificateId?: InputMaybe<Order_By>;
  userExamId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  user_certificate?: InputMaybe<User_Certificates_Order_By>;
};

/** primary key columns input for table: user_exams */
export type User_Exams_Pk_Columns_Input = {
  userExamId: Scalars['String']['input'];
};

/** select columns of table "user_exams" */
export enum User_Exams_Select_Column {
  /** column name */
  AnswersCount = 'answersCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  ExamId = 'examId',
  /** column name */
  QuestionsCount = 'questionsCount',
  /** column name */
  Result = 'result',
  /** column name */
  Score = 'score',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  TotalCorrectAnswers = 'totalCorrectAnswers',
  /** column name */
  TotalCountOfSubmitedquestions = 'totalCountOFSubmitedquestions',
  /** column name */
  TotalPoints = 'totalPoints',
  /** column name */
  TotalWrongAnswers = 'totalWrongAnswers',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserCertificateId = 'userCertificateId',
  /** column name */
  UserExamId = 'userExamId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_exams" */
export type User_Exams_Set_Input = {
  answersCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endTime?: InputMaybe<Scalars['timestamptz']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  questionsCount?: InputMaybe<Scalars['Int']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['timestamptz']['input']>;
  totalCorrectAnswers?: InputMaybe<Scalars['Int']['input']>;
  totalCountOFSubmitedquestions?: InputMaybe<Scalars['Int']['input']>;
  totalPoints?: InputMaybe<Scalars['Int']['input']>;
  totalWrongAnswers?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userCertificateId?: InputMaybe<Scalars['String']['input']>;
  userExamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Exams_Stddev_Fields = {
  __typename?: 'user_exams_stddev_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_exams" */
export type User_Exams_Stddev_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Exams_Stddev_Pop_Fields = {
  __typename?: 'user_exams_stddev_pop_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_exams" */
export type User_Exams_Stddev_Pop_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Exams_Stddev_Samp_Fields = {
  __typename?: 'user_exams_stddev_samp_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_exams" */
export type User_Exams_Stddev_Samp_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_exams" */
export type User_Exams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Exams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Exams_Stream_Cursor_Value_Input = {
  answersCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endTime?: InputMaybe<Scalars['timestamptz']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
  questionsCount?: InputMaybe<Scalars['Int']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['timestamptz']['input']>;
  totalCorrectAnswers?: InputMaybe<Scalars['Int']['input']>;
  totalCountOFSubmitedquestions?: InputMaybe<Scalars['Int']['input']>;
  totalPoints?: InputMaybe<Scalars['Int']['input']>;
  totalWrongAnswers?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userCertificateId?: InputMaybe<Scalars['String']['input']>;
  userExamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Exams_Sum_Fields = {
  __typename?: 'user_exams_sum_fields';
  answersCount?: Maybe<Scalars['Int']['output']>;
  questionsCount?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Int']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_exams" */
export type User_Exams_Sum_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** update columns of table "user_exams" */
export enum User_Exams_Update_Column {
  /** column name */
  AnswersCount = 'answersCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  ExamId = 'examId',
  /** column name */
  QuestionsCount = 'questionsCount',
  /** column name */
  Result = 'result',
  /** column name */
  Score = 'score',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  TotalCorrectAnswers = 'totalCorrectAnswers',
  /** column name */
  TotalCountOfSubmitedquestions = 'totalCountOFSubmitedquestions',
  /** column name */
  TotalPoints = 'totalPoints',
  /** column name */
  TotalWrongAnswers = 'totalWrongAnswers',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserCertificateId = 'userCertificateId',
  /** column name */
  UserExamId = 'userExamId',
  /** column name */
  UserId = 'userId'
}

export type User_Exams_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Exams_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Exams_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Exams_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Exams_Var_Pop_Fields = {
  __typename?: 'user_exams_var_pop_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_exams" */
export type User_Exams_Var_Pop_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Exams_Var_Samp_Fields = {
  __typename?: 'user_exams_var_samp_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_exams" */
export type User_Exams_Var_Samp_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Exams_Variance_Fields = {
  __typename?: 'user_exams_variance_fields';
  answersCount?: Maybe<Scalars['Float']['output']>;
  questionsCount?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalCorrectAnswers?: Maybe<Scalars['Float']['output']>;
  totalCountOFSubmitedquestions?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalWrongAnswers?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_exams" */
export type User_Exams_Variance_Order_By = {
  answersCount?: InputMaybe<Order_By>;
  questionsCount?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  totalCorrectAnswers?: InputMaybe<Order_By>;
  totalCountOFSubmitedquestions?: InputMaybe<Order_By>;
  totalPoints?: InputMaybe<Order_By>;
  totalWrongAnswers?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_hearing_councils" */
export type User_Hearing_Councils = {
  __typename?: 'user_hearing_councils';
  /** An object relationship */
  admin?: Maybe<Admins>;
  adminId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  durationAttendance?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  hearingCouncil: Hearing_Councils;
  hearingCouncilId: Scalars['String']['output'];
  joinedAt?: Maybe<Scalars['timestamptz']['output']>;
  lastActionTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  leftAt?: Maybe<Scalars['timestamptz']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  numSubmissions?: Maybe<Scalars['Int']['output']>;
  role: Scalars['userRole']['output'];
  socketId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  student?: Maybe<Students>;
  studentId?: Maybe<Scalars['String']['output']>;
  subscriped: Scalars['Boolean']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userCertificateId?: Maybe<Scalars['String']['output']>;
  userHearingCouncilId: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user_certificate?: Maybe<User_Certificates>;
};

/** aggregated selection of "user_hearing_councils" */
export type User_Hearing_Councils_Aggregate = {
  __typename?: 'user_hearing_councils_aggregate';
  aggregate?: Maybe<User_Hearing_Councils_Aggregate_Fields>;
  nodes: Array<User_Hearing_Councils>;
};

export type User_Hearing_Councils_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Hearing_Councils_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Hearing_Councils_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Hearing_Councils_Aggregate_Bool_Exp_Count>;
};

export type User_Hearing_Councils_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Hearing_Councils_Select_Column_User_Hearing_Councils_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Hearing_Councils_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Hearing_Councils_Select_Column_User_Hearing_Councils_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Hearing_Councils_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_hearing_councils" */
export type User_Hearing_Councils_Aggregate_Fields = {
  __typename?: 'user_hearing_councils_aggregate_fields';
  avg?: Maybe<User_Hearing_Councils_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Hearing_Councils_Max_Fields>;
  min?: Maybe<User_Hearing_Councils_Min_Fields>;
  stddev?: Maybe<User_Hearing_Councils_Stddev_Fields>;
  stddev_pop?: Maybe<User_Hearing_Councils_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Hearing_Councils_Stddev_Samp_Fields>;
  sum?: Maybe<User_Hearing_Councils_Sum_Fields>;
  var_pop?: Maybe<User_Hearing_Councils_Var_Pop_Fields>;
  var_samp?: Maybe<User_Hearing_Councils_Var_Samp_Fields>;
  variance?: Maybe<User_Hearing_Councils_Variance_Fields>;
};


/** aggregate fields of "user_hearing_councils" */
export type User_Hearing_Councils_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_hearing_councils" */
export type User_Hearing_Councils_Aggregate_Order_By = {
  avg?: InputMaybe<User_Hearing_Councils_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Hearing_Councils_Max_Order_By>;
  min?: InputMaybe<User_Hearing_Councils_Min_Order_By>;
  stddev?: InputMaybe<User_Hearing_Councils_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Hearing_Councils_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Hearing_Councils_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Hearing_Councils_Sum_Order_By>;
  var_pop?: InputMaybe<User_Hearing_Councils_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Hearing_Councils_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Hearing_Councils_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_hearing_councils" */
export type User_Hearing_Councils_Arr_Rel_Insert_Input = {
  data: Array<User_Hearing_Councils_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Hearing_Councils_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Hearing_Councils_Avg_Fields = {
  __typename?: 'user_hearing_councils_avg_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Avg_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_hearing_councils". All fields are combined with a logical 'AND'. */
export type User_Hearing_Councils_Bool_Exp = {
  _and?: InputMaybe<Array<User_Hearing_Councils_Bool_Exp>>;
  _not?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  _or?: InputMaybe<Array<User_Hearing_Councils_Bool_Exp>>;
  admin?: InputMaybe<Admins_Bool_Exp>;
  adminId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  durationAttendance?: InputMaybe<Int_Comparison_Exp>;
  hearingCouncil?: InputMaybe<Hearing_Councils_Bool_Exp>;
  hearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  joinedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastActionTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  leftAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  meetingUrl?: InputMaybe<String_Comparison_Exp>;
  numSubmissions?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<UserRole_Comparison_Exp>;
  socketId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  student?: InputMaybe<Students_Bool_Exp>;
  studentId?: InputMaybe<String_Comparison_Exp>;
  subscriped?: InputMaybe<Boolean_Comparison_Exp>;
  transactionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userCertificateId?: InputMaybe<String_Comparison_Exp>;
  userHearingCouncilId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_certificate?: InputMaybe<User_Certificates_Bool_Exp>;
};

/** unique or primary key constraints on table "user_hearing_councils" */
export enum User_Hearing_Councils_Constraint {
  /** unique or primary key constraint on columns "hearingCouncilId", "adminId" */
  UserHearingCouncilsAdminIdHearingCouncilIdKey = 'user_hearing_councils_adminId_hearingCouncilId_key',
  /** unique or primary key constraint on columns "userHearingCouncilId" */
  UserHearingCouncilsPkey = 'user_hearing_councils_pkey',
  /** unique or primary key constraint on columns "userCertificateId" */
  UserHearingCouncilsUserCertificateIdKey = 'user_hearing_councils_userCertificateId_key',
  /** unique or primary key constraint on columns "userId", "hearingCouncilId" */
  UserHearingCouncilsUserIdHearingCouncilIdKey = 'user_hearing_councils_userId_hearingCouncilId_key'
}

/** input type for incrementing numeric columns in table "user_hearing_councils" */
export type User_Hearing_Councils_Inc_Input = {
  durationAttendance?: InputMaybe<Scalars['Int']['input']>;
  numSubmissions?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_hearing_councils" */
export type User_Hearing_Councils_Insert_Input = {
  admin?: InputMaybe<Admins_Obj_Rel_Insert_Input>;
  adminId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  durationAttendance?: InputMaybe<Scalars['Int']['input']>;
  hearingCouncil?: InputMaybe<Hearing_Councils_Obj_Rel_Insert_Input>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  joinedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  lastActionTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  leftAt?: InputMaybe<Scalars['timestamptz']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  numSubmissions?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  socketId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  student?: InputMaybe<Students_Obj_Rel_Insert_Input>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  subscriped?: InputMaybe<Scalars['Boolean']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userCertificateId?: InputMaybe<Scalars['String']['input']>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_certificate?: InputMaybe<User_Certificates_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Hearing_Councils_Max_Fields = {
  __typename?: 'user_hearing_councils_max_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  durationAttendance?: Maybe<Scalars['Int']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  joinedAt?: Maybe<Scalars['timestamptz']['output']>;
  lastActionTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  leftAt?: Maybe<Scalars['timestamptz']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  numSubmissions?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  socketId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userCertificateId?: Maybe<Scalars['String']['output']>;
  userHearingCouncilId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Max_Order_By = {
  adminId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  durationAttendance?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  joinedAt?: InputMaybe<Order_By>;
  lastActionTimestamp?: InputMaybe<Order_By>;
  leftAt?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  socketId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userCertificateId?: InputMaybe<Order_By>;
  userHearingCouncilId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Hearing_Councils_Min_Fields = {
  __typename?: 'user_hearing_councils_min_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  durationAttendance?: Maybe<Scalars['Int']['output']>;
  hearingCouncilId?: Maybe<Scalars['String']['output']>;
  joinedAt?: Maybe<Scalars['timestamptz']['output']>;
  lastActionTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  leftAt?: Maybe<Scalars['timestamptz']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  numSubmissions?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  socketId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userCertificateId?: Maybe<Scalars['String']['output']>;
  userHearingCouncilId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Min_Order_By = {
  adminId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  durationAttendance?: InputMaybe<Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  joinedAt?: InputMaybe<Order_By>;
  lastActionTimestamp?: InputMaybe<Order_By>;
  leftAt?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  socketId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  studentId?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userCertificateId?: InputMaybe<Order_By>;
  userHearingCouncilId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_hearing_councils" */
export type User_Hearing_Councils_Mutation_Response = {
  __typename?: 'user_hearing_councils_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Hearing_Councils>;
};

/** input type for inserting object relation for remote table "user_hearing_councils" */
export type User_Hearing_Councils_Obj_Rel_Insert_Input = {
  data: User_Hearing_Councils_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Hearing_Councils_On_Conflict>;
};

/** on_conflict condition type for table "user_hearing_councils" */
export type User_Hearing_Councils_On_Conflict = {
  constraint: User_Hearing_Councils_Constraint;
  update_columns?: Array<User_Hearing_Councils_Update_Column>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};

/** Ordering options when selecting data from "user_hearing_councils". */
export type User_Hearing_Councils_Order_By = {
  admin?: InputMaybe<Admins_Order_By>;
  adminId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  durationAttendance?: InputMaybe<Order_By>;
  hearingCouncil?: InputMaybe<Hearing_Councils_Order_By>;
  hearingCouncilId?: InputMaybe<Order_By>;
  joinedAt?: InputMaybe<Order_By>;
  lastActionTimestamp?: InputMaybe<Order_By>;
  leftAt?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  socketId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  student?: InputMaybe<Students_Order_By>;
  studentId?: InputMaybe<Order_By>;
  subscriped?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userCertificateId?: InputMaybe<Order_By>;
  userHearingCouncilId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  user_certificate?: InputMaybe<User_Certificates_Order_By>;
};

/** primary key columns input for table: user_hearing_councils */
export type User_Hearing_Councils_Pk_Columns_Input = {
  userHearingCouncilId: Scalars['String']['input'];
};

/** select columns of table "user_hearing_councils" */
export enum User_Hearing_Councils_Select_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DurationAttendance = 'durationAttendance',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  JoinedAt = 'joinedAt',
  /** column name */
  LastActionTimestamp = 'lastActionTimestamp',
  /** column name */
  LeftAt = 'leftAt',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  NumSubmissions = 'numSubmissions',
  /** column name */
  Role = 'role',
  /** column name */
  SocketId = 'socketId',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'studentId',
  /** column name */
  Subscriped = 'subscriped',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserCertificateId = 'userCertificateId',
  /** column name */
  UserHearingCouncilId = 'userHearingCouncilId',
  /** column name */
  UserId = 'userId'
}

/** select "user_hearing_councils_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_hearing_councils" */
export enum User_Hearing_Councils_Select_Column_User_Hearing_Councils_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Subscriped = 'subscriped'
}

/** select "user_hearing_councils_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_hearing_councils" */
export enum User_Hearing_Councils_Select_Column_User_Hearing_Councils_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Subscriped = 'subscriped'
}

/** input type for updating data in table "user_hearing_councils" */
export type User_Hearing_Councils_Set_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  durationAttendance?: InputMaybe<Scalars['Int']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  joinedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  lastActionTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  leftAt?: InputMaybe<Scalars['timestamptz']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  numSubmissions?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  socketId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  subscriped?: InputMaybe<Scalars['Boolean']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userCertificateId?: InputMaybe<Scalars['String']['input']>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Hearing_Councils_Stddev_Fields = {
  __typename?: 'user_hearing_councils_stddev_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Stddev_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Hearing_Councils_Stddev_Pop_Fields = {
  __typename?: 'user_hearing_councils_stddev_pop_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Stddev_Pop_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Hearing_Councils_Stddev_Samp_Fields = {
  __typename?: 'user_hearing_councils_stddev_samp_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Stddev_Samp_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_hearing_councils" */
export type User_Hearing_Councils_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Hearing_Councils_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Hearing_Councils_Stream_Cursor_Value_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  durationAttendance?: InputMaybe<Scalars['Int']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  joinedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  lastActionTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  leftAt?: InputMaybe<Scalars['timestamptz']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  numSubmissions?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  socketId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  subscriped?: InputMaybe<Scalars['Boolean']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userCertificateId?: InputMaybe<Scalars['String']['input']>;
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Hearing_Councils_Sum_Fields = {
  __typename?: 'user_hearing_councils_sum_fields';
  durationAttendance?: Maybe<Scalars['Int']['output']>;
  numSubmissions?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Sum_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** update columns of table "user_hearing_councils" */
export enum User_Hearing_Councils_Update_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DurationAttendance = 'durationAttendance',
  /** column name */
  HearingCouncilId = 'hearingCouncilId',
  /** column name */
  JoinedAt = 'joinedAt',
  /** column name */
  LastActionTimestamp = 'lastActionTimestamp',
  /** column name */
  LeftAt = 'leftAt',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  NumSubmissions = 'numSubmissions',
  /** column name */
  Role = 'role',
  /** column name */
  SocketId = 'socketId',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'studentId',
  /** column name */
  Subscriped = 'subscriped',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserCertificateId = 'userCertificateId',
  /** column name */
  UserHearingCouncilId = 'userHearingCouncilId',
  /** column name */
  UserId = 'userId'
}

export type User_Hearing_Councils_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Hearing_Councils_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Hearing_Councils_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Hearing_Councils_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Hearing_Councils_Var_Pop_Fields = {
  __typename?: 'user_hearing_councils_var_pop_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Var_Pop_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Hearing_Councils_Var_Samp_Fields = {
  __typename?: 'user_hearing_councils_var_samp_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Var_Samp_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Hearing_Councils_Variance_Fields = {
  __typename?: 'user_hearing_councils_variance_fields';
  durationAttendance?: Maybe<Scalars['Float']['output']>;
  numSubmissions?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_hearing_councils" */
export type User_Hearing_Councils_Variance_Order_By = {
  durationAttendance?: InputMaybe<Order_By>;
  numSubmissions?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_meetings" */
export type User_Meetings = {
  __typename?: 'user_meetings';
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  meetingId: Scalars['String']['output'];
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role: Scalars['userRole']['output'];
  status?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_meetings" */
export type User_Meetings_Aggregate = {
  __typename?: 'user_meetings_aggregate';
  aggregate?: Maybe<User_Meetings_Aggregate_Fields>;
  nodes: Array<User_Meetings>;
};

/** aggregate fields of "user_meetings" */
export type User_Meetings_Aggregate_Fields = {
  __typename?: 'user_meetings_aggregate_fields';
  avg?: Maybe<User_Meetings_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Meetings_Max_Fields>;
  min?: Maybe<User_Meetings_Min_Fields>;
  stddev?: Maybe<User_Meetings_Stddev_Fields>;
  stddev_pop?: Maybe<User_Meetings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Meetings_Stddev_Samp_Fields>;
  sum?: Maybe<User_Meetings_Sum_Fields>;
  var_pop?: Maybe<User_Meetings_Var_Pop_Fields>;
  var_samp?: Maybe<User_Meetings_Var_Samp_Fields>;
  variance?: Maybe<User_Meetings_Variance_Fields>;
};


/** aggregate fields of "user_meetings" */
export type User_Meetings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Meetings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Meetings_Avg_Fields = {
  __typename?: 'user_meetings_avg_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_meetings". All fields are combined with a logical 'AND'. */
export type User_Meetings_Bool_Exp = {
  _and?: InputMaybe<Array<User_Meetings_Bool_Exp>>;
  _not?: InputMaybe<User_Meetings_Bool_Exp>;
  _or?: InputMaybe<Array<User_Meetings_Bool_Exp>>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  meetingId?: InputMaybe<String_Comparison_Exp>;
  meetingUrl?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<UserRole_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_meetings" */
export enum User_Meetings_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserMeetingsPkey = 'user_meetings_pkey'
}

/** input type for incrementing numeric columns in table "user_meetings" */
export type User_Meetings_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_meetings" */
export type User_Meetings_Insert_Input = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Meetings_Max_Fields = {
  __typename?: 'user_meetings_max_fields';
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meetingId?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Meetings_Min_Fields = {
  __typename?: 'user_meetings_min_fields';
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meetingId?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_meetings" */
export type User_Meetings_Mutation_Response = {
  __typename?: 'user_meetings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Meetings>;
};

/** on_conflict condition type for table "user_meetings" */
export type User_Meetings_On_Conflict = {
  constraint: User_Meetings_Constraint;
  update_columns?: Array<User_Meetings_Update_Column>;
  where?: InputMaybe<User_Meetings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_meetings". */
export type User_Meetings_Order_By = {
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_meetings */
export type User_Meetings_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_meetings" */
export enum User_Meetings_Select_Column {
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_meetings" */
export type User_Meetings_Set_Input = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Meetings_Stddev_Fields = {
  __typename?: 'user_meetings_stddev_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Meetings_Stddev_Pop_Fields = {
  __typename?: 'user_meetings_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Meetings_Stddev_Samp_Fields = {
  __typename?: 'user_meetings_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_meetings" */
export type User_Meetings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Meetings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Meetings_Stream_Cursor_Value_Input = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Meetings_Sum_Fields = {
  __typename?: 'user_meetings_sum_fields';
  duration?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "user_meetings" */
export enum User_Meetings_Update_Column {
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId'
}

export type User_Meetings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Meetings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Meetings_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Meetings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Meetings_Var_Pop_Fields = {
  __typename?: 'user_meetings_var_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Meetings_Var_Samp_Fields = {
  __typename?: 'user_meetings_var_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Meetings_Variance_Fields = {
  __typename?: 'user_meetings_variance_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_preferences" */
export type User_Preferences = {
  __typename?: 'user_preferences';
  chosenMadhab?: Maybe<Scalars['String']['output']>;
  contentFilters?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lang?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "user_preferences" */
export type User_Preferences_Aggregate = {
  __typename?: 'user_preferences_aggregate';
  aggregate?: Maybe<User_Preferences_Aggregate_Fields>;
  nodes: Array<User_Preferences>;
};

/** aggregate fields of "user_preferences" */
export type User_Preferences_Aggregate_Fields = {
  __typename?: 'user_preferences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Preferences_Max_Fields>;
  min?: Maybe<User_Preferences_Min_Fields>;
};


/** aggregate fields of "user_preferences" */
export type User_Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_preferences". All fields are combined with a logical 'AND'. */
export type User_Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<User_Preferences_Bool_Exp>>;
  _not?: InputMaybe<User_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<User_Preferences_Bool_Exp>>;
  chosenMadhab?: InputMaybe<String_Comparison_Exp>;
  contentFilters?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_preferences" */
export enum User_Preferences_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPreferencesPkey = 'user_preferences_pkey'
}

/** input type for inserting data into table "user_preferences" */
export type User_Preferences_Insert_Input = {
  chosenMadhab?: InputMaybe<Scalars['String']['input']>;
  contentFilters?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Preferences_Max_Fields = {
  __typename?: 'user_preferences_max_fields';
  chosenMadhab?: Maybe<Scalars['String']['output']>;
  contentFilters?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Preferences_Min_Fields = {
  __typename?: 'user_preferences_min_fields';
  chosenMadhab?: Maybe<Scalars['String']['output']>;
  contentFilters?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_preferences" */
export type User_Preferences_Mutation_Response = {
  __typename?: 'user_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Preferences>;
};

/** on_conflict condition type for table "user_preferences" */
export type User_Preferences_On_Conflict = {
  constraint: User_Preferences_Constraint;
  update_columns?: Array<User_Preferences_Update_Column>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "user_preferences". */
export type User_Preferences_Order_By = {
  chosenMadhab?: InputMaybe<Order_By>;
  contentFilters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_preferences */
export type User_Preferences_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_preferences" */
export enum User_Preferences_Select_Column {
  /** column name */
  ChosenMadhab = 'chosenMadhab',
  /** column name */
  ContentFilters = 'contentFilters',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang'
}

/** input type for updating data in table "user_preferences" */
export type User_Preferences_Set_Input = {
  chosenMadhab?: InputMaybe<Scalars['String']['input']>;
  contentFilters?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_preferences" */
export type User_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Preferences_Stream_Cursor_Value_Input = {
  chosenMadhab?: InputMaybe<Scalars['String']['input']>;
  contentFilters?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_preferences" */
export enum User_Preferences_Update_Column {
  /** column name */
  ChosenMadhab = 'chosenMadhab',
  /** column name */
  ContentFilters = 'contentFilters',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang'
}

export type User_Preferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Preferences_Bool_Exp;
};

/** columns and relationships of "user_quizes" */
export type User_Quizes = {
  __typename?: 'user_quizes';
  /** An object relationship */
  answer?: Maybe<Answers>;
  answerId?: Maybe<Scalars['String']['output']>;
  answerIndex?: Maybe<Scalars['Int']['output']>;
  answerString?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  isAnswerCorrect: Scalars['Boolean']['output'];
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mutoon_section?: Maybe<Mutoon_Sections>;
  /** An object relationship */
  question: Questions;
  questionId: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  userQuizId: Scalars['String']['output'];
  weaknessPoint: Scalars['Boolean']['output'];
};

/** aggregated selection of "user_quizes" */
export type User_Quizes_Aggregate = {
  __typename?: 'user_quizes_aggregate';
  aggregate?: Maybe<User_Quizes_Aggregate_Fields>;
  nodes: Array<User_Quizes>;
};

export type User_Quizes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Quizes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Quizes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Quizes_Aggregate_Bool_Exp_Count>;
};

export type User_Quizes_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Quizes_Select_Column_User_Quizes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Quizes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Quizes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Quizes_Select_Column_User_Quizes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Quizes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Quizes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Quizes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Quizes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_quizes" */
export type User_Quizes_Aggregate_Fields = {
  __typename?: 'user_quizes_aggregate_fields';
  avg?: Maybe<User_Quizes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Quizes_Max_Fields>;
  min?: Maybe<User_Quizes_Min_Fields>;
  stddev?: Maybe<User_Quizes_Stddev_Fields>;
  stddev_pop?: Maybe<User_Quizes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Quizes_Stddev_Samp_Fields>;
  sum?: Maybe<User_Quizes_Sum_Fields>;
  var_pop?: Maybe<User_Quizes_Var_Pop_Fields>;
  var_samp?: Maybe<User_Quizes_Var_Samp_Fields>;
  variance?: Maybe<User_Quizes_Variance_Fields>;
};


/** aggregate fields of "user_quizes" */
export type User_Quizes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Quizes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_quizes" */
export type User_Quizes_Aggregate_Order_By = {
  avg?: InputMaybe<User_Quizes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Quizes_Max_Order_By>;
  min?: InputMaybe<User_Quizes_Min_Order_By>;
  stddev?: InputMaybe<User_Quizes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Quizes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Quizes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Quizes_Sum_Order_By>;
  var_pop?: InputMaybe<User_Quizes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Quizes_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Quizes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_quizes" */
export type User_Quizes_Arr_Rel_Insert_Input = {
  data: Array<User_Quizes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Quizes_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Quizes_Avg_Fields = {
  __typename?: 'user_quizes_avg_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_quizes" */
export type User_Quizes_Avg_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_quizes". All fields are combined with a logical 'AND'. */
export type User_Quizes_Bool_Exp = {
  _and?: InputMaybe<Array<User_Quizes_Bool_Exp>>;
  _not?: InputMaybe<User_Quizes_Bool_Exp>;
  _or?: InputMaybe<Array<User_Quizes_Bool_Exp>>;
  answer?: InputMaybe<Answers_Bool_Exp>;
  answerId?: InputMaybe<String_Comparison_Exp>;
  answerIndex?: InputMaybe<Int_Comparison_Exp>;
  answerString?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isAnswerCorrect?: InputMaybe<Boolean_Comparison_Exp>;
  mutoonSectionId?: InputMaybe<String_Comparison_Exp>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionId?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  userQuizId?: InputMaybe<String_Comparison_Exp>;
  weaknessPoint?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_quizes" */
export enum User_Quizes_Constraint {
  /** unique or primary key constraint on columns "userQuizId" */
  UserQuizesPkey = 'user_quizes_pkey'
}

/** input type for incrementing numeric columns in table "user_quizes" */
export type User_Quizes_Inc_Input = {
  answerIndex?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_quizes" */
export type User_Quizes_Insert_Input = {
  answer?: InputMaybe<Answers_Obj_Rel_Insert_Input>;
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerIndex?: InputMaybe<Scalars['Int']['input']>;
  answerString?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  isAnswerCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userQuizId?: InputMaybe<Scalars['String']['input']>;
  weaknessPoint?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type User_Quizes_Max_Fields = {
  __typename?: 'user_quizes_max_fields';
  answerId?: Maybe<Scalars['String']['output']>;
  answerIndex?: Maybe<Scalars['Int']['output']>;
  answerString?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userQuizId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_quizes" */
export type User_Quizes_Max_Order_By = {
  answerId?: InputMaybe<Order_By>;
  answerIndex?: InputMaybe<Order_By>;
  answerString?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userQuizId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Quizes_Min_Fields = {
  __typename?: 'user_quizes_min_fields';
  answerId?: Maybe<Scalars['String']['output']>;
  answerIndex?: Maybe<Scalars['Int']['output']>;
  answerString?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  mutoonSectionId?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userQuizId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_quizes" */
export type User_Quizes_Min_Order_By = {
  answerId?: InputMaybe<Order_By>;
  answerIndex?: InputMaybe<Order_By>;
  answerString?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userQuizId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_quizes" */
export type User_Quizes_Mutation_Response = {
  __typename?: 'user_quizes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Quizes>;
};

/** on_conflict condition type for table "user_quizes" */
export type User_Quizes_On_Conflict = {
  constraint: User_Quizes_Constraint;
  update_columns?: Array<User_Quizes_Update_Column>;
  where?: InputMaybe<User_Quizes_Bool_Exp>;
};

/** Ordering options when selecting data from "user_quizes". */
export type User_Quizes_Order_By = {
  answer?: InputMaybe<Answers_Order_By>;
  answerId?: InputMaybe<Order_By>;
  answerIndex?: InputMaybe<Order_By>;
  answerString?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  isAnswerCorrect?: InputMaybe<Order_By>;
  mutoonSectionId?: InputMaybe<Order_By>;
  mutoon_section?: InputMaybe<Mutoon_Sections_Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionId?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  userQuizId?: InputMaybe<Order_By>;
  weaknessPoint?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_quizes */
export type User_Quizes_Pk_Columns_Input = {
  userQuizId: Scalars['String']['input'];
};

/** select columns of table "user_quizes" */
export enum User_Quizes_Select_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  AnswerIndex = 'answerIndex',
  /** column name */
  AnswerString = 'answerString',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  IsAnswerCorrect = 'isAnswerCorrect',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserQuizId = 'userQuizId',
  /** column name */
  WeaknessPoint = 'weaknessPoint'
}

/** select "user_quizes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_quizes" */
export enum User_Quizes_Select_Column_User_Quizes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAnswerCorrect = 'isAnswerCorrect',
  /** column name */
  WeaknessPoint = 'weaknessPoint'
}

/** select "user_quizes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_quizes" */
export enum User_Quizes_Select_Column_User_Quizes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAnswerCorrect = 'isAnswerCorrect',
  /** column name */
  WeaknessPoint = 'weaknessPoint'
}

/** input type for updating data in table "user_quizes" */
export type User_Quizes_Set_Input = {
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerIndex?: InputMaybe<Scalars['Int']['input']>;
  answerString?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  isAnswerCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userQuizId?: InputMaybe<Scalars['String']['input']>;
  weaknessPoint?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type User_Quizes_Stddev_Fields = {
  __typename?: 'user_quizes_stddev_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_quizes" */
export type User_Quizes_Stddev_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Quizes_Stddev_Pop_Fields = {
  __typename?: 'user_quizes_stddev_pop_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_quizes" */
export type User_Quizes_Stddev_Pop_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Quizes_Stddev_Samp_Fields = {
  __typename?: 'user_quizes_stddev_samp_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_quizes" */
export type User_Quizes_Stddev_Samp_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_quizes" */
export type User_Quizes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Quizes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Quizes_Stream_Cursor_Value_Input = {
  answerId?: InputMaybe<Scalars['String']['input']>;
  answerIndex?: InputMaybe<Scalars['Int']['input']>;
  answerString?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  isAnswerCorrect?: InputMaybe<Scalars['Boolean']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userQuizId?: InputMaybe<Scalars['String']['input']>;
  weaknessPoint?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type User_Quizes_Sum_Fields = {
  __typename?: 'user_quizes_sum_fields';
  answerIndex?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_quizes" */
export type User_Quizes_Sum_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** update columns of table "user_quizes" */
export enum User_Quizes_Update_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  AnswerIndex = 'answerIndex',
  /** column name */
  AnswerString = 'answerString',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  IsAnswerCorrect = 'isAnswerCorrect',
  /** column name */
  MutoonSectionId = 'mutoonSectionId',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserQuizId = 'userQuizId',
  /** column name */
  WeaknessPoint = 'weaknessPoint'
}

export type User_Quizes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Quizes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Quizes_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Quizes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Quizes_Var_Pop_Fields = {
  __typename?: 'user_quizes_var_pop_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_quizes" */
export type User_Quizes_Var_Pop_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Quizes_Var_Samp_Fields = {
  __typename?: 'user_quizes_var_samp_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_quizes" */
export type User_Quizes_Var_Samp_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Quizes_Variance_Fields = {
  __typename?: 'user_quizes_variance_fields';
  answerIndex?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_quizes" */
export type User_Quizes_Variance_Order_By = {
  answerIndex?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_records" */
export type User_Records = {
  __typename?: 'user_records';
  adminMessage?: Maybe<Scalars['String']['output']>;
  audioRecordUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  mutoonSection?: Maybe<Mutoon_Sections>;
  /** An array relationship */
  planSections: Array<Plan_Sections>;
  /** An aggregate relationship */
  planSections_aggregate: Plan_Sections_Aggregate;
  recordingId: Scalars['String']['output'];
  sectionId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sound?: Maybe<Sounds>;
  soundId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};


/** columns and relationships of "user_records" */
export type User_RecordsPlanSectionsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};


/** columns and relationships of "user_records" */
export type User_RecordsPlanSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Sections_Order_By>>;
  where?: InputMaybe<Plan_Sections_Bool_Exp>;
};

/** aggregated selection of "user_records" */
export type User_Records_Aggregate = {
  __typename?: 'user_records_aggregate';
  aggregate?: Maybe<User_Records_Aggregate_Fields>;
  nodes: Array<User_Records>;
};

export type User_Records_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Records_Aggregate_Bool_Exp_Count>;
};

export type User_Records_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Records_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Records_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_records" */
export type User_Records_Aggregate_Fields = {
  __typename?: 'user_records_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Records_Max_Fields>;
  min?: Maybe<User_Records_Min_Fields>;
};


/** aggregate fields of "user_records" */
export type User_Records_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Records_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_records" */
export type User_Records_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Records_Max_Order_By>;
  min?: InputMaybe<User_Records_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_records" */
export type User_Records_Arr_Rel_Insert_Input = {
  data: Array<User_Records_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Records_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_records". All fields are combined with a logical 'AND'. */
export type User_Records_Bool_Exp = {
  _and?: InputMaybe<Array<User_Records_Bool_Exp>>;
  _not?: InputMaybe<User_Records_Bool_Exp>;
  _or?: InputMaybe<Array<User_Records_Bool_Exp>>;
  adminMessage?: InputMaybe<String_Comparison_Exp>;
  audioRecordUrl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  mutoonSection?: InputMaybe<Mutoon_Sections_Bool_Exp>;
  planSections?: InputMaybe<Plan_Sections_Bool_Exp>;
  planSections_aggregate?: InputMaybe<Plan_Sections_Aggregate_Bool_Exp>;
  recordingId?: InputMaybe<String_Comparison_Exp>;
  sectionId?: InputMaybe<String_Comparison_Exp>;
  sound?: InputMaybe<Sounds_Bool_Exp>;
  soundId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_records" */
export enum User_Records_Constraint {
  /** unique or primary key constraint on columns "recordingId" */
  UserRecordsPkey = 'user_records_pkey'
}

/** input type for inserting data into table "user_records" */
export type User_Records_Insert_Input = {
  adminMessage?: InputMaybe<Scalars['String']['input']>;
  audioRecordUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  mutoonSection?: InputMaybe<Mutoon_Sections_Obj_Rel_Insert_Input>;
  planSections?: InputMaybe<Plan_Sections_Arr_Rel_Insert_Input>;
  recordingId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  sound?: InputMaybe<Sounds_Obj_Rel_Insert_Input>;
  soundId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Records_Max_Fields = {
  __typename?: 'user_records_max_fields';
  adminMessage?: Maybe<Scalars['String']['output']>;
  audioRecordUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  recordingId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  soundId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_records" */
export type User_Records_Max_Order_By = {
  adminMessage?: InputMaybe<Order_By>;
  audioRecordUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  recordingId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  soundId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Records_Min_Fields = {
  __typename?: 'user_records_min_fields';
  adminMessage?: Maybe<Scalars['String']['output']>;
  audioRecordUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  recordingId?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  soundId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_records" */
export type User_Records_Min_Order_By = {
  adminMessage?: InputMaybe<Order_By>;
  audioRecordUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  recordingId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  soundId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_records" */
export type User_Records_Mutation_Response = {
  __typename?: 'user_records_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Records>;
};

/** input type for inserting object relation for remote table "user_records" */
export type User_Records_Obj_Rel_Insert_Input = {
  data: User_Records_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Records_On_Conflict>;
};

/** on_conflict condition type for table "user_records" */
export type User_Records_On_Conflict = {
  constraint: User_Records_Constraint;
  update_columns?: Array<User_Records_Update_Column>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};

/** Ordering options when selecting data from "user_records". */
export type User_Records_Order_By = {
  adminMessage?: InputMaybe<Order_By>;
  audioRecordUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  mutoonSection?: InputMaybe<Mutoon_Sections_Order_By>;
  planSections_aggregate?: InputMaybe<Plan_Sections_Aggregate_Order_By>;
  recordingId?: InputMaybe<Order_By>;
  sectionId?: InputMaybe<Order_By>;
  sound?: InputMaybe<Sounds_Order_By>;
  soundId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_records */
export type User_Records_Pk_Columns_Input = {
  recordingId: Scalars['String']['input'];
};

/** select columns of table "user_records" */
export enum User_Records_Select_Column {
  /** column name */
  AdminMessage = 'adminMessage',
  /** column name */
  AudioRecordUrl = 'audioRecordUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  RecordingId = 'recordingId',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  SoundId = 'soundId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_records" */
export type User_Records_Set_Input = {
  adminMessage?: InputMaybe<Scalars['String']['input']>;
  audioRecordUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  recordingId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  soundId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_records" */
export type User_Records_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Records_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Records_Stream_Cursor_Value_Input = {
  adminMessage?: InputMaybe<Scalars['String']['input']>;
  audioRecordUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  recordingId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  soundId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_records" */
export enum User_Records_Update_Column {
  /** column name */
  AdminMessage = 'adminMessage',
  /** column name */
  AudioRecordUrl = 'audioRecordUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  RecordingId = 'recordingId',
  /** column name */
  SectionId = 'sectionId',
  /** column name */
  SoundId = 'soundId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Records_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Records_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Records_Bool_Exp;
};

/** columns and relationships of "user_scientific_sessions" */
export type User_Scientific_Sessions = {
  __typename?: 'user_scientific_sessions';
  adminId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role: Scalars['userRole']['output'];
  scientificSessionId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user_scientific_sessions_scientific_session: Scientific_Sessions;
  /** An object relationship */
  user_scientific_sessions_user?: Maybe<Users>;
};

/** aggregated selection of "user_scientific_sessions" */
export type User_Scientific_Sessions_Aggregate = {
  __typename?: 'user_scientific_sessions_aggregate';
  aggregate?: Maybe<User_Scientific_Sessions_Aggregate_Fields>;
  nodes: Array<User_Scientific_Sessions>;
};

export type User_Scientific_Sessions_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Scientific_Sessions_Aggregate_Bool_Exp_Count>;
};

export type User_Scientific_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_scientific_sessions" */
export type User_Scientific_Sessions_Aggregate_Fields = {
  __typename?: 'user_scientific_sessions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Scientific_Sessions_Max_Fields>;
  min?: Maybe<User_Scientific_Sessions_Min_Fields>;
};


/** aggregate fields of "user_scientific_sessions" */
export type User_Scientific_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_scientific_sessions" */
export type User_Scientific_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Scientific_Sessions_Max_Order_By>;
  min?: InputMaybe<User_Scientific_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_scientific_sessions" */
export type User_Scientific_Sessions_Arr_Rel_Insert_Input = {
  data: Array<User_Scientific_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Scientific_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_scientific_sessions". All fields are combined with a logical 'AND'. */
export type User_Scientific_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Scientific_Sessions_Bool_Exp>>;
  _not?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Scientific_Sessions_Bool_Exp>>;
  adminId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  meetingUrl?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<UserRole_Comparison_Exp>;
  scientificSessionId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transactionId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_scientific_sessions_scientific_session?: InputMaybe<Scientific_Sessions_Bool_Exp>;
  user_scientific_sessions_user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "user_scientific_sessions" */
export enum User_Scientific_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserScientificSessionsPkey = 'user_scientific_sessions_pkey'
}

/** input type for inserting data into table "user_scientific_sessions" */
export type User_Scientific_Sessions_Insert_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  scientificSessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_scientific_sessions_scientific_session?: InputMaybe<Scientific_Sessions_Obj_Rel_Insert_Input>;
  user_scientific_sessions_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Scientific_Sessions_Max_Fields = {
  __typename?: 'user_scientific_sessions_max_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  scientificSessionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_scientific_sessions" */
export type User_Scientific_Sessions_Max_Order_By = {
  adminId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  scientificSessionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Scientific_Sessions_Min_Fields = {
  __typename?: 'user_scientific_sessions_min_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  scientificSessionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_scientific_sessions" */
export type User_Scientific_Sessions_Min_Order_By = {
  adminId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  scientificSessionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_scientific_sessions" */
export type User_Scientific_Sessions_Mutation_Response = {
  __typename?: 'user_scientific_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Scientific_Sessions>;
};

/** on_conflict condition type for table "user_scientific_sessions" */
export type User_Scientific_Sessions_On_Conflict = {
  constraint: User_Scientific_Sessions_Constraint;
  update_columns?: Array<User_Scientific_Sessions_Update_Column>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_scientific_sessions". */
export type User_Scientific_Sessions_Order_By = {
  adminId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  scientificSessionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  user_scientific_sessions_scientific_session?: InputMaybe<Scientific_Sessions_Order_By>;
  user_scientific_sessions_user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: user_scientific_sessions */
export type User_Scientific_Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_scientific_sessions" */
export enum User_Scientific_Sessions_Select_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  Role = 'role',
  /** column name */
  ScientificSessionId = 'scientificSessionId',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_scientific_sessions" */
export type User_Scientific_Sessions_Set_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  scientificSessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_scientific_sessions" */
export type User_Scientific_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Scientific_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Scientific_Sessions_Stream_Cursor_Value_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  scientificSessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_scientific_sessions" */
export enum User_Scientific_Sessions_Update_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  Role = 'role',
  /** column name */
  ScientificSessionId = 'scientificSessionId',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UserId = 'userId'
}

export type User_Scientific_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Scientific_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Scientific_Sessions_Bool_Exp;
};

/** columns and relationships of "user_sessions" */
export type User_Sessions = {
  __typename?: 'user_sessions';
  /** An object relationship */
  admin?: Maybe<Admins>;
  adminId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role: Scalars['userRole']['output'];
  /** An object relationship */
  session: Sessions;
  sessionId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  subscriped: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "user_sessions" */
export type User_Sessions_Aggregate = {
  __typename?: 'user_sessions_aggregate';
  aggregate?: Maybe<User_Sessions_Aggregate_Fields>;
  nodes: Array<User_Sessions>;
};

export type User_Sessions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Sessions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Sessions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Sessions_Aggregate_Bool_Exp_Count>;
};

export type User_Sessions_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Sessions_Select_Column_User_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Sessions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Sessions_Select_Column_User_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_sessions" */
export type User_Sessions_Aggregate_Fields = {
  __typename?: 'user_sessions_aggregate_fields';
  avg?: Maybe<User_Sessions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Sessions_Max_Fields>;
  min?: Maybe<User_Sessions_Min_Fields>;
  stddev?: Maybe<User_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sessions_Sum_Fields>;
  var_pop?: Maybe<User_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Sessions_Var_Samp_Fields>;
  variance?: Maybe<User_Sessions_Variance_Fields>;
};


/** aggregate fields of "user_sessions" */
export type User_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_sessions" */
export type User_Sessions_Aggregate_Order_By = {
  avg?: InputMaybe<User_Sessions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Sessions_Max_Order_By>;
  min?: InputMaybe<User_Sessions_Min_Order_By>;
  stddev?: InputMaybe<User_Sessions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Sessions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Sessions_Sum_Order_By>;
  var_pop?: InputMaybe<User_Sessions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Sessions_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Sessions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_sessions" */
export type User_Sessions_Arr_Rel_Insert_Input = {
  data: Array<User_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Sessions_Avg_Fields = {
  __typename?: 'user_sessions_avg_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_sessions" */
export type User_Sessions_Avg_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_sessions". All fields are combined with a logical 'AND'. */
export type User_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Sessions_Bool_Exp>>;
  _not?: InputMaybe<User_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Sessions_Bool_Exp>>;
  admin?: InputMaybe<Admins_Bool_Exp>;
  adminId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  meetingUrl?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<UserRole_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  sessionId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscriped?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_sessions" */
export enum User_Sessions_Constraint {
  /** unique or primary key constraint on columns "adminId", "sessionId" */
  UserSessionsAdminIdSessionIdKey = 'user_sessions_adminId_sessionId_key',
  /** unique or primary key constraint on columns "id" */
  UserSessionsPkey = 'user_sessions_pkey',
  /** unique or primary key constraint on columns "userId", "sessionId" */
  UserSessionsUserIdSessionIdKey = 'user_sessions_userId_sessionId_key'
}

/** input type for incrementing numeric columns in table "user_sessions" */
export type User_Sessions_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_sessions" */
export type User_Sessions_Insert_Input = {
  admin?: InputMaybe<Admins_Obj_Rel_Insert_Input>;
  adminId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscriped?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Sessions_Max_Fields = {
  __typename?: 'user_sessions_max_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_sessions" */
export type User_Sessions_Max_Order_By = {
  adminId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Sessions_Min_Fields = {
  __typename?: 'user_sessions_min_fields';
  adminId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_sessions" */
export type User_Sessions_Min_Order_By = {
  adminId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_sessions" */
export type User_Sessions_Mutation_Response = {
  __typename?: 'user_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Sessions>;
};

/** on_conflict condition type for table "user_sessions" */
export type User_Sessions_On_Conflict = {
  constraint: User_Sessions_Constraint;
  update_columns?: Array<User_Sessions_Update_Column>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_sessions". */
export type User_Sessions_Order_By = {
  admin?: InputMaybe<Admins_Order_By>;
  adminId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingUrl?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  sessionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscriped?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_sessions */
export type User_Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_sessions" */
export enum User_Sessions_Select_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  Role = 'role',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Status = 'status',
  /** column name */
  Subscriped = 'subscriped',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** select "user_sessions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_sessions" */
export enum User_Sessions_Select_Column_User_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Subscriped = 'subscriped'
}

/** select "user_sessions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_sessions" */
export enum User_Sessions_Select_Column_User_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Subscriped = 'subscriped'
}

/** input type for updating data in table "user_sessions" */
export type User_Sessions_Set_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscriped?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type User_Sessions_Stddev_Fields = {
  __typename?: 'user_sessions_stddev_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_sessions" */
export type User_Sessions_Stddev_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Sessions_Stddev_Pop_Fields = {
  __typename?: 'user_sessions_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_sessions" */
export type User_Sessions_Stddev_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Sessions_Stddev_Samp_Fields = {
  __typename?: 'user_sessions_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_sessions" */
export type User_Sessions_Stddev_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_sessions" */
export type User_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Sessions_Stream_Cursor_Value_Input = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscriped?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type User_Sessions_Sum_Fields = {
  __typename?: 'user_sessions_sum_fields';
  duration?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_sessions" */
export type User_Sessions_Sum_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** update columns of table "user_sessions" */
export enum User_Sessions_Update_Column {
  /** column name */
  AdminId = 'adminId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingUrl = 'meetingUrl',
  /** column name */
  Role = 'role',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Status = 'status',
  /** column name */
  Subscriped = 'subscriped',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Sessions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Sessions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Sessions_Var_Pop_Fields = {
  __typename?: 'user_sessions_var_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_sessions" */
export type User_Sessions_Var_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Sessions_Var_Samp_Fields = {
  __typename?: 'user_sessions_var_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_sessions" */
export type User_Sessions_Var_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Sessions_Variance_Fields = {
  __typename?: 'user_sessions_variance_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_sessions" */
export type User_Sessions_Variance_Order_By = {
  duration?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_subscriptions" */
export type User_Subscriptions = {
  __typename?: 'user_subscriptions';
  id: Scalars['String']['output'];
  role: Scalars['userRole']['output'];
  subscriptionId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "user_subscriptions" */
export type User_Subscriptions_Aggregate = {
  __typename?: 'user_subscriptions_aggregate';
  aggregate?: Maybe<User_Subscriptions_Aggregate_Fields>;
  nodes: Array<User_Subscriptions>;
};

/** aggregate fields of "user_subscriptions" */
export type User_Subscriptions_Aggregate_Fields = {
  __typename?: 'user_subscriptions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Subscriptions_Max_Fields>;
  min?: Maybe<User_Subscriptions_Min_Fields>;
};


/** aggregate fields of "user_subscriptions" */
export type User_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_subscriptions". All fields are combined with a logical 'AND'. */
export type User_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<User_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Subscriptions_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<UserRole_Comparison_Exp>;
  subscriptionId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_subscriptions" */
export enum User_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSubscriptionsPkey = 'user_subscriptions_pkey'
}

/** input type for inserting data into table "user_subscriptions" */
export type User_Subscriptions_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Subscriptions_Max_Fields = {
  __typename?: 'user_subscriptions_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Subscriptions_Min_Fields = {
  __typename?: 'user_subscriptions_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['userRole']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_subscriptions" */
export type User_Subscriptions_Mutation_Response = {
  __typename?: 'user_subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscriptions>;
};

/** on_conflict condition type for table "user_subscriptions" */
export type User_Subscriptions_On_Conflict = {
  constraint: User_Subscriptions_Constraint;
  update_columns?: Array<User_Subscriptions_Update_Column>;
  where?: InputMaybe<User_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_subscriptions". */
export type User_Subscriptions_Order_By = {
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_subscriptions */
export type User_Subscriptions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_subscriptions" */
export enum User_Subscriptions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_subscriptions" */
export type User_Subscriptions_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_subscriptions" */
export type User_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Subscriptions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['userRole']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_subscriptions" */
export enum User_Subscriptions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId'
}

export type User_Subscriptions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Subscriptions_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  Type: Scalars['String']['output'];
  /** An object relationship */
  authorized_user?: Maybe<Authorized_Users>;
  avatar: Scalars['String']['output'];
  birthDate?: Maybe<Scalars['date']['output']>;
  contributionCount: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isPreferencesChoosen: Scalars['Boolean']['output'];
  isSchool: Scalars['Boolean']['output'];
  joinHearingCouncil: Scalars['Int']['output'];
  joinSessionCount: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  lastStreak?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  school_user?: Maybe<School_Users>;
  sharesCount: Scalars['Int']['output'];
  signinMethod: Scalars['String']['output'];
  status: Scalars['String']['output'];
  streaks: Scalars['Int']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  userRecords: Array<User_Records>;
  /** An aggregate relationship */
  userRecords_aggregate: User_Records_Aggregate;
  /** An array relationship */
  user_badges: Array<User_Badges>;
  /** An aggregate relationship */
  user_badges_aggregate: User_Badges_Aggregate;
  /** An array relationship */
  user_scientific_sessions: Array<User_Scientific_Sessions>;
  /** An aggregate relationship */
  user_scientific_sessions_aggregate: User_Scientific_Sessions_Aggregate;
  /** An array relationship */
  user_sessions: Array<User_Sessions>;
  /** An aggregate relationship */
  user_sessions_aggregate: User_Sessions_Aggregate;
  /** An array relationship */
  users_user_hearing_councils: Array<User_Hearing_Councils>;
  /** An aggregate relationship */
  users_user_hearing_councils_aggregate: User_Hearing_Councils_Aggregate;
  verified: Scalars['Boolean']['output'];
};


/** columns and relationships of "users" */
export type UsersNotesArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notes_Order_By>>;
  where?: InputMaybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUserRecordsArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUserRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Records_Order_By>>;
  where?: InputMaybe<User_Records_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_BadgesArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Badges_Order_By>>;
  where?: InputMaybe<User_Badges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Scientific_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Scientific_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Scientific_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Scientific_Sessions_Order_By>>;
  where?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Sessions_Order_By>>;
  where?: InputMaybe<User_Sessions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_User_Hearing_CouncilsArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_User_Hearing_Councils_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Hearing_Councils_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By>>;
  where?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  Type?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  authorized_user?: InputMaybe<Authorized_Users_Bool_Exp>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  birthDate?: InputMaybe<Date_Comparison_Exp>;
  contributionCount?: InputMaybe<Int_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fcmTokens?: InputMaybe<String_Array_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isPreferencesChoosen?: InputMaybe<Boolean_Comparison_Exp>;
  isSchool?: InputMaybe<Boolean_Comparison_Exp>;
  joinHearingCouncil?: InputMaybe<Int_Comparison_Exp>;
  joinSessionCount?: InputMaybe<Int_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastStreak?: InputMaybe<Timestamp_Comparison_Exp>;
  notes?: InputMaybe<Notes_Bool_Exp>;
  notes_aggregate?: InputMaybe<Notes_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  school_user?: InputMaybe<School_Users_Bool_Exp>;
  sharesCount?: InputMaybe<Int_Comparison_Exp>;
  signinMethod?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  streaks?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userRecords?: InputMaybe<User_Records_Bool_Exp>;
  userRecords_aggregate?: InputMaybe<User_Records_Aggregate_Bool_Exp>;
  user_badges?: InputMaybe<User_Badges_Bool_Exp>;
  user_badges_aggregate?: InputMaybe<User_Badges_Aggregate_Bool_Exp>;
  user_scientific_sessions?: InputMaybe<User_Scientific_Sessions_Bool_Exp>;
  user_scientific_sessions_aggregate?: InputMaybe<User_Scientific_Sessions_Aggregate_Bool_Exp>;
  user_sessions?: InputMaybe<User_Sessions_Bool_Exp>;
  user_sessions_aggregate?: InputMaybe<User_Sessions_Aggregate_Bool_Exp>;
  users_user_hearing_councils?: InputMaybe<User_Hearing_Councils_Bool_Exp>;
  users_user_hearing_councils_aggregate?: InputMaybe<User_Hearing_Councils_Aggregate_Bool_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  contributionCount?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  joinHearingCouncil?: InputMaybe<Scalars['Int']['input']>;
  joinSessionCount?: InputMaybe<Scalars['Int']['input']>;
  sharesCount?: InputMaybe<Scalars['Int']['input']>;
  streaks?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  Type?: InputMaybe<Scalars['String']['input']>;
  authorized_user?: InputMaybe<Authorized_Users_Obj_Rel_Insert_Input>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['date']['input']>;
  contributionCount?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPreferencesChoosen?: InputMaybe<Scalars['Boolean']['input']>;
  isSchool?: InputMaybe<Scalars['Boolean']['input']>;
  joinHearingCouncil?: InputMaybe<Scalars['Int']['input']>;
  joinSessionCount?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastStreak?: InputMaybe<Scalars['timestamp']['input']>;
  notes?: InputMaybe<Notes_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  school_user?: InputMaybe<School_Users_Obj_Rel_Insert_Input>;
  sharesCount?: InputMaybe<Scalars['Int']['input']>;
  signinMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  streaks?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userRecords?: InputMaybe<User_Records_Arr_Rel_Insert_Input>;
  user_badges?: InputMaybe<User_Badges_Arr_Rel_Insert_Input>;
  user_scientific_sessions?: InputMaybe<User_Scientific_Sessions_Arr_Rel_Insert_Input>;
  user_sessions?: InputMaybe<User_Sessions_Arr_Rel_Insert_Input>;
  users_user_hearing_councils?: InputMaybe<User_Hearing_Councils_Arr_Rel_Insert_Input>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  Type?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['date']['output']>;
  contributionCount?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Int']['output']>;
  joinSessionCount?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastStreak?: Maybe<Scalars['timestamp']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sharesCount?: Maybe<Scalars['Int']['output']>;
  signinMethod?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  streaks?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  Type?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['date']['output']>;
  contributionCount?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmTokens?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Int']['output']>;
  joinSessionCount?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastStreak?: Maybe<Scalars['timestamp']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sharesCount?: Maybe<Scalars['Int']['output']>;
  signinMethod?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  streaks?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  Type?: InputMaybe<Order_By>;
  authorized_user?: InputMaybe<Authorized_Users_Order_By>;
  avatar?: InputMaybe<Order_By>;
  birthDate?: InputMaybe<Order_By>;
  contributionCount?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fcmTokens?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPreferencesChoosen?: InputMaybe<Order_By>;
  isSchool?: InputMaybe<Order_By>;
  joinHearingCouncil?: InputMaybe<Order_By>;
  joinSessionCount?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastStreak?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Notes_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  school_user?: InputMaybe<School_Users_Order_By>;
  sharesCount?: InputMaybe<Order_By>;
  signinMethod?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  streaks?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userRecords_aggregate?: InputMaybe<User_Records_Aggregate_Order_By>;
  user_badges_aggregate?: InputMaybe<User_Badges_Aggregate_Order_By>;
  user_scientific_sessions_aggregate?: InputMaybe<User_Scientific_Sessions_Aggregate_Order_By>;
  user_sessions_aggregate?: InputMaybe<User_Sessions_Aggregate_Order_By>;
  users_user_hearing_councils_aggregate?: InputMaybe<User_Hearing_Councils_Aggregate_Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Type = 'Type',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  ContributionCount = 'contributionCount',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  FcmTokens = 'fcmTokens',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreferencesChoosen = 'isPreferencesChoosen',
  /** column name */
  IsSchool = 'isSchool',
  /** column name */
  JoinHearingCouncil = 'joinHearingCouncil',
  /** column name */
  JoinSessionCount = 'joinSessionCount',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastStreak = 'lastStreak',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  SharesCount = 'sharesCount',
  /** column name */
  SigninMethod = 'signinMethod',
  /** column name */
  Status = 'status',
  /** column name */
  Streaks = 'streaks',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  Type?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['date']['input']>;
  contributionCount?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPreferencesChoosen?: InputMaybe<Scalars['Boolean']['input']>;
  isSchool?: InputMaybe<Scalars['Boolean']['input']>;
  joinHearingCouncil?: InputMaybe<Scalars['Int']['input']>;
  joinSessionCount?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastStreak?: InputMaybe<Scalars['timestamp']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  sharesCount?: InputMaybe<Scalars['Int']['input']>;
  signinMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  streaks?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  Type?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['date']['input']>;
  contributionCount?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPreferencesChoosen?: InputMaybe<Scalars['Boolean']['input']>;
  isSchool?: InputMaybe<Scalars['Boolean']['input']>;
  joinHearingCouncil?: InputMaybe<Scalars['Int']['input']>;
  joinSessionCount?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastStreak?: InputMaybe<Scalars['timestamp']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  sharesCount?: InputMaybe<Scalars['Int']['input']>;
  signinMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  streaks?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  contributionCount?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Int']['output']>;
  joinSessionCount?: Maybe<Scalars['Int']['output']>;
  sharesCount?: Maybe<Scalars['Int']['output']>;
  streaks?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Type = 'Type',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  ContributionCount = 'contributionCount',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  FcmTokens = 'fcmTokens',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IsPreferencesChoosen = 'isPreferencesChoosen',
  /** column name */
  IsSchool = 'isSchool',
  /** column name */
  JoinHearingCouncil = 'joinHearingCouncil',
  /** column name */
  JoinSessionCount = 'joinSessionCount',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastStreak = 'lastStreak',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  SharesCount = 'sharesCount',
  /** column name */
  SigninMethod = 'signinMethod',
  /** column name */
  Status = 'status',
  /** column name */
  Streaks = 'streaks',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Verified = 'verified'
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  contributionCount?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  joinHearingCouncil?: Maybe<Scalars['Float']['output']>;
  joinSessionCount?: Maybe<Scalars['Float']['output']>;
  sharesCount?: Maybe<Scalars['Float']['output']>;
  streaks?: Maybe<Scalars['Float']['output']>;
};

export type CreateRoleActionMutationVariables = Exact<{
  roleId?: InputMaybe<Scalars['String']['input']>;
  actionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateRoleActionMutation = { __typename?: 'mutation_root', insert_roles_actions_one?: { __typename?: 'roles_actions', actionId: string } | null };

export type DeleteRoleActionMutationVariables = Exact<{
  roleId?: InputMaybe<Scalars['String']['input']>;
  actionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteRoleActionMutation = { __typename?: 'mutation_root', delete_roles_actions_by_pk?: { __typename?: 'roles_actions', actionId: string } | null };

export type DeleteAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteAdminMutation = { __typename?: 'mutation_root', delete_admins_by_pk?: { __typename?: 'admins', id: string } | null };

export type AddAdminMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddAdminMutation = { __typename?: 'mutation_root', createAdmin?: { __typename?: 'AdminAuthResponse', user?: { __typename?: 'Admin', id: string } | null } | null };

export type UpdateAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateAdminMutation = { __typename?: 'mutation_root', update_admins_by_pk?: { __typename?: 'admins', id: string } | null };

export type ApproveUserMutationVariables = Exact<{
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  objects?: InputMaybe<Array<User_Categories_Insert_Input> | User_Categories_Insert_Input>;
  adminMsg?: InputMaybe<Scalars['String']['input']>;
}>;


export type ApproveUserMutation = { __typename?: 'mutation_root', update_authorized_users_by_pk?: { __typename?: 'authorized_users', authorizedUserId: string } | null, insert_user_categories?: { __typename?: 'user_categories_mutation_response', returning: Array<{ __typename?: 'user_categories', authorizedUserId?: string | null }> } | null, update_users?: { __typename?: 'users_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'users', id: string }> } | null };

export type RejectUserMutationVariables = Exact<{
  authorizedUserId: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  adminMsg?: InputMaybe<Scalars['String']['input']>;
}>;


export type RejectUserMutation = { __typename?: 'mutation_root', update_authorized_users_by_pk?: { __typename?: 'authorized_users', authorizedUserId: string } | null };

export type AddBadgeMutationVariables = Exact<{
  badgeImageURL?: InputMaybe<Scalars['String']['input']>;
  programName?: InputMaybe<Scalars['jsonb']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  generalType?: InputMaybe<Scalars['generalBadgeType']['input']>;
}>;


export type AddBadgeMutation = { __typename?: 'mutation_root', insert_badges_one?: { __typename?: 'badges', badgeId: string } | null };

export type EditBadgeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  programName?: InputMaybe<Scalars['jsonb']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  badgeImageURL?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  generalType?: InputMaybe<Scalars['generalBadgeType']['input']>;
}>;


export type EditBadgeMutation = { __typename?: 'mutation_root', update_badges_by_pk?: { __typename?: 'badges', badgeId: string } | null };

export type DeleteBadgeMutationVariables = Exact<{
  badgeId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteBadgeMutation = { __typename?: 'mutation_root', delete_badges_by_pk?: { __typename?: 'badges', badgeId: string } | null };

export type DeleteCategoryMutationVariables = Exact<{
  categoryId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteCategoryMutation = { __typename?: 'mutation_root', delete_categories_by_pk?: { __typename?: 'categories', categoryId: string } | null };

export type AddCategoryMutationVariables = Exact<{
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddCategoryMutation = { __typename?: 'mutation_root', insert_categories_one?: { __typename?: 'categories', categoryId: string } | null };

export type UpdateCategoryMutationVariables = Exact<{
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type UpdateCategoryMutation = { __typename?: 'mutation_root', update_categories_by_pk?: { __typename?: 'categories', categoryId: string } | null };

export type AddCertificateVariablesMutationVariables = Exact<{
  objects?: InputMaybe<Array<Certificate_Variables_Insert_Input> | Certificate_Variables_Insert_Input>;
}>;


export type AddCertificateVariablesMutation = { __typename?: 'mutation_root', insert_certificate_variables?: { __typename?: 'certificate_variables_mutation_response', returning: Array<{ __typename?: 'certificate_variables', certID?: string | null }> } | null };

export type AddCertificateMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddCertificateMutation = { __typename?: 'mutation_root', insert_certificates_one?: { __typename?: 'certificates', certificateId: string } | null };

export type CreateCouncilCertificateMutationVariables = Exact<{
  createCertficateInput?: InputMaybe<Array<CreateCertficateInput> | CreateCertficateInput>;
}>;


export type CreateCouncilCertificateMutation = { __typename?: 'mutation_root', createUserHearingCouncilCertificate: Array<{ __typename?: 'UserHearingCouncil', userCertificateId?: string | null, userCertificate?: { __typename?: 'UserCertificate', certificateId?: string | null, downloadPath?: string | null } | null }> };

export type DeleteCertificatesMutationVariables = Exact<{
  certificateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteCertificatesMutation = { __typename?: 'mutation_root', delete_certificates_by_pk?: { __typename?: 'certificates', certificateId: string } | null };

export type DeleteContactMsgMutationVariables = Exact<{
  contactId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteContactMsgMutation = { __typename?: 'mutation_root', delete_contact_us_by_pk?: { __typename?: 'contact_us', contactId: string } | null };

export type SendEmailToContactMutationVariables = Exact<{
  contactId: Scalars['String']['input'];
  response: Scalars['String']['input'];
}>;


export type SendEmailToContactMutation = { __typename?: 'mutation_root', SendEmailToContact: { __typename?: 'Contact', contactId: string, name?: string | null, email?: string | null, status?: string | null, reason?: string | null, message?: string | null, response?: string | null } };

export type AddExamMutationVariables = Exact<{
  certificateexamId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  gradeA?: InputMaybe<Scalars['Int']['input']>;
  gradeB?: InputMaybe<Scalars['Int']['input']>;
  gradeC?: InputMaybe<Scalars['Int']['input']>;
  gradeD?: InputMaybe<Scalars['Int']['input']>;
  gradeF?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minimumScore?: InputMaybe<Scalars['Int']['input']>;
  mutonId: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type AddExamMutation = { __typename?: 'mutation_root', insert_exams_one?: { __typename?: 'exams', examId: string, title?: any | null, mutoon: { __typename?: 'mutoons', id: string, name: any } } | null };

export type UpdateExamMutationVariables = Exact<{
  certificateexamId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  gradeA?: InputMaybe<Scalars['Int']['input']>;
  gradeB?: InputMaybe<Scalars['Int']['input']>;
  gradeC?: InputMaybe<Scalars['Int']['input']>;
  gradeD?: InputMaybe<Scalars['Int']['input']>;
  gradeF?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  minimumScore?: InputMaybe<Scalars['Int']['input']>;
  mutonId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  examId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateExamMutation = { __typename?: 'mutation_root', update_exams_by_pk?: { __typename?: 'exams', examId: string } | null };

export type DeleteExamMutationVariables = Exact<{
  examId: Scalars['String']['input'];
}>;


export type DeleteExamMutation = { __typename?: 'mutation_root', delete_exams_by_pk?: { __typename?: 'exams', examId: string } | null };

export type AddFaqMutationVariables = Exact<{
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type AddFaqMutation = { __typename?: 'mutation_root', insert_faqs_one?: { __typename?: 'faqs', id: string } | null };

export type EditFaqMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type EditFaqMutation = { __typename?: 'mutation_root', update_faqs_by_pk?: { __typename?: 'faqs', id: string } | null };

export type DeleteFaqMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteFaqMutation = { __typename?: 'mutation_root', delete_faqs_by_pk?: { __typename?: 'faqs', id: string } | null };

export type DeleteHearingCouncilMutationVariables = Exact<{
  hearingCouncilId: Scalars['String']['input'];
}>;


export type DeleteHearingCouncilMutation = { __typename?: 'mutation_root', delete_user_hearing_councils?: { __typename?: 'user_hearing_councils_mutation_response', affected_rows: number } | null, delete_hearing_councils_by_pk?: { __typename?: 'hearing_councils', hearingCouncilId: string } | null };

export type CreateHearingCouncilMutationVariables = Exact<{
  mutoonMatnId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  price: Scalars['Float']['input'];
  expiryDate: Scalars['DateTime']['input'];
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['JSONObject']['input'];
  sessionName: Scalars['JSONObject']['input'];
  sheikhName?: InputMaybe<Scalars['ID']['input']>;
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  max_user_count?: InputMaybe<Scalars['Int']['input']>;
  prerequisiteIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  certificateHearingCouncilId: Scalars['String']['input'];
  autoCertificate?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateHearingCouncilMutation = { __typename?: 'mutation_root', createHearingCouncil: { __typename?: 'HearingCouncil', hearingCouncilId: string } };

export type UpdateHearingCouncilFormMutationVariables = Exact<{
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
  expiryDate?: InputMaybe<Scalars['timestamptz']['input']>;
  sheikhName?: InputMaybe<Scalars['String']['input']>;
  sessionName?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  appointmentLimit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateHearingCouncilFormMutation = { __typename?: 'mutation_root', update_hearing_councils_by_pk?: { __typename?: 'hearing_councils', hearingCouncilId: string } | null };

export type SubscribeHearingCouncilMutationVariables = Exact<{
  hearingCouncilId: Scalars['String']['input'];
  adminId: Scalars['String']['input'];
}>;


export type SubscribeHearingCouncilMutation = { __typename?: 'mutation_root', insert_user_hearing_councils?: { __typename?: 'user_hearing_councils_mutation_response', returning: Array<{ __typename?: 'user_hearing_councils', hearingCouncil: { __typename?: 'hearing_councils', hearing_councils_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', userHearingCouncilId: string }> } }> } | null };

export type JoinHearingCounciMutationVariables = Exact<{
  hearingCouncilId: Scalars['String']['input'];
}>;


export type JoinHearingCounciMutation = { __typename?: 'mutation_root', joinHearingCouncil: { __typename?: 'joinHearingCouncilResponse', join_link?: string | null, stream_key?: string | null, watch_link?: string | null } };

export type UpdateHearingCouncilMutationVariables = Exact<{
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  _set?: InputMaybe<Hearing_Councils_Set_Input>;
}>;


export type UpdateHearingCouncilMutation = { __typename?: 'mutation_root', update_hearing_councils_by_pk?: { __typename?: 'hearing_councils', hearingCouncilId: string } | null };

export type DeleteKnowledgeBaseMutationVariables = Exact<{
  knowledgeBaseId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteKnowledgeBaseMutation = { __typename?: 'mutation_root', delete_knowledge_bases_by_pk?: { __typename?: 'knowledge_bases', knowledgeBaseId: string } | null };

export type AddKnowledgeBaseMutationVariables = Exact<{
  content?: InputMaybe<Scalars['jsonb']['input']>;
  cover_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type AddKnowledgeBaseMutation = { __typename?: 'mutation_root', insert_knowledge_bases_one?: { __typename?: 'knowledge_bases', knowledgeBaseId: string } | null };

export type UpdateKnowledgeBaseMutationVariables = Exact<{
  content?: InputMaybe<Scalars['jsonb']['input']>;
  cover_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  knowledgeBaseId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateKnowledgeBaseMutation = { __typename?: 'mutation_root', update_knowledge_bases_by_pk?: { __typename?: 'knowledge_bases', knowledgeBaseId: string } | null };

export type DeleteLevelMutationVariables = Exact<{
  levelId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteLevelMutation = { __typename?: 'mutation_root', delete_levels_by_pk?: { __typename?: 'levels', categoryId?: string | null } | null };

export type AddLevelMutationVariables = Exact<{
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddLevelMutation = { __typename?: 'mutation_root', insert_levels_one?: { __typename?: 'levels', levelId: string } | null };

export type UpdateLevelMutationVariables = Exact<{
  levelId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type UpdateLevelMutation = { __typename?: 'mutation_root', update_levels_by_pk?: { __typename?: 'levels', levelId: string } | null };

export type LoginAdminMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginAdminMutation = { __typename?: 'mutation_root', loginAdmin?: { __typename?: 'AdminAuthResponse', token?: string | null, user?: { __typename?: 'Admin', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string, role?: { __typename?: 'AdminRole', permissions: Array<{ __typename?: 'Permission', id: string, name: string, systemCriteria: string }> } | null } | null } | null };

export type DeleteMatnSectionMutationVariables = Exact<{
  mutoonSectionId: Scalars['String']['input'];
}>;


export type DeleteMatnSectionMutation = { __typename?: 'mutation_root', delete_mutoon_sections_by_pk?: { __typename?: 'mutoon_sections', mutoonSectionId: string } | null };

export type AddMatnSectionMutationVariables = Exact<{
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  matnId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddMatnSectionMutation = { __typename?: 'mutation_root', insert_mutoon_sections_one?: { __typename?: 'mutoon_sections', mutoonSectionId: string } | null };

export type UpdateMatnSectionMutationVariables = Exact<{
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  mutoonSectionId: Scalars['String']['input'];
}>;


export type UpdateMatnSectionMutation = { __typename?: 'mutation_root', update_mutoon_sections_by_pk?: { __typename?: 'mutoon_sections', mutoonSectionId: string } | null };

export type DeleteMatnMutationVariables = Exact<{
  mutoonId: Scalars['String']['input'];
}>;


export type DeleteMatnMutation = { __typename?: 'mutation_root', delete_mutoon_categories?: { __typename?: 'mutoon_categories_mutation_response', affected_rows: number } | null, delete_mutoons_by_pk?: { __typename?: 'mutoons', id: string } | null };

export type AddMatnMutationVariables = Exact<{
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfHadith?: InputMaybe<Scalars['Int']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddMatnMutation = { __typename?: 'mutation_root', insert_mutoons_one?: { __typename?: 'mutoons', id: string } | null };

export type UpdateMatnMutationVariables = Exact<{
  matnId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfHadith?: InputMaybe<Scalars['Int']['input']>;
  referenceBookBookId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateMatnMutation = { __typename?: 'mutation_root', update_mutoons_by_pk?: { __typename?: 'mutoons', id: string } | null };

export type AttachMatnMutationVariables = Exact<{
  categoryId?: InputMaybe<Scalars['String']['input']>;
  mutoonId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AttachMatnMutation = { __typename?: 'mutation_root', insert_mutoon_categories_one?: { __typename?: 'mutoon_categories', id: string } | null };

export type DeleteQuestionMutationVariables = Exact<{
  questionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteQuestionMutation = { __typename?: 'mutation_root', delete_questions_by_pk?: { __typename?: 'questions', questionId: string } | null };

export type AddQuestionsMutationVariables = Exact<{
  questions: Array<Questions_Insert_Input> | Questions_Insert_Input;
}>;


export type AddQuestionsMutation = { __typename?: 'mutation_root', insert_questions?: { __typename?: 'questions_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'questions', questionId: string }> } | null };

export type UpdateQuestionsMutationVariables = Exact<{
  updates: Array<Questions_Updates> | Questions_Updates;
}>;


export type UpdateQuestionsMutation = { __typename?: 'mutation_root', update_questions_many?: Array<{ __typename?: 'questions_mutation_response', affected_rows: number } | null> | null };

export type AddReferenceBookMutationVariables = Exact<{
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  dateOfBorn?: InputMaybe<Scalars['timestamp']['input']>;
  dateOfDeath?: InputMaybe<Scalars['timestamp']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfLines?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type AddReferenceBookMutation = { __typename?: 'mutation_root', insert_reference_books_one?: { __typename?: 'reference_books', bookId: string } | null };

export type UpdateReferenceBookMutationVariables = Exact<{
  bookId?: InputMaybe<Scalars['String']['input']>;
  bookAuthor?: InputMaybe<Scalars['jsonb']['input']>;
  bookLanguage?: InputMaybe<Scalars['jsonb']['input']>;
  bookName?: InputMaybe<Scalars['jsonb']['input']>;
  dateOfBorn?: InputMaybe<Scalars['timestamp']['input']>;
  dateOfDeath?: InputMaybe<Scalars['timestamp']['input']>;
  fromSchool?: InputMaybe<Scalars['jsonb']['input']>;
  nazemName?: InputMaybe<Scalars['jsonb']['input']>;
  numberOfLines?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['jsonb']['input']>;
  pdfUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateReferenceBookMutation = { __typename?: 'mutation_root', update_reference_books_by_pk?: { __typename?: 'reference_books', bookId: string, pdfUrl?: string | null } | null };

export type DeleteRefBookMutationVariables = Exact<{
  bookId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteRefBookMutation = { __typename?: 'mutation_root', delete_reference_books_by_pk?: { __typename?: 'reference_books', bookId: string } | null };

export type CreateRoleMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  role_actions?: InputMaybe<Roles_Actions_Arr_Rel_Insert_Input>;
}>;


export type CreateRoleMutation = { __typename?: 'mutation_root', insert_roles_one?: { __typename?: 'roles', id: string } | null };

export type EditRoleMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditRoleMutation = { __typename?: 'mutation_root', update_roles_by_pk?: { __typename?: 'roles', id: string } | null };

export type DeleteRoleMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteRoleMutation = { __typename?: 'mutation_root', delete_roles_by_pk?: { __typename?: 'roles', id: string } | null };

export type ApproveSchoolUserMutationVariables = Exact<{
  schoolUserId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type ApproveSchoolUserMutation = { __typename?: 'mutation_root', update_school_users_by_pk?: { __typename?: 'school_users', schoolUserId: string } | null, update_users_by_pk?: { __typename?: 'users', id: string } | null };

export type RejectSchoolUserMutationVariables = Exact<{
  schoolUserId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type RejectSchoolUserMutation = { __typename?: 'mutation_root', update_school_users_by_pk?: { __typename?: 'school_users', schoolUserId: string } | null, update_users_by_pk?: { __typename?: 'users', id: string } | null };

export type DeleteSessionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSessionMutation = { __typename?: 'mutation_root', delete_user_sessions?: { __typename?: 'user_sessions_mutation_response', affected_rows: number } | null, delete_sessions_by_pk?: { __typename?: 'sessions', id: string } | null };

export type CreateSessionMutationVariables = Exact<{
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate: Scalars['DateTime']['input'];
  description: Scalars['JSONObject']['input'];
  mutoonMatnId: Scalars['String']['input'];
  title: Scalars['JSONObject']['input'];
  enable_conversation?: InputMaybe<Scalars['Boolean']['input']>;
  enable_raise_hand?: InputMaybe<Scalars['Boolean']['input']>;
  enable_recording?: InputMaybe<Scalars['Boolean']['input']>;
  enable_upload_files?: InputMaybe<Scalars['Boolean']['input']>;
  enable_video?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  max_user_count: Scalars['Int']['input'];
  prerequisiteIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  days?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateSessionMutation = { __typename?: 'mutation_root', createSession: { __typename?: 'Session', id: string } };

export type SubscribeSessionMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type SubscribeSessionMutation = { __typename?: 'mutation_root', subscribeSession: { __typename?: 'Session', id: string, title: any, description: any, startDate?: any | null, expiryDate?: any | null, type?: string | null, status?: string | null, delayLimit?: number | null, courseFileURL?: string | null, zoomLink?: string | null, youTubeLink?: string | null, days?: Array<string> | null, max_user_count?: number | null, frequency?: string | null, enable_raise_hand?: boolean | null, enable_conversation?: boolean | null, enable_upload_files?: boolean | null, enable_recording?: boolean | null, enable_video?: boolean | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, mutoonMatnId?: string | null } };

export type JoinSessionMutationVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type JoinSessionMutation = { __typename?: 'mutation_root', joinSession: string };

export type AddSoundsMutationVariables = Exact<{
  sounds: Array<Sounds_Insert_Input> | Sounds_Insert_Input;
}>;


export type AddSoundsMutation = { __typename?: 'mutation_root', insert_sounds?: { __typename?: 'sounds_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'sounds', soundId: string, fileUrl: string }> } | null };

export type DeleteSoundMutationVariables = Exact<{
  soundId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteSoundMutation = { __typename?: 'mutation_root', delete_sounds_by_pk?: { __typename?: 'sounds', soundId: string } | null };

export type UpdateSoundMutationVariables = Exact<{
  soundId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSoundMutation = { __typename?: 'mutation_root', update_sounds_by_pk?: { __typename?: 'sounds', soundId: string } | null };

export type AddWordsDefinitionsMutationVariables = Exact<{
  translations: Array<Translations_Insert_Input> | Translations_Insert_Input;
}>;


export type AddWordsDefinitionsMutation = { __typename?: 'mutation_root', insert_translations?: { __typename?: 'translations_mutation_response', returning: Array<{ __typename?: 'translations', mutoonSectionId: string }> } | null };

export type DeleteTranslationMutationVariables = Exact<{
  translationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteTranslationMutation = { __typename?: 'mutation_root', delete_translations_by_pk?: { __typename?: 'translations', translationId: string } | null };

export type UpdateTranslationMutationVariables = Exact<{
  translationId?: InputMaybe<Scalars['String']['input']>;
  translatedText?: InputMaybe<Scalars['jsonb']['input']>;
  word?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type UpdateTranslationMutation = { __typename?: 'mutation_root', update_translations_by_pk?: { __typename?: 'translations', translatedText: any, word: any } | null };

export type DeleteUserRecordMutationVariables = Exact<{
  recordingId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteUserRecordMutation = { __typename?: 'mutation_root', delete_user_records_by_pk?: { __typename?: 'user_records', recordingId: string } | null };

export type ApproveUserRecordMutationVariables = Exact<{
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  mutoonSectionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['jsonb']['input']>;
  recordingId: Scalars['String']['input'];
}>;


export type ApproveUserRecordMutation = { __typename?: 'mutation_root', insert_sounds_one?: { __typename?: 'sounds', soundId: string } | null, update_user_records_by_pk?: { __typename?: 'user_records', recordingId: string } | null };

export type RejectUserRecordMutationVariables = Exact<{
  recordingId: Scalars['String']['input'];
  adminMessage?: InputMaybe<Scalars['String']['input']>;
}>;


export type RejectUserRecordMutation = { __typename?: 'mutation_root', update_user_records_by_pk?: { __typename?: 'user_records', recordingId: string } | null };

export type DeleteUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', delete_users_by_pk?: { __typename?: 'users', id: string } | null };

export type ActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActionsQuery = { __typename?: 'query_root', actions: Array<{ __typename?: 'actions', id: string, systemCriteria: string, name: string }> };

export type AllAdminsQueryVariables = Exact<{
  username?: InputMaybe<Order_By>;
}>;


export type AllAdminsQuery = { __typename?: 'query_root', admins: Array<{ __typename?: 'admins', id: string, username: string, roleId: string }> };

export type AdminsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<Admins_Order_By> | Admins_Order_By>;
}>;


export type AdminsQuery = { __typename?: 'query_root', admins: Array<{ __typename?: 'admins', id: string, roleId: string, username: string, email: string, role: { __typename?: 'roles', name: string } }>, admins_aggregate: { __typename?: 'admins_aggregate', aggregate?: { __typename?: 'admins_aggregate_fields', count: number } | null } };

export type OneAdminQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneAdminQuery = { __typename?: 'query_root', admins_by_pk?: { __typename?: 'admins', id: string, roleId: string, username: string, email: string, role: { __typename?: 'roles', name: string } } | null };

export type Authorized_UserFragment = { __typename?: 'authorized_users', authorizedUserId: string, createdAt: any, fieldOfStudy?: string | null, notes?: string | null, status: string, adminMsg?: string | null, updatedAt: any, userId: string, user: { __typename?: 'users', firstName: string, lastName: string }, files: Array<{ __typename?: 'files', id: string, name?: string | null, status?: string | null, type?: string | null, url?: string | null }>, files_aggregate: { __typename?: 'files_aggregate', aggregate?: { __typename?: 'files_aggregate_fields', count: number } | null }, user_categories: Array<{ __typename?: 'user_categories', categoryId: string, id: string, category: { __typename?: 'categories', name: any } }> };

export type AuthorizedUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<Authorized_Users_Order_By> | Authorized_Users_Order_By>;
}>;


export type AuthorizedUsersQuery = { __typename?: 'query_root', authorized_users: Array<{ __typename?: 'authorized_users', authorizedUserId: string, createdAt: any, fieldOfStudy?: string | null, notes?: string | null, status: string, adminMsg?: string | null, updatedAt: any, userId: string, user: { __typename?: 'users', firstName: string, lastName: string }, files: Array<{ __typename?: 'files', id: string, name?: string | null, status?: string | null, type?: string | null, url?: string | null }>, files_aggregate: { __typename?: 'files_aggregate', aggregate?: { __typename?: 'files_aggregate_fields', count: number } | null }, user_categories: Array<{ __typename?: 'user_categories', categoryId: string, id: string, category: { __typename?: 'categories', name: any } }> }>, authorized_users_aggregate: { __typename?: 'authorized_users_aggregate', aggregate?: { __typename?: 'authorized_users_aggregate_fields', count: number } | null } };

export type AuthorizedUserByIdQueryVariables = Exact<{
  authorizedUserId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AuthorizedUserByIdQuery = { __typename?: 'query_root', authorized_users_by_pk?: { __typename?: 'authorized_users', authorizedUserId: string, createdAt: any, fieldOfStudy?: string | null, notes?: string | null, status: string, adminMsg?: string | null, updatedAt: any, userId: string, user: { __typename?: 'users', firstName: string, lastName: string }, files: Array<{ __typename?: 'files', id: string, name?: string | null, status?: string | null, type?: string | null, url?: string | null }>, files_aggregate: { __typename?: 'files_aggregate', aggregate?: { __typename?: 'files_aggregate_fields', count: number } | null }, user_categories: Array<{ __typename?: 'user_categories', categoryId: string, id: string, category: { __typename?: 'categories', name: any } }> } | null };

export type BadgeFragment = { __typename?: 'badges', badgeId: string, badgeImageURL?: string | null, categoryId?: string | null, hearingCouncilId?: string | null, mutoonId?: string | null, programName: any, createdAt: any, updatedAt: any, count?: number | null, generalType?: any | null, category?: { __typename?: 'categories', name: any } | null, mutoon?: { __typename?: 'mutoons', name: any } | null, hearing_council?: { __typename?: 'hearing_councils', sessionName?: any | null } | null };

export type BadgesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Badges_Order_By> | Badges_Order_By>;
  where?: InputMaybe<Badges_Bool_Exp>;
}>;


export type BadgesQuery = { __typename?: 'query_root', badges: Array<{ __typename?: 'badges', badgeId: string, badgeImageURL?: string | null, categoryId?: string | null, hearingCouncilId?: string | null, mutoonId?: string | null, programName: any, createdAt: any, updatedAt: any, count?: number | null, generalType?: any | null, category?: { __typename?: 'categories', name: any } | null, mutoon?: { __typename?: 'mutoons', name: any } | null, hearing_council?: { __typename?: 'hearing_councils', sessionName?: any | null } | null }>, badges_aggregate: { __typename?: 'badges_aggregate', aggregate?: { __typename?: 'badges_aggregate_fields', count: number } | null } };

export type OneBadgeQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneBadgeQuery = { __typename?: 'query_root', badges_by_pk?: { __typename?: 'badges', badgeId: string, badgeImageURL?: string | null, categoryId?: string | null, hearingCouncilId?: string | null, mutoonId?: string | null, programName: any, createdAt: any, updatedAt: any, count?: number | null, generalType?: any | null, category?: { __typename?: 'categories', name: any } | null, mutoon?: { __typename?: 'mutoons', name: any } | null, hearing_council?: { __typename?: 'hearing_councils', sessionName?: any | null } | null } | null };

export type CategoryFragment = { __typename?: 'categories', categoryId: string, parentId?: string | null, name: any, description?: any | null, updatedAt: any };

export type AllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCategoriesQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', categoryId: string, name: any }> };

export type CategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
}>;


export type CategoriesQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', categoryId: string, parentId?: string | null, name: any, description?: any | null, updatedAt: any }>, categories_aggregate: { __typename?: 'categories_aggregate', aggregate?: { __typename?: 'categories_aggregate_fields', count: number } | null } };

export type CategoryByIdQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CategoryByIdQuery = { __typename?: 'query_root', categories_by_pk?: { __typename?: 'categories', categoryId: string, parentId?: string | null, name: any, description?: any | null, updatedAt: any } | null };

export type CertificatesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Certificates_Order_By> | Certificates_Order_By>;
  search?: InputMaybe<String_Comparison_Exp>;
}>;


export type CertificatesQuery = { __typename?: 'query_root', certificates: Array<{ __typename?: 'certificates', certificateId: string, createdAt: any, image?: string | null, title?: string | null }>, certificates_aggregate: { __typename?: 'certificates_aggregate', aggregate?: { __typename?: 'certificates_aggregate_fields', count: number } | null } };

export type AllCertificatesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCertificatesQuery = { __typename?: 'query_root', certificates: Array<{ __typename?: 'certificates', certificateId: string, createdAt: any, image?: string | null, title?: string | null }>, certificates_aggregate: { __typename?: 'certificates_aggregate', aggregate?: { __typename?: 'certificates_aggregate_fields', count: number } | null } };

export type GetUserCertificateQueryVariables = Exact<{
  userHearingCouncilId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserCertificateQuery = { __typename?: 'query_root', user_certificates: Array<{ __typename?: 'user_certificates', downloadPath?: string | null }> };

export type ContactFragment = { __typename?: 'contact_us', contactId: string, createdAt: any, email: string, message: string, name: string, reason: string, response?: string | null, status: string, updatedAt: any, userId?: string | null };

export type ContactUsQueryVariables = Exact<{
  where?: InputMaybe<Contact_Us_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By> | Contact_Us_Order_By>;
}>;


export type ContactUsQuery = { __typename?: 'query_root', contact_us: Array<{ __typename?: 'contact_us', contactId: string, createdAt: any, email: string, message: string, name: string, reason: string, response?: string | null, status: string, updatedAt: any, userId?: string | null }>, contact_us_aggregate: { __typename?: 'contact_us_aggregate', aggregate?: { __typename?: 'contact_us_aggregate_fields', count: number } | null } };

export type ExamFragment = { __typename?: 'exams', description?: any | null, updatedAt: any, examId: string, mutonId: string, title?: any | null, gradeA: number, gradeB: number, gradeC: number, gradeD: number, gradeF: number, maxAttempts: number, minimumScore: number, limit: number, status: string, duration: number, certificateexamId?: string | null, mutoon: { __typename?: 'mutoons', name: any, categoryId?: string | null, levelId?: string | null, id: string } };

export type ExamsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<Exams_Order_By> | Exams_Order_By>;
}>;


export type ExamsQuery = { __typename?: 'query_root', exams: Array<{ __typename?: 'exams', description?: any | null, updatedAt: any, examId: string, mutonId: string, title?: any | null, gradeA: number, gradeB: number, gradeC: number, gradeD: number, gradeF: number, maxAttempts: number, minimumScore: number, limit: number, status: string, duration: number, certificateexamId?: string | null, mutoon: { __typename?: 'mutoons', name: any, categoryId?: string | null, levelId?: string | null, id: string } }>, exams_aggregate: { __typename?: 'exams_aggregate', aggregate?: { __typename?: 'exams_aggregate_fields', count: number } | null } };

export type OneExamQueryVariables = Exact<{
  examId?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneExamQuery = { __typename?: 'query_root', exams_by_pk?: { __typename?: 'exams', description?: any | null, updatedAt: any, examId: string, mutonId: string, title?: any | null, gradeA: number, gradeB: number, gradeC: number, gradeD: number, gradeF: number, maxAttempts: number, minimumScore: number, limit: number, status: string, duration: number, certificateexamId?: string | null, mutoon: { __typename?: 'mutoons', name: any, categoryId?: string | null, levelId?: string | null, id: string } } | null };

export type FaQsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By> | Faqs_Order_By>;
  where?: InputMaybe<Faqs_Bool_Exp>;
}>;


export type FaQsQuery = { __typename?: 'query_root', faqs: Array<{ __typename?: 'faqs', answer: any, question: any, createdAt: any, updatedAt: any, id: string }>, faqs_aggregate: { __typename?: 'faqs_aggregate', aggregate?: { __typename?: 'faqs_aggregate_fields', count: number } | null } };

export type OneFaqQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneFaqQuery = { __typename?: 'query_root', faqs_by_pk?: { __typename?: 'faqs', answer: any, createdAt: any, id: string, question: any, updatedAt: any } | null };

export type Hearing_CouncilFragment = { __typename?: 'hearing_councils', courseFileURL?: string | null, deletedAt?: any | null, description?: any | null, price?: any | null, referenceBookBookId?: string | null, roomId?: string | null, sessionName?: any | null, sheikhName?: string | null, youTubeLink?: string | null, zoomLink?: string | null, startDate?: any | null, duration: number, expiryDate?: any | null, createdAt: any, updatedAt: any, autoCertificate?: boolean | null, certificateHearingCouncilId?: string | null, hearingCouncilId: string, status?: string | null, appointmentLimit: number, enable_conversation?: boolean | null, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, days?: Array<string> | null, max_user_count?: number | null, hearing_councils_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, hearing_councils_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', status?: string | null, user_certificate?: { __typename?: 'user_certificates', id: string, downloadPath?: string | null } | null, admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, mutoon?: { __typename?: 'mutoons', id: string, name: any, categoryId?: string | null, levelId?: string | null } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> };

export type HearingCouncilsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiryDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<Hearing_Councils_Order_By> | Hearing_Councils_Order_By>;
}>;


export type HearingCouncilsQuery = { __typename?: 'query_root', hearing_councils: Array<{ __typename?: 'hearing_councils', courseFileURL?: string | null, deletedAt?: any | null, description?: any | null, price?: any | null, referenceBookBookId?: string | null, roomId?: string | null, sessionName?: any | null, sheikhName?: string | null, youTubeLink?: string | null, zoomLink?: string | null, startDate?: any | null, duration: number, expiryDate?: any | null, createdAt: any, updatedAt: any, autoCertificate?: boolean | null, certificateHearingCouncilId?: string | null, hearingCouncilId: string, status?: string | null, appointmentLimit: number, enable_conversation?: boolean | null, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, days?: Array<string> | null, max_user_count?: number | null, hearing_councils_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, hearing_councils_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', status?: string | null, user_certificate?: { __typename?: 'user_certificates', id: string, downloadPath?: string | null } | null, admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, mutoon?: { __typename?: 'mutoons', id: string, name: any, categoryId?: string | null, levelId?: string | null } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> }>, hearing_councils_aggregate: { __typename?: 'hearing_councils_aggregate', aggregate?: { __typename?: 'hearing_councils_aggregate_fields', count: number } | null } };

export type OneHearingCouncilQueryVariables = Exact<{
  hearingCouncilId: Scalars['String']['input'];
}>;


export type OneHearingCouncilQuery = { __typename?: 'query_root', hearing_councils_by_pk?: { __typename?: 'hearing_councils', courseFileURL?: string | null, deletedAt?: any | null, description?: any | null, price?: any | null, referenceBookBookId?: string | null, roomId?: string | null, sessionName?: any | null, sheikhName?: string | null, youTubeLink?: string | null, zoomLink?: string | null, startDate?: any | null, duration: number, expiryDate?: any | null, createdAt: any, updatedAt: any, autoCertificate?: boolean | null, certificateHearingCouncilId?: string | null, hearingCouncilId: string, status?: string | null, appointmentLimit: number, enable_conversation?: boolean | null, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, days?: Array<string> | null, max_user_count?: number | null, hearing_councils_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, hearing_councils_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', status?: string | null, user_certificate?: { __typename?: 'user_certificates', id: string, downloadPath?: string | null } | null, admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, mutoon?: { __typename?: 'mutoons', id: string, name: any, categoryId?: string | null, levelId?: string | null } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> } | null };

export type HearingCouncilAnalysisQueryVariables = Exact<{
  hearingCouncilId?: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<User_Hearing_Councils_Order_By> | User_Hearing_Councils_Order_By>;
  user?: InputMaybe<Users_Bool_Exp>;
}>;


export type HearingCouncilAnalysisQuery = { __typename?: 'query_root', user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', joinedAt?: any | null, durationAttendance?: number | null, userHearingCouncilId: string, numSubmissions?: number | null, user_certificate?: { __typename?: 'user_certificates', id: string, downloadPath?: string | null, userHearingCouncil?: { __typename?: 'user_hearing_councils', hearingCouncil: { __typename?: 'hearing_councils', hearingCouncilId: string } } | null } | null, user?: { __typename?: 'users', avatar: string, firstName: string, lastName: string, id: string, email: string, school_user?: { __typename?: 'school_users', students: Array<{ __typename?: 'students', firstName: string, lastName?: string | null, email?: string | null, id: string, user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', hearingCouncilId: string, userHearingCouncilId: string, user_certificate?: { __typename?: 'user_certificates', downloadPath?: string | null, certificateId: string } | null }> }> } | null } | null, hearingCouncil: { __typename?: 'hearing_councils', duration: number } }>, user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null } };

export type HearingCouncilsAnalysisQueryVariables = Exact<{
  where?: InputMaybe<Hearing_Councils_Bool_Exp>;
}>;


export type HearingCouncilsAnalysisQuery = { __typename?: 'query_root', hearing_councils: Array<{ __typename?: 'hearing_councils', courseFileURL?: string | null, deletedAt?: any | null, description?: any | null, price?: any | null, referenceBookBookId?: string | null, roomId?: string | null, sessionName?: any | null, sheikhName?: string | null, youTubeLink?: string | null, zoomLink?: string | null, startDate?: any | null, duration: number, expiryDate?: any | null, createdAt: any, updatedAt: any, autoCertificate?: boolean | null, certificateHearingCouncilId?: string | null, hearingCouncilId: string, status?: string | null, appointmentLimit: number, enable_conversation?: boolean | null, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, days?: Array<string> | null, max_user_count?: number | null, hearing_councils_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, hearing_councils_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', status?: string | null, user_certificate?: { __typename?: 'user_certificates', id: string, downloadPath?: string | null } | null, admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, mutoon?: { __typename?: 'mutoons', id: string, name: any, categoryId?: string | null, levelId?: string | null } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> }>, hearing_councils_aggregate: { __typename?: 'hearing_councils_aggregate', aggregate?: { __typename?: 'hearing_councils_aggregate_fields', count: number } | null } };

export type UserHearingCouncilByCouncilIdAndUserIdQueryVariables = Exact<{
  hearingCouncilId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserHearingCouncilByCouncilIdAndUserIdQuery = { __typename?: 'query_root', user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', userHearingCouncilId: string }> };

export type Knowledge_BaseFragment = { __typename?: 'knowledge_bases', content: any, cover_url?: string | null, knowledgeBaseId: string, status: string, title: any, createdAt: any };

export type KnowledgeBasesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Knowledge_Bases_Order_By> | Knowledge_Bases_Order_By>;
  search?: InputMaybe<String_Comparison_Exp>;
}>;


export type KnowledgeBasesQuery = { __typename?: 'query_root', knowledge_bases: Array<{ __typename?: 'knowledge_bases', content: any, cover_url?: string | null, knowledgeBaseId: string, status: string, title: any, createdAt: any }>, knowledge_bases_aggregate: { __typename?: 'knowledge_bases_aggregate', aggregate?: { __typename?: 'knowledge_bases_aggregate_fields', count: number } | null } };

export type OneKnowledgeBaseQueryVariables = Exact<{
  knowledgeBaseId?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneKnowledgeBaseQuery = { __typename?: 'query_root', knowledge_bases_by_pk?: { __typename?: 'knowledge_bases', content: any, cover_url?: string | null, knowledgeBaseId: string, status: string, title: any, createdAt: any } | null };

export type AllLevelsQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllLevelsQuery = { __typename?: 'query_root', levels: Array<{ __typename?: 'levels', categoryId?: string | null, levelId: string, name: any }> };

export type LevelsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['String']['input']>;
}>;


export type LevelsQuery = { __typename?: 'query_root', levels: Array<{ __typename?: 'levels', categoryId?: string | null, description?: any | null, levelId: string, name: any, updatedAt: any, createdAt: any }>, levels_aggregate: { __typename?: 'levels_aggregate', aggregate?: { __typename?: 'levels_aggregate_fields', count: number } | null } };

export type LevelByIdQueryVariables = Exact<{
  levelId?: InputMaybe<Scalars['String']['input']>;
}>;


export type LevelByIdQuery = { __typename?: 'query_root', levels_by_pk?: { __typename?: 'levels', categoryId?: string | null, description?: any | null, levelId: string, name: any, updatedAt: any, createdAt: any } | null };

export type MatnSectionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  mutoonMatnId?: InputMaybe<Scalars['String']['input']>;
  order_by?: InputMaybe<Array<Mutoon_Sections_Order_By> | Mutoon_Sections_Order_By>;
}>;


export type MatnSectionsQuery = { __typename?: 'query_root', mutoon_sections: Array<{ __typename?: 'mutoon_sections', description?: any | null, name: any, updatedAt: any, mutoonSectionId: string, mutoonMatnId?: string | null }>, mutoon_sections_aggregate: { __typename?: 'mutoon_sections_aggregate', aggregate?: { __typename?: 'mutoon_sections_aggregate_fields', count: number } | null } };

export type AllMatnSectionsQueryVariables = Exact<{
  matnId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllMatnSectionsQuery = { __typename?: 'query_root', mutoon_sections: Array<{ __typename?: 'mutoon_sections', name: any, mutoonMatnId?: string | null, mutoonSectionId: string }> };

export type MatnSectionBySectionIdQueryVariables = Exact<{
  mutoonSectionId: Scalars['String']['input'];
}>;


export type MatnSectionBySectionIdQuery = { __typename?: 'query_root', mutoon_sections_by_pk?: { __typename?: 'mutoon_sections', description?: any | null, name: any } | null };

export type MutoonCategoriesQueryVariables = Exact<{
  categoryCond: String_Comparison_Exp;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Jsonb_Comparison_Exp>;
  order_by?: InputMaybe<Array<Categories_Order_By> | Categories_Order_By>;
  exclude_ctgs?: InputMaybe<String_Comparison_Exp>;
}>;


export type MutoonCategoriesQuery = { __typename?: 'query_root', categories: Array<{ __typename?: 'categories', categoryId: string, parentId?: string | null, name: any, description?: any | null, updatedAt: any }>, categories_aggregate: { __typename?: 'categories_aggregate', aggregate?: { __typename?: 'categories_aggregate_fields', count: number } | null }, mutoon_categories: Array<{ __typename?: 'mutoon_categories', categoryId: string, mutoonId: string, id: string, mutoon: { __typename: 'mutoons', id: string, description?: any | null, name: any, updatedAt: any, numberOfHadith: number, createdAt: any, mutoonType?: string | null, referenceBookBookId?: string | null, referenceBook?: { __typename: 'reference_books', bookId: string, dateOfBorn?: any | null, dateOfDeath?: any | null, pdfUrl?: string | null, nazemName?: any | null, numberOfLines?: number | null, fromSchool?: any | null } | null }, category: { __typename?: 'categories', categoryId: string, name: any } }>, mutoon_categories_aggregate: { __typename?: 'mutoon_categories_aggregate', aggregate?: { __typename?: 'mutoon_categories_aggregate_fields', count: number } | null } };

export type MatnFragment = { __typename: 'mutoons', id: string, description?: any | null, name: any, updatedAt: any, numberOfHadith: number, createdAt: any, mutoonType?: string | null, referenceBookBookId?: string | null, referenceBook?: { __typename: 'reference_books', bookId: string, dateOfBorn?: any | null, dateOfDeath?: any | null, pdfUrl?: string | null, nazemName?: any | null, numberOfLines?: number | null, fromSchool?: any | null } | null };

export type AllMutoonsQueryVariables = Exact<{
  cateogryId?: InputMaybe<String_Comparison_Exp>;
}>;


export type AllMutoonsQuery = { __typename?: 'query_root', mutoon_categories: Array<{ __typename?: 'mutoon_categories', id: string, categoryId: string, mutoonId: string, mutoon: { __typename?: 'mutoons', name: any } }> };

export type MutoonsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<String_Comparison_Exp>;
}>;


export type MutoonsQuery = { __typename?: 'query_root', mutoons: Array<{ __typename: 'mutoons', id: string, description?: any | null, name: any, updatedAt: any, numberOfHadith: number, createdAt: any, mutoonType?: string | null, referenceBookBookId?: string | null, referenceBook?: { __typename: 'reference_books', bookId: string, dateOfBorn?: any | null, dateOfDeath?: any | null, pdfUrl?: string | null, nazemName?: any | null, numberOfLines?: number | null, fromSchool?: any | null } | null }>, mutoons_aggregate: { __typename?: 'mutoons_aggregate', aggregate?: { __typename?: 'mutoons_aggregate_fields', count: number } | null } };

export type MutoonByIdQueryVariables = Exact<{
  mutoonId: Scalars['String']['input'];
}>;


export type MutoonByIdQuery = { __typename?: 'query_root', mutoons_by_pk?: { __typename: 'mutoons', id: string, description?: any | null, name: any, updatedAt: any, numberOfHadith: number, createdAt: any, mutoonType?: string | null, referenceBookBookId?: string | null, referenceBook?: { __typename: 'reference_books', bookId: string, dateOfBorn?: any | null, dateOfDeath?: any | null, pdfUrl?: string | null, nazemName?: any | null, numberOfLines?: number | null, fromSchool?: any | null } | null } | null };

export type QuestionFragment = { __typename?: 'questions', correctAnswerId?: string | null, correctAnswerText?: any | null, explanation?: any | null, hint?: any | null, isQuiz: boolean, mutoonSectionId?: string | null, points: number, questionId: string, questionText: any, type: any, updatedAt: any, choicesType?: string | null, choicesOptions?: Array<string> | null, correctAnswerIndex?: number | null, mutoon_section?: { __typename?: 'mutoon_sections', name: any, mutoon?: { __typename?: 'mutoons', name: any } | null } | null, exam?: { __typename?: 'exams', mutoon: { __typename?: 'mutoons', name: any } } | null };

export type QuestionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Jsonb_Comparison_Exp>;
  examId?: InputMaybe<String_Comparison_Exp>;
  matnName?: InputMaybe<Jsonb_Comparison_Exp>;
  matnId?: InputMaybe<String_Comparison_Exp>;
  matnSectionId?: InputMaybe<String_Comparison_Exp>;
  choicesType?: InputMaybe<String_Comparison_Exp>;
  isQuiz?: InputMaybe<Boolean_Comparison_Exp>;
  order_by?: InputMaybe<Array<Questions_Order_By> | Questions_Order_By>;
}>;


export type QuestionsQuery = { __typename?: 'query_root', questions: Array<{ __typename?: 'questions', correctAnswerId?: string | null, correctAnswerText?: any | null, explanation?: any | null, hint?: any | null, isQuiz: boolean, mutoonSectionId?: string | null, points: number, questionId: string, questionText: any, type: any, updatedAt: any, choicesType?: string | null, choicesOptions?: Array<string> | null, correctAnswerIndex?: number | null, mutoon_section?: { __typename?: 'mutoon_sections', name: any, mutoon?: { __typename?: 'mutoons', name: any } | null } | null, exam?: { __typename?: 'exams', mutoon: { __typename?: 'mutoons', name: any } } | null }>, questions_aggregate: { __typename?: 'questions_aggregate', aggregate?: { __typename?: 'questions_aggregate_fields', count: number } | null } };

export type OneQuestionsQueryVariables = Exact<{
  questionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneQuestionsQuery = { __typename?: 'query_root', questions_by_pk?: { __typename?: 'questions', correctAnswerId?: string | null, correctAnswerText?: any | null, explanation?: any | null, hint?: any | null, isQuiz: boolean, mutoonSectionId?: string | null, points: number, questionId: string, questionText: any, type: any, updatedAt: any, choicesType?: string | null, choicesOptions?: Array<string> | null, correctAnswerIndex?: number | null, mutoon_section?: { __typename?: 'mutoon_sections', name: any, mutoon?: { __typename?: 'mutoons', name: any } | null } | null, exam?: { __typename?: 'exams', mutoon: { __typename?: 'mutoons', name: any } } | null } | null };

export type BookFragment = { __typename?: 'reference_books', bookAuthor?: any | null, bookId: string, bookLanguage?: any | null, bookName?: any | null, dateOfBorn?: any | null, dateOfDeath?: any | null, fromSchool?: any | null, nazemName?: any | null, numberOfLines?: number | null, pdfUrl?: string | null, updatedAt: any, title?: any | null };

export type ReferenceBooksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<Reference_Books_Order_By> | Reference_Books_Order_By>;
  matnId?: InputMaybe<String_Comparison_Exp>;
}>;


export type ReferenceBooksQuery = { __typename?: 'query_root', reference_books: Array<{ __typename?: 'reference_books', bookAuthor?: any | null, bookId: string, bookLanguage?: any | null, bookName?: any | null, dateOfBorn?: any | null, dateOfDeath?: any | null, fromSchool?: any | null, nazemName?: any | null, numberOfLines?: number | null, pdfUrl?: string | null, updatedAt: any, title?: any | null }>, reference_books_aggregate: { __typename?: 'reference_books_aggregate', aggregate?: { __typename?: 'reference_books_aggregate_fields', count: number } | null } };

export type OneReferenceBookQueryVariables = Exact<{
  bookId?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneReferenceBookQuery = { __typename?: 'query_root', reference_books_by_pk?: { __typename?: 'reference_books', bookAuthor?: any | null, bookId: string, bookLanguage?: any | null, bookName?: any | null, dateOfBorn?: any | null, dateOfDeath?: any | null, fromSchool?: any | null, nazemName?: any | null, numberOfLines?: number | null, pdfUrl?: string | null, updatedAt: any, title?: any | null } | null };

export type AllReferenceBooksQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Reference_Books_Order_By> | Reference_Books_Order_By>;
}>;


export type AllReferenceBooksQuery = { __typename?: 'query_root', reference_books: Array<{ __typename?: 'reference_books', bookAuthor?: any | null, bookId: string, bookLanguage?: any | null, bookName?: any | null, dateOfBorn?: any | null, dateOfDeath?: any | null, fromSchool?: any | null, nazemName?: any | null, numberOfLines?: number | null, pdfUrl?: string | null, updatedAt: any, title?: any | null }> };

export type RoleActionsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']['input']>;
}>;


export type RoleActionsQuery = { __typename?: 'query_root', roles_actions: Array<{ __typename?: 'roles_actions', action: { __typename?: 'actions', id: string, systemCriteria: string, name: string } }> };

export type RoleByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type RoleByIdQuery = { __typename?: 'query_root', roles_by_pk?: { __typename?: 'roles', id: string, name: string } | null };

export type AllRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRolesQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'roles', id: string, name: string }> };

export type RolesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_by?: InputMaybe<Array<Roles_Order_By> | Roles_Order_By>;
}>;


export type RolesQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'roles', id: string, name: string, admins: Array<{ __typename?: 'admins', username: string }> }>, roles_aggregate: { __typename?: 'roles_aggregate', aggregate?: { __typename?: 'roles_aggregate_fields', count: number } | null } };

export type SchoolStudentsQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Students_Order_By> | Students_Order_By>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  userId: Scalars['String']['input'];
  hearingCouncilId: Scalars['String']['input'];
}>;


export type SchoolStudentsQuery = { __typename?: 'query_root', students: Array<{ __typename?: 'students', birthDate?: any | null, createdAt: any, email?: string | null, firstName: string, id: string, lastName?: string | null, user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', hearingCouncilId: string, userHearingCouncilId: string }>, user_certificates: Array<{ __typename?: 'user_certificates', downloadPath?: string | null, userHearingCouncil?: { __typename?: 'user_hearing_councils', hearingCouncil: { __typename?: 'hearing_councils', hearingCouncilId: string } } | null }> }>, students_aggregate: { __typename?: 'students_aggregate', aggregate?: { __typename?: 'students_aggregate_fields', count: number } | null } };

export type School_UserFragment = { __typename?: 'school_users', city?: string | null, country?: string | null, createdAt: any, deletedAt?: any | null, updatedAt: any, schoolUserId: string, status: string, schoolName: string, schoolAddress?: string | null, files: Array<{ __typename?: 'files', id: string, name?: string | null, type?: string | null, url?: string | null }>, files_aggregate: { __typename?: 'files_aggregate', aggregate?: { __typename?: 'files_aggregate_fields', count: number } | null }, user: { __typename?: 'users', firstName: string, lastName: string, email: string, id: string } };

export type SchoolUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<School_Users_Order_By> | School_Users_Order_By>;
  search?: InputMaybe<String_Comparison_Exp>;
}>;


export type SchoolUsersQuery = { __typename?: 'query_root', school_users: Array<{ __typename?: 'school_users', city?: string | null, country?: string | null, createdAt: any, deletedAt?: any | null, updatedAt: any, schoolUserId: string, status: string, schoolName: string, schoolAddress?: string | null, files: Array<{ __typename?: 'files', id: string, name?: string | null, type?: string | null, url?: string | null }>, files_aggregate: { __typename?: 'files_aggregate', aggregate?: { __typename?: 'files_aggregate_fields', count: number } | null }, user: { __typename?: 'users', firstName: string, lastName: string, email: string, id: string } }>, school_users_aggregate: { __typename?: 'school_users_aggregate', aggregate?: { __typename?: 'school_users_aggregate_fields', count: number } | null } };

export type SchoolUserByIdQueryVariables = Exact<{
  schoolUserId?: InputMaybe<Scalars['String']['input']>;
}>;


export type SchoolUserByIdQuery = { __typename?: 'query_root', school_users_by_pk?: { __typename?: 'school_users', city?: string | null, country?: string | null, createdAt: any, deletedAt?: any | null, updatedAt: any, schoolUserId: string, status: string, schoolName: string, schoolAddress?: string | null, files: Array<{ __typename?: 'files', id: string, name?: string | null, type?: string | null, url?: string | null }>, files_aggregate: { __typename?: 'files_aggregate', aggregate?: { __typename?: 'files_aggregate_fields', count: number } | null }, user: { __typename?: 'users', firstName: string, lastName: string, email: string, id: string } } | null };

export type SessionFragment = { __typename?: 'sessions', delayLimit: number, description?: any | null, expiryDate?: any | null, meetingId?: string | null, id: string, startDate: any, status?: string | null, title?: any | null, transactionId?: string | null, type?: string | null, updatedAt: any, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, max_user_count?: number | null, duration: number, enable_conversation?: boolean | null, days?: Array<string> | null, user_sessions: Array<{ __typename?: 'user_sessions', admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, user_sessions_aggregate: { __typename?: 'user_sessions_aggregate', aggregate?: { __typename?: 'user_sessions_aggregate_fields', count: number } | null }, mutoon?: { __typename?: 'mutoons', id: string, name: any } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> };

export type SessionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiryDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  order_by?: InputMaybe<Array<Sessions_Order_By> | Sessions_Order_By>;
}>;


export type SessionsQuery = { __typename?: 'query_root', sessions: Array<{ __typename?: 'sessions', delayLimit: number, description?: any | null, expiryDate?: any | null, meetingId?: string | null, id: string, startDate: any, status?: string | null, title?: any | null, transactionId?: string | null, type?: string | null, updatedAt: any, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, max_user_count?: number | null, duration: number, enable_conversation?: boolean | null, days?: Array<string> | null, user_sessions: Array<{ __typename?: 'user_sessions', admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, user_sessions_aggregate: { __typename?: 'user_sessions_aggregate', aggregate?: { __typename?: 'user_sessions_aggregate_fields', count: number } | null }, mutoon?: { __typename?: 'mutoons', id: string, name: any } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> }>, sessions_aggregate: { __typename?: 'sessions_aggregate', aggregate?: { __typename?: 'sessions_aggregate_fields', count: number } | null } };

export type SessionsAnalysisQueryVariables = Exact<{
  where?: InputMaybe<Sessions_Bool_Exp>;
}>;


export type SessionsAnalysisQuery = { __typename?: 'query_root', sessions: Array<{ __typename?: 'sessions', delayLimit: number, description?: any | null, expiryDate?: any | null, meetingId?: string | null, id: string, startDate: any, status?: string | null, title?: any | null, transactionId?: string | null, type?: string | null, updatedAt: any, enable_raise_hand?: boolean | null, enable_recording?: boolean | null, enable_upload_files?: boolean | null, enable_video?: boolean | null, frequency: string, max_user_count?: number | null, duration: number, enable_conversation?: boolean | null, days?: Array<string> | null, user_sessions: Array<{ __typename?: 'user_sessions', admin?: { __typename?: 'admins', avatar?: string | null, email: string, id: string, roleId: string, status: string, username: string } | null, user?: { __typename?: 'users', avatar: string, email: string, firstName: string, id: string, lastName: string, status: string } | null }>, user_sessions_aggregate: { __typename?: 'user_sessions_aggregate', aggregate?: { __typename?: 'user_sessions_aggregate_fields', count: number } | null }, mutoon?: { __typename?: 'mutoons', id: string, name: any } | null, prerequisites: Array<{ __typename?: 'prerequisites', prerequisiteId: string }> }>, sessions_aggregate: { __typename?: 'sessions_aggregate', aggregate?: { __typename?: 'sessions_aggregate_fields', count: number } | null } };

export type SoundsByMatnSectionIdQueryVariables = Exact<{
  matnSectionId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SoundsByMatnSectionIdQuery = { __typename?: 'query_root', sounds: Array<{ __typename?: 'sounds', description?: any | null, fileUrl: string, mutoonSectionId: string, name: any, soundId: string }>, sounds_aggregate: { __typename?: 'sounds_aggregate', aggregate?: { __typename?: 'sounds_aggregate_fields', count: number } | null } };

export type TranslationsByMatnSectionIdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TranslationsByMatnSectionIdQuery = { __typename?: 'query_root', translations: Array<{ __typename?: 'translations', translatedText: any, word: any, translationId: string, mutoonSectionId: string }>, translations_aggregate: { __typename?: 'translations_aggregate', aggregate?: { __typename?: 'translations_aggregate_fields', count: number } | null } };

export type TranslationByIdQueryVariables = Exact<{
  translationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type TranslationByIdQuery = { __typename?: 'query_root', translations_by_pk?: { __typename?: 'translations', translatedText: any, word: any, mutoonSectionId: string } | null };

export type UserRecordsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<String_Comparison_Exp>;
  status?: String_Comparison_Exp;
  order_by?: InputMaybe<Array<User_Records_Order_By> | User_Records_Order_By>;
}>;


export type UserRecordsQuery = { __typename?: 'query_root', user_records: Array<{ __typename?: 'user_records', createdAt: any, recordingId: string, audioRecordUrl?: string | null, status: string, user: { __typename?: 'users', firstName: string, lastName: string, email: string }, mutoonSection?: { __typename?: 'mutoon_sections', name: any, mutoonSectionId: string, mutoon?: { __typename?: 'mutoons', name: any, id: string } | null } | null }>, user_records_aggregate: { __typename?: 'user_records_aggregate', aggregate?: { __typename?: 'user_records_aggregate_fields', count: number } | null } };

export type UserFragment = { __typename?: 'users', avatar: string, createdAt: any, email: string, firstName: string, lastName: string, phoneNumber?: string | null, id: string, users_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, users_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', hearingCouncil: { __typename?: 'hearing_councils', description?: any | null, duration: number, expiryDate?: any | null, hearingCouncilId: string, status?: string | null, sessionName?: any | null, sheikhName?: string | null, startDate?: any | null } }>, userRecords_aggregate: { __typename?: 'user_records_aggregate', aggregate?: { __typename?: 'user_records_aggregate_fields', count: number } | null }, user_sessions_aggregate: { __typename?: 'user_sessions_aggregate', aggregate?: { __typename?: 'user_sessions_aggregate_fields', count: number } | null }, user_sessions: Array<{ __typename?: 'user_sessions', session: { __typename?: 'sessions', description?: any | null, status?: string | null, title?: any | null, startDate: any, expiryDate?: any | null, id: string } }> };

export type UsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By> | Users_Order_By>;
  where?: InputMaybe<Users_Bool_Exp>;
}>;


export type UsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', avatar: string, createdAt: any, email: string, firstName: string, lastName: string, phoneNumber?: string | null, id: string, users_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, users_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', hearingCouncil: { __typename?: 'hearing_councils', description?: any | null, duration: number, expiryDate?: any | null, hearingCouncilId: string, status?: string | null, sessionName?: any | null, sheikhName?: string | null, startDate?: any | null } }>, userRecords_aggregate: { __typename?: 'user_records_aggregate', aggregate?: { __typename?: 'user_records_aggregate_fields', count: number } | null }, user_sessions_aggregate: { __typename?: 'user_sessions_aggregate', aggregate?: { __typename?: 'user_sessions_aggregate_fields', count: number } | null }, user_sessions: Array<{ __typename?: 'user_sessions', session: { __typename?: 'sessions', description?: any | null, status?: string | null, title?: any | null, startDate: any, expiryDate?: any | null, id: string } }> }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } };

export type OneUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type OneUserQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', avatar: string, createdAt: any, email: string, firstName: string, lastName: string, phoneNumber?: string | null, id: string, users_user_hearing_councils_aggregate: { __typename?: 'user_hearing_councils_aggregate', aggregate?: { __typename?: 'user_hearing_councils_aggregate_fields', count: number } | null }, users_user_hearing_councils: Array<{ __typename?: 'user_hearing_councils', hearingCouncil: { __typename?: 'hearing_councils', description?: any | null, duration: number, expiryDate?: any | null, hearingCouncilId: string, status?: string | null, sessionName?: any | null, sheikhName?: string | null, startDate?: any | null } }>, userRecords_aggregate: { __typename?: 'user_records_aggregate', aggregate?: { __typename?: 'user_records_aggregate_fields', count: number } | null }, user_sessions_aggregate: { __typename?: 'user_sessions_aggregate', aggregate?: { __typename?: 'user_sessions_aggregate_fields', count: number } | null }, user_sessions: Array<{ __typename?: 'user_sessions', session: { __typename?: 'sessions', description?: any | null, status?: string | null, title?: any | null, startDate: any, expiryDate?: any | null, id: string } }> } | null };


export const Authorized_UserFragmentDoc = `
    fragment authorized_user on authorized_users {
  authorizedUserId
  createdAt
  fieldOfStudy
  notes
  status
  adminMsg
  updatedAt
  userId
  user {
    firstName
    lastName
  }
  files {
    id
    name
    status
    type
    url
  }
  files_aggregate {
    aggregate {
      count
    }
  }
  user_categories {
    categoryId
    id
    category {
      name
    }
  }
}
    `;
export const BadgeFragmentDoc = `
    fragment badge on badges {
  badgeId
  badgeImageURL
  categoryId
  hearingCouncilId
  mutoonId
  programName
  createdAt
  updatedAt
  category {
    name
  }
  mutoon {
    name
  }
  hearing_council {
    sessionName
  }
  count
  generalType
}
    `;
export const CategoryFragmentDoc = `
    fragment category on categories {
  categoryId
  parentId
  name
  description
  updatedAt
}
    `;
export const ContactFragmentDoc = `
    fragment contact on contact_us {
  contactId
  createdAt
  email
  message
  name
  reason
  response
  status
  updatedAt
  userId
}
    `;
export const ExamFragmentDoc = `
    fragment exam on exams {
  description
  updatedAt
  examId
  mutonId
  title
  mutoon {
    name
    categoryId
    levelId
    id
  }
  gradeA
  gradeB
  gradeC
  gradeD
  gradeF
  maxAttempts
  minimumScore
  limit
  status
  duration
  certificateexamId
}
    `;
export const Hearing_CouncilFragmentDoc = `
    fragment hearing_council on hearing_councils {
  courseFileURL
  deletedAt
  description
  price
  referenceBookBookId
  roomId
  sessionName
  sheikhName
  youTubeLink
  zoomLink
  startDate
  duration
  expiryDate
  duration
  createdAt
  updatedAt
  autoCertificate
  certificateHearingCouncilId
  hearingCouncilId
  hearing_councils_user_hearing_councils_aggregate {
    aggregate {
      count
    }
  }
  hearing_councils_user_hearing_councils {
    user_certificate {
      id
      downloadPath
    }
    admin {
      avatar
      email
      id
      roleId
      status
      username
    }
    user {
      avatar
      email
      firstName
      id
      lastName
      status
    }
    status
  }
  status
  mutoon {
    id
    name
  }
  appointmentLimit
  mutoon {
    categoryId
    levelId
  }
  enable_conversation
  enable_raise_hand
  enable_recording
  enable_upload_files
  enable_video
  frequency
  days
  max_user_count
  prerequisites {
    prerequisiteId
  }
}
    `;
export const Knowledge_BaseFragmentDoc = `
    fragment knowledge_base on knowledge_bases {
  content
  cover_url
  knowledgeBaseId
  status
  title
  createdAt
}
    `;
export const MatnFragmentDoc = `
    fragment matn on mutoons {
  __typename
  id
  description
  name
  updatedAt
  numberOfHadith
  createdAt
  referenceBook {
    __typename
    bookId
    dateOfBorn
    dateOfDeath
    pdfUrl
    nazemName
    numberOfLines
    fromSchool
  }
  mutoonType
  referenceBookBookId
}
    `;
export const QuestionFragmentDoc = `
    fragment question on questions {
  correctAnswerId
  correctAnswerText
  explanation
  hint
  isQuiz
  mutoonSectionId
  points
  questionId
  questionText
  type
  updatedAt
  choicesType
  choicesOptions
  correctAnswerIndex
  mutoon_section {
    name
    mutoon {
      name
    }
  }
  exam {
    mutoon {
      name
    }
  }
}
    `;
export const BookFragmentDoc = `
    fragment book on reference_books {
  bookAuthor
  bookId
  bookLanguage
  bookName
  dateOfBorn
  dateOfDeath
  fromSchool
  nazemName
  numberOfLines
  pdfUrl
  updatedAt
  title
}
    `;
export const School_UserFragmentDoc = `
    fragment school_user on school_users {
  city
  country
  createdAt
  deletedAt
  updatedAt
  schoolUserId
  status
  schoolName
  schoolAddress
  files {
    id
    name
    type
    url
  }
  files_aggregate {
    aggregate {
      count
    }
  }
  user {
    firstName
    lastName
    email
    id
  }
}
    `;
export const SessionFragmentDoc = `
    fragment session on sessions {
  delayLimit
  description
  expiryDate
  meetingId
  id
  startDate
  status
  title
  transactionId
  type
  updatedAt
  user_sessions {
    admin {
      avatar
      email
      id
      roleId
      status
      username
    }
    user {
      avatar
      email
      firstName
      id
      lastName
      status
    }
  }
  user_sessions_aggregate {
    aggregate {
      count
    }
  }
  mutoon {
    id
    name
  }
  enable_raise_hand
  enable_recording
  enable_upload_files
  enable_video
  frequency
  max_user_count
  duration
  enable_conversation
  days
  prerequisites {
    prerequisiteId
  }
}
    `;
export const UserFragmentDoc = `
    fragment user on users {
  avatar
  createdAt
  email
  firstName
  lastName
  phoneNumber
  id
  users_user_hearing_councils_aggregate {
    aggregate {
      count
    }
  }
  users_user_hearing_councils {
    hearingCouncil {
      description
      duration
      expiryDate
      hearingCouncilId
      status
      sessionName
      sheikhName
      startDate
    }
  }
  userRecords_aggregate {
    aggregate {
      count
    }
  }
  user_sessions_aggregate {
    aggregate {
      count
    }
  }
  user_sessions {
    session {
      description
      status
      title
      startDate
      expiryDate
      id
    }
  }
}
    `;
export const CreateRoleActionDocument = `
    mutation CreateRoleAction($roleId: String = "", $actionId: String = "") {
  insert_roles_actions_one(object: {roleId: $roleId, actionId: $actionId}) {
    actionId
  }
}
    `;

export const useCreateRoleActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateRoleActionMutation, TError, CreateRoleActionMutationVariables, TContext>) => {
    
    return useMutation<CreateRoleActionMutation, TError, CreateRoleActionMutationVariables, TContext>(
      ['CreateRoleAction'],
      (variables?: CreateRoleActionMutationVariables) => fetchData<CreateRoleActionMutation, CreateRoleActionMutationVariables>(CreateRoleActionDocument, variables)(),
      options
    )};

export const DeleteRoleActionDocument = `
    mutation DeleteRoleAction($roleId: String = "", $actionId: String = "") {
  delete_roles_actions_by_pk(actionId: $actionId, roleId: $roleId) {
    actionId
  }
}
    `;

export const useDeleteRoleActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRoleActionMutation, TError, DeleteRoleActionMutationVariables, TContext>) => {
    
    return useMutation<DeleteRoleActionMutation, TError, DeleteRoleActionMutationVariables, TContext>(
      ['DeleteRoleAction'],
      (variables?: DeleteRoleActionMutationVariables) => fetchData<DeleteRoleActionMutation, DeleteRoleActionMutationVariables>(DeleteRoleActionDocument, variables)(),
      options
    )};

export const DeleteAdminDocument = `
    mutation DeleteAdmin($id: String = "") {
  delete_admins_by_pk(id: $id) {
    id
  }
}
    `;

export const useDeleteAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteAdminMutation, TError, DeleteAdminMutationVariables, TContext>) => {
    
    return useMutation<DeleteAdminMutation, TError, DeleteAdminMutationVariables, TContext>(
      ['DeleteAdmin'],
      (variables?: DeleteAdminMutationVariables) => fetchData<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, variables)(),
      options
    )};

export const AddAdminDocument = `
    mutation AddAdmin($email: String = "", $password: String = "", $roleId: String = "", $username: String = "") {
  createAdmin(
    createAdminInput: {email: $email, password: $password, roleId: $roleId, status: "Active", username: $username}
  ) {
    user {
      id
    }
  }
}
    `;

export const useAddAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddAdminMutation, TError, AddAdminMutationVariables, TContext>) => {
    
    return useMutation<AddAdminMutation, TError, AddAdminMutationVariables, TContext>(
      ['AddAdmin'],
      (variables?: AddAdminMutationVariables) => fetchData<AddAdminMutation, AddAdminMutationVariables>(AddAdminDocument, variables)(),
      options
    )};

export const UpdateAdminDocument = `
    mutation UpdateAdmin($id: String = "", $username: String = "", $roleId: String = "", $email: String = "") {
  update_admins_by_pk(
    pk_columns: {id: $id}
    _set: {username: $username, roleId: $roleId, email: $email}
  ) {
    id
  }
}
    `;

export const useUpdateAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateAdminMutation, TError, UpdateAdminMutationVariables, TContext>) => {
    
    return useMutation<UpdateAdminMutation, TError, UpdateAdminMutationVariables, TContext>(
      ['UpdateAdmin'],
      (variables?: UpdateAdminMutationVariables) => fetchData<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, variables)(),
      options
    )};

export const ApproveUserDocument = `
    mutation ApproveUser($authorizedUserId: String = "", $status: String = "approved", $objects: [user_categories_insert_input!] = {}, $adminMsg: String = "") {
  update_authorized_users_by_pk(
    pk_columns: {authorizedUserId: $authorizedUserId}
    _set: {status: $status, adminMsg: $adminMsg}
  ) {
    authorizedUserId
  }
  insert_user_categories(objects: $objects) {
    returning {
      authorizedUserId
    }
  }
  update_users(
    where: {authorized_user: {authorizedUserId: {_eq: $authorizedUserId}}}
    _set: {Type: "Sheikh"}
  ) {
    returning {
      id
    }
    affected_rows
  }
}
    `;

export const useApproveUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ApproveUserMutation, TError, ApproveUserMutationVariables, TContext>) => {
    
    return useMutation<ApproveUserMutation, TError, ApproveUserMutationVariables, TContext>(
      ['ApproveUser'],
      (variables?: ApproveUserMutationVariables) => fetchData<ApproveUserMutation, ApproveUserMutationVariables>(ApproveUserDocument, variables)(),
      options
    )};

export const RejectUserDocument = `
    mutation RejectUser($authorizedUserId: String!, $status: String = "rejected", $adminMsg: String = "") {
  update_authorized_users_by_pk(
    pk_columns: {authorizedUserId: $authorizedUserId}
    _set: {status: $status, adminMsg: $adminMsg}
  ) {
    authorizedUserId
  }
}
    `;

export const useRejectUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RejectUserMutation, TError, RejectUserMutationVariables, TContext>) => {
    
    return useMutation<RejectUserMutation, TError, RejectUserMutationVariables, TContext>(
      ['RejectUser'],
      (variables?: RejectUserMutationVariables) => fetchData<RejectUserMutation, RejectUserMutationVariables>(RejectUserDocument, variables)(),
      options
    )};

export const AddBadgeDocument = `
    mutation AddBadge($badgeImageURL: String = "", $programName: jsonb = "", $mutoonId: String = "", $categoryId: String = "", $hearingCouncilId: String = "", $count: Int, $generalType: generalBadgeType) {
  insert_badges_one(
    object: {programName: $programName, badgeImageURL: $badgeImageURL, mutoonId: $mutoonId, categoryId: $categoryId, hearingCouncilId: $hearingCouncilId, count: $count, generalType: $generalType}
  ) {
    badgeId
  }
}
    `;

export const useAddBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddBadgeMutation, TError, AddBadgeMutationVariables, TContext>) => {
    
    return useMutation<AddBadgeMutation, TError, AddBadgeMutationVariables, TContext>(
      ['AddBadge'],
      (variables?: AddBadgeMutationVariables) => fetchData<AddBadgeMutation, AddBadgeMutationVariables>(AddBadgeDocument, variables)(),
      options
    )};

export const EditBadgeDocument = `
    mutation EditBadge($id: String = "", $hearingCouncilId: String = "", $programName: jsonb = "", $mutoonId: String = "", $categoryId: String = "", $badgeImageURL: String = "", $count: Int, $generalType: generalBadgeType) {
  update_badges_by_pk(
    pk_columns: {badgeId: $id}
    _set: {hearingCouncilId: $hearingCouncilId, programName: $programName, mutoonId: $mutoonId, categoryId: $categoryId, badgeImageURL: $badgeImageURL, count: $count, generalType: $generalType}
  ) {
    badgeId
  }
}
    `;

export const useEditBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditBadgeMutation, TError, EditBadgeMutationVariables, TContext>) => {
    
    return useMutation<EditBadgeMutation, TError, EditBadgeMutationVariables, TContext>(
      ['EditBadge'],
      (variables?: EditBadgeMutationVariables) => fetchData<EditBadgeMutation, EditBadgeMutationVariables>(EditBadgeDocument, variables)(),
      options
    )};

export const DeleteBadgeDocument = `
    mutation DeleteBadge($badgeId: String = "") {
  delete_badges_by_pk(badgeId: $badgeId) {
    badgeId
  }
}
    `;

export const useDeleteBadgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBadgeMutation, TError, DeleteBadgeMutationVariables, TContext>) => {
    
    return useMutation<DeleteBadgeMutation, TError, DeleteBadgeMutationVariables, TContext>(
      ['DeleteBadge'],
      (variables?: DeleteBadgeMutationVariables) => fetchData<DeleteBadgeMutation, DeleteBadgeMutationVariables>(DeleteBadgeDocument, variables)(),
      options
    )};

export const DeleteCategoryDocument = `
    mutation DeleteCategory($categoryId: String = "") {
  delete_categories_by_pk(categoryId: $categoryId) {
    categoryId
  }
}
    `;

export const useDeleteCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>) => {
    
    return useMutation<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>(
      ['DeleteCategory'],
      (variables?: DeleteCategoryMutationVariables) => fetchData<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, variables)(),
      options
    )};

export const AddCategoryDocument = `
    mutation AddCategory($description: jsonb = "", $name: jsonb = "", $parentId: String = "") {
  insert_categories_one(
    object: {description: $description, name: $name, parentId: $parentId}
  ) {
    categoryId
  }
}
    `;

export const useAddCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddCategoryMutation, TError, AddCategoryMutationVariables, TContext>) => {
    
    return useMutation<AddCategoryMutation, TError, AddCategoryMutationVariables, TContext>(
      ['AddCategory'],
      (variables?: AddCategoryMutationVariables) => fetchData<AddCategoryMutation, AddCategoryMutationVariables>(AddCategoryDocument, variables)(),
      options
    )};

export const UpdateCategoryDocument = `
    mutation UpdateCategory($categoryId: String = "", $description: jsonb = "", $name: jsonb = "") {
  update_categories_by_pk(
    pk_columns: {categoryId: $categoryId}
    _set: {description: $description, name: $name}
  ) {
    categoryId
  }
}
    `;

export const useUpdateCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCategoryMutation, TError, UpdateCategoryMutationVariables, TContext>) => {
    
    return useMutation<UpdateCategoryMutation, TError, UpdateCategoryMutationVariables, TContext>(
      ['UpdateCategory'],
      (variables?: UpdateCategoryMutationVariables) => fetchData<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, variables)(),
      options
    )};

export const AddCertificateVariablesDocument = `
    mutation AddCertificateVariables($objects: [certificate_variables_insert_input!] = {}) {
  insert_certificate_variables(objects: $objects) {
    returning {
      certID
    }
  }
}
    `;

export const useAddCertificateVariablesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddCertificateVariablesMutation, TError, AddCertificateVariablesMutationVariables, TContext>) => {
    
    return useMutation<AddCertificateVariablesMutation, TError, AddCertificateVariablesMutationVariables, TContext>(
      ['AddCertificateVariables'],
      (variables?: AddCertificateVariablesMutationVariables) => fetchData<AddCertificateVariablesMutation, AddCertificateVariablesMutationVariables>(AddCertificateVariablesDocument, variables)(),
      options
    )};

export const AddCertificateDocument = `
    mutation AddCertificate($title: String = "") {
  insert_certificates_one(object: {title: $title}) {
    certificateId
  }
}
    `;

export const useAddCertificateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddCertificateMutation, TError, AddCertificateMutationVariables, TContext>) => {
    
    return useMutation<AddCertificateMutation, TError, AddCertificateMutationVariables, TContext>(
      ['AddCertificate'],
      (variables?: AddCertificateMutationVariables) => fetchData<AddCertificateMutation, AddCertificateMutationVariables>(AddCertificateDocument, variables)(),
      options
    )};

export const CreateCouncilCertificateDocument = `
    mutation CreateCouncilCertificate($createCertficateInput: [createCertficateInput!] = {}) {
  createUserHearingCouncilCertificate(
    createCertficateInput: $createCertficateInput
  ) {
    userCertificate {
      certificateId
      downloadPath
    }
    userCertificateId
  }
}
    `;

export const useCreateCouncilCertificateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCouncilCertificateMutation, TError, CreateCouncilCertificateMutationVariables, TContext>) => {
    
    return useMutation<CreateCouncilCertificateMutation, TError, CreateCouncilCertificateMutationVariables, TContext>(
      ['CreateCouncilCertificate'],
      (variables?: CreateCouncilCertificateMutationVariables) => fetchData<CreateCouncilCertificateMutation, CreateCouncilCertificateMutationVariables>(CreateCouncilCertificateDocument, variables)(),
      options
    )};

export const DeleteCertificatesDocument = `
    mutation DeleteCertificates($certificateId: String = "") {
  delete_certificates_by_pk(certificateId: $certificateId) {
    certificateId
  }
}
    `;

export const useDeleteCertificatesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCertificatesMutation, TError, DeleteCertificatesMutationVariables, TContext>) => {
    
    return useMutation<DeleteCertificatesMutation, TError, DeleteCertificatesMutationVariables, TContext>(
      ['DeleteCertificates'],
      (variables?: DeleteCertificatesMutationVariables) => fetchData<DeleteCertificatesMutation, DeleteCertificatesMutationVariables>(DeleteCertificatesDocument, variables)(),
      options
    )};

export const DeleteContactMsgDocument = `
    mutation DeleteContactMsg($contactId: String = "") {
  delete_contact_us_by_pk(contactId: $contactId) {
    contactId
  }
}
    `;

export const useDeleteContactMsgMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteContactMsgMutation, TError, DeleteContactMsgMutationVariables, TContext>) => {
    
    return useMutation<DeleteContactMsgMutation, TError, DeleteContactMsgMutationVariables, TContext>(
      ['DeleteContactMsg'],
      (variables?: DeleteContactMsgMutationVariables) => fetchData<DeleteContactMsgMutation, DeleteContactMsgMutationVariables>(DeleteContactMsgDocument, variables)(),
      options
    )};

export const SendEmailToContactDocument = `
    mutation SendEmailToContact($contactId: String!, $response: String!) {
  SendEmailToContact(
    SendEmailToContactInput: {contactId: $contactId, response: $response}
  ) {
    contactId
    name
    email
    status
    reason
    message
    response
  }
}
    `;

export const useSendEmailToContactMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SendEmailToContactMutation, TError, SendEmailToContactMutationVariables, TContext>) => {
    
    return useMutation<SendEmailToContactMutation, TError, SendEmailToContactMutationVariables, TContext>(
      ['SendEmailToContact'],
      (variables?: SendEmailToContactMutationVariables) => fetchData<SendEmailToContactMutation, SendEmailToContactMutationVariables>(SendEmailToContactDocument, variables)(),
      options
    )};

export const AddExamDocument = `
    mutation AddExam($certificateexamId: String, $description: jsonb = "", $duration: Int = 10, $gradeA: Int = 90, $gradeB: Int = 80, $gradeC: Int = 70, $gradeD: Int = 60, $gradeF: Int = 49, $limit: Int = 20, $maxAttempts: Int = 3, $minimumScore: Int = 50, $mutonId: String!, $status: String = "active", $title: jsonb = "") {
  insert_exams_one(
    object: {certificateexamId: $certificateexamId, description: $description, duration: $duration, gradeA: $gradeA, gradeB: $gradeB, gradeC: $gradeC, gradeD: $gradeD, gradeF: $gradeF, limit: $limit, maxAttempts: $maxAttempts, minimumScore: $minimumScore, mutonId: $mutonId, status: $status, title: $title}
  ) {
    examId
    title
    mutoon {
      id
      name
    }
    title
  }
}
    `;

export const useAddExamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddExamMutation, TError, AddExamMutationVariables, TContext>) => {
    
    return useMutation<AddExamMutation, TError, AddExamMutationVariables, TContext>(
      ['AddExam'],
      (variables?: AddExamMutationVariables) => fetchData<AddExamMutation, AddExamMutationVariables>(AddExamDocument, variables)(),
      options
    )};

export const UpdateExamDocument = `
    mutation UpdateExam($certificateexamId: String, $description: jsonb = "", $duration: Int = 10, $gradeA: Int = 90, $gradeB: Int = 80, $gradeC: Int = 70, $gradeD: Int = 60, $gradeF: Int = 49, $limit: Int = 20, $maxAttempts: Int = 3, $minimumScore: Int = 50, $mutonId: String = "", $status: String = "active", $title: jsonb = "", $examId: String = "") {
  update_exams_by_pk(
    _set: {certificateexamId: $certificateexamId, description: $description, duration: $duration, gradeA: $gradeA, gradeB: $gradeB, gradeC: $gradeC, gradeD: $gradeD, gradeF: $gradeF, limit: $limit, maxAttempts: $maxAttempts, minimumScore: $minimumScore, mutonId: $mutonId, status: $status, title: $title}
    pk_columns: {examId: $examId}
  ) {
    examId
  }
}
    `;

export const useUpdateExamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateExamMutation, TError, UpdateExamMutationVariables, TContext>) => {
    
    return useMutation<UpdateExamMutation, TError, UpdateExamMutationVariables, TContext>(
      ['UpdateExam'],
      (variables?: UpdateExamMutationVariables) => fetchData<UpdateExamMutation, UpdateExamMutationVariables>(UpdateExamDocument, variables)(),
      options
    )};

export const DeleteExamDocument = `
    mutation DeleteExam($examId: String!) {
  delete_exams_by_pk(examId: $examId) {
    examId
  }
}
    `;

export const useDeleteExamMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteExamMutation, TError, DeleteExamMutationVariables, TContext>) => {
    
    return useMutation<DeleteExamMutation, TError, DeleteExamMutationVariables, TContext>(
      ['DeleteExam'],
      (variables?: DeleteExamMutationVariables) => fetchData<DeleteExamMutation, DeleteExamMutationVariables>(DeleteExamDocument, variables)(),
      options
    )};

export const AddFaqDocument = `
    mutation AddFaq($answer: jsonb = {}, $question: jsonb = {}) {
  insert_faqs_one(object: {answer: $answer, question: $question}) {
    id
  }
}
    `;

export const useAddFaqMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddFaqMutation, TError, AddFaqMutationVariables, TContext>) => {
    
    return useMutation<AddFaqMutation, TError, AddFaqMutationVariables, TContext>(
      ['AddFaq'],
      (variables?: AddFaqMutationVariables) => fetchData<AddFaqMutation, AddFaqMutationVariables>(AddFaqDocument, variables)(),
      options
    )};

export const EditFaqDocument = `
    mutation EditFaq($id: String = "", $answer: jsonb = {}, $question: jsonb = {}) {
  update_faqs_by_pk(
    pk_columns: {id: $id}
    _set: {answer: $answer, question: $question}
  ) {
    id
  }
}
    `;

export const useEditFaqMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditFaqMutation, TError, EditFaqMutationVariables, TContext>) => {
    
    return useMutation<EditFaqMutation, TError, EditFaqMutationVariables, TContext>(
      ['EditFaq'],
      (variables?: EditFaqMutationVariables) => fetchData<EditFaqMutation, EditFaqMutationVariables>(EditFaqDocument, variables)(),
      options
    )};

export const DeleteFaqDocument = `
    mutation DeleteFaq($id: String = "") {
  delete_faqs_by_pk(id: $id) {
    id
  }
}
    `;

export const useDeleteFaqMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteFaqMutation, TError, DeleteFaqMutationVariables, TContext>) => {
    
    return useMutation<DeleteFaqMutation, TError, DeleteFaqMutationVariables, TContext>(
      ['DeleteFaq'],
      (variables?: DeleteFaqMutationVariables) => fetchData<DeleteFaqMutation, DeleteFaqMutationVariables>(DeleteFaqDocument, variables)(),
      options
    )};

export const DeleteHearingCouncilDocument = `
    mutation DeleteHearingCouncil($hearingCouncilId: String!) {
  delete_user_hearing_councils(
    where: {hearingCouncilId: {_eq: $hearingCouncilId}}
  ) {
    affected_rows
  }
  delete_hearing_councils_by_pk(hearingCouncilId: $hearingCouncilId) {
    hearingCouncilId
  }
}
    `;

export const useDeleteHearingCouncilMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteHearingCouncilMutation, TError, DeleteHearingCouncilMutationVariables, TContext>) => {
    
    return useMutation<DeleteHearingCouncilMutation, TError, DeleteHearingCouncilMutationVariables, TContext>(
      ['DeleteHearingCouncil'],
      (variables?: DeleteHearingCouncilMutationVariables) => fetchData<DeleteHearingCouncilMutation, DeleteHearingCouncilMutationVariables>(DeleteHearingCouncilDocument, variables)(),
      options
    )};

export const CreateHearingCouncilDocument = `
    mutation CreateHearingCouncil($mutoonMatnId: String!, $startDate: DateTime!, $price: Float!, $expiryDate: DateTime!, $appointmentLimit: Int = 10, $description: JSONObject!, $sessionName: JSONObject!, $sheikhName: ID, $enable_conversation: Boolean = true, $enable_raise_hand: Boolean = true, $enable_recording: Boolean = true, $enable_upload_files: Boolean = true, $enable_video: Boolean = true, $frequency: String = "", $max_user_count: Int = 10, $prerequisiteIds: [String!], $days: [String!], $duration: Int = 10, $certificateHearingCouncilId: String!, $autoCertificate: Boolean = false) {
  createHearingCouncil(
    mutoonMatnId: $mutoonMatnId
    createHearingCouncilInput: {sessionName: $sessionName, sheikhName: $sheikhName, description: $description, startDate: $startDate, expiryDate: $expiryDate, price: $price, appointmentLimit: $appointmentLimit, enable_conversation: $enable_conversation, enable_raise_hand: $enable_raise_hand, enable_recording: $enable_recording, enable_upload_files: $enable_upload_files, enable_video: $enable_video, frequency: $frequency, max_user_count: $max_user_count, prerequisiteIds: $prerequisiteIds, days: $days, duration: $duration, certificateHearingCouncilId: $certificateHearingCouncilId, autoCertificate: $autoCertificate}
  ) {
    hearingCouncilId
  }
}
    `;

export const useCreateHearingCouncilMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateHearingCouncilMutation, TError, CreateHearingCouncilMutationVariables, TContext>) => {
    
    return useMutation<CreateHearingCouncilMutation, TError, CreateHearingCouncilMutationVariables, TContext>(
      ['CreateHearingCouncil'],
      (variables?: CreateHearingCouncilMutationVariables) => fetchData<CreateHearingCouncilMutation, CreateHearingCouncilMutationVariables>(CreateHearingCouncilDocument, variables)(),
      options
    )};

export const UpdateHearingCouncilFormDocument = `
    mutation UpdateHearingCouncilForm($hearingCouncilId: String = "", $mutoonMatnId: String, $startDate: timestamptz, $expiryDate: timestamptz, $sheikhName: String, $sessionName: jsonb, $description: jsonb, $appointmentLimit: Int, $status: String) {
  update_hearing_councils_by_pk(
    pk_columns: {hearingCouncilId: $hearingCouncilId}
    _set: {mutoonMatnId: $mutoonMatnId, startDate: $startDate, expiryDate: $expiryDate, sheikhName: $sheikhName, sessionName: $sessionName, description: $description, appointmentLimit: $appointmentLimit, status: $status}
  ) {
    hearingCouncilId
  }
}
    `;

export const useUpdateHearingCouncilFormMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateHearingCouncilFormMutation, TError, UpdateHearingCouncilFormMutationVariables, TContext>) => {
    
    return useMutation<UpdateHearingCouncilFormMutation, TError, UpdateHearingCouncilFormMutationVariables, TContext>(
      ['UpdateHearingCouncilForm'],
      (variables?: UpdateHearingCouncilFormMutationVariables) => fetchData<UpdateHearingCouncilFormMutation, UpdateHearingCouncilFormMutationVariables>(UpdateHearingCouncilFormDocument, variables)(),
      options
    )};

export const SubscribeHearingCouncilDocument = `
    mutation SubscribeHearingCouncil($hearingCouncilId: String!, $adminId: String!) {
  insert_user_hearing_councils(
    objects: {hearingCouncilId: $hearingCouncilId, subscriped: true, adminId: $adminId}
  ) {
    returning {
      hearingCouncil {
        hearing_councils_user_hearing_councils {
          userHearingCouncilId
        }
      }
    }
  }
}
    `;

export const useSubscribeHearingCouncilMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SubscribeHearingCouncilMutation, TError, SubscribeHearingCouncilMutationVariables, TContext>) => {
    
    return useMutation<SubscribeHearingCouncilMutation, TError, SubscribeHearingCouncilMutationVariables, TContext>(
      ['SubscribeHearingCouncil'],
      (variables?: SubscribeHearingCouncilMutationVariables) => fetchData<SubscribeHearingCouncilMutation, SubscribeHearingCouncilMutationVariables>(SubscribeHearingCouncilDocument, variables)(),
      options
    )};

export const JoinHearingCounciDocument = `
    mutation joinHearingCounci($hearingCouncilId: String!) {
  joinHearingCouncil(hearingCouncilId: $hearingCouncilId) {
    join_link
    stream_key
    watch_link
  }
}
    `;

export const useJoinHearingCounciMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<JoinHearingCounciMutation, TError, JoinHearingCounciMutationVariables, TContext>) => {
    
    return useMutation<JoinHearingCounciMutation, TError, JoinHearingCounciMutationVariables, TContext>(
      ['joinHearingCounci'],
      (variables?: JoinHearingCounciMutationVariables) => fetchData<JoinHearingCounciMutation, JoinHearingCounciMutationVariables>(JoinHearingCounciDocument, variables)(),
      options
    )};

export const UpdateHearingCouncilDocument = `
    mutation UpdateHearingCouncil($hearingCouncilId: String = "", $_set: hearing_councils_set_input = {}) {
  update_hearing_councils_by_pk(
    pk_columns: {hearingCouncilId: $hearingCouncilId}
    _set: $_set
  ) {
    hearingCouncilId
  }
}
    `;

export const useUpdateHearingCouncilMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateHearingCouncilMutation, TError, UpdateHearingCouncilMutationVariables, TContext>) => {
    
    return useMutation<UpdateHearingCouncilMutation, TError, UpdateHearingCouncilMutationVariables, TContext>(
      ['UpdateHearingCouncil'],
      (variables?: UpdateHearingCouncilMutationVariables) => fetchData<UpdateHearingCouncilMutation, UpdateHearingCouncilMutationVariables>(UpdateHearingCouncilDocument, variables)(),
      options
    )};

export const DeleteKnowledgeBaseDocument = `
    mutation DeleteKnowledgeBase($knowledgeBaseId: String = "") {
  delete_knowledge_bases_by_pk(knowledgeBaseId: $knowledgeBaseId) {
    knowledgeBaseId
  }
}
    `;

export const useDeleteKnowledgeBaseMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteKnowledgeBaseMutation, TError, DeleteKnowledgeBaseMutationVariables, TContext>) => {
    
    return useMutation<DeleteKnowledgeBaseMutation, TError, DeleteKnowledgeBaseMutationVariables, TContext>(
      ['DeleteKnowledgeBase'],
      (variables?: DeleteKnowledgeBaseMutationVariables) => fetchData<DeleteKnowledgeBaseMutation, DeleteKnowledgeBaseMutationVariables>(DeleteKnowledgeBaseDocument, variables)(),
      options
    )};

export const AddKnowledgeBaseDocument = `
    mutation AddKnowledgeBase($content: jsonb = "", $cover_url: String = "", $title: jsonb = "") {
  insert_knowledge_bases_one(
    object: {content: $content, cover_url: $cover_url, title: $title}
  ) {
    knowledgeBaseId
  }
}
    `;

export const useAddKnowledgeBaseMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddKnowledgeBaseMutation, TError, AddKnowledgeBaseMutationVariables, TContext>) => {
    
    return useMutation<AddKnowledgeBaseMutation, TError, AddKnowledgeBaseMutationVariables, TContext>(
      ['AddKnowledgeBase'],
      (variables?: AddKnowledgeBaseMutationVariables) => fetchData<AddKnowledgeBaseMutation, AddKnowledgeBaseMutationVariables>(AddKnowledgeBaseDocument, variables)(),
      options
    )};

export const UpdateKnowledgeBaseDocument = `
    mutation UpdateKnowledgeBase($content: jsonb = "", $cover_url: String = "", $title: jsonb = "", $knowledgeBaseId: String = "") {
  update_knowledge_bases_by_pk(
    _set: {content: $content, cover_url: $cover_url, title: $title}
    pk_columns: {knowledgeBaseId: $knowledgeBaseId}
  ) {
    knowledgeBaseId
  }
}
    `;

export const useUpdateKnowledgeBaseMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateKnowledgeBaseMutation, TError, UpdateKnowledgeBaseMutationVariables, TContext>) => {
    
    return useMutation<UpdateKnowledgeBaseMutation, TError, UpdateKnowledgeBaseMutationVariables, TContext>(
      ['UpdateKnowledgeBase'],
      (variables?: UpdateKnowledgeBaseMutationVariables) => fetchData<UpdateKnowledgeBaseMutation, UpdateKnowledgeBaseMutationVariables>(UpdateKnowledgeBaseDocument, variables)(),
      options
    )};

export const DeleteLevelDocument = `
    mutation DeleteLevel($levelId: String = "") {
  delete_levels_by_pk(levelId: $levelId) {
    categoryId
  }
}
    `;

export const useDeleteLevelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteLevelMutation, TError, DeleteLevelMutationVariables, TContext>) => {
    
    return useMutation<DeleteLevelMutation, TError, DeleteLevelMutationVariables, TContext>(
      ['DeleteLevel'],
      (variables?: DeleteLevelMutationVariables) => fetchData<DeleteLevelMutation, DeleteLevelMutationVariables>(DeleteLevelDocument, variables)(),
      options
    )};

export const AddLevelDocument = `
    mutation AddLevel($description: jsonb = "", $name: jsonb = "", $categoryId: String = "") {
  insert_levels_one(
    object: {name: $name, description: $description, categoryId: $categoryId}
  ) {
    levelId
  }
}
    `;

export const useAddLevelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddLevelMutation, TError, AddLevelMutationVariables, TContext>) => {
    
    return useMutation<AddLevelMutation, TError, AddLevelMutationVariables, TContext>(
      ['AddLevel'],
      (variables?: AddLevelMutationVariables) => fetchData<AddLevelMutation, AddLevelMutationVariables>(AddLevelDocument, variables)(),
      options
    )};

export const UpdateLevelDocument = `
    mutation UpdateLevel($levelId: String = "", $description: jsonb = "", $name: jsonb = "") {
  update_levels_by_pk(
    pk_columns: {levelId: $levelId}
    _set: {description: $description, name: $name}
  ) {
    levelId
  }
}
    `;

export const useUpdateLevelMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateLevelMutation, TError, UpdateLevelMutationVariables, TContext>) => {
    
    return useMutation<UpdateLevelMutation, TError, UpdateLevelMutationVariables, TContext>(
      ['UpdateLevel'],
      (variables?: UpdateLevelMutationVariables) => fetchData<UpdateLevelMutation, UpdateLevelMutationVariables>(UpdateLevelDocument, variables)(),
      options
    )};

export const LoginAdminDocument = `
    mutation LoginAdmin($email: String = "", $password: String = "") {
  loginAdmin(loginAdminInput: {email: $email, password: $password}) {
    token
    user {
      avatar
      email
      id
      roleId
      role {
        permissions {
          id
          name
          systemCriteria
        }
      }
      status
      username
    }
  }
}
    `;

export const useLoginAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginAdminMutation, TError, LoginAdminMutationVariables, TContext>) => {
    
    return useMutation<LoginAdminMutation, TError, LoginAdminMutationVariables, TContext>(
      ['LoginAdmin'],
      (variables?: LoginAdminMutationVariables) => fetchData<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, variables)(),
      options
    )};

export const DeleteMatnSectionDocument = `
    mutation DeleteMatnSection($mutoonSectionId: String!) {
  delete_mutoon_sections_by_pk(mutoonSectionId: $mutoonSectionId) {
    mutoonSectionId
  }
}
    `;

export const useDeleteMatnSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMatnSectionMutation, TError, DeleteMatnSectionMutationVariables, TContext>) => {
    
    return useMutation<DeleteMatnSectionMutation, TError, DeleteMatnSectionMutationVariables, TContext>(
      ['DeleteMatnSection'],
      (variables?: DeleteMatnSectionMutationVariables) => fetchData<DeleteMatnSectionMutation, DeleteMatnSectionMutationVariables>(DeleteMatnSectionDocument, variables)(),
      options
    )};

export const AddMatnSectionDocument = `
    mutation AddMatnSection($description: jsonb, $name: jsonb, $matnId: String) {
  insert_mutoon_sections_one(
    object: {name: $name, description: $description, mutoonMatnId: $matnId}
  ) {
    mutoonSectionId
  }
}
    `;

export const useAddMatnSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddMatnSectionMutation, TError, AddMatnSectionMutationVariables, TContext>) => {
    
    return useMutation<AddMatnSectionMutation, TError, AddMatnSectionMutationVariables, TContext>(
      ['AddMatnSection'],
      (variables?: AddMatnSectionMutationVariables) => fetchData<AddMatnSectionMutation, AddMatnSectionMutationVariables>(AddMatnSectionDocument, variables)(),
      options
    )};

export const UpdateMatnSectionDocument = `
    mutation UpdateMatnSection($description: jsonb, $name: jsonb, $mutoonSectionId: String!) {
  update_mutoon_sections_by_pk(
    pk_columns: {mutoonSectionId: $mutoonSectionId}
    _set: {name: $name, description: $description}
  ) {
    mutoonSectionId
  }
}
    `;

export const useUpdateMatnSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMatnSectionMutation, TError, UpdateMatnSectionMutationVariables, TContext>) => {
    
    return useMutation<UpdateMatnSectionMutation, TError, UpdateMatnSectionMutationVariables, TContext>(
      ['UpdateMatnSection'],
      (variables?: UpdateMatnSectionMutationVariables) => fetchData<UpdateMatnSectionMutation, UpdateMatnSectionMutationVariables>(UpdateMatnSectionDocument, variables)(),
      options
    )};

export const DeleteMatnDocument = `
    mutation DeleteMatn($mutoonId: String!) {
  delete_mutoon_categories(where: {mutoonId: {_eq: $mutoonId}}) {
    affected_rows
  }
  delete_mutoons_by_pk(id: $mutoonId) {
    id
  }
}
    `;

export const useDeleteMatnMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMatnMutation, TError, DeleteMatnMutationVariables, TContext>) => {
    
    return useMutation<DeleteMatnMutation, TError, DeleteMatnMutationVariables, TContext>(
      ['DeleteMatn'],
      (variables?: DeleteMatnMutationVariables) => fetchData<DeleteMatnMutation, DeleteMatnMutationVariables>(DeleteMatnDocument, variables)(),
      options
    )};

export const AddMatnDocument = `
    mutation AddMatn($description: jsonb, $name: jsonb, $numberOfHadith: Int, $referenceBookBookId: String) {
  insert_mutoons_one(
    object: {name: $name, description: $description, referenceBookBookId: $referenceBookBookId, numberOfHadith: $numberOfHadith}
  ) {
    id
  }
}
    `;

export const useAddMatnMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddMatnMutation, TError, AddMatnMutationVariables, TContext>) => {
    
    return useMutation<AddMatnMutation, TError, AddMatnMutationVariables, TContext>(
      ['AddMatn'],
      (variables?: AddMatnMutationVariables) => fetchData<AddMatnMutation, AddMatnMutationVariables>(AddMatnDocument, variables)(),
      options
    )};

export const UpdateMatnDocument = `
    mutation UpdateMatn($matnId: String = "", $description: jsonb = "", $name: jsonb = "", $numberOfHadith: Int = 0, $referenceBookBookId: String = "") {
  update_mutoons_by_pk(
    pk_columns: {id: $matnId}
    _set: {description: $description, name: $name, referenceBookBookId: $referenceBookBookId, numberOfHadith: $numberOfHadith}
  ) {
    id
  }
}
    `;

export const useUpdateMatnMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMatnMutation, TError, UpdateMatnMutationVariables, TContext>) => {
    
    return useMutation<UpdateMatnMutation, TError, UpdateMatnMutationVariables, TContext>(
      ['UpdateMatn'],
      (variables?: UpdateMatnMutationVariables) => fetchData<UpdateMatnMutation, UpdateMatnMutationVariables>(UpdateMatnDocument, variables)(),
      options
    )};

export const AttachMatnDocument = `
    mutation AttachMatn($categoryId: String = "", $mutoonId: String = "") {
  insert_mutoon_categories_one(
    object: {categoryId: $categoryId, mutoonId: $mutoonId}
  ) {
    id
  }
}
    `;

export const useAttachMatnMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AttachMatnMutation, TError, AttachMatnMutationVariables, TContext>) => {
    
    return useMutation<AttachMatnMutation, TError, AttachMatnMutationVariables, TContext>(
      ['AttachMatn'],
      (variables?: AttachMatnMutationVariables) => fetchData<AttachMatnMutation, AttachMatnMutationVariables>(AttachMatnDocument, variables)(),
      options
    )};

export const DeleteQuestionDocument = `
    mutation DeleteQuestion($questionId: String = "") {
  delete_questions_by_pk(questionId: $questionId) {
    questionId
  }
}
    `;

export const useDeleteQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteQuestionMutation, TError, DeleteQuestionMutationVariables, TContext>) => {
    
    return useMutation<DeleteQuestionMutation, TError, DeleteQuestionMutationVariables, TContext>(
      ['DeleteQuestion'],
      (variables?: DeleteQuestionMutationVariables) => fetchData<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, variables)(),
      options
    )};

export const AddQuestionsDocument = `
    mutation AddQuestions($questions: [questions_insert_input!]!) {
  insert_questions(objects: $questions) {
    affected_rows
    returning {
      questionId
    }
  }
}
    `;

export const useAddQuestionsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddQuestionsMutation, TError, AddQuestionsMutationVariables, TContext>) => {
    
    return useMutation<AddQuestionsMutation, TError, AddQuestionsMutationVariables, TContext>(
      ['AddQuestions'],
      (variables?: AddQuestionsMutationVariables) => fetchData<AddQuestionsMutation, AddQuestionsMutationVariables>(AddQuestionsDocument, variables)(),
      options
    )};

export const UpdateQuestionsDocument = `
    mutation UpdateQuestions($updates: [questions_updates!]!) {
  update_questions_many(updates: $updates) {
    affected_rows
  }
}
    `;

export const useUpdateQuestionsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateQuestionsMutation, TError, UpdateQuestionsMutationVariables, TContext>) => {
    
    return useMutation<UpdateQuestionsMutation, TError, UpdateQuestionsMutationVariables, TContext>(
      ['UpdateQuestions'],
      (variables?: UpdateQuestionsMutationVariables) => fetchData<UpdateQuestionsMutation, UpdateQuestionsMutationVariables>(UpdateQuestionsDocument, variables)(),
      options
    )};

export const AddReferenceBookDocument = `
    mutation AddReferenceBook($bookAuthor: jsonb = "", $bookLanguage: jsonb = "", $bookName: jsonb = "", $dateOfBorn: timestamp = "", $dateOfDeath: timestamp = "", $fromSchool: jsonb = "", $nazemName: jsonb = "", $numberOfLines: Int = 10, $title: jsonb = "") {
  insert_reference_books_one(
    object: {bookAuthor: $bookAuthor, bookLanguage: $bookLanguage, bookName: $bookName, dateOfBorn: $dateOfBorn, dateOfDeath: $dateOfDeath, fromSchool: $fromSchool, nazemName: $nazemName, numberOfLines: $numberOfLines, title: $title}
  ) {
    bookId
  }
}
    `;

export const useAddReferenceBookMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddReferenceBookMutation, TError, AddReferenceBookMutationVariables, TContext>) => {
    
    return useMutation<AddReferenceBookMutation, TError, AddReferenceBookMutationVariables, TContext>(
      ['AddReferenceBook'],
      (variables?: AddReferenceBookMutationVariables) => fetchData<AddReferenceBookMutation, AddReferenceBookMutationVariables>(AddReferenceBookDocument, variables)(),
      options
    )};

export const UpdateReferenceBookDocument = `
    mutation UpdateReferenceBook($bookId: String = "", $bookAuthor: jsonb = "", $bookLanguage: jsonb = "", $bookName: jsonb = "", $dateOfBorn: timestamp = "", $dateOfDeath: timestamp = "", $fromSchool: jsonb = "", $nazemName: jsonb = "", $numberOfLines: Int = 10, $title: jsonb = "", $pdfUrl: String = "") {
  update_reference_books_by_pk(
    pk_columns: {bookId: $bookId}
    _set: {bookAuthor: $bookAuthor, bookLanguage: $bookLanguage, bookName: $bookName, dateOfBorn: $dateOfBorn, dateOfDeath: $dateOfDeath, fromSchool: $fromSchool, nazemName: $nazemName, numberOfLines: $numberOfLines, title: $title, pdfUrl: $pdfUrl}
  ) {
    bookId
    pdfUrl
  }
}
    `;

export const useUpdateReferenceBookMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateReferenceBookMutation, TError, UpdateReferenceBookMutationVariables, TContext>) => {
    
    return useMutation<UpdateReferenceBookMutation, TError, UpdateReferenceBookMutationVariables, TContext>(
      ['UpdateReferenceBook'],
      (variables?: UpdateReferenceBookMutationVariables) => fetchData<UpdateReferenceBookMutation, UpdateReferenceBookMutationVariables>(UpdateReferenceBookDocument, variables)(),
      options
    )};

export const DeleteRefBookDocument = `
    mutation DeleteRefBook($bookId: String = "") {
  delete_reference_books_by_pk(bookId: $bookId) {
    bookId
  }
}
    `;

export const useDeleteRefBookMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRefBookMutation, TError, DeleteRefBookMutationVariables, TContext>) => {
    
    return useMutation<DeleteRefBookMutation, TError, DeleteRefBookMutationVariables, TContext>(
      ['DeleteRefBook'],
      (variables?: DeleteRefBookMutationVariables) => fetchData<DeleteRefBookMutation, DeleteRefBookMutationVariables>(DeleteRefBookDocument, variables)(),
      options
    )};

export const CreateRoleDocument = `
    mutation CreateRole($name: String, $role_actions: roles_actions_arr_rel_insert_input = {data: {actionId: ""}}) {
  insert_roles_one(object: {name: $name, roles_actions: $role_actions}) {
    id
  }
}
    `;

export const useCreateRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateRoleMutation, TError, CreateRoleMutationVariables, TContext>) => {
    
    return useMutation<CreateRoleMutation, TError, CreateRoleMutationVariables, TContext>(
      ['CreateRole'],
      (variables?: CreateRoleMutationVariables) => fetchData<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, variables)(),
      options
    )};

export const EditRoleDocument = `
    mutation EditRole($id: String = "", $name: String) {
  update_roles_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
  }
}
    `;

export const useEditRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditRoleMutation, TError, EditRoleMutationVariables, TContext>) => {
    
    return useMutation<EditRoleMutation, TError, EditRoleMutationVariables, TContext>(
      ['EditRole'],
      (variables?: EditRoleMutationVariables) => fetchData<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, variables)(),
      options
    )};

export const DeleteRoleDocument = `
    mutation DeleteRole($id: String = "") {
  delete_roles_by_pk(id: $id) {
    id
  }
}
    `;

export const useDeleteRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>) => {
    
    return useMutation<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>(
      ['DeleteRole'],
      (variables?: DeleteRoleMutationVariables) => fetchData<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, variables)(),
      options
    )};

export const ApproveSchoolUserDocument = `
    mutation ApproveSchoolUser($schoolUserId: String!, $userId: String!) {
  update_school_users_by_pk(
    pk_columns: {schoolUserId: $schoolUserId}
    _set: {status: "approved"}
  ) {
    schoolUserId
  }
  update_users_by_pk(_set: {isSchool: true}, pk_columns: {id: $userId}) {
    id
  }
}
    `;

export const useApproveSchoolUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ApproveSchoolUserMutation, TError, ApproveSchoolUserMutationVariables, TContext>) => {
    
    return useMutation<ApproveSchoolUserMutation, TError, ApproveSchoolUserMutationVariables, TContext>(
      ['ApproveSchoolUser'],
      (variables?: ApproveSchoolUserMutationVariables) => fetchData<ApproveSchoolUserMutation, ApproveSchoolUserMutationVariables>(ApproveSchoolUserDocument, variables)(),
      options
    )};

export const RejectSchoolUserDocument = `
    mutation RejectSchoolUser($schoolUserId: String!, $userId: String!) {
  update_school_users_by_pk(
    pk_columns: {schoolUserId: $schoolUserId}
    _set: {status: "rejected"}
  ) {
    schoolUserId
  }
  update_users_by_pk(_set: {isSchool: false}, pk_columns: {id: $userId}) {
    id
  }
}
    `;

export const useRejectSchoolUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RejectSchoolUserMutation, TError, RejectSchoolUserMutationVariables, TContext>) => {
    
    return useMutation<RejectSchoolUserMutation, TError, RejectSchoolUserMutationVariables, TContext>(
      ['RejectSchoolUser'],
      (variables?: RejectSchoolUserMutationVariables) => fetchData<RejectSchoolUserMutation, RejectSchoolUserMutationVariables>(RejectSchoolUserDocument, variables)(),
      options
    )};

export const DeleteSessionDocument = `
    mutation DeleteSession($id: String!) {
  delete_user_sessions(where: {sessionId: {_eq: $id}}) {
    affected_rows
  }
  delete_sessions_by_pk(id: $id) {
    id
  }
}
    `;

export const useDeleteSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSessionMutation, TError, DeleteSessionMutationVariables, TContext>) => {
    
    return useMutation<DeleteSessionMutation, TError, DeleteSessionMutationVariables, TContext>(
      ['DeleteSession'],
      (variables?: DeleteSessionMutationVariables) => fetchData<DeleteSessionMutation, DeleteSessionMutationVariables>(DeleteSessionDocument, variables)(),
      options
    )};

export const CreateSessionDocument = `
    mutation CreateSession($expiryDate: DateTime = "", $startDate: DateTime!, $description: JSONObject!, $mutoonMatnId: String!, $title: JSONObject!, $enable_conversation: Boolean = true, $enable_raise_hand: Boolean = true, $enable_recording: Boolean = true, $enable_upload_files: Boolean = true, $enable_video: Boolean = true, $frequency: String, $max_user_count: Int!, $prerequisiteIds: [String!], $days: [String!] = "", $duration: Int = 10) {
  createSession(
    mutoonMatnId: $mutoonMatnId
    createSessionInput: {description: $description, title: $title, startDate: $startDate, expiryDate: $expiryDate, enable_conversation: $enable_conversation, enable_raise_hand: $enable_raise_hand, enable_recording: $enable_recording, enable_upload_files: $enable_upload_files, enable_video: $enable_video, frequency: $frequency, max_user_count: $max_user_count, prerequisiteIds: $prerequisiteIds, days: $days, duration: $duration}
  ) {
    id
  }
}
    `;

export const useCreateSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSessionMutation, TError, CreateSessionMutationVariables, TContext>) => {
    
    return useMutation<CreateSessionMutation, TError, CreateSessionMutationVariables, TContext>(
      ['CreateSession'],
      (variables?: CreateSessionMutationVariables) => fetchData<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, variables)(),
      options
    )};

export const SubscribeSessionDocument = `
    mutation SubscribeSession($sessionId: String!) {
  subscribeSession(sessionId: $sessionId) {
    id
    title
    description
    startDate
    expiryDate
    type
    status
    delayLimit
    courseFileURL
    zoomLink
    youTubeLink
    days
    max_user_count
    frequency
    enable_raise_hand
    enable_conversation
    enable_upload_files
    enable_recording
    enable_video
    createdAt
    updatedAt
    deletedAt
    mutoonMatnId
  }
}
    `;

export const useSubscribeSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SubscribeSessionMutation, TError, SubscribeSessionMutationVariables, TContext>) => {
    
    return useMutation<SubscribeSessionMutation, TError, SubscribeSessionMutationVariables, TContext>(
      ['SubscribeSession'],
      (variables?: SubscribeSessionMutationVariables) => fetchData<SubscribeSessionMutation, SubscribeSessionMutationVariables>(SubscribeSessionDocument, variables)(),
      options
    )};

export const JoinSessionDocument = `
    mutation JoinSession($sessionId: String = "") {
  joinSession(sessionId: $sessionId)
}
    `;

export const useJoinSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<JoinSessionMutation, TError, JoinSessionMutationVariables, TContext>) => {
    
    return useMutation<JoinSessionMutation, TError, JoinSessionMutationVariables, TContext>(
      ['JoinSession'],
      (variables?: JoinSessionMutationVariables) => fetchData<JoinSessionMutation, JoinSessionMutationVariables>(JoinSessionDocument, variables)(),
      options
    )};

export const AddSoundsDocument = `
    mutation AddSounds($sounds: [sounds_insert_input!]!) {
  insert_sounds(objects: $sounds) {
    affected_rows
    returning {
      soundId
      fileUrl
    }
  }
}
    `;

export const useAddSoundsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddSoundsMutation, TError, AddSoundsMutationVariables, TContext>) => {
    
    return useMutation<AddSoundsMutation, TError, AddSoundsMutationVariables, TContext>(
      ['AddSounds'],
      (variables?: AddSoundsMutationVariables) => fetchData<AddSoundsMutation, AddSoundsMutationVariables>(AddSoundsDocument, variables)(),
      options
    )};

export const DeleteSoundDocument = `
    mutation DeleteSound($soundId: String = "") {
  delete_sounds_by_pk(soundId: $soundId) {
    soundId
  }
}
    `;

export const useDeleteSoundMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSoundMutation, TError, DeleteSoundMutationVariables, TContext>) => {
    
    return useMutation<DeleteSoundMutation, TError, DeleteSoundMutationVariables, TContext>(
      ['DeleteSound'],
      (variables?: DeleteSoundMutationVariables) => fetchData<DeleteSoundMutation, DeleteSoundMutationVariables>(DeleteSoundDocument, variables)(),
      options
    )};

export const UpdateSoundDocument = `
    mutation UpdateSound($soundId: String = "", $description: jsonb = "", $name: jsonb = "", $fileUrl: String = "") {
  update_sounds_by_pk(
    pk_columns: {soundId: $soundId}
    _set: {description: $description, name: $name, fileUrl: $fileUrl}
  ) {
    soundId
  }
}
    `;

export const useUpdateSoundMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSoundMutation, TError, UpdateSoundMutationVariables, TContext>) => {
    
    return useMutation<UpdateSoundMutation, TError, UpdateSoundMutationVariables, TContext>(
      ['UpdateSound'],
      (variables?: UpdateSoundMutationVariables) => fetchData<UpdateSoundMutation, UpdateSoundMutationVariables>(UpdateSoundDocument, variables)(),
      options
    )};

export const AddWordsDefinitionsDocument = `
    mutation AddWordsDefinitions($translations: [translations_insert_input!]!) {
  insert_translations(objects: $translations) {
    returning {
      mutoonSectionId
    }
  }
}
    `;

export const useAddWordsDefinitionsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddWordsDefinitionsMutation, TError, AddWordsDefinitionsMutationVariables, TContext>) => {
    
    return useMutation<AddWordsDefinitionsMutation, TError, AddWordsDefinitionsMutationVariables, TContext>(
      ['AddWordsDefinitions'],
      (variables?: AddWordsDefinitionsMutationVariables) => fetchData<AddWordsDefinitionsMutation, AddWordsDefinitionsMutationVariables>(AddWordsDefinitionsDocument, variables)(),
      options
    )};

export const DeleteTranslationDocument = `
    mutation DeleteTranslation($translationId: String = "") {
  delete_translations_by_pk(translationId: $translationId) {
    translationId
  }
}
    `;

export const useDeleteTranslationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTranslationMutation, TError, DeleteTranslationMutationVariables, TContext>) => {
    
    return useMutation<DeleteTranslationMutation, TError, DeleteTranslationMutationVariables, TContext>(
      ['DeleteTranslation'],
      (variables?: DeleteTranslationMutationVariables) => fetchData<DeleteTranslationMutation, DeleteTranslationMutationVariables>(DeleteTranslationDocument, variables)(),
      options
    )};

export const UpdateTranslationDocument = `
    mutation UpdateTranslation($translationId: String = "", $translatedText: jsonb = "", $word: jsonb = "") {
  update_translations_by_pk(
    pk_columns: {translationId: $translationId}
    _set: {translatedText: $translatedText, word: $word}
  ) {
    translatedText
    word
  }
}
    `;

export const useUpdateTranslationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTranslationMutation, TError, UpdateTranslationMutationVariables, TContext>) => {
    
    return useMutation<UpdateTranslationMutation, TError, UpdateTranslationMutationVariables, TContext>(
      ['UpdateTranslation'],
      (variables?: UpdateTranslationMutationVariables) => fetchData<UpdateTranslationMutation, UpdateTranslationMutationVariables>(UpdateTranslationDocument, variables)(),
      options
    )};

export const DeleteUserRecordDocument = `
    mutation DeleteUserRecord($recordingId: String = "") {
  delete_user_records_by_pk(recordingId: $recordingId) {
    recordingId
  }
}
    `;

export const useDeleteUserRecordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserRecordMutation, TError, DeleteUserRecordMutationVariables, TContext>) => {
    
    return useMutation<DeleteUserRecordMutation, TError, DeleteUserRecordMutationVariables, TContext>(
      ['DeleteUserRecord'],
      (variables?: DeleteUserRecordMutationVariables) => fetchData<DeleteUserRecordMutation, DeleteUserRecordMutationVariables>(DeleteUserRecordDocument, variables)(),
      options
    )};

export const ApproveUserRecordDocument = `
    mutation ApproveUserRecord($fileUrl: String = "", $mutoonSectionId: String = "", $name: jsonb = "", $recordingId: String!) {
  insert_sounds_one(
    object: {fileUrl: $fileUrl, mutoonSectionId: $mutoonSectionId, name: $name}
  ) {
    soundId
  }
  update_user_records_by_pk(
    pk_columns: {recordingId: $recordingId}
    _set: {status: "approved"}
  ) {
    recordingId
  }
}
    `;

export const useApproveUserRecordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ApproveUserRecordMutation, TError, ApproveUserRecordMutationVariables, TContext>) => {
    
    return useMutation<ApproveUserRecordMutation, TError, ApproveUserRecordMutationVariables, TContext>(
      ['ApproveUserRecord'],
      (variables?: ApproveUserRecordMutationVariables) => fetchData<ApproveUserRecordMutation, ApproveUserRecordMutationVariables>(ApproveUserRecordDocument, variables)(),
      options
    )};

export const RejectUserRecordDocument = `
    mutation RejectUserRecord($recordingId: String!, $adminMessage: String = "") {
  update_user_records_by_pk(
    pk_columns: {recordingId: $recordingId}
    _set: {status: "rejected", adminMessage: $adminMessage}
  ) {
    recordingId
  }
}
    `;

export const useRejectUserRecordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RejectUserRecordMutation, TError, RejectUserRecordMutationVariables, TContext>) => {
    
    return useMutation<RejectUserRecordMutation, TError, RejectUserRecordMutationVariables, TContext>(
      ['RejectUserRecord'],
      (variables?: RejectUserRecordMutationVariables) => fetchData<RejectUserRecordMutation, RejectUserRecordMutationVariables>(RejectUserRecordDocument, variables)(),
      options
    )};

export const DeleteUserDocument = `
    mutation DeleteUser($id: String = "") {
  delete_users_by_pk(id: $id) {
    id
  }
}
    `;

export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>) => {
    
    return useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      (variables?: DeleteUserMutationVariables) => fetchData<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables)(),
      options
    )};

export const ActionsDocument = `
    query Actions {
  actions {
    id
    systemCriteria
    name
  }
}
    `;

export const useActionsQuery = <
      TData = ActionsQuery,
      TError = unknown
    >(
      variables?: ActionsQueryVariables,
      options?: UseQueryOptions<ActionsQuery, TError, TData>
    ) => {
    
    return useQuery<ActionsQuery, TError, TData>(
      variables === undefined ? ['Actions'] : ['Actions', variables],
      fetchData<ActionsQuery, ActionsQueryVariables>(ActionsDocument, variables),
      options
    )};

export const AllAdminsDocument = `
    query AllAdmins($username: order_by = asc) {
  admins(order_by: {username: $username}) {
    id
    username
    roleId
  }
}
    `;

export const useAllAdminsQuery = <
      TData = AllAdminsQuery,
      TError = unknown
    >(
      variables?: AllAdminsQueryVariables,
      options?: UseQueryOptions<AllAdminsQuery, TError, TData>
    ) => {
    
    return useQuery<AllAdminsQuery, TError, TData>(
      variables === undefined ? ['AllAdmins'] : ['AllAdmins', variables],
      fetchData<AllAdminsQuery, AllAdminsQueryVariables>(AllAdminsDocument, variables),
      options
    )};

export const AdminsDocument = `
    query Admins($offset: Int = 0, $limit: Int = 10, $search: String_comparison_exp = {}, $order_by: [admins_order_by!] = {}) {
  admins(
    offset: $offset
    limit: $limit
    where: {_or: [{username: $search}]}
    order_by: $order_by
  ) {
    id
    roleId
    role {
      name
    }
    username
    email
  }
  admins_aggregate(where: {_or: [{username: $search}]}) {
    aggregate {
      count
    }
  }
}
    `;

export const useAdminsQuery = <
      TData = AdminsQuery,
      TError = unknown
    >(
      variables?: AdminsQueryVariables,
      options?: UseQueryOptions<AdminsQuery, TError, TData>
    ) => {
    
    return useQuery<AdminsQuery, TError, TData>(
      variables === undefined ? ['Admins'] : ['Admins', variables],
      fetchData<AdminsQuery, AdminsQueryVariables>(AdminsDocument, variables),
      options
    )};

export const OneAdminDocument = `
    query OneAdmin($id: String = "") {
  admins_by_pk(id: $id) {
    id
    roleId
    role {
      name
    }
    username
    email
  }
}
    `;

export const useOneAdminQuery = <
      TData = OneAdminQuery,
      TError = unknown
    >(
      variables?: OneAdminQueryVariables,
      options?: UseQueryOptions<OneAdminQuery, TError, TData>
    ) => {
    
    return useQuery<OneAdminQuery, TError, TData>(
      variables === undefined ? ['OneAdmin'] : ['OneAdmin', variables],
      fetchData<OneAdminQuery, OneAdminQueryVariables>(OneAdminDocument, variables),
      options
    )};

export const AuthorizedUsersDocument = `
    query AuthorizedUsers($limit: Int = 10, $offset: Int = 0, $search: String_comparison_exp = {}, $order_by: [authorized_users_order_by!] = {}) {
  authorized_users(
    offset: $offset
    limit: $limit
    where: {_or: [{user: {_or: {firstName: $search, lastName: $search}}}]}
    order_by: $order_by
  ) {
    ...authorized_user
  }
  authorized_users_aggregate(
    where: {_or: [{user: {_or: {firstName: $search, lastName: $search}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${Authorized_UserFragmentDoc}`;

export const useAuthorizedUsersQuery = <
      TData = AuthorizedUsersQuery,
      TError = unknown
    >(
      variables?: AuthorizedUsersQueryVariables,
      options?: UseQueryOptions<AuthorizedUsersQuery, TError, TData>
    ) => {
    
    return useQuery<AuthorizedUsersQuery, TError, TData>(
      variables === undefined ? ['AuthorizedUsers'] : ['AuthorizedUsers', variables],
      fetchData<AuthorizedUsersQuery, AuthorizedUsersQueryVariables>(AuthorizedUsersDocument, variables),
      options
    )};

export const AuthorizedUserByIdDocument = `
    query AuthorizedUserById($authorizedUserId: String = "") {
  authorized_users_by_pk(authorizedUserId: $authorizedUserId) {
    ...authorized_user
  }
}
    ${Authorized_UserFragmentDoc}`;

export const useAuthorizedUserByIdQuery = <
      TData = AuthorizedUserByIdQuery,
      TError = unknown
    >(
      variables?: AuthorizedUserByIdQueryVariables,
      options?: UseQueryOptions<AuthorizedUserByIdQuery, TError, TData>
    ) => {
    
    return useQuery<AuthorizedUserByIdQuery, TError, TData>(
      variables === undefined ? ['AuthorizedUserById'] : ['AuthorizedUserById', variables],
      fetchData<AuthorizedUserByIdQuery, AuthorizedUserByIdQueryVariables>(AuthorizedUserByIdDocument, variables),
      options
    )};

export const BadgesDocument = `
    query Badges($limit: Int = 10, $offset: Int = 0, $order_by: [badges_order_by!] = {}, $where: badges_bool_exp = {}) {
  badges(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ...badge
  }
  badges_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${BadgeFragmentDoc}`;

export const useBadgesQuery = <
      TData = BadgesQuery,
      TError = unknown
    >(
      variables?: BadgesQueryVariables,
      options?: UseQueryOptions<BadgesQuery, TError, TData>
    ) => {
    
    return useQuery<BadgesQuery, TError, TData>(
      variables === undefined ? ['Badges'] : ['Badges', variables],
      fetchData<BadgesQuery, BadgesQueryVariables>(BadgesDocument, variables),
      options
    )};

export const OneBadgeDocument = `
    query OneBadge($id: String = "") {
  badges_by_pk(badgeId: $id) {
    ...badge
  }
}
    ${BadgeFragmentDoc}`;

export const useOneBadgeQuery = <
      TData = OneBadgeQuery,
      TError = unknown
    >(
      variables?: OneBadgeQueryVariables,
      options?: UseQueryOptions<OneBadgeQuery, TError, TData>
    ) => {
    
    return useQuery<OneBadgeQuery, TError, TData>(
      variables === undefined ? ['OneBadge'] : ['OneBadge', variables],
      fetchData<OneBadgeQuery, OneBadgeQueryVariables>(OneBadgeDocument, variables),
      options
    )};

export const AllCategoriesDocument = `
    query AllCategories {
  categories {
    categoryId
    name
  }
}
    `;

export const useAllCategoriesQuery = <
      TData = AllCategoriesQuery,
      TError = unknown
    >(
      variables?: AllCategoriesQueryVariables,
      options?: UseQueryOptions<AllCategoriesQuery, TError, TData>
    ) => {
    
    return useQuery<AllCategoriesQuery, TError, TData>(
      variables === undefined ? ['AllCategories'] : ['AllCategories', variables],
      fetchData<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, variables),
      options
    )};

export const CategoriesDocument = `
    query Categories($limit: Int = 10, $offset: Int = 0, $_iregex: String = "") {
  categories(
    limit: $limit
    offset: $offset
    where: {_or: [{description: {_cast: {String: {_iregex: $_iregex}}}}, {name: {_cast: {String: {_iregex: $_iregex}}}}]}
  ) {
    ...category
  }
  categories_aggregate(
    where: {_or: [{description: {_cast: {String: {_iregex: $_iregex}}}}, {name: {_cast: {String: {_iregex: $_iregex}}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${CategoryFragmentDoc}`;

export const useCategoriesQuery = <
      TData = CategoriesQuery,
      TError = unknown
    >(
      variables?: CategoriesQueryVariables,
      options?: UseQueryOptions<CategoriesQuery, TError, TData>
    ) => {
    
    return useQuery<CategoriesQuery, TError, TData>(
      variables === undefined ? ['Categories'] : ['Categories', variables],
      fetchData<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, variables),
      options
    )};

export const CategoryByIdDocument = `
    query CategoryById($categoryId: String = "") {
  categories_by_pk(categoryId: $categoryId) {
    ...category
  }
}
    ${CategoryFragmentDoc}`;

export const useCategoryByIdQuery = <
      TData = CategoryByIdQuery,
      TError = unknown
    >(
      variables?: CategoryByIdQueryVariables,
      options?: UseQueryOptions<CategoryByIdQuery, TError, TData>
    ) => {
    
    return useQuery<CategoryByIdQuery, TError, TData>(
      variables === undefined ? ['CategoryById'] : ['CategoryById', variables],
      fetchData<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, variables),
      options
    )};

export const CertificatesDocument = `
    query Certificates($offset: Int = 0, $limit: Int = 10, $order_by: [certificates_order_by!] = {}, $search: String_comparison_exp = {}) {
  certificates(
    offset: $offset
    limit: $limit
    order_by: $order_by
    where: {title: $search}
  ) {
    certificateId
    createdAt
    image
    title
  }
  certificates_aggregate(where: {title: $search}) {
    aggregate {
      count
    }
  }
}
    `;

export const useCertificatesQuery = <
      TData = CertificatesQuery,
      TError = unknown
    >(
      variables?: CertificatesQueryVariables,
      options?: UseQueryOptions<CertificatesQuery, TError, TData>
    ) => {
    
    return useQuery<CertificatesQuery, TError, TData>(
      variables === undefined ? ['Certificates'] : ['Certificates', variables],
      fetchData<CertificatesQuery, CertificatesQueryVariables>(CertificatesDocument, variables),
      options
    )};

export const AllCertificatesDocument = `
    query AllCertificates {
  certificates(order_by: {title: asc}) {
    certificateId
    createdAt
    image
    title
  }
  certificates_aggregate {
    aggregate {
      count
    }
  }
}
    `;

export const useAllCertificatesQuery = <
      TData = AllCertificatesQuery,
      TError = unknown
    >(
      variables?: AllCertificatesQueryVariables,
      options?: UseQueryOptions<AllCertificatesQuery, TError, TData>
    ) => {
    
    return useQuery<AllCertificatesQuery, TError, TData>(
      variables === undefined ? ['AllCertificates'] : ['AllCertificates', variables],
      fetchData<AllCertificatesQuery, AllCertificatesQueryVariables>(AllCertificatesDocument, variables),
      options
    )};

export const GetUserCertificateDocument = `
    query GetUserCertificate($userHearingCouncilId: String = "c9JAKCgFaiPP3V2Qc0eKg") {
  user_certificates(where: {userHearingCouncilId: {_eq: $userHearingCouncilId}}) {
    downloadPath
  }
}
    `;

export const useGetUserCertificateQuery = <
      TData = GetUserCertificateQuery,
      TError = unknown
    >(
      variables?: GetUserCertificateQueryVariables,
      options?: UseQueryOptions<GetUserCertificateQuery, TError, TData>
    ) => {
    
    return useQuery<GetUserCertificateQuery, TError, TData>(
      variables === undefined ? ['GetUserCertificate'] : ['GetUserCertificate', variables],
      fetchData<GetUserCertificateQuery, GetUserCertificateQueryVariables>(GetUserCertificateDocument, variables),
      options
    )};

export const ContactUsDocument = `
    query ContactUs($where: contact_us_bool_exp = {}, $limit: Int = 10, $offset: Int = 0, $order_by: [contact_us_order_by!] = {}) {
  contact_us(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    ...contact
  }
  contact_us_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${ContactFragmentDoc}`;

export const useContactUsQuery = <
      TData = ContactUsQuery,
      TError = unknown
    >(
      variables?: ContactUsQueryVariables,
      options?: UseQueryOptions<ContactUsQuery, TError, TData>
    ) => {
    
    return useQuery<ContactUsQuery, TError, TData>(
      variables === undefined ? ['ContactUs'] : ['ContactUs', variables],
      fetchData<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, variables),
      options
    )};

export const ExamsDocument = `
    query Exams($limit: Int = 10, $offset: Int = 0, $search: String_comparison_exp = {}, $order_by: [exams_order_by!] = {}) {
  exams(
    limit: $limit
    offset: $offset
    where: {_or: [{description: {_cast: {String: $search}}}, {title: {_cast: {String: $search}}}, {mutoon: {name: {_cast: {String: $search}}}}]}
    order_by: $order_by
  ) {
    ...exam
  }
  exams_aggregate(
    where: {_or: [{description: {_cast: {String: $search}}}, {title: {_cast: {String: $search}}}, {mutoon: {name: {_cast: {String: $search}}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${ExamFragmentDoc}`;

export const useExamsQuery = <
      TData = ExamsQuery,
      TError = unknown
    >(
      variables?: ExamsQueryVariables,
      options?: UseQueryOptions<ExamsQuery, TError, TData>
    ) => {
    
    return useQuery<ExamsQuery, TError, TData>(
      variables === undefined ? ['Exams'] : ['Exams', variables],
      fetchData<ExamsQuery, ExamsQueryVariables>(ExamsDocument, variables),
      options
    )};

export const OneExamDocument = `
    query OneExam($examId: String = "") {
  exams_by_pk(examId: $examId) {
    ...exam
  }
}
    ${ExamFragmentDoc}`;

export const useOneExamQuery = <
      TData = OneExamQuery,
      TError = unknown
    >(
      variables?: OneExamQueryVariables,
      options?: UseQueryOptions<OneExamQuery, TError, TData>
    ) => {
    
    return useQuery<OneExamQuery, TError, TData>(
      variables === undefined ? ['OneExam'] : ['OneExam', variables],
      fetchData<OneExamQuery, OneExamQueryVariables>(OneExamDocument, variables),
      options
    )};

export const FaQsDocument = `
    query FAQs($limit: Int = 10, $offset: Int = 0, $order_by: [faqs_order_by!] = {}, $where: faqs_bool_exp = {}) {
  faqs(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    answer
    question
    createdAt
    updatedAt
    id
  }
  faqs_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export const useFaQsQuery = <
      TData = FaQsQuery,
      TError = unknown
    >(
      variables?: FaQsQueryVariables,
      options?: UseQueryOptions<FaQsQuery, TError, TData>
    ) => {
    
    return useQuery<FaQsQuery, TError, TData>(
      variables === undefined ? ['FAQs'] : ['FAQs', variables],
      fetchData<FaQsQuery, FaQsQueryVariables>(FaQsDocument, variables),
      options
    )};

export const OneFaqDocument = `
    query OneFAQ($id: String = "") {
  faqs_by_pk(id: $id) {
    answer
    createdAt
    id
    question
    updatedAt
  }
}
    `;

export const useOneFaqQuery = <
      TData = OneFaqQuery,
      TError = unknown
    >(
      variables?: OneFaqQueryVariables,
      options?: UseQueryOptions<OneFaqQuery, TError, TData>
    ) => {
    
    return useQuery<OneFaqQuery, TError, TData>(
      variables === undefined ? ['OneFAQ'] : ['OneFAQ', variables],
      fetchData<OneFaqQuery, OneFaqQueryVariables>(OneFaqDocument, variables),
      options
    )};

export const HearingCouncilsDocument = `
    query HearingCouncils($limit: Int = 10, $offset: Int = 0, $search: String = "", $status: String_comparison_exp = {}, $startDate: timestamptz_comparison_exp = {}, $expiryDate: timestamptz_comparison_exp = {}, $userId: String_comparison_exp = {}, $order_by: [hearing_councils_order_by!] = {createdAt: desc}) {
  hearing_councils(
    offset: $offset
    limit: $limit
    where: {_or: [{description: {_cast: {String: {_iregex: $search}}}}, {sessionName: {_cast: {String: {_iregex: $search}}}}], status: $status, startDate: $startDate, expiryDate: $expiryDate, hearing_councils_user_hearing_councils: {userId: $userId}}
    order_by: $order_by
  ) {
    ...hearing_council
  }
  hearing_councils_aggregate(
    where: {_or: [{description: {_cast: {String: {_iregex: $search}}}}, {sessionName: {_cast: {String: {_iregex: $search}}}}], status: $status, startDate: $startDate, expiryDate: $expiryDate, hearing_councils_user_hearing_councils: {userId: $userId}}
  ) {
    aggregate {
      count
    }
  }
}
    ${Hearing_CouncilFragmentDoc}`;

export const useHearingCouncilsQuery = <
      TData = HearingCouncilsQuery,
      TError = unknown
    >(
      variables?: HearingCouncilsQueryVariables,
      options?: UseQueryOptions<HearingCouncilsQuery, TError, TData>
    ) => {
    
    return useQuery<HearingCouncilsQuery, TError, TData>(
      variables === undefined ? ['HearingCouncils'] : ['HearingCouncils', variables],
      fetchData<HearingCouncilsQuery, HearingCouncilsQueryVariables>(HearingCouncilsDocument, variables),
      options
    )};

export const OneHearingCouncilDocument = `
    query OneHearingCouncil($hearingCouncilId: String!) {
  hearing_councils_by_pk(hearingCouncilId: $hearingCouncilId) {
    ...hearing_council
  }
}
    ${Hearing_CouncilFragmentDoc}`;

export const useOneHearingCouncilQuery = <
      TData = OneHearingCouncilQuery,
      TError = unknown
    >(
      variables: OneHearingCouncilQueryVariables,
      options?: UseQueryOptions<OneHearingCouncilQuery, TError, TData>
    ) => {
    
    return useQuery<OneHearingCouncilQuery, TError, TData>(
      ['OneHearingCouncil', variables],
      fetchData<OneHearingCouncilQuery, OneHearingCouncilQueryVariables>(OneHearingCouncilDocument, variables),
      options
    )};

export const HearingCouncilAnalysisDocument = `
    query HearingCouncilAnalysis($hearingCouncilId: String! = "", $limit: Int, $offset: Int, $search: String_comparison_exp = {}, $order_by: [user_hearing_councils_order_by!] = {}, $user: users_bool_exp = {}) {
  user_hearing_councils(
    where: {hearingCouncilId: {_eq: $hearingCouncilId}, _or: [{user: {firstName: $search}}, {user: {lastName: $search}}], user: $user}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    user_certificate {
      id
      downloadPath
      userHearingCouncil {
        hearingCouncil {
          hearingCouncilId
        }
      }
    }
    user {
      avatar
      firstName
      lastName
      id
      email
      school_user {
        students(
          where: {user_hearing_councils: {hearingCouncilId: {_eq: $hearingCouncilId}}}
        ) {
          firstName
          lastName
          email
          id
          user_hearing_councils {
            hearingCouncilId
            userHearingCouncilId
            user_certificate {
              downloadPath
              certificateId
            }
          }
        }
      }
    }
    joinedAt
    durationAttendance
    hearingCouncil {
      duration
    }
    userHearingCouncilId
    numSubmissions
  }
  user_hearing_councils_aggregate(
    where: {hearingCouncilId: {_eq: $hearingCouncilId}, _or: [{user: {firstName: $search}}, {user: {lastName: $search}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export const useHearingCouncilAnalysisQuery = <
      TData = HearingCouncilAnalysisQuery,
      TError = unknown
    >(
      variables?: HearingCouncilAnalysisQueryVariables,
      options?: UseQueryOptions<HearingCouncilAnalysisQuery, TError, TData>
    ) => {
    
    return useQuery<HearingCouncilAnalysisQuery, TError, TData>(
      variables === undefined ? ['HearingCouncilAnalysis'] : ['HearingCouncilAnalysis', variables],
      fetchData<HearingCouncilAnalysisQuery, HearingCouncilAnalysisQueryVariables>(HearingCouncilAnalysisDocument, variables),
      options
    )};

export const HearingCouncilsAnalysisDocument = `
    query HearingCouncilsAnalysis($where: hearing_councils_bool_exp) {
  hearing_councils(where: $where) {
    ...hearing_council
  }
  hearing_councils_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${Hearing_CouncilFragmentDoc}`;

export const useHearingCouncilsAnalysisQuery = <
      TData = HearingCouncilsAnalysisQuery,
      TError = unknown
    >(
      variables?: HearingCouncilsAnalysisQueryVariables,
      options?: UseQueryOptions<HearingCouncilsAnalysisQuery, TError, TData>
    ) => {
    
    return useQuery<HearingCouncilsAnalysisQuery, TError, TData>(
      variables === undefined ? ['HearingCouncilsAnalysis'] : ['HearingCouncilsAnalysis', variables],
      fetchData<HearingCouncilsAnalysisQuery, HearingCouncilsAnalysisQueryVariables>(HearingCouncilsAnalysisDocument, variables),
      options
    )};

export const UserHearingCouncilByCouncilIdAndUserIdDocument = `
    query UserHearingCouncilByCouncilIdAndUserId($hearingCouncilId: String = "", $userId: String = "") {
  user_hearing_councils(
    where: {hearingCouncilId: {_eq: $hearingCouncilId}, adminId: {_eq: $userId}}
  ) {
    userHearingCouncilId
  }
}
    `;

export const useUserHearingCouncilByCouncilIdAndUserIdQuery = <
      TData = UserHearingCouncilByCouncilIdAndUserIdQuery,
      TError = unknown
    >(
      variables?: UserHearingCouncilByCouncilIdAndUserIdQueryVariables,
      options?: UseQueryOptions<UserHearingCouncilByCouncilIdAndUserIdQuery, TError, TData>
    ) => {
    
    return useQuery<UserHearingCouncilByCouncilIdAndUserIdQuery, TError, TData>(
      variables === undefined ? ['UserHearingCouncilByCouncilIdAndUserId'] : ['UserHearingCouncilByCouncilIdAndUserId', variables],
      fetchData<UserHearingCouncilByCouncilIdAndUserIdQuery, UserHearingCouncilByCouncilIdAndUserIdQueryVariables>(UserHearingCouncilByCouncilIdAndUserIdDocument, variables),
      options
    )};

export const KnowledgeBasesDocument = `
    query knowledgeBases($limit: Int = 10, $offset: Int = 0, $order_by: [knowledge_bases_order_by!] = {}, $search: String_comparison_exp = {}) {
  knowledge_bases(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {_or: [{title: {_cast: {String: $search}}}, {content: {_cast: {String: $search}}}]}
  ) {
    ...knowledge_base
  }
  knowledge_bases_aggregate {
    aggregate {
      count
    }
  }
}
    ${Knowledge_BaseFragmentDoc}`;

export const useKnowledgeBasesQuery = <
      TData = KnowledgeBasesQuery,
      TError = unknown
    >(
      variables?: KnowledgeBasesQueryVariables,
      options?: UseQueryOptions<KnowledgeBasesQuery, TError, TData>
    ) => {
    
    return useQuery<KnowledgeBasesQuery, TError, TData>(
      variables === undefined ? ['knowledgeBases'] : ['knowledgeBases', variables],
      fetchData<KnowledgeBasesQuery, KnowledgeBasesQueryVariables>(KnowledgeBasesDocument, variables),
      options
    )};

export const OneKnowledgeBaseDocument = `
    query OneKnowledgeBase($knowledgeBaseId: String = "") {
  knowledge_bases_by_pk(knowledgeBaseId: $knowledgeBaseId) {
    ...knowledge_base
  }
}
    ${Knowledge_BaseFragmentDoc}`;

export const useOneKnowledgeBaseQuery = <
      TData = OneKnowledgeBaseQuery,
      TError = unknown
    >(
      variables?: OneKnowledgeBaseQueryVariables,
      options?: UseQueryOptions<OneKnowledgeBaseQuery, TError, TData>
    ) => {
    
    return useQuery<OneKnowledgeBaseQuery, TError, TData>(
      variables === undefined ? ['OneKnowledgeBase'] : ['OneKnowledgeBase', variables],
      fetchData<OneKnowledgeBaseQuery, OneKnowledgeBaseQueryVariables>(OneKnowledgeBaseDocument, variables),
      options
    )};

export const AllLevelsDocument = `
    query AllLevels($categoryId: String = "") {
  levels(where: {categoryId: {_eq: $categoryId}}) {
    categoryId
    levelId
    name
  }
}
    `;

export const useAllLevelsQuery = <
      TData = AllLevelsQuery,
      TError = unknown
    >(
      variables?: AllLevelsQueryVariables,
      options?: UseQueryOptions<AllLevelsQuery, TError, TData>
    ) => {
    
    return useQuery<AllLevelsQuery, TError, TData>(
      variables === undefined ? ['AllLevels'] : ['AllLevels', variables],
      fetchData<AllLevelsQuery, AllLevelsQueryVariables>(AllLevelsDocument, variables),
      options
    )};

export const LevelsDocument = `
    query Levels($limit: Int = 10, $offset: Int = 0, $_iregex: String = "", $_eq: String) {
  levels(
    limit: $limit
    offset: $offset
    where: {_or: [{description: {_cast: {String: {_iregex: $_iregex}}}}, {name: {_cast: {String: {_iregex: $_iregex}}}}], categoryId: {_eq: $_eq}}
  ) {
    categoryId
    description
    levelId
    name
    updatedAt
    createdAt
  }
  levels_aggregate(
    where: {_or: [{description: {_cast: {String: {_iregex: $_iregex}}}}, {name: {_cast: {String: {_iregex: $_iregex}}}}], categoryId: {_eq: $_eq}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export const useLevelsQuery = <
      TData = LevelsQuery,
      TError = unknown
    >(
      variables?: LevelsQueryVariables,
      options?: UseQueryOptions<LevelsQuery, TError, TData>
    ) => {
    
    return useQuery<LevelsQuery, TError, TData>(
      variables === undefined ? ['Levels'] : ['Levels', variables],
      fetchData<LevelsQuery, LevelsQueryVariables>(LevelsDocument, variables),
      options
    )};

export const LevelByIdDocument = `
    query LevelById($levelId: String = "") {
  levels_by_pk(levelId: $levelId) {
    categoryId
    description
    levelId
    name
    updatedAt
    createdAt
  }
}
    `;

export const useLevelByIdQuery = <
      TData = LevelByIdQuery,
      TError = unknown
    >(
      variables?: LevelByIdQueryVariables,
      options?: UseQueryOptions<LevelByIdQuery, TError, TData>
    ) => {
    
    return useQuery<LevelByIdQuery, TError, TData>(
      variables === undefined ? ['LevelById'] : ['LevelById', variables],
      fetchData<LevelByIdQuery, LevelByIdQueryVariables>(LevelByIdDocument, variables),
      options
    )};

export const MatnSectionsDocument = `
    query MatnSections($limit: Int = 10, $offset: Int = 0, $search: String_comparison_exp = {}, $mutoonMatnId: String, $order_by: [mutoon_sections_order_by!] = {}) {
  mutoon_sections(
    limit: $limit
    offset: $offset
    where: {_or: [{description: {_cast: {String: $search}}}, {name: {_cast: {String: $search}}}], mutoonMatnId: {_eq: $mutoonMatnId}}
    order_by: $order_by
  ) {
    description
    name
    updatedAt
    mutoonSectionId
    mutoonMatnId
  }
  mutoon_sections_aggregate(
    where: {_or: [{description: {_cast: {String: $search}}}, {name: {_cast: {String: $search}}}], mutoonMatnId: {_eq: $mutoonMatnId}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export const useMatnSectionsQuery = <
      TData = MatnSectionsQuery,
      TError = unknown
    >(
      variables?: MatnSectionsQueryVariables,
      options?: UseQueryOptions<MatnSectionsQuery, TError, TData>
    ) => {
    
    return useQuery<MatnSectionsQuery, TError, TData>(
      variables === undefined ? ['MatnSections'] : ['MatnSections', variables],
      fetchData<MatnSectionsQuery, MatnSectionsQueryVariables>(MatnSectionsDocument, variables),
      options
    )};

export const AllMatnSectionsDocument = `
    query AllMatnSections($matnId: String = "") {
  mutoon_sections(
    where: {mutoonMatnId: {_eq: $matnId}}
    order_by: {createdAt: asc}
  ) {
    name
    mutoonMatnId
    mutoonSectionId
  }
}
    `;

export const useAllMatnSectionsQuery = <
      TData = AllMatnSectionsQuery,
      TError = unknown
    >(
      variables?: AllMatnSectionsQueryVariables,
      options?: UseQueryOptions<AllMatnSectionsQuery, TError, TData>
    ) => {
    
    return useQuery<AllMatnSectionsQuery, TError, TData>(
      variables === undefined ? ['AllMatnSections'] : ['AllMatnSections', variables],
      fetchData<AllMatnSectionsQuery, AllMatnSectionsQueryVariables>(AllMatnSectionsDocument, variables),
      options
    )};

export const MatnSectionBySectionIdDocument = `
    query MatnSectionBySectionId($mutoonSectionId: String!) {
  mutoon_sections_by_pk(mutoonSectionId: $mutoonSectionId) {
    description
    name
  }
}
    `;

export const useMatnSectionBySectionIdQuery = <
      TData = MatnSectionBySectionIdQuery,
      TError = unknown
    >(
      variables: MatnSectionBySectionIdQueryVariables,
      options?: UseQueryOptions<MatnSectionBySectionIdQuery, TError, TData>
    ) => {
    
    return useQuery<MatnSectionBySectionIdQuery, TError, TData>(
      ['MatnSectionBySectionId', variables],
      fetchData<MatnSectionBySectionIdQuery, MatnSectionBySectionIdQueryVariables>(MatnSectionBySectionIdDocument, variables),
      options
    )};

export const MutoonCategoriesDocument = `
    query MutoonCategories($categoryCond: String_comparison_exp!, $limit: Int = 10, $offset: Int = 0, $search: jsonb_comparison_exp = {}, $order_by: [categories_order_by!] = {}, $exclude_ctgs: String_comparison_exp = {}) {
  categories(
    where: {parentId: $categoryCond, name: $search, categoryId: $exclude_ctgs}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...category
  }
  categories_aggregate(
    where: {parentId: $categoryCond, name: $search, categoryId: $exclude_ctgs}
  ) {
    aggregate {
      count
    }
  }
  mutoon_categories(
    where: {categoryId: $categoryCond, mutoon: {name: $search}}
    distinct_on: mutoonId
  ) {
    categoryId
    mutoonId
    id
    mutoon {
      ...matn
    }
    category {
      categoryId
      name
    }
  }
  mutoon_categories_aggregate(
    where: {categoryId: $categoryCond, mutoon: {name: $search}}
    distinct_on: mutoonId
  ) {
    aggregate {
      count
    }
  }
}
    ${CategoryFragmentDoc}
${MatnFragmentDoc}`;

export const useMutoonCategoriesQuery = <
      TData = MutoonCategoriesQuery,
      TError = unknown
    >(
      variables: MutoonCategoriesQueryVariables,
      options?: UseQueryOptions<MutoonCategoriesQuery, TError, TData>
    ) => {
    
    return useQuery<MutoonCategoriesQuery, TError, TData>(
      ['MutoonCategories', variables],
      fetchData<MutoonCategoriesQuery, MutoonCategoriesQueryVariables>(MutoonCategoriesDocument, variables),
      options
    )};

export const AllMutoonsDocument = `
    query AllMutoons($cateogryId: String_comparison_exp) {
  mutoon_categories(where: {categoryId: $cateogryId}) {
    id
    categoryId
    mutoonId
    mutoon {
      name
    }
  }
}
    `;

export const useAllMutoonsQuery = <
      TData = AllMutoonsQuery,
      TError = unknown
    >(
      variables?: AllMutoonsQueryVariables,
      options?: UseQueryOptions<AllMutoonsQuery, TError, TData>
    ) => {
    
    return useQuery<AllMutoonsQuery, TError, TData>(
      variables === undefined ? ['AllMutoons'] : ['AllMutoons', variables],
      fetchData<AllMutoonsQuery, AllMutoonsQueryVariables>(AllMutoonsDocument, variables),
      options
    )};

export const MutoonsDocument = `
    query Mutoons($limit: Int = 10, $offset: Int = 0, $_iregex: String = "", $levelId: String_comparison_exp) {
  mutoons(
    limit: $limit
    offset: $offset
    where: {_or: [{description: {_cast: {String: {_iregex: $_iregex}}}}, {name: {_cast: {String: {_iregex: $_iregex}}}}], levelId: $levelId}
  ) {
    ...matn
  }
  mutoons_aggregate(
    where: {_or: [{description: {_cast: {String: {_iregex: $_iregex}}}}, {name: {_cast: {String: {_iregex: $_iregex}}}}], levelId: $levelId}
  ) {
    aggregate {
      count
    }
  }
}
    ${MatnFragmentDoc}`;

export const useMutoonsQuery = <
      TData = MutoonsQuery,
      TError = unknown
    >(
      variables?: MutoonsQueryVariables,
      options?: UseQueryOptions<MutoonsQuery, TError, TData>
    ) => {
    
    return useQuery<MutoonsQuery, TError, TData>(
      variables === undefined ? ['Mutoons'] : ['Mutoons', variables],
      fetchData<MutoonsQuery, MutoonsQueryVariables>(MutoonsDocument, variables),
      options
    )};

export const MutoonByIdDocument = `
    query MutoonById($mutoonId: String!) {
  mutoons_by_pk(id: $mutoonId) {
    ...matn
  }
}
    ${MatnFragmentDoc}`;

export const useMutoonByIdQuery = <
      TData = MutoonByIdQuery,
      TError = unknown
    >(
      variables: MutoonByIdQueryVariables,
      options?: UseQueryOptions<MutoonByIdQuery, TError, TData>
    ) => {
    
    return useQuery<MutoonByIdQuery, TError, TData>(
      ['MutoonById', variables],
      fetchData<MutoonByIdQuery, MutoonByIdQueryVariables>(MutoonByIdDocument, variables),
      options
    )};

export const QuestionsDocument = `
    query Questions($limit: Int, $offset: Int = 0, $search: jsonb_comparison_exp = {}, $examId: String_comparison_exp = {}, $matnName: jsonb_comparison_exp = {}, $matnId: String_comparison_exp = {}, $matnSectionId: String_comparison_exp = {}, $choicesType: String_comparison_exp = {}, $isQuiz: Boolean_comparison_exp = {}, $order_by: [questions_order_by!]) {
  questions(
    limit: $limit
    offset: $offset
    where: {questionText: $search, examId: $examId, exam: {mutoon: {_or: {name: $matnName, id: $matnId}}}, mutoonSectionId: $matnSectionId, choicesType: $choicesType, isQuiz: $isQuiz}
    order_by: $order_by
  ) {
    ...question
  }
  questions_aggregate(
    where: {questionText: $search, examId: $examId, exam: {mutoon: {_or: {name: $matnName, id: $matnId}}}, mutoonSectionId: $matnSectionId, choicesType: $choicesType, isQuiz: $isQuiz}
  ) {
    aggregate {
      count
    }
  }
}
    ${QuestionFragmentDoc}`;

export const useQuestionsQuery = <
      TData = QuestionsQuery,
      TError = unknown
    >(
      variables?: QuestionsQueryVariables,
      options?: UseQueryOptions<QuestionsQuery, TError, TData>
    ) => {
    
    return useQuery<QuestionsQuery, TError, TData>(
      variables === undefined ? ['Questions'] : ['Questions', variables],
      fetchData<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, variables),
      options
    )};

export const OneQuestionsDocument = `
    query OneQuestions($questionId: String = "") {
  questions_by_pk(questionId: $questionId) {
    ...question
  }
}
    ${QuestionFragmentDoc}`;

export const useOneQuestionsQuery = <
      TData = OneQuestionsQuery,
      TError = unknown
    >(
      variables?: OneQuestionsQueryVariables,
      options?: UseQueryOptions<OneQuestionsQuery, TError, TData>
    ) => {
    
    return useQuery<OneQuestionsQuery, TError, TData>(
      variables === undefined ? ['OneQuestions'] : ['OneQuestions', variables],
      fetchData<OneQuestionsQuery, OneQuestionsQueryVariables>(OneQuestionsDocument, variables),
      options
    )};

export const ReferenceBooksDocument = `
    query ReferenceBooks($limit: Int = 10, $offset: Int = 0, $search: String_comparison_exp = {}, $order_by: [reference_books_order_by!], $matnId: String_comparison_exp = {}) {
  reference_books(
    limit: $limit
    offset: $offset
    where: {_or: [{bookAuthor: {_cast: {String: $search}}}, {bookName: {_cast: {String: $search}}}], mutoons: {id: $matnId}}
    order_by: $order_by
  ) {
    ...book
  }
  reference_books_aggregate(
    where: {_or: [{bookAuthor: {_cast: {String: $search}}}, {bookName: {_cast: {String: $search}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${BookFragmentDoc}`;

export const useReferenceBooksQuery = <
      TData = ReferenceBooksQuery,
      TError = unknown
    >(
      variables?: ReferenceBooksQueryVariables,
      options?: UseQueryOptions<ReferenceBooksQuery, TError, TData>
    ) => {
    
    return useQuery<ReferenceBooksQuery, TError, TData>(
      variables === undefined ? ['ReferenceBooks'] : ['ReferenceBooks', variables],
      fetchData<ReferenceBooksQuery, ReferenceBooksQueryVariables>(ReferenceBooksDocument, variables),
      options
    )};

export const OneReferenceBookDocument = `
    query OneReferenceBook($bookId: String = "") {
  reference_books_by_pk(bookId: $bookId) {
    ...book
  }
}
    ${BookFragmentDoc}`;

export const useOneReferenceBookQuery = <
      TData = OneReferenceBookQuery,
      TError = unknown
    >(
      variables?: OneReferenceBookQueryVariables,
      options?: UseQueryOptions<OneReferenceBookQuery, TError, TData>
    ) => {
    
    return useQuery<OneReferenceBookQuery, TError, TData>(
      variables === undefined ? ['OneReferenceBook'] : ['OneReferenceBook', variables],
      fetchData<OneReferenceBookQuery, OneReferenceBookQueryVariables>(OneReferenceBookDocument, variables),
      options
    )};

export const AllReferenceBooksDocument = `
    query AllReferenceBooks($order_by: [reference_books_order_by!]) {
  reference_books(order_by: $order_by) {
    ...book
  }
}
    ${BookFragmentDoc}`;

export const useAllReferenceBooksQuery = <
      TData = AllReferenceBooksQuery,
      TError = unknown
    >(
      variables?: AllReferenceBooksQueryVariables,
      options?: UseQueryOptions<AllReferenceBooksQuery, TError, TData>
    ) => {
    
    return useQuery<AllReferenceBooksQuery, TError, TData>(
      variables === undefined ? ['AllReferenceBooks'] : ['AllReferenceBooks', variables],
      fetchData<AllReferenceBooksQuery, AllReferenceBooksQueryVariables>(AllReferenceBooksDocument, variables),
      options
    )};

export const RoleActionsDocument = `
    query RoleActions($_eq: String) {
  roles_actions(where: {roleId: {_eq: $_eq}}) {
    action {
      id
      systemCriteria
      name
    }
  }
}
    `;

export const useRoleActionsQuery = <
      TData = RoleActionsQuery,
      TError = unknown
    >(
      variables?: RoleActionsQueryVariables,
      options?: UseQueryOptions<RoleActionsQuery, TError, TData>
    ) => {
    
    return useQuery<RoleActionsQuery, TError, TData>(
      variables === undefined ? ['RoleActions'] : ['RoleActions', variables],
      fetchData<RoleActionsQuery, RoleActionsQueryVariables>(RoleActionsDocument, variables),
      options
    )};

export const RoleByIdDocument = `
    query RoleById($id: String = "") {
  roles_by_pk(id: $id) {
    id
    name
  }
}
    `;

export const useRoleByIdQuery = <
      TData = RoleByIdQuery,
      TError = unknown
    >(
      variables?: RoleByIdQueryVariables,
      options?: UseQueryOptions<RoleByIdQuery, TError, TData>
    ) => {
    
    return useQuery<RoleByIdQuery, TError, TData>(
      variables === undefined ? ['RoleById'] : ['RoleById', variables],
      fetchData<RoleByIdQuery, RoleByIdQueryVariables>(RoleByIdDocument, variables),
      options
    )};

export const AllRolesDocument = `
    query AllRoles {
  roles {
    id
    name
  }
}
    `;

export const useAllRolesQuery = <
      TData = AllRolesQuery,
      TError = unknown
    >(
      variables?: AllRolesQueryVariables,
      options?: UseQueryOptions<AllRolesQuery, TError, TData>
    ) => {
    
    return useQuery<AllRolesQuery, TError, TData>(
      variables === undefined ? ['AllRoles'] : ['AllRoles', variables],
      fetchData<AllRolesQuery, AllRolesQueryVariables>(AllRolesDocument, variables),
      options
    )};

export const RolesDocument = `
    query Roles($limit: Int, $offset: Int, $name: String_comparison_exp = {}, $order_by: [roles_order_by!] = {}) {
  roles(limit: $limit, offset: $offset, where: {name: $name}, order_by: $order_by) {
    id
    name
    admins {
      username
    }
  }
  roles_aggregate(where: {name: $name}) {
    aggregate {
      count
    }
  }
}
    `;

export const useRolesQuery = <
      TData = RolesQuery,
      TError = unknown
    >(
      variables?: RolesQueryVariables,
      options?: UseQueryOptions<RolesQuery, TError, TData>
    ) => {
    
    return useQuery<RolesQuery, TError, TData>(
      variables === undefined ? ['Roles'] : ['Roles', variables],
      fetchData<RolesQuery, RolesQueryVariables>(RolesDocument, variables),
      options
    )};

export const SchoolStudentsDocument = `
    query SchoolStudents($order_by: [students_order_by!] = {}, $offset: Int = 0, $limit: Int = 10, $search: String_comparison_exp = {}, $userId: String!, $hearingCouncilId: String!) {
  students(
    order_by: $order_by
    limit: $limit
    offset: $offset
    where: {_or: [{firstName: $search}, {lastName: $search}, {email: $search}], school_user: {userId: {_eq: $userId}}, user_hearing_councils: {hearingCouncilId: {_eq: $hearingCouncilId}}}
  ) {
    birthDate
    createdAt
    email
    firstName
    id
    lastName
    user_hearing_councils {
      hearingCouncilId
      userHearingCouncilId
    }
    user_certificates {
      userHearingCouncil {
        hearingCouncil {
          hearingCouncilId
        }
      }
      downloadPath
    }
  }
  students_aggregate(
    where: {_or: [{firstName: $search}, {lastName: $search}, {email: $search}], school_user: {userId: {_eq: $userId}}, user_hearing_councils: {hearingCouncilId: {_eq: $hearingCouncilId}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export const useSchoolStudentsQuery = <
      TData = SchoolStudentsQuery,
      TError = unknown
    >(
      variables: SchoolStudentsQueryVariables,
      options?: UseQueryOptions<SchoolStudentsQuery, TError, TData>
    ) => {
    
    return useQuery<SchoolStudentsQuery, TError, TData>(
      ['SchoolStudents', variables],
      fetchData<SchoolStudentsQuery, SchoolStudentsQueryVariables>(SchoolStudentsDocument, variables),
      options
    )};

export const SchoolUsersDocument = `
    query SchoolUsers($limit: Int = 10, $offset: Int = 0, $order_by: [school_users_order_by!] = {}, $search: String_comparison_exp = {}) {
  school_users(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {_or: [{user: {firstName: $search}}, {user: {lastName: $search}}, {user: {email: $search}}, {city: $search}, {schoolName: $search}, {schoolAddress: $search}, {country: $search}]}
  ) {
    ...school_user
  }
  school_users_aggregate {
    aggregate {
      count
    }
  }
}
    ${School_UserFragmentDoc}`;

export const useSchoolUsersQuery = <
      TData = SchoolUsersQuery,
      TError = unknown
    >(
      variables?: SchoolUsersQueryVariables,
      options?: UseQueryOptions<SchoolUsersQuery, TError, TData>
    ) => {
    
    return useQuery<SchoolUsersQuery, TError, TData>(
      variables === undefined ? ['SchoolUsers'] : ['SchoolUsers', variables],
      fetchData<SchoolUsersQuery, SchoolUsersQueryVariables>(SchoolUsersDocument, variables),
      options
    )};

export const SchoolUserByIdDocument = `
    query SchoolUserById($schoolUserId: String = "") {
  school_users_by_pk(schoolUserId: $schoolUserId) {
    ...school_user
  }
}
    ${School_UserFragmentDoc}`;

export const useSchoolUserByIdQuery = <
      TData = SchoolUserByIdQuery,
      TError = unknown
    >(
      variables?: SchoolUserByIdQueryVariables,
      options?: UseQueryOptions<SchoolUserByIdQuery, TError, TData>
    ) => {
    
    return useQuery<SchoolUserByIdQuery, TError, TData>(
      variables === undefined ? ['SchoolUserById'] : ['SchoolUserById', variables],
      fetchData<SchoolUserByIdQuery, SchoolUserByIdQueryVariables>(SchoolUserByIdDocument, variables),
      options
    )};

export const SessionsDocument = `
    query Sessions($limit: Int = 100, $offset: Int = 0, $search: String = "", $userId: String_comparison_exp = {}, $status: String_comparison_exp = {}, $startDate: timestamptz_comparison_exp = {}, $expiryDate: timestamptz_comparison_exp = {}, $order_by: [sessions_order_by!] = {}) {
  sessions(
    offset: $offset
    limit: $limit
    where: {_or: [{description: {_cast: {String: {_iregex: $search}}}}, {title: {_cast: {String: {_iregex: $search}}}}], user_sessions: {userId: $userId}, status: $status, startDate: $startDate, expiryDate: $expiryDate}
    order_by: $order_by
  ) {
    ...session
  }
  sessions_aggregate(
    where: {_or: [{description: {_cast: {String: {_iregex: $search}}}}, {title: {_cast: {String: {_iregex: $search}}}}], user_sessions: {userId: $userId}, status: $status, startDate: $startDate, expiryDate: $expiryDate}
  ) {
    aggregate {
      count
    }
  }
}
    ${SessionFragmentDoc}`;

export const useSessionsQuery = <
      TData = SessionsQuery,
      TError = unknown
    >(
      variables?: SessionsQueryVariables,
      options?: UseQueryOptions<SessionsQuery, TError, TData>
    ) => {
    
    return useQuery<SessionsQuery, TError, TData>(
      variables === undefined ? ['Sessions'] : ['Sessions', variables],
      fetchData<SessionsQuery, SessionsQueryVariables>(SessionsDocument, variables),
      options
    )};

export const SessionsAnalysisDocument = `
    query SessionsAnalysis($where: sessions_bool_exp) {
  sessions(where: $where) {
    ...session
  }
  sessions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${SessionFragmentDoc}`;

export const useSessionsAnalysisQuery = <
      TData = SessionsAnalysisQuery,
      TError = unknown
    >(
      variables?: SessionsAnalysisQueryVariables,
      options?: UseQueryOptions<SessionsAnalysisQuery, TError, TData>
    ) => {
    
    return useQuery<SessionsAnalysisQuery, TError, TData>(
      variables === undefined ? ['SessionsAnalysis'] : ['SessionsAnalysis', variables],
      fetchData<SessionsAnalysisQuery, SessionsAnalysisQueryVariables>(SessionsAnalysisDocument, variables),
      options
    )};

export const SoundsByMatnSectionIdDocument = `
    query SoundsByMatnSectionId($matnSectionId: String = "", $limit: Int, $offset: Int) {
  sounds(
    where: {mutoonSectionId: {_eq: $matnSectionId}}
    limit: $limit
    offset: $offset
  ) {
    description
    fileUrl
    mutoonSectionId
    name
    soundId
  }
  sounds_aggregate(where: {mutoonSectionId: {_eq: $matnSectionId}}) {
    aggregate {
      count
    }
  }
}
    `;

export const useSoundsByMatnSectionIdQuery = <
      TData = SoundsByMatnSectionIdQuery,
      TError = unknown
    >(
      variables?: SoundsByMatnSectionIdQueryVariables,
      options?: UseQueryOptions<SoundsByMatnSectionIdQuery, TError, TData>
    ) => {
    
    return useQuery<SoundsByMatnSectionIdQuery, TError, TData>(
      variables === undefined ? ['SoundsByMatnSectionId'] : ['SoundsByMatnSectionId', variables],
      fetchData<SoundsByMatnSectionIdQuery, SoundsByMatnSectionIdQueryVariables>(SoundsByMatnSectionIdDocument, variables),
      options
    )};

export const TranslationsByMatnSectionIdDocument = `
    query TranslationsByMatnSectionId($_eq: String = "", $offset: Int, $limit: Int) {
  translations(
    where: {mutoonSectionId: {_eq: $_eq}}
    offset: $offset
    limit: $limit
  ) {
    translatedText
    word
    translationId
    mutoonSectionId
  }
  translations_aggregate(where: {mutoonSectionId: {_eq: $_eq}}) {
    aggregate {
      count
    }
  }
}
    `;

export const useTranslationsByMatnSectionIdQuery = <
      TData = TranslationsByMatnSectionIdQuery,
      TError = unknown
    >(
      variables?: TranslationsByMatnSectionIdQueryVariables,
      options?: UseQueryOptions<TranslationsByMatnSectionIdQuery, TError, TData>
    ) => {
    
    return useQuery<TranslationsByMatnSectionIdQuery, TError, TData>(
      variables === undefined ? ['TranslationsByMatnSectionId'] : ['TranslationsByMatnSectionId', variables],
      fetchData<TranslationsByMatnSectionIdQuery, TranslationsByMatnSectionIdQueryVariables>(TranslationsByMatnSectionIdDocument, variables),
      options
    )};

export const TranslationByIdDocument = `
    query TranslationById($translationId: String = "") {
  translations_by_pk(translationId: $translationId) {
    translatedText
    word
    mutoonSectionId
  }
}
    `;

export const useTranslationByIdQuery = <
      TData = TranslationByIdQuery,
      TError = unknown
    >(
      variables?: TranslationByIdQueryVariables,
      options?: UseQueryOptions<TranslationByIdQuery, TError, TData>
    ) => {
    
    return useQuery<TranslationByIdQuery, TError, TData>(
      variables === undefined ? ['TranslationById'] : ['TranslationById', variables],
      fetchData<TranslationByIdQuery, TranslationByIdQueryVariables>(TranslationByIdDocument, variables),
      options
    )};

export const UserRecordsDocument = `
    query UserRecords($limit: Int = 10, $offset: Int = 0, $search: String_comparison_exp = {}, $status: String_comparison_exp! = {}, $order_by: [user_records_order_by!] = {status: asc}) {
  user_records(
    limit: $limit
    offset: $offset
    where: {_or: [{user: {firstName: $search}}, {user: {lastName: $search}}, {mutoonSection: {mutoon: {name: {_cast: {String: $search}}}}}, {mutoonSection: {name: {_cast: {String: $search}}}}], status: $status}
    order_by: $order_by
  ) {
    createdAt
    recordingId
    audioRecordUrl
    user {
      firstName
      lastName
      email
    }
    mutoonSection {
      name
      mutoon {
        name
        id
      }
      mutoonSectionId
    }
    status
  }
  user_records_aggregate(
    where: {_or: [{user: {_or: {firstName: $search, lastName: $search}}}, {mutoonSection: {mutoon: {name: {_cast: {String: $search}}}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export const useUserRecordsQuery = <
      TData = UserRecordsQuery,
      TError = unknown
    >(
      variables?: UserRecordsQueryVariables,
      options?: UseQueryOptions<UserRecordsQuery, TError, TData>
    ) => {
    
    return useQuery<UserRecordsQuery, TError, TData>(
      variables === undefined ? ['UserRecords'] : ['UserRecords', variables],
      fetchData<UserRecordsQuery, UserRecordsQueryVariables>(UserRecordsDocument, variables),
      options
    )};

export const UsersDocument = `
    query Users($offset: Int = 0, $limit: Int = 10, $order_by: [users_order_by!] = {}, $where: users_bool_exp = {}) {
  users(offset: $offset, limit: $limit, order_by: $order_by, where: $where) {
    ...user
  }
  users_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${UserFragmentDoc}`;

export const useUsersQuery = <
      TData = UsersQuery,
      TError = unknown
    >(
      variables?: UsersQueryVariables,
      options?: UseQueryOptions<UsersQuery, TError, TData>
    ) => {
    
    return useQuery<UsersQuery, TError, TData>(
      variables === undefined ? ['Users'] : ['Users', variables],
      fetchData<UsersQuery, UsersQueryVariables>(UsersDocument, variables),
      options
    )};

export const OneUserDocument = `
    query OneUser($id: String = "") {
  users_by_pk(id: $id) {
    ...user
  }
}
    ${UserFragmentDoc}`;

export const useOneUserQuery = <
      TData = OneUserQuery,
      TError = unknown
    >(
      variables?: OneUserQueryVariables,
      options?: UseQueryOptions<OneUserQuery, TError, TData>
    ) => {
    
    return useQuery<OneUserQuery, TError, TData>(
      variables === undefined ? ['OneUser'] : ['OneUser', variables],
      fetchData<OneUserQuery, OneUserQueryVariables>(OneUserDocument, variables),
      options
    )};
