import {
  Card,
  CardHeader,
  Container,
  Divider,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import { CONTACT_US_STATUS } from 'src/constants/contact-us-status';
import { CONTACT_US_TABLE_HEAD } from 'src/constants/tables-heads';
import ContactUsRow from './contact-us-row';
import useContactUs from './use-contact-us';

type ContactUsPropsType = {
  title?: string;
};

const ContactUs = ({ title }: ContactUsPropsType) => {
  const {
    data,
    handleDeleteContactMsg,
    handleSearch,
    isDeleting,
    isLoading,
    methods,
    openFilter,
    query,
    setQuery,
    t,
    table,
  } = useContactUs();

  const viewComponent = (
    <AppLoadingAndErrorWrapper isLoading={isDeleting} errorMessage={null}>
      <Helmet>
        <title>{t('sidebar.contactUs')}</title>
      </Helmet>
      <Card>
        {title && <CardHeader title={title} />}
        {title && <Divider sx={{ mt: 3 }} />}
        <FormProvider methods={methods}>
          <AppTableToolbar
            handleSearch={handleSearch}
            tableName=""
            filters={[
              {
                name: 'status',
                type: 'select',
                options: CONTACT_US_STATUS(t),
                title: t('tables.contactUs.filters.status'),
              },
              {
                type: 'date',
                name: 'createdAt.value',
                opertaorFieldName: 'createdAt.operator',
                title: t('tables.contactUs.filters.createdAt'),
              },
            ]}
            openFilters={openFilter}
            setQuery={setQuery}
            search={query.search!}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  setQuery={setQuery}
                  headLabel={CONTACT_US_TABLE_HEAD(t)}
                  methods={methods}
                />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.contact_us?.map((row) => (
                        <ContactUsRow
                          key={row.contactId}
                          row={row}
                          deleteHandler={handleDeleteContactMsg}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.contact_us_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
      </Card>
    </AppLoadingAndErrorWrapper>
  );

  return title ? viewComponent : <Container>{viewComponent}</Container>;
};

export default ContactUs;
