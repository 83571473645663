// @mui
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
// components
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import AddNewMatnDialog from 'src/sections/dialogs/add/AddNewMatnDialog';
import { MatnSectionStateType } from 'src/types/matn-sections';
import { CategoryMatnDto } from 'src/types/mutoon-categories';

// ----------------------------------------------------------------------

type Props = {
  row: CategoryMatnDto;
  deleteHandler: (mutoonId: string) => void;
};

export default function CategoriesMutoonRow({ row, deleteHandler }: Props) {
  const { t, currentLang } = useLocales();
  const addDialog = useBoolean();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';

  const navState: MatnSectionStateType | undefined = {
    matn: row,
  };

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Iconify icon="simple-line-icons:doc" />
            <Link
              to={`${paths.dashboard.mutoon.matn}/${row.mutoon.id}`}
              style={{
                color: isLight ? 'black' : 'white',
              }}
              state={navState}
            >
              {row.mutoon.name[currentLang.value]}
            </Link>
          </Stack>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {t('tables.categoryMutoon.type')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row.mutoon.updatedAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.CONTENT_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton onClick={addDialog.onTrue}>
                <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
          <AppPermissionGate permissionsName={[AppPermissions.CONTENT_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.mutoon.id)}>
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
      <AddNewMatnDialog open={addDialog.value} onClose={addDialog.onFalse} matn={row} />
    </>
  );
}
