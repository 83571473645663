import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTable } from 'src/components/table';
import { IAppTableToolbarProps } from 'src/components/table/types';
import { QUESTIONS_CHOICES_TYPE } from 'src/constants/questions-choices-type';
import {
  Order_By,
  useAllMutoonsQuery,
  useDeleteQuestionMutation,
  useMatnSectionsQuery,
  useQuestionsQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';
import { useQueryParam, useQueryParams } from 'use-query-params';

type formType = {
  matnName: string | undefined;
  matnSectionId: string | undefined;
  choicesType: string | undefined;
  isQuiz: boolean | undefined;
  sortBy: string;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

const useQuestions = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const openFilters = useBoolean();
  const queryClient = useQueryClient();

  const [filtersQuery] = useQueryParams({
    choicesType: '',
    isQuiz: '',
    matnName: '',
  });

  const { methods, query, handleSearch, setQuery } = UseSearch<formType>({
    choicesType: filtersQuery.choicesType || undefined,
    isQuiz:
      filtersQuery.isQuiz !== undefined
        ? filtersQuery.isQuiz === 'true'
          ? true
          : false
        : undefined,
    matnName: filtersQuery.matnName || undefined,
  });

  const { matnName, choicesType, isQuiz, matnSectionId, sortBy, sortDirection } = methods.watch();
  const { t, currentLang } = useLocales();

  // #region Services
  console.log(query);
  // Questions
  const { data, isLoading } = useQuestionsQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _cast: { String: { _ilike: `%${query.search}%` } } } : undefined,
    matnName: query.matnName ? { _cast: { String: { _ilike: `%${query.matnName}%` } } } : {},
    matnSectionId: { _eq: matnSectionId! },
    choicesType: { _eq: choicesType! },
    isQuiz: { _eq: isQuiz! },
    order_by: orderBy(sortBy, sortDirection),
  });

  // Delete question
  const { mutate, isLoading: isDeleting } = useDeleteQuestionMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Questions'] }),
  });

  // Matn sections
  const { data: matnSections } = useMatnSectionsQuery(
    {
      mutoonMatnId: '',
    },
    {
      enabled: false,
    }
  );
  // #endregion Services

  // #region Handlers
  const handleDeleteQuestion = (questionId: string) => {
    mutate({ questionId });
  };
  // #endregion Handlers

  // Filters
  const filters: IAppTableToolbarProps<{}>['filters'] = [
    {
      name: 'matnName',
      title: t('tables.questions.filters.matn'),
      type: 'text',
    },
    // {
    //   name: 'matnSectionId',
    //   title: 'Matn section',
    //   type: 'select',
    //   options: matnSections?.mutoon_sections.map((item) => ({
    //     label: item.name[currentLang.value],
    //     value: item.mutoonSectionId,
    //   })),
    // },
    {
      name: 'choicesType',
      title: t('tables.questions.filters.choicesType'),
      type: 'select',
      options: QUESTIONS_CHOICES_TYPE(t),
    },
    {
      name: 'isQuiz',
      title: t('tables.questions.filters.isQuiz'),
      type: 'select',
      options: [
        {
          label: t('tables.questions.filters.enums.isQuiz.exam'),
          value: false as any,
        },
        {
          label: t('tables.questions.filters.enums.isQuiz.quiz'),
          value: true as any,
        },
      ],
    },
  ];

  return {
    methods,
    handleSearch,
    openFilters,
    currentLang,
    matnSections,
    isLoading,
    isDeleting,
    table,
    data,
    handleDeleteQuestion,
    t,
    filters,
    query,
    setQuery,
  };
};

export default useQuestions;
