import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import {
  useAddReferenceBookMutation,
  useOneReferenceBookQuery,
  useUpdateReferenceBookMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddBookMutationVariablesType, BookDto } from 'src/types/books';

const useAddNewBook = () => {
  // #region States
  const confirm = useBoolean();
  const { bookSchema } = useSchemas();
  const { bookId: bookIdParam } = useParams();
  const queryClient = useQueryClient();
  const { usePostApi } = useApiServices();
  const { t, allLangs } = useLocales();
  const router = useRouter();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const [file, setFile] = useState<File | string | null>(null);
  const { state }: { state: { book: BookDto | undefined } | undefined } = useLocation();
  const [bookId, setBookId] = useState<string | undefined>(bookIdParam);
  const isEditing = !!state?.book;
  const [dataToSend, setDataToSend] = useState<AddBookMutationVariablesType>();
  // #endregion States

  let defaultValues: AddBookMutationVariablesType = {
    bookName: state?.book ? state?.book?.bookName : {},
    title: state?.book ? state?.book?.title : {},
    bookAuthor: state?.book ? state?.book?.bookAuthor : {},
    bookLanguage: state?.book ? state?.book?.bookLanguage : {},
    dateOfBorn: state?.book?.dateOfBorn ? state?.book?.dateOfBorn : '',
    dateOfDeath: state?.book?.dateOfDeath ? state?.book?.dateOfDeath : null,
    fromSchool: state?.book ? state?.book?.fromSchool : {},
    nazemName: state?.book ? state?.book?.nazemName : {},
    numberOfLines: state?.book ? state?.book?.numberOfLines! : 10,
    pdfUrl: state?.book ? state?.book?.pdfUrl! : '',
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state?.book; i++) {
    defaultValues.bookName[allLangs[i].value] = '';
    defaultValues.title[allLangs[i].value] = '';
    defaultValues.bookAuthor[allLangs[i].value] = '';
    defaultValues.bookLanguage[allLangs[i].value] = '';
    defaultValues.fromSchool[allLangs[i].value] = '';
    defaultValues.nazemName[allLangs[i].value] = '';
  }

  const methods = useForm<AddBookMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(bookSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddBookMutationVariablesType) => {
      data.dateOfBorn = moment(data.dateOfBorn).format('YYYY-MM-DD');
      data.dateOfDeath = moment(data.dateOfDeath).format('YYYY-MM-DD');
      if (data.dateOfDeath.toLowerCase() === 'invalid date') {
        data.dateOfDeath = null;
      }
      if (!isEditing) delete (data as any).pdfUrl;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  const { isLoading: isLoadingBook } = useOneReferenceBookQuery(
    {
      bookId,
    },
    {
      enabled: !state?.book && !!bookId,
      onSuccess: (data) => {
        {
          methods.setValue('bookName', data.reference_books_by_pk!?.bookName);
          methods.setValue('title', data.reference_books_by_pk!?.title);
          methods.setValue('bookAuthor', data.reference_books_by_pk!?.bookAuthor);
          methods.setValue('bookLanguage', data.reference_books_by_pk!?.bookLanguage);
          methods.setValue('dateOfBorn', data.reference_books_by_pk!?.dateOfBorn);
          methods.setValue('dateOfDeath', data.reference_books_by_pk!?.dateOfDeath);
          methods.setValue('fromSchool', data.reference_books_by_pk!?.fromSchool);
          methods.setValue('nazemName', data.reference_books_by_pk!?.nazemName);
          methods.setValue('numberOfLines', data.reference_books_by_pk!?.numberOfLines!);
          methods.setValue('pdfUrl', data.reference_books_by_pk!?.pdfUrl!);
        }
      },
    }
  );

  const {
    mutate: uploadRefBook,
    isLoading: isUploadingRefBook,
    data,
  } = usePostApi({
    url: API_URLS.UPLOAD_REF_BOOK(bookId ?? ''),
    withFormData: true,
    onSuccess: (data: any) => {
      methods.setValue('pdfUrl', data?.avatar);
    },
  });

  const {
    mutate: createRefBook,
    isLoading: isAdding,
    isSuccess: hasAddedBook,
  } = useAddReferenceBookMutation({
    onSuccess: (data) => {
      setBookId(data.insert_reference_books_one?.bookId);
      confirm.onFalse();
    },
  });

  const { mutate: editRefBook, isLoading: isUpdating } = useUpdateReferenceBookMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['ReferenceBooks'] });
      // If the user was just removing the book do not take him out
      if (data.update_reference_books_by_pk?.pdfUrl) router.push(paths.dashboard.mutoon.books);
    },
  });

  const mutate = isEditing
    ? () =>
        editRefBook({
          ...dataToSend,
          bookId,
          pdfUrl: dataToSend?.pdfUrl,
        })
    : () =>
        createRefBook({
          ...dataToSend,
        });
  // #endregion Services

  // #region handlers
  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
      methods.setValue('pdfUrl', URL.createObjectURL(newFile));
    }
  }, []);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`title.${currentTabValue}`, methods.watch().title[currentTabValue] || '');
    methods.setValue(
      `bookName.${currentTabValue}`,
      methods.watch().bookName[currentTabValue] || ''
    );
    methods.setValue(
      `bookAuthor.${currentTabValue}`,
      methods.watch().bookAuthor[currentTabValue] || ''
    );
    methods.setValue(
      `bookLanguage.${currentTabValue}`,
      methods.watch().bookLanguage[currentTabValue] || ''
    );
    methods.setValue(
      `fromSchool.${currentTabValue}`,
      methods.watch().fromSchool[currentTabValue] || ''
    );
    methods.setValue(
      `nazemName.${currentTabValue}`,
      methods.watch().nazemName[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);

  useEffect(() => {
    if (file && (hasAddedBook || state?.book)) uploadRefBook({ file: file });
  }, [file, hasAddedBook]);
  // #endregion useEffect

  return {
    t,
    handleSubmit,
    onSubmit,
    methods,
    isUpdating,
    isUploadingRefBook,
    file,
    handleDropSingleFile,
    setFile,
    editRefBook,
    state,
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    isEditing,
    confirm,
    isAdding,
    mutate,
  };
};

export default useAddNewBook;
