// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    statistics: `${ROOTS.DASHBOARD}/statistics`,
    roles: {
      root: `${ROOTS.DASHBOARD}/roles-and-users/roles`,
      addRole: `${ROOTS.DASHBOARD}/roles-and-users/roles/new-role`,
      editRole: (id: string) => `${ROOTS.DASHBOARD}/roles-and-users/roles/editRole/${id}`,
    },

    userAuthorization: {
      root: `${ROOTS.DASHBOARD}/user-authorization`,
      teachersRequests: `${ROOTS.DASHBOARD}/user-authorization/teachers-requests`,
      schoolsAccounts: `${ROOTS.DASHBOARD}/user-authorization/schools-accounts`,
    },

    sessions: `${ROOTS.DASHBOARD}/sessions`,

    mutoon: {
      root: `${ROOTS.DASHBOARD}/mutoon`,
      matn: `${ROOTS.DASHBOARD}/mutoon/categories/matn`,
      categories: `${ROOTS.DASHBOARD}/mutoon/categories`,
      books: `${ROOTS.DASHBOARD}/mutoon/books`,
      exams: `${ROOTS.DASHBOARD}/mutoon/exams`,
      questions: `${ROOTS.DASHBOARD}/mutoon/questions`,
    },

    certificates: {
      root: `${ROOTS.DASHBOARD}/certificates`,
      builder: `${ROOTS.DASHBOARD}/certificates/builder`,
      table: `${ROOTS.DASHBOARD}/certificates`,
    },

    appUsers: `${ROOTS.DASHBOARD}/app-users`,

    badges: {
      root: `${ROOTS.DASHBOARD}/badges`,
      customBadges: `${ROOTS.DASHBOARD}/badges/custom-badges`,
      generalBadges: `${ROOTS.DASHBOARD}/badges/general-badges`,
      new: {
        custom: `${ROOTS.DASHBOARD}/badges/new/custom-badges`,
        general: `${ROOTS.DASHBOARD}/badges/new/general-badges`,
      },
    },

    voiceRequests: `${ROOTS.DASHBOARD}/voice-requests`,

    scientificSessions: `${ROOTS.DASHBOARD}/scientific-sessions`,

    hearingCouncils: `${ROOTS.DASHBOARD}/hearing-councils`,

    knowledgeBases: `${ROOTS.DASHBOARD}/knowledge-bases`,

    faqs: `${ROOTS.DASHBOARD}/faqs`,

    discussionsRoom: `${ROOTS.DASHBOARD}/discussion-room`,

    rolesAndUsers: {
      roles: `${ROOTS.DASHBOARD}/roles-and-users/roles`,
      users: `${ROOTS.DASHBOARD}/roles-and-users/users`,
    },

    finance: `${ROOTS.DASHBOARD}/finance`,

    pages: {
      root: `${ROOTS.DASHBOARD}/pages`,
      aboutTheApp: `${ROOTS.DASHBOARD}/about-the-app`,
      faqs: `${ROOTS.DASHBOARD}/faqs`,
      privacyPolicy: `${ROOTS.DASHBOARD}/privacy-policy`,
      termsOfService: `${ROOTS.DASHBOARD}/terms-of-service`,
    },

    contactUs: `${ROOTS.DASHBOARD}/contact-us`,
  },
};
