import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import HearingCouncils from 'src/assets/icons/sidebar/HearingCouncils';
import Mutoons from 'src/assets/icons/sidebar/Mutoons';
import RolesAndUsers from 'src/assets/icons/sidebar/RolesAndUsers';
import VoiceRequests from 'src/assets/icons/sidebar/VoiceRequests';
import { useLocales } from 'src/locales';
import AppPermissions from 'src/constants/app-permissions';
import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppUsers from 'src/assets/icons/sidebar/AppUsers';
import Badges from 'src/assets/icons/sidebar/Badges';
import Sessions from 'src/assets/icons/sidebar/Sessions';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t, currentLang } = useLocales();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';

  const data = useMemo(
    () => [
      // Statistics
      {
        subheader: '',
        items: [
          // Statistics
          {
            title: t('sidebar.statistics'),
            path: paths.dashboard.statistics,
            icon: <Iconify icon="material-symbols-light:analytics" width={24} height={24} />,
            permission: [AppPermissions.VIEW_DASHBOARD],
          },
          // Content management
          {
            title: t('sidebar.contentManagement'),
            path: paths.dashboard.hearingCouncils,
            icon: <Iconify icon="fluent:content-view-gallery-20-filled" width={24} height={24} />,
            permission: [
              AppPermissions.VIEW_DASHBOARD,
              AppPermissions.HEARING_COUNCILS_VIEW,
              AppPermissions.CERTIFICATES_VIEW,
              AppPermissions.CONTENT_VIEW,
              AppPermissions.BADGES_VIEW,
              AppPermissions.USER_RECORD_VIEW,
              AppPermissions.SESSIONS_VIEW,
              AppPermissions.KNOWLEDGE_BASES_VIEW,
            ],
            children: [
              // Hearing councils
              {
                title: t('sidebar.hearingCouncils'),
                path: paths.dashboard.hearingCouncils,
                icon: <HearingCouncils />,
                permission: [AppPermissions.HEARING_COUNCILS_VIEW],
              },
              // Certificates
              {
                title: t('sidebar.certificates.table'),
                path: paths.dashboard.certificates.table,
                icon: <Iconify icon="fluent:certificate-32-regular" />,
                permission: [AppPermissions.CERTIFICATES_VIEW],
              },
              // Mutoon
              {
                title: t('sidebar.mutoon.root'),
                path: paths.dashboard.mutoon.root,
                icon: <Mutoons />,
                permission: [AppPermissions.CONTENT_VIEW],
                children: [
                  {
                    title: t('sidebar.mutoon.categories'),
                    path: paths.dashboard.mutoon.categories,
                  },
                  {
                    title: t('sidebar.mutoon.books'),
                    path: paths.dashboard.mutoon.books,
                  },
                  {
                    title: t('sidebar.mutoon.exams'),
                    path: paths.dashboard.mutoon.exams,
                    permission: [AppPermissions.EXAMS_VIEW],
                  },
                  {
                    title: t('sidebar.mutoon.questionsBank'),
                    path: paths.dashboard.mutoon.questions,
                    permission: [AppPermissions.VIEW_QUESTIONS],
                  },
                ],
              },
              {
                title: t('sidebar.badges'),
                path: paths.dashboard.badges.root,
                icon: <Badges />,
                permission: [AppPermissions.BADGES_VIEW],
              },
              {
                title: t('sidebar.voiceRequests'),
                path: paths.dashboard.voiceRequests,
                icon: <VoiceRequests />,
                permission: [AppPermissions.USER_RECORD_VIEW],
              },
              // Sessions
              {
                title: t('sidebar.sessions.root'),
                path: paths.dashboard.sessions,
                icon: <Sessions />,
                permission: [AppPermissions.SESSIONS_VIEW],
              },
              {
                title: t('sidebar.knowledgeBases'),
                path: paths.dashboard.knowledgeBases,
                icon: (
                  <Iconify
                    icon="carbon:rule"
                    width={24}
                    height={24}
                    color={isLight ? 'black' : 'white'}
                  />
                ),
                permission: [AppPermissions.KNOWLEDGE_BASES_VIEW],
              },
            ],
          },
          // User authorization
          {
            title: t('sidebar.userAuthorization.title'),
            path: paths.dashboard.userAuthorization.root,
            icon: <Iconify icon="ph:chalkboard-teacher-fill" width={24} height={24} />,
            permission: [AppPermissions.VIEW_DASHBOARD],
            children: [
              {
                title: t('sidebar.userAuthorization.teachersRequests'),
                path: paths.dashboard.userAuthorization.teachersRequests,
              },
              {
                title: t('sidebar.userAuthorization.schoolRequests'),
                path: paths.dashboard.userAuthorization.schoolsAccounts,
              },
            ],
          },
          // App users
          {
            title: t('sidebar.appUsers'),
            path: paths.dashboard.appUsers,
            icon: <AppUsers />,
            permission: [AppPermissions.USER_VIEW],
          },
          // Help Center
          {
            title: t('sidebar.helpCenter'),
            path: paths.dashboard.faqs,
            icon: (
              <Iconify
                icon="material-symbols:help-center"
                width={24}
                height={24}
                color={isLight ? 'black' : 'white'}
              />
            ),
            permission: [AppPermissions.CONTENT_VIEW],
            children: [
              {
                title: t('sidebar.faqs'),
                path: paths.dashboard.faqs,
                icon: (
                  <Iconify
                    icon="mingcute:question-line"
                    width={24}
                    height={24}
                    color={isLight ? 'black' : 'white'}
                  />
                ),
                permission: [AppPermissions.CONTENT_VIEW],
              },
              {
                title: t('sidebar.contactUs'),
                path: paths.dashboard.contactUs,
                icon: <Iconify icon="icon-park-solid:message" width={24} height={24} />,
                permission: [AppPermissions.CONTACT_US_VIEW],
              },
            ],
          },
          // Roles and permissions
          {
            title: t('sidebar.rolesAndUsers'),
            path: '',
            icon: <RolesAndUsers />,
            permission: [AppPermissions.VIEW_ROLE_MANAGEMENT],
            children: [
              {
                path: paths.dashboard.rolesAndUsers.roles,
                title: t('sidebar.roles'),
              },
              {
                path: paths.dashboard.rolesAndUsers.users,
                title: t('sidebar.users'),
              },
            ],
          },
        ],
      },
    ],
    [currentLang]
  );

  return data;
}
