import { Node } from '@misraj-sdk/certificate-generator-ui/dist/common/types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import { useAddCertificateMutation, useAddCertificateVariablesMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';

const useAddNewCertificate = () => {
  // #region States and Hooks
  const { t } = useLocales();
  const confirm = useBoolean();
  const hasAddedImage = useBoolean();
  const router = useNavigate();
  const [certImg, setCertImg] = useState();
  const [title, setTitle] = useState<string>();
  const [certId, setCertId] = useState<string>();
  const [variables, setVariables] = useState<Node[]>();
  const { usePostApi } = useApiServices();

  const STATIC_VARS = [
    'USERNAME',
    'DATE_OF_ISSUER',
    'DATE_OF_EXPIRY',
    'CERTIFICATE_NUMBER',
    'DEGREE',
    'SCORE',
    'RESULT',
    'MutoonName',
  ];
  // #endregion States and Hooks

  // #region Services
  const { mutate: uploadCertificate, isLoading: isUploadingCert } = usePostApi({
    url: API_URLS.UPLOAD_CERTIFICATE(certId!),
    withFormData: true,
    onSuccess: () => {
      router(paths.dashboard.certificates.root);
    },
  });

  const { mutate: addVars, isLoading: isAddingVars } = useAddCertificateVariablesMutation();

  const {
    mutate: addCertificate,
    isSuccess: isCertAdded,
    isLoading: isAddingCert,
  } = useAddCertificateMutation({
    onSuccess: (data) => {
      setCertId(data.insert_certificates_one?.certificateId);
      variables?.length &&
        addVars({
          objects: variables?.map((variable) => {
            delete (variable as any).id;
            return {
              ...variable,
              certID: data.insert_certificates_one?.certificateId,
              dynamicVarType: 'text' in variable ? variable.text : undefined,
            };
          }),
        });
    },
  });
  // #endregion Services

  // #region Handlers
  const handleImageUpload = (e: any) => {
    hasAddedImage.onTrue();
    setCertImg(e);
  };

  const handleAddVariables = useCallback((e: Node[]) => {
    if (!e.length) return;
    setVariables(e);
  }, []);
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    certId && uploadCertificate({ file: certImg });
  }, [certId]);
  // #endregion useEffect

  return {
    title,
    setTitle,
    t,
    STATIC_VARS,
    hasAddedImage,
    handleImageUpload,
    handleAddVariables,
    confirm,
    isAddingCert,
    isUploadingCert,
    addCertificate,
  };
};

export default useAddNewCertificate;
