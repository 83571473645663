import { Card, Chip, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import AppButton from 'src/components/common/AppButton';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import TimeCounter from 'src/components/common/CounterTimer';
import Iconify from 'src/components/iconify/iconify';
import DeleteDialog from 'src/sections/dialogs/delete/DeleteDialog';
import HearingCouncilQrDialog from 'src/sections/dialogs/view/hearing-council-qr-dialog';
import { HearingCouncilDto } from 'src/types/hearing-council';
import useHearingCouncilsCard from './use-hearing-councils-card';

const HearingCouncilCard = ({
  hearingCouncil,
  mutateDelete,
  isDeleting,
}: {
  hearingCouncil: HearingCouncilDto;
  isDeleting: boolean;
  mutateDelete: any;
}) => {
  const {
    buttonLabel,
    currentLang,
    deleteDialog,
    isButtonEnabled,
    isJoining,
    isSubscribedToHearingCouncil,
    isSubscribing,
    qrDialog,
    subscribe,
    t,
    adminId,
    join,
    router,
    chipColor,
  } = useHearingCouncilsCard({ hearingCouncil });

  if (isDeleting)
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: 118,
          borderRadius: 2,
        }}
      />
    );

  return (
    <Card sx={{ p: 3, backgroundColor: 'background.cards', width: '100%', height: '100%' }}>
      <Stack spacing={3}>
        {/* Title */}
        <Card sx={{ p: 1.5 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
              {hearingCouncil.sessionName[currentLang.value]}
            </Typography>
            <Chip
              color={chipColor(hearingCouncil.status as 'pending' | 'live' | 'expired')}
              label={t(`sessions.status.${hearingCouncil.status as 'Pending'}`)}
            />
          </Stack>
        </Card>
        {/* Description */}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">{hearingCouncil.description[currentLang.value]}</Typography>
          <Stack direction="row">
            {!isButtonEnabled && (
              <IconButton
                onClick={() =>
                  router(`${hearingCouncil.hearingCouncilId}/edit`, { state: hearingCouncil })
                }
                color="warning"
              >
                <Iconify icon="tabler:edit" />
              </IconButton>
            )}
            <IconButton onClick={deleteDialog.onTrue} color="error">
              <Iconify icon="material-symbols:delete-outline" />
            </IconButton>
            <IconButton onClick={qrDialog.onTrue} color="info">
              <Iconify icon="fluent:qr-code-28-regular" />
            </IconButton>
          </Stack>
        </Stack>
        {/* Session date */}
        <TimeCounter
          canStartBefore={hearingCouncil.appointmentLimit}
          eventTime={hearingCouncil.startDate}
          endTime={hearingCouncil.expiryDate}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {/* Join button */}
          {isSubscribedToHearingCouncil && buttonLabel && (
            <AppLoadingButton
              label={buttonLabel}
              size="small"
              type="button"
              onClick={() => join({ hearingCouncilId: hearingCouncil.hearingCouncilId })}
              isLoading={isJoining}
              disabled={!isButtonEnabled}
            />
          )}
          {/* Subscribe button */}
          {!isSubscribedToHearingCouncil &&
            !(hearingCouncil.status?.toLowerCase() === 'expired') && (
              <AppLoadingButton
                label={t('buttons.subscribe')}
                size="small"
                type="button"
                onClick={() =>
                  subscribe({ hearingCouncilId: hearingCouncil.hearingCouncilId, adminId })
                }
                isLoading={isSubscribing}
              />
            )}
          {/* Show analysis */}
          {hearingCouncil.status?.toLowerCase() === 'expired' && (
            <AppButton
              label={t('buttons.showAnalysis')}
              size="small"
              href={`${hearingCouncil.hearingCouncilId}/analysis`}
            />
          )}
        </Stack>
      </Stack>
      <DeleteDialog
        idWord="hearingCouncilId"
        id={hearingCouncil.hearingCouncilId}
        isDeleting={isDeleting}
        open={deleteDialog.value}
        onClose={deleteDialog.onFalse}
        mutate={mutateDelete}
      />
      <HearingCouncilQrDialog
        open={qrDialog.value}
        onClose={qrDialog.onFalse}
        hearingCouncilId={hearingCouncil.hearingCouncilId}
      />
    </Card>
  );
};

export default HearingCouncilCard;
